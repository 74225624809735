import { Rail } from "types";

export default {
  type: "rail",
  uuid: "f903e5dd-03b2-4659-98fd-f2be073943b1",
  template: "tile-landscape",
  title: "Documentaries",
  tiles: [
    {
      type: "landscapeTile",
      title: "The Misadventures of Romesh Ranganathan",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/38df82b5-2585-4d1c-9216-462f6d8325a0/16-9/{width}",
      hero: {
        mode: "standard",
        title: "The Misadventures of Romesh Ranganathan",
        metadata: "1h 45m   ",
        synopsis:
          "Romesh Ranganathan travels to some of the most unlikely places on earth for a holiday.",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/38df82b5-2585-4d1c-9216-462f6d8325a0/background/{width}",
      },
    },
    {
      type: "landscapeTile",
      title: "Ibiza Dreams",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/fd078335-3872-460d-b1c9-22983c28baeb/16-9/{width}",
      hero: {
        mode: "standard",
        title: "Ibiza Dreams",
        metadata: "1h 45m   ",
        synopsis:
          "Ibiza Dreams follows 10 hard-working Brits trying to kick start their careers.",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/fd078335-3872-460d-b1c9-22983c28baeb/background/{width}",
      },
    },
    {
      type: "landscapeTile",
      title: "China: A New World Order",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/fedce351-5fdb-482b-8c53-f7a9865439aa/16-9/{width}",
      hero: {
        mode: "standard",
        title: "China: A New World Order",
        metadata: "1h 45m   ",
        synopsis: "Examining the controversial presidency of Xi Jinping.",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/fedce351-5fdb-482b-8c53-f7a9865439aa/background/{width}",
      },
    },
    {
      type: "landscapeTile",
      title: "Earth From Space",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/e2d31c12-f4b0-4797-b203-12b0c30ca557/16-9/{width}",
      hero: {
        mode: "standard",
        title: "Earth From Space",
        metadata: "1h 45m   ",
        synopsis:
          "Cameras in space tell stories of life on our planet from a brand new perspective.",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/e2d31c12-f4b0-4797-b203-12b0c30ca557/background/{width}",
      },
    },
    {
      type: "landscapeTile",
      title: "Britain's Forgotten Slave Owners",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/ccc8a1ae-8223-4dbc-9f94-0d5d42cacab6/16-9/{width}",
      hero: {
        mode: "standard",
        title: "Britain's Forgotten Slave Owners",
        metadata: "1h 45m   ",
        synopsis:
          "Documentary looking at the price of the abolition of slavery in Britain",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/ccc8a1ae-8223-4dbc-9f94-0d5d42cacab6/background/{width}",
      },
    },
    {
      type: "landscapeTile",
      title: "Black Music Legends of the 1980s",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/86e8033d-fccd-483a-83c0-a388ccb36d5e/16-9/{width}",
      hero: {
        mode: "standard",
        title: "Black Music Legends of the 1980s",
        metadata: "1h 45m   ",
        synopsis:
          "Series of documentaries looking at the careers of four titans of African-American music.",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/86e8033d-fccd-483a-83c0-a388ccb36d5e/background/{width}",
      },
    },
    {
      type: "landscapeTile",
      title: "Simply Nigella",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/a8591dd4-d7a8-4bd3-955d-8c7d777cdc9a/16-9/{width}",
      hero: {
        mode: "standard",
        title: "Simply Nigella",
        metadata: "1h 45m   ",
        synopsis:
          "Nigella Lawson shows us that what and how we cook can make us feel better and more alive.",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/a8591dd4-d7a8-4bd3-955d-8c7d777cdc9a/background/{width}",
      },
    },
    {
      type: "landscapeTile",
      title: "Nigella: At My Table",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/aa3ee5cb-1c57-4fa5-bc39-2fb27ed6a37c/16-9/{width}",
      hero: {
        mode: "standard",
        title: "Nigella: At My Table",
        metadata: "1h 45m   ",
        synopsis:
          "Nigella Lawson is back with a series packed with simple recipes full of complex flavour.",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/aa3ee5cb-1c57-4fa5-bc39-2fb27ed6a37c/background/{width}",
      },
    },
    {
      type: "landscapeTile",
      title: "Princess Margaret: The Rebel Royal",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/87db2779-532e-4182-a974-a9eea9fd51ca/16-9/{width}",
      hero: {
        mode: "standard",
        title: "Princess Margaret: The Rebel Royal",
        metadata: "1h 45m   ",
        synopsis: "Intimate series profiling Princess Margaret.",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/87db2779-532e-4182-a974-a9eea9fd51ca/background/{width}",
      },
    },
    {
      type: "landscapeTile",
      title: "Amazing Hotels: Life Beyond the Lobby",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/4b0e50e2-ead2-4f45-9821-8fc46dc9a613/16-9/{width}",
      hero: {
        mode: "standard",
        title: "Amazing Hotels: Life Beyond the Lobby",
        metadata: "1h 45m   ",
        synopsis:
          "Giles Coren and Monica Galetti work in some of the most extraordinary hotels in the world.",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/4b0e50e2-ead2-4f45-9821-8fc46dc9a613/background/{width}",
      },
    },
  ],
} as Rail;
