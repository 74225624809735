import { Rail } from "types";

export default {
  type: "rail",
  uuid: "ac68cf7a-ae2a-4644-ad45-286605a4f3b8",
  template: "tile-landscape",
  title: "Yesterday",
  tiles: [
    {
      type: "landscapeTile",
      title: "Father Brown",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/37c33fdd-93a9-4090-ba8b-dba7a569e60f/16-9/{width}",
      hero: {
        mode: "standard",
        title: "Father Brown",
        metadata: "1h 45m   ",
        synopsis:
          "The Tanganyika Green. Father Brown investigates the murder of a Colonial Postal Service officer.",
        logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_bbcone/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/37c33fdd-93a9-4090-ba8b-dba7a569e60f/background/{width}",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_bbcone/408/84",
    },
    {
      type: "landscapeTile",
      title: "The Great British Sewing Bee",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/ddac4f0b-5745-4275-bcde-be59610fdc0c/16-9/{width}",
      hero: {
        mode: "standard",
        title: "The Great British Sewing Bee",
        metadata: "1h 45m   ",
        synopsis:
          "Episode 10. The competition reaches its dramatic denouement as the series winner is crowned.",
        logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_bbcone/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/ddac4f0b-5745-4275-bcde-be59610fdc0c/background/{width}",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_bbcone/408/84",
    },
    {
      type: "landscapeTile",
      title: "The Repair Shop",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/0f2f1e69-0de1-4270-8ae0-4369377ad61e/16-9/{width}",
      hero: {
        mode: "standard",
        title: "The Repair Shop",
        metadata: "1h 45m   ",
        synopsis:
          "Episode 6. Today in the Repair Shop, three more treasured family heirlooms are brought back to life.",
        logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_bbcone/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/0f2f1e69-0de1-4270-8ae0-4369377ad61e/background/{width}",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_bbcone/408/84",
    },
    {
      type: "landscapeTile",
      title: "Disclosure",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/b05ff54f-3e93-4bf4-b0c2-b25b917ee1c6/16-9/{width}",
      hero: {
        mode: "standard",
        title: "Disclosure",
        metadata: "1h 45m   ",
        synopsis:
          "Secrets of Scotlands Superhospital. Investigation into patient deaths at Scotlands superhospital.",
        logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_bbcone/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/b05ff54f-3e93-4bf4-b0c2-b25b917ee1c6/background/{width}",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_bbcone/408/84",
    },
    {
      type: "landscapeTile",
      title: "Pointless",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/90df5d52-2654-45d2-a75a-7262894b6a44/16-9/{width}",
      hero: {
        mode: "standard",
        title: "Pointless",
        metadata: "1h 45m   ",
        synopsis:
          "Episode 33. Quiz in which contestants try to score as few points as possible.",
        logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_bbcone/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/90df5d52-2654-45d2-a75a-7262894b6a44/background/{width}",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_bbcone/408/84",
    },
    {
      type: "landscapeTile",
      title: "Pointless",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/90df5d52-2654-45d2-a75a-7262894b6a44/16-9/{width}",
      hero: {
        mode: "standard",
        title: "Pointless",
        metadata: "1h 45m   ",
        synopsis:
          "Episode 34. Quiz in which contestants try to score as few points as possible.",
        logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_bbcone/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/90df5d52-2654-45d2-a75a-7262894b6a44/background/{width}",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_bbcone/408/84",
    },
    {
      type: "landscapeTile",
      title: "The Bidding Room",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/2f854338-bc3b-4774-969e-208ae3b318b5/16-9/{width}",
      hero: {
        mode: "standard",
        title: "The Bidding Room",
        metadata: "1h 45m   ",
        synopsis:
          "Episode 13. The dealers bid on a retro Olivetti typewriter and a prop from a Terry Pratchett film.",
        logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_bbcone/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/2f854338-bc3b-4774-969e-208ae3b318b5/background/{width}",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_bbcone/408/84",
    },
    {
      type: "landscapeTile",
      title: "Garden Rescue",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/8764e2b3-d13f-429f-a369-8c312ad8b12f/16-9/{width}",
      hero: {
        mode: "standard",
        title: "Garden Rescue",
        metadata: "1h 45m   ",
        synopsis:
          "Stevenage. A manhole-strewn garden in Stevenage is made safe and usable for newborn twins.",
        logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_bbcone/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/8764e2b3-d13f-429f-a369-8c312ad8b12f/background/{width}",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_bbcone/408/84",
    },
    {
      type: "landscapeTile",
      title: "Escape to the Country",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/04740f60-2e32-445a-81ec-1160ff54491e/16-9/{width}",
      hero: {
        mode: "standard",
        title: "Escape to the Country",
        metadata: "1h 45m   ",
        synopsis:
          "Norfolk. Nicki Chapman helps a couple find a rural home with space for them to keep rescue animals.",
        logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_bbcone/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/04740f60-2e32-445a-81ec-1160ff54491e/background/{width}",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_bbcone/408/84",
    },
    {
      type: "landscapeTile",
      title: "Politics Scotland",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/8683023c-78e6-4d46-8745-5b23f21ea71e/16-9/{width}",
      hero: {
        mode: "standard",
        title: "Politics Scotland",
        metadata: "1h 45m   ",
        synopsis:
          "24/06/2020. Gordon Brewer and guests look at the weeks events at Westminster and Holyrood.",
        logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_bbcone/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/8683023c-78e6-4d46-8745-5b23f21ea71e/background/{width}",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_bbcone/408/84",
    },
  ],
} as Rail;
