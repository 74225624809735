import { Rail } from "types";

export default {
  type: "rail",
  uuid: "48747359-169a-4349-8ca4-9f6562d4f28f",
  template: "tile-landscape",
  title: "Wednesday",
  tiles: [
    {
      type: "landscapeTile",
      title: "Spotlight",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/ec6c07b5-6709-4696-b41b-8cff50cd7a41/16-9/{width}",
      hero: {
        mode: "standard",
        title: "Spotlight",
        metadata: "1h 45m   ",
        synopsis:
          "Spotlight Special. TV debate with Naomi Long, Gavin Robinson, Chris Hazzard, Simon Hoare & Neale Richmond.",
        logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_bbcone/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/ec6c07b5-6709-4696-b41b-8cff50cd7a41/background/{width}",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_bbcone/408/84",
    },
    {
      type: "landscapeTile",
      title: "Holby City",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/97ea8260-da34-4ec2-a514-4a3aa10c5073/16-9/{width}",
      hero: {
        mode: "standard",
        title: "Holby City",
        metadata: "1h 45m   ",
        synopsis:
          "Episode 18. Fletch and his daughter Evie reveal life-changing secrets each has been keeping.",
        logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_bbcone/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/97ea8260-da34-4ec2-a514-4a3aa10c5073/background/{width}",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_bbcone/408/84",
    },
    {
      type: "landscapeTile",
      title: "EastEnders: Iconic Episodes",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/8e407a37-4609-4f22-b558-671905b377cb/16-9/{width}",
      hero: {
        mode: "standard",
        title: "EastEnders: Iconic Episodes",
        metadata: "1h 45m   ",
        synopsis:
          "Den & Angie. Den's demands for a divorce lead to a revelation from Angie, but is she telling the truth?",
        logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_bbcone/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/8e407a37-4609-4f22-b558-671905b377cb/background/{width}",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_bbcone/408/84",
    },
    {
      type: "landscapeTile",
      title: "The One Show",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/c4d361ea-c909-4b8e-81c1-a065b3f67cea/16-9/{width}",
      hero: {
        mode: "standard",
        title: "The One Show",
        metadata: "1h 45m   ",
        synopsis:
          "23/06/2020. Alex Jones and Chris Ramsey chat to soap star Anita Dobson and tennis legend Sue Barker.",
        logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_bbcone/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/c4d361ea-c909-4b8e-81c1-a065b3f67cea/background/{width}",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_bbcone/408/84",
    },
    {
      type: "landscapeTile",
      title: "BBC News Special",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/314691ca-924b-48e6-aae9-33be8944d6b6/16-9/{width}",
      hero: {
        mode: "standard",
        title: "BBC News Special",
        metadata: "1h 45m   ",
        synopsis:
          "23/06/2020. The latest news including a Downing Street news conference on the coronavirus pandemic.",
        logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_bbcone/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/314691ca-924b-48e6-aae9-33be8944d6b6/background/{width}",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_bbcone/408/84",
    },
    {
      type: "landscapeTile",
      title: "The Bidding Room",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/00fa11ea-5024-482f-b772-9240332e600d/16-9/{width}",
      hero: {
        mode: "standard",
        title: "The Bidding Room",
        metadata: "1h 45m   ",
        synopsis:
          "Episode 12. The dealers bid on a watercolour painting, a vintage walking stick and a classic toy.",
        logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_bbcone/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/00fa11ea-5024-482f-b772-9240332e600d/background/{width}",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_bbcone/408/84",
    },
    {
      type: "landscapeTile",
      title: "Escape to the Country",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/f47dd175-829b-4792-bdb1-007afa9a5be5/16-9/{width}",
      hero: {
        mode: "standard",
        title: "Escape to the Country",
        metadata: "1h 45m   ",
        synopsis:
          "West Sussex. Jules Hudson heads to West Sussex to help a couple on a budget of £700,000.",
        logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_bbcone/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/f47dd175-829b-4792-bdb1-007afa9a5be5/background/{width}",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_bbcone/408/84",
    },
    {
      type: "landscapeTile",
      title: "Father Brown",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/99e9f98c-4887-4244-9ab6-9569f3522750/16-9/{width}",
      hero: {
        mode: "standard",
        title: "Father Brown",
        metadata: "1h 45m   ",
        synopsis:
          "The Theatre of the Invisible. Father Brown suspects that one of the cast of a radio quiz is responsible for murder.",
        logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_bbcone/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/99e9f98c-4887-4244-9ab6-9569f3522750/background/{width}",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_bbcone/408/84",
    },
    {
      type: "landscapeTile",
      title: "The Heights",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/b53f20e3-c905-4288-8106-4fe2cc320c8d/16-9/{width}",
      hero: {
        mode: "standard",
        title: "The Heights",
        metadata: "1h 45m   ",
        synopsis:
          "Episode 7. Claudia lectures Sabine, Renee criticises Mark over renovations, and Iris frustrates Kam.",
        logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_bbcone/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/b53f20e3-c905-4288-8106-4fe2cc320c8d/background/{width}",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_bbcone/408/84",
    },
    {
      type: "landscapeTile",
      title: "Bargain Hunt",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/e8928867-7dd3-42e9-a9ec-d3d4026c5666/16-9/{width}",
      hero: {
        mode: "standard",
        title: "Bargain Hunt",
        metadata: "1h 45m   ",
        synopsis:
          "Edinburgh 5. Today's programme comes from Edinburgh, presented by Natasha Raskin Sharp.",
        logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_bbcone/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/e8928867-7dd3-42e9-a9ec-d3d4026c5666/background/{width}",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_bbcone/408/84",
    },
  ],
} as Rail;
