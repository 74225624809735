import React, { useRef } from "react";
import {
  Container,
  Frame,
  TextContainer,
  SpeakerIcon,
} from "./VolumePanel.styles";
import { Text } from "components/Text";
import { boolToInt } from "utils/boolToInt";
import { useDefaultAnimation } from "hooks/useDefaultAnimation";

type Props = {
  volume: number;
  muted: boolean;
  visible: boolean;
};

const meterGrey = "#9FA8B4";
const meterBlue = "#3C89E5";

const Meter = ({
  width,
  height,
  value,
}: {
  width: number;
  height: number;
  value: number;
}) => {
  const valueHeight = (height * value) / 100;
  return (
    <svg width={width} height={height}>
      <rect fill={meterGrey} width={width} height={height}></rect>
      <rect
        fill={meterBlue}
        y={height - valueHeight}
        width={width}
        height={valueHeight}
      ></rect>
    </svg>
  );
};

const speakerIconSrc = (muted: boolean) =>
  `assets/images/volume/volume-${muted ? "mute" : "speaker"}.png`;

export const VolumePanel = ({ volume, muted, visible }: Props) => {
  const effectiveVolume = muted ? 0 : volume,
    container = useRef(null);

  useDefaultAnimation(container, { opacity: boolToInt(visible) });

  return (
    <Container ref={container}>
      <Frame>
        <TextContainer>
          <Text size="medium" weight="medium">
            {effectiveVolume}
          </Text>
        </TextContainer>
        <Meter width={10} height={320} value={effectiveVolume} />
        <SpeakerIcon src={speakerIconSrc(muted)} />
      </Frame>
    </Container>
  );
};
