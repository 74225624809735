import React, { useRef } from "react";
import { grid, rail as railDims } from "constants/dimensions";
import { CategoryTile } from "components/CategoryTile";
import { CoverTile } from "components/CoverTile";
import { LandscapeTile } from "components/LandscapeTile";
import { TitleTile } from "components/TitleTile";
import { PromoTile } from "components/PromoTile";
import { Text } from "components/Text";
import { shouldShowRailTitle } from "utils/railHelpers";
import { Rail as RailType, Tile } from "types";
import { Container, TextContainer, ItemContainer } from "./Rail.styles";
import { NowAndNextTile } from "components/NowAndNextTile";
import { SquareTile } from "components/SquareTile";
import { useAnimations } from "hooks/useAnimations";
import gsap from "gsap";
import { boolToInt } from "utils/boolToInt";
import { wait } from "utils/wait";
import { transitions } from "constants/transitions";
import { useDefaultAnimation } from "hooks/useDefaultAnimation";
import { RailSelector } from "components/RailSelector";

const tileComponent = (tile: Tile, focused: boolean) => {
  switch (tile.type) {
    case "titleTile":
      return <TitleTile tile={tile} focused={focused} />;
    case "landscapeTile": {
      return <LandscapeTile tile={tile} focused={focused} />;
    }
    case "coverTile": {
      return <CoverTile tile={tile} focused={focused} />;
    }
    case "categoryTile": {
      return <CategoryTile tile={tile} focused={focused} />;
    }
    case "promoTile": {
      return <PromoTile tile={tile} focused={focused} />;
    }
    case "nowAndNextTile":
      return <NowAndNextTile tile={tile} focused={focused} />;
    case "squareTile":
      return <SquareTile tile={tile} focused={focused} />;
  }
};

type Props = {
  rail: RailType;
  focusedTileIndex: number;
  animationScope?: string;
  focused?: boolean;
  visible?: boolean;
};

export const Rail = ({
  animationScope,
  focusedTileIndex,
  rail,
  focused,
  visible,
}: Props) => {
  focused = focused ?? false;
  visible = visible ?? true;
  const container = useRef(null),
    textContainer = useRef(null),
    showTitle = shouldShowRailTitle(rail, focusedTileIndex);

  // Animate container
  useDefaultAnimation(
    container,
    {
      opacity: boolToInt(visible),
      marginTop: railDims.marginTop(showTitle || focused),
    },
    transitions.changeRail
  );

  // Animate text container
  useAnimations(
    {
      scope: animationScope,
      reset: params => gsap.set(textContainer.current, params),
      defaultAnimation: params =>
        gsap.to(textContainer.current, { ...params, ...transitions.moveLens }),
      uninterruptible: ["holdRail"],
      animations: {
        holdRail: () => wait(transitions.changeRail.duration),
      },
    },
    {
      y: railDims.titleOffset(focused, rail.template),
      opacity: boolToInt(showTitle),
    }
  );

  return (
    <Container ref={container}>
      <TextContainer ref={textContainer}>
        <Text size="medium" weight="medium">
          {rail.title}
        </Text>
      </TextContainer>
      <RailSelector
        height={railDims.height(rail.template)}
        index={focusedTileIndex}
        items={focused =>
          rail.tiles.map((tile, index) => (
            <ItemContainer key={index} focused={focused}>
              {tileComponent(tile, focused)}
            </ItemContainer>
          ))
        }
        width={railDims.innerWidth}
        animationScope={animationScope}
        focused={focused}
        focusedHeight={railDims.focusedHeight(rail.template)}
        focusedExtraWidth={2 * grid.column.gap}
      />
    </Container>
  );
};
