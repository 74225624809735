import { RailsPage } from "types";
import apps from "./apps";
import bestTv from "./bestTv";
import catchUpSport from "./catchUpSport";
import categories from "./categories";
import mustSee from "./mustSee";
import newSeries from "./newSeries";
import recordings from "./recordings";
import settings from "./settings";
import skyChannels from "./skyChannels";
import skyCinema from "./skyCinema";
import tvGuide from "./tvGuide";

export default {
  type: "railsPage",
  rails: [
    tvGuide,
    recordings,
    categories,
    bestTv,
    mustSee,
    apps,
    skyCinema,
    catchUpSport,
    skyChannels,
    newSeries,
    settings,
  ],
  title: {
    text: "Home",
  },
} as RailsPage;
