import { Rail } from "types";

export default {
  type: "rail",
  uuid: "492ee91a-c8b9-41a1-8ba4-283b3b789f51",
  template: "tile-landscape",
  title: "Entertainment",
  tiles: [
    {
      type: "landscapeTile",
      title: "Your Home Made Perfect",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/4dddb68d-1a58-48c1-b175-bd08e559fbde/16-9/{width}",
      hero: {
        mode: "standard",
        title: "Your Home Made Perfect",
        metadata: "1h 45m   ",
        synopsis:
          "Episode 6. Virtual reality shows two different ways to remodel a home with a problematic hallway.",
        logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_bbctwo/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/4dddb68d-1a58-48c1-b175-bd08e559fbde/background/{width}",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_bbctwo/408/84",
    },
    {
      type: "landscapeTile",
      title: "Peter Crouch: Save Our Summer",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/63cf08b1-39e2-4445-b6f8-5a099331d3ed/16-9/{width}",
      hero: {
        mode: "standard",
        title: "Peter Crouch: Save Our Summer",
        metadata: "1h 45m   ",
        synopsis:
          "Episode 3. Abbey Clancy drops in for an extra special edition of Peter Crouch's Extreme Retirement. Contains some strong language.",
        logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_bbcone/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/63cf08b1-39e2-4445-b6f8-5a099331d3ed/background/{width}",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_bbcone/408/84",
    },
    {
      type: "landscapeTile",
      title: "EastEnders: Secrets from the Square",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/cf9e5d19-4524-4af4-bd90-ac672b2c6651/16-9/{width}",
      hero: {
        mode: "standard",
        title: "EastEnders: Secrets from the Square",
        metadata: "1h 45m   ",
        synopsis:
          "Mick and Linda. Stacey Dooley is joined by Danny Dyer and Kellie Bright to talk all things Mick and Linda.",
        logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_bbcone/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/cf9e5d19-4524-4af4-bd90-ac672b2c6651/background/{width}",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_bbcone/408/84",
    },
    {
      type: "landscapeTile",
      title: "You Are What You Wear",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/294ee7fa-f1ad-4d93-9247-ede89eaf9d00/16-9/{width}",
      hero: {
        mode: "standard",
        title: "You Are What You Wear",
        metadata: "1h 45m   ",
        synopsis:
          "Episode 1. Fashion makeover series presented by Rylan Clark-Neal.",
        logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_bbcone/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/294ee7fa-f1ad-4d93-9247-ede89eaf9d00/background/{width}",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_bbcone/408/84",
    },
    {
      type: "landscapeTile",
      title: "The Choir",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/83b78543-59fb-4257-8c10-83caf5feaeeb/16-9/{width}",
      hero: {
        mode: "standard",
        title: "The Choir",
        metadata: "1h 45m   ",
        synopsis:
          "Episode 1. Gareth Malone works with frontline staff to compose a song to bring Britain together.",
        logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_bbctwo/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/83b78543-59fb-4257-8c10-83caf5feaeeb/background/{width}",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_bbctwo/408/84",
    },
    {
      type: "landscapeTile",
      title: "The Great British Sewing Bee",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/d1c46c59-7cff-4e6d-b9cb-f18711ee6176/16-9/{width}",
      hero: {
        mode: "standard",
        title: "The Great British Sewing Bee",
        metadata: "1h 45m   ",
        synopsis: "Episode 1. Twelve amateur sewers compete over ten weeks.",
        logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_bbcone/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/d1c46c59-7cff-4e6d-b9cb-f18711ee6176/background/{width}",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_bbcone/408/84",
    },
    {
      type: "landscapeTile",
      title: "The Rap Game UK",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/fbe280a3-94cd-40b8-b3e6-dde6157a6538/16-9/{width}",
      hero: {
        mode: "standard",
        title: "The Rap Game UK",
        metadata: "1h 45m   ",
        synopsis:
          "Episode 1. Straight after meeting, the artists are faced with their first on the spot performance. Contains strong language.",
        logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_bbc/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/fbe280a3-94cd-40b8-b3e6-dde6157a6538/background/{width}",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_bbc/408/84",
    },
    {
      type: "landscapeTile",
      title: "QI",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/20769390-66f3-4878-8057-54e5e3ce4616/16-9/{width}",
      hero: {
        mode: "standard",
        title: "QI",
        metadata: "1h 45m   ",
        synopsis:
          "Rubbish. Sandi talks rubbish with Alan Davies, Bridget Christie, Johnny Vegas and Holly Walsh.",
        logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_bbctwo/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/20769390-66f3-4878-8057-54e5e3ce4616/background/{width}",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_bbctwo/408/84",
    },
    {
      type: "landscapeTile",
      title: "The Bidding Room",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/ddb41ea2-f3e9-4655-8108-f5896f1b44c7/16-9/{width}",
      hero: {
        mode: "standard",
        title: "The Bidding Room",
        metadata: "1h 45m   ",
        synopsis:
          "Episode 1. Five dealers bid against each other to buy items brought in by the public.",
        logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_bbcone/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/ddb41ea2-f3e9-4655-8108-f5896f1b44c7/background/{width}",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_bbcone/408/84",
    },
    {
      type: "landscapeTile",
      title: "Remarkable Places to Eat",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/44d0ce06-ef66-4623-ac42-ee69a807c0d7/16-9/{width}",
      hero: {
        mode: "standard",
        title: "Remarkable Places to Eat",
        metadata: "1h 45m   ",
        synopsis:
          "Marrakesh. TV chef Andi Oliver takes Fred to Marrakesh to discover the finest Moroccan cooking.",
        logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_bbctwo/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/44d0ce06-ef66-4623-ac42-ee69a807c0d7/background/{width}",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_bbctwo/408/84",
    },
  ],
} as Rail;
