import { fork, takeEvery, takeLatest } from "sagas/effects";
import { toastSaga } from "sagas/toast";
import { timeSaga } from "sagas/time";
import { voiceSaga } from "sagas/voice";
import { volumeSaga } from "sagas/volume";
import { settingsSaga } from "sagas/settings";
import { browseSaga } from "sagas/browse";
import { entrypointsSaga } from "sagas/entrypoints";
import { qamSaga } from "sagas/quickAccessMenu";
import { doorbellSaga } from "sagas/doorbell";
import { showMoreHeroAfterPause } from "sagas/hero";
import { shortcut } from "sagas/shortcuts";
import { navigate } from "sagas/navigate";
import { journeyStart } from "sagas/journey";
import { goToPage, initializePage } from "sagas/pages";
import { goHome } from "sagas/home";

export function* rootSaga() {
  yield fork(settingsSaga);
  yield fork(toastSaga);
  yield fork(timeSaga);
  yield fork(voiceSaga);
  yield fork(volumeSaga);
  yield fork(browseSaga);
  yield fork(entrypointsSaga);
  yield fork(qamSaga);
  yield fork(doorbellSaga);

  yield takeLatest(
    ["NEXT_SWITCHER_ITEM", "PREV_SWITCHER_ITEM"],
    showMoreHeroAfterPause
  );
  yield fork(showMoreHeroAfterPause);

  yield takeEvery("NAVIGATE", navigate);
  yield takeEvery("SHORTCUT", shortcut);
  yield takeLatest("GO_TO_PAGE", goToPage);
  yield takeLatest("GO_HOME", goHome);
  yield takeLatest("JOURNEY.START", journeyStart);
  yield takeLatest(
    ["BROWSE_FORWARD", "BROWSE_BACK", "BROWSE_REPLACE_PAGE"],
    initializePage
  );
}
