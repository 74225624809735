import React, { useState, ImgHTMLAttributes, useEffect } from "react";

type Props = {
  fallbackSrc: string;
} & ImgHTMLAttributes<HTMLImageElement>;

export const ImageWithFallback = ({
  src,
  fallbackSrc,
  ...remainingProps
}: Props) => {
  const [errorFlag, setErrorFlag] = useState(false);

  // When src changes, unset error
  useEffect(() => {
    setErrorFlag(false);
  }, [src]);

  return (
    // eslint-disable-next-line jsx-a11y/alt-text
    <img
      {...remainingProps}
      src={errorFlag ? fallbackSrc : src}
      onError={() => setErrorFlag(true)}
    />
  );
};
