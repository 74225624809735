import { Rail } from "types";

export default {
  type: "rail",
  uuid: "7d842e09-5acf-41e2-8b11-d26241130786",
  template: "tile-cover",
  title: "Sing & Dance",
  tiles: [
    {
      type: "coverTile",
      title: "Little Baby Bum",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/e9cd39d4-b573-4541-933b-9988a85dccdc/cover/{height}",
      hero: {
        mode: "standard",
        title: "Little Baby Bum",
        metadata: "1h 45m   ",
        synopsis:
          "Little Baby Bum makes learning fun! Combining nursery rhymes with adorable animations to create beautiful educational videos your children will love!",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/e9cd39d4-b573-4541-933b-9988a85dccdc/background/{width}",
        heroTitleImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/e4aa8f65c1cc8498a7216270831f9692/herotitle.png",
      },
    },
    {
      type: "coverTile",
      title: "Songs & Rhymes",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/99073d86-41f1-417b-b5fc-195be7947978/cover/{height}",
      hero: {
        mode: "standard",
        title: "Songs & Rhymes",
        metadata: "1h 45m   ",
        synopsis: "Special karaoke mix with the best of BabyTV songs.",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/99073d86-41f1-417b-b5fc-195be7947978/background/{width}",
      },
    },
    {
      type: "coverTile",
      title: "Jump Out of Bed Sleepy Head",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/9e889067-42c7-4e41-87f4-2e5fede0dfaf/cover/{height}",
      hero: {
        mode: "standard",
        title: "Jump Out of Bed Sleepy Head",
        metadata: "1h 45m   ",
        synopsis:
          "Waking up the castle every day is not easy, but our two heroes manage to overcome obstacles and get the kingdom ready in time for the new day.",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/e39786b2242a16d36ce12a905575111c/hero.jpg",
      },
    },
    {
      type: "coverTile",
      title: "Go Buster",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/552762d3-869d-4c5d-8516-b6f6c834843c/cover/{height}",
      hero: {
        mode: "standard",
        title: "Go Buster",
        metadata: "1h 45m   ",
        synopsis:
          "Join popular Little Baby Bum character Buster, a curious, friendly and eager-to-learn yellow bus, as he goes on a series of brand-new adventures.",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/552762d3-869d-4c5d-8516-b6f6c834843c/background/{width}",
      },
    },
    {
      type: "coverTile",
      title: "Aliens Love Underpants and... Singalongs",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/5d2a31a5-4b5c-46bd-9e97-3222683daa8b/cover/{height}",
      hero: {
        mode: "standard",
        title: "Aliens Love Underpants and... Singalongs",
        metadata: "1h 45m   ",
        synopsis:
          "...and Singalongs. Join your five favourite underpants-loving aliens as they star in a series of silly, catchy songs about their other great loves.",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/5d2a31a5-4b5c-46bd-9e97-3222683daa8b/background/{width}",
      },
    },
    {
      type: "coverTile",
      title: "Labuntina",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/af837a6e-fc95-48ba-917d-b109774d2691/cover/{height}",
      hero: {
        mode: "standard",
        title: "Labuntina",
        metadata: "1h 45m   ",
        synopsis:
          "Sing along with Lili Fish, Judi Bee and Kodi Fox and learn about numbers, ABC, colours, animals and much more, in the magical world of Labuntina!",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/af837a6e-fc95-48ba-917d-b109774d2691/background/{width}",
      },
    },
    {
      type: "coverTile",
      title: "Milkshake! Music Box",
      imageUrl:
        "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/e3639583362faf3f4bc0c9fd9d68cedf/{width}/{height}/item.jpg",
      hero: {
        mode: "standard",
        title: "Milkshake! Music Box",
        metadata: "1h 45m   ",
        synopsis: "Exciting songs from the Milkshake Music Box.\r",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/d21b31d0-dd97-4be8-a7e7-42706ea903ac/background/{width}",
      },
    },
    {
      type: "coverTile",
      title: "Ready Set Dance",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/5aa1f5fb-294c-41b6-bb2c-022b51725d66/cover/{height}",
      hero: {
        mode: "standard",
        title: "Ready Set Dance",
        metadata: "1h 45m   ",
        synopsis:
          "Ready Set Dance will get you moving and grooving. Join Freeze and Twirl for great songs and cool moves!\r",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/5aa1f5fb-294c-41b6-bb2c-022b51725d66/background/{width}",
      },
    },
    {
      type: "coverTile",
      title: "Mr Bean Music Videos",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/8d6b2662-76ef-4eeb-a9e1-9be00d28c728/cover/{height}",
      hero: {
        mode: "standard",
        title: "Mr Bean Music Videos",
        metadata: "1h 45m   ",
        synopsis:
          "Sing and dance with Mr Bean! Original songs celebrating his favourite things including dancing, pizza, holidays and, of course, a nice cup of tea!\r",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/8d6b2662-76ef-4eeb-a9e1-9be00d28c728/background/{width}",
      },
    },
    {
      type: "titleTile",
      actionText: "View all",
      hero: {
        mode: "standard",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/8d6b2662-76ef-4eeb-a9e1-9be00d28c728/background/{width}",
      },
    },
  ],
} as Rail;
