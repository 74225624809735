import React from "react";
import { Container } from "./QAMEventReel.styles";
import { CameraEventLineController } from "components/CameraEventLine";
import { timeToDateMs } from "utils/timeHelpers";
import { CameraEvent } from "types";
import { Text } from "components/Text";
import { screen } from "constants/dimensions";

type Props = { focused: boolean; title?: string };

const events: CameraEvent[] = [
    {
      type: "person",
      title: "Garden activity",
      dateMs: timeToDateMs(6, 47),
      url: "assets/videos/camera-events/man-garden/man-garden.mpd",
      previewUrl: "assets/videos/camera-events/man-garden-preview.mp4",
    },
    {
      type: "person",
      title: "Left home",
      dateMs: timeToDateMs(8, 16),
      url: "assets/videos/camera-events/leaving-home/leaving-home.mpd",
      previewUrl: "assets/videos/camera-events/leaving-home-preview.mp4",
    },
    {
      type: "person",
      title: "Fedex rang doorbell",
      dateMs: timeToDateMs(11, 7),
      url: "assets/videos/camera-events/postman/postman.mpd",
      previewUrl: "assets/videos/camera-events/postman-preview.mp4",
    },
    {
      type: "person",
      title: "Hallway activity",
      dateMs: timeToDateMs(13, 26),
      url: "assets/videos/camera-events/hallway/hallway.mpd",
      previewUrl: "assets/videos/camera-events/hallway-preview.mp4",
    },
    {
      type: "person",
      title: "Returned home",
      dateMs: timeToDateMs(16, 15),
      url: "assets/videos/camera-events/return-home-2/return-home-2.mpd",
      previewUrl: "assets/videos/camera-events/return-home-2-preview.mp4",
    },
    {
      type: "person",
      title: "Sitting room",
      dateMs: timeToDateMs(18),
      url: "assets/videos/camera-events/sitting-room/sitting-room.mpd",
      previewUrl: "assets/videos/camera-events/sitting-room-preview.mp4",
    },
  ],
  startDateMs = timeToDateMs(7),
  endDateMs = timeToDateMs(18),
  currentDateMs = timeToDateMs(18);

export const QAMEventReel = ({ focused, title }: Props) => {
  return (
    <Container>
      {title && (
        <Text
          weight="medium"
          size="medium"
          style={{
            position: "absolute",
            bottom: 180,
            width: screen.width,
            textAlign: "center",
          }}
        >
          {title}
        </Text>
      )}
      <CameraEventLineController
        focused={focused}
        events={events}
        startDateMs={startDateMs}
        endDateMs={endDateMs}
        currentDateMs={currentDateMs}
      />
    </Container>
  );
};

type ControllerProps = { focused: boolean; title?: string };

export const QAMEventReelController = ({ focused, title }: ControllerProps) => {
  return <QAMEventReel focused={focused} title={title} />;
};
