import { SpecificAction } from "types/actions";
import { delay, put, takeLatest } from "sagas/effects";

export function* volumeSaga() {
  yield takeLatest("SHOW_VOLUME", onShowVolume);
  yield takeLatest(
    ["CHANGE_VOLUME", "SET_VOLUME", "MUTE", "UNMUTE"],
    showVolume
  );
}

function* onShowVolume(action: SpecificAction<"SHOW_VOLUME">) {
  yield delay(3000);
  yield put({ type: "HIDE_VOLUME" });
}

function* showVolume() {
  yield put({ type: "SHOW_VOLUME" });
}
