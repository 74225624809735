import {
  RemoteVideoTrack,
  RemoteDataTrack,
  RemoteAudioTrack,
  Participant,
  Publication,
} from "twilio-video";
import { Observer } from "rxjs";
import { TwilioRxEvent, StreamsPayload } from "../../types/twiliorx";

class StreamManager {
  private _liveVideoTracks = new Map<string, RemoteVideoTrack>();
  private _liveAudioTracks = new Map<string, RemoteAudioTrack>();
  private _liveDataTracks = new Map<string, RemoteDataTrack>();
  private _participants = new Map<string, Participant>();

  public observer: Observer<TwilioRxEvent> | null = null;

  addParticipant(participant) {
    participant.tracks.forEach(publication => {
      if (publication.isSubscribed) {
        this.checkAndAddTrack(publication.track);
      }
    });
    participant.on("trackSubscribed", this.checkAndAddTrack);
    participant.on("trackRemoved", this.handleTrackRemoved);
    this._participants.set(participant.id, participant);
  }

  checkAndAddTrack = (track: RemoteVideoTrack) => {
    let theMap: Map<string, any> = null;

    switch (track.kind) {
      case "video":
        theMap = this._liveVideoTracks;
        break;
      case "audio":
        theMap = this._liveAudioTracks;
        break;
      case "data":
        track.on("message", this.handleData);
        theMap = this._liveDataTracks;
        break;
    }

    if (theMap) {
      theMap.set(track.sid, track);
      if (this.observer) {
        this.observer.next({
          type: `track ready`,
          payload: { track, liveTracks: this.liveTracks },
        });
      }
    }
  };

  handleData = data => {
    console.log("INCOMING DATA", data);
    if (this.observer) {
      this.observer.next({
        type: "incoming data",
        payload: { data },
      });
    }
  };

  checkAndRemoveTrack = (track: Publication) => {
    if (track.kind === "video") {
      this._liveVideoTracks.delete(track.trackSid);
      if (this.observer) {
        this.observer.next({
          type: "video track removed",
          payload: { track, liveTracks: this.liveTracks },
        });
      }
    }
    if (track.kind === "data") {
      const dataTrack = this._liveDataTracks.get(track.trackSid);
      if (track) {
        if (dataTrack) dataTrack.off("data", this.handleData);
        this._liveDataTracks.delete(track.trackSid);
      }

      if (this.observer) {
        this.observer.next({
          type: "data track removed",
          payload: { track, liveTracks: this.liveTracks },
        });
      }
    }
  };

  handleTrackRemoved(track) {
    console.log("Track Removed +++ DOING NUTHING", track);
    // TODO handle a removed track
  }

  removeParticipant(participant) {
    participant.off("trackSubscribed", this.checkAndAddTrack);
    participant.off("trackRemoved", this.checkAndRemoveTrack);

    participant.tracks.forEach((t: Publication) => {
      console.log("Participant tracks checking", t);
      this.checkAndRemoveTrack(t);
    });

    this._participants.delete(participant.id);
  }

  destroy() {
    this._participants.forEach(participant => {
      participant.off("trackSubscribed", this.checkAndAddTrack);
      participant.off("trackRemoved", this.checkAndRemoveTrack);
    });

    this._liveDataTracks.forEach(dataTrack => {
      dataTrack.off("data", this.handleData);
    });

    this._liveVideoTracks = new Map<string, RemoteVideoTrack>();
    this._liveDataTracks = new Map<string, RemoteDataTrack>();
    this._participants = new Map<string, Participant>();
    if (this.observer) {
      this.observer.next({
        type: "closing all tracks",
        payload: { liveTracks: this.liveTracks },
      });
    }
    this.observer = null;
  }

  get liveTracks(): StreamsPayload {
    return {
      video: Array.from(this._liveVideoTracks.values()),
      data: Array.from(this._liveDataTracks.values()),
      audio: Array.from(this._liveAudioTracks.values()),
    };
  }
}

export default StreamManager;
