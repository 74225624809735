import styled from "@emotion/styled";
import { tiles } from "../../constants/dimensions";
import { tileShadow } from "constants/effects";

const { landscapeTile } = tiles;

export const Container = styled.div(
  ({ focused }: { focused: boolean }) => `
  background-color: rgba(241, 241, 241, 0.06);
  box-shadow: ${tileShadow};
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  height: ${landscapeTile.height}px;
  position: relative;
  width: ${landscapeTile.width}px;
  box-sizing: border-box;
  border-radius: ${focused ? 0 : landscapeTile.borderRadius}px;
  overflow: hidden;
`
);

export const MediaContainer = styled.div`
  position: absolute;
  width: 100%;
`;

export const Image = styled.img`
  display: block;
  width: 100%;
`;

export const Logo = styled.img`
  height: 100%;
  display: block;
`;

export const LogoContainer = styled.div`
  margin-bottom: 4px;
  height: 70px;
  width: 100%;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-end;
  padding: 24px;
  position: relative;
  transition: all 0.5s ease-in-out;
  background: url(assets/images/tile-gradient-16-9.png);
  background-size: cover;
`;
