import { roundToNearestMinutes, subMinutes } from "date-fns";
import sample from "lodash/sample";
import { Rail } from "types";
import { formatTime } from "utils/timeHelpers";

const MINS_AGO_EXAMPLES = [15, 30, 45, 60];

function randomStartTime(now: Date) {
  const minsAgo = sample(MINS_AGO_EXAMPLES);
  return roundToNearestMinutes(subMinutes(now, minsAgo), { nearestTo: 15 });
}

export const addStartedAtSublines = (rail: Rail, now: Date) => ({
  ...rail,
  tiles: rail.tiles.map(tile => ({
    ...tile,
    subline: `Started at ${formatTime(randomStartTime(now))}`,
  })),
});
