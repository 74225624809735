import { TvGuidePage } from "types";
import rail from "./rail";
import { addStartedAtSublines } from "data/pages/helpers";

export default {
  type: "tvGuidePage",
  channelIds: [
    "1409", // Sky Premiere
    "1001", // Sky Action
    "1815", // Sky Greats
    "1814", // Sky Hits
    "1177", // Sky Comedy
    "1816", // Sky Drama
    "1807", // Sky SciFi Horror
    "1818", // Sky Thriller
    "1627", // Film 4
    "1105", // Colors Cineplex
    "4420", // Movies 24
    "4421", // Movies 24+
    "3708", // Sony Action
  ],
  rail: addStartedAtSublines(rail, new Date()),
  title: {
    text: "Movies",
  },
} as TvGuidePage;
