import { Subject } from "rxjs";
import { Animation } from "types/animations";
import { config } from "utils/config";
import { gsap } from "gsap";
import { store } from "store";

type AnimationParams = {
  name: Animation;
  timeline: gsap.core.Timeline;
  scope?: string;
};

const subject = new Subject<AnimationParams>();

export const animations = {
  animate(name: Animation, scope?: string) {
    config.logAnimations &&
      console.log(
        `%cAnimation: ${name} ${scope || ""}`,
        "font-weight: bold; background-color: red; color: white; padding: 3px;"
      );
    subject.next({ name, timeline: gsap.timeline(), scope });
  },

  subscribe(callback: (params: AnimationParams) => void) {
    return subject.subscribe(callback);
  },
};

// Create a new timeline on each store change
let timeline: gsap.core.Timeline;

store.subscribe(() => {
  timeline = gsap.timeline();
});

export const currentTimeline = () => timeline;
