import { Rail } from "types";

export default {
  type: "rail",
  uuid: "77fb33be-e050-409f-bdef-e25d83edb797",
  template: "tile-landscape",
  title: "Football News",
  tiles: [
    {
      type: "landscapeTile",
      title: "Aluko: Targets needed for BAME representation",
      imageUrl:
        "https://img.skysports.com/20/05/456x257/skysports-eni-aluko-aluko_4992857.jpg?20200517213005",
      hero: {
        mode: "standard",
        title: "Aluko: Targets needed for BAME representation",
        metadata: "1h 45m   ",
        synopsis:
          "Former England forward Eniola Aluko believes it's time to set targets to increase the number of BAME individuals at the top level of sport in the UK.",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skysports/365/205",
        heroImageUrl:
          "https://cdn.skyq.sky.com/mashup/images/sports-football-hs-hero.jpg",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_skysports/365/205",
    },
    {
      type: "landscapeTile",
      title: "Wigan appeal points deduction ",
      imageUrl:
        "https://img.skysports.com/20/07/456x257/skysports-wigan-athletic-crest_5028018.jpg?20200702092507",
      hero: {
        mode: "standard",
        title: "Wigan appeal points deduction ",
        metadata: "1h 45m   ",
        synopsis:
          "Wigan's joint administrator says they've already started the appeal process for their points deduction but warns they could face another one if certain debts aren't paid.",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skysports/365/205",
        heroImageUrl:
          "https://cdn.skyq.sky.com/mashup/images/sports-football-hs-hero.jpg",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_skysports/365/205",
    },
    {
      type: "landscapeTile",
      title: "'Four or five serious offers for Wigan'",
      imageUrl:
        "https://img.skysports.com/20/07/456x257/skysports-wigan-dw-stadium_5028122.jpg?20200702114843",
      hero: {
        mode: "standard",
        title: "'Four or five serious offers for Wigan'",
        metadata: "1h 45m   ",
        synopsis:
          "Wigan Athletic's joint administrator updates us on the possible sale of the club.",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skysports/365/205",
        heroImageUrl:
          "https://cdn.skyq.sky.com/mashup/images/sports-football-hs-hero.jpg",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_skysports/365/205",
    },
    {
      type: "landscapeTile",
      title: "Catley: Arsenal move no-brainer",
      imageUrl:
        "https://img.skysports.com/20/07/456x257/promo418381276_5032549.jpg?20200707100405",
      hero: {
        mode: "standard",
        title: "Catley: Arsenal move no-brainer",
        metadata: "1h 45m   ",
        synopsis:
          "Australia defender Steph Catley says her decision to sign for Arsenal Women was a 'no brainer' because of the quality of the side and the WSL.",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skysports/365/205",
        heroImageUrl:
          "https://cdn.skyq.sky.com/mashup/images/sports-football-hs-hero.jpg",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_skysports/365/205",
    },
    {
      type: "landscapeTile",
      title: "Lloris and Son make up after bust-up",
      imageUrl:
        "https://img.skysports.com/20/07/456x257/skysports-heung-min-son-hugo-lloris_5032606.jpg?20200707110508",
      hero: {
        mode: "standard",
        title: "Lloris and Son make up after bust-up",
        metadata: "1h 45m   ",
        synopsis:
          "Tottenham team-mates Hugo Lloris and Heung-Min Son put their differences behind them following their altercation during the match against Everton.",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skysports/365/205",
        heroImageUrl:
          "https://cdn.skyq.sky.com/mashup/images/sports-football-hs-hero.jpg",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_skysports/365/205",
    },
    {
      type: "landscapeTile",
      title: "Drinks breaks 'like a time out for managers'",
      imageUrl:
        "https://img.skysports.com/20/07/456x257/8d184feb6bbea2f1d65d4169784dc7ffe58103b8389806b6c9faf400f9db1ee9_5032558.jpg?20200707101354",
      hero: {
        mode: "standard",
        title: "Drinks breaks 'like a time out for managers'",
        metadata: "1h 45m   ",
        synopsis:
          "The Ref Watch panel debate whether the introduction of drinks breaks to Premier League games is necessary.",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skysports/365/205",
        heroImageUrl:
          "https://cdn.skyq.sky.com/mashup/images/sports-football-hs-hero.jpg",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_skysports/365/205",
    },
    {
      type: "landscapeTile",
      title: "Klopp backs use of drinks breaks",
      imageUrl:
        "https://img.skysports.com/20/06/456x257/skysports-jurgen-klopp-stapler_5026828.jpg?20200630213021",
      hero: {
        mode: "standard",
        title: "Klopp backs use of drinks breaks",
        metadata: "1h 45m   ",
        synopsis:
          "Jurgen Klopp has backed the introduction of drinks breaks to Premier League games, and says he likes the opportunity to speak to his players.",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skysports/365/205",
        heroImageUrl:
          "https://cdn.skyq.sky.com/mashup/images/sports-football-hs-hero.jpg",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_skysports/365/205",
    },
    {
      type: "landscapeTile",
      title: "PL Goals of the Season: 2006/2007",
      imageUrl:
        "https://img.skysports.com/20/07/456x257/promo418312553_5031896.jpg?20200706150335",
      hero: {
        mode: "standard",
        title: "PL Goals of the Season: 2006/2007",
        metadata: "1h 45m   ",
        synopsis:
          "Take a look back at the 2006/2007 Premier League Goal of the Season contenders including iconic goals from Michael Essien, Robin van Persie and Xabi Alonso.",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skysports/365/205",
        heroImageUrl:
          "https://cdn.skyq.sky.com/mashup/images/sports-football-hs-hero.jpg",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_skysports/365/205",
    },
    {
      type: "landscapeTile",
      title: "Wilder: Don't see much drinking in drinks breaks!",
      imageUrl:
        "https://img.skysports.com/20/07/456x257/skysports-drinks-wilder-sheffield-united_5032538.jpg?20200707095800",
      hero: {
        mode: "standard",
        title: "Wilder: Don't see much drinking in drinks breaks!",
        metadata: "1h 45m   ",
        synopsis:
          "Sheffield United manager Chris Wilder has criticised the introduction of drinks breaks during Premier League games following the restart.",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skysports/365/205",
        heroImageUrl:
          "https://cdn.skyq.sky.com/mashup/images/sports-football-hs-hero.jpg",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_skysports/365/205",
    },
    {
      type: "landscapeTile",
      title: "'Glass ceiling' for black managers",
      imageUrl:
        "https://img.skysports.com/20/07/456x257/promo418376891_5032472.jpg?20200707084945",
      hero: {
        mode: "standard",
        title: "'Glass ceiling' for black managers",
        metadata: "1h 45m   ",
        synopsis:
          "England U21 coach Michael Johnson says there is a 'glass ceiling' for black players who have aspirations to take on coaching or leadership roles.",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skysports/365/205",
        heroImageUrl:
          "https://cdn.skyq.sky.com/mashup/images/sports-football-hs-hero.jpg",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_skysports/365/205",
    },
  ],
} as Rail;
