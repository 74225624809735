import { Journey } from "types";

export const skynews: Journey = {
  id: "skynews",
  pages: [
    {
      type: "videoPage",
      url:
        "https://skydvn-ssmtv-mobile-prod.mobile-tv.sky.com/ssmtv-skynews/1404/sn.m3u8",
    },
  ],
  title: "Sky News",
};
