//---------------------------------------------------------------------//
// This file is here to strictly type redux-saga effects using our app's
// - actions
// - state
//---------------------------------------------------------------------//
import {
  take as originalTake,
  takeEvery as originalTakeEvery,
  takeLatest as originalTakeLatest,
  put as originalPut,
  select as originalSelect,
  call,
} from "redux-saga/effects";
import { Action } from "types/actions";
import { State } from "reducers";
import { Animation } from "types/animations";
import { animations } from "animations";

type ActionType = Action["type"];

type Pattern = ActionType | ActionType[];
type Selector = (state: State) => unknown;
type Worker = (...args: any[]) => unknown;

// --------------------------------------------------------------------- //
// Add types to some effects
// --------------------------------------------------------------------- //

export const take = (pattern: Pattern) => originalTake(pattern);

export const takeEvery = (
  pattern: Pattern,
  worker: Worker,
  ...extraArgs: any[]
) =>
  // @ts-ignore
  originalTakeEvery(pattern, worker, ...extraArgs);

export const takeLatest = (
  pattern: Pattern,
  worker: Worker,
  ...extraArgs: any[]
) =>
  // @ts-ignore
  originalTakeLatest(pattern, worker, ...extraArgs);

export const put = (action: Action) => originalPut(action);

export const select = (selector?: Selector) => originalSelect(selector);

// --------------------------------------------------------------------- //
// Export some other effects for convenience
// --------------------------------------------------------------------- //
export {
  call,
  fork,
  spawn,
  join,
  cancel,
  delay,
  throttle,
  debounce,
  retry,
} from "redux-saga/effects";

// --------------------------------------------------------------------- //
// Custom effects
// --------------------------------------------------------------------- //
export const animate = (name: Animation, scope?: string) =>
  call(animations.animate, name, scope);
