import styled from "@emotion/styled";
import { screen } from "constants/dimensions";

export const Container = styled.div(
  ({ visible }: { visible: boolean }) => `
  position: absolute;
  opacity: ${visible ? 1 : 0};
  transition: all .5s
`
);

const top = 214;
export const Sidebar = styled.div`
  position: absolute;
  top: ${214}px;
  left: 0;
  width: 244px;
  height: ${screen.height - top}px;
  display: flex;
  justify-content: center;
  z-index: 10;
`;

export const BgMask = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 729px;
  height: ${screen.height}px;
  background: linear-gradient(
    90deg,
    #012fb1 25.49%,
    rgba(1, 47, 177, 0.0001) 94.94%
  );
`;

export const FrameContainer = styled.div(
  ({ visible }: { visible: boolean }) => `
  position: absolute;
  top: 68px;
  left: calc(50% - 36px);
  opacity: ${visible ? 1 : 0};
`
);
