import { pageIds, PageId, journeyIds, JourneyId } from "types";

const throwInvalidError = (
  obj: any,
  expectedType: string,
  name: string = "item"
) => {
  throw new Error(
    `Expected ${name} to be a ${expectedType} - instead got ${JSON.stringify(
      obj
    )}`
  );
};

export const validateString = (maybeString: unknown, name?: string): string => {
  if (typeof maybeString !== "string") {
    throwInvalidError(maybeString, "string", name);
  }
  return maybeString as string;
};

export const validateNumber = (maybeNumber: any, name?: string): number => {
  if (isNaN(maybeNumber)) {
    throwInvalidError(maybeNumber, "number", name);
  }
  return maybeNumber as number;
};

export const validateJourneyId = (
  maybeJourneyId: unknown,
  name?: string
): JourneyId => {
  const journeyId = journeyIds.find(id => id === maybeJourneyId);
  if (!journeyId) {
    throwInvalidError(maybeJourneyId, "journeyId", name);
  }
  return journeyId;
};

export const validatePageId = (maybePageId: unknown, name?: string): PageId => {
  const pageId = pageIds.find(id => id === maybePageId);
  if (!pageId) {
    throwInvalidError(maybePageId, "pageId", name);
  }
  return pageId;
};
