import { Rail } from "types";

export default {
  type: "rail",
  uuid: "a6f12136-c425-4737-b880-2fb09822d741",
  template: "tile-landscape",
  title: "On Now",
  tiles: [
    {
      type: "landscapeTile",
      title: "Doctor Foster",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/c60094df-e103-4de2-b9cc-32c95781220a/16-9/{width}",
      hero: {
        mode: "standard",
        title: "Doctor Foster",
        metadata: "1h 45m   ",
        synopsis:
          "Episode 1. Dr Gemma Foster has her life torn apart when she investigates her husband's secrets. Contains some strong language and some sexual content.",
        logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_bbc/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/c60094df-e103-4de2-b9cc-32c95781220a/background/{width}",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_bbc/408/84",
    },
    {
      type: "landscapeTile",
      title: "Arena",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/41a1b11a-a97e-465a-bcba-218a4d45d9bb/16-9/{width}",
      hero: {
        mode: "standard",
        title: "Arena",
        metadata: "1h 45m   ",
        synopsis:
          "I Am Not Your Negro. How the images and reality of black lives in America today are fabricated and enforced. Contains some strong language and upsetting scenes.",
        logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_bbctwo/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/41a1b11a-a97e-465a-bcba-218a4d45d9bb/background/{width}",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_bbctwo/408/84",
    },
    {
      type: "landscapeTile",
      title: "The Luminaries",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/5dc386d8-0ae1-4e05-96c7-238c9c1f86f5/16-9/{width}",
      hero: {
        mode: "standard",
        title: "The Luminaries",
        metadata: "1h 45m   ",
        synopsis:
          "Fingerprint. A couple meet on a voyage to New Zealand, but their plans to reunite are frustrated. Contains adult themes.",
        logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_bbcone/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/5dc386d8-0ae1-4e05-96c7-238c9c1f86f5/background/{width}",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_bbcone/408/84",
    },
    {
      type: "landscapeTile",
      title: "I May Destroy You",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/915c9ce5-1d18-41ba-8ae6-5d4ccd22ede9/16-9/{width}",
      hero: {
        mode: "standard",
        title: "I May Destroy You",
        metadata: "1h 45m   ",
        synopsis:
          "Eyes, Eyes, Eyes, Eyes. A writer with a looming deadline is tempted into a night out with a friend. Contains strong language.",
        logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_bbc/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/915c9ce5-1d18-41ba-8ae6-5d4ccd22ede9/background/{width}",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_bbc/408/84",
    },
    {
      type: "landscapeTile",
      title: "Staged",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/b7ee9de5-6ad2-4de7-996a-9fc8fcd9fade/16-9/{width}",
      hero: {
        mode: "standard",
        title: "Staged",
        metadata: "1h 45m   ",
        synopsis:
          "Cachu Hwch. David and Michael are persuaded to rehearse their postponed West End play online. Contains very strong language.",
        logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_bbcone/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/b7ee9de5-6ad2-4de7-996a-9fc8fcd9fade/background/{width}",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_bbcone/408/84",
    },
    {
      type: "landscapeTile",
      title: "Euros Rewind",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/d4c79c2a-4f82-4ac2-af0e-7b23812e4dab/16-9/{width}",
      hero: {
        mode: "standard",
        title: "Euros Rewind",
        metadata: "1h 45m   ",
        synopsis:
          "Denmark v Germany 1992. Relive a classic European Championship final chosen by the public.",
        logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_bbctwo/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/d4c79c2a-4f82-4ac2-af0e-7b23812e4dab/background/{width}",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_bbctwo/408/84",
    },
    {
      type: "landscapeTile",
      title: "Alan Bennett's Talking Heads",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/789a85c0-a630-4f50-96b4-bb6c8519f056/16-9/{width}",
      hero: {
        mode: "standard",
        title: "Alan Bennett's Talking Heads",
        metadata: "1h 45m   ",
        synopsis:
          "A Lady of Letters. Alan Bennett's classic monologue, performed by Imelda Staunton. Contains some strong language.",
        logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_bbc/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/789a85c0-a630-4f50-96b4-bb6c8519f056/background/{width}",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_bbc/408/84",
    },
    {
      type: "landscapeTile",
      title: "The Salisbury Poisonings",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/08fca649-0256-4220-a2df-1ae47e807b7f/16-9/{width}",
      hero: {
        mode: "standard",
        title: "The Salisbury Poisonings",
        metadata: "1h 45m   ",
        synopsis:
          "Episode 1. The Skripals fight to survive as the battle begins to find the source of their attack. Contains some strong language and upsetting scenes.",
        logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_bbcone/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/08fca649-0256-4220-a2df-1ae47e807b7f/background/{width}",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_bbcone/408/84",
    },
    {
      type: "landscapeTile",
      title: "Killing Eve",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/132ba13b-4fe8-424d-85b0-03c609e9cdf2/16-9/{width}",
      hero: {
        mode: "standard",
        title: "Killing Eve",
        metadata: "1h 45m   ",
        synopsis:
          "Nice Face. When a politician is murdered, an MI5 security officer must protect the only witness. Contains some violence and some upsetting scenes.",
        logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_bbc/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/132ba13b-4fe8-424d-85b0-03c609e9cdf2/background/{width}",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_bbc/408/84",
    },
    {
      type: "landscapeTile",
      title: "Ibiza Dreams",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/588d4f5f-60c4-4693-87d8-1d5fb3a13efa/16-9/{width}",
      hero: {
        mode: "standard",
        title: "Ibiza Dreams",
        metadata: "1h 45m   ",
        synopsis:
          "Episode 1. The group moves in, determined to follow their dreams and survive in paradise. Contains strong language.",
        logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_bbc/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/588d4f5f-60c4-4693-87d8-1d5fb3a13efa/background/{width}",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_bbc/408/84",
    },
  ],
} as Rail;
