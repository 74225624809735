import { getAccessToken } from "services/googleOAuth";
import { config } from "utils/config";

type QueryResult = {
  action: string;
  parameters: {
    [key: string]: any;
  };
};

const getToken = () =>
  getAccessToken(
    config.dialogFlow.clientEmail,
    config.dialogFlow.privateKey,
    "https://www.googleapis.com/auth/dialogflow"
  );

export class DialogFlow {
  private sessionId: string;
  private projectName = config.dialogFlow.projectName;
  private token: string;

  constructor(sessionId: string) {
    this.sessionId = sessionId;
  }

  async detectIntent(phrase: string) {
    try {
      return await this.detectIntentRequest(phrase);
    } catch (error) {
      if (error.status === "UNAUTHENTICATED") {
        await this.refreshToken();
        return await this.detectIntentRequest(phrase);
      } else {
        throw error;
      }
    }
  }

  private async detectIntentRequest(phrase: string) {
    await this.ensureToken();
    const response = await fetch(
      `https://dialogflow.googleapis.com/v2/projects/${this.projectName}/agent/sessions/${this.sessionId}:detectIntent`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer "${this.token}"`,
        },
        body: JSON.stringify({
          query_input: {
            text: {
              text: phrase,
              language_code: "en-GB",
            },
          },
        }),
      }
    );
    const result = await response.json();
    if (result.error) {
      throw result.error;
    } else {
      return result.queryResult as QueryResult;
    }
  }

  private async ensureToken() {
    if (!this.token) {
      await this.refreshToken();
    }
  }

  private async refreshToken() {
    this.token = await getToken();
  }
}
