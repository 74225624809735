import React from "react";

type Props = { on: boolean };

const width = 52,
  height = 32,
  strokeWidth = 3;

const ovalBackground = (strokeWidth: number, fill: string) => (
  <rect
    transform={`translate(${strokeWidth}, ${strokeWidth})`}
    strokeOpacity="0.8"
    stroke="#F1F1F1"
    strokeWidth={strokeWidth}
    x={-strokeWidth / 2}
    y={-strokeWidth / 2}
    width={width - strokeWidth}
    height={height - strokeWidth}
    rx={(height - strokeWidth) / 2}
    fill={fill}
  />
);

export const Switch = ({ on }: Props) => {
  const offsetX = height / 2,
    cx = on ? width - offsetX : offsetX;
  return (
    <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`}>
      {on
        ? ovalBackground(0, "rgba(241,241,241,0.8)")
        : ovalBackground(strokeWidth, "rgba(0,0,0,0.2)")}
      <circle
        cx={cx}
        cy={height / 2}
        r={height / 2 - 2 * strokeWidth}
        fill={on ? "rgba(0,0,0,0.8)" : "#f1f1f1"}
      />
    </svg>
  );
};
