const env = process.env;

// --------- Helpers --------- //
const get = (name: string, { required }: { required?: boolean } = {}) => {
  const value = env[`REACT_APP_${name}`];
  if (required) {
    if (value === undefined || value === "") {
      throw new Error(`ENV variable REACT_APP_${name} needs to be set`);
    } else {
      return value as string;
    }
  }
  return value;
};

const bool = (name: string): boolean => {
  const value = get(name);
  return !!value && value.toLowerCase() !== "false" && value !== "0";
};

const ensureProperNewlines = (value: string | undefined) =>
  value ? value.split("\\n").join("\n") : undefined;

// --------- Config --------- //

const dfEnabled = bool("DIALOG_FLOW_ENABLED");

export const config = {
  pusher: {
    enabled: bool("PUSHER_KEY"),
    key: get("PUSHER_KEY"),
    logToConsole: bool("PUSHER_LOG_TO_CONSOLE"),
    authApi: get("PUSHER_AUTH_API"),
  },
  dialogFlow: {
    enabled: dfEnabled,
    projectName: get("DIALOG_FLOW_PROJECT_NAME", { required: dfEnabled }),
    clientEmail: get("DIALOG_FLOW_CLIENT_EMAIL", { required: dfEnabled }),
    privateKey: ensureProperNewlines(
      get("DIALOG_FLOW_PRIVATE_KEY", { required: dfEnabled })
    ),
  },
  logAnimations: bool("LOG_ANIMATIONS"),
};
