import { Rail } from "types";

export default {
  type: "rail",
  uuid: "079aa40c-edea-4ecd-bf86-7a3a7cee5f9f",
  template: "tile-cover",
  title: "Sky Box Sets",
  tiles: [
    {
      type: "coverTile",
      title: "Condor",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/0d5d53f3-97ad-43eb-8639-2b8383647913/cover/{height}",
      hero: {
        mode: "standard",
        title: "Condor",
        metadata: "1h 45m   ",
        synopsis:
          "Thriller based on the film Three Days of the Condor. A young CIA analyst has his idealism is tested when he stumbles onto a plan that could cost the lives of millions.\r",
        logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_skyone/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/0d5d53f3-97ad-43eb-8639-2b8383647913/background/{width}",
        heroTitleImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/af66932c4ae996a87192c808c3e8dc39/herotitle.png",
      },
    },
    {
      type: "coverTile",
      title: "Das Boot 2",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/4d18b5ea-a12a-4d26-b804-831775fb2301/cover/{height}",
      hero: {
        mode: "standard",
        title: "Das Boot 2",
        metadata: "1h 45m   ",
        synopsis:
          "The gripping World War II thriller returns with three tense storylines intertwining on both sides of the Atlantic, exploring how the human soul can become twisted by the experience of war.",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skyatlantic/408/84",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/e4f938f6a3a7c587f51e337cb82fb026/hero.jpg",
      },
    },
    {
      type: "coverTile",
      title: "Hillary",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/130de4a0-38db-4c87-93d8-1825f7fd42c1/cover/{height}",
      hero: {
        mode: "standard",
        title: "Hillary",
        metadata: "1h 45m   ",
        synopsis:
          "Four-part documentary series providing an intimate portrait of former first lady and Secretary of State Hillary Rodham Clinton, featuring exclusive footage from her 2016 presidential campaign.",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skydocumentaries/408/84",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/c46dd35ac05adb39f5f5eafb25a3c3f5/hero.jpg",
      },
    },
    {
      type: "coverTile",
      title: "Chernobyl",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/119a15a4-c006-4945-bce5-16fd7b9a284a/cover/{height}",
      hero: {
        mode: "standard",
        title: "Chernobyl",
        metadata: "1h 45m   ",
        synopsis:
          "BAFTA-nominated, Emmy Award-winning Sky original exploring the truth about the Chernobyl disaster. Jared Harris and Emily Watson star.",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skyatlantic/408/84",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/16f80e192a37c6b12fbbb0ab65a4a7bc/hero.jpg",
        heroTitleImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/ba75f7a07d9957a765098f1825517055/herotitle.png",
      },
    },
    {
      type: "coverTile",
      title: "The Night Of",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/06cc49cc-4993-4070-8a83-d85c26107b82/cover/{height}",
      hero: {
        mode: "standard",
        title: "The Night Of",
        metadata: "1h 45m   ",
        synopsis:
          "HBO's critically acclaimed crime drama starring Riz Ahmed and John Turturro. A spontaneous night out results in a promising student being implicated in a vicious murder.",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skyatlantic/408/84",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/0f9be9572e227469cf4e23e410b93335/hero.jpg",
        heroTitleImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/6d977381b64dd2e5b0fc9b54c11ffd8d/herotitle.png",
      },
    },
    {
      type: "coverTile",
      title: "Succession",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/6b0e0ac5-c207-489a-851c-f5d911e14c27/cover/{height}",
      hero: {
        mode: "standard",
        title: "Succession",
        metadata: "1h 45m   ",
        synopsis:
          "BAFTA-nominated bitingly funny drama starring Brian Cox. A family dynasty finds itself in meltdown when their patriarch refuses to name a successor.",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skyatlantic/408/84",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/5c25c2ed922cddc19c14a840082b6b63/hero.jpg",
        heroTitleImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/135a4cb4304da1c89730f7a96218f095/herotitle.png",
      },
    },
    {
      type: "coverTile",
      title: "War of the Worlds",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/e130317a-beb6-4149-877a-979fa5a59056/cover/{height}",
      hero: {
        mode: "standard",
        title: "War of the Worlds",
        metadata: "1h 45m   ",
        synopsis:
          "When astronomers detect a transmission from another galaxy, it is definitive proof of intelligent extra-terrestrial life. The world's population waits for further contact with bated breath.",
        logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_fox/408/84",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/c9ecd9579901b9228cae1ad29ff50a0e/hero.jpg",
      },
    },
    {
      type: "coverTile",
      title: "Brassic",
      imageUrl:
        "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/743c5c729f4a84a773e888bcf354508b/{width}/{height}/item.jpg",
      hero: {
        mode: "standard",
        title: "Brassic",
        metadata: "1h 45m   ",
        synopsis:
          "BAFTA-nominated Sky original with Joseph Gilgun and Michelle Keegan about a gang of mates who thieve, bribe and joyride their way through life.",
        logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_skyone/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/f55c0842-c232-4a4a-a854-5f49cfc75a9b/background/1428",
      },
    },
    {
      type: "coverTile",
      title: "Friday Night Dinner",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/b43fab6a-3948-4f4f-a31f-b61036344f54/cover/{height}",
      hero: {
        mode: "standard",
        title: "Friday Night Dinner",
        metadata: "1h 45m   ",
        synopsis:
          "British sitcom revolving around a family that always gathers on a Friday night to share a main meal. Starring Simon Bird as Adam and Tom Rosenthal as his brother Jonny. Series 6 added.",
        logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_all4/408/84",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/caae763338fe0dd19f0d0db486c3daf8/hero.jpg",
        heroTitleImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/aebead8a9540c906f809a4c282764bf4/herotitle.png",
      },
    },
    {
      type: "coverTile",
      title: "Euphoria",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/15dcc872-7089-4b69-bcd2-4276c0250948/cover/{height}",
      hero: {
        mode: "standard",
        title: "Euphoria",
        metadata: "1h 45m   ",
        synopsis:
          "BAFTA-nominated, no-holds-barred look at teenage life starring Zendaya as drug-addicted 17-year-old Rue. Buzzy drama from Sam Levinson.",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skyatlantic/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/15dcc872-7089-4b69-bcd2-4276c0250948/background/{width}",
        heroTitleImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/44f63e7b02d88ab5d7ac8338f60d3880/herotitle.png",
      },
    },
    {
      type: "titleTile",
      actionText: "View all",
      hero: {
        mode: "standard",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/15dcc872-7089-4b69-bcd2-4276c0250948/background/{width}",
      },
    },
  ],
} as Rail;
