import styled from "@emotion/styled";

export const Container = styled.div`
  position: relative;
`;

export const Row = styled.div({
  position: "relative",
  display: "flex",
  alignItems: "stretch",
  width: "9999em", // otherwise -webkit-mask clips at parent width
});
