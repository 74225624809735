import { Rail } from "types";

export default {
  type: "rail",
  uuid: "f9235252-44a3-477d-9452-85bb5fe994c5",
  template: "tile-landscape",
  title: "Last Night's TV",
  tiles: [
    {
      type: "landscapeTile",
      title: "A Deadly Union",
      imageUrl:
        "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/09c779c921394158e38bb2c7fef8a308/{width}/{height}/item.jpg",
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_channel4/408/84",
      hero: {
        mode: "standard",
        title: "A Deadly Union",
        synopsis:
          "Walter Presents: French mystery drama set on the stunning Riviera. Alice comes home for her sister Sandra's wedding. But during the day, Sandra vanishes. In French/subs.",
        heroTitleImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/a18cef273f90d100e3100c8cd0f15923/herotitle.png",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/7b1be518c9b8b39c349df35a14eca004/hero.jpg",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_channel4/408/84",
        metadata: "1h 45m   ",
      },
    },
    {
      type: "landscapeTile",
      title: "The Luminaries",
      imageUrl:
        "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/28703dbb96085e08d9d29b721b26f75d/{width}/{height}/item.jpg",
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_bbcone/408/84",
      hero: {
        mode: "standard",
        title: "The Luminaries",
        synopsis:
          "1/6. 1865. Anna and Emery meet on a voyage to New Zealand, but their plans to reunite are frustrated. ",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/bf5d5e0916df2480738c534899f33c67/hero.jpg",
        logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_bbcone/408/84",
        metadata: "1h 45m   ",
      },
    },
    {
      type: "landscapeTile",
      title: "Lost Beasts of the Ice Age",
      imageUrl:
        "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/c8dd18038e47e43baacfcef75165dd4a/{width}/{height}/item.jpg",
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_discovery/408/84",
      hero: {
        mode: "standard",
        title: "Lost Beasts of the Ice Age",
        synopsis:
          "Phil Torres and Dr. Tori Herridge go on a journey to Siberia for the biggest ever Ice Age dig, unearthing a treasure trove of animals buried in the ground for thousands of years.",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/6cd1f536de998949f7ec6c5512e2c3dd/hero.jpg",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_discovery/408/84",
        metadata: "1h 45m   ",
      },
    },
    {
      type: "landscapeTile",
      title: "Big Brother: Best Shows Ever",
      imageUrl:
        "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/932e1da2695740fee9d734fb0e5870c6/{width}/{height}/item.jpg",
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_e4/408/84",
      hero: {
        mode: "standard",
        title: "Big Brother: Best Shows Ever",
        synopsis:
          "Davina McCall and Rylan Clark-Neal revisit some of the juiciest and most memorable Big Brother and Celebrity Big Brother episodes of all time to celebrate the show's 20th anniversary. (Ep6)",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/956ed8e79c3e831de53cbfd09ceb18d0/hero.jpg",
        logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_e4/408/84",
        metadata: "1h 45m   ",
      },
    },
    {
      type: "landscapeTile",
      title: "Cosmos: Possible Worlds",
      imageUrl:
        "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/0319b6e8178d5b3f416f6791a1c96863/{width}/{height}/item.jpg",
      hero: {
        mode: "standard",
        title: "Cosmos: Possible Worlds",
        synopsis:
          "Seven Wonders of the New World: Head into the future and the 2039 NY World's Fair, where problems we currently think intractable have been plausibly solved. (S2, ep 13) ",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/cdf0b01725d3d05205d5fd2436553a31/hero.jpg",
        metadata: "1h 45m   ",
      },
    },
    {
      type: "landscapeTile",
      title: "The Jail: 60 Days In",
      imageUrl:
        "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/6dd0ac714e6b2ce9fe34b3985e40231c/{width}/{height}/item.jpg",
      hero: {
        mode: "standard",
        title: "The Jail: 60 Days In",
        synopsis:
          "Moment of Truth: As the end of the program draws closer, Sheriff Horton shakes things up for one last look at how far the jail has come. (S6, ep 16)",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/cd89d692c58b1671b1d91f754ca4e8cb/hero.jpg",
        metadata: "1h 45m   ",
      },
    },
    {
      type: "titleTile",
      actionText: "View all",
      hero: {
        mode: "standard",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/cd89d692c58b1671b1d91f754ca4e8cb/hero.jpg",
      },
    },
  ],
} as Rail;
