import { RailsPage } from "types";
import apps from "./apps";
import becauseYouWatchedTalesOfNy from "./becauseYouWatchedTalesOfNy";
import channels from "./channels";
import editorial from "./editorial";
import lastNightsTv from "./lastNightsTv";
import recordings from "./recordings";
import netflixOriginals from "./netflixOriginals";
import skyBoxSets from "./skyBoxSets";
import skyChannels from "./skyChannels";
import skyOriginals from "./skyOriginals";
import skyStore from "./skyStore";
import topPicks from "./topPicks";
import trending from "./trending";
import ultraHd from "./ultraHd";
import utils from "../sharedRails/utils";

export default {
  type: "railsPage",
  rails: [
    topPicks,
    lastNightsTv,
    trending,
    recordings,
    netflixOriginals,
    becauseYouWatchedTalesOfNy,
    skyOriginals,
    skyBoxSets,
    ultraHd,
    skyChannels,
    channels,
    editorial,
    apps,
    skyStore,
    utils,
  ],
  title: {
    text: "TV Shows",
  },
} as RailsPage;
