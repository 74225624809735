import styled from "@emotion/styled";
import { screen } from "constants/dimensions";
import { boolToInt } from "utils/boolToInt";

export const Container = styled.div`
  position: relative;
`;

export const PageWrapper = styled.div(
  ({ visible, x }: { visible: boolean; x: number }) => `
  opacity: ${boolToInt(visible)};
  position: absolute;
  top: 0;
  left: 0;
  width: ${screen.width}px;
  height: ${screen.height}px;
  transform-origin: center ${screen.height / 2}px;
  transform: translateX(${x}px);
`
);
