import styled from "@emotion/styled";
import { tiles } from "constants/dimensions";
import { tileShadow } from "constants/effects";

const { categoryTile } = tiles;

export const Container = styled.div(
  ({ focused }: { focused: boolean }) => `
  background:${focused ? "rgba(68,92,202,1.0)" : "rgba(241,241,241,0.04)"};
  box-shadow: ${tileShadow};
  height: ${categoryTile.height}px;
  width: ${categoryTile.width}px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding: 0 30px;
  border-radius: ${focused ? 0 : categoryTile.borderRadius}px;
  overflow: hidden;
`
);
