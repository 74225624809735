import { Rail } from "types";

export default {
  type: "rail",
  uuid: "c0157116-362f-446f-b979-2868be16dcaf",
  template: "tile-landscape",
  title: "Friday",
  tiles: [
    {
      type: "landscapeTile",
      title: "War of the Worlds",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/034b0fb6-e40e-4160-9182-0a46307948ec/16-9/{width}",
      hero: {
        mode: "standard",
        title: "War of the Worlds",
        metadata: "1h 45m   ",
        synopsis:
          "Tom Cruise stars in a remake of the 1953 adaptation of HG Wells's classic novel. Contains adult themes.",
        logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_bbcone/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/034b0fb6-e40e-4160-9182-0a46307948ec/background/{width}",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_bbcone/408/84",
    },
    {
      type: "landscapeTile",
      title: "War of the Worlds",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/034b0fb6-e40e-4160-9182-0a46307948ec/16-9/{width}",
      hero: {
        mode: "standard",
        title: "War of the Worlds",
        metadata: "1h 45m   ",
        synopsis:
          "Tom Cruise stars in a remake of the 1953 adaptation of HG Wells's classic novel. Contains adult themes.",
        logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_bbcone/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/034b0fb6-e40e-4160-9182-0a46307948ec/background/{width}",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_bbcone/408/84",
    },
    {
      type: "landscapeTile",
      title: "A Question of Sport",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/88861406-3dde-4382-86f5-76967bfe3738/16-9/{width}",
      hero: {
        mode: "standard",
        title: "A Question of Sport",
        metadata: "1h 45m   ",
        synopsis:
          "Episode 3. With Jack Butland, Denise Lewis, Barry McGuigan and snowboarder Katie Ormerod.",
        logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_bbcone/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/88861406-3dde-4382-86f5-76967bfe3738/background/{width}",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_bbcone/408/84",
    },
    {
      type: "landscapeTile",
      title: "War of the Worlds",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/034b0fb6-e40e-4160-9182-0a46307948ec/16-9/{width}",
      hero: {
        mode: "standard",
        title: "War of the Worlds",
        metadata: "1h 45m   ",
        synopsis:
          "Tom Cruise stars in a remake of the 1953 adaptation of HG Wells's classic novel. Contains adult themes.",
        logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_bbcone/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/034b0fb6-e40e-4160-9182-0a46307948ec/background/{width}",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_bbcone/408/84",
    },
    {
      type: "landscapeTile",
      title: "A Question of Sport",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/88861406-3dde-4382-86f5-76967bfe3738/16-9/{width}",
      hero: {
        mode: "standard",
        title: "A Question of Sport",
        metadata: "1h 45m   ",
        synopsis:
          "Episode 3. With Jack Butland, Denise Lewis, Barry McGuigan and snowboarder Katie Ormerod.",
        logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_bbcone/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/88861406-3dde-4382-86f5-76967bfe3738/background/{width}",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_bbcone/408/84",
    },
    {
      type: "landscapeTile",
      title: "Rhod Gilbert's Work Experience",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/cd7f2bd6-3844-4e0c-a9b6-1418cfd95cbe/16-9/{width}",
      hero: {
        mode: "standard",
        title: "Rhod Gilbert's Work Experience",
        metadata: "1h 45m   ",
        synopsis:
          "Road Worker. Comedian Rhod Gilbert joins a crew of road workers in South Wales.",
        logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_bbcone/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/cd7f2bd6-3844-4e0c-a9b6-1418cfd95cbe/background/{width}",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_bbcone/408/84",
    },
    {
      type: "landscapeTile",
      title: "Jack Whitehalls Fathers Day",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/c11ee01e-4f07-49ef-9a0a-07c07be2d0e7/16-9/{width}",
      hero: {
        mode: "standard",
        title: "Jack Whitehalls Fathers Day",
        metadata: "1h 45m   ",
        synopsis:
          "Jack spends a socially distanced Father's Day with his dad, Michael.",
        logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_bbcone/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/c11ee01e-4f07-49ef-9a0a-07c07be2d0e7/background/{width}",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_bbcone/408/84",
    },
    {
      type: "landscapeTile",
      title: "Not Going Out",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/fadbbeb5-63d5-4c13-878d-531ada41bb38/16-9/{width}",
      hero: {
        mode: "standard",
        title: "Not Going Out",
        metadata: "1h 45m   ",
        synopsis:
          "Whodunnit? Who has broken Lucys precious vase? Lee investigates.",
        logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_bbcone/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/fadbbeb5-63d5-4c13-878d-531ada41bb38/background/{width}",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_bbcone/408/84",
    },
    {
      type: "landscapeTile",
      title: "Keepin 'er Country",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/15058ac4-d161-4ec8-877a-762094b4681d/16-9/{width}",
      hero: {
        mode: "standard",
        title: "Keepin 'er Country",
        metadata: "1h 45m   ",
        synopsis:
          "Robert Mizzell. Robert Mizzell dons his signature Stetson for a very special performance from his own home.",
        logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_bbcone/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/15058ac4-d161-4ec8-877a-762094b4681d/background/{width}",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_bbcone/408/84",
    },
    {
      type: "landscapeTile",
      title: "A Question of Sport",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/88861406-3dde-4382-86f5-76967bfe3738/16-9/{width}",
      hero: {
        mode: "standard",
        title: "A Question of Sport",
        metadata: "1h 45m   ",
        synopsis:
          "Episode 3. With Jack Butland, Denise Lewis, Barry McGuigan and snowboarder Katie Ormerod.",
        logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_bbcone/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/88861406-3dde-4382-86f5-76967bfe3738/background/{width}",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_bbcone/408/84",
    },
  ],
} as Rail;
