import { put, delay, call } from "sagas/effects";
import { stores } from "stores";
import { SpecificAction } from "types/actions";

export function* shortcut({ key }: SpecificAction<"SHORTCUT">) {
  switch (key) {
    case "0":
      yield put({ type: "STANDBY.TOGGLE" });
      break;
    case "1":
      yield put({ type: "QUICK_ACCESS_MENU.HIDE" });
      yield put({ type: "CHANGE_MODE", mode: "glance" });
      yield delay(1000);
      yield put({ type: "QUICK_ACCESS_MENU.SHOW", tab: "landing" });
      yield put({ type: "STANDBY.OFF" });
      break;
    case "2":
      yield put({ type: "CHANGE_MODE", mode: "normal" });
      yield put({ type: "QUICK_ACCESS_MENU.HIDE" });
      yield put({ type: "STANDBY.OFF" });
      break;
    case "5":
      yield put({ type: "QUICK_ACCESS_MENU.SHOW", tab: "doorbellCanned" });
      yield put({ type: "PAUSE" });
      break;
    case "6":
      yield put({
        type: "SHOW_TOAST",
        message: "Hey Sky show me home energy",
        status: "success",
        quote: true,
        durationMs: 4000,
      });
      yield delay(3000);
      yield put({
        type: "GO_TO_PAGE",
        pageType: "videoPage",
        pageId: "smartDashboard",
      });
      break;
    case "7": {
      const lock = stores.frontDoorLock,
        locked = lock.on;
      yield put({
        type: "SHOW_TOAST",
        message: `${locked ? "Unlocking" : "Locking"} front door...`,
        status: "listening",
      });
      yield delay(2000);
      yield put({
        type: "SHOW_TOAST",
        message: `Front door ${locked ? "Unlocked" : "Locked"}`,
        status: "success",
        durationMs: 2000,
      });
      yield call([lock, "toggle"]);
      break;
    }
    case "8":
      yield put({ type: "DOORBELL.RING" });
      break;
    case "9":
      yield put({
        type: "SHOW_TOAST",
        message: "Hey Sky show me my cameras",
        status: "success",
        quote: true,
        durationMs: 3000,
      });
      yield delay(3000);
      yield put({ type: "QUICK_ACCESS_MENU.SHOW", tab: "cameras" });
      break;
  }
}
