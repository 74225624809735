import React, { useMemo } from "react";
import { GridPage as GridPageType } from "types";
import { GridTitle } from "./GridPage.styles";
import {
  gridPageToRailsPage,
  gridPageIndexToRailsPageIndices,
} from "./helpers";
import { RailsPage } from "components/pages/RailsPage";
import { tilesPerRail } from "selectors/gridPage";
import { Text } from "components/Text";

type Props = {
  page: GridPageType;
  index: number;
  focused: boolean;
};

export const GridPage = ({ page, index, focused }: Props) => {
  const numTilesPerRail = tilesPerRail(page),
    railsPage = useMemo(() => gridPageToRailsPage(page, numTilesPerRail), [
      page,
      numTilesPerRail,
    ]),
    [railsPageIndex, railsPageRailIndices] = gridPageIndexToRailsPageIndices(
      index,
      numTilesPerRail
    );
  return (
    <>
      <GridTitle>
        <Text size="medium" weight="medium">
          {page.gridTitle}
        </Text>
      </GridTitle>
      <RailsPage
        page={railsPage}
        index={railsPageIndex}
        railIndices={railsPageRailIndices}
        focused={focused}
      />
    </>
  );
};
