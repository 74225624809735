import { Rail } from "types";

export default {
  type: "rail",
  uuid: "6dec2237-e311-4cb0-8562-f7fcaa47989a",
  template: "tile-cover",
  title: "Movies",
  tiles: [
    {
      type: "coverTile",
      title: "Find Me in Paris",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/eb831685-0689-4331-a109-d282e0a0502a/cover/{height}",
      hero: {
        mode: "standard",
        title: "Find Me in Paris",
        metadata: "1h 45m   ",
        synopsis:
          "When Lena Grisky, a Russian Princess and student at the Paris Opera Ballet School accidentally time travels to present day, she must keep her secret and hide from the dangerous Time Collectors.\r",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/eb831685-0689-4331-a109-d282e0a0502a/background/{width}",
        heroTitleImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/f527bb96ca063cdbb12f58f0afff7cef/herotitle.png",
      },
    },
    {
      type: "coverTile",
      title: "The Athena",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/257d29be-1d9b-40b7-9f55-49ed609839ff/cover/{height}",
      hero: {
        mode: "standard",
        title: "The Athena",
        metadata: "1h 45m   ",
        synopsis:
          "The Athena is a new drama series charting the fortunes of a group of students at a prestigious college of visual arts in present-day London.",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/257d29be-1d9b-40b7-9f55-49ed609839ff/background/{width}",
      },
    },
    {
      type: "coverTile",
      title: "Hunter Street",
      imageUrl:
        "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/941a7037440c0680afa8bce40f719dd9/{width}/{height}/item.jpg",
      hero: {
        mode: "standard",
        title: "Hunter Street",
        metadata: "1h 45m   ",
        synopsis:
          "When Mr. and Mrs. Hunter go missing, it's up to their five foster children to unravel the mystery while keeping up appearances that everything's fine.\r",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/1981447a-57fd-4c30-9f39-fd85de0a7f6e/background/{width}",
      },
    },
    {
      type: "coverTile",
      title: "Raven's Home",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/b02ca73b-c717-4c29-8525-3da23f90d913/cover/{height}",
      hero: {
        mode: "standard",
        title: "Raven's Home",
        metadata: "1h 45m   ",
        synopsis:
          "The gang is pursuing its creative dreams! Booker, Nia and Tess form a band, while Raven is determined to launch her fashion line and Chelsea has found her niche as a life coach.\r",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/b02ca73b-c717-4c29-8525-3da23f90d913/background/{width}",
      },
    },
    {
      type: "coverTile",
      title: "School of Rock",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/19e27007-b4b4-4eee-8dac-bcc6d6c9e098/cover/{height}",
      hero: {
        mode: "standard",
        title: "School of Rock",
        metadata: "1h 45m   ",
        synopsis:
          "US comedy series based on the 2003 film. A down-on-his-luck musician poses as a teacher and inspires his students to create a secret rock band.\r",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/19e27007-b4b4-4eee-8dac-bcc6d6c9e098/background/{width}",
      },
    },
    {
      type: "coverTile",
      title: "Sydney to the Max",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/1a71e23d-f493-4ed3-8a6b-0ed6e8432cd1/cover/{height}",
      hero: {
        mode: "standard",
        title: "Sydney to the Max",
        metadata: "1h 45m   ",
        synopsis:
          "This entertaining comedy sees middle school girl Sydney Reynolds navigate the challenges of growing up in a house with three generations under one roof.",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/1a71e23d-f493-4ed3-8a6b-0ed6e8432cd1/background/{width}",
      },
    },
    {
      type: "coverTile",
      title: "Victorious",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/e5b0376b-f467-4334-9012-1c7bccbd956d/cover/{height}",
      hero: {
        mode: "standard",
        title: "Victorious",
        metadata: "1h 45m   ",
        synopsis:
          "Tori Vega is an ordinary girl, who one day gets invited to join the crazy crowd at the Hollywood Arts school for talented performers.",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/e5b0376b-f467-4334-9012-1c7bccbd956d/background/{width}",
      },
    },
    {
      type: "coverTile",
      title: "So Awkward",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/50fa891c-d38a-46ee-8615-3a5ec8db2d35/cover/{height}",
      hero: {
        mode: "standard",
        title: "So Awkward",
        metadata: "1h 45m   ",
        synopsis:
          "Drama series about three smart but socially awkward friends trying to make sense of life, boys and fitting in.",
        logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_bbc/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/50fa891c-d38a-46ee-8615-3a5ec8db2d35/background/{width}",
      },
    },
    {
      type: "coverTile",
      title: "The Dumping Ground",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/65bbda13-6006-4eed-be64-1238d0c0bad9/cover/{height}",
      hero: {
        mode: "standard",
        title: "The Dumping Ground",
        metadata: "1h 45m   ",
        synopsis:
          "Get all the drama from The Dumping Ground where literally anything can happen.",
        logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_bbc/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/65bbda13-6006-4eed-be64-1238d0c0bad9/background/{width}",
      },
    },
    {
      type: "titleTile",
      actionText: "View all",
      hero: {
        mode: "standard",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/65bbda13-6006-4eed-be64-1238d0c0bad9/background/{width}",
      },
    },
  ],
} as Rail;
