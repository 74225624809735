import { Action } from "types/actions";
import { PageType, PageId } from "types";
import {
  RailsPageState,
  initialState as initialRailsPageState,
  newPageState as newRailsPageState,
  railsPageReducer,
} from "reducers/browse/pages/rails";
import {
  GridPageState,
  newPageState as newGridPageState,
  gridPageReducer,
} from "reducers/browse/pages/grid";
import {
  ImagePageState,
  newPageState as newImagePageState,
} from "reducers/browse/pages/image";
import {
  VideoPageState,
  newPageState as newVideoPageState,
} from "reducers/browse/pages/video";
import {
  TVGuidePageState,
  newPageState as newTVGuidePageState,
  tvGuidePageReducer,
} from "reducers/browse/pages/tvGuide";

export type PageState =
  | RailsPageState
  | GridPageState
  | ImagePageState
  | VideoPageState
  | TVGuidePageState;

const newPageState = (pageType: PageType, pageId: PageId) => {
  switch (pageType) {
    case "railsPage":
      return newRailsPageState(pageId);
    case "gridPage":
      return newGridPageState(pageId);
    case "imagePage":
      return newImagePageState(pageId);
    case "videoPage":
      return newVideoPageState(pageId);
    case "tvGuidePage":
      return newTVGuidePageState(pageId);
  }
};

export type BrowseState = {
  pageState: PageState;
  pageStateHistory: PageState[];
  showPageTitleInHero: boolean;
  includeCurrentPageInHistory: boolean;
  showTvDays: boolean;
};

export const initialBrowseState: BrowseState = {
  pageState: initialRailsPageState,
  pageStateHistory: [],
  showPageTitleInHero: false,
  includeCurrentPageInHistory: true,
  showTvDays: false,
};

const applyPageReducer = <TPageState>(
  reducer: (state: TPageState, action: Action) => TPageState,
  state: BrowseState,
  action: Action
) => ({
  ...state,
  pageState: reducer((state.pageState as unknown) as TPageState, action),
});

export const browseReducer = (
  state: BrowseState = initialBrowseState,
  action: Action
): BrowseState => {
  if (action.type.match(/^RAILS\./)) {
    return applyPageReducer(railsPageReducer, state, action);
  } else if (action.type.match(/^GRID\./)) {
    return applyPageReducer(gridPageReducer, state, action);
  } else if (action.type.match(/^TVGUIDE\./)) {
    return applyPageReducer(tvGuidePageReducer, state, action);
  }

  switch (action.type) {
    case "BROWSE_BACK": {
      if (state.pageStateHistory.length) {
        const pageStateHistory = [...state.pageStateHistory],
          pageState = pageStateHistory.pop();
        return {
          ...state,
          pageStateHistory,
          pageState,
        };
      } else {
        return state;
      }
    }

    case "BROWSE_FORWARD": {
      const pageStateHistory = state.includeCurrentPageInHistory
        ? [...state.pageStateHistory, state.pageState]
        : state.pageStateHistory;
      return {
        ...state,
        pageState: newPageState(action.pageType, action.pageId),
        pageStateHistory,
        includeCurrentPageInHistory: action.includeInHistory,
      };
    }

    case "BROWSE_REPLACE_PAGE": {
      return {
        ...state,
        pageState: newPageState(action.pageType, action.pageId),
      };
    }

    case "BROWSE_INCLUDE_CURRENT_PAGE_IN_HISTORY":
      return { ...state, includeCurrentPageInHistory: true };

    case "BROWSE_RESET":
      return {
        ...state,
        pageState: initialRailsPageState,
        pageStateHistory: [],
      };

    case "BROWSE.SHOW_PAGE_TITLE_IN_HERO":
      return { ...state, showPageTitleInHero: true };

    case "BROWSE.HIDE_PAGE_TITLE_IN_HERO":
      return { ...state, showPageTitleInHero: false };

    case "BROWSE.SHOW_TV_DAYS":
      return { ...state, showTvDays: true };

    case "BROWSE.HIDE_TV_DAYS":
      return { ...state, showTvDays: false };

    default:
      return state;
  }
};
