export function generateChars(
  n: number,
  withNumbers: boolean = true,
  lower: boolean = true
): string {
  let str = "";
  const randomFunc: Function = withNumbers ? randomDigitOrLetter : randomLetter;
  for (let i: number = 0; i < n; i++) {
    str += randomFunc(lower);
  }
  return str;
}

export function randomDigitOrLetter(lower: boolean): string {
  const alphaBase = lower ? 97 : 65;
  const rnd: number = Math.random() * 36;
  const code = rnd < 26 ? rnd + alphaBase : rnd - 26 + 48;
  return String.fromCharCode(code);
}

export function randomLetter(lower:boolean): string {
  const alphaBase = lower ? 97 : 65;
  const rnd: number = Math.random() * 26;
  const code = rnd + alphaBase;
  return String.fromCharCode(code);
}
