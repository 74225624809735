import { State } from "reducers";
import { TVGuidePageState } from "reducers/browse/pages/tvGuide";
import { Hero, TvGuidePageId } from "../../types";
import { getPage } from "../../data/pages";
import { slotIndexToDate } from "helpers/tvGuideHelpers";
import { pageOrder } from "data/pages/tvGuide";
import { tvDaysIsShowing } from "selectors/browse";

const pageState = ({ browse: { pageState } }: State): TVGuidePageState => {
  if (pageState.pageType !== "tvGuidePage") {
    throw new Error(
      `Expected page type to be tvGuidePage, not ${pageState.pageType}`
    );
  }
  return pageState;
};

const page = (state: State) => {
  const { pageId, pageType } = pageState(state);
  return getPage(pageId, pageType);
};

export const channelIndex = (state: State) => pageState(state).channelIndex;

export const onFirstChannel = (state: State) =>
  pageState(state).channelIndex === 0;

export const onLastChannel = (state: State) => {
  const { channelIndex, schedule } = pageState(state);
  return schedule && channelIndex === schedule.channels.length - 1;
};

export const onFirstTile = (state: State) => pageState(state).tileIndex === 0;

export const selectedEvent = (state: State) => {
  const { schedule, eventIndex, channelIndex } = pageState(state);
  if (!schedule) return;
  return schedule.channels[channelIndex].events[eventIndex];
};

const selectedChannel = (state: State) => {
  const { channelIndex, schedule } = pageState(state);
  if (!schedule) return;
  return schedule.channels[channelIndex].channel;
};

export const onNowEvent = (state: State) => {
  const { onNowLookup } = pageState(state),
    channel = selectedChannel(state);
  return onNowLookup[channel?.id];
};

export const focusArea = (state: State) => pageState(state).focusArea;

export const overrideHeroFields = (state: State): Partial<Hero> | undefined =>
  pageState(state).overrideHeroFields;

export const pageHero = (state: State): Hero =>
  pageState(state).hero || { mode: "none" };

export const railHero = (state: State): Hero => {
  const { tileIndex } = pageState(state);
  const { rail } = page(state);
  return rail.tiles[tileIndex]?.hero || { mode: "none" };
};

export const selectedDate = (state: State) => {
  const { midnight, slotIndex } = pageState(state);
  return slotIndexToDate(slotIndex, midnight);
};

export const previousSectionPageId = (state: State) => {
  const index = tvGuidePageIndex(state);
  switch (index) {
    case -1:
      return;
    case 0:
      return pageOrder[pageOrder.length - 1];
    default:
      return pageOrder[index - 1];
  }
};

export const nextSectionPageId = (state: State) => {
  const index = tvGuidePageIndex(state);
  switch (index) {
    case -1:
      return;
    case pageOrder.length - 1:
      return pageOrder[0];
    default:
      return pageOrder[index + 1];
  }
};

export const tvGuidePageIndex = (state: State) => {
  return pageOrder.indexOf(state.browse.pageState.pageId as TvGuidePageId);
};

export const overlayIsShowing = (state: State) => tvDaysIsShowing(state);
