import { Rail } from "types";

export default {
  type: "rail",
  uuid: "abccaeaf-b52f-427d-8df2-3edd4a43d113",
  template: "tile-cover",
  title: "Sky Originals",
  tiles: [
    {
      type: "coverTile",
      title: "My Brilliant Friend",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/18d4f8a1-b47c-4096-8ec3-524d019bb196/cover/{height}",
      hero: {
        mode: "standard",
        title: "My Brilliant Friend",
        metadata: "1h 45m   ",
        synopsis:
          "Elena Ferrante's Neapolitan Novels are adapted for the screen in HBO's Italian drama. A writer recounts her decades-long relationship with Lila, her best friend, and often worst enemy. Season 2 added.\r",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skyatlantic/408/84",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/68d3008913c8dbe51bf2b8f50643931c/hero.jpg",
        heroTitleImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/57cc32d36e1f0c884ce03c7957ef7885/herotitle.png",
      },
    },
    {
      type: "coverTile",
      title: "Project Blue Book",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/da73f40d-b7be-4537-8928-3951202efa9b/cover/{height}",
      hero: {
        mode: "standard",
        title: "Project Blue Book",
        metadata: "1h 45m   ",
        synopsis:
          "In this conspiratorial Sci-Fi thriller set some time after the Second World War, a tough US Air Force officer and a scientist investigate alien conspiracies as Cold War paranoia spreads. Season 2 added.",
        logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_syfy/408/84",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/54e43bea4f59679927dba5840b41a6cf/hero.jpg",
        heroTitleImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/4942bb3521db70848e8afc68db545db1/herotitle.png",
      },
    },
    {
      type: "coverTile",
      title: "Insecure",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/ca50b890-3b93-4d26-b04b-29da6e3bd619/cover/{height}",
      hero: {
        mode: "standard",
        title: "Insecure",
        metadata: "1h 45m   ",
        synopsis:
          "Created by and starring Issa Rae, Insecure looks at the friendship of two modern-day women, as well as all their uncomfortable experiences and racy tribulations. ",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycomedy/408/84",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/f69993093f61250572f502252c598656/hero.jpg",
        heroTitleImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/5f701419cb3456ad2519f17c77e103cb/herotitle.png",
      },
    },
    {
      type: "coverTile",
      title: "Catastrophe",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/3c17943e-2de6-4f8b-92f6-a6d325d6eefa/cover/{height}",
      hero: {
        mode: "standard",
        title: "Catastrophe",
        metadata: "1h 45m   ",
        synopsis:
          "Sharon Horgan and Rob Delaney’s BAFTA-nominated comedy series about a mismatched couple who try to navigate a proper relationship after a one-night stand results in pregnancy. ",
        logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_all4/408/84",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/2f07e6ad603fb208330fbce63e5545a9/hero.jpg",
        heroTitleImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/c93ace6aaa90a93d95488e60fd97e0e4/herotitle.png",
      },
    },
    {
      type: "coverTile",
      title: "Spides",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/75435b04-c298-4b90-b5a6-d17340a76d73/cover/{height}",
      hero: {
        mode: "standard",
        title: "Spides",
        metadata: "1h 45m   ",
        synopsis:
          "A spate of murders and disappearances appear to be connected to the arrival of a new club drug in Berlin. Sci-fi drama starring Rosabell Laurenti Sellers.\r",
        logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_syfy/408/84",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/6561c7f2856bd71a70ac7ab43256c29f/hero.jpg",
        heroTitleImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/e35567b4cf65587eec57d81c74131f70/herotitle.png",
      },
    },
    {
      type: "coverTile",
      title: "Micro Monsters with...",
      imageUrl:
        "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/e800c13381c27b37b948ade46902665c/{width}/{height}/item.jpg",
      hero: {
        mode: "standard",
        title: "Micro Monsters with...",
        metadata: "1h 45m   ",
        synopsis:
          "...David Attenborough. Sir David Attenborough investigates the weird and wonderful lives of creepy-crawlies in this close-up look at the microscopic world of bugs.\r",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/67566a32f1bc674b5304d605388e6216/hero.jpg",
      },
    },
    {
      type: "coverTile",
      title: "Unidentified",
      imageUrl:
        "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/4b6a741ff7de3f1b81d30769028f8f98/{width}/{height}/item.jpg",
      hero: {
        mode: "standard",
        title: "Unidentified",
        metadata: "1h 45m   ",
        synopsis:
          "Groundbreaking docuseries lifting the lid on fascinating new UFO investigations. With evidence and footage of encounters, and insight from a former military intelligence official.\r",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skyhistory/408/84",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/96885d21fe954cd55a3341c5b2521af0/hero.jpg",
      },
    },
    {
      type: "coverTile",
      title: "FBI",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/3d8ef760-059e-4e86-be16-3d1aad5bd7d7/cover/{height}",
      hero: {
        mode: "standard",
        title: "FBI",
        metadata: "1h 45m   ",
        synopsis:
          "From the Emmy Award-winning creator of Law and Order comes this exhilarating crime drama about a team of FBI agents fighting to keep New York safe.\r",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skywitness/408/84",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/0be970fa65110d53fc6e858a1c3c2e98/hero.jpg",
        heroTitleImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/ae13689cf2fea8be9d1a4565b4117e6d/herotitle.png",
      },
    },
    {
      type: "coverTile",
      title: "Younger",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/da7727e4-6c8b-4e96-9e91-76bb706c3315/cover/{height}",
      hero: {
        mode: "standard",
        title: "Younger",
        metadata: "1h 45m   ",
        synopsis:
          "A 40-year-old single mum must juggle her real life and her pretend life with her young co-workers after she claims to be only 26 in order to secure her dream job in the publishing world. Season 2 added.",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_comedycentral/408/84",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/5912b262f0624b1db30733077e6373aa/hero.jpg",
      },
    },
    {
      type: "coverTile",
      title: "Betty",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/01810e1c-236f-46d5-b4e5-c0b1acb51eb6/cover/{height}",
      hero: {
        mode: "standard",
        title: "Betty",
        metadata: "1h 45m   ",
        synopsis:
          "Comedy following a diverse group of young women as they navigate life through the challenging, male-dominated world of skateboarding in New York City.\r",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycomedy/408/84",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/58f3e4a2d3e4c0ad32fbfd4417822960/hero.jpg",
        heroTitleImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/33d2e11af8b11bdc833ca772039ce916/herotitle.png",
      },
    },
    {
      type: "titleTile",
      actionText: "View all",
      hero: {
        mode: "standard",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/58f3e4a2d3e4c0ad32fbfd4417822960/hero.jpg",
      },
    },
  ],
} as Rail;
