import { Rail } from "types";

export default {
  type: "rail",
  uuid: "c46f138a-75bd-4ac3-87c4-e94e0010d069",
  template: "tile-cover",
  title: "Crime",
  tiles: [
    {
      type: "coverTile",
      title: "The Kitchen",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/13d760a2-5e75-4b43-8a27-ba26b6296f16/cover/{height}",
      hero: {
        mode: "standard",
        title: "The Kitchen",
        metadata: "1h 45m   ",
        synopsis:
          "In 1970s New York, three mob wives take business into their own hands when their husbands are sent to prison. Melissa McCarthy, Tiffany Haddish and Elisabeth Moss star. (2019)(103 mins) Also in UHD.",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/13d760a2-5e75-4b43-8a27-ba26b6296f16/background/{width}",
      },
    },
    {
      type: "coverTile",
      title: "Widows",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/c0d3a064-96f7-4253-81ed-2679b9ffda79/cover/{height}",
      hero: {
        mode: "standard",
        title: "Widows",
        metadata: "1h 45m   ",
        synopsis:
          "A group of widows linked by their husbands' debts team up to pull off a huge heist. From award-winning director Steve McQueen. With Viola Davis and Michelle Rodriguez. (2018)(128 mins) Also in UHD.",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/c0d3a064-96f7-4253-81ed-2679b9ffda79/background/{width}",
      },
    },
    {
      type: "coverTile",
      title: "No Country For Old Men",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/52a6b9ce-6cc3-4d27-856f-d6de22e6ba37/cover/{height}",
      hero: {
        mode: "standard",
        title: "No Country For Old Men",
        metadata: "1h 45m   ",
        synopsis:
          "After stealing $2million in drug money, Josh Brolin is hunted by ruthless psychotic Javier Bardem. Oscar-winning crime thriller. Strong bloody violence/strong language. (2007)(118 mins)",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/52a6b9ce-6cc3-4d27-856f-d6de22e6ba37/background/{width}",
      },
    },
    {
      type: "coverTile",
      title: "O Brother, Where Art Thou?",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/07a07142-87fd-4f80-85f0-9ba6cdad2d88/cover/{height}",
      hero: {
        mode: "standard",
        title: "O Brother, Where Art Thou?",
        metadata: "1h 45m   ",
        synopsis:
          "George Clooney and two pals escape the chain gang and go on a quest for treasure in 1930s Mississippi. From the Coen brothers. Strong language/violence/mature themes. (2000)(103 mins)",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/07a07142-87fd-4f80-85f0-9ba6cdad2d88/background/{width}",
      },
    },
    {
      type: "coverTile",
      title: "The Sisters Brothers",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/89e0b57e-e1bf-4a41-94bb-30e033432167/cover/{height}",
      hero: {
        mode: "standard",
        title: "The Sisters Brothers",
        metadata: "1h 45m   ",
        synopsis:
          "Dark western starring Jake Gyllenhaal and Riz Ahmed as two men who embark on a powerful journey while on the run from assassin brothers John C Reilly and Joaquin Phoenix. (2018)(121 mins)",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/89e0b57e-e1bf-4a41-94bb-30e033432167/background/{width}",
      },
    },
    {
      type: "coverTile",
      title: "The Mule",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/f42f0512-34ca-4f9a-aec6-527018365a02/cover/{height}",
      hero: {
        mode: "standard",
        title: "The Mule",
        metadata: "1h 45m   ",
        synopsis:
          "An elderly man winds up working as a drug courier for a Mexican cartel. Oscar winner Clint Eastwood directs and stars, alongside Bradley Cooper and Laurence Fishburne. (2018)(116 mins)Enhanced for UHD",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/f42f0512-34ca-4f9a-aec6-527018365a02/background/{width}",
      },
    },
    {
      type: "coverTile",
      title: "Captain Phillips",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/e75c1ba4-897b-4c0e-af87-119880a5124f/cover/{height}",
      hero: {
        mode: "standard",
        title: "Captain Phillips",
        metadata: "1h 45m   ",
        synopsis:
          "Tom Hanks stars as the captain of a container ship that is hijacked by Somali pirates in this Oscar-nominated thriller. Violence/flashing images. (2013)(129 mins) Also in UHD.",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/e75c1ba4-897b-4c0e-af87-119880a5124f/background/{width}",
      },
    },
    {
      type: "coverTile",
      title: "Zero Dark Thirty",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/ab5097a4-7893-4e3d-b61b-dfb2fdbefa15/cover/{height}",
      hero: {
        mode: "standard",
        title: "Zero Dark Thirty",
        metadata: "1h 45m   ",
        synopsis:
          "CIA officer Jessica Chastain leads the US hunt for Osama Bin Laden in this gripping Oscar-winning drama. Strong language/mature themes/violence/flashing images. (2012)(151 mins)",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/ab5097a4-7893-4e3d-b61b-dfb2fdbefa15/background/{width}",
      },
    },
    {
      type: "coverTile",
      title: "The Bourne Collection",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/5dde8576-9533-4d50-a061-08a3dea9bf49/cover/{height}",
      hero: {
        mode: "standard",
        title: "The Bourne Collection",
        metadata: "1h 45m   ",
        synopsis:
          "From the mind of Robert Ludlum, enjoy the original Bourne trilogy starring Matt Damon and The Bourne Legacy with Jeremy Renner.",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/5dde8576-9533-4d50-a061-08a3dea9bf49/background/{width}",
      },
    },
    {
      type: "coverTile",
      title: "Enemy Of The State",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/21309912-08ab-4344-8766-add33cb7c301/cover/{height}",
      hero: {
        mode: "standard",
        title: "Enemy Of The State",
        metadata: "1h 45m   ",
        synopsis:
          "Tony Scott's breathless conspiracy thriller stars Will Smith as a lawyer being hunted by shady government operatives. With Gene Hackman and Jon Voight. (1998)(128 mins)",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/21309912-08ab-4344-8766-add33cb7c301/background/{width}",
      },
    },
  ],
} as Rail;
