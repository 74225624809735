import { call, select, takeEvery } from "sagas/effects";
import { localStore } from "utils/localStore";

function* saveToLocalStore() {
  const settings = yield select(s => s.settings);
  yield call(localStore.saveSettings, settings);
}

export function* settingsSaga() {
  yield takeEvery("SETTINGS.UPDATE", saveToLocalStore);
}
