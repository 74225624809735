import React from "react";
import styled from "@emotion/styled";
import { ImagePage as ImagePageType } from "types";

type Props = {
  page: ImagePageType;
};

const Image = styled.img`
  width: 100%;
`;

export const ImagePage = ({ page }) => {
  return <Image src={page.url} />;
};
