export class SimpleCache<T> {
  private results = new Map<string, T>();

  async call(key: string, func: () => Promise<T> | T): Promise<T> {
    if (this.results.has(key)) {
      return this.results.get(key);
    } else {
      const result = await func();
      this.results.set(key, result);
      return result;
    }
  }
}
