import React from "react";
//import { setIn } from "lodash-redux-immutability";
import {
  Container,
  Row,
  Column,
  Select,
  Option,
  Dd,
  Dt,
  Dl,
  Ul,
  Li,
  InputCheckbox,
  //InputNumber,
} from "./Settings.styles";
import { Modal } from "components/Modal";
import {
  SettingsState,
  pageTitlePositions,
  backBehaviours,
} from "reducers/settings";
//import { buttonPressTypes } from "services/keyListener";
import RoomForm from "../RoomForm/RoomFormController";
import { RemoteControlFormController } from "../RemoteControlForm";

export type Props = {
  open: boolean;
  settings: SettingsState;
  onUpdateSettings: (settings: Partial<SettingsState>) => void;
  onClose?: () => void;
};

const Dropdown = <
  Value extends string | number,
  Options extends Readonly<Value[]>
>({
  value,
  options,
  onChange,
  inline,
}: {
  value: Value;
  options: Options;
  onChange: (val: Value) => void;
  inline?: boolean;
}) => (
  <Select
    inline={inline}
    value={value}
    onChange={evt => onChange(evt.target.value as Options[number])}
  >
    {options.map(val => (
      <Option key={val} value={val}>
        {val}
      </Option>
    ))}
  </Select>
);

const Checkbox = ({
  value,
  onChange,
}: {
  value: boolean;
  onChange: (val: boolean) => void;
}) => (
  <InputCheckbox
    type="checkbox"
    checked={value}
    onChange={evt => onChange(evt.target.checked)}
  />
);

export const Settings = ({
  open,
  settings,
  onUpdateSettings,
  onClose,
}: Props) => {
  return (
    <Modal open={open} onClose={onClose}>
      <Container>
        <h2>Settings</h2>
        <Row>
          <Column>
            <h3>List of Key Commands</h3>
            <Row>
              <Dl>
                <Dt>&larr;</Dt>
                <Dd>Left</Dd>
                <Dt>&rarr;</Dt>
                <Dd>Right</Dd>
                <Dt>&uarr;</Dt>
                <Dd>Up</Dd>
                <Dt>&darr;</Dt>
                <Dd>Down</Dd>
                <Dt>Enter</Dt>
                <Dd>Select</Dd>
                <Dt>h</Dt>
                <Dd>Home</Dd>
                <Dt>b</Dt>
                <Dd>Back</Dd>
                <Dt>u</Dt>
                <Dd>Volume Up</Dd>
                <Dt>d</Dt>
                <Dd>Volume Down</Dd>
                <Dt>a</Dt>
                <Dd>Show/hide Quick Access Menu</Dd>
                <Dt>s</Dt>
                <Dd>Show/hide this settings modal</Dd>
                <Dt>q / </Dt>
                <Dd>Toggle standby mode</Dd>
              </Dl>
              <Dl>
                <Dt>0</Dt>
                <Dd>Toggle standby mode</Dd>
                <Dt>1</Dt>
                <Dd>Glance with QAM landing screen</Dd>
                <Dt>2</Dt>
                <Dd>Standard prototype (not glance)</Dd>
                <Dt>6</Dt>
                <Dd>Emulate "show smart dashboard" voice command</Dd>
                <Dt>7</Dt>
                <Dd>Show 'Say "It's me" ...' text</Dd>
                <Dt>8</Dt>
                <Dd>Emulate doorbell</Dd>
                <Dt>9</Dt>
                <Dd>Emulate "show cameras" voice command</Dd>
              </Dl>
            </Row>
          </Column>
          <Column>
            <h3>Example Voice Commands</h3>
            <Ul>
              <Li>"Hello Sky" - wake and listen to further commands</Li>
              <Li>
                "Go to TV shows", "Go to the TV page", "Open the Movies page"
                and similar
              </Li>
              <Li>
                "Turn the volume down", "Volume up", "Volume down by 20",
                "quieter", etc.
              </Li>
              <Li>"Mute", "unmute", "mute the volume", "be quiet", etc.</Li>
              <Li>"Play the Reluctant Landlord", "Play The Capture", etc.</Li>
              <Li>"Go back"</Li>
            </Ul>
          </Column>
        </Row>
        <Row>
          <Column>
            <h3>Video Comms</h3>
            <RoomForm />
          </Column>
          <Column>
            <h3>Remote Control</h3>
            <RemoteControlFormController />
          </Column>
        </Row>

        <Row>
          <Column>
            <h3>Page Title position</h3>
            <Dropdown
              value={settings.pageTitlePosition}
              options={pageTitlePositions}
              onChange={pageTitlePosition =>
                onUpdateSettings({ pageTitlePosition })
              }
            />
          </Column>
          <Column>
            <h3>Back behaviour</h3>
            <Dropdown
              value={settings.backBehaviour}
              options={backBehaviours}
              onChange={backBehaviour => onUpdateSettings({ backBehaviour })}
            />
          </Column>
          <Column>
            <h3>Enable Browse Tabs</h3>
            <Checkbox
              value={settings.enableBrowseTabs}
              onChange={enableBrowseTabs =>
                onUpdateSettings({ enableBrowseTabs })
              }
            />

            <h3>Enable Autoresize</h3>
            <Checkbox
              value={settings.enableAutoResize}
              onChange={enableAutoResize =>
                onUpdateSettings({ enableAutoResize })
              }
            />

            <h3>Auto-hide TV Categories</h3>
            <Checkbox
              value={settings.autoHideTvCategories}
              onChange={autoHideTvCategories =>
                onUpdateSettings({ autoHideTvCategories })
              }
            />
          </Column>
        </Row>
      </Container>
    </Modal>
  );
};
