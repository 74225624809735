import React, { useState } from "react";
import { RemoteControlForm } from "./RemoteControlForm";
import RemoteControlService from "../../services/RemoteControlService";
import { observer } from "mobx-react-lite";

type ControllerProps = { remoteControlService: RemoteControlService };

const FormObservable = observer(({ remoteControlService }: ControllerProps) => {
  const [isConnectEnabled, setIsConnectEnabled] = useState(false);
  const [remoteId, setRemoteId] = useState(remoteControlService.remoteId);
  const url = `${process.env.REACT_APP_REMOTE_CONTROL_URL}?remoteId=${remoteId}`;

  function handleRemoteIdChange(value: string) {
    setRemoteId(value);
  }

  function handleDisconnectCallback() {
    setIsConnectEnabled(true);
  }

  function handleConnectCallback() {
    setIsConnectEnabled(false);
    RemoteControlService.instance.connectToChannel(remoteId);
  }

  return (
    <RemoteControlForm
      status={isConnectEnabled ? "disconnected" : "connected"}
      initialRemoteId={remoteId}
      remoteIdCallback={handleRemoteIdChange}
      remoteControlUrl={url}
      isConnectEnabled={isConnectEnabled}
      connectCallback={handleConnectCallback}
      disconnectCallback={handleDisconnectCallback}
    />
  );
});

export const RemoteControlFormController = () => (
  <FormObservable remoteControlService={RemoteControlService.instance} />
);
