import { Rail } from "types";

export default {
  type: "rail",
  uuid: "7c6ff6a7-6eb6-4cd5-b246-5478c1a401af",
  template: "tile-landscape",
  title: "My List",
  tiles: [
    {
      type: "titleTile",
      title: "My List",
      actionText: "View All",
      hero: {
        mode: "standard",
        title: "My List",
        synopsis: "Your watchlist of shows and movies",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/e0df652acfe06a7976cd633724e1c52d/hero.jpg",
      },
    },
    {
      type: "landscapeTile",
      title: "Irene's Ghost",
      imageUrl:
        "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/2be1864bdfa9acd4cb90de457284cda1/{width}/{height}/item.jpg",
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_skydocumentaries/408/84",
      hero: {
        mode: "standard",
        title: "Irene's Ghost",
        metadata: "1h 45m   ",
        synopsis:
          "Follow filmmaker Iain Cunningham's emotional search to find out about the mother he never knew, as he tracks down her friends and family to build a picture of her.",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skydocumentaries/408/84",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/e0df652acfe06a7976cd633724e1c52d/hero.jpg",
        heroTitleImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/236488492a0cd494911e2610885af3ac/herotitle.png",
      },
    },
    {
      type: "landscapeTile",
      title: "Lost Beasts of the Ice Age",
      imageUrl:
        "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/c8dd18038e47e43baacfcef75165dd4a/{width}/{height}/item.jpg",
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_discovery/408/84",
      hero: {
        mode: "standard",
        title: "Lost Beasts of the Ice Age",
        metadata: "1h 45m   ",
        synopsis:
          "Phil Torres and Dr Tori Herridge head on a journey to the remote reaches of Siberia for the biggest-ever Ice Age dig, unearthing a treasure trove of animals buried frozen in the ground for millennia. ",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_discovery/408/84",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/6cd1f536de998949f7ec6c5512e2c3dd/hero.jpg",
      },
    },
    {
      type: "landscapeTile",
      title: "I Am Not Your Negro",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/41a1b11a-a97e-465a-bcba-218a4d45d9bb/16-9/{width}",
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_bbctwo/408/84",
      hero: {
        mode: "standard",
        title: "I Am Not Your Negro",
        metadata: "1h 45m   ",
        synopsis:
          "Acclaimed documentary using the words of James Baldwin to reflect on the black experience in America and the lives of Medgar Evers, Martin Luther King Jr and Malcolm X. Samuel L Jackson narrates.",
        logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_bbctwo/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/41a1b11a-a97e-465a-bcba-218a4d45d9bb/background/{width}",
      },
    },
    {
      type: "landscapeTile",
      title: "Secret Wars Uncovered",
      imageUrl:
        "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/666bf895f8a82adcc5c4658d5a8f98ef/{width}/{height}/item.jpg",
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_skyhistory/408/84",
      hero: {
        mode: "standard",
        title: "Secret Wars Uncovered",
        metadata: "1h 45m   ",
        synopsis:
          "The shocking facts behind battles that were fought covertly since the Second World War.",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skyhistory/408/84",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/1ea5e07d15ae9b4f03ec49799efd763f/hero.jpg",
      },
    },
    {
      type: "landscapeTile",
      title: "8 Minutes And 46 Seconds...",
      imageUrl:
        "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/73ed5030198a0c2d0add06245a352ce2/{width}/{height}/item.jpg",
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_skydocumentaries/408/84",
      hero: {
        mode: "standard",
        title: "8 Minutes And 46 Seconds...",
        metadata: "1h 45m   ",
        synopsis:
          "...The Killing of George Floyd. He died after being pinned down to the ground with an officer's knee on his neck, sparking outrage and a turning point for the anti-racism movement.",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skydocumentaries/408/84",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/24e037f67d32b33883134f66cd58d55f/hero.jpg",
        heroTitleImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/497a3c6cb6ee134c49e616677b052aaf/herotitle.png",
      },
    },
    {
      type: "landscapeTile",
      title: "Hillary",
      imageUrl:
        "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/4017ff1b1f2fea10dd35903b64822e68/{width}/{height}/item.jpg",
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_skydocumentaries/408/84",
      hero: {
        mode: "standard",
        title: "Hillary",
        metadata: "1h 45m   ",
        synopsis:
          "Four-part documentary series providing an intimate portrait of former first lady and Secretary of State Hillary Rodham Clinton, featuring exclusive footage from her 2016 presidential campaign.\r",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skydocumentaries/408/84",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/c46dd35ac05adb39f5f5eafb25a3c3f5/hero.jpg",
        heroTitleImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/01786485f23594db3117d1bb55c030e6/herotitle.png",
      },
    },
    {
      type: "landscapeTile",
      title: "Wild Animal Babies",
      imageUrl:
        "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/dd90059795f2fd9f17923a7a803446c3/{width}/{height}/item.jpg",
      hero: {
        mode: "standard",
        title: "Wild Animal Babies",
        metadata: "1h 45m   ",
        synopsis:
          "Patrick Aryee presents as he explores the fascinating first steps that animal babies take in those often-challenging formative moments.",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/d113911f02142b01a03d4eac9cbae686/hero.jpg",
        heroTitleImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/710f5451d0fa7a19488aa15219c16743/herotitle.png",
      },
    },
    {
      type: "landscapeTile",
      title: "Warrior's Way",
      imageUrl:
        "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/6cabaf83c5fa4ebd0fe692986bc83025/{width}/{height}/item.jpg",
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_skyhistory/408/84",
      hero: {
        mode: "standard",
        title: "Warrior's Way",
        metadata: "1h 45m   ",
        synopsis:
          "A look at the lives of some of the most famous warrior classes of ancient and medieval history from Vikings to Scottish clansmen, Medieval knights - including the Knights Templar -  and beyond.",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skyhistory/408/84",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/172a17763cffe2ec79e5c3be2616aaab/hero.jpg",
      },
    },
    {
      type: "titleTile",
      actionText: "View all",
      hero: {
        mode: "standard",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/172a17763cffe2ec79e5c3be2616aaab/hero.jpg",
      },
    },
  ],
} as Rail;
