import { NavStep } from "types";
import { animate, put, select } from "sagas/effects";
import { switcherIsShowing } from "selectors/app";
import { prevBrowseTab, nextBrowseTab } from "selectors/browseTabs";

export function* browseTabsNavigate(step: NavStep) {
  switch (step) {
    case "LEFT": {
      const { pageType, pageId } = yield select(prevBrowseTab);
      yield put({
        type: "BROWSE_FORWARD",
        pageType,
        pageId,
        includeInHistory: false,
      });
      yield animate("moveLens", "browseTabs");
      break;
    }
    case "RIGHT": {
      const { pageType, pageId } = yield select(nextBrowseTab);
      yield put({
        type: "BROWSE_FORWARD",
        pageType,
        pageId,
        includeInHistory: false,
      });
      yield animate("moveLens", "browseTabs");
      break;
    }
    case "DOWN": {
      if (yield select(switcherIsShowing)) {
        yield put({ type: "FOCUS_ON_SWITCHER" });
      } else {
        yield put({ type: "BROWSE_INCLUDE_CURRENT_PAGE_IN_HISTORY" });
        yield put({ type: "FOCUS_ON_BROWSE" });
      }
      break;
    }
    case "BACK":
      yield put({ type: "BROWSE_BACK" });
      break;
  }
}
