import { NavStep, SwitcherTile } from "types";
import { put, select, animate } from "sagas/effects";
import { selectedSwitcherTile } from "selectors/switcher";
import { showBrowseTabs } from "selectors/browseTabs";

export function* switcherNavigate(step: NavStep) {
  switch (step) {
    case "UP":
      if (yield select(showBrowseTabs)) {
        yield put({ type: "FOCUS_ON_BROWSE_TABS" });
      }
      break;
    case "DOWN":
      yield put({ type: "HIDE_SWITCHER" });
      yield put({ type: "FOCUS_ON_BROWSE" });
      yield animate("switcherToBrowse");
      break;
    case "LEFT":
      yield put({ type: "PREV_SWITCHER_ITEM" });
      yield animate("moveLens", "switcher");
      break;
    case "RIGHT":
      yield put({ type: "NEXT_SWITCHER_ITEM" });
      yield animate("moveLens", "switcher");
      break;
    case "SELECT":
      yield handleSelect();
      break;
    case "BACK": {
      yield put({ type: "GO_HOME" });
      break;
    }
  }
}

function* handleSelect() {
  const item: SwitcherTile = yield select(selectedSwitcherTile);
  switch (item.type) {
    case "recentTile": {
      if (item.action) yield put(item.action);
      break;
    }
    case "onNowTile":
      yield put({ type: "TURN_ON_FULLSCREEN" });
      yield animate("zoomIn");
      break;
  }
}
