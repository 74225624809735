import { Action } from "types/actions";
import { FocusArea, OverlayFocusArea } from "types";

export type AppMode = "glance" | "normal";

export type AppState = Readonly<{
  fullscreenMode: boolean;
  pageBackgroundSrc: string;
  focusArea: FocusArea;
  overlayFocusArea?: OverlayFocusArea;
  showSwitcher: boolean;
  introVideoUrl?: string;
  settingsOpen: boolean;
  mode: AppMode;
}>;

export const initialAppState: AppState = {
  fullscreenMode: false,
  pageBackgroundSrc: "./assets/images/backgrounds/llama-wallpaper.jpg",
  focusArea: "switcher",
  showSwitcher: true,
  settingsOpen: false,
  mode: "normal",
};

export const appReducer = (
  state: AppState = initialAppState,
  action: Action
): AppState => {
  switch (action.type) {
    case "JOURNEY.START":
      return { ...state, fullscreenMode: true };

    case "JOURNEY.STOP":
      return { ...state, fullscreenMode: false };

    case "GO_TO_PAGE":
      return { ...state, showSwitcher: false, fullscreenMode: false };

    case "SHOW_SWITCHER":
      return { ...state, showSwitcher: true };

    case "HIDE_SWITCHER":
      return { ...state, showSwitcher: false };

    case "FOCUS_ON_SWITCHER":
      return {
        ...state,
        focusArea: "switcher" as FocusArea,
        showSwitcher: true,
        fullscreenMode: false,
      };

    case "FOCUS_ON_BROWSE":
      return { ...state, focusArea: "browse" as FocusArea };

    case "FOCUS_ON_BROWSE_TABS":
      return { ...state, focusArea: "browseTabs" as FocusArea };

    case "FOCUS_ON_TV_CATEGORIES":
      return { ...state, focusArea: "tvCategories" as FocusArea };

    case "TURN_ON_FULLSCREEN":
      return { ...state, fullscreenMode: true };

    case "TURN_OFF_FULLSCREEN":
      return { ...state, fullscreenMode: false };

    case "INTRO.PLAY":
      return { ...state, introVideoUrl: action.videoUrl };

    case "INTRO.ENDED":
      return { ...state, introVideoUrl: undefined };

    case "SETTINGS.TOGGLE":
      return { ...state, settingsOpen: !state.settingsOpen };

    case "QUICK_ACCESS_MENU.SHOW":
      return { ...state, overlayFocusArea: "quickAccessMenu" };

    case "QUICK_ACCESS_MENU.HIDE":
      return { ...state, overlayFocusArea: undefined };

    case "CHANGE_MODE":
      return { ...state, mode: action.mode };

    default:
      return state;
  }
};
