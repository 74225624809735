export const videos = {
  alwaysBe: "/assets/videos/always_be/always_be.mpd",
  capture: "/assets/videos/the_capture/the_capture.mpd",
  coast: "/assets/videos/Coast/Coast.mpd",
  landlord: "/assets/videos/reluctant_landlord/reluctant_landlord.mpd",
  lineOfDuty: "/assets/videos/line_of_duty/line_of_duty.mpd",
  outOfBlue: "/assets/videos/out_of_blue/out_of_blue.mpd",
  greatestShowman: "/assets/videos/greatest_showman/greatest_showman.mpd",
  skyCinemaIntro: "/assets/videos/sky_cinema_intro.mp4",
  netflixIntro: "/assets/videos/netflix_intro.mp4",
};
