import { State } from "reducers";
import { currentPage } from "selectors/currentPage";
import { PageTitle } from "types";

export const pageType = (state: State) => state.browse.pageState.pageType;

export const onHomepage = ({ browse: { pageState } }: State) =>
  pageState.pageId === "home";

export const pageTitleForHero = (state: State): PageTitle | undefined => {
  if (state.browse.showPageTitleInHero) {
    const { page } = currentPage(state);
    return "title" in page ? page.title : undefined;
  }
};

export const tvDaysIsShowing = (state: State) => state.browse.showTvDays;

export const tvCategoriesIsShowing = (state: State) =>
  pageType(state) === "tvGuidePage" &&
  (state.settings.autoHideTvCategories
    ? state.app.focusArea === "tvCategories"
    : true);
