import React from "react";
import { Text } from "../Text";
import { Container } from "./TitleTile.styles";
import { TitleTile as TitleTileType } from "types";

type Props = {
  tile: TitleTileType;
  focused: boolean;
};

export const TitleTile = ({ tile: { actionText, title }, focused }: Props) => (
  <Container focused={focused}>
    <Text size="medium" weight="medium" extraLineHeight>
      {title || actionText}
    </Text>
    {focused && !!title && (
      <Text size="small" weight="medium" extraLineHeight>
        {actionText}
      </Text>
    )}
  </Container>
);
