import React from "react";
import {
  Container,
  Icon,
  Detail,
  SwitchContainer,
} from "./ToggleQAMItem.styles";
import { Text } from "components/Text";
import { Switch } from "components/Switch";
import { observer } from "mobx-react-lite";
import { useKeys } from "hooks/useKeys";
import { stores, StoreId } from "stores";
import { Toggler } from "stores/Toggler";

type Props = {
  title: string;
  iconUrl: string;
  focusedStyle: boolean;
  on: boolean;
};

export const ToggleQAMItem = ({ title, iconUrl, on, focusedStyle }: Props) => (
  <Container focusedStyle={focusedStyle}>
    <Icon url={iconUrl} />
    <Detail>
      <Text size="medium" weight="medium" style={{ marginBottom: 5 }}>
        {title}
      </Text>
      <SwitchContainer>
        <Switch on={on} />
        <Text size="small" weight="regular" style={{ marginLeft: 9 }}>
          {on ? "On" : "Off"}
        </Text>
      </SwitchContainer>
    </Detail>
  </Container>
);

type ControllerProps = {
  title: string;
  iconUrl: string;
  focusedStyle: boolean;
  focused: boolean;
  storeId: StoreId<Toggler>;
};

export const ToggleQAMItemController = observer<ControllerProps>(props => {
  const toggler = stores[props.storeId];

  useKeys(props.focused, ({ button }) => {
    switch (button) {
      case "SELECT":
        toggler.toggle();
        break;
    }
  });

  return <ToggleQAMItem {...props} on={toggler.on} />;
});
