import { Rail } from "types";

export default {
  type: "rail",
  uuid: "5d9a6a1c-970c-41e4-8558-86f054e6e6e3",
  template: "tile-cover",
  title: "Ultra HD",
  tiles: [
    {
      type: "coverTile",
      title: "Malawi Wildlife Rescue",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/843bf9b7-4b06-40b0-b8d4-51687e39b744/cover/{height}",
      hero: {
        mode: "standard",
        title: "Malawi Wildlife Rescue",
        metadata: "1h 45m   ",
        synopsis:
          "Join vet Amanda at the Lilongwe Wildlife centre, where her and her dedicated team rescue, treat and rehabilitate hundreds of wild animals every year. ",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/2c33f299d2b989d7d657877e28cff8c8/hero.jpg",
      },
    },
    {
      type: "coverTile",
      title: "MacGyver",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/5d71033e-682d-4f8c-9c40-baaaf15f4ee9/cover/{height}",
      hero: {
        mode: "standard",
        title: "MacGyver",
        metadata: "1h 45m   ",
        synopsis:
          'Lucas Till stars as genius problem solver Angus "Mac" MacGyver in series one of the much-anticipated reboot of the hit 1980s action adventure series.\r',
        logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_skyone/408/84",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/3bbc3e1d25d80d5f892ff269e2c3dcee/hero.jpg",
        heroTitleImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/6be9e37dd06fa9af78aa3c69fce0ef81/herotitle.png",
      },
    },
    {
      type: "coverTile",
      title: "Sunderland 'Til I Die",
      imageUrl:
        "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/e7188637c6399e44d3c68a13374af3ec/{width}/{height}/item.jpg",
      hero: {
        mode: "standard",
        title: "Sunderland 'Til I Die",
        metadata: "1h 45m   ",
        synopsis:
          "This docuseries follows English soccer club Sunderland through the 2017-18 season as they try to bounce back after relegation from the Premier League.",
        logoUrl:
          "https://cdn.skyq.sky.com/uk/epg/live/media/logos/netflix/v1/logo_365x75.png",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/f49cabc8bfb535af0f2e89cf8e8d70d8/hero.jpg",
      },
    },
    {
      type: "coverTile",
      title: "The Blacklist",
      imageUrl:
        "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/177776a4db244e399a676f08ed8c7c36/{width}/{height}/item.jpg",
      hero: {
        mode: "standard",
        title: "The Blacklist",
        metadata: "1h 45m   ",
        synopsis:
          "Emmy Award winner James Spader (Boston Legal) is the world's most wanted criminal in an electrifying US drama delivering conspiracy and action. S1-3 available in HD.",
        logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_skyone/408/84",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/6f9825c388147d4781b2fba07296e998/hero.jpg",
      },
    },
    {
      type: "coverTile",
      title: "Better Call Saul",
      imageUrl:
        "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/ee19abcd522c3ad8a90ee866fa11795f/{width}/{height}/item.jpg",
      hero: {
        mode: "standard",
        title: "Better Call Saul",
        metadata: "1h 45m   ",
        synopsis:
          'This Emmy-nominated prequel to "Breaking Bad" follows small-time attorney Jimmy McGill as he transforms into morally challenged lawyer Saul Goodman.',
        logoUrl:
          "https://cdn.skyq.sky.com/uk/epg/live/media/logos/netflix/v1/logo_365x75.png",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/8dff8de45dc9bcdea50b51a3ae3c62b4/hero.jpg",
      },
    },
    {
      type: "coverTile",
      title: "Das Boot",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/4d18b5ea-a12a-4d26-b804-831775fb2301/cover/{height}",
      hero: {
        mode: "standard",
        title: "Das Boot",
        metadata: "1h 45m   ",
        synopsis:
          "Gripping World War II thriller following a U-boat crew’s battle to survive a perilous secret mission. All episodes available now.",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skyatlantic/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/4d18b5ea-a12a-4d26-b804-831775fb2301/background/{width}",
        heroTitleImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/843922ac2427cd8b9d7a783ff4f40fce/herotitle.png",
      },
    },
    {
      type: "coverTile",
      title: "Altered Carbon",
      imageUrl:
        "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/0e3f15c8f4d71e172f5b6ac5af1926fd/{width}/{height}/item.jpg",
      hero: {
        mode: "standard",
        title: "Altered Carbon",
        metadata: "1h 45m   ",
        synopsis:
          "After 250 years on ice, a prisoner returns to life in a new body with one chance to win his freedom: by solving a mind-bending murder.",
        logoUrl:
          "https://cdn.skyq.sky.com/uk/epg/live/media/logos/netflix/v1/logo_365x75.png",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/403a794e9e4555756b17449dd7920be0/hero.jpg",
      },
    },
    {
      type: "coverTile",
      title: "Babylon Berlin",
      imageUrl:
        "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/ce80a09ce26532322e84dbc5d457d499/{width}/{height}/item.jpg",
      hero: {
        mode: "standard",
        title: "Babylon Berlin",
        metadata: "1h 45m   ",
        synopsis:
          "A shell-shocked Inspector falls into a tangled web of corruption while investigating pre-World War II Berlin's seedy underbell. ",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skyatlantic/408/84",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/7ce49a9cdbf93f59bc6d56f9dcfb8bd3/hero.jpg",
        heroTitleImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/dd76116401037452af8f026ac907d227/herotitle.png",
      },
    },
    {
      type: "coverTile",
      title: "Kingdom",
      imageUrl:
        "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/505df3e15d5d16c208b136f2940e3177/{width}/{height}/item.jpg",
      hero: {
        mode: "standard",
        title: "Kingdom",
        metadata: "1h 45m   ",
        synopsis:
          "While strange rumors about their ill king grip a kingdom, the crown prince becomes their only hope against a mysterious plague overtaking the land.",
        logoUrl:
          "https://cdn.skyq.sky.com/uk/epg/live/media/logos/netflix/v1/logo_365x75.png",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/752d50c223df489a1a29aef1810edb2c/hero.jpg",
      },
    },
    {
      type: "coverTile",
      title: "Ray Donovan",
      imageUrl:
        "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/edcfcda1004d896e9307ffd20d975fa9/{width}/{height}/item.jpg",
      hero: {
        mode: "standard",
        title: "Ray Donovan",
        metadata: "1h 45m   ",
        synopsis:
          "Meet Ray (Liev Schreiber), a problem solver to LA's rich and famous with a past that's back to haunt him. Absorbing drama with Jon Voight.\r",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skyatlantic/408/84",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/0bd33a4664afe6ef5db2606e1a6caba6/hero.jpg",
      },
    },
    {
      type: "titleTile",
      actionText: "View all",
      hero: {
        mode: "standard",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/0bd33a4664afe6ef5db2606e1a6caba6/hero.jpg",
      },
    },
  ],
} as Rail;
