import { Rail } from "types";

export default {
  type: "rail",
  uuid: "96e055c4-444d-4765-a4d5-46d5d8fc0438",
  template: "tile-landscape",
  title: "Best of TV",
  tiles: [
    {
      type: "landscapeTile",
      title: "The A Word",
      imageUrl:
        "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/e6d1afa8942a2fa24d2ab4ea1ef8bcc2/{width}/{height}/item.jpg",
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_bbcone/408/84",
      hero: {
        mode: "standard",
        title: "The A Word",
        synopsis:
          "Returning for a much-anticipated third series, the Hughes family navigate their new reality now that Joe is 10-years-old, splitting his time between his parents and living with autism.\r",
        heroTitleImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/3a8be77d71d6d78146a2744bbc6f0db5/herotitle.png",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/a9a141882b6405ee4b0fb4bb63144074/hero.jpg",
        logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_bbcone/408/84",
        metadata: "1h 45m   ",
      },
    },
    {
      type: "landscapeTile",
      title: "Bake Off: The Professionals",
      imageUrl:
        "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/69de4a6fe9ad07aeb682b1228b55d25b/{width}/{height}/item.jpg",
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_channel4/408/84",
      hero: {
        mode: "standard",
        title: "Bake Off: The Professionals",
        synopsis:
          "The toughest Bake Off on TV returns as six teams of professional pastry chefs do battle in the kitchen, turning ordinary patisserie and desserts into masterpieces.\r",
        heroTitleImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/f831f6c361ae368b79ed3805f6477052/herotitle.png",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/f9513ae3bf5102dd1d3be25a3445609b/hero.jpg",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_channel4/408/84",
        metadata: "1h 45m   ",
      },
    },
    {
      type: "landscapeTile",
      title: "The UnXplained with William Shatner",
      imageUrl:
        "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/2107998d8351cb369894d6c670bfebf1/{width}/{height}/item.jpg",
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_skyhistory/408/84",
      hero: {
        mode: "standard",
        title: "The UnXplained with William Shatner",
        synopsis:
          "From baffling structures to evil places, strange creatures and bizarre rituals, Star Trek's William Shatner returns to explore more mysteries that have stumped mankind for centuries.",
        heroTitleImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/639f246ce7afd8b3d4864efff425ad44/herotitle.png",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/8a0ecbb7b3e70e5c3cbc4883ba5ce35e/hero.jpg",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skyhistory/408/84",
        metadata: "1h 45m   ",
      },
    },
    {
      type: "landscapeTile",
      title: "Accused: Guilty Or Innocent?",
      imageUrl:
        "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/3a47ac5c8ae20100542cadfab25c5622/{width}/{height}/item.jpg",
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_crimeinv/408/84",
      hero: {
        mode: "standard",
        title: "Accused: Guilty Or Innocent?",
        synopsis:
          "True crime series revealing what happens when someone is formally charged with a crime, following the accused through their journey and revealing the stories that put their freedom on the line.",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/772eb9bba22efd9d1da4578ac4f00da7/hero.jpg",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_crimeinv/408/84",
        metadata: "1h 45m   ",
      },
    },
    {
      type: "landscapeTile",
      title: "Coroner",
      imageUrl:
        "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/bed3cac26e520455b436f2111b7a3168/{width}/{height}/item.jpg",
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_skywitness/408/84",
      hero: {
        mode: "standard",
        title: "Coroner",
        synopsis:
          "When a co-worker dies, Jenny and McAvoy uncover clues that lead them to a serial killer.\r",
        heroTitleImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/03214b4ab9bef6b2b25927cbbf2857c0/herotitle.png",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/5aad7091b70a18889becdf144807c5c3/hero.jpg",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skywitness/408/84",
        metadata: "1h 45m   ",
      },
    },
    {
      type: "landscapeTile",
      title: "The Tonight Show Starring Jimmy Fallon",
      imageUrl:
        "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/bccc71fdd41b07b38ad7a325ee7fdb9c/{width}/{height}/item.jpg",
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_skycomedy/408/84",
      hero: {
        mode: "standard",
        title: "The Tonight Show Starring Jimmy Fallon",
        synopsis:
          "Late-night American talk show hosted by Jimmy Fallon from Studio 6B in Rockefeller Center, New York City. It features sidekick and announcer Steve Higgins and house band the Roots.",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/c999862866374f1184b40945eb7bb021/hero.jpg",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycomedy/408/84",
        metadata: "1h 45m   ",
      },
    },
    {
      type: "landscapeTile",
      title: "The Late Late Show...",
      imageUrl:
        "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/d3754668da9344578960e38aecdec47b/{width}/{height}/item.jpg",
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_skycomedy/408/84",
      hero: {
        mode: "standard",
        title: "The Late Late Show...",
        synopsis:
          "...with James Corden. James Corden welcomes a parade of celebrity guests in the late-night talk show featuring musical acts, games, sketches and of course, carpool karaoke.",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/d5bc8699a8817ff24a6e01e763dfbefe/hero.jpg",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycomedy/408/84",
        metadata: "1h 45m   ",
      },
    },
    {
      type: "landscapeTile",
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_skywitness/408/84",
      title: "9-1-1",
      imageUrl:
        "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/395315b7d11fbb1db3ec06b2a69b955c/{width}/{height}/item.jpg",
      hero: {
        mode: "standard",
        metadata: "1h 45m   ",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skywitness/408/84",
        title: "9-1-1",
        synopsis:
          "Things are more tense than ever in the third season of the adrenaline-fuelled drama following the high-pressure lives of first responders. Angela Bassett, Peter Krause and Jennifer Love Hewitt star.",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/c10c55953b494ed011e4e8ed84b92d6f/hero.jpg",
        heroTitleImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/a9e18895aad75576f24151ad3a86b55f/herotitle.png",
      },
    },
    {
      type: "landscapeTile",
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_bbctwo/408/84",
      title: "A House Through Time",
      imageUrl:
        "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/e27c88dd9729582eda521674681a3011/{width}/{height}/item.jpg",
      hero: {
        mode: "standard",
        metadata: "1h 45m   ",
        logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_bbctwo/408/84",
        title: "A House Through Time",
        synopsis:
          "In a bid to discover Bristol’s rich history, David Olusoga traces the lives of the occupants of a single house, getting to know them and following their stories wherever they lead.\r",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/a4c8b17b2c8523c0c832d4daff9ac289/hero.jpg",
        heroTitleImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/240c30113681c0c218e6559cf01c272d/herotitle.png",
      },
    },
    {
      type: "landscapeTile",
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_all4/408/84",
      title: "Derry Girls",
      imageUrl:
        "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/67dd6a57442dd60879fd2eccd9e1fd63/{width}/{height}/item.jpg",
      hero: {
        mode: "standard",
        metadata: "1h 45m   ",
        logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_all4/408/84",
        title: "Derry Girls",
        synopsis:
          "Family comedy with Saoirse-Monica Jackson and Louisa Harland. It follows the escapades of a group of teens as they grow up amongst the Troubles in early-1990s Northern Ireland.",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/3463f6034017a28cf18dfcfa191986d8/hero.jpg",
      },
    },
    {
      type: "landscapeTile",
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_skyone/408/84",
      title: "Manifest",
      imageUrl:
        "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/c0f574caba3c1b72395b22150cdbcdb0/{width}/{height}/item.jpg",
      hero: {
        mode: "standard",
        metadata: "1h 45m   ",
        logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_skyone/408/84",
        title: "Manifest",
        synopsis:
          "The mystery of Flight 828 continues in the second season of the breakout supernatural drama. Can the passengers finally find the answers they’ve been searching for? All episodes available. ",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/d33fa47ed65d45643819ecf6ddb59d95/hero.jpg",
      },
    },
    {
      type: "landscapeTile",
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_bbcone/408/84",
      title: "I May Destroy You",
      imageUrl:
        "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/e9f0e4b3ffe57ee34be8333f1244d9b7/{width}/{height}/item.jpg",
      hero: {
        mode: "standard",
        metadata: "1h 45m   ",
        logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_bbcone/408/84",
        title: "I May Destroy You",
        synopsis:
          "A frank and provocative two-part drama created by and starring BAFTA winner Michaela Coel, following the journey of a young writer as her life changes following a sexual assault in a nightclub.",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/7333fb4625409f83393b66e7eb4681bb/hero.jpg",
      },
    },
    {
      type: "landscapeTile",
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_itv/408/84",
      title: "Broadchurch",
      imageUrl:
        "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/95a1dd77ef4f4406c89265a30facc53a/{width}/{height}/item.jpg",
      hero: {
        mode: "standard",
        metadata: "1h 45m   ",
        logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_itv/408/84",
        title: "Broadchurch",
        synopsis:
          "Head back to the beginning and relive Chris Chibnall’s multi-award-winning drama about a small Dorset town rocked by the murder of a teenage boy. Olivia Colman and David Tennant star.",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/8b9451bcce9271f1e67d5ba419a03ae5/hero.jpg",
      },
    },
    {
      type: "landscapeTile",
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_skyatlantic/408/84",
      title: "I Know This Much Is True",
      imageUrl:
        "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/a572c810647c4a364103e19a4763cd49/{width}/{height}/item.jpg",
      hero: {
        mode: "standard",
        metadata: "1h 45m   ",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skyatlantic/408/84",
        title: "I Know This Much Is True",
        synopsis:
          "Mark Ruffalo stars as identical twin brothers in this six-part limited series - an epic story of betrayal, sacrifice and forgiveness set against the backdrop of 20th century America.",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/57a0c6a5beed9bf6dcc5ae35c111b5e7/hero.jpg",
        heroTitleImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/b92af07da7aeab8fe9f56f85ad6bd7f5/herotitle.png",
      },
    },
    {
      type: "landscapeTile",
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_comedycentral/408/84",
      title: "Takeshi's Castle",
      imageUrl:
        "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/7362d38f7f19748085ab4c8b742049a6/{width}/{height}/item.jpg",
      hero: {
        mode: "standard",
        metadata: "1h 45m   ",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_comedycentral/408/84",
        title: "Takeshi's Castle",
        synopsis:
          "Join host Stephen Bailey and football legend Chris Kamara on commentary as the iconic game show featuring hilarious hurdles like the honeycomb maze and the slippery wall returns for a new series.",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/d45d1b30daca87ffa3ff448e07714a91/hero.jpg",
        heroTitleImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/40ae771f899cda185422eacc94a099ca/herotitle.png",
      },
    },
    {
      type: "landscapeTile",
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_alibi/408/84",
      title: "Reef Break",
      imageUrl:
        "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/5d060d1d742f8409f9fdf24db5b75169/{width}/{height}/item.jpg",
      hero: {
        mode: "standard",
        metadata: "1h 45m   ",
        logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_alibi/408/84",
        title: "Reef Break",
        synopsis:
          "Cat Chambers, a professional surfer with a shady past and ties to a crime syndicate, finds herself entangled in a string of fast-paced, high-octane island adventures.",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/97ed65759da7f3f163b3b2eb402f02df/hero.jpg",
      },
    },
    {
      type: "titleTile",
      actionText: "View all",
      action: {
        type: "GO_TO_PAGE",
        pageType: "gridPage",
        pageId: "bestTv",
      },
      hero: {
        mode: "titleArt",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/97ed65759da7f3f163b3b2eb402f02df/hero.jpg",
      },
    },
  ],
} as Rail;
