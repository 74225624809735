import styled from "@emotion/styled";
import { fonts } from "constants/type";

export const Container = styled.div({
  padding: 20,
  fontFamily: fonts.regular,
});

export const Row = styled.div({
  display: "flex",
  justifyContent: "space-between",
});

export const Column = styled.div({});

export const Dl = styled.dl({
  display: "flex",
  flexWrap: "wrap",
  width: 300,
});

export const Dt = styled.dt`
  width: 25%;
  text-align: right;
  font-weight: bold;
`;

export const Dd = styled.dd`
  margin-left: auto;
  padding-left: 15px;
  width: 75%;
`;

export const Ul = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`;
export const Li = styled.li`
  margin-bottom: 10px;
`;

export const Select = styled.select(({ inline }: { inline?: boolean }) => ({
  fontFamily: fonts.regular,
  padding: "5px 10px",
  fontSize: "1.3em",
  margin: inline ? "0 0.5em" : 0,
}));

export const Option = styled.option``;

export const InputCheckbox = styled.input`
  transform: translateX(5px) scale(2.5);
`;

export const InputNumber = styled.input(({ inline }: { inline?: boolean }) => ({
  fontFamily: fonts.regular,
  fontSize: "1.2em",
  width: "3em",
  margin: inline ? "0 0.5em" : 0,
}));
