import { select, put } from "sagas/effects";
import { SpecificAction } from "types/actions";
import { inAJourney } from "selectors/nav";
import { journeyNavigate } from "sagas/navigate/journey";
import { switcherNavigate } from "sagas/navigate/switcher";
import { browseNavigate } from "sagas/navigate/browse";
import { browseTabsNavigate } from "sagas/navigate/browseTabs";
import { focusArea, overlayFocusArea, inGlance } from "selectors/app";

export function* navigate({ step }: SpecificAction<"NAVIGATE">) {
  switch (step) {
    case "HOME":
      yield put({ type: "GO_HOME" });
      break;
    default:
      switch (yield select(overlayFocusArea)) {
        // QAM
        case "quickAccessMenu":
          // Keys are taken care of locally in QAMXXXController components
          break;
        case undefined: // No overlay
          // Playing something
          if (yield select(inAJourney)) {
            yield journeyNavigate(step);
          } else {
            // GLANCE
            if (yield select(inGlance)) {
              return;
            }
            switch (yield select(focusArea)) {
              // SWITCHER
              case "switcher":
                yield switcherNavigate(step);
                break;
              // BROWSE
              case "browse":
                yield browseNavigate(step);
                break;
              // BROWSE TABS
              case "browseTabs":
                yield browseTabsNavigate(step);
                break;
            }
          }
      }
  }
}
