import styled from "@emotion/styled";
import { screen } from "constants/dimensions";
import { fonts } from "constants/type";

export const Container = styled.div({
  width: screen.width,
  height: screen.height,
  position: "absolute",
  top: 0,
  left: 0,
  backgroundColor: "rgba(0, 0, 0, 0.5)",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

export const Box = styled.div`
  width: 70%;
  height: 70%;
  background: white;
  overflow: scroll;
  border-radius: 10px;
  position: relative;
`;

export const CloseButton = styled.div({
  fontFamily: fonts.regular,
  fontSize: 64,
  width: 64,
  height: 64,
  position: "absolute",
  top: 0,
  right: 0,
  cursor: "pointer",
});
