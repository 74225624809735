import { delay, put, select } from "sagas/effects";
import { pageTitleInHeroPosition } from "selectors/pageTitle";

export function* showMoreHeroAfterPause() {
  yield delay(4000);
  yield put({ type: "SHOW_MORE_HERO_INFO" });
}

export function* brieflyShowPageTitleInHero() {
  if (yield select(pageTitleInHeroPosition)) {
    yield put({ type: "BROWSE.SHOW_PAGE_TITLE_IN_HERO" });
    yield delay(4000);
    yield put({ type: "BROWSE.HIDE_PAGE_TITLE_IN_HERO" });
  }
}
