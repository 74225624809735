import styled from "@emotion/styled";
import React, { useRef } from "react";
import { Text } from "components/Text";
import { tvGuide } from "constants/dimensions";
import { Fade } from "components/Fade";
import { GlassFrame } from "components/GlassFrame";
import { boolToInt } from "utils/boolToInt";
import { useAnimations } from "hooks/useAnimations";
import gsap from "gsap";

type BackgroundStyle = "light" | "verylight";

type Props = {
  title: string;
  width: number;
  x?: number;
  focused?: boolean;
  channelNumber?: string;
  backgroundStyle?: BackgroundStyle;
  visible?: boolean;
};

const BORDER_RADIUS = 6,
  MARGIN_RIGHT = tvGuide.grid.itemMarginRight;

const containerParams = (visible, width: number, x: number = 0) => {
  // Don't let padding be greater than the (box-sizing: border-box) width
  const paddingSide = Math.min(tvGuide.item.paddingLeft, width / 2);
  return {
    paddingLeft: paddingSide,
    paddingRight: paddingSide,
    width,
    x,
    opacity: boolToInt(visible),
  };
};

const Container = styled.div`
  background: rgba(241, 241, 241, 0.06);
  box-shadow: 4px 4px 8px 0 rgba(0, 0, 0, 0.12);
  border-radius: ${BORDER_RADIUS}px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: ${tvGuide.item.height}px;
  margin: 0 ${MARGIN_RIGHT}px ${tvGuide.item.marginBottom}px 0;
  position: absolute;
`;

const bgOpacity = (style: BackgroundStyle) => {
  switch (style) {
    case "light":
      return 0.05;
    case "verylight":
      return 0.12;
    default:
      return 0;
  }
};

const LightBg = styled.div(
  ({ backgroundStyle }: { backgroundStyle: BackgroundStyle }) => `
  width: 100%;
  height: 100%;
  background: white;
  opacity: ${bgOpacity(backgroundStyle)};
  position: absolute;
  left: 0;
  top: 0;
  border-radius: ${BORDER_RADIUS}px;
`
);

export const GridItem = ({
  title,
  channelNumber,
  focused,
  backgroundStyle,
  x,
  width: fullWidth,
  visible,
}: Props) => {
  visible = visible ?? true;
  const width = fullWidth - MARGIN_RIGHT,
    containerRef = useRef(null);

  useAnimations(
    {
      animations: {
        tvGuideScrollHorizontally: (params, timeline) =>
          timeline.to(containerRef.current, { ...params, ease: "power2.out" }),
      },
      reset: params => gsap.set(containerRef.current, params),
    },
    containerParams(visible, width, x)
  );

  return (
    <Container ref={containerRef}>
      {backgroundStyle && <LightBg backgroundStyle={backgroundStyle} />}
      <Text weight={focused ? "medium" : "regular"} size="medium" clamp={1}>
        {title}
      </Text>
      {channelNumber && (
        <Text weight="regular" size="small" clamp={1}>
          {channelNumber}
        </Text>
      )}
      <Fade
        visible={!!focused}
        duration={0.3}
        style={{ position: "absolute", top: 0, left: 0 }}
        render={() => (
          <GlassFrame
            animationScope="grid-item"
            width={width}
            height={tvGuide.item.height}
            borderRadius={BORDER_RADIUS}
          />
        )}
      />
    </Container>
  );
};
