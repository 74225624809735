import { Rail } from "types";

export default {
  type: "rail",
  uuid: "ded2f577-d003-45a7-9f2c-bf0e79e613d6",
  template: "tile-square",
  title: "Apps",
  tiles: [
    {
      type: "titleTile",
      title: "Apps",
      hero: {
        mode: "standard",
        title: "Apps",
        synopsis: "Explore your apps, all from one place.",
        heroImageUrl: "assets/images/apps/netflix-bg.jpg",
      },
    },
    {
      type: "squareTile",
      imageUrl: "assets/images/apps/disney.png",
      hero: {
        mode: "standard",
        title: "Disney",
        synopsis:
          "From classics to new releases, stream stories that you won't find anywhere else.",
        heroImageUrl: "assets/images/apps/disney-bg.jpg",
      },
    },
    {
      type: "squareTile",
      imageUrl: "assets/images/apps/netflix.png",
      hero: {
        mode: "standard",
        title: "Netflix",
        synopsis:
          "Watch Netflix movies & TV shows online or stream right to your smart TV, game console, PC, Mac, mobile, tablet and more.",
        heroImageUrl: "assets/images/apps/netflix-bg.jpg",
      },
    },
    {
      type: "squareTile",
      imageUrl: "assets/images/apps/youtube.png",
      hero: {
        mode: "standard",
        title: "YouTube",
        synopsis:
          "Enjoy the videos and music you love, upload original content, and share it all with friends, family, and the world on YouTube.",
        heroImageUrl: "assets/images/apps/youtube-bg.jpg",
      },
    },
    {
      type: "squareTile",
      imageUrl: "assets/images/apps/spotify.png",
      hero: {
        mode: "standard",
        title: "Spotify",
        synopsis:
          "Spotify is a digital music service that gives you access to millions of songs.",
        heroImageUrl: "assets/images/apps/spotify-bg.jpg",
      },
    },
    {
      type: "squareTile",
      imageUrl: "assets/images/apps/xite.png",
      hero: {
        mode: "standard",
        title: "XITE",
        synopsis:
          "XITE provides a fun and easy to use personal music video service that allows people to rediscover the music videos they love.",
        heroImageUrl: "assets/images/apps/xite-bg.jpg",
      },
    },
    {
      type: "squareTile",
      imageUrl: "assets/images/apps/roku.png",
      hero: {
        mode: "standard",
        title: "Roku",
        synopsis:
          "The world's largest all-premium music video provider, offering artists a global platform with enormous scale through its distribution partners.",
        heroImageUrl: "assets/images/apps/roku-bg.jpg",
      },
    },
    {
      type: "squareTile",
      imageUrl: "assets/images/apps/iplayer.png",
      hero: {
        mode: "standard",
        title: "iPlayer",
        synopsis:
          "BBC iPlayer is an internet streaming, catchup, television and former radio service from the BBC.",
        heroImageUrl: "assets/images/apps/bbc-bg.jpg",
      },
    },
    {
      type: "titleTile",
      actionText: "View all",
      hero: {
        mode: "standard",
        heroImageUrl: "assets/images/apps/bbc-bg.jpg",
      },
    },
  ],
} as Rail;
