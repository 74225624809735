import { select, animate, put } from "sagas/effects";
import { switcherIsShowing } from "selectors/app";

export function* goHome() {
  const wasFullscreenMode: boolean = yield select(s => s.app.fullscreenMode),
    switcherWasShowing: boolean = yield select(switcherIsShowing);
  yield put({ type: "FOCUS_ON_SWITCHER" });
  yield put({ type: "SWITCHER_RESET" });
  yield put({ type: "BROWSE_RESET" });
  yield put({ type: "QUICK_ACCESS_MENU.HIDE" });
  if (wasFullscreenMode) {
    yield animate("zoomOut");
  } else if (!switcherWasShowing) {
    yield animate("browseToSwitcher");
  }
}
