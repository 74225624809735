import styled from "@emotion/styled";
import { quickAccessMenu } from "constants/dimensions";
import { tileShadow } from "constants/effects";

export const Container = styled.div(
  ({ focusedStyle }: { focusedStyle: boolean }) => `
  background: ${
    focusedStyle ? "rgba(241,241,241,0.2)" : "rgba(241,241,241,0.08)"
  };
  box-shadow: ${tileShadow};
  height: ${quickAccessMenu.item.height}px;
  width: ${quickAccessMenu.item.width}px;
  display: flex;
  align-items: center;
  justify-content: left;
  padding: 0 55px;
  border-radius: ${focusedStyle ? 0 : quickAccessMenu.item.borderRadius}px;
  overflow: hidden;
`
);

export const Icon = styled.div(
  ({ url }: { url: string }) => `
  width: 64px;
  height: 64px;
  background: url(${url}) no-repeat;
  background-size: contain;
  margin-right: 32px;
  flex: 0 0 64px;
`
);

export const Detail = styled.div``;
