import styled from "@emotion/styled";
import { screen, tvGuide } from "../../../constants/dimensions";

export const Container = styled.div({
  width: screen.width,
  height: screen.height,
  position: "absolute",
  top: tvGuide.yLines.withRail,
  left: tvGuide.leftMargin,
});

export const Header = styled.div(
  ({ width }: { width: number }) => `
  padding: 0 0 14px ${tvGuide.item.paddingLeft}px;
  width: ${width}px;
  height: ${tvGuide.titles.height}px;
`
);

export const HeaderRowContainer = styled.div`
  height: ${tvGuide.titles.height}px;
  position: relative;
  overflow: hidden;
`;

export const HeaderRow = styled.div`
  height: 100%;
  position: absolute;
`;

export const ChannelNames = styled.div`
  width: ${tvGuide.channelNames.width}px;
  margin-right: 4px;
  z-index: 0; // otherwise day doesn't show
`;

export const Grid = styled.div`
  flex: 1 0 auto;
  z-index: 0; // otherwise times don't show
`;

export const ScrollContainerWrapper = styled.div`
  overflow: hidden;
  // make sure drop shadows are still showing by giving big width
  width: 3000px;
  position: relative;
  left: -${tvGuide.overflowAllowanceForFocusState.x}px;
  padding-left: ${tvGuide.overflowAllowanceForFocusState.x}px;
  top: -${tvGuide.overflowAllowanceForFocusState.y}px;
`;

export const ScrollContainer = styled.div`
  position: relative;
`;

export const Row = styled.div`
  height: ${tvGuide.item.outerHeight}px;
  position: relative;
`;

export const EpgContainer = styled.div`
  display: flex;
  position: relative;
`;

export const EpgMask = styled.div`
  position: absolute;
  left: -${tvGuide.leftMargin}px;
  top: 0;
  background-image: linear-gradient(
    180deg,
    rgba(17, 47, 186, 0) 0%,
    rgba(17, 47, 186, 0.75) 50%
  );
  width: ${screen.width}px;
  height: 700px;
`;

export const RailContainer = styled.div`
  height: ${tvGuide.railContainer.height}px;
  margin-bottom: ${tvGuide.railContainer.marginBottom}px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;
