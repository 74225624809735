import { Rail } from "types";

export default {
  type: "rail",
  uuid: "04b8db0d-40e6-4ca6-9c24-4873123fc112",
  template: "tile-category",
  tiles: [
    {
      type: "categoryTile",
      title: " Search",
      action: { type: "GO_TO_PAGE", pageType: "imagePage", pageId: "search" },
      hero: {
        mode: "standard",
        title: "Search",
        synopsis: "Find shows, movies, actors, competitions and teams.",
        heroImageUrl: "assets/images/settings/forgotten-pin.jpg",
      },
    },
    {
      type: "categoryTile",
      title: "TV Guide",
      action: {
        type: "GO_TO_PAGE",
        pageType: "tvGuidePage",
        pageId: "tvGuide_tvShows",
      },
      hero: {
        mode: "standard",
        title: "TV Guide",
        synopsis: "Explore the shows you know and love",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/aa739175-7ae0-48ef-a6f0-7e5fe6d90acf/background/1428",
      },
    },
    {
      type: "categoryTile",
      title: "My List",
      action: {
        type: "GO_TO_PAGE",
        pageType: "railsPage",
        pageId: "recordings",
      },
      hero: {
        mode: "standard",
        title: "My List",
        synopsis: "Your watchlist of shows and movies",
        heroImageUrl:
          "assets/images/content/Save My Child/Save-my-child-hero.jpg",
      },
    },
    {
      type: "categoryTile",
      title: "Help",
      hero: {
        mode: "titleArt",
        heroImageUrl: "assets/images/settings/help.jpg",
      },
    },
  ],
} as Rail;
