import { videos } from "data/videos";
import { Journey } from "types";

export const landlord: Journey = {
  id: "landlord",
  pages: [
    {
      type: "videoPage",
      url: videos.landlord,
    },
  ],
  title: "The Reluctant Landlord",
};
