import React from "react";
import { Text } from "../Text";
import { Container } from "./CategoryTile.styles";
import { CategoryTile as CategoryTileType } from "types";

type Props = {
  focused: boolean;
  tile: CategoryTileType;
};

export const CategoryTile = ({ tile: { title }, focused }: Props) => (
  <Container focused={focused}>
    <Text size="large" weight="medium">
      {title}
    </Text>
  </Container>
);
