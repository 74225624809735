import mapValues from "lodash/mapValues";
import { PageId } from "types";
import { Action } from "types/actions";

export type RailsPageState = Readonly<{
  pageType: "railsPage";
  pageId: PageId;
  index: number;
  railIndices: { [index: number]: number };
}>;

export const newPageState = (pageId: PageId): RailsPageState => ({
  pageType: "railsPage",
  pageId,
  index: 0,
  railIndices: {},
});

export const initialState: RailsPageState = {
  pageType: "railsPage",
  pageId: "home",
  index: 0,
  railIndices: {},
};

export const resetOffscreenRails = (pageState: RailsPageState) => ({
  ...pageState,
  railIndices: mapValues(pageState.railIndices, (tileIndex, railIndex) => {
    const i = parseInt(railIndex);
    return i < pageState.index || pageState.index + 2 < i ? 0 : tileIndex;
  }) as RailsPageState["railIndices"],
});

export const resetRails = (pageState: RailsPageState) => ({
  ...pageState,
  index: 0,
  railIndices: { 0: 0 },
});

export const changeTile = (pageState: RailsPageState, tileIndex: number) => {
  const { index, railIndices } = pageState;
  return {
    ...pageState,
    railIndices: { ...railIndices, [index]: tileIndex },
  };
};

export const railsPageReducer = (state: RailsPageState, action: Action) => {
  switch (action.type) {
    case "RAILS.RESET":
      return resetRails(state);

    case "RAILS.CHANGE_RAIL":
      return resetOffscreenRails({ ...state, index: action.index });

    case "RAILS.CHANGE_TILE":
      return changeTile(state, action.index);

    default:
      return state;
  }
};
