import styled from "@emotion/styled";
import { screen, quickAccessMenu } from "constants/dimensions";

export const Container = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: ${screen.width}px;
  height: ${quickAccessMenu.height}px;
  background-image: linear-gradient(
    0deg,
    rgb(8, 9, 15, 0.95) 42%,
    rgba(0, 0, 0, 0.0095) 100%,
    rgba(0, 0, 0, 0) 100%,
    rgba(0, 0, 0, 0.0095) 100%
  );
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;
