import { Action } from "types/actions";

export type TimeState = Readonly<{
  timeMs: number;
}>;

export const initialTimeState = {
  timeMs: Date.now(),
};

export const timeReducer = (
  state: TimeState = initialTimeState,
  action: Action
) => {
  switch (action.type) {
    case "SET_TIME":
      return { ...state, timeMs: action.timeMs };
    default:
      return state;
  }
};
