import { State } from "reducers";
import { selectedSwitcherTile } from "selectors/switcher";
import { getHero as getJourneyHero } from "data/journeys";
import { Hero } from "types";

export const heroSelector = (state: State): Hero => {
  const switcherTile = selectedSwitcherTile(state);
  if (switcherTile.type === "onNowTile") {
    return { mode: "standard", ...getJourneyHero(switcherTile.journeyId) };
  } else {
    return {
      mode: state.switcher.heroTitleMode ? "titleArt" : "standard",
      ...switcherTile.hero,
    };
  }
};
