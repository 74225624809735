import { Rail } from "types";

export default {
  type: "rail",
  uuid: "755f7e37-d012-4004-8a57-dcb2566ba195",
  template: "tile-landscape",
  title: "New Series for You",
  tiles: [
    {
      type: "landscapeTile",
      title: "Catherine the Great",
      imageUrl:
        "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/618c216e32b058f3287f8ce062bd5a48/{width}/{height}/item.jpg",
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_skyatlantic/408/84",
      hero: {
        mode: "standard",
        title: "Catherine the Great",
        synopsis:
          "Oscar-winner Helen Mirren stars as history’s most powerful female monarch in a much-anticipated Sky original about the tumultuous final years of the Russian empress's rule. All episodes available.",
        heroTitleImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/6f53d19485e36b1cc5670eaac4778ee1/herotitle.png",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/73d54bdf05d36241561c0261618c1762/hero.jpg",
        metadata: "1h 45m   ",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skyatlantic/408/84",
      },
    },
    {
      type: "landscapeTile",
      title: "Brassic",
      imageUrl:
        "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/bdf6e9c25bf2ad3f757f8fb0ca9d9e1b/{width}/{height}/item.jpg",
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_skyone/408/84",
      hero: {
        mode: "standard",
        title: "Brassic",
        synopsis:
          "A gang of inseparable mates thieve, bribe and joyride their way through life. But their criminal antics are about to catch up with them. A Sky original with Joe Gilgun and Michelle Keegan. All episodes available.",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/66720903b65f39533bf2f72005630dce/hero.jpg",
        metadata: "1h 45m   ",
        logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_skyone/408/84",
      },
    },
    {
      type: "landscapeTile",
      title: "Hotel Mumbai",
      imageUrl:
        "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/d89781b0a4aca55340fd86b404435c32/{width}/{height}/item.jpg",
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_skyone/408/84",
      hero: {
        mode: "standard",
        title: "Hotel Mumbai",
        synopsis:
          "The true story of the 2008 Taj Hotel terror attacks, shining a spotlight on the heroic hotel staff who risked their lives to protect their guests. A Sky original with Dev Patel. (2018)(123 mins)",
        heroTitleImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/bf0239d010f2db0982b2e8612c7b4db3/herotitle.png",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/13517b497866f1bf84d05aaa8b3f6fca/hero.jpg",
        metadata: "1h 45m   ",
        logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_skyone/408/84",
      },
    },
    {
      type: "landscapeTile",
      title: "Save Me",
      imageUrl:
        "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/92d98f4028e4d8236646e727f5f7a3b6/{width}/{height}/item.jpg",
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_skyatlantic/408/84",
      hero: {
        mode: "standard",
        title: "Save Me",
        synopsis:
          "Nelly Rowe's life is turned upside down when he is targeted as the chief suspect in his estranged daughter's disappearance. Six-part thriller, starring Lennie James. ",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/f9843d4d-5610-4b7f-abc9-fb7a6d3bb61a/background/1428",
        metadata: "1h 45m   ",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skyatlantic/408/84",
      },
    },
    {
      type: "landscapeTile",
      title: "Sick of It",
      imageUrl:
        "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/15ef29b9cc75d940caeeff47def8ac52/{width}/{height}/item.jpg",
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_skyone/408/84",
      hero: {
        mode: "standard",
        title: "Sick of It",
        synopsis:
          "Like Karl Pilkington? Us too. Not only did he co-create this comedy drama about a crabby cabbie and his inner self, he also stars in it... twice.  ",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/320255c6-ef44-4963-bffd-b8278ccd2ab4/background/1428",
        metadata: "1h 45m   ",
        logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_skyone/408/84",
      },
    },
    {
      type: "landscapeTile",
      title: "Extremely Wicked, Shockingly Evil and Vile",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/ae2107bb-40f0-4fca-8766-f17405f520b5/16-9/{width}",
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
      hero: {
        mode: "standard",
        title: "Extremely Wicked, Shockingly Evil and Vile",
        synopsis:
          "Sky Cinema original exploring the crimes of Ted Bundy (Zac Efron) from the perspective of his girlfriend Elizabeth Kloepfer. Strong language/violence/sex/mature themes. (2019)(108 mins)",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/ae2107bb-40f0-4fca-8766-f17405f520b5/background/1428",
        metadata: "1h 45m   ",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
      },
    },
    {
      type: "landscapeTile",
      title: "Patrick Melrose",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/41bf9bcb-ec98-45b1-9bc9-d5da42e7d929/16-9/{width}?providername=skyatlantic",
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_skyatlantic/408/84",
      hero: {
        mode: "standard",
        title: "Patrick Melrose",
        synopsis:
          "Benedict Cumberbatch stars as Patrick Melrose in an adaptation of Edward St Aubyn's acclaimed novels. ",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/41bf9bcb-ec98-45b1-9bc9-d5da42e7d929/background/1428",
        metadata: "1h 45m   ",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skyatlantic/408/84",
      },
    },
    {
      type: "landscapeTile",
      title: "In the Long Run",
      imageUrl:
        "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/a57adb8c868da2892eb98fad83c25797/{width}/{height}/item.jpg",
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_skyone/408/84",
      hero: {
        mode: "standard",
        title: "In the Long Run",
        synopsis:
          "Head back to the tower blocks and shell suits of 1980s East London for a second season of the charming Idris Elba comedy. A Sky original also starring Bill Bailey. All episodes available.",
        heroTitleImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/067ab838d59502dde21bcc8b3ba2d445/herotitle.png",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/dbad581eb0a642d6ea5f77bcf1b2ca16/hero.jpg",
        metadata: "1h 45m   ",
        logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_skyone/408/84",
      },
    },
    {
      type: "landscapeTile",
      title: "Monster Family",
      imageUrl:
        "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/2df6c70c356c06ac8372251ca9750986/{width}/{height}/item.jpg",
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
      hero: {
        mode: "standard",
        title: "Monster Family",
        synopsis:
          "The first Sky Cinema Original Film sees a young family cursed by a witch and transformed into monsters. Animated comedy with the voices of Emily Watson and Jason Isaacs. (2017)(90 mins)",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/9de9d887c96318e371e293620734c05e/hero.jpg",
        metadata: "1h 45m   ",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
      },
    },
    {
      type: "landscapeTile",
      title: "Chernobyl",
      imageUrl:
        "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/3f844a7133af8e4a3230910363f4eb9c/{width}/{height}/item.jpg",
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_skyatlantic/408/84",
      hero: {
        mode: "standard",
        title: "Chernobyl",
        synopsis:
          "Watch the most-awarded British series at the 2019 Emmys, with 10 wins. Jared Harris and Emily Watson star in the acclaimed Sky original exploring the truth about the Chernobyl disaster.",
        heroTitleImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/7995dafa82815d48e7f75f4d16c30a77/herotitle.png",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/16f80e192a37c6b12fbbb0ab65a4a7bc/hero.jpg",
        metadata: "1h 45m   ",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skyatlantic/408/84",
      },
    },
    {
      type: "titleTile",
      actionText: "View all",
      hero: {
        mode: "standard",
        heroTitleImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/7995dafa82815d48e7f75f4d16c30a77/herotitle.png",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/16f80e192a37c6b12fbbb0ab65a4a7bc/hero.jpg",
      },
    },
  ],
} as Rail;
