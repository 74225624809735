import React from "react";
import ReactPlayer from "react-player";
import { useSelector, useDispatch } from "store";
import { MAX_VOLUME } from "reducers/tv";
import { screen } from "constants/dimensions";

type Props = {
  videoUrl: string;
};

export const IntroVideo = ({ videoUrl }: Props) => {
  const { volume, muted } = useSelector(s => s.tv);
  const dispatch = useDispatch();
  return (
    <ReactPlayer
      style={{ position: "absolute", left: 0, top: 0 }}
      url={videoUrl}
      playing={true}
      loop={false}
      width={screen.width}
      height={screen.height}
      volume={volume / MAX_VOLUME}
      muted={muted}
      onEnded={() => dispatch({ type: "INTRO.ENDED" })}
    />
  );
};
