export const switcherTile = {
  width: 560,
  height: 315,
};

export const cover = {
  width: 261,
  height: 348,
};

export const hero = {
  width: 1428,
  height: 803,
};

export const landscape = {
  width: 456,
  height: 257,
};

export const promo = {
  width: 864,
  height: 486,
};

export const logo = {
  width: 408,
  height: 84,
};

export const category = {
  width: 414,
  height: 147,
};
