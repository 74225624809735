import { Rail } from "types";

export default {
  type: "rail",
  uuid: "b36a44e0-e7fb-43ba-80b8-c18ed206d069",
  template: "tile-cover",
  title: "Coming soon",
  tiles: [
    {
      type: "coverTile",
      title: "IT Chapter 2: Trailer",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/47e2f42e-43cb-4bd8-ab56-27c6a420c611/cover/{height}",
      hero: {
        mode: "standard",
        title: "IT Chapter 2: Trailer",
        metadata: "1h 45m   ",
        synopsis:
          "Watch the trailer for the second horrifying instalment based on Stephen King's novel It, starring James McAvoy and Jessica Chastain.",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/47e2f42e-43cb-4bd8-ab56-27c6a420c611/background/{width}",
      },
    },
    {
      type: "coverTile",
      title: "Ad Astra: Trailer",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/e81e06e5-b041-47de-bb8b-e9948ffdb8d0/cover/{height}",
      hero: {
        mode: "standard",
        title: "Ad Astra: Trailer",
        metadata: "1h 45m   ",
        synopsis:
          "Watch the trailer for the drama starring Brad Pitt as an astronaut trying to uncover the truth about his missing father and a doomed expedition.",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/21ee37f0926ec88575ce4406afd0fb22/hero.jpg",
      },
    },
    {
      type: "coverTile",
      title: "Charlie's Angels: Trailer",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/c2370224-eb43-4a1a-859f-e92cb0bfc63d/cover/{height}",
      hero: {
        mode: "standard",
        title: "Charlie's Angels: Trailer",
        metadata: "1h 45m   ",
        synopsis:
          "Charlie's Angels are called back into action and put their lives on the line when an engineer reveals a dangerous technology. Third film in the series starring Kristen Stewart.",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/ad5f5238ddaba1f8a86ff22a310402a0/hero.jpg",
      },
    },
    {
      type: "coverTile",
      title: "Joker: Trailer",
      imageUrl:
        "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/6e0760535a3da3e6ed282bebed90c301/{width}/{height}/item.jpg",
      hero: {
        mode: "standard",
        title: "Joker: Trailer",
        metadata: "1h 45m   ",
        synopsis:
          "Trailer for the chilling standalone story of the iconic villain, played by the Oscar-winning Joaquin Phoenix. ",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/5560c35e6d831499c4c2d4bd70e85205/hero.jpg",
      },
    },
    {
      type: "coverTile",
      title: "The Informer: Trailer",
      imageUrl:
        "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/824889bed4648ff0a3a612cbce367335/{width}/{height}/item.jpg",
      hero: {
        mode: "standard",
        title: "The Informer: Trailer",
        metadata: "1h 45m   ",
        synopsis:
          "An ex-convict working undercover intentionally gets himself incarcerated again in order to infiltrate the mob inside the prison. Strong language/violence/flashing images. (2019)",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/bc2e2d2d347474334f73b37c0ac6b7dd/hero.jpg",
      },
    },
    {
      type: "coverTile",
      title: "Dora and the Lost City of Gold",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/91333547-707c-4f7a-9e04-d1a8d565a53a/cover/{height}",
      hero: {
        mode: "standard",
        title: "Dora and the Lost City of Gold",
        metadata: "1h 45m   ",
        synopsis:
          "Despite years of exploring, nothing could prepare Dora for her biggest journey yet - high school. Live-action adaptation of the animated show. (2019)(102 mins)",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/91333547-707c-4f7a-9e04-d1a8d565a53a/background/{width}",
        heroTitleImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/428565932550936987e4df89c4f1ad7c/herotitle.png",
      },
    },
    {
      type: "coverTile",
      title: "Stuber",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/8252798d-0b39-4086-ad95-de58c7560bfd/cover/{height}",
      hero: {
        mode: "standard",
        title: "Stuber",
        metadata: "1h 45m   ",
        synopsis:
          "A wild journey ensues for a mild-mannered Uber driver when he picks up an aggressive cop who is on the hunt for a vicious killer. Action comedy with Dave Bautista. (2019)(93 mins)",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/8252798d-0b39-4086-ad95-de58c7560bfd/background/{width}",
      },
    },
  ],
} as Rail;
