import { State } from "reducers";
import { getBrand } from "data/brands";
import { RailsPage } from "types";
import { currentPage } from "selectors/currentPage";

const railsPageState = (state: State) => {
  const { pageState, page } = currentPage(state);
  if (pageState.pageType !== "railsPage") {
    throw new Error(
      `Expected pageType to be railsPage, not ${pageState.pageType} (pageId ${pageState.pageId})`
    );
  }
  return { pageState, page: page as RailsPage };
};

export const selectedRailIndex = (state: State) =>
  railsPageState(state).pageState.index;

export const selectedRail = (state: State) => {
  const {
    page,
    pageState: { index },
  } = railsPageState(state);
  return page.rails[index];
};

export const selectedTile = (state: State) => {
  const {
    page,
    pageState: { index, railIndices },
  } = railsPageState(state);
  const tileIndex = railIndices[index] || 0;
  return page.rails[index]?.tiles[tileIndex];
};

export const pageBrand = (state: State) => {
  const {
    page: { brandId },
  } = railsPageState(state);
  return brandId && getBrand(brandId);
};

export const railBrand = (state: State) => {
  const rail = selectedRail(state);
  return rail?.brandId && getBrand(rail.brandId);
};

export const onFirstRail = (state: State) => {
  const {
    pageState: { index },
  } = railsPageState(state);
  return index === 0;
};

export const onFirstRailAndTile = (state: State) => {
  const {
    pageState: { index, railIndices },
  } = railsPageState(state);
  return index === 0 && !railIndices[0];
};

// prev/next indices

export const prevRailIndex = (state: State) => {
  const {
    pageState: { index },
  } = railsPageState(state);
  return Math.max(index - 1, 0);
};

export const nextRailIndex = (state: State) => {
  const {
    pageState: { index },
    page: { rails },
  } = railsPageState(state);
  return Math.min(index + 1, rails.length - 1);
};

export const prevTileIndex = (state: State) => {
  const {
    pageState: { index, railIndices },
  } = railsPageState(state);
  const itemIndex = railIndices[index] || 0;
  return Math.max(itemIndex - 1, 0);
};

export const nextTileIndex = (state: State) => {
  const {
      pageState: { index, railIndices },
      page: { rails },
    } = railsPageState(state),
    itemIndex = railIndices[index] || 0,
    numItems = rails[index].tiles.length;
  return Math.min(itemIndex + 1, numItems - 1);
};
