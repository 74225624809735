import React, { useState } from "react";
import { useKeys } from "hooks/useKeys";
import { useDispatch, useSelector } from "store";
import { TVGuideOverlayDays } from "./TVGuideOverlayDays";
import { Selector } from "stores/Selector";
import { addDays, differenceInDays, startOfDay } from "date-fns";
import { observer } from "mobx-react-lite";
import range from "lodash/range";
import { formatDay, formatDayDifference } from "utils/timeHelpers";
import { selectedDate } from "../../selectors/pages/tvGuide";
import { tvCategoriesIsShowing } from "selectors/browse";
import { sidebar } from "constants/dimensions";

type Props = { focused: boolean };

export const TVGuideOverlayDaysController = observer(({ focused }: Props) => {
  const dispatch = useDispatch();
  const currentDate = useSelector(selectedDate),
    hasXOffset = useSelector(tvCategoriesIsShowing);

  const [daySelector] = useState(() => {
    const today = new Date(),
      next7Days = range(0, 7).map(i => {
        const date = addDays(today, i);
        return {
          title: formatDay(date, today, true),
          date,
        };
      });
    const currentIndex = differenceInDays(
      startOfDay(currentDate),
      startOfDay(today)
    );
    return new Selector(next7Days, currentIndex);
  });

  useKeys(focused, ({ button }) => {
    switch (button) {
      case "RIGHT":
        daySelector.next();
        break;
      case "LEFT":
        daySelector.prev();
        break;
      case "BACK":
        dispatch({ type: "BROWSE.HIDE_TV_DAYS" });
        break;
      case "SELECT":
        dispatch({ type: "BROWSE.HIDE_TV_DAYS" });
        dispatch({
          type: "TVGUIDE.CHANGE_DAY",
          date: daySelector.item.date,
        });
        break;
    }
  });
  return (
    <TVGuideOverlayDays
      days={daySelector.items.map(i => i.title)}
      index={daySelector.index}
      minilabel={formatDayDifference(daySelector.item.date, new Date())}
      offsetX={hasXOffset ? sidebar.extraWidth : 0}
    />
  );
});
