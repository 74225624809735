import { Rail } from "types";

export default {
  type: "rail",
  uuid: "d926b965-67b4-49ea-9a60-7b7c655bad8b",
  template: "tile-cover",
  title: "Trending",
  tiles: [
    {
      type: "coverTile",
      title: "Jay And Silent Bob Reboot",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/468ee3a6-aff3-4f67-a982-eab45f086a81/cover/{height}",
      hero: {
        mode: "standard",
        title: "Jay And Silent Bob Reboot",
        metadata: "1h 45m   ",
        synopsis:
          "Jay and Silent Bob return once more as they embark on a cross-country mission across the USA, to stop a re-boot of a film they both hated. (2019)(105 mins)",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/468ee3a6-aff3-4f67-a982-eab45f086a81/background/{width}",
      },
    },
    {
      type: "coverTile",
      title: "The Wolf Hour",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/6757e97f-82a1-4d1c-875f-494cb69cf659/cover/{height}",
      hero: {
        mode: "standard",
        title: "The Wolf Hour",
        metadata: "1h 45m   ",
        synopsis:
          "As a wave of violence and murder sweeps New York City in 1977, a troubled woman tries to hide from the chaos in her apartment. Psychological drama starring Naomi Watts. (2019)(99 mins)",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/6757e97f-82a1-4d1c-875f-494cb69cf659/background/{width}",
      },
    },
    {
      type: "coverTile",
      title: "Beyond the River",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/c63de32d-52b5-4623-a3db-376d1cef1d6c/cover/{height}",
      hero: {
        mode: "standard",
        title: "Beyond the River",
        metadata: "1h 45m   ",
        synopsis:
          "Drama following the quest of two men who share one goal: to win gold in the most arduous canoe marathon. Based on a remarkable true story. (2017)(108 mins)",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/c63de32d-52b5-4623-a3db-376d1cef1d6c/background/{width}",
      },
    },
    {
      type: "coverTile",
      title: "Standing Up, Falling Down",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/c9b29bba-1b34-40e9-8919-b0a68e7e7345/cover/{height}",
      hero: {
        mode: "standard",
        title: "Standing Up, Falling Down",
        metadata: "1h 45m   ",
        synopsis:
          "A failed stand-up comedian returns home to Long Island, where he forms an unlikely friendship with a similarly troubled man. Billy Crystal stars. (2019)(91 mins)",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/c9b29bba-1b34-40e9-8919-b0a68e7e7345/background/{width}",
      },
    },
    {
      type: "coverTile",
      title: "Annabelle Comes Home",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/ae570efa-d64b-4b2e-8f46-ac3cb0521ea4/cover/{height}",
      hero: {
        mode: "standard",
        title: "Annabelle Comes Home",
        metadata: "1h 45m   ",
        synopsis:
          "The possessed doll strikes again in the horror sequel, as paranormal investigators Ed and Lorraine Warren make the grave mistake of locking it in a room in their house. (2019)(106 mins)",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/ae570efa-d64b-4b2e-8f46-ac3cb0521ea4/background/{width}",
        heroTitleImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/5fdf1d56d85c4eae48bcb8328ef2c8a0/herotitle.png",
      },
    },
    {
      type: "coverTile",
      title: "The Kitchen",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/13d760a2-5e75-4b43-8a27-ba26b6296f16/cover/{height}",
      hero: {
        mode: "standard",
        title: "The Kitchen",
        metadata: "1h 45m   ",
        synopsis:
          "In 1970s New York, three mob wives take business into their own hands when their husbands are sent to prison. Melissa McCarthy, Tiffany Haddish and Elisabeth Moss star. (2019)(103 minutes)",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/13d760a2-5e75-4b43-8a27-ba26b6296f16/background/{width}",
        heroTitleImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/e0422a5d83e79c9196674d086e88ebac/herotitle.png",
      },
    },
    {
      type: "coverTile",
      title: "Blood on Her Name",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/557385f1-412d-4e98-97ef-fcd553be9f83/cover/{height}",
      hero: {
        mode: "standard",
        title: "Blood on Her Name",
        metadata: "1h 45m   ",
        synopsis:
          "Things spiral out of control for a woman who is haunted by her panicked decision to cover up an accidental killing. Thriller with Bethany Anne Lind. (2019)(85 mins)",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/557385f1-412d-4e98-97ef-fcd553be9f83/background/{width}",
      },
    },
    {
      type: "coverTile",
      title: "Mr. Jones",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/1468982f-6de3-4583-b07b-264bb07ec667/cover/{height}",
      hero: {
        mode: "standard",
        title: "Mr. Jones",
        metadata: "1h 45m   ",
        synopsis:
          "A Welsh journalist breaks the news of the famine in the Soviet Union in the early 1930s. Drama with James Norton. (2019)(115 mins)",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/1468982f-6de3-4583-b07b-264bb07ec667/background/{width}",
      },
    },
    {
      type: "coverTile",
      title: "Strike",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/588ccac6-78c6-46e1-be9f-cea41fc4e158/cover/{height}",
      hero: {
        mode: "standard",
        title: "Strike",
        metadata: "1h 45m   ",
        synopsis:
          "A young and ambitious mole wants to become a footballer and save his town's gold mine from greedy super villain 'The Boss'. Fun animation. (2018)(96 mins)",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/588ccac6-78c6-46e1-be9f-cea41fc4e158/background/{width}",
      },
    },
    {
      type: "coverTile",
      title: "Crawl",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/7b7ee267-1578-41e7-87f4-30ea1988e169/cover/{height}",
      hero: {
        mode: "standard",
        title: "Crawl",
        metadata: "1h 45m   ",
        synopsis:
          "When her hometown is devastated by a hurricane, a teenager returns to find her missing father. But rising waters may be the least of their worries. (2019)(87 mins)",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/7b7ee267-1578-41e7-87f4-30ea1988e169/background/{width}",
        heroTitleImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/7dacbf40bc3bc432f334c809f682770d/herotitle.png",
      },
    },
  ],
} as Rail;
