import { Rail } from "types";

export default {
  type: "rail",
  uuid: "b2856047-a26b-421c-8846-b1a9a05f3cc8",
  template: "tile-cover",
  title: "Sky Store",
  tiles: [
    {
      type: "coverTile",
      title: "Trolls",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/bb31afa0-8c11-4d3b-a83c-4e3da1bca591/cover/{height}",
      hero: {
        mode: "standard",
        title: "Trolls",
        synopsis:
          "A pair of fuzzy-headed trolls embark on a daring rescue mission when members of their tribe are kidnapped. Animation with the voice of Anna Kendrick. Flashing images. (2016)(110 mins)",
        heroTitleImageUrl: "assets/images/content/trolls/trolls_hero_title.png",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/bb31afa0-8c11-4d3b-a83c-4e3da1bca591/background/1428",
        metadata: "1h 45m   ",
      },
    },
    {
      type: "coverTile",
      title: "Easter Sale",
      imageUrl:
        "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/d5d3e2ce17d2b0c88b2b0794ad185eb8/{width}/{height}/item.jpg",
      hero: {
        mode: "standard",
        title: "Easter Sale",
        synopsis:
          "We have some egg-cellent titles at cracking prices in our Sky Store Easter Sale. Downton Abbey, Angel Has Fallen, Detective Pikachu and more are ready to keep you entertained, for less!",
        heroTitleImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/a0ebab3f528f9544641c15a796de24b2/herotitle.png",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/2d08ad6643e22c4b5e2b15bbd461b955/hero.jpg",
        metadata: "1h 45m   ",
      },
    },
    {
      type: "coverTile",
      title: "Star Wars Sale",
      imageUrl:
        "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/320afd996dfe55c7968cea734a9285ad/{width}/{height}/item.jpg",
      hero: {
        mode: "standard",
        title: "Star Wars Sale",
        synopsis:
          "To celebrate the release of Star Wars: The Rise of Skywalker, feel the force with discounts across the rest of the Star Wars franchise!",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/c704d9374b8949384c6da166c5088cc5/hero.jpg",
        metadata: "1h 45m   ",
      },
    },
    {
      type: "coverTile",
      title: "Jumanji 1-2",
      imageUrl:
        "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/b1495ed141f0ac9d73ab941d867861ed/{width}/{height}/item.jpg",
      hero: {
        mode: "standard",
        title: "Jumanji 1-2",
        synopsis:
          "Dwayne Johnson, Kevin Hart and Jack Black find themselves trapped within the fantastical world of Jumanji in these two fantasy-comedy hits. Strong language/violence/flashing images.",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/8fe341f7bf9aa4209099f9d03950f326/hero.jpg",
        metadata: "1h 45m   ",
      },
    },
    {
      type: "coverTile",
      title: "Family Favourites",
      imageUrl:
        "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/4cfd5e76ff69ea70bd1ad2c9c86bd5bc/{width}/{height}/item.jpg",
      hero: {
        mode: "standard",
        title: "Family Favourites",
        synopsis:
          "Settle in with our selection of films which the whole family can enjoy.",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/e36b4a32ce6029922589c1f26359482b/hero.jpg",
        metadata: "1h 45m   ",
      },
    },
    {
      type: "coverTile",
      title: "Box Set Binge",
      imageUrl:
        "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/918287742d6a1ff354855a5cb532bb11/{width}/{height}/item.jpg",
      hero: {
        mode: "standard",
        title: "Box Set Binge",
        synopsis:
          "Immerse yourself in some of the best TV content on Sky Store, such as Succession, Peaky Blinders and Line of Duty.",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/ac878cdf360fcd32fa0b80a8c9deb815/hero.jpg",
        metadata: "1h 45m   ",
      },
    },
    {
      type: "coverTile",
      title: "The Biggest Little Farm",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/c809f5bc-5ed7-475e-b770-c103519b0767/cover/{height}",
      hero: {
        mode: "standard",
        title: "The Biggest Little Farm",
        synopsis:
          "On the edge of Los Angeles, documentarian John Chester and his wife Molly work to develop a modern-day sustainable farm - this is their story. Mature themes/flashing images. (2018)(91 mins)",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/c809f5bc-5ed7-475e-b770-c103519b0767/background/1428",
        metadata: "1h 45m   ",
      },
    },
  ],
} as Rail;
