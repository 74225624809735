import React, { useRef } from "react";
import styled from "@emotion/styled";
import { useAnimations } from "hooks/useAnimations";
import gsap from "gsap";
import { transitions } from "constants/transitions";

type Props = {
  width: number;
  height: number;
  animationScope?: string;
  borderRadius?: 6 | 12;
};

const MARGIN = 50,
  BORDER_WIDTH = 3;

const Frame = styled.div(({ borderRadius }: { borderRadius: number }) => {
  const m = MARGIN,
    r = borderRadius + BORDER_WIDTH / 2;
  return `
  position: absolute;
  top: 0;
  left: 0;
  border-image-source: url(assets/images/glass-lens/border-rad-${borderRadius}.svg);
  border-radius: ${r}px;
  border-image-width: ${m + r}px ${r}px ${r}px ${m + r}px;
  border-image-slice: ${m + r} ${r} ${r} ${m + r} fill;
  border-image-outset: ${m}px 0 0 ${m}px;
  border-image-repeat: stretch;
  background: url(assets/images/glass-lens/glass-top.svg) no-repeat;
  background-size: contain;
  box-shadow: 30px 30px 60px 0px rgba(0, 0, 0, 0.3);
`;
});

const Glint = styled.div`
  width: 510px;
  height: 322px;
  background: transparent no-repeat top left
    url(assets/images/glass-lens/glint.png);
  position: absolute;
  left: -32px;
  top: -24px;
`;

const transition = (frameElement: HTMLElement) =>
  gsap.getProperty(frameElement, "width")
    ? transitions.moveLens
    : { duration: 0 };

export const GlassFrame = ({
  width,
  height,
  animationScope,
  borderRadius,
}: Props) => {
  borderRadius = borderRadius || 12;

  const frame = useRef(null);
  useAnimations(
    {
      scope: animationScope,
      uninterruptible: ["moveLens"],
      animations: {
        // Need this as well as defaultAnimation so that the timeline syncs properly with other animations
        moveLens: (params, timeline) =>
          timeline.to(frame.current, {
            ...params,
            ...transition(frame.current),
          }),
      },
      defaultAnimation: (params, timeline) =>
        timeline.to(frame.current, {
          ...params,
          ...transition(frame.current),
        }),
      reset: params => gsap.set(frame.current, params),
    },
    { width, height }
  );

  return (
    <Frame ref={frame} borderRadius={borderRadius}>
      <Glint />
    </Frame>
  );
};
