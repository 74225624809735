import React, { useLayoutEffect, useRef } from "react";
import {
  Container,
  MinilabelContainer,
  DaysContainer,
  Days,
} from "./TVGuideOverlayDays.styles";
import { Text } from "components/Text";
import { useDefaultAnimation } from "hooks/useDefaultAnimation";
import gsap from "gsap";

type Props = {
  days: string[];
  minilabel?: string;
  index: number;
  offsetX?: number;
};

function getXForItem(container?: HTMLDivElement, index?: number) {
  if (!container || !container.children[index]) {
    return 0;
  }
  const el = container.children[index] as HTMLElement;
  return el.offsetLeft;
}

function getOpacity(idx: number, focusedIdx: number) {
  const dist = Math.abs(idx - focusedIdx);
  return 1 / (1 + dist * dist);
}

export const TVGuideOverlayDays = ({
  days,
  minilabel,
  index,
  offsetX,
}: Props) => {
  const daysRef = useRef(null);

  useDefaultAnimation(daysRef, {
    x: -getXForItem(daysRef.current, index),
  });

  // Set x manually first time because getXForItem will always return 0 on first render
  useLayoutEffect(() => {
    const div = daysRef.current;
    gsap.set(div, { x: -getXForItem(div, index) });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container offsetX={offsetX}>
      <MinilabelContainer>
        <Text size="medium" weight="medium" style={{ whiteSpace: "nowrap" }}>
          {minilabel}
        </Text>
      </MinilabelContainer>
      <DaysContainer>
        <Days ref={daysRef}>
          {days.map((day, i) => (
            <Text
              key={i}
              size="ginormous"
              weight="light"
              style={{
                marginRight: 100,
                opacity: getOpacity(i, index),
                transition: "opacity .5s",
              }}
            >
              {day}
            </Text>
          ))}
        </Days>
      </DaysContainer>
    </Container>
  );
};
