import { Rail } from "types";

export default {
  type: "rail",
  uuid: "46d0c54a-efd3-4c4d-8a06-2abb96dc10a4",
  template: "tile-landscape",
  title: "Restart what's on now",
  tiles: [
    {
      type: "landscapeTile",
      title: "The Big Wish",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/1e11efb2-2c23-4656-83a3-727949c0e0e0/16-9/{width}",
      hero: {
        mode: "standard",
        title: "The Big Wish",
        synopsis:
          "A 16-year-old orphan sets off on an adventure to find the missing links to her family's heritage in the hope of finally getting to meet her long-lost parents. Animation. (2019)(90 mins)",
        metadata: "1h 45m   ",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/1e11efb2-2c23-4656-83a3-727949c0e0e0/background/{width}",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
    },
    {
      type: "landscapeTile",
      title: "Book Club",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/070e98a1-6a49-4f0e-b406-430cb5897145/16-9/{width}",
      hero: {
        mode: "standard",
        title: "Book Club",
        synopsis:
          "Four sixty-something friends find their lives turned upside down after reading Fifty Shades of Grey. Diane Keaton stars. Strong language/mature themes/flashing images. (2018)(90 mins)",
        metadata: "1h 45m   ",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/070e98a1-6a49-4f0e-b406-430cb5897145/background/{width}",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
    },
    {
      type: "landscapeTile",
      title: "Fantastic Beasts and Where to Find Them",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/3af6bc82-554d-482e-82e3-1463565dbf8d/16-9/{width}",
      hero: {
        mode: "standard",
        title: "Fantastic Beasts and Where to Find Them",
        synopsis:
          "...Find Them: Harry Potter spin-off starring Eddie Redmayne as a wizarding Brit who arrives in 1920s New York with a bagful of magical beasts. With Katherine Waterston. (2016)(128 mins)",
        metadata: "1h 45m   ",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/3af6bc82-554d-482e-82e3-1463565dbf8d/background/{width}",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
    },
    {
      type: "landscapeTile",
      title: "Hancock",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/7b464033-e2fd-409e-bbbb-879a74525a3e/16-9/{width}",
      hero: {
        mode: "standard",
        title: "Hancock",
        synopsis:
          "Alcoholic superhero Will Smith hires a PR officer to help improve his image. With Charlize Theron. Strong language/violence/flashing images. (2008)(98 mins)",
        metadata: "1h 45m   ",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/7b464033-e2fd-409e-bbbb-879a74525a3e/background/{width}",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
    },
    {
      type: "landscapeTile",
      title: "Matilda",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/a6fe5573-6a65-4537-9af8-f015300a2c4c/16-9/{width}",
      hero: {
        mode: "standard",
        title: "Matilda",
        synopsis:
          "Danny DeVito stars in Roald Dahl's magical story about a little girl who develops superpowers to cope with her horrible parents and cruel headmistress. Flashing images. (1996)(95 mins)",
        metadata: "1h 45m   ",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/a6fe5573-6a65-4537-9af8-f015300a2c4c/background/{width}",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
    },
  ],
} as Rail;
