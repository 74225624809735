import { Hero, LinearEventLookup, PageId, Schedule } from "types";
import { Action } from "types/actions";

import {
  eventIndexForDate,
  lastStartedEventIndex,
  slotIndexForEvent,
  slotIndexFromDate,
} from "../../../helpers/tvGuideHelpers";
import { startOfDay } from "date-fns";
import { getPage } from "data/pages";

export type FocusArea = "rail" | "channels" | "grid";

export type TVGuidePageState = Readonly<{
  pageType: "tvGuidePage";
  pageId: PageId;
  loading: boolean;
  schedule?: Schedule;
  slotIndex?: number;
  focusArea?: FocusArea;
  showRail: boolean;
  tileIndex: number;
  channelIndex: number;
  eventIndex: number;
  midnight?: Date;
  hero?: Hero;
  overrideHeroFields?: Partial<Hero>;
  onNowLookup: LinearEventLookup;
}>;

export const newPageState = (pageId: PageId): TVGuidePageState => ({
  pageType: "tvGuidePage",
  pageId,
  loading: false,
  schedule: undefined,
  slotIndex: undefined,
  focusArea: undefined,
  showRail: true,
  tileIndex: 0,
  channelIndex: 0,
  eventIndex: 0,
  midnight: undefined,
  hero: undefined,
  overrideHeroFields: undefined,
  onNowLookup: {},
});

export const tvGuidePageReducer = (
  state: TVGuidePageState,
  action: Action
): TVGuidePageState => {
  switch (action.type) {
    case "TVGUIDE.LOADING":
      return { ...state, loading: true };

    case "TVGUIDE.LOADED_PAGE": {
      const events = action.schedule.channels[state.channelIndex].events;
      if (!events.length) return state;
      const midnight = startOfDay(action.date);
      return {
        ...state,
        loading: false,
        schedule: action.schedule,
        midnight,
        slotIndex: slotIndexFromDate(action.date, midnight),
        eventIndex: eventIndexForDate(action.date, events),
        focusArea: state.focusArea || "rail",
      };
    }

    case "TVGUIDE.ON_NOW_LOADED":
      return { ...state, onNowLookup: action.eventLookup };

    case "TVGUIDE.SET_FOCUS_AREA":
      return { ...state, focusArea: action.area };

    case "TVGUIDE.FOCUS_ON_RAIL":
      return { ...state, focusArea: "rail", showRail: true };

    case "TVGUIDE.FOCUS_ON_EPG":
      return { ...state, focusArea: "channels", showRail: false };

    case "TVGUIDE.PREV_TILE":
      return { ...state, tileIndex: Math.max(0, state.tileIndex - 1) };

    case "TVGUIDE.NEXT_TILE": {
      const { rail } = getPage(state.pageId, state.pageType);
      return {
        ...state,
        tileIndex: Math.min(rail.tiles.length - 1, state.tileIndex + 1),
      };
    }

    case "TVGUIDE.NEXT_CHANNEL": {
      if (!state.schedule) return state;
      const maxChannel = state.schedule.channels.length - 1;
      const channelIndex = Math.min(maxChannel, state.channelIndex + 1);
      return {
        ...state,
        channelIndex,
        eventIndex:
          lastStartedEventIndex(
            state.slotIndex,
            state.schedule.channels[channelIndex].events,
            state.midnight
          ) || 0,
      };
    }

    case "TVGUIDE.PREV_CHANNEL":
      if (!state.schedule) return state;
      const channelIndex = Math.max(0, state.channelIndex - 1);
      return {
        ...state,
        channelIndex,
        eventIndex:
          lastStartedEventIndex(
            state.slotIndex,
            state.schedule.channels[channelIndex].events,
            state.midnight
          ) || 0,
      };

    case "TVGUIDE.NEXT_EVENT": {
      if (!state.schedule) return state;
      const events = state.schedule.channels[state.channelIndex].events;
      const maxEvent = events.length - 1;
      const eventIndex = Math.min(maxEvent, state.eventIndex + 1);
      return {
        ...state,
        eventIndex,
        slotIndex: slotIndexForEvent(events[eventIndex], state.midnight),
      };
    }

    case "TVGUIDE.PREV_EVENT": {
      if (!state.schedule) return state;
      const eventIndex = Math.max(0, state.eventIndex - 1);
      const event =
        state.schedule.channels[state.channelIndex].events[eventIndex];
      return {
        ...state,
        eventIndex,
        slotIndex: slotIndexForEvent(event, state.midnight),
      };
    }

    case "TVGUIDE.UPDATE_HERO": {
      return { ...state, hero: action.hero };
    }

    case "TVGUIDE.UPDATE_OVERRIDE_HERO_FIELDS": {
      return { ...state, overrideHeroFields: action.heroFields };
    }

    case "TVGUIDE.GO_TO_LAST_CHANNEL":
      return state.schedule
        ? {
            ...state,
            focusArea: "channels",
            showRail: false,
            channelIndex: state.schedule.channels.length - 1,
          }
        : state;

    default:
      return state;
  }
};
