import { getPage } from "data/pages";
import { tilesPerRail } from "selectors/gridPage";
import { PageId } from "types";
import { Action } from "types/actions";

type Direction = "up" | "down" | "left" | "right";

export type GridPageState = Readonly<{
  pageType: "gridPage";
  pageId: PageId;
  index: number;
}>;

export const newPageState = (pageId: PageId): GridPageState => ({
  pageType: "gridPage",
  pageId,
  index: 0,
});

const move = (
  direction: Direction,
  index: number,
  numPerRail: number,
  totalNumTiles: number
) => {
  switch (direction) {
    case "up":
      return index >= numPerRail ? index - numPerRail : index;
    case "down":
      return index + numPerRail < totalNumTiles ? index + numPerRail : index;
    case "left":
      return index % numPerRail > 0 ? index - 1 : index;
    case "right":
      return index % numPerRail < numPerRail - 1 ? index + 1 : index;
  }
};

export const gridMove = (direction: Direction, pageState: GridPageState) => {
  // TODO: do what we did with railsPage: pass in the index via the action
  // so that we don't use getPage here
  const page = getPage(pageState.pageId, pageState.pageType),
    numPerRail = tilesPerRail(page),
    totalNumTiles = page.tiles.length;
  return {
    ...pageState,
    index: move(direction, pageState.index, numPerRail, totalNumTiles),
  };
};

export const gridPageReducer = (state: GridPageState, action: Action) => {
  switch (action.type) {
    case "GRID.UP":
      return gridMove("up", state);

    case "GRID.DOWN":
      return gridMove("down", state);

    case "GRID.LEFT":
      return gridMove("left", state);

    case "GRID.RIGHT":
      return gridMove("right", state);

    case "GRID.RESET":
      return { ...state, index: 0 };

    default:
      return state;
  }
};
