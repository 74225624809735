import React, { useState } from "react";
import { Container, RailContainer, ItemContainer } from "./QAMMenu.styles";
import { quickAccessMenu } from "constants/dimensions";
import { QAMItem } from "types";
import { RailSelector } from "components/RailSelector";
import { SimpleQAMItemController } from "components/SimpleQAMItem";
import { ToggleQAMItemController } from "components/ToggleQAMItem";
import { Selector } from "stores/Selector";
import { observer } from "mobx-react-lite";
import { useKeys } from "hooks/useKeys";
import { animations } from "animations";
import { useDispatch } from "store";

type Props = { focused: boolean; items: QAMItem[]; itemIndex: number };

const itemComponent = ({
  item,
  focusedStyle,
  focused,
}: {
  item: QAMItem;
  focusedStyle: boolean;
  focused: boolean;
}) => {
  switch (item.type) {
    case "simpleQAMItem":
      return (
        <SimpleQAMItemController
          focusedStyle={focusedStyle}
          focused={focused}
          title={item.title}
          iconUrl={
            focusedStyle && item.focusedIconUrl
              ? item.focusedIconUrl
              : item.iconUrl
          }
          action={item.action}
        />
      );
    case "toggleQAMItem":
      return (
        <ToggleQAMItemController
          focusedStyle={focusedStyle}
          focused={focused}
          title={item.title}
          iconUrl={
            focusedStyle && item.focusedIconUrl
              ? item.focusedIconUrl
              : item.iconUrl
          }
          storeId={item.storeId}
        />
      );
  }
};

export const QAMMenu = ({ items, itemIndex, focused }: Props) => (
  <Container>
    <RailContainer>
      <RailSelector
        items={focusedStyle =>
          items.map((item, i) => (
            <ItemContainer key={i} focusedStyle={focusedStyle}>
              {itemComponent({
                item,
                focusedStyle,
                focused: focusedStyle && i === itemIndex,
              })}
            </ItemContainer>
          ))
        }
        index={itemIndex}
        width={quickAccessMenu.innerWidth}
        height={quickAccessMenu.item.height}
        focused={focused}
        focusedExtraWidth={quickAccessMenu.item.gap * 2}
        focusedHeight={quickAccessMenu.item.focusedHeight}
        animationScope="quickAccessMenu"
      />
    </RailContainer>
  </Container>
);

//---------------------------------------------------

type ControllerProps = { focused: boolean };

export const QAMMenuController = observer(({ focused }: ControllerProps) => {
  const [selector] = useState(
    () =>
      new Selector<QAMItem>([
        {
          type: "simpleQAMItem",
          title: "Search",
          iconUrl: "assets/images/quickAccessMenu/search.png",
          action: {
            type: "GO_TO_PAGE",
            pageType: "imagePage",
            pageId: "search",
          },
        },
        {
          type: "simpleQAMItem",
          title: "Cameras",
          iconUrl: "assets/images/quickAccessMenu/cameras.svg",
          action: { type: "QUICK_ACCESS_MENU.SHOW", tab: "cameras" },
        },
        {
          type: "toggleQAMItem",
          title: "Audio Description",
          iconUrl: "assets/images/quickAccessMenu/audio-desc.png",
          storeId: "audioDescription",
        },
        {
          type: "simpleQAMItem",
          title: "HDMI",
          iconUrl: "assets/images/quickAccessMenu/source.png",
        },
        {
          type: "simpleQAMItem",
          title: "Subtitles",
          iconUrl: "assets/images/quickAccessMenu/subtitles.png",
          focusedIconUrl: "assets/images/quickAccessMenu/subtitles-focused.png",
        },
        {
          type: "simpleQAMItem",
          title: "Help",
          iconUrl: "assets/images/quickAccessMenu/help.png",
        },
      ])
  );

  const dispatch = useDispatch();

  useKeys(focused, ({ button }) => {
    switch (button) {
      case "LEFT":
        selector.prev();
        animations.animate("moveLens", "quickAccessMenu");
        break;
      case "RIGHT":
        selector.next();
        animations.animate("moveLens", "quickAccessMenu");
        break;
      case "BACK":
        dispatch({ type: "QUICK_ACCESS_MENU.HIDE" });
        break;
    }
  });

  return (
    <QAMMenu
      focused={focused}
      items={selector.items}
      itemIndex={selector.index}
    />
  );
});
