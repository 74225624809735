import React, { useRef } from "react";
import { useSelector, useDispatch } from "store";
import { Shell } from "./AppShell.styles";
import { Background } from "components/Background";
import { Toast } from "components/Toast";
import { Switcher } from "components/Switcher";
import { HeroController } from "components/Hero";
import { PageTitle } from "components/PageTitle";
import { Browse } from "components/Browse";
import { IntroVideo } from "components/IntroVideo";
import { pageTitle as pageTitleSelector } from "selectors/pageTitle";
import { VolumePanel } from "components/VolumePanel";
import { Settings } from "components/Settings";
import { BrowseTabsController } from "components/BrowseTabs";
import { Clock } from "components/Clock";
import { QuickAccessMenuController } from "components/QuickAccessMenu";
import { useDefaultAnimation } from "hooks/useDefaultAnimation";
import { boolToInt } from "utils/boolToInt";
import { Glance } from "components/Glance";
import { TVGuideCategoriesController } from "components/TVGuideCategories";

export const AppShell = () => {
  const dispatch = useDispatch(),
    state = useSelector(s => s),
    {
      fullscreenMode,
      pageBackgroundSrc,
      introVideoUrl,
      settingsOpen,
      mode,
    } = state.app,
    toast = state.toast,
    tv = state.tv,
    pageTitle = pageTitleSelector(state),
    settings = state.settings,
    topLeftPageTitle =
      settings.pageTitlePosition === "top-left" ? pageTitle?.text : undefined,
    timeMs = state.time.timeMs;
  const shellRef = useRef(null);

  useDefaultAnimation(shellRef, { opacity: boolToInt(!tv.standbyMode) });

  return (
    <>
      <Shell ref={shellRef}>
        <Background src={pageBackgroundSrc} key={pageBackgroundSrc} />
        <TVGuideCategoriesController />
        <HeroController />
        <Browse />
        <BrowseTabsController />
        <Switcher />
        {settings.pageTitlePosition === "top-right" && (
          <PageTitle
            pageTitle={pageTitle}
            position={settings.pageTitlePosition}
          />
        )}
        <Glance visible={mode === "glance"} />
        <Clock visible={!fullscreenMode} timeMs={timeMs} />
        <Toast
          message={toast.message || topLeftPageTitle}
          quote={toast.quote}
          status={toast.status}
          mode={fullscreenMode ? "overlay" : "header"}
          showSeparator={!!topLeftPageTitle}
        />
        {introVideoUrl && <IntroVideo videoUrl={introVideoUrl} />}
        <VolumePanel
          volume={tv.volume}
          muted={tv.muted}
          visible={tv.volumeVisible}
        />
        <QuickAccessMenuController />
        <Settings
          open={settingsOpen}
          settings={settings}
          onUpdateSettings={settings =>
            dispatch({ type: "SETTINGS.UPDATE", settings })
          }
          onClose={() => dispatch({ type: "SETTINGS.TOGGLE" })}
        />
      </Shell>
    </>
  );
};
