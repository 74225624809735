import { Rail } from "types";

export default {
  type: "rail",
  uuid: "337dd604-77b9-4272-9f0a-e6e0987f1b63",
  template: "tile-cover",
  title: "Must See Movies",
  tiles: [
    {
      type: "coverTile",
      title: "The Godfather",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/31d3ae92-0a9a-4b6f-8bce-ff85c1a17170/cover/528",
      hero: {
        mode: "standard",
        title: "The Godfather",
        synopsis:
          "Marlon Brando won Best Actor as the ageing Mafia don replaced by steely son Al Pacino. Francis Ford Coppola's towering Mob epic. Strong language/violence. (1972)(169 mins)",
        heroTitleImageUrl:
          "assets/images/content/the_godfather/the_godfather_hero_title.png",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/31d3ae92-0a9a-4b6f-8bce-ff85c1a17170/background/1428",
        metadata: "1h 45m   ",
      },
    },
    {
      type: "coverTile",
      title: "Star Wars: Episode VI - Return of the Jedi",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/c9ebcf48-b35b-400f-9c68-7abe5f4c70d8/cover/{height}",
      hero: {
        mode: "standard",
        title: "Star Wars: Episode VI - Return of the Jedi",
        synopsis:
          "The Empire and the Rebellion prepare for a final stand-off as Luke confronts Darth Vader. With Mark Hamill, Harrison Ford and the late Carrie Fisher. Flashing images. (1983)(130 mins)",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/c9ebcf48-b35b-400f-9c68-7abe5f4c70d8/background/1428",
        metadata: "1h 45m   ",
      },
    },
    {
      type: "coverTile",
      title: "Star Wars: Episode V - The Empire Strikes Back",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/56d01716-2e26-4a6e-af27-aed4f8c905be/cover/{height}",
      hero: {
        mode: "standard",
        title: "Star Wars: Episode V - The Empire Strikes Back",
        synopsis:
          "Luke Skywalker travels to a distant planet to train with Jedi master Yoda while Han and Leia (the late Carrie Fisher) are pursued by the Empire. Flashing images. (1980)(122 mins)",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/56d01716-2e26-4a6e-af27-aed4f8c905be/background/1428",
        metadata: "1h 45m   ",
      },
    },
    {
      type: "coverTile",
      title: "Star Wars: Episode IV - A New Hope",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/023f9fd3-80a2-4415-9fe1-02df22049e82/cover/{height}",
      hero: {
        mode: "standard",
        title: "Star Wars: Episode IV - A New Hope",
        synopsis:
          "Luke Skywalker joins forces with Jedi Knight Obi-Wan Kenobi and cocky pilot Han Solo to rescue Princess Leia (the late Carrie Fisher). Iconic sci-fi. Flashing images. (1977)(125 mins)",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/023f9fd3-80a2-4415-9fe1-02df22049e82/background/1428",
        metadata: "1h 45m   ",
      },
    },
    {
      type: "coverTile",
      title: "The Bourne Supremacy",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/443462dd-e33a-4baf-97a4-061b487da68b/cover/{height}",
      hero: {
        mode: "standard",
        title: "The Bourne Supremacy",
        synopsis:
          "Matt Damon returns as the amnesiac assassin, forced to abandon his quiet new life when he's framed for murder. Strong language/violence/flashing images. (2004)(105 mins)",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/443462dd-e33a-4baf-97a4-061b487da68b/background/1428",
        metadata: "1h 45m   ",
      },
    },
    {
      type: "coverTile",
      title: "The Big Lebowski",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/4cb16b0e-4bc7-492f-8c5e-754ee6042e6d/cover/{height}",
      hero: {
        mode: "standard",
        title: "The Big Lebowski",
        synopsis:
          "Cult comedy from the Coen brothers, starring Jeff Bridges as an ageing hippy who finds himself embroiled in a rich man's shady dealings. Strong language/sexual references. (1998)(113 mins)",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/4cb16b0e-4bc7-492f-8c5e-754ee6042e6d/background/1428",
        metadata: "1h 45m   ",
      },
    },
    {
      type: "coverTile",
      title: "The Shawshank Redemption",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/b5bebc37-9499-4afb-8c9e-3bb4db6517c0/cover/{height}",
      hero: {
        mode: "standard",
        title: "The Shawshank Redemption",
        synopsis:
          "A man is thrown into the brutality of prison life when he gets two life sentences for a crime he claims he didn't commit. Drama starring Tim Robbins and Morgan Freeman. (1995)(142 mins)",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/b5bebc37-9499-4afb-8c9e-3bb4db6517c0/background/1428",
        metadata: "1h 45m   ",
      },
    },
    {
      type: "coverTile",
      title: "Se7en",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/221e5981-577b-4ad7-b269-c7c3ca658290/cover/{height}",
      hero: {
        mode: "standard",
        title: "Se7en",
        synopsis:
          "Dark thriller starring Brad Pitt and Morgan Freeman as homicide detectives on the trail of a sick and twisted psychopath with a unique way of choosing his victims. (1995)(122 mins)",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/221e5981-577b-4ad7-b269-c7c3ca658290/background/1428",
        metadata: "1h 45m   ",
      },
    },
    {
      type: "coverTile",
      title: "Terminator 2: Judgment Day",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/ec7734b0-dc38-43eb-91d3-67ba32cbf122/cover/{height}",
      hero: {
        mode: "standard",
        title: "Terminator 2: Judgment Day",
        synopsis:
          "...Special Edition: Classic sci-fi action sequel with Arnold Schwarzenegger as a cyborg sent back in time to protect humanity's hope for the future. James Cameron directs. (1991)(148 mins)",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/ec7734b0-dc38-43eb-91d3-67ba32cbf122/background/1428",
        metadata: "1h 45m   ",
      },
    },
    {
      type: "coverTile",
      title: "The Breakfast Club",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/e9abbfea-8599-4c42-a9c5-525fde0f2e76/cover/{height}",
      hero: {
        mode: "standard",
        title: "The Breakfast Club",
        synopsis:
          "Molly Ringwald, Emilio Estevez and Judd Nelson lead a line-up of high school enemies who find common ground during detention. Mature themes. (1984)(100 mins)",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/e9abbfea-8599-4c42-a9c5-525fde0f2e76/background/1428",
        metadata: "1h 45m   ",
      },
    },
  ],
} as Rail;
