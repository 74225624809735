import React, { useRef } from "react";
import { Journey as JourneyType, Page } from "types";
import styled from "@emotion/styled";
import { ImagePage } from "components/pages/ImagePage";
import { VideoPage } from "components/pages/VideoPage";
import { screen } from "constants/dimensions";
import { KeepPrevious } from "components/KeepPrevious";
import { useAnimations } from "hooks/useAnimations";
import gsap from "gsap";

type Props = {
  journey: JourneyType;
  pageIndex: number;
  focused?: boolean;
};

const Background = styled.div`
  background-color: black;
  width: ${screen.width}px;
  height: ${screen.height}px;
  position: relative;
`;

const Container = styled.div`
  position: absolute;
  width: ${screen.width}px;
  height: ${screen.height}px;
`;

const pageComponent = (page: Page, focused: boolean) => {
  switch (page.type) {
    case "imagePage":
      return <ImagePage page={page} />;
    case "videoPage":
      return <VideoPage page={page} focused={focused} />;
    default:
      throw new Error(`Don't know how to display a page of type ${page.type}`);
  }
};

export const Journey = ({ journey, pageIndex, focused }: Props) => {
  const page = journey.pages[pageIndex],
    pageRef = useRef(null),
    prevPageRef = useRef(null);

  useAnimations({
    animations: {
      journeyForward: () =>
        Promise.all([
          gsap.fromTo(
            pageRef.current,
            { scale: 0.9, opacity: 0 },
            { scale: 1, opacity: 1, duration: 1.2, ease: "expo.inOut" }
          ),
          gsap.fromTo(
            prevPageRef.current,
            { scale: 1, opacity: 1 },
            {
              scale: 1.1,
              opacity: 0,
              duration: 0.8,
              ease: "expo.inOut",
            }
          ),
        ]),
      journeyBack: () =>
        Promise.all([
          gsap.fromTo(
            pageRef.current,
            { scale: 1.1, opacity: 0 },
            { scale: 1, opacity: 1, duration: 1.2, ease: "expo.inOut" }
          ),
          gsap.fromTo(
            prevPageRef.current,
            { scale: 1, opacity: 1 },
            {
              scale: 0.9,
              opacity: 0,
              duration: 0.8,
              ease: "expo.inOut",
            }
          ),
        ]),
    },
  });

  return (
    <Background>
      <KeepPrevious
        keyBy={pageIndex.toString()}
        props={{ page, focused }}
        duration={2}
        render={({ page, focused }, key, isPrevious) => (
          <Container ref={isPrevious ? prevPageRef : pageRef} key={key}>
            {pageComponent(page, focused)}
          </Container>
        )}
      />
    </Background>
  );
};
