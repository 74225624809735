import React from "react";
import { Container, Image } from "./PromoTile.styles";
import { PromoTile as PromoTileType } from "types";
import { imageUrlFromTemplate } from "utils/imageUrlFromTemplate";
import { promo } from "constants/assetSizes";

type Props = {
  focused: boolean;
  tile: PromoTileType;
};

export const PromoTile = ({ tile: { title, imageUrl }, focused }: Props) => {
  return (
    <Container focused={focused}>
      <Image alt={title} src={imageUrlFromTemplate(imageUrl, promo)} />
    </Container>
  );
};
