type Params = { [param: string]: string };
type Callback = (params: Params) => void;
type Route = [RegExp, Callback];

const patternToRegexp = (pattern: string) =>
  new RegExp(
    "^" + pattern.replace(/:(\w+)/g, (_, name) => `(?<${name}>\\w+)`) + "$"
  );

export class UrlParser {
  private routes: Route[];

  constructor(routes: { [pattern: string]: Callback }) {
    this.routes = Object.keys(routes).map(pattern => [
      patternToRegexp(pattern),
      routes[pattern],
    ]);
  }

  parse(hashString?: string) {
    const path = hashString?.replace("#", "");
    if (!path) return;
    const route = this.routes.find(([regexp, _]) => regexp.test(path));
    if (route) {
      const md = path.match(route[0]);
      route[1](md.groups);
    }
  }
}
