import React, { FC } from "react";

import { observer } from "mobx-react-lite";
import RoomForm from "./RoomForm";
import DoorbellService from "../../services/DoorbellService";

const Controller: FC<{ dbs: DoorbellService }> = observer(({ dbs }) => {
  function handleConnect(roomId: string) {
    DoorbellService.instance.roomId = roomId;
    DoorbellService.instance.connect();
  }

  function handleDisconnect() {
    DoorbellService.instance.disconnect();
  }

  function handleChangeRoomId(roomId: string) {
    DoorbellService.instance.roomId = roomId;
  }

  const doorbellUrlWithRoom = `${
    process.env.REACT_APP_DOORBELL_URL
  }?roomId=${encodeURIComponent(dbs.roomId)}`;

  return (
    <RoomForm
      handleConnect={handleConnect}
      handleDisconnect={handleDisconnect}
      status={dbs.status}
      initialRoomId={dbs.roomId}
      doorbellUrl={doorbellUrlWithRoom}
      roomIdChangeCallback={handleChangeRoomId}
      isConnectEnabled={DoorbellService.instance.isConnectEnabled}
    />
  );
});

const Wrapper = () => <Controller dbs={DoorbellService.instance} />;

export default Wrapper;
