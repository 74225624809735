import { Rail } from "types";

export default {
  type: "rail",
  uuid: "69e6024a-0da0-4e0b-9467-3492f92d6366",
  template: "tile-landscape",
  title: "Restart what's on now",
  tiles: [
    {
      type: "landscapeTile",
      title: "Supergirl",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/c4ca7f58-f302-49a1-9c15-21e9de49bcbb/16-9/{width}",
      hero: {
        mode: "standard",
        title: "Supergirl",
        synopsis:
          'The series begins eleven years later when the now 24-year-old Kara is learning to embrace her powers and has adopted the superheroic alias "Supergirl". In the first season, Kara is forced to reveal her powers, and becomes National City\'s protector.',
        metadata: "1h 45m   ",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/c4ca7f58-f302-49a1-9c15-21e9de49bcbb/background/{width}",
        logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_skyone/408/84",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_skyone/408/84",
    },
    {
      type: "landscapeTile",
      title: "Succession",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/6b0e0ac5-c207-489a-851c-f5d911e14c27/16-9/{width}",
      hero: {
        mode: "standard",
        title: "Succession",
        synopsis:
          "The Logan family is known for controlling the biggest media and entertainment company in the world. However, their world changes when their father steps down from the company.",
        metadata: "1h 45m   ",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/6b0e0ac5-c207-489a-851c-f5d911e14c27/background/{width}",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skywitness/408/84",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_skywitness/408/84",
    },
    {
      type: "landscapeTile",
      title: "Blue Bloods",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/e83c14be-be54-4a9b-90a1-fb75d2eade2f/16-9/{width}",
      hero: {
        mode: "standard",
        title: "Blue Bloods",
        synopsis:
          "The Reagans are an Irish Catholic family of cops. Each member represents different aspects of police work and law, with their lives interweaving occasionally.",
        metadata: "1h 45m   ",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/e83c14be-be54-4a9b-90a1-fb75d2eade2f/background/{width}",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skyatlantic/408/84",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_skyatlantic/408/84",
    },
    {
      type: "landscapeTile",
      title: "The Office (US)",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/e4aae372-6f3f-4848-97f9-982b30fcc9ed/16-9/{width}",
      hero: {
        mode: "standard",
        title: "The Office (US)",
        synopsis:
          "A motley group of office workers go through hilarious misadventures at the Scranton, Pennsylvania, branch of the Dunder Mifflin Paper Company.",
        metadata: "1h 45m   ",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/e4aae372-6f3f-4848-97f9-982b30fcc9ed/background/{width}",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycomedy/408/84",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_skycomedy/408/84",
    },
    {
      type: "landscapeTile",
      title: "Dateline",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/49a16d21-4c44-4feb-bd97-757360953cac/16-9/{width}",
      hero: {
        mode: "standard",
        title: "Dateline",
        synopsis:
          "`Dateline' has been a staple of the newsmagazine genre since premiering in 1992. Given how long it's been on the air, the show has an extensive archive of in-depth news stories and investigative journalism. This syndicated version offers viewers another chance to watch some of the show's previous content, serving up real-life mysteries and in-depth investigations from the show's 20-plus years on the air. Cases involving murders and missing people are frequent topics on the series that has won multiple Emmys in the news and documentary category.",
        metadata: "1h 45m   ",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/49a16d21-4c44-4feb-bd97-757360953cac/background/{width}",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycrime/408/84",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_skycrime/408/84",
    },
  ],
} as Rail;
