import { useKeys } from "hooks/useKeys";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { Selector } from "stores/Selector";
import { CameraEvent } from "types";
import {
  Bar,
  Circle,
  Container,
  Fill,
  LargeCircle,
  Icon,
  Label,
  CameraFeedContainer,
} from "./CameraEventLine.styles";
import { screen } from "constants/dimensions";
import { Text } from "components/Text";
import { formatTime } from "utils/timeHelpers";
import { CameraFeedController } from "components/CameraFeed";
import { Toggler } from "stores/Toggler";
import { animations } from "animations";

type Props = {
  focused: boolean;
  events: CameraEvent[];
  index: number;
  startDateMs: number;
  endDateMs: number;
  currentDateMs: number;
  fullscreen?: boolean;
};

const calcX = (dateMs: number, startMs: number, endMs: number) => {
  const width = screen.width,
    margin = 0.1 * width;
  return (
    margin + ((dateMs - startMs) / (endMs - startMs)) * (width - 2 * margin)
  );
};

export const CameraEventLine = ({
  focused,
  events,
  index,
  startDateMs,
  endDateMs,
  currentDateMs,
  fullscreen,
}: Props) => (
  <Container>
    <Bar>
      <Fill width={calcX(currentDateMs, startDateMs, endDateMs)} />
      {events.map(({ dateMs, type, title, url, previewUrl }, i) => {
        const x = calcX(dateMs, startDateMs, endDateMs),
          isFocused = focused && index === i;
        return (
          <div key={dateMs}>
            {isFocused && (
              <CameraFeedContainer x={x}>
                <CameraFeedController
                  url={url}
                  previewUrl={previewUrl}
                  small={true}
                  fullscreen={fullscreen}
                />
              </CameraFeedContainer>
            )}
            {isFocused ? <LargeCircle x={x} /> : <Circle x={x} />}
            <Icon x={x} type={type} />
            <Label x={x}>
              <Text weight="medium" size="small">
                {formatTime(dateMs)}
              </Text>
              {title && (
                <Text weight="regular" size="small">
                  {title}
                </Text>
              )}
            </Label>
          </div>
        );
      })}
    </Bar>
  </Container>
);

type ControllerProps = {
  focused: boolean;
  events: CameraEvent[];
  startDateMs: number;
  endDateMs: number;
  currentDateMs: number;
};

export const CameraEventLineController = observer(
  ({
    focused,
    events,
    startDateMs,
    endDateMs,
    currentDateMs,
  }: ControllerProps) => {
    const [eventsChooser] = useState(() => new Selector<CameraEvent>(events));
    const [fullscreenToggler] = useState(() => new Toggler());
    useKeys(focused, ({ button }) => {
      switch (button) {
        case "LEFT":
          eventsChooser.prev();
          break;
        case "RIGHT":
          eventsChooser.next();
          break;
        case "SELECT":
          fullscreenToggler.toggle();
          animations.animate(
            fullscreenToggler.on ? "cameraFeedZoomIn" : "cameraFeedZoomOut",
            eventsChooser.item.url
          );
          break;
        case "BACK":
          if (fullscreenToggler.on) {
            fullscreenToggler.on = false;
            animations.animate("cameraFeedZoomOut", eventsChooser.item.url);
          }
          break;
      }
    });
    return (
      <CameraEventLine
        focused={focused}
        events={eventsChooser.items}
        index={eventsChooser.index}
        startDateMs={startDateMs}
        endDateMs={endDateMs}
        currentDateMs={currentDateMs}
        fullscreen={fullscreenToggler.on}
      />
    );
  }
);
