import { Rail } from "types";

export default {
  type: "rail",
  uuid: "e42bc61b-cf0e-494b-8cba-2bbfb2cc900a",
  template: "tile-landscape",
  title: "Restart what's on now",
  tiles: [
    {
      type: "landscapeTile",
      title: "Music Icons",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/ca96593b-f300-40be-b164-2b5ced07096f/16-9/{width}",
      hero: {
        mode: "standard",
        title: "Music Icons",
        synopsis:
          "A fascinating look at the life and work of history's most iconic musicians and performers.",
        metadata: "1h 45m   ",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/ca96593b-f300-40be-b164-2b5ced07096f/background/{width}",
        logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_mtv/408/84",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_mtv/408/84",
    },
    {
      type: "landscapeTile",
      title: "Soundtracks: Songs That Defined History",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/e6de8bcb-946a-45a6-b17d-b30b1b56adba/16-9/{width}",
      hero: {
        mode: "standard",
        title: "Soundtracks: Songs That Defined History",
        synopsis:
          "A look at significant historical moments in time and the music that helped defined them.",
        metadata: "1h 45m   ",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/e6de8bcb-946a-45a6-b17d-b30b1b56adba/background/{width}",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skyarts/408/84",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_skyarts/408/84",
    },
    {
      type: "landscapeTile",
      title: "Discovering Music",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/808f5032-c681-4ccc-b848-ec964850eb2f/16-9/{width}",
      hero: {
        mode: "standard",
        title: "Discovering Music",
        synopsis:
          "Music journalists take a closer look at the work of some of the biggest recording artists in the world, past and present.",
        metadata: "1h 45m   ",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/808f5032-c681-4ccc-b848-ec964850eb2f/background/{width}",
        logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_mtv/408/84",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_mtv/408/84",
    },
    {
      type: "landscapeTile",
      title: "Music Videos That Defined the 2000s",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/eacc0946-b355-4f74-86ab-b452d8829f5d/16-9/{width}",
      hero: {
        mode: "standard",
        title: "Music Videos That Defined the 2000s",
        synopsis:
          "...The 2000s. An entertaining look at the most iconic music videos of the 2000s, when the likes of Beyonce and Fatboy Slim broke new ground in music television.",
        metadata: "1h 45m   ",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/eacc0946-b355-4f74-86ab-b452d8829f5d/background/{width}",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skyarts/408/84",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_skyarts/408/84",
    },
    {
      type: "landscapeTile",
      title: "Coldplay: A Head Full of Dreams",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/aa4c991c-96ac-4015-bba5-ae6dfe67f4f7/16-9/{width}",
      hero: {
        mode: "standard",
        title: "Coldplay: A Head Full of Dreams",
        synopsis:
          "The chart-topping band reflects on their two decades together as this film weaves together live footage and unseen clips for an intimate portrait of their rise to fame.",
        metadata: "1h 45m   ",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/aa4c991c-96ac-4015-bba5-ae6dfe67f4f7/background/{width}",
        logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_mtv/408/84",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_mtv/408/84",
    },
  ],
} as Rail;
