import styled from "@emotion/styled";
import { tiles } from "../../constants/dimensions";

const { promoTile } = tiles;

export const Container = styled.div(
  ({ focused }: { focused: boolean }) => `
  width: ${promoTile.width}px;
  height: ${promoTile.height}px;
  box-sizing: border-box;
  border-radius: ${focused ? 0 : promoTile.borderRadius}px;
  overflow: hidden;
`
);

export const Image = styled.img`
  width: 100%;
`;
