import { Rail } from "types";

export default {
  type: "rail",
  uuid: "651c8797-497c-4293-a036-100b565d6093",
  template: "tile-category",
  tiles: [
    {
      type: "categoryTile",
      title: "TV Shows",
      action: { type: "GO_TO_PAGE", pageType: "railsPage", pageId: "tv" },
      hero: {
        mode: "standard",
        heroImageUrl: "assets/images/categories/tv-shows-hero.jpg",
        synopsis:
          "From award-winning Sky TV dramas and documentaries, to epic Netflix originals and never before seen Disney+ series, find your favourite TV shows and discover new things to watch.",
        title: "TV Shows",
      },
    },
    {
      type: "categoryTile",
      title: "Movies",
      action: { type: "GO_TO_PAGE", pageType: "railsPage", pageId: "movies" },
      hero: {
        mode: "standard",
        heroImageUrl: "assets/images/categories/movies-hero.jpg",
        synopsis:
          "Catch the latest premieres, rewatch a family favourite or discover a classic you missed the first time – find movies for all the family’s viewing taste across Sky, Netflix, Disney+ and more.",
        title: "Movies",
      },
    },
    {
      type: "categoryTile",
      title: "Sports",
      action: { type: "GO_TO_PAGE", pageType: "railsPage", pageId: "sports" },
      hero: {
        mode: "standard",
        heroImageUrl: "assets/images/categories/sports-hero.jpg",
        synopsis:
          "The biggest games, F1 races, golf, cricket and more on Sky Sports and BT Sport – with all Sky Sports channels showing in glorious HD.",
        title: "Sports",
      },
    },
    {
      type: "categoryTile",
      title: "Kids",
      action: { type: "GO_TO_PAGE", pageType: "railsPage", pageId: "kids" },
      hero: {
        mode: "standard",
        heroImageUrl: "assets/images/categories/kids-hero.jpg",
        synopsis: "Eleven kids channels to keep your children captivated.",
        title: "Kids",
      },
    },
    {
      type: "categoryTile",
      title: "Music",
      action: { type: "GO_TO_PAGE", pageType: "imagePage", pageId: "music" },
      hero: {
        mode: "standard",
        heroImageUrl: "assets/images/categories/music-hero.jpg",
        synopsis: "Music for every type of listener.",
        title: "Music",
      },
    },
    {
      type: "categoryTile",
      title: "My List",
      action: {
        type: "GO_TO_PAGE",
        pageType: "railsPage",
        pageId: "recordings",
      },
      hero: {
        mode: "standard",
        heroImageUrl:
          "assets/images/content/Save My Child/Save-my-child-hero.jpg",
        synopsis: "Your watchlist of shows and movies.",
        title: "My List",
      },
    },
  ],
} as Rail;
