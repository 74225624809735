import { Rail } from "types";

export default {
  type: "rail",
  uuid: "cfc2d192-5bd6-4701-8c4b-6db89eda2ada",
  template: "tile-landscape",
  title: "Featured",
  tiles: [
    {
      type: "landscapeTile",
      title: "What We Do in the Shadows",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/2f30c794-7dff-4d98-b461-85c318b6aca5/16-9/{width}",
      hero: {
        mode: "standard",
        title: "What We Do in the Shadows",
        metadata: "1h 45m   ",
        synopsis:
          "Resurrection. The vampires enlist the services of a local necromancer. Contains some strong language and some violence.",
        logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_bbctwo/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/2f30c794-7dff-4d98-b461-85c318b6aca5/background/{width}",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_bbctwo/408/84",
    },
    {
      type: "landscapeTile",
      title: "EastEnders 2008",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/d1176644-be67-4695-80ab-02e4a0b7edf4/16-9/{width}",
      hero: {
        mode: "standard",
        title: "EastEnders 2008",
        metadata: "1h 45m   ",
        synopsis:
          "01/04/2008. Frank has the last laugh as Peggy and Pat clash over his funeral arrangements.",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_bbcfour/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/d1176644-be67-4695-80ab-02e4a0b7edf4/background/{width}",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_bbcfour/408/84",
    },
    {
      type: "landscapeTile",
      title: "The Capture",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/da79851a-7245-4ff5-8fa8-48f91ae2e0e7/16-9/{width}",
      hero: {
        mode: "standard",
        title: "The Capture",
        metadata: "1h 45m   ",
        synopsis:
          "What Happens in Helmand. Soldier Shaun Emery is accused of a crime he denies. Contains strong language and some violence.",
        logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_bbcone/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/da79851a-7245-4ff5-8fa8-48f91ae2e0e7/background/{width}",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_bbcone/408/84",
    },
    {
      type: "landscapeTile",
      title: "Glow Up: Britain's Next Make-Up...",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/71da94d8-2713-4564-ab28-ce0b12dd4e0e/16-9/{width}",
      hero: {
        mode: "standard",
        title: "Glow Up: Britain's Next Make-Up...",
        metadata: "1h 45m   ",
        synopsis:
          "...Star. Episode 1. The MUAs work on an advertising campaign for sports fashion retailer JD.",
        logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_bbc/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/71da94d8-2713-4564-ab28-ce0b12dd4e0e/background/{width}",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_bbc/408/84",
    },
    {
      type: "landscapeTile",
      title: "Sherlock",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/de55da47-8770-4535-abeb-cc6f55165168/16-9/{width}",
      hero: {
        mode: "standard",
        title: "Sherlock",
        metadata: "1h 45m   ",
        synopsis:
          "A Study in Pink. Dr Watson and Sherlock Holmes meet, and tackle the case of the Impossible Suicides.",
        logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_bbc/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/de55da47-8770-4535-abeb-cc6f55165168/background/{width}",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_bbc/408/84",
    },
    {
      type: "landscapeTile",
      title: "Spooks",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/af4a1c22-e30d-47f9-b409-6cbd226b4b6d/16-9/{width}",
      hero: {
        mode: "standard",
        title: "Spooks",
        metadata: "1h 45m   ",
        synopsis:
          "Episode 1. A car bomb explodes in Liverpool and Zoe is sent undercover to investigate.",
        logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_bbc/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/af4a1c22-e30d-47f9-b409-6cbd226b4b6d/background/{width}",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_bbc/408/84",
    },
    {
      type: "landscapeTile",
      title: "Storyville",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/a5900032-4c78-44b8-aeb7-5e0c33a23423/16-9/{width}",
      hero: {
        mode: "standard",
        title: "Storyville",
        metadata: "1h 45m   ",
        synopsis:
          "Episode 1. The inspiring story of men and women in the USA pursuing college degrees while in prison.",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_bbcfour/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/a5900032-4c78-44b8-aeb7-5e0c33a23423/background/{width}",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_bbcfour/408/84",
    },
    {
      type: "landscapeTile",
      title: "Inside Monaco: Playground of the...",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/4bd7a3cd-2c6a-4209-8e1d-83bac8a24e1a/16-9/{width}",
      hero: {
        mode: "standard",
        title: "Inside Monaco: Playground of the...",
        metadata: "1h 45m   ",
        synopsis:
          "...Rich. Episode 2. The Casino de Monte Carlo holds an exclusive event for high-rollers.",
        logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_bbctwo/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/4bd7a3cd-2c6a-4209-8e1d-83bac8a24e1a/background/{width}",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_bbctwo/408/84",
    },
    {
      type: "landscapeTile",
      title: "Comedians: Home Alone",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/8c592d29-3dd2-4e44-b600-876528760421/16-9/{width}",
      hero: {
        mode: "standard",
        title: "Comedians: Home Alone",
        metadata: "1h 45m   ",
        synopsis:
          "Episode 1. Michael Spicer takes on Priti Patel in the viral hit Room Next Door.",
        logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_bbctwo/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/8c592d29-3dd2-4e44-b600-876528760421/background/{width}",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_bbctwo/408/84",
    },
    {
      type: "landscapeTile",
      title: "My Family",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/12a5d331-cbd3-46d1-9e90-73e7687c198c/16-9/{width}",
      hero: {
        mode: "standard",
        title: "My Family",
        metadata: "1h 45m   ",
        synopsis:
          "The Serpent's Tooth. Ben is puzzled to hear his wife Susan and daughter Janey are seeing another dentist.",
        logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_bbc/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/12a5d331-cbd3-46d1-9e90-73e7687c198c/background/{width}",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_bbc/408/84",
    },
  ],
} as Rail;
