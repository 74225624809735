import { Rail } from "types";

export default {
  type: "rail",
  uuid: "651c8797-497c-4293-a036-100b565d6093",
  template: "tile-category",
  tiles: [
    {
      type: "categoryTile",
      title: "Football",
      hero: {
        mode: "titleArt",
        heroImageUrl: "assets/images/sports/Football.jpg",
      },
    },
    {
      type: "categoryTile",
      title: "Cricket",
      hero: {
        mode: "titleArt",
        heroImageUrl: "assets/images/sports/Cricket.jpg",
      },
    },
    {
      type: "categoryTile",
      title: "Formula 1",
      hero: {
        mode: "titleArt",
        heroImageUrl: "assets/images/sports/F1.jpg",
      },
    },
    {
      type: "categoryTile",
      title: "Golf",
      hero: {
        mode: "titleArt",
        heroImageUrl: "assets/images/sports/Golf.jpg",
      },
    },
    { type: "categoryTile", title: "Boxing", hero: { mode: "titleArt" } },
    { type: "categoryTile", title: "Rugby League", hero: { mode: "titleArt" } },
    { type: "categoryTile", title: "Rugby Union", hero: { mode: "titleArt" } },
    { type: "categoryTile", title: "Cycling", hero: { mode: "titleArt" } },
    { type: "categoryTile", title: "Darts", hero: { mode: "titleArt" } },
    { type: "categoryTile", title: "NFL", hero: { mode: "titleArt" } },
    { type: "categoryTile", title: "NBA", hero: { mode: "titleArt" } },
    { type: "categoryTile", title: "GAA", hero: { mode: "titleArt" } },
    { type: "categoryTile", title: "MMA", hero: { mode: "titleArt" } },
    { type: "categoryTile", title: "Motor racing", hero: { mode: "titleArt" } },
    { type: "categoryTile", title: "Netball", hero: { mode: "titleArt" } },
    { type: "categoryTile", title: "Tennis", hero: { mode: "titleArt" } },
    { type: "categoryTile", title: "Snooker", hero: { mode: "titleArt" } },
    { type: "categoryTile", title: "Wrestling", hero: { mode: "titleArt" } },
    { type: "categoryTile", title: "Box Office", hero: { mode: "titleArt" } },
  ],
} as Rail;
