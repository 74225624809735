import { Rail } from "types";

export default {
  type: "rail",
  uuid: "e2e4109b-c61f-448a-83ce-69b93b6fd4bc",
  template: "tile-landscape",
  title: "Trending Today",
  tiles: [
    {
      type: "landscapeTile",
      title: "Lloris and Son clash on pitch",
      imageUrl:
        "https://img.skysports.com/20/07/456x257/skysports-lloris-son-tottenham_5032235.jpg?20200706210417",
      hero: {
        mode: "standard",
        title: "Lloris and Son clash on pitch",
        metadata: "1h 45m   ",
        synopsis:
          "Hugo Lloris squared up to Heung-Min Son just before halftime in the match between Tottenham and Everton.  ",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skysports/365/205",
        heroImageUrl: "https://cdn.skyq.sky.com/mashup/images/sports-hero.jpg",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_skysports/365/205",
    },
    {
      type: "landscapeTile",
      title: "Aluko: Targets needed for BAME representation",
      imageUrl:
        "https://img.skysports.com/20/05/456x257/skysports-eni-aluko-aluko_4992857.jpg?20200517213005",
      hero: {
        mode: "standard",
        title: "Aluko: Targets needed for BAME representation",
        metadata: "1h 45m   ",
        synopsis:
          "Former England forward Eniola Aluko believes it's time to set targets to increase the number of BAME individuals at the top level of sport in the UK.",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skysports/365/205",
        heroImageUrl: "https://cdn.skyq.sky.com/mashup/images/sports-hero.jpg",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_skysports/365/205",
    },
    {
      type: "landscapeTile",
      title: "Lloris and Son make up after bust-up",
      imageUrl:
        "https://img.skysports.com/20/07/456x257/skysports-heung-min-son-hugo-lloris_5032606.jpg?20200707110508",
      hero: {
        mode: "standard",
        title: "Lloris and Son make up after bust-up",
        metadata: "1h 45m   ",
        synopsis:
          "Tottenham team-mates Hugo Lloris and Heung-Min Son put their differences behind them following their altercation during the match against Everton.",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skysports/365/205",
        heroImageUrl: "https://cdn.skyq.sky.com/mashup/images/sports-hero.jpg",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_skysports/365/205",
    },
    {
      type: "landscapeTile",
      title: "Klopp backs use of drinks breaks",
      imageUrl:
        "https://img.skysports.com/20/06/456x257/skysports-jurgen-klopp-stapler_5026828.jpg?20200630213021",
      hero: {
        mode: "standard",
        title: "Klopp backs use of drinks breaks",
        metadata: "1h 45m   ",
        synopsis:
          "Jurgen Klopp has backed the introduction of drinks breaks to Premier League games, and says he likes the opportunity to speak to his players.",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skysports/365/205",
        heroImageUrl: "https://cdn.skyq.sky.com/mashup/images/sports-hero.jpg",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_skysports/365/205",
    },
    {
      type: "landscapeTile",
      title: "Mourinho: Argument was 'beautiful'",
      imageUrl:
        "https://img.skysports.com/20/07/456x257/dc4f916149998b4d0ad3dea7f066865d72e033bb8023e2767f0696edf00ce2b3_5032358.jpg?20200706224647",
      hero: {
        mode: "standard",
        title: "Mourinho: Argument was 'beautiful'",
        metadata: "1h 45m   ",
        synopsis:
          "Tottenham's Jose Mourinho says he was pleased to see the argument between Hugo Lloris and Heung-min Son as he's asked his players to be more demanding of each other.",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skysports/365/205",
        heroImageUrl: "https://cdn.skyq.sky.com/mashup/images/sports-hero.jpg",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_skysports/365/205",
    },
    {
      type: "landscapeTile",
      title: "PL Weekend Round-up",
      imageUrl:
        "https://img.skysports.com/20/07/456x257/promo418339370_5032380.jpg?20200706230004",
      hero: {
        mode: "standard",
        title: "PL Weekend Round-up",
        metadata: "1h 45m   ",
        synopsis:
          "A round-up of the weekend's Premier League action as Manchester United thumped Bournemouth and Southampton pulled off a shock win over Manchester City.",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skysports/365/205",
        heroImageUrl: "https://cdn.skyq.sky.com/mashup/images/sports-hero.jpg",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_skysports/365/205",
    },
    {
      type: "landscapeTile",
      title: "Wycombe 2-2 Fleetwood (6-3 agg)",
      imageUrl:
        "https://img.skysports.com/20/07/456x257/promo418337707_5032289.jpg?20200706215929",
      hero: {
        mode: "standard",
        title: "Wycombe 2-2 Fleetwood (6-3 agg)",
        metadata: "1h 45m   ",
        synopsis:
          "Highlights of the Sky Bet League One play-off semi-final between Wycombe and Fleetwood.",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skysports/365/205",
        heroImageUrl: "https://cdn.skyq.sky.com/mashup/images/sports-hero.jpg",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_skysports/365/205",
    },
    {
      type: "landscapeTile",
      title: "Oxford 1-1 Portsmouth (Oxford win 5-4 on pens)",
      imageUrl:
        "https://img.skysports.com/20/07/456x257/promo418330795_5032172.jpg?20200706200533",
      hero: {
        mode: "standard",
        title: "Oxford 1-1 Portsmouth (Oxford win 5-4 on pens)",
        metadata: "1h 45m   ",
        synopsis:
          "Highlights of the Sky Bet League One play-off semi-final between Oxford United and Portsmouth.",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skysports/365/205",
        heroImageUrl: "https://cdn.skyq.sky.com/mashup/images/sports-hero.jpg",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_skysports/365/205",
    },
    {
      type: "landscapeTile",
      title: "Spurs edge past Everton",
      imageUrl:
        "https://img.skysports.com/20/07/456x257/promo418338585_5032311.jpg?20200706221216",
      hero: {
        mode: "standard",
        title: "Spurs edge past Everton",
        metadata: "1h 45m   ",
        synopsis:
          "FREE TO WATCH: Highlights from Tottenham's win over Everton in the Premier League.",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skysports/365/205",
        heroImageUrl: "https://cdn.skyq.sky.com/mashup/images/sports-hero.jpg",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_skysports/365/205",
    },
    {
      type: "landscapeTile",
      title: "Hussain: Broad, Anderson should start series",
      imageUrl:
        "https://img.skysports.com/20/04/456x257/skysports-james-anderson-stuart-broad_4975204.jpg?20200423145928",
      hero: {
        mode: "standard",
        title: "Hussain: Broad, Anderson should start series",
        metadata: "1h 45m   ",
        synopsis:
          "Nasser Hussain says 'legendary bowlers' James Anderson and Stuart Broad both deserve to play in England's first #raisethebat Test against West Indies.",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skysports/365/205",
        heroImageUrl: "https://cdn.skyq.sky.com/mashup/images/sports-hero.jpg",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_skysports/365/205",
    },
    {
      type: "landscapeTile",
      title: "'Lloris is demanding respect and accountability'",
      imageUrl:
        "https://img.skysports.com/20/07/456x257/skysports-hugo-lloris-henun-min-son_5032624.jpg?20200707112431",
      hero: {
        mode: "standard",
        title: "'Lloris is demanding respect and accountability'",
        metadata: "1h 45m   ",
        synopsis:
          "Tim Cahill and Jamie Redknapp reflect on Hugo Lloris and Son Heung-min's half-time dispute, believing that they feel it shows the players care and that it was for the right reasons.",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skysports/365/205",
        heroImageUrl: "https://cdn.skyq.sky.com/mashup/images/sports-hero.jpg",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_skysports/365/205",
    },
    {
      type: "landscapeTile",
      title: "Shaw: Big change in group happiness",
      imageUrl:
        "https://img.skysports.com/20/07/456x257/skysports-manchester-united_5029986.jpg?20200704132232",
      hero: {
        mode: "standard",
        title: "Shaw: Big change in group happiness",
        metadata: "1h 45m   ",
        synopsis:
          "Manchester United defender Luke Shaw says there has been a 'big change' in the relationships within the squad which has contributed to their recent good form.",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skysports/365/205",
        heroImageUrl: "https://cdn.skyq.sky.com/mashup/images/sports-hero.jpg",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_skysports/365/205",
    },
  ],
} as Rail;
