import { SpecificAction } from "types/actions";
import { call, delay, put, takeLatest } from "sagas/effects";
import { sounds } from "services/sounds";

export function* toastSaga() {
  yield takeLatest("SHOW_TOAST", onShowToast);
  yield takeLatest(["SHOW_TOAST", "CHANGE_TOAST_STATUS"], hideAfterDelay);
}

const DEFAULT_SHOW_DURATION = 8000;

function* onShowToast({ status, durationMs }: SpecificAction<"SHOW_TOAST">) {
  durationMs = durationMs || DEFAULT_SHOW_DURATION;
  const primaryDurationMs = 1200;
  switch (status) {
    case "success":
    case "error":
      yield call(
        status === "success" ? sounds.voiceSuccess : sounds.voiceError
      );
      yield delay(primaryDurationMs);
      yield put({
        type: "CHANGE_TOAST_STATUS",
        status: "listening",
        durationMs: durationMs - primaryDurationMs,
      });
      break;
  }
}

function* hideAfterDelay({
  durationMs,
}: SpecificAction<"SHOW_TOAST" | "CHANGE_TOAST_STATUS">) {
  yield delay(durationMs || DEFAULT_SHOW_DURATION);
  yield put({ type: "HIDE_TOAST_MESSAGE" });
}
