import { Rail } from "types";

export default {
  type: "rail",
  uuid: "abbf0a8b-28d0-47ba-b46b-3c2b728764d8",
  template: "tile-landscape",
  title: "Restart what's on now",
  tiles: [
    {
      type: "landscapeTile",
      title: "Newsnight",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/c1eb85a7-a470-4267-87c0-3aae4e89e37c/16-9/{width}",
      hero: {
        mode: "standard",
        title: "Newsnight",
        synopsis:
          "Comprehensive coverage of today's important national and international news stories.",
        metadata: "1h 45m   ",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/c1eb85a7-a470-4267-87c0-3aae4e89e37c/background/{width}",
        logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_bbc2/408/84",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_bbc2/408/84",
    },
    {
      type: "landscapeTile",
      title: "BBC News",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/257e7bc3-45e0-4ed8-8864-7f95d7974281/16-9/{width}",
      hero: {
        mode: "standard",
        title: "BBC News",
        synopsis:
          "Visit BBC News for up-to-the-minute news, breaking news, video, audio and feature stories.",
        metadata: "1h 45m   ",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/257e7bc3-45e0-4ed8-8864-7f95d7974281/background/{width}",
        logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_bbc/408/84",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_bbc/408/84",
    },
    {
      type: "landscapeTile",
      title: "Sky News Today",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/d1269b8a-9b98-4ecb-9dbd-4541558f0480/16-9/{width}",
      hero: {
        mode: "standard",
        title: "Sky News Today",
        synopsis:
          "Expert comment and analysis on the latest UK news, with headlines from England, Scotland, Northern Ireland and Wales.",
        metadata: "1h 45m   ",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/d1269b8a-9b98-4ecb-9dbd-4541558f0480/background/{width}",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skynews/408/84",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_skynews/408/84",
    },
    {
      type: "landscapeTile",
      title: "Sky News with Sarah Hewson",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/8d948a89-4dcf-4e19-863f-e2ea34d891f0/16-9/{width}",
      hero: {
        mode: "standard",
        title: "Sky News with Sarah Hewson",
        synopsis:
          "Join Sarah Hewson for the afternoon's breaking news, along with interviews and lively debate on the big stories and issues of the day.",
        metadata: "1h 45m   ",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/8d948a89-4dcf-4e19-863f-e2ea34d891f0/background/{width}",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skynews/408/84",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_skynews/408/84",
    },
    {
      type: "landscapeTile",
      title: "ITV Evening News",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/6b3f6f9a-10c5-4648-bc05-63cf084269da/16-9/{width}",
      hero: {
        mode: "standard",
        title: "ITV Evening News",
        synopsis:
          "Breaking news and analysis from across the UK and around the world.",
        metadata: "1h 45m   ",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/6b3f6f9a-10c5-4648-bc05-63cf084269da/background/{width}",
        logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_itv/408/84",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_itv/408/84",
    },
  ],
} as Rail;
