import React, { useRef, useEffect } from "react";
import { QRCanvas } from "./QRCode.styles";
import QRCodeFunc from "qrcode";

type Props = { url: string; width: number; height: number };

const QRCode = ({ url, width, height }: Props) => {
  const qrCanvasRef = useRef(null);

  useEffect(() => {
    QRCodeFunc.toCanvas(qrCanvasRef.current, url, function(error) {
      if (error) console.error(error);
    });
  }, [url]);

  return <QRCanvas ref={qrCanvasRef} />;
};

QRCode.defaultProps = {
  width: 100,
  height: 100,
};

export default QRCode;
