import { videos } from "data/videos";
import { Journey } from "types";

export const capture: Journey = {
  id: "capture",
  pages: [
    {
      type: "videoPage",
      url: videos.capture,
    },
  ],
  title: "The Capture",
};
