import { Rail } from "types";

export default {
  type: "rail",
  uuid: "356701b7-4e54-4e56-bea5-1af0c79e25c9",
  template: "tile-landscape",
  title: "Box Sets",
  tiles: [
    {
      type: "landscapeTile",
      title: "Normal People",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/3514f54d-a9ef-489b-90a7-103c87476a21/16-9/{width}",
      hero: {
        mode: "standard",
        title: "Normal People",
        metadata: "1h 45m   ",
        synopsis: "Two teeangers weave in and out of each other's lives.",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/3514f54d-a9ef-489b-90a7-103c87476a21/background/{width}",
      },
    },
    {
      type: "landscapeTile",
      title: "Nudes",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/3f831b0a-e015-4251-9036-4b7ef1d8985b/16-9/{width}",
      hero: {
        mode: "standard",
        title: "Nudes",
        metadata: "1h 45m   ",
        synopsis:
          "Three teenagers are plunged into a nightmare when their sex lives go public online.",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/3f831b0a-e015-4251-9036-4b7ef1d8985b/background/{width}",
      },
    },
    {
      type: "landscapeTile",
      title: "Bodies",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/35c08a2a-3378-44f7-a800-881a4c424dfe/16-9/{width}",
      hero: {
        mode: "standard",
        title: "Bodies",
        metadata: "1h 45m   ",
        synopsis:
          "Rob Lake starts work in a maternity unit and witnesses the negligence of an obstetrician.",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/35c08a2a-3378-44f7-a800-881a4c424dfe/background/{width}",
      },
    },
    {
      type: "landscapeTile",
      title: "Twin",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/a16b11de-e97b-4798-98bd-3d422961ae89/16-9/{width}",
      hero: {
        mode: "standard",
        title: "Twin",
        metadata: "1h 45m   ",
        synopsis:
          "Following an accident, a man assumes the identity of his dead twin.",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/a16b11de-e97b-4798-98bd-3d422961ae89/background/{width}",
      },
    },
    {
      type: "landscapeTile",
      title: "Taboo",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/d19ea1b5-d2b3-4dc0-ad68-b4678cb0c75e/16-9/{width}",
      hero: {
        mode: "standard",
        title: "Taboo",
        metadata: "1h 45m   ",
        synopsis:
          "London 1814. James Keziah Delaney is encircled by conspiracy, murder and betrayal.",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/d19ea1b5-d2b3-4dc0-ad68-b4678cb0c75e/background/{width}",
      },
    },
    {
      type: "landscapeTile",
      title: "Dracula",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/f8459fd8-48f3-40dd-8dc8-504504d0aca4/16-9/{width}",
      hero: {
        mode: "standard",
        title: "Dracula",
        metadata: "1h 45m   ",
        synopsis:
          "The Count is drawing his plans against Victorian London. And the dead travel fast.",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/f8459fd8-48f3-40dd-8dc8-504504d0aca4/background/{width}",
      },
    },
    {
      type: "landscapeTile",
      title: "Performance Live",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/2f0bdb2b-df03-44e4-b1de-4267bed23947/16-9/{width}",
      hero: {
        mode: "standard",
        title: "Performance Live",
        metadata: "1h 45m   ",
        synopsis:
          "Showcasing a spectrum of some of the most exciting artists working in performance today.",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/2f0bdb2b-df03-44e4-b1de-4267bed23947/background/{width}",
      },
    },
    {
      type: "landscapeTile",
      title: "DEVS",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/dec251e2-0519-4098-bbe3-237762006fb7/16-9/{width}",
      hero: {
        mode: "standard",
        title: "DEVS",
        metadata: "1h 45m   ",
        synopsis: "Multi-dimensional tech thriller.",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/dec251e2-0519-4098-bbe3-237762006fb7/background/{width}",
      },
    },
    {
      type: "landscapeTile",
      title: "The A Word",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/afe2c178-46d3-4acd-8aa0-07ff1420b78b/16-9/{width}",
      hero: {
        mode: "standard",
        title: "The A Word",
        metadata: "1h 45m   ",
        synopsis: "Family drama with a boy with autism at its heart.",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/afe2c178-46d3-4acd-8aa0-07ff1420b78b/background/{width}",
      },
    },
    {
      type: "landscapeTile",
      title: "The Fades",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/540968d9-71ce-467c-a4bd-01c863b3d010/16-9/{width}",
      hero: {
        mode: "standard",
        title: "The Fades",
        metadata: "1h 45m   ",
        synopsis:
          "A teenager is thrown into the heart of an apocalyptic struggle with the restless dead.",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/540968d9-71ce-467c-a4bd-01c863b3d010/background/{width}",
      },
    },
  ],
} as Rail;
