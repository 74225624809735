import styled from "@emotion/styled";
import { browseTabs } from "constants/dimensions";

export const Container = styled.div({
  position: "absolute",
  top: 0,
  left: 0,
  height: 360,
  backgroundImage: "linear-gradient(rgba(0,0,0,0.6), rgba(0,0,0,0))",
});

export const TabsContainer = styled.div({
  width: browseTabs.width,
  height: browseTabs.height,
  margin: `${browseTabs.marginTop}px ${browseTabs.marginSide}px 0`,
  borderRadius: 10,
  overflow: "hidden",
  display: "flex",
});

export const GlassFrameContainer = styled.div({
  position: "absolute",
  top: browseTabs.marginTop,
  left: browseTabs.marginSide,
});

export const Tab = styled.div(({ focused }: { focused: boolean }) => ({
  display: "flex",
  alignItems: "center",
  textAlign: "center",
  flex: `0 0 ${browseTabs.tab.width}px`,
  height: focused ? browseTabs.focus.height : browseTabs.height,
  backgroundColor: focused ? "rgba(241,241,241,0.24)" : undefined,
}));
