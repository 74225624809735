import { Rail } from "types";

export default {
  type: "rail",
  uuid: "8887074b-02ce-47b2-813f-146d2000dca7",
  template: "tile-landscape",
  title: "Kids",
  tiles: [
    {
      type: "landscapeTile",
      title: "Curious George",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/7dc2f106-2b07-40b5-a71a-6c3e5a91b1bc/16-9/{width}",
      hero: {
        mode: "standard",
        title: "Curious George",
        metadata: "1h 45m    ",
        synopsis:
          "Follow the mischievous adventures of a Monkey by the name of George.",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/7dc2f106-2b07-40b5-a71a-6c3e5a91b1bc/background/{width}",
        heroTitleImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/a9695d1fe40101d63431be669d400757/herotitle.png",
      },
    },
    {
      type: "landscapeTile",
      title: "Streetcat Bob",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/fe33dde6-5305-40f0-b2a9-9e7f53cc6b1f/16-9/{width}",
      hero: {
        mode: "standard",
        title: "Streetcat Bob",
        metadata: "1h 45m    ",
        synopsis:
          "Meet Bob - a stray ginger cat living in Bowen Park alongside his animal friends. Animated lyrical adventure for preschoolers based on the bestselling books and film.",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/fe33dde6-5305-40f0-b2a9-9e7f53cc6b1f/background/{width}",
      },
    },
    {
      type: "landscapeTile",
      title: "Songs & Rhymes",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/99073d86-41f1-417b-b5fc-195be7947978/16-9/{width}",
      hero: {
        mode: "standard",
        title: "Songs & Rhymes",
        metadata: "1h 45m    ",
        synopsis: "Special karaoke mix with the best of BabyTV songs.",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/99073d86-41f1-417b-b5fc-195be7947978/background/{width}",
      },
    },
    {
      type: "landscapeTile",
      title: "Numberblocks",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/b07d99a6-9266-475c-9ac6-d822544045ec/16-9/{width}",
      hero: {
        mode: "standard",
        title: "Numberblocks",
        metadata: "1h 45m    ",
        synopsis:
          "Animated characters who can always count on each other join forces to get young children to think differently about numbers. The world they inhabit is full of singing, counting and learning.\r",
        logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_bbc/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/b07d99a6-9266-475c-9ac6-d822544045ec/background/{width}",
        heroTitleImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/78d2bc8ffc6b8504aee3d56889b7bf2a/herotitle.png",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_bbc/408/84",
    },
    {
      type: "landscapeTile",
      title: "At Home with Mr Tumble",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/5859aadd-8714-43f5-ac37-7a4c6af7f77c/16-9/{width}",
      hero: {
        mode: "standard",
        title: "At Home with Mr Tumble",
        metadata: "1h 45m    ",
        synopsis:
          "Join Mr Tumble at home to find out what he's been up to today. Maybe you can have a go too? He will also teach you a new Makaton word of the day and share a viewer's hilarious joke.\r",
        logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_bbc/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/5859aadd-8714-43f5-ac37-7a4c6af7f77c/background/{width}",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_bbc/408/84",
    },
    {
      type: "landscapeTile",
      title: "Octonauts: Creature Reports",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/3c9d9504-2331-416b-a55e-7221335fd6fb/16-9/{width}",
      hero: {
        mode: "standard",
        title: "Octonauts: Creature Reports",
        metadata: "1h 45m    ",
        synopsis:
          "The Octonauts are a team of underwater adventurers who explore the world's oceans, rescue the creatures who live there and protect their habitats - above and below the waves.",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/3c9d9504-2331-416b-a55e-7221335fd6fb/background/{width}",
      },
    },
    {
      type: "landscapeTile",
      title: "Andy's Dinosaur Adventures",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/1f5ec83a-ce19-4a3b-99a8-492abf3093f9/16-9/{width}",
      hero: {
        mode: "standard",
        title: "Andy's Dinosaur Adventures",
        metadata: "1h 45m    ",
        synopsis:
          "Children's series. When museum worker Andy Day's clumsiness threatens to jeopardise future exhibitions, he travels back in time to search for dinosaurs that once roamed the planet.\r",
        logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_bbc/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/1f5ec83a-ce19-4a3b-99a8-492abf3093f9/background/{width}",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_bbc/408/84",
    },
    {
      type: "landscapeTile",
      title: "Mister Maker",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/d0739966-514b-4cc8-a67c-e4ce30d73ccf/16-9/{width}",
      hero: {
        mode: "standard",
        title: "Mister Maker",
        metadata: "1h 45m    ",
        synopsis:
          "Mister Maker combines live action, graphics and animation techniques to bring the world of arts and crafts to life in a ground breaking way, for 4 - 6 year olds.",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/d0739966-514b-4cc8-a67c-e4ce30d73ccf/background/{width}",
      },
    },
  ],
} as Rail;
