import { videos } from "data/videos";
import { Journey } from "types";

export const greatestShowman: Journey = {
  id: "greatestShowman",
  intro: {
    durationMs: 1920,
    url: videos.skyCinemaIntro,
  },
  pages: [
    {
      type: "imagePage",
      url: "./assets/images/journeys/greatest-showman/sky-cinema.jpg",
    },
    {
      type: "imagePage",
      url:
        "./assets/images/journeys/greatest-showman/greatest-showman-showpage.jpg",
    },
    {
      type: "videoPage",
      url: videos.greatestShowman,
    },
  ],
  title: "Sky Cinema",
};
