import { Rail } from "types";

export default {
  type: "rail",
  uuid: "83aa47c3-2087-45dc-ac96-c694a5cccbe5",
  template: "tile-landscape",
  title: "Top TV Picks",
  tiles: [
    {
      type: "landscapeTile",
      title: "Little Birds",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/0ce2bd30-3d3d-4ce8-b8c5-6d47502b36e9/16-9/{width}",
      hero: {
        mode: "standard",
        title: "Little Birds",
        synopsis:
          "Sky original set in Tangier in 1995 - one of the last outposts of colonial decadence, and a culture shock in more ways than one. Juno Temple stars. All episodes available, plus extras.",
        metadata: "1h 45m   ",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/0ce2bd30-3d3d-4ce8-b8c5-6d47502b36e9/background/{width}",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skyatlantic/408/84",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_skyatlantic/408/84",
    },
    {
      type: "landscapeTile",
      title: "Ackley Bridge",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/994b58c4-e35a-45a4-80f3-f148840d98d2/16-9/{width}",
      hero: {
        mode: "standard",
        title: "Ackley Bridge",
        synopsis:
          "The lives and cultures of a largely divided white and Asian population in a Yorkshire mill town are set to collide when a brand new academy merging two formerly isolated comprehensives is built.",
        metadata: "1h 45m   ",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/994b58c4-e35a-45a4-80f3-f148840d98d2/background/{width}",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_channel4/408/84",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_channel4/408/84",
    },
    {
      type: "landscapeTile",
      title: "I Hate Suzie",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/cccbcf36-46e1-4be8-838a-360a5380bc68/16-9/{width}",
      hero: {
        mode: "standard",
        title: "I Hate Suzie",
        synopsis:
          "Suzie Pickles has her life upended when she is hacked and pictures of her emerge online in an extremely compromising position. Aided by her best friend and manager, Naomi, Suzie tries to hold her life together.",
        metadata: "1h 45m   ",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/cccbcf36-46e1-4be8-838a-360a5380bc68/background/{width}",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skyatlantic/408/84",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_skyatlantic/408/84",
    },
    {
      type: "landscapeTile",
      title: "Top Gear",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/7a549252-39d1-43c2-a95d-c01cd74079f4/16-9/{width}",
      hero: {
        mode: "standard",
        title: "Top Gear",
        synopsis:
          "This fast-paced and stunt-filled motor show tests whether cars, both mundane and extraordinary, live up to their manufacturers' claims. ",
        metadata: "1h 45m   ",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/7a549252-39d1-43c2-a95d-c01cd74079f4/background/{width}",
        logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_bbc2/408/84",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_bbc2/408/84",
    },
    {
      type: "landscapeTile",
      title: "9-1-1",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/2e3feb25-53cd-4670-b2d7-937e73ca6926/16-9/{width}",
      hero: {
        mode: "standard",
        title: "9-1-1",
        synopsis:
          "9-1-1 is an American procedural television series created by Ryan Murphy, Brad Falchuk, and Tim Minear for the Fox Broadcasting Company. The series follows the lives of Los Angeles first responders: police officers, paramedics, firefighters, and dispatchers. ",
        metadata: "1h 45m   ",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/2e3feb25-53cd-4670-b2d7-937e73ca6926/background/{width}",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skywitness/408/84",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_skywitness/408/84",
    },
    {
      type: "landscapeTile",
      title: "UFOs: The White House Files",
      imageUrl:
        "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/44c2115439c1f2da6cdbfc3149b728fe/{width}/{height}/item.jpg",
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_skyhistory/408/84",
      hero: {
        mode: "standard",
        title: "UFOs: The White House Files",
        synopsis:
          "From Truman to Trump, there has been a legacy of secrecy and controversy surrounding UFOs and the US presidency. Could these powerful leaders be hiding the truth from the public?",
        heroTitleImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/74891991607efe351613ba79a6ed9c3b/herotitle.png",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/34d4afa738937cbecd4feba39e8562c1/hero.jpg",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skyhistory/408/84",
        metadata: "1h 45m   ",
      },
    },
    {
      type: "landscapeTile",
      title: "The Secrets She Keeps",
      imageUrl:
        "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/0a6750952744d8f13761704645e37677/{width}/{height}/item.jpg",
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_rtone/408/84",
      hero: {
        mode: "standard",
        title: "The Secrets She Keeps",
        synopsis:
          "Two women from two different walks of life find that they have explosive secrets in common, and fight to conceal the truth as their worlds are set to collide.",
        heroTitleImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/9fa9142a46c2e93adad52ffd640a273c/herotitle.png",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/a6697bdc932c8834870dbea7f807a2d8/hero.jpg",
        logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_rtone/408/84",
        metadata: "1h 45m   ",
      },
    },
    {
      type: "landscapeTile",
      title: "Big Brother: Best Shows Ever",
      imageUrl:
        "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/932e1da2695740fee9d734fb0e5870c6/{width}/{height}/item.jpg",
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_e4/408/84",
      hero: {
        mode: "standard",
        title: "Big Brother: Best Shows Ever",
        synopsis:
          "Davina McCall and Rylan Clark-Neal are on hand to relive some of the most memorable episodes from Big Brother and Celebrity Big Brother, as the genre-defying show celebrates its 20th anniversary.",
        heroTitleImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/6a1c390b90a9f8fab628caead80f4171/herotitle.png",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/956ed8e79c3e831de53cbfd09ceb18d0/hero.jpg",
        logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_e4/408/84",
        metadata: "1h 45m   ",
      },
    },
    {
      type: "landscapeTile",
      title: "Das Boot 2",
      imageUrl:
        "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/122918386ef6ce810eef399ffa9aef9e/{width}/{height}/item.jpg",
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_skyatlantic/408/84",
      hero: {
        mode: "standard",
        title: "Das Boot 2",
        synopsis:
          "The World War II thriller returns for a second series, with three tense storylines intertwining on both sides of the Atlantic, exploring how the human soul becomes twisted by the experience of war.",
        heroTitleImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/80c193cc14e27877f9c132406fff47de/herotitle.png",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/e4f938f6a3a7c587f51e337cb82fb026/hero.jpg",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skyatlantic/408/84",
        metadata: "1h 45m   ",
      },
    },
    {
      type: "landscapeTile",
      title: "My Brilliant Friend",
      imageUrl:
        "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/eb302eac04fd03ad3f64e7fab7e576d5/{width}/{height}/item.jpg",
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_skyatlantic/408/84",
      hero: {
        mode: "standard",
        title: "My Brilliant Friend",
        synopsis:
          "Second season of the Italian drama based on Elena Ferrante's Neapolitan Novels. A writer recounts her decades-long relationship with her best friend. All episodes available.",
        heroTitleImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/57cc32d36e1f0c884ce03c7957ef7885/herotitle.png",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/68d3008913c8dbe51bf2b8f50643931c/hero.jpg",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skyatlantic/408/84",
        metadata: "1h 45m   ",
      },
    },
    {
      type: "titleTile",
      actionText: "View all",
      hero: {
        mode: "standard",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/68d3008913c8dbe51bf2b8f50643931c/hero.jpg",
      },
    },
  ],
} as Rail;
