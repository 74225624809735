import React, {
  ReactElement,
  useState,
  useEffect,
  useLayoutEffect,
} from "react";
import { usePrevious } from "hooks/usePrevious";
import { Thenable } from "types";

type Props<ChildProps> = {
  keyBy: string;
  duration?: number;
  until?: () => Thenable;
  onChange?: () => void;
  render: (
    childProps: ChildProps,
    key: string,
    isPrevious: boolean
  ) => ReactElement;
  props?: ChildProps;
};

export const KeepPrevious = <ChildProps,>({
  keyBy,
  duration,
  until,
  onChange,
  render,
  props,
}: Props<ChildProps>) => {
  const prevLastRender = usePrevious({ keyBy, props }),
    [prevSaved, setPrevSaved] = useState(prevLastRender);

  useEffect(() => {
    if (prevLastRender?.keyBy !== keyBy) {
      setPrevSaved(prevLastRender);
    }
  }, [keyBy, prevLastRender]);

  useLayoutEffect(() => {
    onChange?.();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyBy]);

  useEffect(() => {
    let timeoutID;
    if (duration !== undefined) {
      timeoutID = setTimeout(() => {
        setPrevSaved(null);
      }, duration * 1000);
    } else if (until) {
      until().then(() => setPrevSaved(null));
    }
    return function cleanup() {
      clearTimeout(timeoutID);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyBy]);

  // There's some tricky render timing stuff going on.
  // If last render has different key use that one,
  // else use the last saved one
  const previous = prevLastRender?.keyBy !== keyBy ? prevLastRender : prevSaved;

  return (
    <>
      {previous && render(previous.props, previous.keyBy, true)}
      {render(props, keyBy, false)}
    </>
  );
};
