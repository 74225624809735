import React, { useEffect, useState } from "react";

import { Container } from "./Autosize.styles";
import { getWindowSize } from "../../utils/windowSize";
import { screen } from "../../constants/dimensions";

export const Autosize: React.FC<{
  resize: boolean;
  onResize?: (scale: number) => void;
}> = ({ resize, onResize, children }) => {
  const [scale, setScale] = useState(1);

  const onResizeCallback = React.useCallback(onResize, []);

  const fillWindow = React.useCallback(() => {
    const { width, height } = getWindowSize();
    const s = Math.min(width / screen.width, height / screen.height);
    setScale(s);
    onResizeCallback?.(s);
  }, [onResizeCallback]);

  useEffect(() => {
    if (resize) {
      window.addEventListener("resize", fillWindow);
      fillWindow();
      return function cleanup() {
        window.removeEventListener("resize", fillWindow);
      };
    } else {
      setScale(1);
      onResizeCallback?.(1);
    }
  }, [resize, fillWindow, onResizeCallback]);

  return <Container scale={scale}>{children}</Container>;
};
