import { CameraFeedController } from "components/CameraFeed";
import { QAMButtonsController } from "components/QAMButtons";
import { Text } from "components/Text";
import { observer } from "mobx-react-lite";
import React from "react";
import { useDispatch } from "store";
import {
  Container,
  Column,
  Buttons,
  FeedTextContainer,
} from "./QAMDoorbellCanned.styles";

type Props = {
  focused: boolean;
  onSelectButton: (id: string) => void;
};

export const QAMDoorbellCanned = ({ focused, onSelectButton }: Props) => (
  <Container>
    <Column flex="0 0 512px">
      <CameraFeedController url={"assets/videos/live-feeds/bike-crash.mp4"} />
      <FeedTextContainer>
        <Text
          weight="regular"
          size="medium"
          style={{ position: "absolute", left: 22, bottom: 18 }}
        >
          Front door
        </Text>
      </FeedTextContainer>
    </Column>
    <Column>
      <Text weight="medium" size="medium">
        We've detected a fall.
      </Text>
      <Buttons>
        <QAMButtonsController
          focused={focused}
          buttons={[{ id: "dismiss", title: "Dismiss" }]}
          onSelect={onSelectButton}
        />
      </Buttons>
    </Column>
  </Container>
);

type ControllerProps = { focused: boolean };

export const QAMDoorbellCannedController = observer(
  ({ focused }: ControllerProps) => {
    const dispatch = useDispatch();

    const handleSelectButton = (id: string) => {
      switch (id) {
        case "dismiss":
          dispatch({ type: "QUICK_ACCESS_MENU.HIDE" });
          dispatch({ type: "UNPAUSE" });
          break;
      }
    };

    return (
      <QAMDoorbellCanned
        focused={focused}
        onSelectButton={handleSelectButton}
      />
    );
  }
);
