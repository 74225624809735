import { Action } from "types/actions";
import { ToastStatus } from "types";

export type ToastState = Readonly<{
  message?: string;
  status: ToastStatus;
  quote: boolean;
}>;

export const initialToastState = {
  status: "idle" as ToastStatus,
  quote: false,
};

export const toastReducer = (
  state: ToastState = initialToastState,
  action: Action
) => {
  switch (action.type) {
    case "SHOW_TOAST":
      return {
        ...state,
        message: action.message,
        quote: !!action.quote,
        status: action.status || state.status,
      };

    case "CHANGE_TOAST_STATUS":
      return { ...state, status: action.status };

    case "HIDE_TOAST_MESSAGE":
      return {
        ...state,
        message: undefined,
        quote: false,
        status: "idle" as ToastStatus,
      };

    default:
      return state;
  }
};
