import { Channel } from "types";

const lookup = {
  "1001": { title: "Sky Action", id: "1001", channelNumber: "308" },
  "1002": { title: "Sky Comedy Cinema", id: "1002", channelNumber: "309" },
  "1003": { title: "MUTV HD", id: "1003", channelNumber: "870" },
  "1004": { title: "Hillsong", id: "1004", channelNumber: "595" },
  "1014": { title: "ITVBe+1", id: "1014", channelNumber: "231" },
  "1015": { title: "LaLigaTV HD", id: "1015", channelNumber: "435" },
  "1018": { title: "Cruise1st.tv", id: "1018", channelNumber: "688" },
  "1019": { title: "CNN HD", id: "1019", channelNumber: "878" },
  "1023": { title: "Al Jazeera HD", id: "1023", channelNumber: "880" },
  "1026": { title: "New Vision TV", id: "1026", channelNumber: "736" },
  "1028": { title: "XXX College", id: "1028", channelNumber: "917" },
  "1029": { title: "XXX Girl Girl", id: "1029", channelNumber: "916" },
  "1030": { title: "XXXpublicpikups", id: "1030", channelNumber: "910" },
  "1031": { title: "TVX 40+", id: "1031", channelNumber: "915" },
  "1033": { title: "TJC", id: "1033", channelNumber: "662" },
  "1034": { title: "SkySpBoxOff", id: "1034", channelNumber: "491" },
  "1035": { title: "SkySpBoxOffHD", id: "1035", channelNumber: "492" },
  "1036": { title: "Paramount", id: "1036", channelNumber: "150" },
  "1038": { title: "Dunya News", id: "1038", channelNumber: "754" },
  "1048": { title: "CGTN HD", id: "1048", channelNumber: "891" },
  "1056": { title: "KTV", id: "1056", channelNumber: "771" },
  "1058": { title: "YANGA!", id: "1058", channelNumber: "186" },
  "1065": { title: "BLAZE", id: "1065", channelNumber: "164" },
  "1066": { title: "Arirang TV HD", id: "1066", channelNumber: "522" },
  "1069": { title: "HUM Masala", id: "1069", channelNumber: "732" },
  "1074": { title: "Bloomberg HD", id: "1074", channelNumber: "502" },
  "1081": { title: "Drama+1", id: "1081", channelNumber: "243" },
  "1083": { title: "Heart 90s", id: "1083", channelNumber: "0217" },
  "1088": { title: "CNBC HD", id: "1088", channelNumber: "890" },
  "1089": { title: "Smithsonian", id: "1089", channelNumber: "171" },
  "1100": { title: "Sanskar", id: "1100", channelNumber: "725" },
  "1101": { title: "Scala Radio", id: "1101", channelNumber: "0216" },
  "1102": { title: "Hi-Impact TV", id: "1102", channelNumber: "184" },
  "1104": { title: "A1TV", id: "1104", channelNumber: "759" },
  "1105": { title: "ColorsCineplex", id: "1105", channelNumber: "714" },
  "1114": { title: "Iran Int'l HD", id: "1114", channelNumber: "793" },
  "1121": { title: "FRANCE 24 HD", id: "1121", channelNumber: "512" },
  "1127": { title: "SkyDocumntrs", id: "1127", channelNumber: "114" },
  "1128": { title: "QUEST HD", id: "1128", channelNumber: "831" },
  "1129": { title: "Gemporia Craft", id: "1129", channelNumber: "670" },
  "1130": { title: "High Street TV 5", id: "1130", channelNumber: "675" },
  "1133": { title: "Live 360", id: "1133", channelNumber: "175" },
  "1134": { title: "HUM News", id: "1134", channelNumber: "760" },
  "1136": { title: "SkyDocmntrsHD", id: "1136", channelNumber: "814" },
  "1137": { title: "BBC Scotland", id: "1137", channelNumber: "457" },
  "1138": { title: "BBCScotlandHD", id: "1138", channelNumber: "876" },
  "1143": { title: "Sky Comedy HD", id: "1143", channelNumber: "813" },
  "1148": { title: "Virgin Radio", id: "1148", channelNumber: "0215" },
  "1150": { title: "FreeSports HD", id: "1150", channelNumber: "422" },
  "1165": { title: "Sky Nature HD", id: "1165", channelNumber: "893" },
  "1177": { title: "Sky Comedy", id: "1177", channelNumber: "113" },
  "1188": { title: "Good News TV", id: "1188", channelNumber: "596" },
  "1191": { title: "Dunamis TV", id: "1191", channelNumber: "597" },
  "1194": { title: "Sky Nature", id: "1194", channelNumber: "130" },
  "1195": { title: "SkyHistory2HD", id: "1195", channelNumber: "894" },
  "1199": { title: "SkyAnimationHD", id: "1199", channelNumber: "305" },
  "1205": { title: "LBC News", id: "1205", channelNumber: "0221" },
  "1206": { title: "Celebration TV", id: "1206", channelNumber: "598" },
  "1207": { title: "Court TV", id: "1207", channelNumber: "179" },
  "1212": { title: "Sky Crime HD", id: "1212", channelNumber: "819" },
  "1218": { title: "BTSBoxOffWWE", id: "1218", channelNumber: "494" },
  "1226": { title: "Travelxp", id: "1226", channelNumber: "185" },
  "1227": { title: "Foodxp", id: "1227", channelNumber: "712" },
  "1228": { title: "LoveWorld HD", id: "1228", channelNumber: "585" },
  "1234": { title: "Republic Bharat", id: "1234", channelNumber: "708" },
  "1244": { title: "Ideal World HD", id: "1244", channelNumber: "664" },
  "1245": { title: "Create&CraftHD", id: "1245", channelNumber: "683" },
  "1261": { title: "RTÉ Radio 1", id: "1261", channelNumber: "0160" },
  "1262": { title: "RTÉ 2FM", id: "1262", channelNumber: "0164" },
  "1263": { title: "RTÉ R na G", id: "1263", channelNumber: "0166" },
  "1264": { title: "RTÉ Lyric fm", id: "1264", channelNumber: "0165" },
  "1301": { title: "SkySpMainEv", id: "1301", channelNumber: "401" },
  "1302": { title: "SkySp Cricket", id: "1302", channelNumber: "404" },
  "1303": { title: "SkySp PL", id: "1303", channelNumber: "402" },
  "1305": { title: "FOX", id: "1305", channelNumber: "124" },
  "1306": { title: "SkySp F1", id: "1306", channelNumber: "406" },
  "1314": { title: "SkySp News", id: "1314", channelNumber: "409" },
  "1322": { title: "Sky Sp Golf", id: "1322", channelNumber: "405" },
  "1333": { title: "SkySp NFL", id: "1333", channelNumber: "407" },
  "1350": { title: "Box Hits", id: "1350", channelNumber: "358" },
  "1351": { title: "Disc.Sci+1", id: "1351", channelNumber: "267" },
  "1352": { title: "Animal Plnt+1", id: "1352", channelNumber: "262" },
  "1354": { title: "SkySp Racing", id: "1354", channelNumber: "415" },
  "1360": { title: "E!", id: "1360", channelNumber: "151" },
  "1371": { title: "Cartoonito", id: "1371", channelNumber: "619" },
  "1372": { title: "COLORS HD", id: "1372", channelNumber: "884" },
  "1402": { title: "Sky One", id: "1402", channelNumber: "106" },
  "1403": { title: "Sky One+1", id: "1403", channelNumber: "206" },
  "1404": { title: "Sky News", id: "1404", channelNumber: "501" },
  "1409": { title: "Sky Premiere", id: "1409", channelNumber: "301" },
  "1412": { title: "Sky Atlantic", id: "1412", channelNumber: "108" },
  "1413": { title: "Sky Atlantic+1", id: "1413", channelNumber: "208" },
  "1448": { title: "Crime+Inv", id: "1448", channelNumber: "156" },
  "1621": { title: "Channel 4", id: "1621", channelNumber: "104" },
  "1627": { title: "Film4", id: "1627", channelNumber: "313" },
  "1628": { title: "E4", id: "1628", channelNumber: "135" },
  "1629": { title: "Film4+1", id: "1629", channelNumber: "314" },
  "1634": { title: "Premier 2 HD", id: "1634", channelNumber: "429" },
  "1670": { title: "Channel 4+1", id: "1670", channelNumber: "204" },
  "1726": { title: "Eurosport 1", id: "1726", channelNumber: "410" },
  "1752": { title: "Sky Arts", id: "1752", channelNumber: "122" },
  "1754": { title: "XXX Mums", id: "1754", channelNumber: "918" },
  "1758": { title: "4Music", id: "1758", channelNumber: "139" },
  "1771": { title: "Star Plus", id: "1771", channelNumber: "705" },
  "1791": { title: "FOX+1", id: "1791", channelNumber: "224" },
  "1800": { title: "Channel 5", id: "1800", channelNumber: "105" },
  "1802": { title: "The Box", id: "1802", channelNumber: "359" },
  "1806": { title: "Nat Geo", id: "1806", channelNumber: "129" },
  "1807": { title: "Sky SciFi/Horror", id: "1807", channelNumber: "898" },
  "1808": { title: "Sky Adventure", id: "1808", channelNumber: "306" },
  "1810": { title: "Sky", id: "1810", channelNumber: "999" },
  "1811": { title: "WizardingWorld", id: "1811", channelNumber: "896" },
  "1813": { title: "ComedyXtra", id: "1813", channelNumber: "127" },
  "1814": { title: "Sky Hits", id: "1814", channelNumber: "897" },
  "1815": { title: "Sky Greats", id: "1815", channelNumber: "304" },
  "1816": { title: "Sky Drama", id: "1816", channelNumber: "311" },
  "1818": { title: "Sky Thriller", id: "1818", channelNumber: "310" },
  "1819": { title: "Sky Intro", id: "1819", channelNumber: "899" },
  "1821": { title: "NDTV 24x7", id: "1821", channelNumber: "510" },
  "1822": { title: "Nat Geo+1", id: "1822", channelNumber: "229" },
  "1827": { title: "VICE", id: "1827", channelNumber: "183" },
  "1830": { title: "GINXeSportsTV", id: "1830", channelNumber: "433" },
  "1832": { title: "Pick", id: "1832", channelNumber: "159" },
  "1833": { title: "Sky Replay", id: "1833", channelNumber: "145" },
  "1834": { title: "MUTV", id: "1834", channelNumber: "418" },
  "1836": { title: "Pick+1", id: "1836", channelNumber: "259" },
  "1838": { title: "Sky Disney", id: "1838", channelNumber: "307" },
  "1839": { title: "Channel 5+1", id: "1839", channelNumber: "205" },
  "1841": { title: "Eurosport 2", id: "1841", channelNumber: "411" },
  "1845": { title: "Nickelodeon+1", id: "1845", channelNumber: "605" },
  "1846": { title: "Nickelodeon", id: "1846", channelNumber: "604" },
  "1847": { title: "Nat Geo Wild", id: "1847", channelNumber: "165" },
  "1849": { title: "Nicktoons", id: "1849", channelNumber: "606" },
  "1850": { title: "Chl Line-up", id: "1850", channelNumber: "996" },
  "1851": { title: "Sky Intro", id: "1851", channelNumber: "998" },
  "1852": { title: "Sky Intro", id: "1852", channelNumber: "950" },
  "1853": { title: "Kerrang!", id: "1853", channelNumber: "362" },
  "1857": { title: "Nick Jr.", id: "1857", channelNumber: "615" },
  "1858": { title: "KISS", id: "1858", channelNumber: "360" },
  "1865": { title: "DMAX+1", id: "1865", channelNumber: "272" },
  "1872": { title: "Together", id: "1872", channelNumber: "170" },
  "1875": { title: "Sky History", id: "1875", channelNumber: "123" },
  "1877": { title: "Magic", id: "1877", channelNumber: "361" },
  "1879": { title: "Sky History2", id: "1879", channelNumber: "163" },
  "1891": { title: "Sky History+1", id: "1891", channelNumber: "223" },
  "2002": { title: "BBC One Lon", id: "2002", channelNumber: "101" },
  "2003": { title: "BBC One Wales", id: "2003", channelNumber: "952" },
  "2004": { title: "BBC One Scot", id: "2004", channelNumber: "951" },
  "2005": { title: "BBC One NI", id: "2005", channelNumber: "953" },
  "2006": { title: "BBC Two", id: "2006", channelNumber: "102" },
  "2011": { title: "BBC NEWS", id: "2011", channelNumber: "503" },
  "2012": { title: "BBC ALBA", id: "2012", channelNumber: "169" },
  "2015": { title: "BBC Two Wales", id: "2015", channelNumber: "971" },
  "2017": { title: "BBC Two NI", id: "2017", channelNumber: "972" },
  "2018": { title: "BBC Four", id: "2018", channelNumber: "116" },
  "2019": { title: "CBeebies", id: "2019", channelNumber: "608" },
  "2020": { title: "CBBC", id: "2020", channelNumber: "607" },
  "2021": { title: "BBC Cymru 2", id: "2021", channelNumber: "0219" },
  "2026": { title: "BBC R5L", id: "2026", channelNumber: "0105" },
  "2034": { title: "BBC R5SX", id: "2034", channelNumber: "0144" },
  "2051": { title: "BBC RB 1", id: "2051", channelNumber: "981" },
  "2072": { title: "BBC Parliament", id: "2072", channelNumber: "504" },
  "2073": { title: "BBC One East W", id: "2073", channelNumber: "962" },
  "2074": { title: "BBC One CI", id: "2074", channelNumber: "968" },
  "2075": { title: "BBC Two HD", id: "2075", channelNumber: "802" },
  "2076": { title: "BBC One HD", id: "2076", channelNumber: "115" },
  "2078": { title: "CBBC HD", id: "2078", channelNumber: "643" },
  "2081": { title: "BBC One NI HD", id: "2081", channelNumber: "979" },
  "2082": { title: "BBC One ScotHD", id: "2082", channelNumber: "977" },
  "2083": { title: "BBC One Wal HD", id: "2083", channelNumber: "978" },
  "2085": { title: "BBC NEWS HD", id: "2085", channelNumber: "877" },
  "2086": { title: "BBC Four HD", id: "2086", channelNumber: "815" },
  "2088": { title: "CBeebies HD", id: "2088", channelNumber: "644" },
  "2101": { title: "BBC One W Mid", id: "2101", channelNumber: "959" },
  "2102": { title: "BBC One N West", id: "2102", channelNumber: "958" },
  "2103": { title: "BBC One Yk&Li", id: "2103", channelNumber: "957" },
  "2104": { title: "BBC One Yorks", id: "2104", channelNumber: "956" },
  "2105": { title: "BBC One E Mid", id: "2105", channelNumber: "960" },
  "2106": { title: "BBC One East E", id: "2106", channelNumber: "961" },
  "2111": { title: "BBC R1", id: "2111", channelNumber: "0101" },
  "2112": { title: "BBC R2", id: "2112", channelNumber: "0102" },
  "2113": { title: "BBC R3", id: "2113", channelNumber: "0103" },
  "2114": { title: "BBC R4 FM", id: "2114", channelNumber: "0104" },
  "2115": { title: "BBC R4 LW", id: "2115", channelNumber: "0143" },
  "2117": { title: "BBC R Scot.", id: "2117", channelNumber: "0116" },
  "2118": { title: "BBC R Wales", id: "2118", channelNumber: "0117" },
  "2119": { title: "BBC R Ulster", id: "2119", channelNumber: "0118" },
  "2120": { title: "BBC Asian", id: "2120", channelNumber: "0119" },
  "2122": { title: "BBC WS", id: "2122", channelNumber: "0115" },
  "2123": { title: "BBC R Cymru", id: "2123", channelNumber: "0154" },
  "2124": { title: "BBC R Foyle", id: "2124", channelNumber: "0220" },
  "2126": { title: "BBC R1X", id: "2126", channelNumber: "0137" },
  "2127": { title: "BBC 6 Music", id: "2127", channelNumber: "0120" },
  "2128": { title: "BBC R4 Ex", id: "2128", channelNumber: "0131" },
  "2129": { title: "BBC R n Gael", id: "2129", channelNumber: "0139" },
  "2130": { title: "BBC RLondon", id: "2130", channelNumber: "0152" },
  "2151": { title: "BBC One West", id: "2151", channelNumber: "966" },
  "2152": { title: "BBC One S East", id: "2152", channelNumber: "963" },
  "2153": { title: "BBC One South", id: "2153", channelNumber: "964" },
  "2154": { title: "BBC One S West", id: "2154", channelNumber: "967" },
  "2155": { title: "BBC One NE&C", id: "2155", channelNumber: "955" },
  "2156": { title: "BBC One Oxford", id: "2156", channelNumber: "965" },
  "2201": { title: "Sky Witness", id: "2201", channelNumber: "107" },
  "2202": { title: "Challenge", id: "2202", channelNumber: "160" },
  "2205": { title: "Sky Witness+1", id: "2205", channelNumber: "207" },
  "2207": { title: "Sky Crime", id: "2207", channelNumber: "121" },
  "2296": { title: "S4C HD", id: "2296", channelNumber: "828" },
  "2301": { title: "HGTV", id: "2301", channelNumber: "158" },
  "2302": { title: "Eden", id: "2302", channelNumber: "166" },
  "2303": { title: "alibi", id: "2303", channelNumber: "132" },
  "2304": { title: "GOLD", id: "2304", channelNumber: "110" },
  "2305": { title: "YESTERDAY", id: "2305", channelNumber: "155" },
  "2306": { title: "Dave", id: "2306", channelNumber: "111" },
  "2307": { title: "Eden+1", id: "2307", channelNumber: "266" },
  "2309": { title: "HGTV+1", id: "2309", channelNumber: "258" },
  "2320": { title: "Dave ja vu", id: "2320", channelNumber: "211" },
  "2325": { title: "Really", id: "2325", channelNumber: "142" },
  "2401": { title: "Discovery", id: "2401", channelNumber: "125" },
  "2402": { title: "Animal Planet", id: "2402", channelNumber: "162" },
  "2404": { title: "Discovery+1", id: "2404", channelNumber: "225" },
  "2406": { title: "Disc.Science", id: "2406", channelNumber: "167" },
  "2407": { title: "Disc.History", id: "2407", channelNumber: "177" },
  "2408": { title: "Home & Health", id: "2408", channelNumber: "178" },
  "2409": { title: "Disc.Turbo", id: "2409", channelNumber: "161" },
  "2410": { title: "HomeHealth+1", id: "2410", channelNumber: "278" },
  "2411": { title: "Quest Red", id: "2411", channelNumber: "149" },
  "2413": { title: "ID", id: "2413", channelNumber: "154" },
  "2501": { title: "MTV", id: "2501", channelNumber: "126" },
  "2505": { title: "SYFY", id: "2505", channelNumber: "152" },
  "2506": { title: "MTV HITS", id: "2506", channelNumber: "351" },
  "2508": { title: "MTV BASE", id: "2508", channelNumber: "353" },
  "2509": { title: "MTV CLASSIC", id: "2509", channelNumber: "355" },
  "2510": { title: "ComedyCentral", id: "2510", channelNumber: "112" },
  "2512": { title: "MTV MUSIC", id: "2512", channelNumber: "350" },
  "2513": { title: "SYFY+1", id: "2513", channelNumber: "252" },
  "2601": { title: "SONY TV", id: "2601", channelNumber: "703" },
  "2603": { title: "SONY MAX", id: "2603", channelNumber: "715" },
  "2607": { title: "Zee TV", id: "2607", channelNumber: "709" },
  "2608": { title: "Zee Cinema", id: "2608", channelNumber: "707" },
  "2612": { title: "Drama", id: "2612", channelNumber: "143" },
  "2615": { title: "YESTERDAY+1", id: "2615", channelNumber: "255" },
  "2616": { title: "W+1", id: "2616", channelNumber: "209" },
  "2617": { title: "W", id: "2617", channelNumber: "109" },
  "2630": { title: "alibi+1", id: "2630", channelNumber: "232" },
  "2703": { title: "GOLD+1", id: "2703", channelNumber: "210" },
  "2862": { title: "UCB Ireland", id: "2862", channelNumber: "0214" },
  "2901": { title: "Absolute", id: "2901", channelNumber: "0107" },
  "2902": { title: "talkSPORT", id: "2902", channelNumber: "0108" },
  "2903": { title: "Gold", id: "2903", channelNumber: "0121" },
  "2904": { title: "Radio X", id: "2904", channelNumber: "0113" },
  "2908": { title: "Capital", id: "2908", channelNumber: "0109" },
  "2909": { title: "Heart", id: "2909", channelNumber: "0111" },
  "2917": { title: "Smooth", id: "2917", channelNumber: "0128" },
  "2920": { title: "Heart Dance", id: "2920", channelNumber: "0146" },
  "2926": { title: "LBC", id: "2926", channelNumber: "0124" },
  "2928": { title: "Heart 80s", id: "2928", channelNumber: "0112" },
  "2971": { title: "Capital XTRA", id: "2971", channelNumber: "0114" },
  "3010": { title: "Gems TV", id: "3010", channelNumber: "665" },
  "3012": { title: "Craft Extra", id: "3012", channelNumber: "684" },
  "3015": { title: "BBC Two Wal HD", id: "3015", channelNumber: "980" },
  "3022": { title: "5 USA", id: "3022", channelNumber: "141" },
  "3023": { title: "5STAR", id: "3023", channelNumber: "128" },
  "3024": { title: "5STAR+1", id: "3024", channelNumber: "228" },
  "3027": { title: "5USA+1", id: "3027", channelNumber: "241" },
  "3028": { title: "5SELECT", id: "3028", channelNumber: "153" },
  "3105": { title: "GOD Channel", id: "3105", channelNumber: "580" },
  "3107": { title: "Al Jazeera Eng", id: "3107", channelNumber: "513" },
  "3108": { title: "Vox Africa", id: "3108", channelNumber: "193" },
  "3109": { title: "TBN UK", id: "3109", channelNumber: "582" },
  "3111": { title: "Eman Channel", id: "3111", channelNumber: "757" },
  "3116": { title: "revelation", id: "3116", channelNumber: "581" },
  "3117": { title: "EWTN Catholic", id: "3117", channelNumber: "588" },
  "3141": { title: "BT Sport//ESPN", id: "3141", channelNumber: "423" },
  "3147": { title: "NHK World HD", id: "3147", channelNumber: "507" },
  "3150": { title: "4seven", id: "3150", channelNumber: "137" },
  "3201": { title: "mta-muslim tv", id: "3201", channelNumber: "731" },
  "3205": { title: "Word Network", id: "3205", channelNumber: "587" },
  "3206": { title: "Showcase", id: "3206", channelNumber: "192" },
  "3208": { title: "BEN", id: "3208", channelNumber: "458" },
  "3209": { title: "Abu Dhabi TV", id: "3209", channelNumber: "786" },
  "3211": { title: "CGTN", id: "3211", channelNumber: "509" },
  "3213": { title: "RT", id: "3213", channelNumber: "511" },
  "3214": { title: "Islam Channel", id: "3214", channelNumber: "737" },
  "3219": { title: "Inspiration TV", id: "3219", channelNumber: "584" },
  "3255": { title: "AAJ TAK", id: "3255", channelNumber: "710" },
  "3258": { title: "PCNE Chinese", id: "3258", channelNumber: "791" },
  "3300": { title: "E4+1", id: "3300", channelNumber: "235" },
  "3310": { title: "More4+1", id: "3310", channelNumber: "236" },
  "3340": { title: "More4", id: "3340", channelNumber: "136" },
  "3352": { title: "CBS Reality", id: "3352", channelNumber: "146" },
  "3354": { title: "JewelleryMaker", id: "3354", channelNumber: "674" },
  "3355": { title: "Sangat", id: "3355", channelNumber: "769" },
  "3357": { title: "Hochanda", id: "3357", channelNumber: "673" },
  "3359": { title: "QVC Extra", id: "3359", channelNumber: "682" },
  "3363": { title: "Panjab Radio", id: "3363", channelNumber: "0130" },
  "3368": { title: "Sukh Sagar", id: "3368", channelNumber: "0150" },
  "3371": { title: "BFBS Radio", id: "3371", channelNumber: "0211" },
  "3383": { title: "Khushkhabri", id: "3383", channelNumber: "0151" },
  "3392": { title: "SonLife", id: "3392", channelNumber: "593" },
  "3400": { title: "Babenation", id: "3400", channelNumber: "906" },
  "3401": { title: "MATV National", id: "3401", channelNumber: "711" },
  "3403": { title: "NOW 70s", id: "3403", channelNumber: "368" },
  "3404": { title: "High Street TV 4", id: "3404", channelNumber: "672" },
  "3414": { title: "Record TV HD", id: "3414", channelNumber: "792" },
  "3415": { title: "Psychic Today", id: "3415", channelNumber: "680" },
  "3417": { title: "BoxNation", id: "3417", channelNumber: "427" },
  "3421": { title: "Xpanded TV2", id: "3421", channelNumber: "914" },
  "3470": { title: "Classic FM", id: "3470", channelNumber: "0106" },
  "3475": { title: "Planet Rock", id: "3475", channelNumber: "0110" },
  "3505": { title: "Trace Xmas", id: "3505", channelNumber: "364" },
  "3530": { title: "Studio 66 3", id: "3530", channelNumber: "924" },
  "3542": { title: "ROK", id: "3542", channelNumber: "329" },
  "3580": { title: "High Street TV 1", id: "3580", channelNumber: "666" },
  "3590": { title: "Food Network", id: "3590", channelNumber: "140" },
  "3592": { title: "Food Netwrk+1", id: "3592", channelNumber: "240" },
  "3594": { title: "Aastha", id: "3594", channelNumber: "724" },
  "3602": { title: "CBS Reality+1", id: "3602", channelNumber: "246" },
  "3605": { title: "horror channel", id: "3605", channelNumber: "317" },
  "3608": { title: "Star Bharat", id: "3608", channelNumber: "704" },
  "3611": { title: "CNBC", id: "3611", channelNumber: "505" },
  "3613": { title: "Star GOLD", id: "3613", channelNumber: "717" },
  "3616": { title: "Newstalk", id: "3616", channelNumber: "0210" },
  "3617": { title: "CBS Drama", id: "3617", channelNumber: "147" },
  "3618": { title: "DMAX", id: "3618", channelNumber: "172" },
  "3620": { title: "Crime+Inv+1", id: "3620", channelNumber: "256" },
  "3621": { title: "QUEST+1", id: "3621", channelNumber: "244" },
  "3625": { title: "BT Sport 1 HD", id: "3625", channelNumber: "867" },
  "3627": { title: "BT Sport 2 HD", id: "3627", channelNumber: "868" },
  "3629": { title: "BT Sport 3 HD", id: "3629", channelNumber: "869" },
  "3631": { title: "SONY SAB", id: "3631", channelNumber: "718" },
  "3632": { title: "TV Warehouse", id: "3632", channelNumber: "676" },
  "3636": { title: "Racing TV", id: "3636", channelNumber: "426" },
  "3643": { title: "Sony Christmas", id: "3643", channelNumber: "319" },
  "3645": { title: "BabyTV", id: "3645", channelNumber: "609" },
  "3646": { title: "PTV Global", id: "3646", channelNumber: "735" },
  "3650": { title: "Sikh Channel", id: "3650", channelNumber: "768" },
  "3653": { title: "Brit Asia TV", id: "3653", channelNumber: "767" },
  "3656": { title: "Kiss", id: "3656", channelNumber: "0178" },
  "3660": { title: "Magic", id: "3660", channelNumber: "0180" },
  "3661": { title: "BT Sport 1", id: "3661", channelNumber: "413" },
  "3663": { title: "BT Sport 2", id: "3663", channelNumber: "414" },
  "3682": { title: "NOW 90s", id: "3682", channelNumber: "372" },
  "3687": { title: "Channel 7", id: "3687", channelNumber: "182" },
  "3701": { title: "Ahlulbayt TV", id: "3701", channelNumber: "787" },
  "3708": { title: "Sony Action", id: "3708", channelNumber: "323" },
  "3709": { title: "Sony Movies", id: "3709", channelNumber: "321" },
  "3714": { title: "COLORS", id: "3714", channelNumber: "706" },
  "3715": { title: "Noor TV", id: "3715", channelNumber: "739" },
  "3719": { title: "BET:BlackEntTv", id: "3719", channelNumber: "173" },
  "3720": { title: "Venus TV", id: "3720", channelNumber: "716" },
  "3721": { title: "Sony Action+1", id: "3721", channelNumber: "324" },
  "3722": { title: "Samaa", id: "3722", channelNumber: "748" },
  "3731": { title: "mytv", id: "3731", channelNumber: "191" },
  "3750": { title: "POP", id: "3750", channelNumber: "616" },
  "3751": { title: "Sony Xmas+1", id: "3751", channelNumber: "320" },
  "3753": { title: "Kanshi TV", id: "3753", channelNumber: "772" },
  "3760": { title: "Disc.History+1", id: "3760", channelNumber: "277" },
  "3771": { title: "Sony Movies+1", id: "3771", channelNumber: "322" },
  "3776": { title: "KICC TV", id: "3776", channelNumber: "590" },
  "3780": { title: "Tiny Pop", id: "3780", channelNumber: "617" },
  "3802": { title: "ComedyCent+1", id: "3802", channelNumber: "212" },
  "3805": { title: "Thane", id: "3805", channelNumber: "679" },
  "3809": { title: "Dave HD", id: "3809", channelNumber: "811" },
  "3810": { title: "W HD", id: "3810", channelNumber: "809" },
  "3812": { title: "TLC HD", id: "3812", channelNumber: "827" },
  "3815": { title: "CHSTV", id: "3815", channelNumber: "777" },
  "3822": { title: "92 News", id: "3822", channelNumber: "743" },
  "3825": { title: "alibi HD", id: "3825", channelNumber: "826" },
  "3831": { title: "MTV HD", id: "3831", channelNumber: "823" },
  "3835": { title: "SkySp F1 HD", id: "3835", channelNumber: "860" },
  "3838": { title: "SkySp F'ball", id: "3838", channelNumber: "403" },
  "3839": { title: "SkySp Arena", id: "3839", channelNumber: "408" },
  "3845": { title: "LFCTV HD", id: "3845", channelNumber: "425" },
  "3914": { title: "IQRA BANGLA", id: "3914", channelNumber: "778" },
  "3916": { title: "ColorsRishtey", id: "3916", channelNumber: "713" },
  "3935": { title: "Trace Vault", id: "3935", channelNumber: "365" },
  "3939": { title: "SkySp F'ball HD", id: "3939", channelNumber: "857" },
  "3940": { title: "SkySp ArenaHD", id: "3940", channelNumber: "862" },
  "4002": { title: "SkySpMainEvHD", id: "4002", channelNumber: "855" },
  "4003": { title: "Discovery HD", id: "4003", channelNumber: "822" },
  "4004": { title: "Eurosport 1 HD", id: "4004", channelNumber: "864" },
  "4009": { title: "Eurosport 2 HD", id: "4009", channelNumber: "865" },
  "4010": { title: "SkySp PL HD", id: "4010", channelNumber: "856" },
  "4013": { title: "Sky Disney HD", id: "4013", channelNumber: "847" },
  "4014": { title: "Sky Action HD", id: "4014", channelNumber: "848" },
  "4015": { title: "Sky Greats HD", id: "4015", channelNumber: "845" },
  "4016": { title: "Sky Drama HD", id: "4016", channelNumber: "851" },
  "4017": { title: "Sky ScFi/HorHD", id: "4017", channelNumber: "312" },
  "4018": { title: "SkyAdventureHD", id: "4018", channelNumber: "846" },
  "4019": { title: "Sky Comedy HD", id: "4019", channelNumber: "849" },
  "4020": { title: "WizardingWldHD", id: "4020", channelNumber: "302" },
  "4021": { title: "SkyPremiereHD", id: "4021", channelNumber: "842" },
  "4022": { title: "SkySp NFL HD", id: "4022", channelNumber: "861" },
  "4023": { title: "FOX HD", id: "4023", channelNumber: "821" },
  "4025": { title: "NatGeoWild HD", id: "4025", channelNumber: "839" },
  "4026": { title: "SkySp Golf HD", id: "4026", channelNumber: "859" },
  "4028": { title: "E! HD", id: "4028", channelNumber: "834" },
  "4030": { title: "Boomerang HD", id: "4030", channelNumber: "641" },
  "4031": { title: "Nat Geo HD", id: "4031", channelNumber: "824" },
  "4032": { title: "SkySp Racing HD", id: "4032", channelNumber: "888" },
  "4033": { title: "Sky Hits HD", id: "4033", channelNumber: "303" },
  "4039": { title: "Nick Jr. HD", id: "4039", channelNumber: "648" },
  "4040": { title: "BTSpt//ESPNHD", id: "4040", channelNumber: "872" },
  "4043": { title: "More4 HD", id: "4043", channelNumber: "830" },
  "4044": { title: "Film4 HD", id: "4044", channelNumber: "853" },
  "4045": { title: "SONY TV HD", id: "4045", channelNumber: "882" },
  "4046": { title: "SONY MAX HD", id: "4046", channelNumber: "886" },
  "4047": { title: "Star GOLD HD", id: "4047", channelNumber: "887" },
  "4049": { title: "SkySp News HD", id: "4049", channelNumber: "863" },
  "4050": { title: "Sky News HD", id: "4050", channelNumber: "875" },
  "4053": { title: "Sky Atlantic HD", id: "4053", channelNumber: "808" },
  "4056": { title: "ComedyCentHD", id: "4056", channelNumber: "812" },
  "4058": { title: "Channel 5 HD", id: "4058", channelNumber: "805" },
  "4061": { title: "Sky One HD", id: "4061", channelNumber: "806" },
  "4062": { title: "Sky Thriller HD", id: "4062", channelNumber: "850" },
  "4063": { title: "Sky Arts HD", id: "4063", channelNumber: "820" },
  "4066": { title: "Sky Witness HD", id: "4066", channelNumber: "807" },
  "4069": { title: "NickelodeonHD", id: "4069", channelNumber: "642" },
  "4074": { title: "SYFY HD", id: "4074", channelNumber: "892" },
  "4075": { title: "Channel 4 HD", id: "4075", channelNumber: "804" },
  "4076": { title: "E4 HD", id: "4076", channelNumber: "829" },
  "4077": { title: "Cartoon Net HD", id: "4077", channelNumber: "640" },
  "4081": { title: "SkySpCricketHD", id: "4081", channelNumber: "858" },
  "4086": { title: "Sky HistoryHD", id: "4086", channelNumber: "825" },
  "4088": { title: "Crime+Inv HD", id: "4088", channelNumber: "835" },
  "4090": { title: "SkySp Mix HD", id: "4090", channelNumber: "895" },
  "4091": { title: "SkySp Mix", id: "4091", channelNumber: "416" },
  "4100": { title: "Best Direct", id: "4100", channelNumber: "669" },
  "4105": { title: "QVC Beauty", id: "4105", channelNumber: "663" },
  "4110": { title: "GEO TV", id: "4110", channelNumber: "738" },
  "4152": { title: "Desi Radio", id: "4152", channelNumber: "0169" },
  "4155": { title: "TWR", id: "4155", channelNumber: "0138" },
  "4158": { title: "WRN Europe", id: "4158", channelNumber: "0122" },
  "4163": { title: "EWTN Catholic", id: "4163", channelNumber: "0147" },
  "4191": { title: "Xpanded TV", id: "4191", channelNumber: "902" },
  "4192": { title: "Television X", id: "4192", channelNumber: "900" },
  "4195": { title: "Adult Channel", id: "4195", channelNumber: "901" },
  "4197": { title: "babes&brazzers", id: "4197", channelNumber: "903" },
  "4216": { title: "POP+1", id: "4216", channelNumber: "625" },
  "4261": { title: "Tiny Pop+1", id: "4261", channelNumber: "624" },
  "4262": { title: "POP Max", id: "4262", channelNumber: "626" },
  "4263": { title: "POP Max+1", id: "4263", channelNumber: "627" },
  "4265": { title: "ARY Family", id: "4265", channelNumber: "758" },
  "4266": { title: "Sony Channel", id: "4266", channelNumber: "157" },
  "4320": { title: "Lifetime", id: "4320", channelNumber: "187" },
  "4330": { title: "Sky Crime+1", id: "4330", channelNumber: "221" },
  "4340": { title: "NickJrPAWPatrol", id: "4340", channelNumber: "620" },
  "4371": { title: "Classic Rock", id: "4371", channelNumber: "0203" },
  "4372": { title: "Absolute 90s", id: "4372", channelNumber: "0201" },
  "4373": { title: "Absolute 80s", id: "4373", channelNumber: "0200" },
  "4376": { title: "Jazz FM", id: "4376", channelNumber: "0202" },
  "4403": { title: "Islam TV", id: "4403", channelNumber: "744" },
  "4404": { title: "Faith World TV", id: "4404", channelNumber: "589" },
  "4407": { title: "GEO News", id: "4407", channelNumber: "734" },
  "4408": { title: "Takbeer TV", id: "4408", channelNumber: "749" },
  "4410": { title: "QVC Style", id: "4410", channelNumber: "677" },
  "4420": { title: "Movies 24", id: "4420", channelNumber: "325" },
  "4421": { title: "Movies 24+", id: "4421", channelNumber: "326" },
  "4460": { title: "Madani Chnl", id: "4460", channelNumber: "746" },
  "4461": { title: "iON TV", id: "4461", channelNumber: "782" },
  "4502": { title: "horror ch+1", id: "4502", channelNumber: "318" },
  "4505": { title: "Clubland TV", id: "4505", channelNumber: "373" },
  "4538": { title: "Get Lucky TV", id: "4538", channelNumber: "908" },
  "4540": { title: "NTV", id: "4540", channelNumber: "780" },
  "4541": { title: "NOW 80s", id: "4541", channelNumber: "371" },
  "4547": { title: "Quest Red+1", id: "4547", channelNumber: "249" },
  "4548": { title: "Discovery Shed", id: "4548", channelNumber: "197" },
  "4549": { title: "Hidayat TV", id: "4549", channelNumber: "733" },
  "4604": { title: "Euronews", id: "4604", channelNumber: "508" },
  "4610": { title: "CBS Justice", id: "4610", channelNumber: "148" },
  "4645": { title: "SkyNewsArabia", id: "4645", channelNumber: "788" },
  "4933": { title: "Spotlight TV", id: "4933", channelNumber: "376" },
  "5032": { title: "BT Sport 3", id: "5032", channelNumber: "417" },
  "5040": { title: "Arise News", id: "5040", channelNumber: "519" },
  "5071": { title: "TRT World", id: "5071", channelNumber: "516" },
  "5087": { title: "Akaal Channel", id: "5087", channelNumber: "770" },
  "5089": { title: "Nick Jr+1", id: "5089", channelNumber: "628" },
  "5090": { title: "London Live", id: "5090", channelNumber: "117" },
  "5135": { title: "Smithsonian HD", id: "5135", channelNumber: "889" },
  "5153": { title: "Premier 1 HD", id: "5153", channelNumber: "412" },
  "5205": { title: "HUM EUROPE", id: "5205", channelNumber: "751" },
  "5211": { title: "Islam Ch Urdu", id: "5211", channelNumber: "755" },
  "5212": { title: "High Street TV 3", id: "5212", channelNumber: "668" },
  "5237": { title: "TRT World HD", id: "5237", channelNumber: "881" },
  "5245": { title: "Disc.Turbo+1", id: "5245", channelNumber: "261" },
  "5252": { title: "TalkingPictures", id: "5252", channelNumber: "328" },
  "5272": { title: "Racing TV HD", id: "5272", channelNumber: "874" },
  "5275": { title: "TCM Movies+1", id: "5275", channelNumber: "316" },
  "5300": { title: "Star Plus HD", id: "5300", channelNumber: "883" },
  "5314": { title: "RT HD", id: "5314", channelNumber: "879" },
  "5338": { title: "Sony Channel+1", id: "5338", channelNumber: "257" },
  "5354": { title: "Faith UK", id: "5354", channelNumber: "594" },
  "5359": { title: "Channel 44", id: "5359", channelNumber: "756" },
  "5415": { title: "Forces TV", id: "5415", channelNumber: "181" },
  "5417": { title: "British Muslim", id: "5417", channelNumber: "752" },
  "5426": { title: "TV One", id: "5426", channelNumber: "781" },
  "5431": { title: "SONY MAX 2", id: "5431", channelNumber: "720" },
  "5450": { title: "TLC", id: "5450", channelNumber: "133" },
  "5451": { title: "TLC+1", id: "5451", channelNumber: "233" },
  "5452": { title: "ID+1", id: "5452", channelNumber: "254" },
  "5500": { title: "PBS America", id: "5500", channelNumber: "174" },
  "5520": { title: "Channels 24", id: "5520", channelNumber: "518" },
  "5541": { title: "DAYSTAR HD", id: "5541", channelNumber: "583" },
  "5562": { title: "Hits Radio", id: "5562", channelNumber: "0188" },
  "5601": { title: "Cartoon Netwrk", id: "5601", channelNumber: "601" },
  "5602": { title: "CNN", id: "5602", channelNumber: "506" },
  "5603": { title: "Boomerang+1", id: "5603", channelNumber: "618" },
  "5605": { title: "TCM Movies", id: "5605", channelNumber: "315" },
  "5609": { title: "Boomerang", id: "5609", channelNumber: "603" },
  "5610": { title: "CN+1", id: "5610", channelNumber: "602" },
  "5701": { title: "S4C", id: "5701", channelNumber: "134" },
  "5703": { title: "B4U Movies", id: "5703", channelNumber: "701" },
  "5706": { title: "High Street TV 2", id: "5706", channelNumber: "667" },
  "5708": { title: "B4U Music", id: "5708", channelNumber: "702" },
  "5715": { title: "PTC PUNJABI", id: "5715", channelNumber: "766" },
  "5740": { title: "Ideal Extra", id: "5740", channelNumber: "671" },
  "5761": { title: "JML Direct", id: "5761", channelNumber: "661" },
  "5882": { title: "TVC News", id: "5882", channelNumber: "515" },
  "5907": { title: "QVC", id: "5907", channelNumber: "660" },
  "6000": { title: "ITV", id: "6000", channelNumber: "103" },
  "6014": { title: "BTSptBoxOffHD", id: "6014", channelNumber: "490" },
  "6155": { title: "ITV+1", id: "6155", channelNumber: "203" },
  "6240": { title: "ITV2", id: "6240", channelNumber: "118" },
  "6241": { title: "ITV2+1", id: "6241", channelNumber: "218" },
  "6260": { title: "ITV3", id: "6260", channelNumber: "119" },
  "6261": { title: "ITV3+1", id: "6261", channelNumber: "219" },
  "6272": { title: "ITV4", id: "6272", channelNumber: "120" },
  "6273": { title: "CITV", id: "6273", channelNumber: "621" },
  "6274": { title: "ITV4+1", id: "6274", channelNumber: "220" },
  "6504": { title: "ITV HD", id: "6504", channelNumber: "803" },
  "6508": { title: "ITVBe", id: "6508", channelNumber: "131" },
  "6513": { title: "GOLD HD", id: "6513", channelNumber: "810" },
  "6532": { title: "ITV2 HD", id: "6532", channelNumber: "816" },
  "6533": { title: "ITV3 HD", id: "6533", channelNumber: "817" },
  "6534": { title: "ITV4 HD", id: "6534", channelNumber: "818" },
  "6753": { title: "ATN", id: "6753", channelNumber: "779" },
  "6754": { title: "IQRA TV", id: "6754", channelNumber: "742" },
  "6761": { title: "QUEST", id: "6761", channelNumber: "144" },
  "6765": { title: "Ahlebait TV", id: "6765", channelNumber: "745" },
  "8025": { title: "Browse Shop", id: "8025", channelNumber: "4901" },
  "8040": { title: "My Sky", id: "8040", channelNumber: "2049" },
  "8266": { title: "My Account", id: "8266", channelNumber: "4903" },
  "8301": { title: "Program Your Remote", id: "8301", channelNumber: "4906" },
  "8331": { title: "Help and Support", id: "8331", channelNumber: "4905" },
  "8346": { title: "Reset your PIN", id: "8346", channelNumber: "4902" },
  "8459": { title: "Get Sky Products", id: "8459", channelNumber: "4904" },
};

export type ChannelId = keyof typeof lookup;

export const getChannel = (id: string): Channel => {
  if (!lookup[id]) {
    throw new Error(`Couldn't find channel with id '${id}'`);
  }
  return lookup[id] as Channel;
};

export const allChannelIds = Object.keys(lookup) as ChannelId[];
