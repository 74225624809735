import { PageId } from "types";

export type VideoPageState = Readonly<{
  pageType: "videoPage";
  pageId: PageId;
}>;

export const newPageState = (pageId: PageId): VideoPageState => ({
  pageType: "videoPage",
  pageId,
});
