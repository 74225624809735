import React, { useState, FormEvent, FC } from "react";
import { Form } from "./RoomForm.styles";
import QRCode from "../QRCode/QRCode";

const RoomForm: FC<{
  handleConnect: Function;
  handleDisconnect: Function;
  status: string;
  initialRoomId: string;
  doorbellUrl: string;
  roomIdChangeCallback?: Function;
  isConnectEnabled: boolean;
}> = ({
  handleConnect,
  handleDisconnect,
  status,
  initialRoomId,
  doorbellUrl,
  roomIdChangeCallback,
  isConnectEnabled,
}) => {
  const [roomId, setRoomId] = useState<string>(initialRoomId);

  function handleChangeRoomId(e: FormEvent<HTMLInputElement>) {
    const newRoomId: string = e.currentTarget.value;
    setRoomId(newRoomId);
    if (typeof handleChangeRoomId === "function") {
      roomIdChangeCallback(newRoomId);
    }
  }

  function handleSubmit(e: FormEvent<HTMLFormElement>): void {
    e.preventDefault();
    handleConnect(roomId);
  }

  function handleDisconnectButton() {
    handleDisconnect();
  }

  return (
    <div>
      <Form onSubmit={handleSubmit}>
        <label>Room ID:</label>
        <input type="text" value={roomId} onChange={handleChangeRoomId} />
        <button type={"submit"} disabled={!isConnectEnabled}>
          Connect
        </button>
        <button
          type={"button"}
          disabled={isConnectEnabled}
          onClick={handleDisconnectButton}
        >
          Disconnect
        </button>
        <p>Status: {status}</p>
        <p>
          <a href={doorbellUrl} target="_blank" rel="noopener noreferrer">
            {doorbellUrl}
          </a>
        </p>
        <QRCode url={doorbellUrl} />
      </Form>
    </div>
  );
};

export default RoomForm;
