import { Rail } from "types";

export default {
  type: "rail",
  uuid: "39d2ca7a-cded-4233-88eb-7ff2e3e24362",
  template: "tile-landscape",
  title: "Family Favourites",
  tiles: [
    {
      type: "landscapeTile",
      title: "The Young Offenders",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/59af871b-4db7-4199-90ef-5bdc97029542/16-9/{width}",
      hero: {
        mode: "standard",
        title: "The Young Offenders",
        metadata: "1h 45m   ",
        synopsis:
          "Episode 1. Comedy following the coming-of-age adventures of lovable rogues Conor and Jock. Contains very strong language.",
        logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_bbc/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/59af871b-4db7-4199-90ef-5bdc97029542/background/{width}",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_bbc/408/84",
    },
    {
      type: "landscapeTile",
      title: "The Other One",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/e9458d53-8342-4017-b429-7383d9925bc4/16-9/{width}",
      hero: {
        mode: "standard",
        title: "The Other One",
        metadata: "1h 45m   ",
        synopsis:
          "Episode 1. Comedy about a girl called Catherine Walcott. And another girl called Catherine Walcott. Contains adult humour.",
        logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_bbc/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/e9458d53-8342-4017-b429-7383d9925bc4/background/{width}",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_bbc/408/84",
    },
    {
      type: "landscapeTile",
      title: "French and Saunders",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/23354900-85d2-4728-862e-aac2851de3ee/16-9/{width}",
      hero: {
        mode: "standard",
        title: "French and Saunders",
        metadata: "1h 45m   ",
        synopsis:
          "Episode 1. Light entertainment variety show, starring Dawn French and Jennifer Saunders.",
        logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_bbc/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/23354900-85d2-4728-862e-aac2851de3ee/background/{width}",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_bbc/408/84",
    },
    {
      type: "landscapeTile",
      title: "Goodness Gracious Me",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/e9c835e7-9b32-4fdc-ba44-7d0e424c635d/16-9/{width}",
      hero: {
        mode: "standard",
        title: "Goodness Gracious Me",
        metadata: "1h 45m   ",
        synopsis:
          "Episode 1. Highlights include life with the Coopers and nightclubbing with the Bhangramuffins. Contains adult humour.",
        logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_bbc/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/e9c835e7-9b32-4fdc-ba44-7d0e424c635d/background/{width}",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_bbc/408/84",
    },
    {
      type: "landscapeTile",
      title: "Staged",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/6bda8858-43c3-4560-9602-b074c0ce6845/16-9/{width}",
      hero: {
        mode: "standard",
        title: "Staged",
        metadata: "1h 45m   ",
        synopsis:
          "Cachu Hwch. David and Michael are persuaded to rehearse their postponed West End play online. Contains very strong language.",
        logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_bbcone/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/6bda8858-43c3-4560-9602-b074c0ce6845/background/{width}",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_bbcone/408/84",
    },
    {
      type: "landscapeTile",
      title: "Detectorists",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/67332250-9d25-4de0-9b7c-d49ec67ce241/16-9/{width}",
      hero: {
        mode: "standard",
        title: "Detectorists",
        metadata: "1h 45m   ",
        synopsis:
          "Episode 1. After a chance encounter, Lance and Andy journey towards the discovery of a lifetime. Contains some strong language.",
        logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_bbc/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/67332250-9d25-4de0-9b7c-d49ec67ce241/background/{width}",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_bbc/408/84",
    },
    {
      type: "landscapeTile",
      title: "Live from the BBC",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/cc1052bc-2d7c-4c86-b9a0-9de179ef6f93/16-9/{width}",
      hero: {
        mode: "standard",
        title: "Live from the BBC",
        metadata: "1h 45m   ",
        synopsis:
          "Mae Martin and Nish Kumar. Nish Kumar discusses the benefits of having an 'ethnically ambiguous' face. Contains some strong language.",
        logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_bbc/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/cc1052bc-2d7c-4c86-b9a0-9de179ef6f93/background/{width}",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_bbc/408/84",
    },
    {
      type: "landscapeTile",
      title: "QI",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/20769390-66f3-4878-8057-54e5e3ce4616/16-9/{width}",
      hero: {
        mode: "standard",
        title: "QI",
        metadata: "1h 45m   ",
        synopsis:
          "Rubbish. Sandi talks rubbish with Alan Davies, Bridget Christie, Johnny Vegas and Holly Walsh.",
        logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_bbctwo/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/20769390-66f3-4878-8057-54e5e3ce4616/background/{width}",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_bbctwo/408/84",
    },
  ],
} as Rail;
