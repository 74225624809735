import forEach from "lodash/forEach";
import Pusher, { Channel } from "pusher-js";
import { config } from "utils/config";

Pusher.logToConsole = config.pusher.logToConsole;

type Callback = (payload: any) => void;
type Callbacks = { [name: string]: Callback };

class PusherConnection {
  private _pusher: Pusher | null = null;
  private _channel: Channel | null = null;

  constructor(channelId: string, callbacks: Callbacks) {
    this._pusher = new Pusher(config.pusher.key, {
      cluster: "eu",
      forceTLS: true,
      authEndpoint: config.pusher.authApi,
    });
    this._channel = this._pusher.subscribe(channelId);
    forEach(callbacks, (callback, name) => this._channel.bind(name, callback));
  }

  public destroy() {
    if (this._channel) {
      this._channel.unbind_all();
      this._channel.unsubscribe();
      this._channel = null;
    }
    if (this._pusher) {
      this._pusher.disconnect();
      this._pusher = null;
    }
  }

  public get status() {
    return this._pusher.connection.state;
  }

  public trigger(eventSuffix: string, event: any) {
    if (this._channel) {
      this._channel.trigger(`client-${eventSuffix}`, event);
    }
  }
}

export default PusherConnection;
