import { Rail } from "types";

export default {
  type: "rail",
  uuid: "39e2808a-58b2-4b41-ace1-7862546220a2",
  template: "tile-cover",
  title: "Editorial",
  tiles: [
    {
      type: "coverTile",
      title: "Britain's Greatest Comedian",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/c7da9f8f-8f17-428a-a2d9-1d759836f020/cover/{height}",
      hero: {
        mode: "standard",
        title: "Britain's Greatest Comedian",
        metadata: "1h 45m   ",
        synopsis:
          "A jury of nine comedians, actors and writers try to establish the definitive answer.",
        logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_gold/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/c7da9f8f-8f17-428a-a2d9-1d759836f020/background/{width}",
      },
    },
    {
      type: "coverTile",
      title: "Wu-Tang Clan: Of Mics and Men",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/43198d42-f2e7-463c-a145-3d5256df6f61/cover/{height}",
      hero: {
        mode: "standard",
        title: "Wu-Tang Clan: Of Mics and Men",
        metadata: "1h 45m   ",
        synopsis:
          "Four-part docuseries exploring the story of Wu-Tang Clan to mark the 25th anniversary of the release of their debut album, with never-before-seen footage and insight from the surviving members.",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skydocumentaries/408/84",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/541ca50214ccbcc7cb6b709015682ace/hero.jpg",
        heroTitleImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/d43192f8ff01452e37951f4981545948/herotitle.png",
      },
    },
    {
      type: "coverTile",
      title: "Insecure",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/ca50b890-3b93-4d26-b04b-29da6e3bd619/cover/{height}",
      hero: {
        mode: "standard",
        title: "Insecure",
        metadata: "1h 45m   ",
        synopsis:
          "Created by and starring Issa Rae, Insecure looks at the friendship of two modern-day women, as well as all their uncomfortable experiences and racy tribulations. Season 4 added.",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycomedy/408/84",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/f69993093f61250572f502252c598656/hero.jpg",
        heroTitleImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/5f701419cb3456ad2519f17c77e103cb/herotitle.png",
      },
    },
    {
      type: "coverTile",
      title: "The Animated World of Halas...",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/67c5c854-9529-4a6c-92df-8e7b9439ddd1/cover/{height}",
      hero: {
        mode: "standard",
        title: "The Animated World of Halas...",
        metadata: "1h 45m   ",
        synopsis:
          "...and Batchelor. New. The story of a Jewish emigre from Hungary and a working-class woman from Watford, who created the first feature-length cartoon in British history, Animal Farm.",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skyarts/408/84",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/ce06d4f4786248727de5096e0c63cfa8/hero.jpg",
      },
    },
    {
      type: "coverTile",
      title: "Teen Mom UK",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/0b47adee-8d0c-40b7-9112-f73bdeed2183/cover/{height}",
      hero: {
        mode: "standard",
        title: "Teen Mom UK",
        metadata: "1h 45m   ",
        synopsis:
          "Hot on the heels of the US TV hit 'Teen Mom' comes the much anticipated Teen Mom UK, an access-all-areas documentary series following the highs, lows, lives and loves of five feisty Brit girls.\r",
        logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_mtv/408/84",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/2b44a65694f2f48a52d758ff24413d33/hero.jpg",
      },
    },
    {
      type: "coverTile",
      title: "Project Blue Book",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/da73f40d-b7be-4537-8928-3951202efa9b/cover/{height}",
      hero: {
        mode: "standard",
        title: "Project Blue Book",
        metadata: "1h 45m   ",
        synopsis:
          "In this conspiratorial Sci-Fi thriller set some time after the Second World War, a tough US Air Force officer and a scientist investigate alien conspiracies as Cold War paranoia spreads. Season 2 added.",
        logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_syfy/408/84",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/54e43bea4f59679927dba5840b41a6cf/hero.jpg",
        heroTitleImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/4942bb3521db70848e8afc68db545db1/herotitle.png",
      },
    },
    {
      type: "coverTile",
      title: "The Order",
      imageUrl:
        "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/c153e10b0674a10cd68387d09b031e45/{width}/{height}/item.jpg",
      hero: {
        mode: "standard",
        title: "The Order",
        metadata: "1h 45m   ",
        synopsis:
          "Out to avenge his mother's death, a college student pledges a secret order and lands in a war between werewolves and practitioners of dark magic.",
        logoUrl:
          "https://cdn.skyq.sky.com/uk/epg/live/media/logos/netflix/v1/logo_365x75.png",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/e47a33f73e9c29d0f28d573edf06357f/hero.jpg",
      },
    },
    {
      type: "titleTile",
      actionText: "View all",
      hero: {
        mode: "standard",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/e47a33f73e9c29d0f28d573edf06357f/hero.jpg",
      },
    },
  ],
} as Rail;
