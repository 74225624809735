import { Rail } from "types";
import { startOfHour, addHours } from "date-fns";

const date = Date.now(),
  past = startOfHour(date).getTime(),
  future = addHours(past, 1).getTime();

export default {
  type: "rail",
  uuid: "1b405dc5-2dd7-4868-b79a-cdec1b209fb1",
  template: "tile-now-next",
  title: "TV Guide",
  tiles: [
    {
      type: "titleTile",
      title: "TV Guide",
      hero: {
        mode: "standard",
        title: "TV Guide",
        synopsis: "Explore the shows you know and love",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/aa739175-7ae0-48ef-a6f0-7e5fe6d90acf/background/1428",
      },
      action: {
        type: "GO_TO_PAGE",
        pageType: "tvGuidePage",
        pageId: "tvGuide_tvShows",
      },
    },
    {
      type: "nowAndNextTile",
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_bbc1/408/84",
      nowTitle: "Line of Duty",
      nowStart: past,
      nextTitle: "Garden Rescue",
      nextStart: future,
      progressPercent: 23,
      hero: {
        mode: "standard",
        title: "Line of Duty",
        synopsis:
          "Episode 2. AC-12 crank up their investigation into Operation Trapdoor. Contains some upsetting scenes.",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/b7059eb2-baff-4b2d-83fc-ca879b7740fe/background/{width}",
        logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_bbc1/408/84",
      },
      action: {
        type: "JOURNEY.START",
        journeyId: "lineOfDuty",
      },
    },
    {
      type: "nowAndNextTile",
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_itv/408/84",
      nowTitle: "Emmerdale",
      nowStart: past,
      nextTitle: "Coronation Street",
      nextStart: future,
      progressPercent: 23,
      hero: {
        mode: "standard",
        logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_itv/408/84",
        title: "Emmerdale",
        synopsis:
          "There's an underlying tension between Paddy and Chas. Jacob suspects Eric has feelings for someone. David gets some good news....",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/aa739175-7ae0-48ef-a6f0-7e5fe6d90acf/background/1428",
        metadata: "1h 45m   ",
      },
    },
    {
      type: "nowAndNextTile",
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_channel4/408/84",
      nowTitle: "Channel 4 News",
      nowStart: past,
      nextTitle: "New: Location Location",
      nextStart: future,
      progressPercent: 23,
      hero: {
        mode: "standard",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_channel4/408/84",
        title: "Channel 4 News",
        synopsis:
          "Channel 4 News Special: Leading healthcare and policy experts discuss the latest coronavirus developments",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/3788a449-ef4b-4919-aa3e-5f7346a07580/16-9/1480",
        metadata: "1h 45m   ",
      },
    },
    {
      type: "nowAndNextTile",
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_skyatlantic/408/84",
      nowTitle: "Cobra",
      nowStart: past,
      nextTitle: "Blue Bloods",
      nextStart: future,
      progressPercent: 23,
      hero: {
        mode: "standard",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skyatlantic/408/84",
        title: "Cobra",
        synopsis:
          "As the nation slides into anarchy, Sutherland considers extreme measures to keep control. Rachel faces tough questions about Ellie.",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/23f7d238-5346-4add-974e-36761c57bb9f/background/1428",
        metadata: "1h 45m   ",
      },
    },
    {
      type: "titleTile",
      actionText: "View all",
      hero: {
        mode: "titleArt",
        title: "The One Show",
        synopsis:
          "Alex Jones and Patrick Kielty are joined by comedian Joel Dommett.",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/6ea0f270-f89a-4c0e-baff-26426f10540b/16-9/800",
        metadata: "1h 45m   ",
      },
      action: {
        type: "GO_TO_PAGE",
        pageType: "tvGuidePage",
        pageId: "tvGuide_tvShows",
      },
    },
  ],
} as Rail;
