import { Rail } from "types";

export default {
  type: "rail",
  uuid: "d66ef738-e870-48fa-b642-039b5be7d4c4",
  template: "tile-cover",
  title: "Rentals",
  tiles: [
    {
      type: "coverTile",
      title: "Terminator Salvation",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/52e3bcc7-f273-46c2-bbef-980a94b34598/cover/{height}",
      hero: {
        mode: "standard",
        title: "Terminator Salvation",
        metadata: "1h 45m    ",
        synopsis:
          "With the world in the grip of murderous machines, Christian Bale fights to save humanity in this killer retooling of the cyborg saga. Contains violence/flashing images. (2009)(111 mins)",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/52e3bcc7-f273-46c2-bbef-980a94b34598/background/{width}",
      },
    },
    {
      type: "coverTile",
      title: "Terminator Genisys",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/bf4e4420-cdf7-44b5-b12d-695702b3f3e2/cover/{height}",
      hero: {
        mode: "standard",
        title: "Terminator Genisys",
        metadata: "1h 45m    ",
        synopsis:
          "Arnold Schwarzenegger is back in this action-packed reboot of the sci-fi-series, co-starring Emilia Clarke. Strong language/violence/flashing images. (2015)(121 mins)",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/bf4e4420-cdf7-44b5-b12d-695702b3f3e2/background/{width}",
      },
    },
    {
      type: "coverTile",
      title: "Ocean's 8",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/a4b039b0-3867-4a3f-8f7d-4f71d3987387/cover/{height}",
      hero: {
        mode: "standard",
        title: "Ocean's 8",
        metadata: "1h 45m    ",
        synopsis:
          "After getting out of prison, Debbie Ocean plots to steal a priceless necklace from New York's Met Gala. Heist caper with Sandra Bullock. (2018)(106 mins) ",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/a4b039b0-3867-4a3f-8f7d-4f71d3987387/background/{width}",
      },
    },
    {
      type: "coverTile",
      title: "Will Smith: Reel Life",
      imageUrl:
        "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/bd8cad59caa878f92bfd7e20d02c7d66/{width}/{height}/item.jpg",
      hero: {
        mode: "standard",
        title: "Will Smith: Reel Life",
        metadata: "1h 45m    ",
        synopsis:
          "Alex Zane sits down with Will Smith to chat about his career, as well as his new action thriller Gemini Man, directed by Ang Lee.  ",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/336054ad1ffdc6977806e3b66578884c/hero.jpg",
      },
    },
    {
      type: "coverTile",
      title: "BlacKkKlansman",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/9728feaf-233c-4353-9e6c-988afcf6ae68/cover/{height}",
      hero: {
        mode: "standard",
        title: "BlacKkKlansman",
        metadata: "1h 45m    ",
        synopsis:
          "The incredible true story of an African-American cop who bravely infiltrated the Ku Klux Klan to take them down. Oscar winner from Spike Lee. Contains distressing scenes. (2018)(135 mins)",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/9728feaf-233c-4353-9e6c-988afcf6ae68/background/{width}",
      },
    },
    {
      type: "coverTile",
      title: "Tangled",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/a0c3d669-a41b-4fff-a5e4-fbb7fe9979b1/cover/{height}",
      hero: {
        mode: "standard",
        title: "Tangled",
        metadata: "1h 45m    ",
        synopsis:
          "In Disney's dazzling animated twist on Rapunzel, the princess with the magical long, blonde hair is imprisoned by a villainess. Can dashing rogue Flynn come to her rescue? (2010)(100 mins)",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/a0c3d669-a41b-4fff-a5e4-fbb7fe9979b1/background/{width}",
      },
    },
    {
      type: "coverTile",
      title: "The Pirates! In an Adventure with Scientists!",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/24859a07-faf9-40f6-83ec-ff8fe241f850/cover/{height}",
      hero: {
        mode: "standard",
        title: "The Pirates! In an Adventure with Scientists!",
        metadata: "1h 45m    ",
        synopsis:
          "An inept swashbuckler makes an attempt to win the Pirate of the Year Award. Stop-motion comedy with Hugh Grant. (2012)(85mins)",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/24859a07-faf9-40f6-83ec-ff8fe241f850/background/{width}",
      },
    },
    {
      type: "coverTile",
      title: "Frozen Fever",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/ebf87d11-7383-4b36-8d99-a832b2fa7a10/cover/{height}",
      hero: {
        mode: "standard",
        title: "Frozen Fever",
        metadata: "1h 45m    ",
        synopsis:
          "Animated short featuring the characters from Disney's Oscar-winning Frozen. With the voices of Kristen Bell, Idina Menzel and Josh Gad. (2015)(8 mins)",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/ebf87d11-7383-4b36-8d99-a832b2fa7a10/background/{width}",
      },
    },
    {
      type: "coverTile",
      title: "Mary and the Witch's Flower",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/d4ab829c-f763-42b2-b26f-356bcdc80b4c/cover/{height}",
      hero: {
        mode: "standard",
        title: "Mary and the Witch's Flower",
        metadata: "1h 45m    ",
        synopsis:
          "Fantasy animation about a young girl who stumbles upon a flower that grants magical powers, but only for one night. With the voices of Kate Winslet and Jim Broadbent. (2017)(99 mins)",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/d4ab829c-f763-42b2-b26f-356bcdc80b4c/background/{width}",
      },
    },
  ],
} as Rail;
