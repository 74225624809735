import { Rail } from "types";

export default {
  type: "rail",
  uuid: "88ae05a6-02b9-459c-a357-fd88c92f3131",
  template: "tile-cover",
  title: "Sky Cinema",
  brandId: "skycinema",
  tiles: [
    {
      type: "coverTile",
      title: "Spider-Man: Far from Home",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/9d4980c6-ed1c-4d40-9a26-59ef72b80ac6/cover/{height}",
      hero: {
        mode: "standard",
        title: "Spider-Man: Far from Home",
        synopsis:
          "Tom Holland stars as the web-slinging teenager, whose holiday is cut short when Europe comes under attack. Epic superhero sequel. (2019)(129 mins) Also in HD",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/9d4980c6-ed1c-4d40-9a26-59ef72b80ac6/background/1428",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
        metadata: "1h 45m   ",
      },
    },
    {
      type: "coverTile",
      title: "Superheroes",
      imageUrl:
        "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/b396c394d886516a0153c20c70e1c620/{width}/{height}/item.jpg",
      hero: {
        mode: "standard",
        title: "Superheroes",
        synopsis:
          "Our hugely popular superhero channel returns. Get fired up with more action from Aquaman, Captain Marvel, Captain America, Ant-Man and plenty of other gems to enjoy.",
        heroTitleImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/5f19efa6f59006578d145b29197b050b/herotitle.png",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/a240bdf6fa79e7b13c0aaf86c1365d29/hero.jpg",
        metadata: "1h 45m   ",
      },
    },
    {
      type: "coverTile",
      title: "Saving Private Ryan",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/89a529b2-76ae-4e72-a31b-709a06d70ba6/cover/{height}",
      hero: {
        mode: "standard",
        title: "Saving Private Ryan",
        synopsis:
          "Steven Spielberg's Oscar-winner stars Tom Hanks as the hardened veteran leading a platoon on a WWII rescue mission. (1998)(165 mins)",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/89a529b2-76ae-4e72-a31b-709a06d70ba6/background/1428",
        metadata: "1h 45m   ",
      },
    },
    {
      type: "coverTile",
      title: "Crazy, Stupid, Love",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/9724354a-f67b-4651-9cab-e5a94e40e226/cover/{height}",
      hero: {
        mode: "standard",
        title: "Crazy, Stupid, Love",
        synopsis:
          "A middle-aged man has a perfect life...until his wife divorces him and he's suddenly catapulted into the world of dating. Rom-com starring Steve Carell and Ryan Gosling. (2011)(114 mins)",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/fdf33789863bce24723fe57631f9b833/hero.jpg",
        metadata: "1h 45m   ",
      },
    },
    {
      type: "coverTile",
      title: "Disney Classics Reimagined",
      imageUrl:
        "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/1f970d5f796c99d30e60e3dc9fd70b1c/{width}/{height}/item.jpg",
      hero: {
        mode: "standard",
        title: "Disney Classics Reimagined",
        synopsis:
          "Enjoy a host of much-loved Disney films, reimagined and reworked, with actors such as Will Smith, Colin Farrell and Angelina Jolie bringing your favourite characters back to life.",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/d8940e005224d1fee0698c81a17e8a78/hero.jpg",
        metadata: "1h 45m   ",
      },
    },
    {
      type: "coverTile",
      title: "Super 8",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/ba08dc87-258e-4623-92eb-a77511ae4783/cover/{height}",
      hero: {
        mode: "standard",
        title: "Super 8",
        synopsis:
          "Six youngsters witness a train crash, but discover they've caught something far more frightening on camera. (2011)(108 mins)",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/ba08dc87-258e-4623-92eb-a77511ae4783/background/1428",
        metadata: "1h 45m   ",
      },
    },
    {
      type: "coverTile",
      title: "Top Ten: 1997",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/58851901-53af-4478-8689-f46781a0bedf/cover/{height}",
      hero: {
        mode: "standard",
        title: "Top Ten: 1997",
        synopsis:
          "Relax and reminisce as Sky Cinema counts down the top ten grossing box office hits from the year of 1997.",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/58851901-53af-4478-8689-f46781a0bedf/background/1428",
        metadata: "1h 45m   ",
      },
    },
    {
      type: "coverTile",
      title: "Titanic",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/9bb2f05b-a40b-4923-990f-5daedf1c6700/cover/{height}",
      hero: {
        mode: "standard",
        title: "Titanic",
        synopsis:
          "Leonardo DiCaprio and Kate Winslet are the lovers aboard the doomed ocean liner in James Cameron's Oscar-winning romantic blockbuster. (1997)(187 mins)",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/9bb2f05b-a40b-4923-990f-5daedf1c6700/background/1428",
        metadata: "1h 45m   ",
      },
    },
    {
      type: "coverTile",
      title: "Men in Black",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/7228369e-ce07-49ab-b85f-5bcddaf513a0/cover/{height}",
      hero: {
        mode: "standard",
        title: "Men in Black",
        synopsis:
          "Will Smith joins Tommy Lee Jones for a sci-fi comedy about a pair of secret agents who set out to stop an alien terrorist who is threatening the planet. (1997)(94 mins)",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/4d13ecb69e218eed6d161b0ba7319096/hero.jpg",
        metadata: "1h 45m   ",
      },
    },
  ],
} as Rail;
