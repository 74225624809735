import pick from "lodash/pick";
import { dispatch, sagaMiddleware, store } from "store";
import { rootSaga } from "sagas";
import { BrowserVoiceListener } from "services/browserVoiceListener";
import { config } from "utils/config";
import { onStoreChange } from "utils/onStoreChange";
import { initialSettingsState } from "reducers/settings";
import { localStore } from "utils/localStore";
import { KeyListener } from "services/keyListener";
import { UrlParser } from "services/UrlParser";
import { keyListener } from "keyListener";
import DoorbellService from "./services/DoorbellService";
import RemoteControlService from "./services/RemoteControlService";

export const initializeApp = () => {
  initSagas();
  initBrowserVoiceListener();
  initSettings();
  initKeyListener();
  initFromRoute();
  initDoorbell();
  preventArrowScroll();
  return function cleanup() {};
};

const initSagas = () => {
  sagaMiddleware.run(rootSaga);
};

const initBrowserVoiceListener = () => {
  if (config.dialogFlow.enabled) {
    try {
      const listener = new BrowserVoiceListener({
        goHome: ({ asr }) => dispatch({ type: "VOICE.GO_HOME", asr }),
        goBack: ({ asr }) => dispatch({ type: "VOICE.GO_BACK", asr }),
        goToPage: ({ asr, pageId }) =>
          dispatch({ type: "VOICE.GO_TO_PAGE", asr, pageId }),
        mute: ({ asr }) => dispatch({ type: "VOICE.MUTE", asr }),
        notRecognised: ({ asr }) =>
          dispatch({ type: "VOICE.NOT_RECOGNISED", asr }),
        play: ({ asr, contentId }) =>
          dispatch({ type: "VOICE.PLAY", asr, contentId }),
        unmute: ({ asr }) => dispatch({ type: "VOICE.UNMUTE", asr }),
        volumeChange: ({ asr, value }) =>
          dispatch({ type: "VOICE.VOLUME_CHANGE", asr, value }),
        volumeSet: ({ asr, value }) =>
          dispatch({ type: "VOICE.VOLUME_SET", asr, value }),
        wakeWord: () => dispatch({ type: "VOICE.WAKE" }),
      });
      onStoreChange(
        s => s.toast.status === "listening",
        isListening =>
          isListening ? listener.commandMode() : listener.wakewordMode()
      );
    } catch (error) {
      alert(error);
    }
  }
};

const initSettings = () => {
  const settings = pick(localStore.settings, Object.keys(initialSettingsState));
  dispatch({ type: "SETTINGS.UPDATE", settings });
};

const initKeyListener = () => {
  subscribeToKeyListener(keyListener);
  const [down, up] = RemoteControlService.instance.upAndDownStreams;
  subscribeToKeyListener(new KeyListener({ keyDown$: down, keyUp$: up }));
};

const subscribeToKeyListener = (keyListener: KeyListener) => {
  return keyListener.subscribe(({ button, pressType }) => {
    if (process.env.NODE_ENV === "development") {
      console.log("KEYPRESS", button, pressType);
    }

    // Disable if settings is open
    if (store.getState().app.settingsOpen) return;
    if (pressType === "SHORT") {
      // Short presses
      switch (button) {
        case "LEFT":
        case "RIGHT":
        case "UP":
        case "DOWN":
        case "SELECT":
        case "HOME":
        case "BACK":
        case "ADD":
          dispatch({ type: "NAVIGATE", step: button });
          break;
        case "VOLUME_UP":
          dispatch({ type: "CHANGE_VOLUME", value: 1 });
          break;
        case "VOLUME_DOWN":
          dispatch({ type: "CHANGE_VOLUME", value: -1 });
          break;
        case "SETTINGS":
          dispatch({ type: "SETTINGS.TOGGLE" });
          break;
        case "MENU":
          dispatch({ type: "QUICK_ACCESS_MENU.TOGGLE" });
          break;
        case "POWER":
          dispatch({ type: "STANDBY.TOGGLE" });
          break;
        case "TOGGLE_PAUSE":
          dispatch({ type: "TOGGLE_PAUSE" });
          break;
        case "0":
        case "1":
        case "2":
        case "3":
        case "4":
        case "5":
        case "6":
        case "7":
        case "8":
        case "9":
          dispatch({ type: "SHORTCUT", key: button });
          break;
      }
    } else {
      // Long presses
      switch (button) {
        case "LEFT":
          dispatch({ type: "NAVIGATE", step: "LONG_LEFT" });
          break;
        case "RIGHT":
          dispatch({ type: "NAVIGATE", step: "LONG_RIGHT" });
          break;
        case "UP":
          dispatch({ type: "NAVIGATE", step: "LONG_UP" });
          break;
        case "DOWN":
          dispatch({ type: "NAVIGATE", step: "LONG_DOWN" });
          break;
      }
    }
  });
};

const initFromRoute = () => {
  const parser = new UrlParser({
    "page/:pageId": ({ pageId }) =>
      dispatch({ type: "ENTRYPOINT.START_ON_RAIL", pageId, railIndex: 0 }),
    "page/:pageId/rail/:railIndex": ({ pageId, railIndex }) =>
      dispatch({
        type: "ENTRYPOINT.START_ON_RAIL",
        pageId,
        railIndex: parseInt(railIndex),
      }),
    "autohide/:on": ({ on }) =>
      dispatch({
        type: "ENTRYPOINT.AUTOHIDE_TV_CATEGORIES",
        on: !!parseInt(on),
      }),
  });
  parser.parse(window.location.hash);
};

const initDoorbell = () => {
  DoorbellService.instance.doorbellRingCallback = () => {
    console.log("DOOR BELL RING BEING HANDLED");
    dispatch({ type: "DOORBELL.RING" });
  };
};

const preventArrowScroll = () => {
  document.addEventListener("keydown", event => {
    if (event.key.match(/^Arrow/)) {
      event.preventDefault();
    }
  });
};
