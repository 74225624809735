import { Rail } from "types";

export default {
  type: "rail",
  uuid: "54ae7392-2ade-41d4-a861-95d267f06207",
  template: "tile-landscape",
  title: "Saturday",
  tiles: [
    {
      type: "landscapeTile",
      title: "France '98 - The Three Musketeers",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/54b7bb94-cfd7-4b8f-bb01-dc6ffbc4f0b3/16-9/{width}",
      hero: {
        mode: "standard",
        title: "France '98 - The Three Musketeers",
        metadata: "1h 45m   ",
        synopsis:
          "The contrasting fortunes of David Beckham, Zinedine Zidane and Ronaldo at France '98.",
        logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_bbcone/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/54b7bb94-cfd7-4b8f-bb01-dc6ffbc4f0b3/background/{width}",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_bbcone/408/84",
    },
    {
      type: "landscapeTile",
      title: "Peter Crouch: Save Our Summer",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/63cf08b1-39e2-4445-b6f8-5a099331d3ed/16-9/{width}",
      hero: {
        mode: "standard",
        title: "Peter Crouch: Save Our Summer",
        metadata: "1h 45m   ",
        synopsis:
          "Episode 3. Abbey Clancy drops in for an extra special edition of Peter Crouch's Extreme Retirement. Contains some strong language.",
        logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_bbcone/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/63cf08b1-39e2-4445-b6f8-5a099331d3ed/background/{width}",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_bbcone/408/84",
    },
    {
      type: "landscapeTile",
      title: "Escape to the Country",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/ba52b208-dcd3-400c-a463-5f100ada79b2/16-9/{width}",
      hero: {
        mode: "standard",
        title: "Escape to the Country",
        metadata: "1h 45m   ",
        synopsis:
          "Dorset. Nicki Chapman helps a couple who have £400,000 to fulfil their dream of moving to Dorset.",
        logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_bbcone/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/ba52b208-dcd3-400c-a463-5f100ada79b2/background/{width}",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_bbcone/408/84",
    },
    {
      type: "landscapeTile",
      title: "Euros Rewind",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/1465a9f7-1376-4769-a1d7-5c3f58798e0c/16-9/{width}",
      hero: {
        mode: "standard",
        title: "Euros Rewind",
        metadata: "1h 45m   ",
        synopsis:
          "Portugal v England 2004. Relive England's game against a talented Portugal team in the quarter-finals of Euro 2004.",
        logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_bbcone/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/1465a9f7-1376-4769-a1d7-5c3f58798e0c/background/{width}",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_bbcone/408/84",
    },
    {
      type: "landscapeTile",
      title: "The Great Food Guys",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/516a6cbf-f718-49f7-8d9b-905b6ce08078/16-9/{width}",
      hero: {
        mode: "standard",
        title: "The Great Food Guys",
        metadata: "1h 45m   ",
        synopsis: "Beef. Nick Nairn and Dougie Vipond focus on Scottish beef.",
        logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_bbcone/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/516a6cbf-f718-49f7-8d9b-905b6ce08078/background/{width}",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_bbcone/408/84",
    },
    {
      type: "landscapeTile",
      title: "Saturday Kitchen",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/545c333e-5fae-48f7-a583-564791b71c18/16-9/{width}",
      hero: {
        mode: "standard",
        title: "Saturday Kitchen",
        metadata: "1h 45m   ",
        synopsis:
          "20/06/2020. Matt Tebbutt and chef Ching He Huang are joined by Jack Whitehall via video link.",
        logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_bbcone/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/545c333e-5fae-48f7-a583-564791b71c18/background/{width}",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_bbcone/408/84",
    },
    {
      type: "landscapeTile",
      title: "Weather for the Week Ahead",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/6bccc454-bbed-44f3-9efa-20b0681a9264/16-9/{width}",
      hero: {
        mode: "standard",
        title: "Weather for the Week Ahead",
        metadata: "1h 45m   ",
        synopsis: "20/06/2020. Detailed weather forecast.",
        logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_bbcone/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/6bccc454-bbed-44f3-9efa-20b0681a9264/background/{width}",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_bbcone/408/84",
    },
    {
      type: "landscapeTile",
      title: "Weather for the Week Ahead",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/6bccc454-bbed-44f3-9efa-20b0681a9264/16-9/{width}",
      hero: {
        mode: "standard",
        title: "Weather for the Week Ahead",
        metadata: "1h 45m   ",
        synopsis: "20/06/2020. Detailed weather forecast.",
        logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_bbcone/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/6bccc454-bbed-44f3-9efa-20b0681a9264/background/{width}",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_bbcone/408/84",
    },
  ],
} as Rail;
