const SpeechRec =
  (window as any).SpeechRecognition || (window as any).webkitSpeechRecognition;

export class SpeechRecognizer {
  private speechRec: typeof SpeechRec;

  constructor() {
    if (!SpeechRec) {
      throw new Error(
        "Can't initiate speech recognition as this browser doesn't support it"
      );
    }
    const speechRec = new SpeechRec();
    speechRec.continuous = true;
    speechRec.lang = "en-GB";
    speechRec.onend = () => speechRec.start();
    speechRec.start();
    this.speechRec = speechRec;
  }

  onSpeech(callback: (phrase: string) => void) {
    this.speechRec.onresult = ({ results }) => {
      const latestResult = results[results.length - 1];
      callback(latestResult[0].transcript);
    };
  }
}
