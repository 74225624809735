type Dims = {
  x: number;
  y: number;
  width: number;
};

// Scale it from top-left
export function applyScaleTransform({ x, y, width }: Dims, scale: number) {
  return { x: x * scale, y: y * scale, width: width * scale };
}

export function paramsForTransform(srcDims: Dims, destDims: Dims) {
  return {
    x: destDims.x - srcDims.x,
    y: destDims.y - srcDims.y,
    scale: destDims.width / srcDims.width,
  };
}
