import { RailsPage } from "types";
import forTheLittleOnes from "./forTheLittleOnes";
import favouriteFriends from "./favouriteFriends";
import movies from "./movies";
import lol from "./lol";
import learningAtHome from "./learningAtHome";
import singDance from "./singDance";
import weLoveBooks from "./weLoveBooks";
import utils from "../sharedRails/utils";

export default {
  type: "railsPage",
  rails: [
    forTheLittleOnes,
    favouriteFriends,
    movies,
    learningAtHome,
    lol,
    singDance,
    weLoveBooks,
    utils,
  ],
  title: {
    text: "Kids",
  },
} as RailsPage;
