import { observable } from "mobx";
import { Toggler } from "./Toggler";

export const stores = {
  audioDescription: new Toggler(),
  frontDoorLock: new Toggler(true),
  appScale: observable.box(1),
} as const;

type Stores = typeof stores;
type StoreIdAll = keyof Stores;

// Usage:
// StoreId<Toggler> will give only store ids that point to a Toggler store
// in the lookup above
export type StoreId<StoreType = any> = {
  [Key in StoreIdAll]: Stores[Key] extends StoreType ? Key : never;
}[StoreIdAll];
