import { Rail } from "types";

export default {
  type: "rail",
  uuid: "6787bdd9-9fd9-4c89-b87a-a6e7f02256fd",
  template: "tile-cover",
  title: "Actors",
  tiles: [
    {
      type: "coverTile",
      title: "Cameron Diaz",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/96152d1e-bb57-4734-a8f7-c00b4a3c89f1/cover/{height}",
      hero: {
        mode: "standard",
        title: "Cameron Diaz",
        metadata: "1h 45m   ",
        synopsis:
          "With a possible comeback on the cards, now is the time to remind yourself why we all came to love her movies and that bright, wide smile. ",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/96152d1e-bb57-4734-a8f7-c00b4a3c89f1/background/{width}",
      },
    },
    {
      type: "coverTile",
      title: "Morgan Freeman",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/1e32f3df-ccdc-40a6-a44a-7c6ecfaf0eab/cover/{height}",
      hero: {
        mode: "standard",
        title: "Morgan Freeman",
        metadata: "1h 45m   ",
        synopsis:
          "Enjoy this top-class collection of films featuring the Academy Award-winning star Morgan Freeman.",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/1e32f3df-ccdc-40a6-a44a-7c6ecfaf0eab/background/{width}",
      },
    },
    {
      type: "coverTile",
      title: "Adam Sandler",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/cff1f1d4-be05-4719-bea1-8bf93f03d729/cover/{height}",
      hero: {
        mode: "standard",
        title: "Adam Sandler",
        metadata: "1h 45m   ",
        synopsis: "Adam Sandler, master of the movie man-child.",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/cff1f1d4-be05-4719-bea1-8bf93f03d729/background/{width}",
      },
    },
    {
      type: "coverTile",
      title: "Sandra Bullock",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/32cd6c14-d311-4233-a3e5-f93063d36f43/cover/{height}",
      hero: {
        mode: "standard",
        title: "Sandra Bullock",
        metadata: "1h 45m   ",
        synopsis:
          "Never mind the... other actors, here's a collection of our favourite Sandra Bullock movies.",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/32cd6c14-d311-4233-a3e5-f93063d36f43/background/{width}",
      },
    },
    {
      type: "coverTile",
      title: "Salma Hayek",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/3e543563-1c0f-41b8-a379-03f3b51e1ddf/cover/{height}",
      hero: {
        mode: "standard",
        title: "Salma Hayek",
        metadata: "1h 45m   ",
        synopsis:
          "A selection of fabulous films featuring the Oscar-nominated Salma Hayek - from comedy gold to animated hits and beyond.",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/3e543563-1c0f-41b8-a379-03f3b51e1ddf/background/{width}",
      },
    },
    {
      type: "coverTile",
      title: "Dwayne Johnson",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/b8a4ccfb-6c86-4901-bf73-87e05c43665c/cover/{height}",
      hero: {
        mode: "standard",
        title: "Dwayne Johnson",
        metadata: "1h 45m   ",
        synopsis:
          "Can you smell what The Rock is cooking? To us it has the scent of a great movie collection.",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/b8a4ccfb-6c86-4901-bf73-87e05c43665c/background/{width}",
      },
    },
    {
      type: "coverTile",
      title: "Tom Cruise",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/1b8a6037-8bc3-4b17-bce9-5ea48a3b2f92/cover/{height}",
      hero: {
        mode: "standard",
        title: "Tom Cruise",
        metadata: "1h 45m   ",
        synopsis:
          "Sit back, relax and put your mind into Cruise control as we bring you a collection from Hollywood A-lister Tom.",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/1b8a6037-8bc3-4b17-bce9-5ea48a3b2f92/background/{width}",
      },
    },
    {
      type: "coverTile",
      title: "Matt Damon",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/5dde8576-9533-4d50-a061-08a3dea9bf49/cover/{height}",
      hero: {
        mode: "standard",
        title: "Matt Damon",
        metadata: "1h 45m   ",
        synopsis: "Matt Damon!",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/5dde8576-9533-4d50-a061-08a3dea9bf49/background/{width}",
      },
    },
    {
      type: "coverTile",
      title: "Cate Blanchett",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/10817d36-44ba-433e-a7f4-f02564d6b33d/cover/{height}",
      hero: {
        mode: "standard",
        title: "Cate Blanchett",
        metadata: "1h 45m   ",
        synopsis:
          "Sit back and enjoy this cracking collection of films starring double Oscar winner Cate Blanchett ",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/10817d36-44ba-433e-a7f4-f02564d6b33d/background/{width}",
      },
    },
    {
      type: "coverTile",
      title: "Denzel Washington",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/b9e42ea4-464b-4763-af2a-96052a045cbf/cover/{height}",
      hero: {
        mode: "standard",
        title: "Denzel Washington",
        metadata: "1h 45m   ",
        synopsis:
          "Iconic performances from Oscar winning screen legend Denzel Washington. ",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/b9e42ea4-464b-4763-af2a-96052a045cbf/background/{width}",
      },
    },
  ],
} as Rail;
