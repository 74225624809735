import React, { useRef } from "react";
import { Schedule, TvGuidePage as TvGuidePageType } from "types";
import {
  Container,
  Header,
  HeaderRowContainer,
  HeaderRow,
  ChannelNames,
  Grid,
  Row,
  EpgContainer,
  EpgMask,
  RailContainer,
  ScrollContainerWrapper,
  ScrollContainer,
} from "./TVGuidePage.styles";
import { GridItem } from "./GridItem";
import { Text } from "../../Text";
import { tvGuide } from "constants/dimensions";
import {
  durationToWidth,
  placeholderChannels,
  placeholderRail,
  slotNumToX,
  timeSlots,
  timeToX,
} from "helpers/tvGuideHelpers";
import { Rail } from "../../Rail";
import { FocusArea } from "reducers/browse/pages/tvGuide";
import { formatDay } from "utils/timeHelpers";
import { useDefaultAnimation } from "hooks/useDefaultAnimation";
import { boolToInt } from "utils/boolToInt";
import { useAnimations } from "hooks/useAnimations";
import gsap from "gsap";
import { startOfDay } from "date-fns/esm";

const MIN_VISIBLE_CHANNELS = 6;

type Props = {
  tileIndex: number;
  channelIndex: number;
  eventIndex: number;
  slotIndex: number;
  midnight?: Date;
  now: Date;
  schedule?: Schedule;
  focusArea: FocusArea;
  showRail: boolean;
  page: TvGuidePageType;
  loading: boolean;
  visible: boolean;
  focused: boolean;
};

export const TvGuidePage = ({
  tileIndex,
  channelIndex,
  eventIndex,
  schedule,
  midnight,
  now,
  slotIndex,
  focusArea,
  showRail,
  page,
  loading,
  visible,
  focused,
}: Props) => {
  midnight = midnight || startOfDay(now);

  let channels, rail, focusedChannel, focusedEvent, slotX;
  if (!schedule) {
    channels = placeholderChannels(midnight);
    rail = placeholderRail();
    slotX = 0;
  } else {
    channels = schedule.channels;
    rail = page.rail;
    focusedChannel = channels[channelIndex]?.channel;
    focusedEvent = channels[channelIndex]?.events[eventIndex];
    slotX = slotNumToX(slotIndex);
  }

  const firstVisibleIndex = Math.min(
      channelIndex,
      channels.length - MIN_VISIBLE_CHANNELS
    ),
    focusedOnRail = focused && focusArea === "rail",
    focusedOnChannels = focused && focusArea === "channels",
    focusedOnGrid = focused && focusArea === "grid",
    focusedOnEPG = focusedOnChannels || focusedOnGrid,
    channelIsVisible = (index: number) =>
      firstVisibleIndex - 1 <= index &&
      index < firstVisibleIndex + MIN_VISIBLE_CHANNELS + 2;

  const containerRef = useRef(null),
    railRef = useRef(null),
    headerRowRef = useRef(null),
    scrollNamesRef = useRef(null),
    channelRowRef = useRef(null),
    prevChannelRowRef = useRef(null),
    scrollGridRef = useRef(null),
    rowRef = useRef(null),
    prevRowRef = useRef(null);

  // Animate container
  useDefaultAnimation(
    containerRef,
    {
      top: showRail ? tvGuide.yLines.withRail : tvGuide.yLines.withoutRail,
      opacity: boolToInt(visible),
    },
    { ease: "power2.out" }
  );

  // Animate rail container
  useDefaultAnimation(
    railRef,
    {
      opacity: boolToInt(showRail),
      height: showRail ? tvGuide.railContainer.height : 0,
      marginBottom: showRail ? tvGuide.railContainer.marginBottom : 0,
    },
    { ease: "power2.out" }
  );

  // Animate vertical scrolling of EPG
  useAnimations(
    {
      animations: {
        tvGuideScrollDown: ({ y }, timeline) =>
          timeline
            .to(scrollNamesRef.current, { y, ease: "power2.out" }, 0)
            .to(scrollGridRef.current, { y, ease: "power2.out" }, 0),
        tvGuideScrollUp: ({ y }, timeline) =>
          timeline
            .set(prevChannelRowRef.current, { opacity: 0 })
            .set(prevRowRef.current, { opacity: 0 })
            .set(channelRowRef.current, { opacity: 1 })
            .set(rowRef.current, { opacity: 1 })
            .to(scrollNamesRef.current, { y, ease: "power2.out" }, 0)
            .to(scrollGridRef.current, { y, ease: "power2.out" }, 0),
      },
      reset: ({ y }) => {
        gsap.set(scrollNamesRef.current, { y });
        gsap.set(scrollGridRef.current, { y });
        gsap.set(prevChannelRowRef.current, { opacity: 0 });
        gsap.set(prevRowRef.current, { opacity: 0 });
        gsap.set(channelRowRef.current, { opacity: 1 });
        gsap.set(rowRef.current, { opacity: 1 });
      },
    },
    {
      y:
        -firstVisibleIndex * tvGuide.item.outerHeight +
        tvGuide.overflowAllowanceForFocusState.y,
    }
  );

  // Animate horizontal scrolling of EPG
  useAnimations(
    {
      animations: {
        tvGuideScrollHorizontally: ({ x }, timeline) =>
          timeline
            .to(headerRowRef.current, { x, ease: "power2.out" }, 0)
            .to(scrollGridRef.current, { x, ease: "power2.out" }, 0),
      },
      reset: ({ x }) => {
        gsap.set(headerRowRef.current, { x });
        gsap.set(scrollGridRef.current, { x });
      },
    },
    { x: -slotX }
  );

  return (
    <Container ref={containerRef}>
      <RailContainer ref={railRef}>
        <Rail
          rail={rail}
          focusedTileIndex={tileIndex}
          focused={focusedOnRail}
        />
      </RailContainer>
      <EpgContainer>
        <EpgMask />
        <ChannelNames>
          <Header width={tvGuide.channelNames.width}>
            <Text size="small" weight="medium">
              {formatDay(midnight, now)}
            </Text>
          </Header>
          <ScrollContainerWrapper>
            <ScrollContainer ref={scrollNamesRef}>
              {channels.map(({ channel }, index) => (
                <Row
                  key={channel.id}
                  ref={
                    index === firstVisibleIndex - 1
                      ? prevChannelRowRef
                      : index === firstVisibleIndex
                      ? channelRowRef
                      : null
                  }
                >
                  {channelIsVisible(index) && (
                    <GridItem
                      title={channel.title}
                      channelNumber={channel.channelNumber}
                      backgroundStyle={
                        focusedOnEPG && channel.id === focusedChannel?.id
                          ? "verylight"
                          : "light"
                      }
                      width={tvGuide.channelNames.width}
                      focused={
                        focusedOnChannels && channel.id === focusedChannel?.id
                      }
                    />
                  )}
                </Row>
              ))}
            </ScrollContainer>
          </ScrollContainerWrapper>
        </ChannelNames>
        <Grid>
          <HeaderRowContainer>
            <HeaderRow ref={headerRowRef}>
              {timeSlots(slotIndex).map(({ index, title }) => (
                <Text
                  key={title}
                  style={{
                    position: "absolute",
                    left: tvGuide.grid.slotWidth * index,
                  }}
                  size="small"
                  weight="medium"
                >
                  {title}
                </Text>
              ))}
            </HeaderRow>
          </HeaderRowContainer>
          <ScrollContainerWrapper>
            <ScrollContainer ref={scrollGridRef}>
              {(loading ? placeholderChannels(midnight) : channels).map(
                ({ channel, events }, j) => (
                  <Row
                    key={channel.id}
                    ref={
                      j === firstVisibleIndex - 1
                        ? prevRowRef
                        : j === firstVisibleIndex
                        ? rowRef
                        : null
                    }
                  >
                    {channelIsVisible(j) &&
                      events.map(({ title, startMs, endMs }) => {
                        const startX = timeToX(startMs, midnight),
                          endX = startX + durationToWidth(endMs - startMs),
                          x = Math.max(startX, slotX),
                          width = Math.max(endX - x, 0);
                        return (
                          slotX - tvGuide.grid.slotWidth * 3 < endX &&
                          startX <= slotX + tvGuide.grid.width && (
                            <GridItem
                              key={startMs}
                              title={title}
                              width={width}
                              visible={width > 0}
                              x={x}
                              focused={
                                focusedOnGrid &&
                                channel.id === focusedChannel?.id &&
                                startMs === focusedEvent?.startMs
                              }
                              backgroundStyle={
                                focusedOnEPG &&
                                channel.id === focusedChannel?.id
                                  ? "verylight"
                                  : undefined
                              }
                            />
                          )
                        );
                      })}
                  </Row>
                )
              )}
            </ScrollContainer>
          </ScrollContainerWrapper>
        </Grid>
      </EpgContainer>
    </Container>
  );
};
