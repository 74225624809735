import { RecentTile } from "types";

export const initialRecentTiles: RecentTile[] = [
  {
    type: "recentTile",
    tag: "Free Preview",
    title: "Captain Marvel",
    imageUrl: "assets/images/switcher/CaptainMarvel/tile.webp",
    providerImageUrl:
      "https://images.metadata.sky.com/pd-logo/skychb_skystore/408/84",
    hero: {
      title: "Captain Marvel",
      synopsis:
        "Oscar winner Brie Larson is Captain Marvel, one of the great heroes of the universe, caught up in an intergalactic struggle between alien worlds. With Samuel L Jackson.",
      heroTitleImageUrl: "assets/images/switcher/CaptainMarvel/heroTitle.webp",
      heroImageUrl: "assets/images/switcher/CaptainMarvel/hero.webp",
      logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_skystore/408/84",
    },
  },
  {
    type: "recentTile",
    tag: "Liverpool vs Arsenal",
    title: "Carabao Cup",
    imageUrl: "assets/images/switcher/CarabaoCup/tile.webp",
    providerImageUrl:
      "https://images.metadata.sky.com/pd-logo/skychb_skyspfootball/408/84",
    hero: {
      title: "Carabao Cup",
      synopsis:
        "The Gunners take on Liverpool at Anfield in the round of 16 in the Carabao Cup.",
      heroTitleImageUrl: "assets/images/switcher/CarabaoCup/heroTitle.webp",
      heroImageUrl: "assets/images/switcher/CarabaoCup/hero.webp",
      logoUrl: "",
    },
  },
  {
    type: "recentTile",
    tag: "Recent App",
    //title: "BBC iPlayer",
    imageUrl:
      "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/a328f94b6e40839c78541072d475ac70/900/507/item.png",
    hero: {
      mode: "standard",
      title: "BBC iPlayer",
      synopsis:
        "Enjoy watching live or on demand TV, from live news coverage, music and big sport events to great comedies, gripping documentaries and nail-biting dramas.",
      heroImageUrl:
        "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/0c40fd3c11fa17049dd789002a891d29/hero.jpg",
    },
  },
  {
    type: "recentTile",
    tag: "Recent HDMI",
    title: "",
    imageUrl: "assets/images/switcher/XBoxAlt/tile.webp",
    providerImageUrl: "",
    hero: {
      title: "XBox",
      synopsis: "Games play better on Xbox One X",
      heroTitleImageUrl: "assets/images/switcher/Xbox/herotitle.png",
      heroImageUrl: "assets/images/switcher/XBoxAlt/hero.webp",
      logoUrl: "",
    },
  },
];
