import styled from "@emotion/styled";
import { colours } from "constants/type";
import { screen } from "constants/dimensions";

export const Container = styled.div``;

export const TopRightContainer = styled.div({
  position: "absolute",
  top: 70,
  right: 96,
  height: 51,
  border: `1px solid ${colours.white}`,
  borderRadius: "9px",
  backgroundColor: "rgba(0,27,67,0.05)",
  display: "flex",
  alignItems: "center",
  padding: "0 18px",
});

// Assumes a standard sky logo of size 365x75 (logo is actually bottom-left of that)
export const Logo = styled.img({
  position: "absolute",
  top: 26,
  left: screen.width - 200,
});
