import React from "react";
import { Text } from "components/Text";
import { Container, TopRightContainer, Logo } from "./PageTitle.styles";
import { PageTitle as PageTitleType } from "types";
import { Fade } from "components/Fade";

type Props = {
  pageTitle?: PageTitleType;
  position: "top-right";
};

export const PageTitle = ({ pageTitle, position }: Props) => {
  const logoUrl = pageTitle?.logoUrl,
    text = !logoUrl && pageTitle?.text;

  return (
    <Fade
      keyBy={text || logoUrl}
      props={{ text, logoUrl, position }}
      render={({ text, logoUrl, position }) => (
        <Container>
          {text && position === "top-right" && (
            <TopRightContainer>
              <Text size="small" weight="regular">
                {text}
              </Text>
            </TopRightContainer>
          )}
          {logoUrl && <Logo src={logoUrl} />}
        </Container>
      )}
    />
  );
};
