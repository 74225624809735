import { Rail } from "types";

export default {
  type: "rail",
  uuid: "67d5d3a5-d448-4423-9e1a-cfe7883b8116",
  template: "tile-cover",
  title: "New Premieres",
  tiles: [
    {
      type: "coverTile",
      title: "Upgrade To Sky Cinema",
      imageUrl:
        "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/a8f84f4c688acac4a8c86c08ecd2f98a/{width}/{height}/item.jpg",
      hero: {
        mode: "standard",
        title: "Upgrade To Sky Cinema",
        synopsis:
          "Get Sky Cinema and discover a new premiere every day, plus over 1000 movies on demand. Click here to find out more.",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/231e967ba7e1bfdd8520e1f1d00109e7/hero.jpg",
        metadata: "1h 45m   ",
      },
    },
    {
      type: "coverTile",
      title: "Maleficent: Mistress of Evil",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/cac2a705-350d-46f4-9520-098296354316/cover/{height}",
      hero: {
        mode: "standard",
        title: "Maleficent: Mistress of Evil",
        synopsis:
          "Loyalties are tested as Maleficent embarks on an epic battle against Aurora's future mother-in-law. Fantasy sequel starring Angelina Jolie. (2019)(118 mins) ",
        heroTitleImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/d901773534ee31206fe1ace9c91d21cf/herotitle.png",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/8fc1e8f5caaec1aac7f3cfc611e38051/hero.jpg",
        metadata: "1h 45m   ",
      },
    },
    {
      type: "coverTile",
      title: "Fast & Furious Presents: Hobbs & Shaw",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/7cd0fcec-0f5a-4a8a-963d-4bd862f758f5/cover/{height}",
      hero: {
        mode: "standard",
        title: "Fast & Furious Presents: Hobbs & Shaw",
        synopsis:
          "Spin-off from the iconic franchise. Luke Hobbs (Dwayne Johnson) and Deckard Shaw (Jason Statham) must put their differences aside and team up to stop a huge terror threat. (2019)(137 mins)",
        heroTitleImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/11d8fcba1c32a4f5af195eced1e8c82e/herotitle.png",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/7cd0fcec-0f5a-4a8a-963d-4bd862f758f5/background/1428",
        metadata: "1h 45m   ",
      },
    },
    {
      type: "coverTile",
      title: "Crawl",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/7b7ee267-1578-41e7-87f4-30ea1988e169/cover/{height}",
      hero: {
        mode: "standard",
        title: "Crawl",
        synopsis:
          "When her hometown is devastated by a hurricane, a teenager returns to find her missing father. But rising waters may be the least of their worries. (2019)(87 mins)",
        heroTitleImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/7dacbf40bc3bc432f334c809f682770d/herotitle.png",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/7b7ee267-1578-41e7-87f4-30ea1988e169/background/1428",
        metadata: "1h 45m   ",
      },
    },
    {
      type: "coverTile",
      title: "The Dead Don't Die",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/9f3bfab5-81a0-405e-bdf5-79c64b9753e4/cover/{height}",
      hero: {
        mode: "standard",
        title: "The Dead Don't Die",
        synopsis:
          "The citizens of a sleepy small town must confront the rise of the undead. Zombie comedy with a star-studded cast including Bill Murray, Adam Driver and Steve Buscemi. (2019)(103 mins)",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/9f3bfab5-81a0-405e-bdf5-79c64b9753e4/background/1428",
        metadata: "1h 45m   ",
      },
    },
    {
      type: "coverTile",
      title: "Polar Squad",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/5b323e3e-5016-4f73-a29c-e7375c1919fc/cover/{height}",
      hero: {
        mode: "standard",
        title: "Polar Squad",
        synopsis:
          "Swifty the fox discovers Otto Van Walrus' devious plan to melt all the ice in the Arctic and teams up with his pals to stop him. Animation with Alec Baldwin and John Cleese. (2019)(93 mins)",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/5b323e3e-5016-4f73-a29c-e7375c1919fc/background/1428",
        metadata: "1h 45m   ",
      },
    },
    {
      type: "coverTile",
      title: "Alita: Battle Angel",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/ded8d126-5d0d-4d02-bb1f-676bb3415424/cover/{height}",
      hero: {
        mode: "standard",
        title: "Alita: Battle Angel",
        synopsis:
          "A battle cyborg awakens in a post-apocalyptic world with no memory and embarks on a search to find out who she really is. Epic sci-fi adventure co-written by James Cameron. (2019)(122 mins)",
        heroTitleImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/386216d897e7013eaa42f0a0b060f119/herotitle.png",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/ded8d126-5d0d-4d02-bb1f-676bb3415424/background/1428",
        metadata: "1h 45m   ",
      },
    },
    {
      type: "coverTile",
      title: "Star Wars: Episode IX - The Rise of Skywalker",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/1cbf6f64-0eab-42d2-b282-13db50305b02/cover/{height}",
      hero: {
        mode: "standard",
        title: "Star Wars: Episode IX - The Rise of Skywalker",
        synopsis:
          "The iconic Skywalker saga concludes as the remnants of the Resistance fight for survival. With Daisy Ridley and Adam Driver. (2019)(142 mins)",
        heroTitleImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/b879d43971321ef6b1997668bc20ea14/herotitle.png",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/3d50de99f016e025485d0dc0468b9aec/hero.jpg",
        metadata: "1h 45m   ",
      },
    },
    {
      type: "coverTile",
      title: "Driven",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/7883cdf0-0439-4afc-a414-dd901579e2fe/cover/{height}",
      hero: {
        mode: "standard",
        title: "Driven",
        synopsis:
          "Lee Pace stars as John DeLorean, whose quest to design the car of the future and friendship with an FBI informant leads to his downfall. Drama with Jason Sudeikis. (2018)(108 mins)",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/7883cdf0-0439-4afc-a414-dd901579e2fe/background/1428",
        metadata: "1h 45m   ",
      },
    },
  ],
} as Rail;
