import { Rail } from "types";

export default {
  type: "rail",
  uuid: "b903a730-a546-4216-9bc7-cb7c6f27d7ad",
  template: "tile-cover",
  title: "Even better in Ultra HD",
  tiles: [
    {
      type: "coverTile",
      title: "The Goldfinch",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/99605174-679b-44ad-8c69-92c21c01495c/cover/{height}",
      hero: {
        mode: "standard",
        title: "The Goldfinch",
        metadata: "1h 45m   ",
        synopsis:
          "A troubled young man enters the world of art forgery, years after his mother was killed at the Metropolitan Museum of Art. With Ansel Elgort and Sarah Paulson. (2019)(149 mins)",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/99605174-679b-44ad-8c69-92c21c01495c/background/{width}",
      },
    },
    {
      type: "coverTile",
      title: "Downton Abbey",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/631af54d-e06f-4eb1-ba62-512492043748/cover/{height}",
      hero: {
        mode: "standard",
        title: "Downton Abbey",
        metadata: "1h 45m   ",
        synopsis:
          "Big-screen adaptation of the hit television series, following the beloved Crawleys as they prepare for a royal visit. With Maggie Smith. (2019)(122 mins) ",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/631af54d-e06f-4eb1-ba62-512492043748/background/{width}",
        heroTitleImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/7be1f1ff2ef13bce5ae414250ec32cda/herotitle.png",
      },
    },
    {
      type: "coverTile",
      title: "Gemini Man",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/3df6fb6a-a92d-40c9-8141-306c585a16b6/cover/{height}",
      hero: {
        mode: "standard",
        title: "Gemini Man",
        metadata: "1h 45m   ",
        synopsis:
          "Will Smith stars as an aging assassin who becomes targeted by a mysterious enemy that seems able to anticipate his every move. Ang Lee directs. (2019)(117 mins)",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/b8868e8a896a4c6ed5df000fab6ff21a/hero.jpg",
        heroTitleImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/8b749efdb7f09817f8e6738ec4dac7d9/herotitle.png",
      },
    },
    {
      type: "coverTile",
      title: "The Kitchen",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/13d760a2-5e75-4b43-8a27-ba26b6296f16/cover/{height}",
      hero: {
        mode: "standard",
        title: "The Kitchen",
        metadata: "1h 45m   ",
        synopsis:
          "In 1970s New York, three mob wives take business into their own hands when their husbands are sent to prison. Melissa McCarthy, Tiffany Haddish and Elisabeth Moss star. (2019)(103 minutes)",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/13d760a2-5e75-4b43-8a27-ba26b6296f16/background/{width}",
        heroTitleImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/989f7a9b2cae3c42b84d4d4d967edb8e/herotitle.png",
      },
    },
    {
      type: "coverTile",
      title: "Annabelle Comes Home",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/ae570efa-d64b-4b2e-8f46-ac3cb0521ea4/cover/{height}",
      hero: {
        mode: "standard",
        title: "Annabelle Comes Home",
        metadata: "1h 45m   ",
        synopsis:
          "The possessed doll strikes again in the horror sequel, as paranormal investigators Ed and Lorraine Warren make the grave mistake of locking it in a room in their house. (2019)(106 mins)",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/ae570efa-d64b-4b2e-8f46-ac3cb0521ea4/background/{width}",
        heroTitleImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/2da0bf0adb9d6af5d8f3f15caec46c3c/herotitle.png",
      },
    },
    {
      type: "coverTile",
      title: "Crawl",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/7b7ee267-1578-41e7-87f4-30ea1988e169/cover/{height}",
      hero: {
        mode: "standard",
        title: "Crawl",
        metadata: "1h 45m   ",
        synopsis:
          "When her hometown is devastated by a hurricane, a teenager returns to find her missing father. But rising waters may be the least of their worries. (2019)(87 mins)",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/7b7ee267-1578-41e7-87f4-30ea1988e169/background/{width}",
        heroTitleImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/b2e9b8941ff2ce98b64e499d50ea5282/herotitle.png",
      },
    },
    {
      type: "coverTile",
      title: "Fast & Furious Presents: Hobbs & Shaw",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/7cd0fcec-0f5a-4a8a-963d-4bd862f758f5/cover/{height}",
      hero: {
        mode: "standard",
        title: "Fast & Furious Presents: Hobbs & Shaw",
        metadata: "1h 45m   ",
        synopsis:
          "Spin-off from the iconic franchise. Luke Hobbs (Dwayne Johnson) and Deckard Shaw (Jason Statham) must put their differences aside and team up to stop a huge terror threat. (2019)(137 mins)",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/7cd0fcec-0f5a-4a8a-963d-4bd862f758f5/background/{width}",
        heroTitleImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/2fa700f12672d4b7dd853efd54162392/herotitle.png",
      },
    },
    {
      type: "coverTile",
      title: "Alita: Battle Angel",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/ded8d126-5d0d-4d02-bb1f-676bb3415424/cover/{height}",
      hero: {
        mode: "standard",
        title: "Alita: Battle Angel",
        metadata: "1h 45m   ",
        synopsis:
          "A battle cyborg awakens in a post-apocalyptic world with no memory and embarks on a search to find out who she really is. Epic sci-fi adventure co-written by James Cameron. (2019)(122 mins)",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/ded8d126-5d0d-4d02-bb1f-676bb3415424/background/{width}",
        heroTitleImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/f9dc20cc8befc53e078bded7e2290141/herotitle.png",
      },
    },
    {
      type: "coverTile",
      title: "Stuber",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/8252798d-0b39-4086-ad95-de58c7560bfd/cover/{height}",
      hero: {
        mode: "standard",
        title: "Stuber",
        metadata: "1h 45m   ",
        synopsis:
          "A wild journey ensues for a mild-mannered Uber driver when he picks up an aggressive cop who is on the hunt for a vicious killer. Action comedy with Dave Bautista. (2019)(93 mins)",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/8252798d-0b39-4086-ad95-de58c7560bfd/background/{width}",
      },
    },
    {
      type: "coverTile",
      title: "Dora and the Lost City of Gold",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/91333547-707c-4f7a-9e04-d1a8d565a53a/cover/{height}",
      hero: {
        mode: "standard",
        title: "Dora and the Lost City of Gold",
        metadata: "1h 45m   ",
        synopsis:
          "Despite years of exploring, nothing could prepare Dora for her biggest journey yet - high school. Live-action adaptation of the animated show. (2019)(102 mins)",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/91333547-707c-4f7a-9e04-d1a8d565a53a/background/{width}",
        heroTitleImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/428565932550936987e4df89c4f1ad7c/herotitle.png",
      },
    },
  ],
} as Rail;
