import { Rail } from "types";

export default {
  type: "rail",
  uuid: "28229df2-1fb9-4560-b1e6-4bf14957dd2e",
  template: "tile-landscape",
  title: "Trending",
  tiles: [
    {
      type: "landscapeTile",
      title: "The Great British Sewing Bee",
      imageUrl:
        "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/6ed2d41c48b56360edad3ab7df3dd95e/{width}/{height}/item.jpg",
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_bbcone/408/84",
      hero: {
        mode: "standard",
        title: "The Great British Sewing Bee",
        metadata: "1h 45m   ",
        synopsis:
          "Joe Lycett kicks off ten weeks of the most perplexing patterns, eye-popping transformations and stunning made-to-measure garments as the sixth season of the nation’s favourite craft show begins.",
        logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_bbcone/408/84",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/3b99af7c91d168908e24f26b853a5315/hero.jpg",
        heroTitleImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/cbe30d6593f40353f06f29e15dffc54e/herotitle.png",
      },
    },
    {
      type: "landscapeTile",
      title: "Agatha Raisin",
      imageUrl:
        "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/11b82f4ec1e5d681dea9bde91957c2df/{width}/{height}/item.jpg",
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_skyone/408/84",
      hero: {
        mode: "standard",
        title: "Agatha Raisin",
        metadata: "1h 45m   ",
        synopsis:
          "Now with her own detective agency, get ready for a third series of instalments as Ashley Jensen returns as Agatha Raisin, London PR professional-turned-sleuth.\r",
        logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_skyone/408/84",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/ce4e064906f70af7c113768d71e71333/hero.jpg",
        heroTitleImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/81b6e5640e2a3cd0332827a4b055f98f/herotitle.png",
      },
    },
    {
      type: "landscapeTile",
      title: "Wu-Tang Clan: Of Mics and Men",
      imageUrl:
        "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/dc4305aa51ee8c80f600074abba3d745/{width}/{height}/item.jpg",
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_skydocumentaries/408/84",
      hero: {
        mode: "standard",
        title: "Wu-Tang Clan: Of Mics and Men",
        metadata: "1h 45m   ",
        synopsis:
          "This four-part docuseries looks back on the group's career and how its founding members overcame violence and poverty to form one of the most recognisable musical movements in the world.",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skydocumentaries/408/84",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/541ca50214ccbcc7cb6b709015682ace/hero.jpg",
        heroTitleImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/d43192f8ff01452e37951f4981545948/herotitle.png",
      },
    },
    {
      type: "landscapeTile",
      title: "100 Embarrassing Dads",
      imageUrl:
        "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/b4518d680406f57c36028298bb4283d2/{width}/{height}/item.jpg",
      hero: {
        mode: "standard",
        title: "100 Embarrassing Dads",
        metadata: "1h 45m   ",
        synopsis:
          "Clip show of online content showing fathers doing epic fails and mothers causing mayhem for their kids. Including some amazing examples of 'dad dancing'.",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/27a41c4cb38b8cecfdb6966e5e16acee/hero.jpg",
      },
    },
    {
      type: "landscapeTile",
      title: "APB",
      imageUrl:
        "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/2b1bb351bba56df05a592fd580c7252f/{width}/{height}/item.jpg",
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_fox/408/84",
      hero: {
        mode: "standard",
        title: "APB",
        metadata: "1h 45m   ",
        synopsis:
          "Crime drama following a billionaire, who, after witnessing a violent crime, purchases a troubled police precinct and reboots it with cutting-edge technology as a private law enforcement agency.",
        logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_fox/408/84",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/8432f0e56445b286ea07207f86953a23/hero.jpg",
        heroTitleImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/b40779e610a40587eb18951a1c50a1be/herotitle.png",
      },
    },
    {
      type: "landscapeTile",
      title: "Spides",
      imageUrl:
        "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/1a4e1927c42816ca8f796c4a0b4d9ed9/{width}/{height}/item.jpg",
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_syfy/408/84",
      hero: {
        mode: "standard",
        title: "Spides",
        metadata: "1h 45m   ",
        synopsis:
          "Sci-fi drama starring Game of Thrones’ Rosabell Laurenti Sellers as Nora, a young woman who awakens from a coma with no memory of her previous life after taking a strange drug. All episodes available.",
        logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_syfy/408/84",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/a6637c76036b593b14f06939c6620307/hero.jpg",
        heroTitleImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/897367b03339f71de4eeb4646e8200a8/herotitle.png",
      },
    },
    {
      type: "landscapeTile",
      title: "Alan Bennett's Talking Heads",
      imageUrl:
        "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/c652907df6cc8cb5f6c8a3c58c01dc28/{width}/{height}/item.jpg",
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_bbcone/408/84",
      hero: {
        mode: "standard",
        title: "Alan Bennett's Talking Heads",
        metadata: "1h 45m   ",
        synopsis:
          "Jodie Comer, Martin Freeman and Sarah Lancashire are among the stars in the latest adaptation of Alan Bennett's iconic dramatic monologues. All episodes available.",
        logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_bbcone/408/84",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/68f5b2cc2c665e731bf2f5e484b9e75a/hero.jpg",
        heroTitleImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/525669b0c309b3dd06bdbd58bda51393/herotitle.png",
      },
    },
    {
      type: "titleTile",
      actionText: "View all",
      hero: {
        mode: "standard",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/68f5b2cc2c665e731bf2f5e484b9e75a/hero.jpg",
      },
    },
  ],
} as Rail;
