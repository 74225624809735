export const transitions = {
  changeRail: {
    duration: 0.4,
    ease: "power2.out",
  },

  moveLens: {
    duration: 0.6,
    ease: "power2.out",
  },

  zoomIn: {
    duration: 0.6,
    ease: "power2.out",
  },

  zoomOut: {
    duration: 0.6,
    ease: "power2.out",
  },

  switcherToBrowse: {
    duration: 0.6,
    ease: "power2.out",
  },

  browseToSwitcher: {
    duration: 0.6,
    ease: "power2.out",
  },
};
