import { QAMTab } from "types";
import { Action } from "types/actions";

export type QuickAccessMenuState = Readonly<{
  visible: boolean;
  tab: QAMTab;
}>;

export const initialQuickAccessMenuState: QuickAccessMenuState = {
  visible: false,
  tab: "menu",
};

export const quickAccessMenuReducer = (
  state: QuickAccessMenuState = initialQuickAccessMenuState,
  action: Action
) => {
  switch (action.type) {
    case "QUICK_ACCESS_MENU.HIDE":
      return { ...state, visible: false };
    case "QUICK_ACCESS_MENU.SHOW":
      return { ...state, tab: action.tab, visible: true };
    default:
      return state;
  }
};
