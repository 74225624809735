import { TvGuidePageId } from "types";

export const pageOrder: TvGuidePageId[] = [
  "tvGuide_tvShows",
  "tvGuide_movies",
  "tvGuide_music",
  "tvGuide_sports",
  "tvGuide_news",
  "tvGuide_kids",
];
