import { CSSProperties } from "react";
import styled from "@emotion/styled";
import { colours, fonts, sizes } from "constants/type";
import { FontSize, FontWeight } from "../../types";

export type Props = {
  size: FontSize;
  weight: FontWeight;
  clamp?: number;
  subdued?: boolean;
  extraLineHeight?: boolean;
  style?: CSSProperties;
};

export const Text = styled.div(
  ({ clamp, size, subdued, weight, extraLineHeight }: Props) => ({
    color: colours.white,
    fontFamily: fonts[weight],
    fontSize: typeof size === "string" ? sizes[size] : size,
    opacity: subdued ? "50%" : "100%",
    ...(clamp && {
      display: "-webkit-box",
      WebkitLineClamp: clamp,
      WebkitBoxOrient: "vertical",
      overflow: "hidden",
    }),
    ...(extraLineHeight && {
      lineHeight: 1.22,
    }),
  })
);
