import { fromEvent, Observable, race, timer } from "rxjs";
import {
  throttle,
  mergeMap,
  mapTo,
  take,
  share,
  throttleTime,
} from "rxjs/operators";

export type Button =
  | "LEFT"
  | "UP"
  | "RIGHT"
  | "DOWN"
  | "SELECT"
  | "BACK"
  | "HOME"
  | "ADD"
  | "0"
  | "1"
  | "2"
  | "3"
  | "4"
  | "5"
  | "6"
  | "7"
  | "8"
  | "9"
  | "VOLUME_UP"
  | "VOLUME_DOWN"
  | "SETTINGS"
  | "MENU"
  | "POWER"
  | "TOGGLE_PAUSE";

const buttonLookup: { [key: string]: Button } = {
  ArrowLeft: "LEFT",
  ArrowUp: "UP",
  ArrowRight: "RIGHT",
  ArrowDown: "DOWN",
  Enter: "SELECT",
  KeyA: "MENU",
  KeyB: "BACK",
  KeyH: "HOME",
  KeyU: "VOLUME_UP",
  KeyD: "VOLUME_DOWN",
  KeyJ: "BACK", // TEMPORARY
  KeyS: "SETTINGS",
  KeyP: "TOGGLE_PAUSE",
  KeyQ: "POWER",
  Digit0: "0",
  Digit1: "1",
  Digit2: "2",
  Digit3: "3",
  Digit4: "4",
  Digit5: "5",
  Digit6: "6",
  Digit7: "7",
  Digit8: "8",
  Digit9: "9",
};

export const buttonPressTypes = ["LONG", "SHORT"] as const;
export type ButtonPressType = typeof buttonPressTypes[number];
export type ButtonPress = {
  button: Button;
  pressType: ButtonPressType;
};
export type SubscribeOptions = {
  throttleMs?: number;
};

const LONG_PRESS_DURATION_MS = 2000;

const calculateStream = (
  keyDown$: Observable<KeyboardEvent>,
  keyUp$: Observable<KeyboardEvent>
) => {
  const firstDown$ = keyDown$.pipe(throttle(() => keyUp$));

  return firstDown$.pipe(
    mergeMap(e => {
      const button = buttonLookup[e.code];
      return race(
        keyUp$.pipe(
          take(1),
          mapTo({ button, pressType: "SHORT" } as ButtonPress)
        ),
        timer(LONG_PRESS_DURATION_MS).pipe(
          mapTo({ button, pressType: "LONG" } as ButtonPress)
        )
      );
    })
  );
};

export class KeyListener {
  private keyDown$: Observable<KeyboardEvent>;
  private keyUp$: Observable<KeyboardEvent>;

  public stream$: Observable<ButtonPress>;

  constructor({
    keyDown$,
    keyUp$,
  }: {
    keyDown$?: Observable<KeyboardEvent>;
    keyUp$?: Observable<KeyboardEvent>;
  } = {}) {
    this.keyDown$ =
      keyDown$ || (fromEvent(document, "keydown") as Observable<KeyboardEvent>);
    this.keyUp$ =
      keyUp$ || (fromEvent(document, "keyup") as Observable<KeyboardEvent>);
    this.stream$ = calculateStream(this.keyDown$, this.keyUp$).pipe(share());
  }

  subscribe(
    callback: (buttonPress: ButtonPress) => void,
    options: SubscribeOptions = {}
  ) {
    return options.throttleMs
      ? this.stream$.pipe(throttleTime(options.throttleMs)).subscribe(callback)
      : this.stream$.subscribe(callback);
  }
}
