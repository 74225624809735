import { Rail } from "types";

export default {
  type: "rail",
  uuid: "80467f7a-5fb1-40d2-81a9-631f1a0ce237",
  template: "tile-cover",
  title: "Purchases",
  tiles: [
    {
      type: "coverTile",
      title: "Joker: Special",
      imageUrl:
        "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/076c1ca7a04868a144499073ca0638ec/{width}/{height}/item.jpg",
      hero: {
        mode: "standard",
        title: "Joker: Special",
        metadata: "1h 45m    ",
        synopsis:
          "A sneak peek at the highly-anticipated upcoming thriller starring Joaquin Phoenix and Robert De Niro.",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/232e9f2b3babf0ede2c76b4f09b256d0/hero.jpg",
      },
    },
    {
      type: "coverTile",
      title: "Resident Evil: Apocalypse",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/76b9a447-a999-43b2-90ec-8314f7f5eea1/cover/{height}",
      hero: {
        mode: "standard",
        title: "Resident Evil: Apocalypse",
        metadata: "1h 45m    ",
        synopsis:
          "The game's not over for super survivor Milla Jovovich as she returns to blow away another wave of ravenous zombies. Sci-fi action. Strong language/violence/flashing images. (2004)(90 mins)",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/76b9a447-a999-43b2-90ec-8314f7f5eea1/background/{width}",
      },
    },
    {
      type: "coverTile",
      title: "Resident Evil: Extinction",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/2b1e69ce-0f07-43e4-8de5-1ee83289c9b2/cover/{height}",
      hero: {
        mode: "standard",
        title: "Resident Evil: Extinction",
        metadata: "1h 45m    ",
        synopsis:
          "Warrior Milla Jovovich joins up with survivors of the deadly T-virus, but they have to face the terrifying creatures it has created. Violence/flashing images. (2007)(91 mins)",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/2b1e69ce-0f07-43e4-8de5-1ee83289c9b2/background/{width}",
      },
    },
    {
      type: "coverTile",
      title: "Resident Evil: Afterlife",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/ff7afb87-ec2a-4b5a-b024-d149d1e61b47/cover/{height}",
      hero: {
        mode: "standard",
        title: "Resident Evil: Afterlife",
        metadata: "1h 45m    ",
        synopsis:
          "Zombie killer Milla Jovovich battles alongside Wentworth Miller to find a haven from the undead. Action horror. Strong language/violence/flashing images. (2010)(97 mins)",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/ff7afb87-ec2a-4b5a-b024-d149d1e61b47/background/{width}",
      },
    },
    {
      type: "coverTile",
      title: "Resident Evil: Retribution",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/92de943d-a63e-413b-814a-58215cfbf164/cover/{height}",
      hero: {
        mode: "standard",
        title: "Resident Evil: Retribution",
        metadata: "1h 45m    ",
        synopsis:
          "Milla Jovovich takes on the evil Umbrella Corporation and its undead army in this fifth chapter of the fantasy franchise. With Michelle Rodriguez. Violence/flashing images. (2012)(91 mins)",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/92de943d-a63e-413b-814a-58215cfbf164/background/{width}",
      },
    },
    {
      type: "coverTile",
      title: "Shaun of the Dead",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/f20f3f35-afd2-4bfd-84c9-e12cca88c3be/cover/{height}",
      hero: {
        mode: "standard",
        title: "Shaun of the Dead",
        metadata: "1h 45m    ",
        synopsis:
          "Life's a yawn for Shaun, until zombies take over London. Then it's a no guts, no glory rescue mission: to the pub! Comedy. Strong language/violence/flashing images. (2004)(96 mins)",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/f20f3f35-afd2-4bfd-84c9-e12cca88c3be/background/{width}",
      },
    },
    {
      type: "coverTile",
      title: "The Finest Hours",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/df962999-0d36-47ca-9409-aac970cc9a5a/cover/{height}",
      hero: {
        mode: "standard",
        title: "The Finest Hours",
        metadata: "1h 45m    ",
        synopsis:
          "True-life drama about the efforts of the US Coast Guard to save the crew of a sinking tanker. Chris Pine stars alongside Casey Affleck and Ben Foster. (2015)(113 mins)",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/df962999-0d36-47ca-9409-aac970cc9a5a/background/{width}",
      },
    },
    {
      type: "coverTile",
      title: "Peter Rabbit",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/7f2b8cd9-3390-4a3d-be44-26264abe1c9f/cover/{height}",
      hero: {
        mode: "standard",
        title: "Peter Rabbit",
        metadata: "1h 45m    ",
        synopsis:
          "Beatrix Potter adaptation with James Corden voicing the mischievous rabbit whose feud with the McGregors reaches new heights. (2018)(91 mins)",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/7f2b8cd9-3390-4a3d-be44-26264abe1c9f/background/{width}",
      },
    },
    {
      type: "coverTile",
      title: "The Good Dinosaur",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/065570dc-0624-4b67-91c0-b401cff8b20a/cover/{height}",
      hero: {
        mode: "standard",
        title: "The Good Dinosaur",
        metadata: "1h 45m    ",
        synopsis:
          "Pixar animation about an evolved Apatosaurus named Arlo and his unlikely Neanderthal pal Spot. With the voices of Raymond Ochoa and Sam Elliott. Flashing images. (2015)(90 mins)",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/065570dc-0624-4b67-91c0-b401cff8b20a/background/{width}",
      },
    },
    {
      type: "coverTile",
      title: "Aladdin",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/32ad82fc-9190-41da-a06a-d161de9eb691/cover/{height}",
      hero: {
        mode: "standard",
        title: "Aladdin",
        metadata: "1h 45m    ",
        synopsis:
          "Brilliant Disney animation featuring the late Robin Williams as the voice of the irrepressible Genie who grants street kid Aladdin three wishes. (1992)(87 mins)",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/32ad82fc-9190-41da-a06a-d161de9eb691/background/{width}",
      },
    },
    {
      type: "coverTile",
      title: "Lilo & Stitch",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/c1b826fa-5d38-44f7-8cc7-47cdee56e0d8/cover/{height}",
      hero: {
        mode: "standard",
        title: "Lilo & Stitch",
        metadata: "1h 45m    ",
        synopsis:
          "Hawaiian orphan Lilo adopts a cute abandoned creature, unaware he's actually an extra-terrestrial experiment on the run. Adorable animated adventure. Flashing images. (2002)(82 mins)",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/c1b826fa-5d38-44f7-8cc7-47cdee56e0d8/background/{width}",
      },
    },
    {
      type: "coverTile",
      title: "Luis and the Aliens",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/41a73c9c-c10c-4938-946e-ab48c67fba0e/cover/{height}",
      hero: {
        mode: "standard",
        title: "Luis and the Aliens",
        metadata: "1h 45m    ",
        synopsis:
          "A lonely and neglected 12-year-old boy named Luis goes on the adventure of a lifetime when three little aliens crash-land their spaceship right in front of him. Animation. (2018)(86 mins)",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/41a73c9c-c10c-4938-946e-ab48c67fba0e/background/{width}",
      },
    },
    {
      type: "coverTile",
      title: "Star Wars The Rise of Skywalker: Trailer",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/29c26365-79f7-4037-a530-43bef1032143/cover/{height}",
      hero: {
        mode: "standard",
        title: "Star Wars The Rise of Skywalker: Trailer",
        metadata: "1h 45m    ",
        synopsis:
          "It's nearly here - watch a trailer for the final instalment of the Star Wars sequel trilogy, as the Resistance face the First Order once more.",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/4a98b7cf1d462c4b9fa84131dcd189de/hero.jpg",
      },
    },
    {
      type: "coverTile",
      title: "The Lone Ranger",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/cd425639-e888-405b-bcff-6c05d1937a87/cover/{height}",
      hero: {
        mode: "standard",
        title: "The Lone Ranger",
        metadata: "1h 45m    ",
        synopsis:
          "Armie Hammer's lawman embarks on a quest for justice across the American West. Johnny Depp co-stars as his Comanche Indian sidekick Tonto. Violence/flashing images. (2013)(144 mins)",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/cd425639-e888-405b-bcff-6c05d1937a87/background/{width}",
      },
    },
  ],
} as Rail;
