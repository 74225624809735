import { State } from "reducers";
import { FocusArea } from "reducers/browse/pages/tvGuide";
import { Hero } from "types";
import {
  focusArea,
  railHero,
  pageHero,
  overrideHeroFields,
  overlayIsShowing,
} from "selectors/pages/tvGuide";

export const heroSelector = (state: State): Hero => {
  // Basic
  let hero = basicHeroSelector(state);

  // Overrides
  hero = { ...hero, ...overrideHeroFields(state) };

  // Only image when overlay is showing
  if (overlayIsShowing(state)) {
    hero = { ...hero, mode: "imageOnly", imageOpacity: 0.5 };
  }

  return hero;
};

const basicHeroSelector = (state: State): Hero => {
  const area: FocusArea = focusArea(state);
  switch (area) {
    case "rail":
      return railHero(state);
    case "channels":
    case "grid":
      return pageHero(state);
    default:
      return { mode: "none" };
  }
};
