import { State } from "reducers";
import { Hero } from "types";
import { browsePageHeroSelector } from "selectors/hero";
import { currentPage } from "selectors/currentPage";

export const heroSelector = (state: State): Hero => {
  const hero = browsePageHeroSelector(state);
  // Add page title
  const { page } = currentPage(state);
  const title = "title" in page ? page.title.text : undefined;
  return title ? { ...hero, mode: "hugeTitle", title } : hero;
};
