import { Rail } from "types";

export default {
  type: "rail",
  uuid: "508c3430-d780-445d-a04a-31c41b1fb9cd",
  template: "tile-landscape",
  title: "Sports Documentaries",
  tiles: [
    {
      type: "landscapeTile",
      title: "Conor McGregor: Notorious",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/d7d0e21a-e33f-4105-a20d-de3482d6775c/16-9/{width}",
      hero: {
        mode: "standard",
        title: "Conor McGregor: Notorious",
        metadata: "1h 45m   ",
        synopsis:
          "From making ends meet to a lavish life at the top, an all-access look at the remarkable rise of Conor McGregor, with all the highs and lows of his ascent.",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/d7d0e21a-e33f-4105-a20d-de3482d6775c/background/{width}",
        heroTitleImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/00c6e0b05e8f54461ae288f5514cf5c7/herotitle.png",
      },
    },
    {
      type: "landscapeTile",
      title: "Kenny",
      imageUrl:
        "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/ef3f7a7d65749569b86c08592a0ad990/{width}/{height}/item.jpg",
      hero: {
        mode: "standard",
        title: "Kenny",
        metadata: "1h 45m   ",
        synopsis:
          "Documentary focusing on the bond between Liverpool FC and club legend Kenny Dalglish, featuring interviews with former players and rivals.",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skydocumentaries/408/84",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/89c189f08949d1731d09fdda5437a9fd/hero.jpg",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_skydocumentaries/408/84",
    },
    {
      type: "landscapeTile",
      title: "Klitschko",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/3aad08a0-dd5d-4cd5-96fa-44e1db9096a0/16-9/{width}",
      hero: {
        mode: "standard",
        title: "Klitschko",
        metadata: "1h 45m   ",
        synopsis:
          "Brothers, giants, champions. First released in 2011, hear the amazing story of heavyweight boxing legends and history makers Vitali and Wladimir Klitschko, from their own perspectives.",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/3aad08a0-dd5d-4cd5-96fa-44e1db9096a0/background/{width}",
      },
    },
    {
      type: "landscapeTile",
      title: "Take the Ball, Pass the Ball",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/1308f96d-92aa-4a71-8008-712b7c57187c/16-9/{width}",
      hero: {
        mode: "standard",
        title: "Take the Ball, Pass the Ball",
        metadata: "1h 45m   ",
        synopsis:
          "Some of the biggest names in the history of football look back on one of the sport's most successful teams - Pep Guardiola's Barcelona. Strong language/flashing images. (2018)(90 mins)",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skysppl/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/1308f96d-92aa-4a71-8008-712b7c57187c/background/{width}",
        heroTitleImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/cb68898f8110c06d1d0f134485f17833/herotitle.png",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_skysppl/408/84",
    },
    {
      type: "landscapeTile",
      title: "I Believe in Miracles",
      imageUrl:
        "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/65c27657814e4869659645381f85a1a5/{width}/{height}/item.jpg",
      hero: {
        mode: "standard",
        title: "I Believe in Miracles",
        metadata: "1h 45m   ",
        synopsis:
          "Film exploring the rise of an underachieving Nottingham Forest football club, and how the team went on to win two unlikely European cups, lead by Brian Clough.",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skydocumentaries/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/e1013355-aee0-4a76-912b-02866031ed51/background/{width}",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_skydocumentaries/408/84",
    },
    {
      type: "landscapeTile",
      title: "Everton - Howard's Way",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/0d1704b5-8c08-48f8-a357-f0b7ec554e77/16-9/{width}",
      hero: {
        mode: "standard",
        title: "Everton - Howard's Way",
        metadata: "1h 45m   ",
        synopsis:
          "Documentary film telling the story of Everton and how they reached their their trophy-winning peak in the 1980s under the stewardship of Howard Kendall.",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skydocumentaries/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/0d1704b5-8c08-48f8-a357-f0b7ec554e77/background/{width}",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_skydocumentaries/408/84",
    },
    {
      type: "landscapeTile",
      title: "89",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/da74771e-6ff2-4faf-8e14-eeb901a0e551/16-9/{width}",
      hero: {
        mode: "standard",
        title: "89",
        metadata: "1h 45m   ",
        synopsis:
          "Gunners greats including Tony Adams and Lee Dixon recall Arsenal's legendary 1989 title win, when it all came down to their final game against Liverpool. Flashing images. (2017)(90 mins)",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skysppl/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/da74771e-6ff2-4faf-8e14-eeb901a0e551/background/{width}",
        heroTitleImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/8979660203aebf2b9b577bc0bcb6dcdc/herotitle.png",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_skysppl/408/84",
    },
    {
      type: "landscapeTile",
      title: "Dan Carter: A Perfect 10",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/2c3d30d1-9181-414e-b5db-49c6f4c7ee91/16-9/{width}",
      hero: {
        mode: "standard",
        title: "Dan Carter: A Perfect 10",
        metadata: "1h 45m   ",
        synopsis:
          "In a revealing documentary, follow former New Zealand fly-half Dan Carter's journey from small town childhood to two-time World Cup winner and global rugby union icon.",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skydocumentaries/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/2c3d30d1-9181-414e-b5db-49c6f4c7ee91/background/{width}",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_skydocumentaries/408/84",
    },
    {
      type: "landscapeTile",
      title: "Next Goal Wins",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/b0299a9c-2dc3-41ca-b667-739e180921f5/16-9/{width}",
      hero: {
        mode: "standard",
        title: "Next Goal Wins",
        metadata: "1h 45m   ",
        synopsis:
          "Uplifting documentary following a Dutch football coach who attempts to reverse the fortunes of the American Samoa national side, who have never won a competitive game. ",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skydocumentaries/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/b0299a9c-2dc3-41ca-b667-739e180921f5/background/{width}",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_skydocumentaries/408/84",
    },
    {
      type: "landscapeTile",
      title: "I Am Ali",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/a293948e-11bc-4bf3-a87d-8902dd90182c/16-9/{width}",
      hero: {
        mode: "standard",
        title: "I Am Ali",
        metadata: "1h 45m   ",
        synopsis:
          "An intimate portrait of the late Muhammad Ali, featuring the boxing legend's personal audio diaries and interviews with those who knew him best.",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/a293948e-11bc-4bf3-a87d-8902dd90182c/background/{width}",
      },
    },
    {
      type: "landscapeTile",
      title: "Mike Tyson: Undisputed Truth",
      imageUrl:
        "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/a62d6dc6af77156609cd900ee961e025/{width}/{height}/item.jpg",
      hero: {
        mode: "standard",
        title: "Mike Tyson: Undisputed Truth",
        metadata: "1h 45m   ",
        synopsis:
          "Former heavyweight boxing champion Mike Tyson performs his raw, honest and humorous one-man show in New York. Directed by Spike Lee. ",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skydocumentaries/408/84",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/423df0a75f82fd54fae768db425704c7/hero.jpg",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_skydocumentaries/408/84",
    },
    {
      type: "landscapeTile",
      title: "Gascoigne",
      imageUrl:
        "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/1d9a51e95c35595bbbae708ccdeaf559/{width}/{height}/item.jpg",
      hero: {
        mode: "standard",
        title: "Gascoigne",
        metadata: "1h 45m   ",
        synopsis:
          "A moving look at the life and career of troubled England football genius Paul Gascoigne. Documentary with contributions from Gary Lineker and Jose Mourinho.",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skydocumentaries/408/84",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/c29acfb5a522ed863ca8c5824aecbbc2/hero.jpg",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_skydocumentaries/408/84",
    },
    {
      type: "landscapeTile",
      title: "At the Heart of Gold...",
      imageUrl:
        "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/b252ad6fdebeb22d9c8786b931daf531/{width}/{height}/item.jpg",
      hero: {
        mode: "standard",
        title: "At the Heart of Gold...",
        metadata: "1h 45m   ",
        synopsis:
          "...Inside the USA Gymnastics ScandalInside The USA's Gymnastics Scandal. In 2016, the shocking revelation of his abuse rocked US gymnastics. Now, survivors help to tell the story of Larry Nassar. ",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skydocumentaries/408/84",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/924731e4f79fd170448df6ea908416f7/hero.jpg",
        heroTitleImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/4edebaa3be50520a5e2e5862b15fd77e/herotitle.png",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_skydocumentaries/408/84",
    },
    {
      type: "landscapeTile",
      title: "Ronaldo",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/9c8e9f62-99e4-42cf-9b54-3a703c179729/16-9/{width}",
      hero: {
        mode: "standard",
        title: "Ronaldo",
        metadata: "1h 45m   ",
        synopsis:
          "For the first time on Sky Sports, follow the remarkable life and career of Portuguese football icon Cristiano Ronaldo in this candid documentary filmed over the course of 14 months. (2015)",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skysppl/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/9c8e9f62-99e4-42cf-9b54-3a703c179729/background/{width}",
        heroTitleImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/119a3175876c1a4668af899261fd1232/herotitle.png",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_skysppl/408/84",
    },
    {
      type: "landscapeTile",
      title: "Ferrari: Race To Immortality",
      imageUrl:
        "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/3da96bb4783aa8eb9f140c8a675d7618/{width}/{height}/item.jpg",
      hero: {
        mode: "standard",
        title: "Ferrari: Race To Immortality",
        metadata: "1h 45m   ",
        synopsis:
          "Formula One documentary chronicling the early years of the Scuderia Ferrari racing team, founded by the legendary Enzo Ferrari. ",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skydocumentaries/408/84",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/3882ad26f79e2757d4d7ad5633ec6164/hero.jpg",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_skydocumentaries/408/84",
    },
    {
      type: "landscapeTile",
      title: "Life of Ryan: Caretaker Manager",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/482a82a4-acfd-4888-861f-a0b8978fb0e8/16-9/{width}",
      hero: {
        mode: "standard",
        title: "Life of Ryan: Caretaker Manager",
        metadata: "1h 45m   ",
        synopsis:
          "Documentary following club legend Ryan Giggs as he takes on the manager role at Manchester United following David Moyes's doomed tenure. Strong language/flashing images. (2014)(82 mins)",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skysppl/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/482a82a4-acfd-4888-861f-a0b8978fb0e8/background/{width}",
        heroTitleImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/67399f8f194079236b6c14c3ce26c665/herotitle.png",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_skysppl/408/84",
    },
    {
      type: "landscapeTile",
      title: "McLaren",
      imageUrl:
        "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/d581db094d909d65777590d6f56c4825/{width}/{height}/item.jpg",
      hero: {
        mode: "standard",
        title: "McLaren",
        metadata: "1h 45m   ",
        synopsis:
          "Motorsport documentary celebrating pioneering engineer and driver Bruce McLaren, with contributions from Mario Andretti and Jackie Stewart. ",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skydocumentaries/408/84",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/ca5d428470a65759de9a23df77ca3116/hero.jpg",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_skydocumentaries/408/84",
    },
    {
      type: "landscapeTile",
      title: "Fire in Babylon",
      imageUrl:
        "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/2ea4917579ee4a47e8799ddb7e192599/{width}/{height}/item.jpg",
      hero: {
        mode: "standard",
        title: "Fire in Babylon",
        metadata: "1h 45m   ",
        synopsis:
          "British documentary lifting the lid on the record-breaking West Indies cricket team, journeying back to the 1970s where Captain Clive Lloyd transforms them into champions.",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skydocumentaries/408/84",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/82173724081462940cab7a2dca48aeb2/hero.jpg",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_skydocumentaries/408/84",
    },
    {
      type: "landscapeTile",
      title: "What's My Name: Muhammad Ali",
      imageUrl:
        "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/c22357d6012992ca798e3d8892a2b83f/{width}/{height}/item.jpg",
      hero: {
        mode: "standard",
        title: "What's My Name: Muhammad Ali",
        metadata: "1h 45m   ",
        synopsis:
          "A revealing look at the boxing icon's greatest challenges and triumphs. A portrait of a man who dreamed and achieved the impossible, against the odds. ",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skydocumentaries/408/84",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/622a84215e7236d2fc24f641d95ed92f/hero.jpg",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_skydocumentaries/408/84",
    },
    {
      type: "titleTile",
      actionText: "View all",
      hero: {
        mode: "standard",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/622a84215e7236d2fc24f641d95ed92f/hero.jpg",
      },
    },
  ],
} as Rail;
