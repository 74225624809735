import { Rail } from "types";

export default {
  type: "rail",
  uuid: "0209adfe-03ad-482b-9f3c-16ff7b9ef548",
  template: "tile-landscape",
  title: "Continue Watching",
  tiles: [
    {
      type: "landscapeTile",
      title: "Bear: Koala Hero",
      imageUrl:
        "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/d0dc420dafca3f226b560334db02c1ad/{width}/{height}/item.jpg",
      hero: {
        mode: "standard",
        title: "Bear: Koala Hero",
        metadata: "1h 45m    ",
        synopsis:
          "Bear is an Australian dog that can sniff out animals from huge distances. Follow his team's work as they search burnt-out bushland in southern Queensland for sick and injured koalas.",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/e23dd6c2366fa32947085896ff0aa5d8/hero.jpg",
        heroTitleImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/35f5c21fb237608a3d8b818b2ac0b89a/herotitle.png",
      },
    },
    {
      type: "landscapeTile",
      title: "Austin City Limits: Billie Eilish",
      imageUrl:
        "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/92810b6456ef2ca3f3fb1fcdd4523783/{width}/{height}/item.jpg",
      hero: {
        mode: "standard",
        title: "Austin City Limits: Billie Eilish",
        metadata: "1h 45m    ",
        synopsis:
          "Live footage of the five-time Grammy Award-winning teenage superstar as she performs fan-favourites from her smash-hit debut album, When We All Fall Asleep, Where Do We Go?  ",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skyarts/408/84",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/354139923b1e6e8f5fcaad94c91648c2/hero.jpg",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_skyarts/408/84",
    },
    {
      type: "landscapeTile",
      title: "Washington",
      imageUrl:
        "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/7082b56140ff3e421ba4923d6acd5176/{width}/{height}/item.jpg",
      hero: {
        mode: "standard",
        title: "Washington",
        metadata: "1h 45m    ",
        synopsis:
          "A three-part special bringing to life a President whose name is known by many but whose epic story is known by few. Here, see the beginning of his complex journey.",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skyhistory/408/84",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/5b729501e52f1e2fde6fa6a70c29ef0d/hero.jpg",
        heroTitleImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/228ca5df3326d712948ab828e4ea67fa/herotitle.png",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_skyhistory/408/84",
    },
    {
      type: "landscapeTile",
      title: "A Killer Uncaged",
      imageUrl:
        "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/15216dfd7fa0b9631c6ee4df2dcbdad5/{width}/{height}/item.jpg",
      hero: {
        mode: "standard",
        title: "A Killer Uncaged",
        metadata: "1h 45m    ",
        synopsis:
          "Three-part docuseries following killer Dale Wayne Sigler, a murderer, as he is released from prison after spending half his life behind bars.",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycrime/408/84",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/a95554b3c0f99abc8fc718e41ffbcc5a/hero.jpg",
        heroTitleImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/742da89468b09021481bea4a01b4f1a4/herotitle.png",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_skycrime/408/84",
    },
    {
      type: "landscapeTile",
      title: "The Luminaries",
      imageUrl:
        "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/28703dbb96085e08d9d29b721b26f75d/{width}/{height}/item.jpg",
      hero: {
        mode: "standard",
        title: "The Luminaries",
        metadata: "1h 45m    ",
        synopsis:
          "New Zealand, the 1860s. As the Gold Rush begins, a tale of adventure and mystery unfolds on the wild west coast of the South Island. Drama based on Eleanor Catton’s novel starring Eva Green.",
        logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_bbcone/408/84",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/bf5d5e0916df2480738c534899f33c67/hero.jpg",
        heroTitleImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/04d7c37d3f12e51d1c1576090ab80905/herotitle.png",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_bbcone/408/84",
    },
    {
      type: "landscapeTile",
      title: "Premier League Highlights",
      imageUrl:
        "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/7d8a60eb542defeeaed12f2c5cd2c8d8/{width}/{height}/item.jpg",
      hero: {
        mode: "standard",
        title: "Premier League Highlights",
        metadata: "1h 45m    ",
        synopsis:
          "All the latest action from the Premier League, with all the goals and biggest moments from the top flight.",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skysppl/408/84",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/53f29a21a584fd992efc91d67d2fe6c5/hero.jpg",
        heroTitleImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/c4753402230d9888275bf4cbd084d99a/herotitle.png",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_skysppl/408/84",
    },
    {
      type: "landscapeTile",
      title: "Alex Brooker: Disability and Me",
      imageUrl:
        "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/e3d7b4fd7f76e83f75fee5f71407588b/{width}/{height}/item.jpg",
      hero: {
        mode: "standard",
        title: "Alex Brooker: Disability and Me",
        metadata: "1h 45m    ",
        synopsis:
          "For the first time, comedian Alex Brooker discusses his disability and its impact on his life, and meets others with disabilities to hear their experiences. Intimate documentary.",
        logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_bbctwo/408/84",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/df05884cd5dd4beee6b3d868e5a84895/hero.jpg",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_bbctwo/408/84",
    },
    {
      type: "landscapeTile",
      title: "Batwoman",
      imageUrl:
        "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/4060767dacfb1a2c5fa22edde593a459/{width}/{height}/item.jpg",
      hero: {
        mode: "standard",
        title: "Batwoman",
        metadata: "1h 45m    ",
        synopsis:
          "As a criminal resurgence threatens Gotham City, Ruby Rose stars as Bruce Wayne’s highly trained cousin Kate Kane, who rises up as the city’s symbol of hope – Batwoman. Superhero drama.",
        logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_e4/408/84",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/c7e2dda222901bc54a5a377eec5804a8/hero.jpg",
        heroTitleImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/a02a430500d8e1615accd71d5d3d1a67/herotitle.png",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_e4/408/84",
    },
    {
      type: "landscapeTile",
      title: "Wild Tales from the Farm",
      imageUrl:
        "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/b80df88371c73633396cbbf5fab6cc79/{width}/{height}/item.jpg",
      hero: {
        mode: "standard",
        title: "Wild Tales from the Farm",
        metadata: "1h 45m    ",
        synopsis:
          "An English farm is an idyllic setting, but look deeper and you'll discover a hidden world of drama, rivalry, devotion, and friendship. Filmed across a year, see animals living their complex lives.",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/13ad8e258e59a7118966cf4e34aae609/hero.jpg",
        heroTitleImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/4a8897bccaf334747c632d768d43a065/herotitle.png",
      },
    },
    {
      type: "landscapeTile",
      title: "Austrian F1 GP Highlights",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/242fe218-5241-466e-a34d-8030e9502353/16-9/{width}",
      hero: {
        mode: "standard",
        title: "Austrian F1 GP Highlights",
        metadata: "1h 45m    ",
        synopsis:
          "Formula 1 racing is finally back, as the 2020 season gets underway with the Austrian Grand Prix at the Red Bull Ring in Spielberg. Max Verstappen was the winner at this track last year. ",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/242fe218-5241-466e-a34d-8030e9502353/background/{width}",
      },
    },
    {
      type: "landscapeTile",
      title: "Perry Mason",
      imageUrl:
        "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/16746b5304365fdd6e807c114dd81d0b/{width}/{height}/item.jpg",
      hero: {
        mode: "standard",
        title: "Perry Mason",
        metadata: "1h 45m    ",
        synopsis:
          "In 1931 Los Angeles, criminal defence lawyer Perry Mason investigates a child kidnapping gone very wrong. Origin story with Emmy Award winner Matthew Rhys.",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skyatlantic/408/84",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/0af96988d850882b736fdce14fa646dd/hero.jpg",
        heroTitleImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/f31620822d8d2597eb962a586a5400ee/herotitle.png",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_skyatlantic/408/84",
    },
    {
      type: "landscapeTile",
      title: "I May Destroy You",
      imageUrl:
        "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/e9f0e4b3ffe57ee34be8333f1244d9b7/{width}/{height}/item.jpg",
      hero: {
        mode: "standard",
        title: "I May Destroy You",
        metadata: "1h 45m    ",
        synopsis:
          "A frank and provocative two-part drama created by and starring BAFTA winner Michaela Coel, following the journey of a young writer as her life changes following a sexual assault in a nightclub.",
        logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_bbcone/408/84",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/7333fb4625409f83393b66e7eb4681bb/hero.jpg",
        heroTitleImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/42a77b021d993fd39eb79c33acf8197e/herotitle.png",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_bbcone/408/84",
    },
    {
      type: "landscapeTile",
      title: "Agatha Raisin",
      imageUrl:
        "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/11b82f4ec1e5d681dea9bde91957c2df/{width}/{height}/item.jpg",
      hero: {
        mode: "standard",
        title: "Agatha Raisin",
        metadata: "1h 45m    ",
        synopsis:
          "Now with her own detective agency, get ready for a third series of instalments as Ashley Jensen returns as Agatha Raisin, London PR professional-turned-sleuth.\r",
        logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_skyone/408/84",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/ce4e064906f70af7c113768d71e71333/hero.jpg",
        heroTitleImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/81b6e5640e2a3cd0332827a4b055f98f/herotitle.png",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_skyone/408/84",
    },
    {
      type: "landscapeTile",
      title: "Roswell, New Mexico",
      imageUrl:
        "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/170ab4123a25ee7474b32de27dd74dee/{width}/{height}/item.jpg",
      hero: {
        mode: "standard",
        title: "Roswell, New Mexico",
        metadata: "1h 45m    ",
        synopsis:
          "After losing funding for her research, a young woman reluctantly moves in with her father in one of the most famous towns in America - Roswell. Based on the Roswell High book series.",
        logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_itv2/408/84",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/581aba448c141535733e13d52035a457/hero.jpg",
        heroTitleImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/14fe3acbee6872276140ed9b195c0016/herotitle.png",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_itv2/408/84",
    },
    {
      type: "landscapeTile",
      title: "Celebrity Snoop Dogs",
      imageUrl:
        "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/141485f74690cfad4d259e2eb00e63c0/{width}/{height}/item.jpg",
      hero: {
        mode: "standard",
        title: "Celebrity Snoop Dogs",
        metadata: "1h 45m    ",
        synopsis:
          "Fancy a sniff around the home of your favourite celebrity? Famous faces open the doors to their lavish pads with a twist – it’s their pet pooches that will be giving the grand tour! ",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_channel4/408/84",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/eb2275af447aed1bd172e5aa2d5fd686/hero.jpg",
        heroTitleImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/38efbff4d3c9e3087e0ccb9778eca20a/herotitle.png",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_channel4/408/84",
    },
    {
      type: "titleTile",
      actionText: "View all",
      hero: {
        mode: "standard",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/eb2275af447aed1bd172e5aa2d5fd686/hero.jpg",
      },
    },
  ],
} as Rail;
