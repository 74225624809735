import { addMinutes, differenceInDays, format, startOfDay } from "date-fns";

export const formatTime = (date: Date | number) =>
  format(date, "h:mma").toLowerCase();

export const formatDay = (
  date: Date,
  today: Date,
  longDay: boolean = false
) => {
  switch (differenceInDays(startOfDay(date), startOfDay(today))) {
    case 0:
      return "Today";
    case 1:
      return "Tomorrow";
    case -1:
      return "Yesterday";
    default:
      return longDay ? format(date, "EEEE") : format(date, "E d");
  }
};

export const formatDayDifference = (date: Date, today: Date) => {
  const diff = differenceInDays(startOfDay(date), startOfDay(today));
  switch (diff) {
    case 0:
      return "";
    case 1:
      return "+24hrs";
    case -1:
      return "-24hrs";
    default:
      return `${diff < 0 ? "" : "+"}${diff} Days`;
  }
};

// Note: not a pure function
export const timeToDateMs = (hours: number, minutes: number = 0) =>
  addMinutes(startOfDay(new Date()), hours * 60 + minutes).getTime();
