import styled from "@emotion/styled";
import { grid, quickAccessMenu } from "constants/dimensions";

export const Container = styled.div`
  display: flex;
  margin-bottom: 32px;
  margin-left: ${quickAccessMenu.marginLeft}px;
  & > * {
    margin-right: ${grid.column.gap}px;
  }
`;

export const CameraFeedContainer = styled.div(
  ({ focused }: { focused: boolean }) => `
  transform: scale(${focused ? 1.05 : 1});
  transform-origin: center;
  z-index: ${focused ? 1 : "auto"};
`
);
