import { keyListener } from "keyListener";
import { useEffect, useRef } from "react";
import { ButtonPress, SubscribeOptions } from "services/keyListener";

export function useKeys(
  enabled: boolean,
  callback: (buttonPress: ButtonPress) => void,
  options: SubscribeOptions = {}
) {
  const enabledRef = useRef(enabled),
    callbackRef = useRef(callback);

  enabledRef.current = enabled;
  callbackRef.current = callback;

  useEffect(() => {
    const subscription = keyListener.subscribe(buttonPress => {
      if (enabledRef.current) {
        callbackRef.current(buttonPress);
      }
    }, options);
    return function cleanup() {
      subscription.unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}
