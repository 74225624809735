import { useRef, useEffect, useLayoutEffect } from "react";
import { gsap } from "gsap";
import { Animation } from "types/animations";
import { Thenable } from "types";
import { animations, currentTimeline } from "animations";
import { useComponentName } from "hooks/useComponentName";
import { config } from "utils/config";

export type BasicDeps = {
  [name: string]: string | number | boolean | undefined | null;
};

type AnimationCallback<Deps> = (
  deps: Deps,
  timeline: gsap.core.Timeline
) => Thenable;
type AnimationIncDefault = Animation | "defaultAnimation";
type RunningAnimation = { name: AnimationIncDefault };

type Spec<Deps> = {
  animations?: { [name in Animation]?: AnimationCallback<Deps> };
  reset?: (deps: Deps) => void;
  defaultAnimation?: AnimationCallback<Deps>;
  uninterruptible?: AnimationIncDefault[];
  scope?: string;
};

const eventColour = (eventName: string) =>
  eventName === "animate" ? "red" : "blue";

const log = (componentName: string, eventName: string, ...extraArgs: any[]) => {
  if (!config.logAnimations) return;
  console.log(
    `%c${eventName}`,
    `color: ${eventColour(eventName)};`,
    componentName,
    ...extraArgs
  );
};

const uninterruptibleAnimationIsRunning = (
  runningAnims: Set<RunningAnimation>,
  uninterruptibleAnims?: AnimationIncDefault[]
) =>
  !!Array.from(runningAnims).find(({ name }) =>
    uninterruptibleAnims?.includes(name)
  );

export const useAnimations = <Deps extends BasicDeps>(
  spec: Spec<Deps>,
  dependencies?: Deps
) => {
  const componentName = useComponentName();

  const deps = useRef(dependencies);
  deps.current = dependencies;

  const runningAnimations = useRef(new Set<RunningAnimation>());

  const reset = () => {
    // Only actually reset once all animations have finished
    if (runningAnimations.current.size) return;
    if (spec.reset && deps.current) {
      log(componentName, "reset", deps.current);
      spec.reset({ ...deps.current });
    }
  };

  const animate = async (
    name: AnimationIncDefault,
    callback: AnimationCallback<typeof dependencies>,
    parentTimeline: gsap.core.Timeline
  ) => {
    if (
      uninterruptibleAnimationIsRunning(
        runningAnimations.current,
        spec.uninterruptible
      )
    )
      return;
    log(componentName, "animate", name, "\n", deps.current);
    const runningAnimation = { name };
    runningAnimations.current.add(runningAnimation);
    const timeline = gsap.timeline();
    parentTimeline.add(timeline, 0);
    await callback({ ...deps.current }, timeline);
    runningAnimations.current.delete(runningAnimation);
    reset();
  };

  // ------ Call reset on initialize ------ //
  useLayoutEffect(() => {
    reset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // ------ Subscribe to the animations broadcaster (just once on initialize) ------ //
  useLayoutEffect(() => {
    if (!spec.animations) return;

    const subscription = animations.subscribe(({ name, timeline, scope }) => {
      if (spec.scope && scope && spec.scope !== scope) return;
      const callback = spec.animations?.[name];
      callback && animate(name, callback, timeline);
    });

    return function cleanup() {
      subscription.unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // ------ Call defaultAnimation/reset on standard render if deps have changed ------ //
  // useEffect not useLayoutEffect, and on "next tick", so custom animations have a chance to take priority
  const isMounting = useRef(true);
  useEffect(
    () => {
      if (isMounting.current) {
        isMounting.current = false;
      } else {
        setTimeout(() => {
          spec.defaultAnimation
            ? animate(
                "defaultAnimation",
                spec.defaultAnimation,
                currentTimeline() || gsap.timeline()
              )
            : reset();
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    dependencies ? Object.values(dependencies) : []
  );
};
