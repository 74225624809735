import { getPage } from "data/pages";
import { State } from "reducers";
import { GridPageState } from "reducers/browse/pages/grid";
import { GridPage } from "types";

export const gridPageState = ({
  browse: { pageState },
}: State): [GridPage, GridPageState] => {
  if (pageState.pageType !== "gridPage") {
    throw new Error(
      `Expected pageType to be gridPage, not ${pageState.pageId}`
    );
  }
  return [getPage(pageState.pageId, pageState.pageType), pageState];
};

export const selectedTile = (state: State) => {
  const [page, { index }] = gridPageState(state);
  return page.tiles[index];
};

export const selectedIndex = (state: State) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, { index }] = gridPageState(state);
  return index;
};

export const numTilesPerRail = (state: State) => {
  const page = gridPageState(state)[0];
  return tilesPerRail(page);
};

export const tilesPerRail = (page: GridPage): number => {
  switch (page.template) {
    case "tile-cover":
      return 6;
    case "tile-landscape":
    default:
      return 4;
  }
};
