import { DoorbellFeedController } from "components/DoorbellFeed";
import { QAMButtonsController } from "components/QAMButtons";
import { Text } from "components/Text";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useDispatch } from "store";
import { stores } from "stores";
import {
  Container,
  Column,
  Buttons,
  FeedTextContainer,
  LockIcon,
} from "./QAMDoorbell.styles";

type Props = {
  focused: boolean;
  onSelectButton: (id: string) => void;
  doorLocked: boolean;
  quickResponseMode?: boolean;
};

export const QAMDoorbell = ({
  focused,
  onSelectButton,
  doorLocked,
  quickResponseMode,
}: Props) => (
  <Container>
    <Column flex="0 0 512px">
      <DoorbellFeedController />
      <FeedTextContainer>
        <Text
          weight="regular"
          size="medium"
          style={{ position: "absolute", left: 22, bottom: 18 }}
        >
          <LockIcon locked={doorLocked} /> Front door{" "}
          {doorLocked ? "locked" : "unlocked"}
        </Text>
      </FeedTextContainer>
    </Column>
    <Column>
      <Text weight="medium" size="medium">
        Someone is at the front door.
      </Text>
      <Buttons>
        {quickResponseMode ? (
          <QAMButtonsController
            key="qr-buttons"
            focused={focused}
            buttons={[
              { id: "qr/1", title: "Be right there" },
              { id: "qr/2", title: "How can I help?" },
              { id: "qr/3", title: "Leave it at the door" },
              { id: "qr/4", title: "Please leave" },
            ]}
            onSelect={onSelectButton}
          />
        ) : (
          <QAMButtonsController
            key="standard-buttons"
            focused={focused}
            buttons={[
              { id: "quick-response", title: "Quick response" },
              {
                id: "door-lock",
                title: doorLocked ? "Unlock door" : "Lock door",
              },
              { id: "dismiss", title: "Dismiss" },
            ]}
            onSelect={onSelectButton}
          />
        )}
      </Buttons>
    </Column>
  </Container>
);

type ControllerProps = { focused: boolean };

export const QAMDoorbellController = observer(
  ({ focused }: ControllerProps) => {
    const dispatch = useDispatch();
    const [quickResponseMode, setQuickResponseMode] = useState(false);
    const lock = stores.frontDoorLock;

    const handleSelectButton = (id: string) => {
      switch (id) {
        case "quick-response":
          setQuickResponseMode(!quickResponseMode);
          break;
        case "door-lock":
          if (lock.on) {
            dispatch({
              type: "SHOW_TOAST",
              message: `Say "It's me" to unlock your door`,
              status: "listening",
            });
          } else {
            lock.on = true;
          }
          break;
        case "dismiss":
          dispatch({ type: "QUICK_ACCESS_MENU.HIDE" });
          dispatch({ type: "UNPAUSE" });
          break;
        case "qr/1":
        case "qr/2":
        case "qr/3":
        case "qr/4":
          setQuickResponseMode(false);
          break;
        default:
          console.log("Button pressed:", id);
      }
    };

    return (
      <QAMDoorbell
        focused={focused}
        onSelectButton={handleSelectButton}
        doorLocked={lock.on}
        quickResponseMode={quickResponseMode}
      />
    );
  }
);
