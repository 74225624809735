import { NextTile } from "types";

const imageUrl = (path: string) => `assets/images/switcher/${path}`;

export const next: NextTile[] = [
  {
    type: "nextTile",
    tag: "Continue watching",
    title: "The Third Day",
    imageUrl: "assets/images/switcher/TheThirdDay/tile.webp",
    providerImageUrl:
      "https://images.metadata.sky.com/pd-logo/skychb_skyatlantic/408/84",
    hero: {
      title: "The Third Day",
      synopsis:
        "Friday - The Father: Jude Law stars as Sam, who finds himself drawn to a mysterious island. Contains strong language and distressing scenes.",
      heroTitleImageUrl: "assets/images/switcher/TheThirdDay/heroTitle.webp",
      heroImageUrl: "assets/images/switcher/TheThirdDay/hero.webp",
      logoUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_skyatlantic/408/84",
    },
    progress: 20,
  },
  {
    type: "nextTile",
    tag: "New series for you",
    title: "The Fall",
    imageUrl: "assets/images/switcher/TheFall/tile.webp",
    providerImageUrl:
      "https://cdn.skyq.sky.com/uk/epg/live/media/logos/netflix/v1/logo_365x75.png",
    hero: {
      title: "The Fall",
      synopsis:
        "A dectective superintendent battles for her own personal demons as she tries to get inside the head of a serial killer hiding behind a family-man facade.",
      heroTitleImageUrl: "assets/images/switcher/TheFall/heroTitle.webp",
      heroImageUrl: "assets/images/switcher/TheFall/hero.webp",
      logoUrl:
        "https://cdn.skyq.sky.com/uk/epg/live/media/logos/netflix/v1/logo_365x75.png",
    },
  },
  {
    type: "nextTile",
    tag: "Trending",
    title: "Mulan",
    imageUrl: "assets/images/switcher/Mulan/tile.webp",
    providerImageUrl: "assets/images/disney_plus.png",
    hero: {
      title: "Mulan",
      synopsis:
        "To save her ailing father from serving in the Imperial Army, a fearless young woman disguises herself as a man to battle northern invaders in China.",
      heroTitleImageUrl: "assets/images/switcher/Mulan/heroTitle.webp",
      heroImageUrl: "assets/images/switcher/Mulan/hero.webp",
      logoUrl: "assets/images/disney_plus.png",
    },
  },
  {
    type: "nextTile",
    tag: "New Release",
    title: "New Music Friday",
    imageUrl: "assets/images/switcher/NewMusicFriday/tile.webp",
    providerImageUrl: "",
    hero: {
      title: "New Music Friday",
      synopsis: "",
      heroTitleImageUrl: "assets/images/switcher/Spotify/herotitle.png",
      heroImageUrl: "assets/images/switcher/SpotifyAlt/hero.webp",
    },
  },
  {
    type: "nextTile",
    tag: "Live class",
    title: "30 min HIIT Ride",
    imageUrl: "assets/images/switcher/30minHIITRide/tile.webp",
    providerImageUrl: "",
    hero: {
      title: "30 min HIIT Ride",
      synopsis: "Unlimited motivation, delivered daily.",
      heroTitleImageUrl: "assets/images/switcher/Peleton/heroTitle.webp",
      heroImageUrl: "assets/images/switcher/Peleton/hero.webp",
      logoUrl: "",
    },
    progress: 50,
  },
  {
    type: "nextTile",
    tag: "Top Pick",
    title: "NFL",
    imageUrl: "assets/images/switcher/NFL/tile.webp",
    providerImageUrl:
      "https://images.metadata.sky.com/pd-logo/skychb_skyspaction/408/84",
    hero: {
      title: "NFL",
      synopsis:
        "All the latest content from the National Football League, including round-up shows, highlights and features.",
      heroTitleImageUrl: "assets/images/switcher/NFL/heroTitle.webp",
      heroImageUrl: "assets/images/switcher/NFL/hero.webp",
      logoUrl: "",
    },
  },
  {
    type: "nextTile",
    tag: "Continue watching",
    title: "Gangs of London",
    imageUrl: "assets/images/switcher/GangsOfLondon/tile.webp",
    providerImageUrl:
      "https://images.metadata.sky.com/pd-logo/skychb_skyatlantic/408/84",
    hero: {
      title: "Gangs of London",
      synopsis:
        "Created by visionary award-winning filmmaker Gareth Evans and his creative partner Matt Flannery, Gangs of London takes audiences on an immersive journey into the hidden heart of the capital.",
      heroTitleImageUrl: "assets/images/switcher/GangsOfLondon/heroTitle.webp",
      heroImageUrl: "assets/images/switcher/GangsOfLondon/hero.webp",
      logoUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_skyatlantic/408/84",
    },
  },

  // Temp Replacement for Peaky Blinders

  {
    type: "nextTile",
    tag: "Trending now on Sky Cinema",
    title: "The Greatest Showman",
    providerImageUrl:
      "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
    imageUrl:
      "https://images.metadata.sky.com/pd-image/6c19ce32-fea1-47f1-b057-1ff4cb208439/16-9/{width}",
    hero: {
      title: "The Greatest Showman",
      synopsis:
        "Hugh Jackman dons a top hat and tap shoes to play legendary circus entrepreneur PT Barnum. Showstopping musical biopic with Zac Efron. (2017)(101 mins)",
      heroImageUrl:
        "https://images.metadata.sky.com/pd-image/6c19ce32-fea1-47f1-b057-1ff4cb208439/background/{width}",
      heroTitleImageUrl: imageUrl("The_Greatest_Showman/herotitle.png"),
      logoUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
    },
  },

  // Waiting for content
  /*
  {
    type: "nextTile",
    tag: "",
    title: "Peaky Blinders",
    imageUrl: "assets/images/switcher/PeakyBlinders/tile.webp",
    providerImageUrl: "",
    hero: {
      title: "Peaky Blinders",
      synopsis:
        "Tommy Shelby, a dangerous man, leads the Peaky Blinders, a gang based in Birmingham. Soon, Chester Campbell, an inspector, decides to nab him and put an end to the criminal activities.git ad",
      heroTitleImageUrl: "assets/images/switcher/PeakyBlinders/heroTitle.webp",
      heroImageUrl: "assets/images/switcher/PeakyBlinders/hero.webp",
      logoUrl: "",
    },
  },
    */
];
