import React, { useRef } from "react";
import { useSelector } from "react-redux";
import {
  Container,
  TabsContainer,
  GlassFrameContainer,
  Tab,
} from "./BrowseTabs.styles";
import {
  browseTabs,
  browseTabIndex,
  showBrowseTabs,
} from "selectors/browseTabs";
import { focusArea } from "selectors/app";
import { BrowseTab as BrowseTabType } from "types";
import { Text } from "components/Text";
import { boolToInt } from "utils/boolToInt";
import { useDefaultAnimation } from "hooks/useDefaultAnimation";
import { browseTabs as browseTabDims } from "constants/dimensions";
import { horizMaskGradient } from "utils/railHelpers";
import { RailLens } from "components/RailLens";
import { useAnimations } from "hooks/useAnimations";
import { transitions } from "constants/transitions";
import gsap from "gsap";

type Props = {
  tabs: BrowseTabType[];
  index?: number;
  visible: boolean;
  focused: boolean;
};

export const BrowseTabs = ({ tabs, index, visible, focused }: Props) => {
  const container = useRef(null),
    tabsContainer = useRef(null),
    glassFrameContainer = useRef(null),
    focusedDims = {
      x: index * browseTabDims.tab.width,
      y: -(browseTabDims.focus.height - browseTabDims.height) / 2,
      width: browseTabDims.focus.width,
      height: browseTabDims.focus.height,
    };

  useDefaultAnimation(container, {
    opacity: boolToInt(visible),
  });

  useAnimations(
    {
      uninterruptible: ["moveLens"],
      reset: params => gsap.set(tabsContainer.current, params),
      defaultAnimation: (params, timeline) =>
        timeline.to(tabsContainer.current, params),
      animations: {
        moveLens: (params, timeline) =>
          timeline.to(tabsContainer.current, {
            ...params,
            ...transitions.moveLens,
          }),
      },
    },
    {
      backgroundColor: `rgba(241,241,241,${focused ? 0.24 : 0.12})`,
      "-webkit-mask-image": horizMaskGradient(
        focusedDims.x,
        focusedDims.width,
        focused
      ),
    }
  );

  useDefaultAnimation(glassFrameContainer, {
    opacity: boolToInt(focused),
  });

  return (
    <Container ref={container}>
      <TabsContainer ref={tabsContainer}>
        {tabs.map((tab, i) => (
          <Tab key={tab.title} focused={false}>
            <Text
              size="small"
              weight={i === index ? "medium" : "regular"}
              style={{ flex: "1" }}
            >
              {tab.title}
            </Text>
          </Tab>
        ))}
      </TabsContainer>
      <GlassFrameContainer ref={glassFrameContainer}>
        <RailLens
          x={focusedDims.x}
          y={focusedDims.y}
          height={focusedDims.height}
          index={index}
          animationScope="browseTabs"
        >
          {tabs.map(tab => (
            <Tab key={tab.title} focused={true}>
              <Text size="small" weight="medium" style={{ flex: "1" }}>
                {tab.title}
              </Text>
            </Tab>
          ))}
        </RailLens>
      </GlassFrameContainer>
    </Container>
  );
};

export const BrowseTabsController = () => {
  const tabs = useSelector(browseTabs),
    index = useSelector(browseTabIndex),
    visible = useSelector(showBrowseTabs),
    focused = useSelector(focusArea) === "browseTabs";
  return (
    <BrowseTabs tabs={tabs} index={index} visible={visible} focused={focused} />
  );
};
