import { makeAutoObservable } from "mobx";

export class Toggler {
  on: boolean;

  constructor(on: boolean = false) {
    this.on = on;
    makeAutoObservable(this);
  }

  toggle() {
    this.on = !this.on;
  }
}
