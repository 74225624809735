import { videos } from "data/videos";
import { Journey } from "types";

export const lineOfDuty: Journey = {
  id: "lineOfDuty",
  pages: [
    {
      type: "videoPage",
      url: videos.lineOfDuty,
    },
  ],
  title: "Line of Duty",
};
