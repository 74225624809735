import styled from "@emotion/styled";
import { quickAccessMenu } from "constants/dimensions";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin: 0 0 ${quickAccessMenu.marginBottom}px ${quickAccessMenu.marginLeft}px;
`;

export const RailContainer = styled.div``;

export const ItemContainer = styled.div(
  ({ focusedStyle }: { focusedStyle: boolean }) => ({
    marginRight: focusedStyle ? 0 : quickAccessMenu.item.gap,
  })
);
