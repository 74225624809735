import styled from "@emotion/styled";
import { screen } from "constants/dimensions";

export const Container = styled.div`
  width: ${screen.width}px;
  height: ${screen.height}px;
  background: url(assets/images/glance/glance_2.png) no-repeat;
  position: fixed;
  left: 0;
  top: 0;
`;
