import { Rail } from "types";
import { startOfHour, addHours } from "date-fns";

const date = Date.now(),
  past = startOfHour(date).getTime(),
  future = addHours(past, 1).getTime();

export default {
  type: "rail",
  uuid: "6b405dc5-2dd7-4868-b79a-cdec1b209fb1",
  template: "tile-now-next",
  title: "TV Guide",
  tiles: [
    {
      type: "titleTile",
      title: "TV Guide",
      actionText: "View all",
      hero: {
        mode: "standard",
        title: "TV Guide",
        synopsis: "All the best sporting action",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/9c8e9f62-99e4-42cf-9b54-3a703c179729/background/{width}",
      },
    },
    {
      type: "nowAndNextTile",
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_skysports/408/84",
      nowTitle: "Ronaldo",
      nowStart: past,
      nextTitle: "What's My Name: Muhammad Ali",
      nextStart: future,
      progressPercent: 39,
      hero: {
        mode: "standard",
        title: "Ronaldo",
        metadata: "1h 45m   ",
        synopsis:
          "For the first time on Sky Sports, follow the remarkable life and career of Portuguese football icon Cristiano Ronaldo in this candid documentary filmed over the course of 14 months. (2015)",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skysports/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/9c8e9f62-99e4-42cf-9b54-3a703c179729/background/{width}",
        heroTitleImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/119a3175876c1a4668af899261fd1232/herotitle.png",
      },
    },
    {
      type: "nowAndNextTile",
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_skydocumentaries/408/84",
      nowTitle: "Ferrari: Race To Immortality",
      nowStart: past,
      nextTitle: "Everton - Howard's Way",
      nextStart: future,
      progressPercent: 39,
      hero: {
        mode: "standard",
        title: "Ferrari: Race To Immortality",
        metadata: "1h 45m   ",
        synopsis:
          "Formula One documentary chronicling the early years of the Scuderia Ferrari racing team, founded by the legendary Enzo Ferrari. ",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skydocumentaries/408/84",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/3882ad26f79e2757d4d7ad5633ec6164/hero.jpg",
      },
    },
    {
      type: "nowAndNextTile",
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_skysppl/408/84",
      nowTitle: "Life of Ryan: Caretaker Manager",
      nowStart: past,
      nextTitle: "Dan Carter - A Perfect 10",
      nextStart: future,
      progressPercent: 39,
      hero: {
        mode: "standard",
        title: "Life of Ryan: Caretaker Manager",
        metadata: "1h 45m   ",
        synopsis:
          "Documentary following club legend Ryan Giggs as he takes on the manager role at Manchester United following David Moyes's doomed tenure. Strong language/flashing images. (2014)(82 mins)",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skysppl/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/482a82a4-acfd-4888-861f-a0b8978fb0e8/background/{width}",
        heroTitleImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/67399f8f194079236b6c14c3ce26c665/herotitle.png",
      },
    },
    {
      type: "nowAndNextTile",
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_skydocumentaries/408/84",
      nowTitle: "McLaren",
      nowStart: past,
      nextTitle: "Next Goal Wins",
      nextStart: future,
      progressPercent: 39,
      hero: {
        mode: "standard",
        title: "McLaren",
        metadata: "1h 45m   ",
        synopsis:
          "Motorsport documentary celebrating pioneering engineer and driver Bruce McLaren, with contributions from Mario Andretti and Jackie Stewart. ",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skydocumentaries/408/84",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/ca5d428470a65759de9a23df77ca3116/hero.jpg",
      },
    },
    {
      type: "nowAndNextTile",
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_skydocumentaries/408/84",
      nowTitle: "Fire in Babylon",
      nowStart: past,
      nextTitle: "Klitschko",
      nextStart: future,
      progressPercent: 39,
      hero: {
        mode: "standard",
        title: "Fire in Babylon",
        metadata: "1h 45m   ",
        synopsis:
          "British documentary lifting the lid on the record-breaking West Indies cricket team, journeying back to the 1970s where Captain Clive Lloyd transforms them into champions.",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skydocumentaries/408/84",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/82173724081462940cab7a2dca48aeb2/hero.jpg",
      },
    },
  ],
} as Rail;
