import { select, put, animate, takeLatest } from "sagas/effects";
import { FocusArea } from "types";
import { showBrowseTabs } from "selectors/browseTabs";
import {
  switcherIsShowing,
  focusArea as focusAreaSelector,
} from "selectors/app";
import { onHomepage } from "selectors/browse";
import { SpecificAction } from "types/actions";

export function* browseSaga() {
  yield takeLatest("BROWSE_FORWARD", browseForward);
  yield takeLatest("BROWSE_BACK", browseBack);
  yield takeLatest("BROWSE.FOCUS_ON_TV_CATEGORIES", focusOnTvCategories);
  yield takeLatest("BROWSE.FOCUS_OFF_TV_CATEGORIES", focusOffTvCategories);
}

function* browseForward({
  includeInHistory,
  animation,
}: SpecificAction<"BROWSE_FORWARD">) {
  if (yield select(onHomepage)) {
    yield put({ type: "SHOW_SWITCHER" });
  }
  const animationName =
    animation || (includeInHistory ? "browseForward" : "browseFade");
  yield animate(animationName);
}

function* browseBack() {
  yield animate("browseBack");

  // Make sure we don't get stuck focused on browse tabs if they've disappeared
  const focusArea: FocusArea = yield select(focusAreaSelector),
    browseTabsShowing: boolean = yield select(showBrowseTabs);

  if (focusArea === "browseTabs" && !browseTabsShowing) {
    yield put({ type: "FOCUS_ON_BROWSE" });
  }

  // Make sure we focus on switcher if it's visible and we're on browse
  const switcherShowing: boolean = yield select(switcherIsShowing);
  if (focusArea === "browse" && switcherShowing) {
    yield put({ type: "FOCUS_ON_SWITCHER" });
  }
}

function* focusOnTvCategories() {
  yield put({ type: "FOCUS_ON_TV_CATEGORIES" });
  yield animate("openTvCategories");
}

function* focusOffTvCategories() {
  const autoHide = yield select(s => s.settings.autoHideTvCategories);
  yield put({ type: "FOCUS_ON_BROWSE" });
  if (autoHide) {
    yield animate("closeTvCategories");
  }
}
