import { Rail } from "types";

export default {
  type: "rail",
  uuid: "7ac3d433-ef3b-4c25-b14f-ce3f7303fa94",
  template: "tile-cover",
  title: "Must See Movies",
  tiles: [
    {
      type: "coverTile",
      title: "Forrest Gump",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/226c8913-f33e-41ac-90cf-24f1870dc2b5/cover/{height}",
      hero: {
        mode: "standard",
        title: "Forrest Gump",
        metadata: "1h 45m   ",
        synopsis:
          "Tom Hanks stars in Robert Zemeckis's brilliant Oscar-winning tale which follows the eventful life of an extraordinary man. Strong language/mild violence/flashing images. (1994)(137 mins) ",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/226c8913-f33e-41ac-90cf-24f1870dc2b5/background/{width}",
      },
    },
    {
      type: "coverTile",
      title: "The Green Mile",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/b5e5b5c3-2258-40d2-a76b-42e907b00c35/cover/{height}",
      hero: {
        mode: "standard",
        title: "The Green Mile",
        metadata: "1h 45m   ",
        synopsis:
          "Oscar-nominated drama, with Tom Hanks as a Death Row guard drawn to mysterious inmate Michael Clarke Duncan. Strong language/violence/mature themes/flashing images. (1999)(181 mins)",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/b5e5b5c3-2258-40d2-a76b-42e907b00c35/background/{width}",
      },
    },
    {
      type: "coverTile",
      title: "Full Metal Jacket",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/d6f8daa8-a1e4-498c-b9ac-c0d6066c1330/cover/{height}",
      hero: {
        mode: "standard",
        title: "Full Metal Jacket",
        metadata: "1h 45m   ",
        synopsis:
          "Stanley Kubrick's iconic Vietnam drama sees Matthew Modine dehumanised by training then thrown into the horror of war. Strong language/violence. (1987)(111 mins)",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/d6f8daa8-a1e4-498c-b9ac-c0d6066c1330/background/{width}",
      },
    },
    {
      type: "coverTile",
      title: "Jay And Silent Bob Reboot",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/468ee3a6-aff3-4f67-a982-eab45f086a81/cover/{height}",
      hero: {
        mode: "standard",
        title: "Jay And Silent Bob Reboot",
        metadata: "1h 45m   ",
        synopsis:
          "Jay and Silent Bob return once more as they embark on a cross-country mission across the USA, to stop a re-boot of a film they both hated. (2019)(105 mins)",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/468ee3a6-aff3-4f67-a982-eab45f086a81/background/{width}",
      },
    },
    {
      type: "coverTile",
      title: "Argo",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/bbd87712-0882-4afe-99d7-1b205bf67d69/cover/{height}",
      hero: {
        mode: "standard",
        title: "Argo",
        metadata: "1h 45m   ",
        synopsis:
          "Ben Affleck's CIA agent is charged with bringing home six Americans stranded in revolutionary Iran. Oscar-winning thriller. Strong language/violence/flashing images. (2012)(120 mins)",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/bbd87712-0882-4afe-99d7-1b205bf67d69/background/{width}",
      },
    },
    {
      type: "coverTile",
      title: "I Can Only Imagine",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/4a27cc6b-7ae4-43b7-905a-c4f2bfd96efa/cover/{height}",
      hero: {
        mode: "standard",
        title: "I Can Only Imagine",
        metadata: "1h 45m   ",
        synopsis:
          "The inspiring story of singer Bart Millard, who escaped an abusive childhood and went on to write the biggest selling Christian pop song of all time. With Dennis Quaid. (2018)(105 mins)",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/4a27cc6b-7ae4-43b7-905a-c4f2bfd96efa/background/{width}",
      },
    },
    {
      type: "coverTile",
      title: "Snatch",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/b572d8d8-22f4-4234-afa1-2502612aa245/cover/{height}",
      hero: {
        mode: "standard",
        title: "Snatch",
        metadata: "1h 45m   ",
        synopsis:
          "Brad Pitt stars in Guy Ritchie's sizzling East End thriller with bare-knuckle boxing, wheeler-dealers and conmen in pursuit of a stolen jewel. Strong language/sex/violence. (2000)(99 mins)",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/b572d8d8-22f4-4234-afa1-2502612aa245/background/{width}",
      },
    },
    {
      type: "coverTile",
      title: "Ferris Bueller's Day Off",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/0a3461ff-5cba-43f5-9010-7147646eda53/cover/{height}",
      hero: {
        mode: "standard",
        title: "Ferris Bueller's Day Off",
        metadata: "1h 45m   ",
        synopsis:
          "While Matthew Broderick skips school and has the day off to die for, Principal Ed Rooney tries to catch him out. Classic 80s comedy from John Hughes. Strong language. (1986)(92 mins)",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/0a3461ff-5cba-43f5-9010-7147646eda53/background/{width}",
      },
    },
    {
      type: "coverTile",
      title: "Zulu",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/858d246f-c376-485d-9a19-5c72b08c1fbb/cover/{height}",
      hero: {
        mode: "standard",
        title: "Zulu",
        metadata: "1h 45m   ",
        synopsis:
          "A handful of British troops face Zulu attack at Rorke's Drift in 1879. Stirring war classic featuring Michael Caine in his breakthrough role. (1964)(133 mins)",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/858d246f-c376-485d-9a19-5c72b08c1fbb/background/{width}",
      },
    },
    {
      type: "coverTile",
      title: "Star Wars: Episode VI - Return of the Jedi",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/c9ebcf48-b35b-400f-9c68-7abe5f4c70d8/cover/{height}",
      hero: {
        mode: "standard",
        title: "Star Wars: Episode VI - Return of the Jedi",
        metadata: "1h 45m   ",
        synopsis:
          "The Empire and the Rebellion prepare for a final stand-off as Luke confronts Darth Vader. With Mark Hamill, Harrison Ford and the late Carrie Fisher. Flashing images. (1983)(130 mins)",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/c9ebcf48-b35b-400f-9c68-7abe5f4c70d8/background/{width}",
      },
    },
    {
      type: "coverTile",
      title: "Star Wars: Episode IV - A New Hope",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/023f9fd3-80a2-4415-9fe1-02df22049e82/cover/{height}",
      hero: {
        mode: "standard",
        title: "Star Wars: Episode IV - A New Hope",
        metadata: "1h 45m   ",
        synopsis:
          "Luke Skywalker joins forces with Jedi Knight Obi-Wan Kenobi and cocky pilot Han Solo to rescue Princess Leia (the late Carrie Fisher). Iconic sci-fi. Flashing images. (1977)(125 mins)",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/023f9fd3-80a2-4415-9fe1-02df22049e82/background/{width}",
      },
    },
    {
      type: "coverTile",
      title: "The Secret Life of Pets 2",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/73a7e70e-6d06-484e-a98b-1755a55e85dd/cover/{height}",
      hero: {
        mode: "standard",
        title: "The Secret Life of Pets 2",
        metadata: "1h 45m   ",
        synopsis:
          "Sequel to the hit film about the adventures of our pets that we don't see when we leave home. With the voices of Kevin Hart and Harrison Ford. (2019)(90 mins)",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/73a7e70e-6d06-484e-a98b-1755a55e85dd/background/{width}",
      },
    },
    {
      type: "coverTile",
      title: "The Bourne Supremacy",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/443462dd-e33a-4baf-97a4-061b487da68b/cover/{height}",
      hero: {
        mode: "standard",
        title: "The Bourne Supremacy",
        metadata: "1h 45m   ",
        synopsis:
          "Matt Damon returns as the amnesiac assassin, forced to abandon his quiet new life when he's framed for murder. Strong language/violence/flashing images. (2004)(105 mins)",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/443462dd-e33a-4baf-97a4-061b487da68b/background/{width}",
      },
    },
    {
      type: "coverTile",
      title: "Unforgiven",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/66438ca8-ae6c-4703-ab2d-356454b4a6c5/cover/{height}",
      hero: {
        mode: "standard",
        title: "Unforgiven",
        metadata: "1h 45m   ",
        synopsis:
          "Best Picture Oscar-winner with Clint Eastwood as a hired killer-turned-farmer, who is tempted out of retirement by a big bounty. (1992)(125 mins)",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/66438ca8-ae6c-4703-ab2d-356454b4a6c5/background/{width}",
      },
    },
    {
      type: "coverTile",
      title: "Star Wars: Episode V - The Empire Strikes Back",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/56d01716-2e26-4a6e-af27-aed4f8c905be/cover/{height}",
      hero: {
        mode: "standard",
        title: "Star Wars: Episode V - The Empire Strikes Back",
        metadata: "1h 45m   ",
        synopsis:
          "Luke Skywalker travels to a distant planet to train with Jedi master Yoda while Han and Leia (the late Carrie Fisher) are pursued by the Empire. Flashing images. (1980)(122 mins)",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/56d01716-2e26-4a6e-af27-aed4f8c905be/background/{width}",
      },
    },
    {
      type: "coverTile",
      title: "Breakfast at Tiffany's",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/99fcc255-28a0-4314-8130-2dcf124d9043/cover/{height}",
      hero: {
        mode: "standard",
        title: "Breakfast at Tiffany's",
        metadata: "1h 45m   ",
        synopsis:
          "This film has outdated attitudes, language and cultural depictions which may cause offence today. Iconic romance based on Truman Capote's novella with Audrey Hepburn. (1961)(110 mins)",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/99fcc255-28a0-4314-8130-2dcf124d9043/background/{width}",
      },
    },
    {
      type: "coverTile",
      title: "Shaun of the Dead",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/f20f3f35-afd2-4bfd-84c9-e12cca88c3be/cover/{height}",
      hero: {
        mode: "standard",
        title: "Shaun of the Dead",
        metadata: "1h 45m   ",
        synopsis:
          "Life's a yawn for Shaun, until zombies take over London. Then it's a no guts, no glory rescue mission: to the pub! Comedy. Strong language/violence/flashing images. (2004)(96 mins) ",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/f20f3f35-afd2-4bfd-84c9-e12cca88c3be/background/{width}",
      },
    },
    {
      type: "coverTile",
      title: "Do the Right Thing",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/f9a2e7ed-7251-4361-9e90-89556932ebaf/cover/{height}",
      hero: {
        mode: "standard",
        title: "Do the Right Thing",
        metadata: "1h 45m   ",
        synopsis:
          "Incendiary drama from Spike Lee. A row in an Italian pizza parlour pushes racial tension to boiling point in a New York neighbourhood. Strong language/violence. (1989)(119 mins)",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/f9a2e7ed-7251-4361-9e90-89556932ebaf/background/{width}",
      },
    },
    {
      type: "coverTile",
      title: "The Bourne Identity",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/5dde8576-9533-4d50-a061-08a3dea9bf49/cover/{height}",
      hero: {
        mode: "standard",
        title: "The Bourne Identity",
        metadata: "1h 45m   ",
        synopsis:
          "Matt Damon plays a man with amnesia who finds himself at the centre of a manhunt. First-rate action thriller. Strong language/violence/flashing images. (2002)(114 mins)",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/5dde8576-9533-4d50-a061-08a3dea9bf49/background/{width}",
      },
    },
    {
      type: "titleTile",
      actionText: "View all",
      hero: {
        mode: "standard",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/5dde8576-9533-4d50-a061-08a3dea9bf49/background/{width}",
      },
    },
  ],
} as Rail;
