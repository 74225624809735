import React from "react";
import ReactDOM from "react-dom";
import { App } from "components/App";
import * as serviceWorker from "serviceWorker";
import { initializeApp } from "initializeApp";

if (!navigator.userAgent.match(/chrome/i)) {
  alert(
    "This prototype is tested in Chrome only so may not work well with this browser!"
  );
}

ReactDOM.render(<App />, document.getElementById("root"), initializeApp);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
