import { Rail } from "types";

export default {
  type: "rail",
  uuid: "63c609cd-ed5b-4d5d-8eae-0e9e211dd821",
  template: "tile-landscape",
  title: "Entertainment",
  tiles: [
    {
      type: "landscapeTile",
      title: "How to Get Away with Murder",
      imageUrl:
        "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/fef925ef1cca828696984670aed6fdfc/{width}/{height}/item.jpg",
      hero: {
        mode: "standard",
        title: "How to Get Away with Murder",
        metadata: "1h 45m    ",
        synopsis:
          "Another huge mystery unfolds for the Keating 3 in the sixth and final season of the hit drama starring Viola Davis in her Emmy-winning role as attorney Annalise.",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skywitness/408/84",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/b3759f112ea05dc3092efb3a1a0ffdb8/hero.jpg",
        heroTitleImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/68f91c340ae75103202668fdf6903f7b/herotitle.png",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_skywitness/408/84",
    },
    {
      type: "landscapeTile",
      title: "DC's Legends of Tomorrow",
      imageUrl:
        "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/f59f3a1fa2839b4dd05c5d2460786b15/{width}/{height}/item.jpg",
      hero: {
        mode: "standard",
        title: "DC's Legends of Tomorrow",
        metadata: "1h 45m    ",
        synopsis:
          "The Legends return as the superhero hit is back for a fifth season in a special crossover episode that sees the heroes of the Arrowverse unite to save the world from destruction. ",
        logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_skyone/408/84",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/ab814e2ed322bcd96504c27bf33a80ae/hero.jpg",
        heroTitleImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/8f2a37bbffc692f408db3b335cbee1d6/herotitle.png",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_skyone/408/84",
    },
    {
      type: "landscapeTile",
      title: "Why We Hate: The Reckoning",
      imageUrl:
        "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/9e851ef1e8072669a5ea2938ff20a60b/{width}/{height}/item.jpg",
      hero: {
        mode: "standard",
        title: "Why We Hate: The Reckoning",
        metadata: "1h 45m    ",
        synopsis:
          "Tamron Hall hosts an in-depth roundtable conversation about the documentary and the ongoing events from around the globe surrounding George Floyd's death.",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_discovery/408/84",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/d5760813e86ced63a66be3f00beda2b8/hero.jpg",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_discovery/408/84",
    },
    {
      type: "landscapeTile",
      title: "Penny Dreadful: City of Angels",
      imageUrl:
        "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/4f2e4574211fbc15f32b8bf109a467f7/{width}/{height}/item.jpg",
      hero: {
        mode: "standard",
        title: "Penny Dreadful: City of Angels",
        metadata: "1h 45m    ",
        synopsis:
          "When a murder shocks Los Angeles in 1938, Detective Tiago Vega and his partner Lewis Michener become embroiled in an epic story that reflects the rich history of the city. All episodes available.",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skyatlantic/408/84",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/0cecfc20824abeaa11cd82986a6b4601/hero.jpg",
        heroTitleImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/03f8637233de76554cae70d1c78cedf1/herotitle.png",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_skyatlantic/408/84",
    },
    {
      type: "landscapeTile",
      title: "Wu-Tang Clan: Of Mics and Men",
      imageUrl:
        "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/dc4305aa51ee8c80f600074abba3d745/{width}/{height}/item.jpg",
      hero: {
        mode: "standard",
        title: "Wu-Tang Clan: Of Mics and Men",
        metadata: "1h 45m    ",
        synopsis:
          "This four-part docuseries looks back on the group's career and how its founding members overcame violence and poverty to form one of the most recognisable musical movements in the world.",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skydocumentaries/408/84",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/541ca50214ccbcc7cb6b709015682ace/hero.jpg",
        heroTitleImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/d43192f8ff01452e37951f4981545948/herotitle.png",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_skydocumentaries/408/84",
    },
    {
      type: "landscapeTile",
      title: "APB",
      imageUrl:
        "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/2b1bb351bba56df05a592fd580c7252f/{width}/{height}/item.jpg",
      hero: {
        mode: "standard",
        title: "APB",
        metadata: "1h 45m    ",
        synopsis:
          "Crime drama following a billionaire, who, after witnessing a violent crime, purchases a troubled police precinct and reboots it with cutting-edge technology as a private law enforcement agency.",
        logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_fox/408/84",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/8432f0e56445b286ea07207f86953a23/hero.jpg",
        heroTitleImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/b40779e610a40587eb18951a1c50a1be/herotitle.png",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_fox/408/84",
    },
    {
      type: "landscapeTile",
      title: "Secret Wars Uncovered",
      imageUrl:
        "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/666bf895f8a82adcc5c4658d5a8f98ef/{width}/{height}/item.jpg",
      hero: {
        mode: "standard",
        title: "Secret Wars Uncovered",
        metadata: "1h 45m    ",
        synopsis:
          "The shocking facts behind battles that were fought covertly since the Second World War.",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skyhistory/408/84",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/1ea5e07d15ae9b4f03ec49799efd763f/hero.jpg",
        heroTitleImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/837ef410896f82ae565e4635725c9ca1/herotitle.png",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_skyhistory/408/84",
    },
    {
      type: "landscapeTile",
      title: "Alan Bennett's Talking Heads",
      imageUrl:
        "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/c652907df6cc8cb5f6c8a3c58c01dc28/{width}/{height}/item.jpg",
      hero: {
        mode: "standard",
        title: "Alan Bennett's Talking Heads",
        metadata: "1h 45m    ",
        synopsis:
          "Jodie Comer, Martin Freeman and Sarah Lancashire are among the stars in the latest adaptation of Alan Bennett's iconic dramatic monologues. All episodes available.",
        logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_bbcone/408/84",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/68f5b2cc2c665e731bf2f5e484b9e75a/hero.jpg",
        heroTitleImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/525669b0c309b3dd06bdbd58bda51393/herotitle.png",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_bbcone/408/84",
    },
    {
      type: "landscapeTile",
      title: "My Brilliant Friend",
      imageUrl:
        "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/eb302eac04fd03ad3f64e7fab7e576d5/{width}/{height}/item.jpg",
      hero: {
        mode: "standard",
        title: "My Brilliant Friend",
        metadata: "1h 45m    ",
        synopsis:
          "Second season of the Italian drama based on Elena Ferrante's Neapolitan Novels. A writer recounts her decades-long relationship with her best friend. All episodes available.",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skyatlantic/408/84",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/68d3008913c8dbe51bf2b8f50643931c/hero.jpg",
        heroTitleImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/57cc32d36e1f0c884ce03c7957ef7885/herotitle.png",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_skyatlantic/408/84",
    },
  ],
} as Rail;
