import styled from "@emotion/styled";
import { tiles } from "../../constants/dimensions";
import { tileShadow } from "constants/effects";

const { coverTile } = tiles;

export const Container = styled.div(
  ({ focused }: { focused: boolean }) => `
  background-color: rgba(0, 0, 0, 0.4);
  box-shadow: ${tileShadow};
  height: ${coverTile.height}px;
  width: ${coverTile.width}px;
  box-sizing: border-box;
  border-radius: ${focused ? 0 : coverTile.borderRadius}px;
  overflow: hidden;
`
);

export const MediaContainer = styled.div``;

export const Image = styled.img`
  display: block;
  width: 100%;
`;
