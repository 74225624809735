import styled from "@emotion/styled";

export const Container = styled.div`
  position: relative;
`;

export const ItemContainer = styled.div(
  ({ opacity, y }: { opacity: number; y: number }) => `
  position: absolute;
  left: 0;
  top: 0;
  opacity: ${opacity};
  transform: translateY(${y}px);
  transition: all .5s;
  `
);
