import { Rail } from "types";

export default {
  type: "rail",
  uuid: "36151ac5-afd1-4d4b-9a99-2fb31fa113e0",
  template: "tile-landscape",
  title: "LOL!",
  tiles: [
    {
      type: "landscapeTile",
      title: "SpongeBob SquarePants",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/ca133e21-026f-4f3c-9a67-4f489554e31a/16-9/{width}",
      hero: {
        mode: "standard",
        title: "SpongeBob SquarePants",
        metadata: "1h 45m   ",
        synopsis:
          "Who lives in a pineapple under the sea? SpongeBob SquarePants! Follow him and his friends as they get into all sorts of trouble!",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/ca133e21-026f-4f3c-9a67-4f489554e31a/background/{width}",
        heroTitleImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/f40d95cf7712427d7306a3ba3de4ef9c/herotitle.png",
      },
    },
    {
      type: "landscapeTile",
      title: "The Loud House",
      imageUrl:
        "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/b8e185cc0ca48d2f3af6001088251af9/{width}/{height}/item.jpg",
      hero: {
        mode: "standard",
        title: "The Loud House",
        metadata: "1h 45m   ",
        synopsis:
          "Ever wonder what it's like to grow up in a big family? Lincoln Loud tries to survive the chaos of a huge household, especially as he has ten sisters!",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/48fea0eb-1686-4eb9-92e7-88d3d0644c22/background/{width}",
      },
    },
    {
      type: "landscapeTile",
      title: "Morph",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/93ed277c-1f78-4074-acc3-ace5f6adaa2f/16-9/{width}",
      hero: {
        mode: "standard",
        title: "Morph",
        metadata: "1h 45m   ",
        synopsis:
          "Friendship, mischief and cake are at the heart of these brand new seasonally themed mini-adventures for our hero Morph and his best pal Chas.",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/93ed277c-1f78-4074-acc3-ace5f6adaa2f/background/{width}",
      },
    },
    {
      type: "landscapeTile",
      title: "Mr Bean: The Animated Series",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/2219c055-8c2f-4331-99c1-eb3758dbd48b/16-9/{width}",
      hero: {
        mode: "standard",
        title: "Mr Bean: The Animated Series",
        metadata: "1h 45m   ",
        synopsis:
          "Join the strange but hilariously funny Mr Bean and his life-long best friend (a stuffed teddy) on adventures such as avoiding an irate landlady or an evil one-eyed cat.",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/2219c055-8c2f-4331-99c1-eb3758dbd48b/background/{width}",
      },
    },
    {
      type: "landscapeTile",
      title: "Tom and Jerry",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/085e8709-aa09-498a-96ec-5a6beb9b869b/16-9/{width}",
      hero: {
        mode: "standard",
        title: "Tom and Jerry",
        metadata: "1h 45m   ",
        synopsis:
          "More fun and battling adventures with the duelling cat and mouse, Tom and Jerry.\r",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/085e8709-aa09-498a-96ec-5a6beb9b869b/background/{width}",
      },
    },
    {
      type: "landscapeTile",
      title: "Just Roll With It",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/dac3a96d-021a-4d09-83f8-54eed1e26dae/16-9/{width}",
      hero: {
        mode: "standard",
        title: "Just Roll With It",
        metadata: "1h 45m   ",
        synopsis:
          "Despite their differences, the Bennett-Blatt clan and the actors who play them know how to take whatever life, and the studio audience, throws at them. They just roll with it.",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/dac3a96d-021a-4d09-83f8-54eed1e26dae/background/{width}",
      },
    },
    {
      type: "titleTile",
      actionText: "View all",
      hero: {
        mode: "standard",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/dac3a96d-021a-4d09-83f8-54eed1e26dae/background/{width}",
      },
    },
  ],
} as Rail;
