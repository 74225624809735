import { select, takeEvery, put } from "sagas/effects";
import { qamIsOpen } from "selectors/quickAccessMenu";

export function* qamSaga() {
  yield takeEvery("QUICK_ACCESS_MENU.TOGGLE", toggleQAM);
}

function* toggleQAM() {
  if (yield select(qamIsOpen)) {
    yield put({ type: "QUICK_ACCESS_MENU.HIDE" });
  } else {
    yield put({ type: "QUICK_ACCESS_MENU.SHOW", tab: "menu" });
  }
}
