import { PageId } from "types";
import { fork, put, select, takeLatest } from "sagas/effects";
import { onFirstRailAndTile } from "selectors/pages/rails";
import { brieflyShowPageTitleInHero } from "sagas/hero";

export function* initializeRailsPage(pageId: PageId) {
  yield takeLatest(
    ["RAILS.RESET", "RAILS.CHANGE_RAIL", "RAILS.CHANGE_TILE"],
    maybeShowPageHero
  );
  yield fork(brieflyShowPageTitleInHero);
}

function* maybeShowPageHero() {
  if (yield select(onFirstRailAndTile)) {
    yield fork(brieflyShowPageTitleInHero);
  } else {
    yield put({ type: "BROWSE.HIDE_PAGE_TITLE_IN_HERO" });
  }
}
