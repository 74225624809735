const play = (filename: string) => {
  const src = `assets/sounds/${filename}`,
    audio = new Audio(src);
  audio.play();
};

export const sounds = {
  voiceWake() {
    play("voice_wake.wav");
  },
  voiceSuccess() {
    play("voice_success.wav");
  },
  voiceError() {
    //play("voice_error.wav"); // don't have it yet
  },
};
