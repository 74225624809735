import { epg } from "services/epg";
import {
  call,
  put,
  select,
  takeLatest,
  fork,
  throttle,
  take,
  delay,
} from "sagas/effects";
import { programmeHero } from "../../services/heroes";
import {
  selectedDate,
  selectedEvent,
  onNowEvent,
  focusArea,
  channelIndex,
} from "selectors/pages/tvGuide";
import { Hero, PageId } from "../../types";
import { getPage } from "data/pages";
import { differenceInHours } from "date-fns";
import { FocusArea } from "reducers/browse/pages/tvGuide";
import { now } from "selectors/time";
import { pageTitle } from "selectors/pageTitle";
import { formatDay, formatDayDifference } from "utils/timeHelpers";
import { SpecificAction } from "types/actions";

export function* initializeTvGuidePage(pageId: PageId) {
  yield throttle(
    1000,
    [
      "TVGUIDE.NEXT_CHANNEL",
      "TVGUIDE.PREV_CHANNEL",
      "TVGUIDE.NEXT_EVENT",
      "TVGUIDE.PREV_EVENT",
      "TVGUIDE.FOCUS_ON_EPG",
      "TVGUIDE.LOADED_PAGE",
    ],
    loadHero
  );
  yield takeLatest("TVGUIDE.CHANGE_DAY", selectDay);
  yield fork(loadOnNow, pageId);

  const pgTitle = yield select(pageTitle);
  if (pgTitle) {
    yield fork(brieflyShowHero, {
      mode: "hugeTitle",
      title: pgTitle.text,
      minilabel: pgTitle.minilabel,
    });
  }

  const autoHide = yield select(s => s.settings.autoHideTvCategories);
  if (autoHide) {
    yield put({ type: "BROWSE.FOCUS_ON_TV_CATEGORIES" });
  }

  yield fork(watchForHints);
  yield* loadPage(pageId, new Date());
}

function* loadHero() {
  const area: FocusArea = yield select(focusArea);
  const event =
    area === "channels"
      ? yield select(onNowEvent)
      : area === "grid"
      ? yield select(selectedEvent)
      : undefined;
  const hero: Hero = event
    ? yield call(programmeHero, event.programmeId)
    : { mode: "none" };
  yield put({ type: "TVGUIDE.UPDATE_HERO", hero });
}

function* selectDay({ date }: SpecificAction<"TVGUIDE.CHANGE_DAY">) {
  // Show day on the hero
  const dateNow = yield select(now);
  yield fork(brieflyShowHero, {
    mode: "hugeTitle",
    title: formatDay(date, dateNow, true),
    minilabel: formatDayDifference(date, dateNow),
  });

  // Load the new page
  const pageId = yield select(s => s.browse.pageState.pageId);
  yield* loadPage(pageId, date);
}

function* loadPage(pageId: PageId, date: Date) {
  yield put({ type: "TVGUIDE.LOADING" });
  const page = getPage(pageId, "tvGuidePage");
  const schedule = yield call(epg.schedule, date, page.channelIds);
  yield put({
    type: "TVGUIDE.LOADED_PAGE",
    schedule,
    date,
  });
}

function* loadOnNow(pageId: PageId) {
  const page = getPage(pageId, "tvGuidePage");
  const eventLookup = yield call(epg.onNow, page.channelIds);
  yield put({ type: "TVGUIDE.ON_NOW_LOADED", eventLookup });
}

function* watchForHints() {
  yield fork(watchForUpDownHint);
  yield fork(watchForLeftRightHint);
}

function* watchForUpDownHint() {
  let shownHint = false;
  while (!shownHint) {
    yield take("TVGUIDE.NEXT_CHANNEL");
    const index = yield select(channelIndex);
    if (index >= 9) {
      yield put({
        type: "SHOW_TOAST",
        message: "Press up or down for two seconds to move between genres",
      });
      shownHint = true;
    }
  }
}

function* watchForLeftRightHint() {
  let shownHint = false;
  yield take("TVGUIDE.LOADED_PAGE"); // wait for "now" to be set
  const nowDate = yield select(selectedDate);
  while (!shownHint) {
    yield take("TVGUIDE.NEXT_EVENT");
    const date = yield select(selectedDate);
    if (differenceInHours(date, nowDate) >= 6) {
      yield put({
        type: "SHOW_TOAST",
        message:
          "Press left or right for two seconds to move forward/back 24 hours",
      });
      shownHint = true;
    }
  }
}

function* brieflyShowHero(heroFields: Partial<Hero>) {
  yield put({ type: "TVGUIDE.UPDATE_OVERRIDE_HERO_FIELDS", heroFields });
  yield delay(4000);
  yield put({
    type: "TVGUIDE.UPDATE_OVERRIDE_HERO_FIELDS",
    heroFields: undefined,
  });
}
