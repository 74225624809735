import { NavStep } from "types";
import { animate, put, select, take } from "sagas/effects";
import {
  focusArea,
  onFirstChannel,
  onLastChannel,
  selectedEvent,
  previousSectionPageId,
  nextSectionPageId,
  onFirstTile,
} from "selectors/pages/tvGuide";
import { tvDaysIsShowing } from "selectors/browse";
import { now } from "selectors/time";
import { isAfter } from "date-fns";

export function* tvGuidePageNavigate(step: NavStep) {
  // Overlay navigation
  if (yield select(tvDaysIsShowing)) {
    return;
  }

  // Common navigations
  switch (step) {
    case "BACK":
      yield put({ type: "BROWSE_BACK" });
      return;
  }

  switch (yield select(focusArea)) {
    case "rail":
      yield* railNavigate(step);
      break;
    case "channels":
      yield* channelsNavigate(step);
      break;
    case "grid":
      yield* gridNavigate(step);
      break;
  }
}

function* goToPrevSection({
  goToLastChannel,
}: { goToLastChannel?: boolean } = {}) {
  const pageId = yield select(previousSectionPageId);
  if (pageId) {
    yield put({
      type: "BROWSE_FORWARD",
      pageType: "tvGuidePage",
      pageId,
      includeInHistory: false,
      animation: "browsePageUp",
    });
    if (goToLastChannel) {
      yield put({ type: "TVGUIDE.FOCUS_ON_EPG" });
      yield take("TVGUIDE.LOADED_PAGE");
      yield put({ type: "TVGUIDE.GO_TO_LAST_CHANNEL" });
    }
  }
}

function* goToNextSection() {
  const pageId = yield select(nextSectionPageId);
  pageId &&
    (yield put({
      type: "BROWSE_FORWARD",
      pageType: "tvGuidePage",
      pageId,
      includeInHistory: false,
      animation: "browsePageDown",
    }));
}

function* railNavigate(step: NavStep) {
  switch (step) {
    case "DOWN":
      yield put({ type: "TVGUIDE.FOCUS_ON_EPG" });
      break;
    case "UP":
      yield* goToPrevSection({ goToLastChannel: true });
      break;
    case "RIGHT":
      yield put({ type: "TVGUIDE.NEXT_TILE" });
      yield animate("moveLens", "0");
      break;
    case "LEFT":
      if (yield select(onFirstTile)) {
        yield put({ type: "BROWSE.FOCUS_ON_TV_CATEGORIES" });
      } else {
        yield put({ type: "TVGUIDE.PREV_TILE" });
        yield animate("moveLens", "0");
      }
      break;
  }
}

function* channelsNavigate(step: NavStep) {
  switch (step) {
    case "UP":
      if (yield select(onFirstChannel)) {
        yield put({ type: "TVGUIDE.FOCUS_ON_RAIL" });
      } else {
        yield put({ type: "TVGUIDE.PREV_CHANNEL" });
        yield animate("tvGuideScrollUp");
      }
      break;
    case "DOWN":
      if (yield select(onLastChannel)) {
        yield* goToNextSection();
      } else {
        yield put({ type: "TVGUIDE.NEXT_CHANNEL" });
        yield animate("tvGuideScrollDown");
      }
      break;
    case "RIGHT":
      yield put({ type: "TVGUIDE.SET_FOCUS_AREA", area: "grid" });
      break;
    case "LEFT":
      yield put({ type: "BROWSE.FOCUS_ON_TV_CATEGORIES" });
      break;
    case "LONG_LEFT":
    case "LONG_RIGHT":
      yield put({ type: "BROWSE.SHOW_TV_DAYS" });
      return;
  }
}

function* gridNavigate(step: NavStep) {
  switch (step) {
    case "UP":
      if (yield select(onFirstChannel)) {
        yield put({ type: "TVGUIDE.FOCUS_ON_RAIL" });
      } else {
        yield put({ type: "TVGUIDE.PREV_CHANNEL" });
        yield animate("tvGuideScrollUp");
      }
      break;
    case "DOWN":
      if (yield select(onLastChannel)) {
        yield* goToNextSection();
      } else {
        yield put({ type: "TVGUIDE.NEXT_CHANNEL" });
        yield animate("tvGuideScrollDown");
      }
      break;
    case "LEFT": {
      const dateNow = yield select(now),
        { startMs } = yield select(selectedEvent);
      if (isAfter(dateNow, startMs)) {
        yield put({ type: "TVGUIDE.SET_FOCUS_AREA", area: "channels" });
      } else {
        yield put({ type: "TVGUIDE.PREV_EVENT" });
        yield animate("tvGuideScrollHorizontally");
      }
      break;
    }
    case "RIGHT":
      yield put({ type: "TVGUIDE.NEXT_EVENT" });
      yield animate("tvGuideScrollHorizontally");
      break;
    case "LONG_LEFT":
    case "LONG_RIGHT":
      yield put({ type: "BROWSE.SHOW_TV_DAYS" });
      return;
  }
}
