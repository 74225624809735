import { put, takeLatest } from "sagas/effects";
import { RailsPageId, railsPageIds } from "types";
import { SpecificAction } from "types/actions";

export function* entrypointsSaga() {
  yield takeLatest("ENTRYPOINT.AUTOHIDE_TV_CATEGORIES", autohideTvCategories);
  yield takeLatest("ENTRYPOINT.START_ON_RAIL", startOnRail);
}

function* autohideTvCategories({
  on,
}: SpecificAction<"ENTRYPOINT.AUTOHIDE_TV_CATEGORIES">) {
  yield put({
    type: "SETTINGS.UPDATE",
    settings: { autoHideTvCategories: on },
  });
}

function* startOnRail({
  pageId,
  railIndex,
}: SpecificAction<"ENTRYPOINT.START_ON_RAIL">) {
  if (!railsPageIds.includes(pageId as RailsPageId)) return;
  yield put({ type: "FOCUS_ON_BROWSE" });
  yield put({
    type: "GO_TO_PAGE",
    pageType: "railsPage",
    pageId: pageId as RailsPageId,
  });
  yield put({ type: "RAILS.CHANGE_RAIL", index: railIndex });
}
