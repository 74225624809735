import styled from "@emotion/styled";
import { cameraFeed } from "constants/dimensions";

export const Container = styled.div(
  ({ small }: { small?: boolean }) => `
  background: black;
  width: ${small ? cameraFeed.small.width : cameraFeed.large.width}px;
  height: ${small ? cameraFeed.small.height : cameraFeed.large.height}px;
  border-radius: ${cameraFeed.borderRadius}px;
  overflow: hidden;
`
);

export const VideoBox = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: ${cameraFeed.borderRadius}px;
`;
