import range from "lodash/range";

export function normaliseIndex(idx: number, numItems: number) {
  return ((idx % numItems) + numItems) % numItems;
}

export const rollingList = (
  originalLength: number,
  index: number,
  numItems: number = originalLength
) => range(numItems).map(i => normaliseIndex(index + i, originalLength));
