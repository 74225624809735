import styled from "@emotion/styled";
import { cameraFeed, quickAccessMenu } from "constants/dimensions";

export const Container = styled.div`
  height: 500px;
  display: flex;
  margin: 0 0 ${quickAccessMenu.marginBottom}px ${quickAccessMenu.marginLeft}px;
`;

export const Column = styled.div(
  ({ flex }: { flex?: string }) => `
  flex: ${flex || "auto"};
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;
`
);

export const Buttons = styled.div`
  margin: 42px 0 15px;
`;

export const FeedTextContainer = styled.div`
  width: ${cameraFeed.large.width}px;
  height: ${cameraFeed.large.height}px;
  background: url(assets/images/tile-gradient-16-9.png);
  background-size: cover;
  position: absolute;
  left: 0;
  bottom: 0;
`;

export const LockIcon = styled.div(
  ({ locked }: { locked: boolean }) => `
  background: url(assets/images/quickAccessMenu/${
    locked ? "locked.png" : "unlocked.png"
  }) no-repeat 100% 50%;
  width: 21px;
  height: 24px;
  display: inline-block;
  margin-right: 10px;
`
);
