import { Observable, Subscriber } from "rxjs";
import axios from "axios";
import {
  connect,
  createLocalTracks,
  LocalDataTrack,
  LocalVideoTrack,
  Room,
  Track,
} from "twilio-video";

import StreamManager from "./StreamManager";
import { StreamsPayload, TwilioRxEvent } from "../../types/twiliorx";

class TwilioRx {
  private _roomId: string;
  private _userId: string;
  private _observable: Observable<TwilioRxEvent>;
  private _localTracks: Track[] = [];
  private _localDataTrack: LocalDataTrack | null = null;
  private _localVideoTrack: LocalVideoTrack | null = null;
  private _room: Room | null = null;
  private _roomToken: string = "";
  private _observer: Subscriber<TwilioRxEvent>;
  private _streamManager: StreamManager = new StreamManager();

  connectToRoom(
    roomId: string,
    userId: string,
    isVideoEnabled: boolean = true,
    isAudioEnabled: boolean = true,
    isDataEnabled: boolean = true
  ): Observable<TwilioRxEvent> {
    this._roomId = roomId;
    this._userId = userId;
    this._observable = new Observable(observer => {
      this.performConnection(
        observer,
        roomId,
        userId,
        isVideoEnabled,
        isAudioEnabled,
        isDataEnabled
      );
    });
    return this._observable;
  }

  disconnect() {
    if (this._room) {
      this._room.disconnect();
      this._room.off("participantConnected", this.handleParticipantConnected);
      this._room.off(
        "participantDisconnected",
        this.handleParticipantDisconnected
      );
    }
    this._room = null;
    this._streamManager.destroy();

    if (this._observer) {
      this._observer.next({
        type: "room disconnected",
        payload: { liveTracks: { video: [], audio: [], data: [] } },
      });
      this._observer.complete();
    }
    this._observer = null;
  }

  sendData(message: string) {
    if (this._localDataTrack) {
      this._localDataTrack.send(message);
      console.log("Sent message", message);
    }
  }

  get localVideoTrack() {
    return this._localVideoTrack;
  }

  async performConnection(
    observer: Subscriber<TwilioRxEvent>,
    roomId: string,
    userId: string,
    isVideoEnabled: boolean,
    isAudioEnabled: boolean,
    isDataEnabled: boolean
  ) {
    try {
      this._observer = observer;
      this._streamManager.observer = observer;

      this._roomToken = await TwilioRx.acquireToken(roomId, userId);
      observer.next({ type: "token acquired", payload: this._roomToken });

      await this.createTracks({
        useAudio: isAudioEnabled,
        useVideo: isVideoEnabled,
        useData: isDataEnabled,
      });

      observer.next({
        type: "local tracks created",
        payload: this._localTracks,
      });

      this._room = await connect(this._roomToken, {
        name: roomId,
        tracks: this._localTracks,
      });
      observer.next({ type: "connected to room", payload: this._room });

      this._room.participants.forEach(participant => {
        this._streamManager.addParticipant(participant);
      });

      this._room.on("participantConnected", this.handleParticipantConnected);
      this._room.on(
        "participantDisconnected",
        this.handleParticipantDisconnected
      );
    } catch (e) {
      // TODO Complete
    }
  }

  handleParticipantConnected = participant => {
    console.log(`Participant connected: ${participant.identity}`);
    this._observer.next({ type: "participant joined", payload: participant });
    this._streamManager.addParticipant(participant);
  };

  handleParticipantDisconnected = participant => {
    console.log(`Participant disconnected: ${participant.identity}`);
    this._streamManager.removeParticipant(participant);
  };

  async createTracks({
    useAudio: isAudioEnabled,
    useVideo: isVideoEnabled,
    useData: isDataEnabled,
  }) {
    this._localTracks = await createLocalTracks({
      audio: isAudioEnabled,
      video: isVideoEnabled ? { width: 640 } : false,
    });

    this._localVideoTrack = this._localTracks.find(
      track => track.kind === "video"
    );

    if (isDataEnabled) {
      this._localDataTrack = new LocalDataTrack();
      this._localTracks.push(this._localDataTrack);
    }
  }

  public static async acquireToken(
    roomId: string,
    userId: string
  ): Promise<string | null> {
    return axios({
      method: "post",
      url: process.env.REACT_APP_TWILIO_TOKEN_URL,
      data: {
        token: "1234567890",
        roomId: roomId,
        userId: userId,
      },
    })
      .then(response => {
        return response.data.jwtToken;
      })
      .catch(err => {
        console.log("Error fetching twilio token", err);
        return null;
      });
  }

  public get liveTracks(): StreamsPayload {
    return this._streamManager.liveTracks;
  }
}

export default TwilioRx;
