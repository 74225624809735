import { PageId } from "types";

export type ImagePageState = Readonly<{
  pageType: "imagePage";
  pageId: PageId;
}>;

export const newPageState = (pageId: PageId): ImagePageState => ({
  pageType: "imagePage",
  pageId,
});
