import React, { useEffect, useRef } from "react";
import { Container, VideoBox } from "./DoorbellFeed.styles";
import { RemoteVideoTrack, RemoteAudioTrack } from "twilio-video";

import DoorbellService from "../../services/DoorbellService";

const VideoHolder: React.FC<{
  track: RemoteVideoTrack;
  width: number | string;
  height: number | string;
}> = ({ track, width = 300, height = 150 }) => {
  const videoRef = useRef(null);
  useEffect(() => {
    if (track && videoRef.current) {
      track.attach(videoRef.current);
      const video = videoRef.current;
      video.style.width = width;
      video.style.height = height;
      video.autoPlay = true;
      video.playsInline = true;
      video.muted = true;
    }
  }, [videoRef, track, width, height]);
  return <VideoBox ref={videoRef} autoPlay muted playsInline />;
};

const AudioHolder: React.FC<{ track: RemoteAudioTrack }> = ({ track }) => {
  const audioRef = useRef(null);

  useEffect(() => {
    if (track && audioRef) {
      track.attach(audioRef.current);
    }
  }, [audioRef, track]);
  return <audio ref={audioRef.current} autoPlay />;
};

type Props = {
  small?: boolean;
  videoTrack: RemoteVideoTrack;
  audioTrack: RemoteAudioTrack;
};

export const DoorbellFeed = ({ small, videoTrack, audioTrack }: Props) => {
  return (
    <Container small={small}>
      <AudioHolder track={audioTrack} />
      <VideoHolder track={videoTrack} width="100%" height="100%" />
    </Container>
  );
};

type ControllerProps = {};

export const DoorbellFeedController = (props: ControllerProps) => {
  // Connect to state, keys, etc. here
  return (
    <DoorbellFeed
      videoTrack={DoorbellService.instance.remoteVideoTrack}
      audioTrack={DoorbellService.instance.remoteAudioTrack}
      {...props}
    />
  );
};
