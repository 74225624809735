import { State } from "reducers";
import { store } from "store";

export const onStoreChange = <T>(
  selector: (state: State) => T,
  onChange: (value: T, previousValue: T) => void
) => {
  let previousValue: T = selector(store.getState());
  store.subscribe(() => {
    const value = selector(store.getState());
    if (value !== previousValue) {
      onChange(value, previousValue);
    }
    previousValue = value;
  });
};
