import React from "react";
import { Container, Icon, Detail } from "./SimpleQAMItem.styles";
import { Text } from "components/Text";
import { Action } from "types/actions";
import { useKeys } from "hooks/useKeys";
import { useDispatch } from "store";

type Props = {
  title: string;
  iconUrl: string;
  focusedStyle: boolean;
};

export const SimpleQAMItem = ({ title, iconUrl, focusedStyle }: Props) => (
  <Container focusedStyle={focusedStyle}>
    <Icon url={iconUrl} />
    <Detail>
      <Text size="medium" weight="medium">
        {title}
      </Text>
    </Detail>
  </Container>
);

type ControllerProps = Props & { focused: boolean; action?: Action };

export const SimpleQAMItemController = ({
  focused,
  action,
  ...props
}: ControllerProps) => {
  const dispatch = useDispatch();
  useKeys(focused, ({ button }) => {
    switch (button) {
      case "SELECT":
        action && dispatch(action);
        break;
    }
  });

  return <SimpleQAMItem {...props} />;
};
