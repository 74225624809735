import { makeAutoObservable } from "mobx";
import { normaliseIndex } from "utils/rollingList";

export class CyclicSelector<Item> {
  index: number;
  items: Item[];

  constructor(items: Item[], index: number = 0) {
    this.items = items;
    this.index = index;
    makeAutoObservable(this);
  }

  get item() {
    return this.items[normaliseIndex(this.index, this.items.length)];
  }

  setIndex(index: number) {
    this.index = index;
  }

  prev() {
    this.index--;
  }

  next() {
    this.index++;
  }
}
