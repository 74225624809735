import { JourneyId, Journey, Hero } from "types";
import { capture } from "data/journeys/capture";
import { coast } from "data/journeys/coast";
import { landlord } from "data/journeys/landlord";
import { lineOfDuty } from "data/journeys/lineOfDuty";
import { netflix } from "data/journeys/netflix";
import { outOfBlue } from "data/journeys/outOfBlue";
import { greatestShowman } from "data/journeys/greatestShowman";
import { skynews } from "data/journeys/skynews";

type SwitcherTileFields = {
  imageUrl: string;
  tag: string;
  title?: string;
  providerImageUrl?: string;
  hero: Partial<Hero>;
};

const JOURNEYS = {
  capture,
  coast,
  landlord,
  lineOfDuty,
  netflix,
  outOfBlue,
  greatestShowman,
  skynews,
};

const JOURNEY_TILE_FIELDS: { [key in JourneyId]: SwitcherTileFields } = {
  capture: {
    title: "The Capture",
    tag: "Continue Watching",
    imageUrl: "assets/images/content/capture/the_capture.jpg",
    providerImageUrl:
      "https://images.metadata.sky.com/pd-logo/skychb_bbc1/408/84",
    hero: {
      title: "The Capture",
      synopsis:
        "This six-part mystery thriller follows Shaun Emery, a British soldier convicted of committing murder in Afghanistan, whose conviction is overturned due to problems with the video evidence that was crucial at his trial.",
      heroImageUrl:
        "https://images.metadata.sky.com/pd-image/87f4493d-247e-4a0e-a502-f0b31f4ddeaa/background/{width}",
      logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_bbc1/408/84",
    },
  },
  coast: {
    title: "BBC2",
    tag: "Recent Channel",
    imageUrl: "assets/images/channelsLandscape/bbc2.png",
    providerImageUrl:
      "https://images.metadata.sky.com/pd-logo/skychb_bbctwo/408/84",
    hero: {
      title: "BBC2",
      synopsis:
        "Get the latest TV listings from 600+ channels on Sky's TV Guide. Check daily recommendations, watch videos and Remote Record your favourite shows.",
      logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_bbctwo/408/84",
    },
  },
  greatestShowman: {
    imageUrl: "assets/images/channelsLandscape/SkyCinema.jpg",
    tag: "Recent App",
    hero: {
      title: "Sky Cinema",
      synopsis:
        "With a new premiere every day, get over 1,000 movies on demand with Sky Cinema.",
      heroImageUrl: "assets/images/channelsLandscape/SkyCinema-hero.png",
      heroTitleImageUrl:
        "assets/images/channelsLandscape/SkyCinema-herotitle.png",
      logoUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
    },
  },
  landlord: {
    title: "The Reluctant Landlord",
    tag: "Continue Watching",
    imageUrl:
      "https://images.metadata.sky.com/pd-image/4b6bc808-1bf1-4ab5-b5f5-3846cd694c05/16-9/{width}",
    providerImageUrl:
      "https://images.metadata.sky.com/pd-logo/skychb_skyone/408/84",
    hero: {
      title: "The Reluctant Landlord",
      synopsis:
        "Romesh quickly learns pulling pints is not for him when he has to take over his late dad's pub. What he wants is to be a hip-hop DJ and no amount of emotional blackmail from his wife Natasha and his kids will see him give this new venture his all.",
      heroImageUrl:
        "https://images.metadata.sky.com/pd-image/4b6bc808-1bf1-4ab5-b5f5-3846cd694c05/background/{width}",
      logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_skyone/408/84",
    },
  },
  lineOfDuty: {
    title: "BBC One",
    tag: "Recent Channel",
    imageUrl: "assets/images/channelsLandscape/bbc_one.jpg",
    hero: {
      mode: "standard",
      title: "BBC One",
      synopsis:
        "Enjoy watching live or on demand TV, from live news coverage, music and big sport events to great comedies, gripping documentaries and nail-biting dramas.",
      heroImageUrl: "assets/images/channelsLandscape/bbc_one-hero.jpg",
      logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_bbc1/408/84",
    },
  },
  netflix: {
    imageUrl: "assets/images/appsLandscape/Netflix.png",
    tag: "Recent App",
    hero: {
      title: "Netflix",
      synopsis:
        "Watch Netflix movies & TV shows online or stream right to your smart TV, game console, PC, Mac, mobile, tablet and more.",
      heroImageUrl: "assets/images/appsLandscape/Netflix-hero.png",
      heroTitleImageUrl: "assets/images/appsLandscape/Netflix-herotitle.png",
      logoUrl:
        "https://cdn.skyq.sky.com/uk/epg/live/media/logos/netflix/v1/logo_365x75.png",
    },
  },
  outOfBlue: {
    title: "Out Of Blue",
    tag: "Continue Watching",
    imageUrl: "./assets/images/content/out-of-blue/Out-of-blue.jpg",
    providerImageUrl:
      "https://images.metadata.sky.com/pd-logo/skychb_bbc/408/84",
    hero: {
      title: "Out Of Blue",
      synopsis:
        "Mike Hoolihan is an unconventional New Orleans cop investigating the murder of renowned astrophysicist Jennifer Rockwell, a black hole expert found shot to death in her observatory. As Mike tumbles down the rabbit hole of the disturbing case, she finds herself grappling with increasingly existential questions of quantum mechanics, parallel universes, and exploding stars - cosmic secrets that may hold the key to unraveling the crime, while throwing into doubt her very understanding of reality.",
      heroImageUrl: "./assets/images/content/out-of-blue/Out-of-blue-hero.jpg",
      logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_bbc/408/84",
    },
  },
  skynews: {
    tag: "Recent Channel",
    imageUrl:
      "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/3ee545b646ccf9051a9a4d563b8dc7c6/900/507/item.jpg",
    hero: {
      mode: "standard",
      title: "Sky News",
      synopsis: "Bringing you the latest breaking news stories and videos.",
      heroImageUrl:
        "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/a8ca4da68de5d79a192f5cc4661c2d88/hero.jpg",
    },
  },
};

export const getJourney = (id: JourneyId): Journey => JOURNEYS[id];

export const getTileFields = (id: JourneyId): SwitcherTileFields =>
  JOURNEY_TILE_FIELDS[id];

export const getHero = (id: JourneyId): Partial<Hero> => getTileFields(id).hero;
