import styled from "@emotion/styled";
import { tiles } from "constants/dimensions";
import { tilePale } from "constants/colours";
import { tileShadow } from "constants/effects";

const { titleTile } = tiles;

export const Container = styled.div(
  ({ focused }: { focused: boolean }) => `
  background-color: ${tilePale};
  background-position: bottom 24px right 24px;
  background-repeat: no-repeat;
  box-shadow: ${tileShadow};
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 24px;
  position: relative;
  width: ${titleTile.width}px;
  box-sizing: border-box;
  border-radius: ${focused ? 0 : titleTile.borderRadius}px;
  overflow: hidden;
`
);
