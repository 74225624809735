import { Rail } from "types";
import { startOfHour, addHours } from "date-fns";

const date = Date.now(),
  past = startOfHour(date).getTime(),
  future = addHours(past, 1).getTime();

export default {
  type: "rail",
  uuid: "1b405dc5-2dd7-4868-b79a-cdec1b209fb1",
  template: "tile-now-next",
  title: "TV Guide",
  tiles: [
    {
      type: "titleTile",
      title: "TV Guide",
      actionText: "View all",
      hero: {
        mode: "standard",
        title: "TV Guide",
        synopsis: "Explore the shows you know and love",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/1f3a655b23c48d7a37dae37d723f25fd/hero.jpg",
      },
    },
    {
      type: "nowAndNextTile",
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
      nowTitle: "The Lion King",
      nowStart: past,
      nextTitle: "Godzilla",
      nextStart: future,
      progressPercent: 47,
      hero: {
        mode: "standard",
        title: "The Lion King",
        metadata: "1h 45m   ",
        synopsis:
          "Jon Favreau directs this retelling of the classic tale of a lion prince (Donald Glover) hoping to follow in his father's footsteps. With Beyonce. (2019)(114 mins)",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/1f3a655b23c48d7a37dae37d723f25fd/hero.jpg",
        heroTitleImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/d771f88d52d47272b73abdc41871738a/herotitle.png",
      },
    },
    {
      type: "nowAndNextTile",
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
      nowTitle: "Songbird",
      nowStart: past,
      nextTitle: "The Operative",
      nextStart: future,
      progressPercent: 47,
      hero: {
        mode: "standard",
        title: "Songbird",
        metadata: "1h 45m   ",
        synopsis:
          "A fading rock star enrols in university in a bid to find a fresh start when her once-popular Britpop band breaks up and her relationship falls apart. With Cobie Smulders. (2018)(95 mins)",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/ee24f753-53b2-4589-910d-7fb2e4a13923/background/{width}",
      },
    },
    {
      type: "nowAndNextTile",
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
      nowTitle: "Killerman",
      nowStart: past,
      nextTitle: "The Wolf's Call",
      nextStart: future,
      progressPercent: 47,
      hero: {
        mode: "standard",
        title: "Killerman",
        metadata: "1h 45m   ",
        synopsis:
          "When a money launderer wakes up with millions in stolen cash and drugs, and no memory of how he got it, he sets out to find answers. But a crew of dirty cops are after him. (2019)(112 mins)",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/cedafe2f-251a-401c-97e6-031e7573b892/background/{width}",
      },
    },
    {
      type: "nowAndNextTile",
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
      nowTitle: "Tolkein",
      nowStart: past,
      nextTitle: "Hearts and Bones",
      nextStart: future,
      progressPercent: 47,
      hero: {
        mode: "standard",
        title: "Tolkien",
        metadata: "1h 45m   ",
        synopsis:
          "Drama set in the early years of author JRR Tolkien (Nicholas Hoult), as he finds friendship, love and inspiration among fellow outcasts at school. (2019)(108 mins)",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/f0716055-d546-495a-acda-97c4ca4df5a0/background/{width}",
        heroTitleImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/106124a3af60ed568b44d1bf90f5f654/herotitle.png",
      },
    },
    {
      type: "nowAndNextTile",
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
      nowTitle: "Trial by Fire",
      nowStart: past,
      nextTitle: "Sweetness in the Belly",
      nextStart: future,
      progressPercent: 47,
      hero: {
        mode: "standard",
        title: "Trial by Fire",
        metadata: "1h 45m   ",
        synopsis:
          "Drama about Cameron Todd Willingham, executed for killing his children despite evidence seemingly showing his innocence.  (2018)(122 mins)",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/1c11237a-e71c-40b4-be9b-cd40a52ccaac/background/{width}",
      },
    },
    {
      type: "titleTile",
      actionText: "View all",
      hero: {
        mode: "standard",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/1c11237a-e71c-40b4-be9b-cd40a52ccaac/background/{width}",
      },
    },
  ],
} as Rail;
