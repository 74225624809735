import React, { FormEvent, useState } from "react";
import { Container } from "./RemoteControlForm.styles";
import { Form } from "../RoomForm/RoomForm.styles";
import QRCode from "../QRCode/QRCode";

type Props = {
  remoteIdCallback: Function;
  initialRemoteId: string;
  status: string;
  remoteControlUrl: string;
  isConnectEnabled: boolean;
  connectCallback: Function;
  disconnectCallback: Function;
};

export const RemoteControlForm = ({
  remoteIdCallback,
  initialRemoteId,
  status,
  remoteControlUrl,
  isConnectEnabled,
  connectCallback,
  disconnectCallback,
}: Props) => {
  const [remoteId, setRemoteId] = useState(initialRemoteId);

  function handleSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();
  }

  function handleChangeRemoteId(e) {
    if (isConnectEnabled) {
      const newRemoteId: string = e.currentTarget.value;
      setRemoteId(newRemoteId);
      if (typeof remoteIdCallback === "function") {
        remoteIdCallback(newRemoteId);
      }
    }
  }

  function handleDisconnectButton() {
    console.log("Disconnect");
    if (typeof disconnectCallback === "function") {
      disconnectCallback();
    }
  }

  function handleConnectButton() {
    console.log("Connect");
    if (typeof connectCallback === "function") {
      connectCallback();
    }
  }

  return (
    <Container>
      <Form onSubmit={handleSubmit}>
        <label>Room ID:</label>
        <input type="text" value={remoteId} onChange={handleChangeRemoteId} />
        <button
          type={"submit"}
          disabled={!isConnectEnabled}
          onClick={handleConnectButton}
        >
          Connect
        </button>
        <button
          type={"button"}
          disabled={isConnectEnabled}
          onClick={handleDisconnectButton}
        >
          Disconnect
        </button>
        <p>Status: {status}</p>
        <p>
          <a href={remoteControlUrl} target="_blank" rel="noopener noreferrer">
            {remoteControlUrl}
          </a>
        </p>
        <QRCode url={remoteControlUrl} />
      </Form>
    </Container>
  );
};
