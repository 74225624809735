import { useKeys } from "hooks/useKeys";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useDispatch } from "store";
import { ButtonsContainer, Container } from "./QAMCameras.styles";
import { QAMButtonsController } from "components/QAMButtons";
import { QAMLiveFeedsController } from "components/QAMLiveFeeds";
import { QAMEventReelController } from "components/QAMEventReel";
import { format } from "date-fns";
import { useSelector } from "react-redux";
import { now } from "selectors/time";

type Tab = "event-reel" | "live-feeds";
type FocusArea = "buttons" | "tab";
type Props = {
  focused: boolean;
  tab: Tab;
  onSelectButton: (id: string) => void;
  focusArea: FocusArea;
  date: Date;
};

const renderTab = (tab: Tab, focused: boolean, date: Date) => {
  switch (tab) {
    case "event-reel":
      return (
        <QAMEventReelController
          focused={focused}
          title={`Event reel - ${format(date, "do MMMM")}`}
        />
      );
    case "live-feeds":
      return <QAMLiveFeedsController focused={focused} />;
  }
};

export const QAMCameras = ({
  focused,
  tab,
  onSelectButton,
  focusArea,
  date,
}: Props) => (
  <Container>
    {renderTab(tab, focused && focusArea === "tab", date)}
    <ButtonsContainer>
      <QAMButtonsController
        buttons={[
          { id: "event-reel", title: "Event reel" },
          { id: "live-feeds", title: "Live feeds" },
          { id: "events-by-day", title: "Events by day" },
        ]}
        focused={focused && focusArea === "buttons"}
        onSelect={onSelectButton}
      />
    </ButtonsContainer>
  </Container>
);

// ------------------------------------------------------------------------

type ControllerProps = { focused: boolean };

export const QAMCamerasController = observer(({ focused }: ControllerProps) => {
  const dispatch = useDispatch();

  const [tab, setTab] = useState<Tab>("event-reel");
  const [focusArea, setFocusArea] = useState<FocusArea>("buttons");

  const date = useSelector(now);

  const handleSelectButton = (id: string) => {
    switch (id) {
      case "event-reel":
        setTab("event-reel");
        break;
      case "live-feeds":
        setTab("live-feeds");
        break;
      case "events-by-day":
        dispatch({
          type: "GO_TO_PAGE",
          pageType: "imagePage",
          pageId: "cameraEventsByDay",
        });
        break;
    }
  };

  useKeys(focused, ({ button }) => {
    switch (button) {
      case "BACK":
        if (focusArea === "buttons") {
          dispatch({ type: "QUICK_ACCESS_MENU.HIDE" });
        }
        break;
      case "UP":
        setFocusArea("tab");
        break;
      case "DOWN":
        setFocusArea("buttons");
        break;
    }
  });

  return (
    <QAMCameras
      focused={focused}
      tab={tab}
      onSelectButton={handleSelectButton}
      focusArea={focusArea}
      date={date}
    />
  );
});
