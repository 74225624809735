import { Rail } from "types";

export default {
  type: "rail",
  uuid: "ce34480d-b9df-43c5-a0fb-12aad1e2abb1",
  template: "tile-square",
  title: "Channels",
  tiles: [
    {
      type: "squareTile",
      title: "BBC",
      imageUrl: "assets/images/channels/BBC_One.png",
      action: { type: "GO_TO_PAGE", pageType: "railsPage", pageId: "bbcone" },
      hero: {
        mode: "standard",
        title: "BBC One",
        synopsis:
          "Enjoy watching live or on demand TV, from live news coverage, music and big sport events to great comedies, gripping documentaries and nail-biting dramas.",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/30924283-4fad-4cdd-b6fb-e7d07ea0284f/background/{width}",
      },
    },
    {
      type: "squareTile",
      title: "BBC Two",
      imageUrl: "assets/images/channels/BBC_Two.png",
      hero: {
        mode: "standard",
        title: "BBC Two",
        synopsis:
          "Enjoy watching live or on demand TV, from live news coverage, music and big sport events to great comedies, gripping documentaries and nail-biting dramas.",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/2f30c794-7dff-4d98-b461-85c318b6aca5/background/1428",
      },
    },
    {
      type: "squareTile",
      title: "BBC Three",
      imageUrl: "assets/images/channels/BBC_Three.png",
      hero: {
        mode: "standard",
        title: "BBC Three",
        synopsis:
          "Enjoy watching live or on demand TV, from live news coverage, music and big sport events to great comedies, gripping documentaries and nail-biting dramas.",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/da91539e-9905-4b78-8de5-51269dc54ab3/background/1428",
      },
    },
    {
      type: "squareTile",
      title: "BBC Four",
      imageUrl: "assets/images/channels/BBC_Four.png",
      hero: {
        mode: "standard",
        title: "BBC Four",
        synopsis:
          "Enjoy watching live or on demand TV, from live news coverage, music and big sport events to great comedies, gripping documentaries and nail-biting dramas.",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/9724243f-1a3a-4f36-ae98-7a107d012234/background/1428",
      },
    },
    {
      type: "squareTile",
      title: "CBBC",
      imageUrl: "assets/images/channels/CBBC.png",
      hero: {
        mode: "standard",
        title: "CBBC",
        synopsis:
          "Enjoy watching live or on demand TV, from live news coverage, music and big sport events to great comedies, gripping documentaries and nail-biting dramas.",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/3e9c0bbd-9d64-48bb-ac2c-bef687402dbc/background/1428",
      },
    },
    {
      type: "squareTile",
      title: "CBeebies",
      imageUrl: "assets/images/channels/Cbeebies.png",
      hero: {
        mode: "standard",
        title: "CBeebies",
        synopsis:
          "Enjoy watching live or on demand TV, from live news coverage, music and big sport events to great comedies, gripping documentaries and nail-biting dramas.",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/8112921a-e19b-4762-92e5-c37345192dfe/background/1428",
      },
    },
    {
      type: "squareTile",
      title: "BBC Radio 1",
      imageUrl: "assets/images/channels/BBC_Radio_1.png",
      hero: {
        mode: "standard",
        title: "BBC Radio 1",
        synopsis:
          "Enjoy watching live or on demand TV, from live news coverage, music and big sport events to great comedies, gripping documentaries and nail-biting dramas.",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/a14d38d7-cf97-4612-a55c-ed410cb48c30/background/1428",
      },
    },
  ],
} as Rail;
