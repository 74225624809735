import styled from "@emotion/styled";
import { cameraFeed } from "constants/dimensions";

export const Container = styled.div(
  ({ small, fullscreen }: { small?: boolean; fullscreen?: boolean }) => `
  width: ${small ? cameraFeed.small.width : cameraFeed.large.width}px;
  height: ${small ? cameraFeed.small.height : cameraFeed.large.height}px;
  border-radius: ${fullscreen ? 0 : cameraFeed.borderRadius}px;
  overflow: hidden;
  background: black;
  transform-origin: left top;
  position: relative;
  z-index: ${fullscreen ? 1 : "auto"};
`
);

export const TextContainer = styled.div`
  width: 100%;
  height: 100%;
  background: url(assets/images/tile-gradient-16-9.png);
  background-size: cover;
  position: absolute;
  left: 0;
  top: 0;
`;
