import React, { CSSProperties, ReactNode } from "react";
import { Container, ItemContainer } from "./InfiniteList.styles";
import { rollingList } from "../../utils/rollingList";

type Props = {
  index: number;
  originalLength: number;
  windowLength: number;
  renderItem: (index: number) => ReactNode;
  itemHeight: number;
  style?: CSSProperties;
};

const itemOpacity = (idx: number, numItems: number, focusedIdx: number = 2) => {
  // The outer ones should be invisible so we don't see them move top <--> bottom
  switch (idx) {
    case 0:
    case numItems - 1:
      return 0;
    default: {
      const dist = Math.abs(idx - focusedIdx);
      return 1 / (1 + dist);
    }
  }
};

export const InfiniteList = ({
  index,
  originalLength,
  windowLength,
  renderItem,
  itemHeight,
  style,
}: Props) => {
  const rolling = rollingList(originalLength, index - 2, windowLength);
  return (
    <Container style={style}>
      {rolling.map((originalIndex, idx) => (
        <ItemContainer
          key={idx + index}
          y={(idx - 1) * itemHeight}
          opacity={itemOpacity(idx, rolling.length)}
        >
          {renderItem(originalIndex)}
        </ItemContainer>
      ))}
    </Container>
  );
};
