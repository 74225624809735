import styled from "@emotion/styled";
import { grid } from "constants/dimensions";
import { boolToInt } from "utils/boolToInt";

export const Container = styled.div`
  transform-origin: top left;
`;

export const Row = styled.div`
  align-items: stretch;
  display: flex;
  transform-origin: top left;
`;

export const StyledItem = styled.div(({ focused }: { focused: boolean }) => ({
  marginRight: focused ? 0 : grid.column.gap,
}));

export const LensContainer = styled.div(
  ({ visible }: { visible: boolean }) => ({ opacity: boolToInt(visible) })
);
