import { videos } from "data/videos";
import { Journey } from "types";

export const outOfBlue: Journey = {
  id: "outOfBlue",
  pages: [
    {
      type: "videoPage",
      url: videos.outOfBlue,
    },
  ],
  title: "Out Of Blue",
};
