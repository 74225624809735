import { Action } from "types/actions";
import { next as nextData } from "data/next";
import { RecentTile, OnNowTile, NextTile } from "types";
import {
  numSwitcherItems,
  addToRecent,
  removeFromRecent,
  createOnNow,
} from "./helpers";
import { getJourney } from "data/journeys";
import { initialRecentTiles } from "data/recent";

export type SwitcherState = Readonly<{
  recentTiles: RecentTile[];
  onNow?: OnNowTile;
  nextTiles: NextTile[];
  focusIndex: number;
  heroTitleMode: boolean;
}>;

export const initialSwitcherState = {
  recentTiles: initialRecentTiles,
  onNow: undefined,
  nextTiles: nextData,
  focusIndex: initialRecentTiles.length,
  heroTitleMode: true,
};

export const switcherReducer = (
  state: SwitcherState = initialSwitcherState,
  action: Action
) => {
  switch (action.type) {
    case "JOURNEY.START": {
      const recentTiles = removeFromRecent(
        addToRecent(state.recentTiles, state.onNow),
        action.journeyId
      );
      return {
        ...state,
        recentTiles,
        onNow: createOnNow(action.journeyId, action.pageIndex),
        focusIndex: recentTiles.length,
      };
    }

    case "JOURNEY.STOP": {
      const recentTiles = addToRecent(state.recentTiles, state.onNow);
      return {
        ...state,
        recentTiles,
        onNow: undefined,
        focusIndex: action.focusOnRecent
          ? recentTiles.length - 1
          : recentTiles.length,
      };
    }

    case "JOURNEY.FORWARD": {
      const { onNow } = state;
      if (!onNow) return state;
      const journey = getJourney(onNow.journeyId);
      if (onNow.pageIndex === journey.pages.length - 1) return state;

      return {
        ...state,
        onNow: {
          ...onNow,
          pageIndex: onNow.pageIndex + 1,
        },
      };
    }

    case "JOURNEY.BACK": {
      const { onNow } = state;
      if (!onNow || onNow.pageIndex === 0) {
        return state;
      } else {
        return {
          ...state,
          onNow: {
            ...onNow,
            pageIndex: onNow.pageIndex - 1,
          },
        };
      }
    }

    case "SWITCHER_RESET":
    case "HIDE_SWITCHER":
      return {
        ...state,
        focusIndex: state.recentTiles.length,
        heroTitleMode: false,
      };

    case "PREV_SWITCHER_ITEM": {
      const index = state.focusIndex;
      if (index === 0) {
        return state;
      } else {
        return {
          ...state,
          focusIndex: index - 1,
          heroTitleMode: true,
        };
      }
    }

    case "NEXT_SWITCHER_ITEM": {
      const index = state.focusIndex;
      if (index === numSwitcherItems(state) - 1) {
        return state;
      } else {
        return {
          ...state,
          focusIndex: index + 1,
          heroTitleMode: true,
        };
      }
    }

    case "SHOW_MORE_HERO_INFO":
      return { ...state, heroTitleMode: false };
    default:
      return state;
  }
};
