import styled from "@emotion/styled";
import { onNowTile, screen } from "constants/dimensions";
import { tileShadow } from "constants/effects";

export const Container = styled.div(
  ({ focused, fullscreen }: { focused: boolean; fullscreen: boolean }) => `
  width: ${onNowTile.width}px;
  height: ${onNowTile.height}px;
  background-color: rgba(0, 0, 0, 0.4);
  box-shadow: ${tileShadow};
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  position: relative;
  box-sizing: border-box;
  border-radius: ${focused || fullscreen ? 0 : onNowTile.borderRadius}px;
  overflow: hidden;
`
);

export const MediaContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  transform: scale(${onNowTile.height / screen.height});
  transform-origin: top left;
`;

export const ContentContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 24px;
  position: relative;
`;

export const SwitcherTileContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
`;
