import React, { useLayoutEffect, useRef, useState } from "react";
import ReactPlayer from "react-player";
import { applyScaleTransform, paramsForTransform } from "utils/2dHelpers";
import { Container, TextContainer } from "./CameraFeed.styles";
import { screen } from "constants/dimensions";
import { useAnimations } from "hooks/useAnimations";
import gsap from "gsap";
import { Text } from "components/Text";
import { stores } from "stores";
import { observer } from "mobx-react-lite";

type Props = {
  url: string;
  previewUrl?: string;
  small?: boolean;
  fullscreen?: boolean;
  title?: string;
  appScale?: number;
};

const noTransform = {
  x: 0,
  y: 0,
  scale: 1,
};

export const CameraFeed = ({
  url,
  previewUrl,
  small,
  fullscreen,
  title,
  appScale,
}: Props) => {
  previewUrl = previewUrl || url;
  appScale = appScale ?? 1;
  const container = useRef(null),
    [fullscreenTransform, setFullscreenTransform] = useState(null);

  useLayoutEffect(() => {
    const unscaledDims = applyScaleTransform(
      container.current.getBoundingClientRect(),
      1 / appScale
    );
    setFullscreenTransform(
      paramsForTransform(unscaledDims, { x: 0, y: 0, width: screen.width })
    );
  }, [appScale]);

  useAnimations(
    {
      scope: url,
      animations: {
        cameraFeedZoomIn: (params, timeline) =>
          timeline.to(container.current, params),
        cameraFeedZoomOut: (params, timeline) =>
          timeline.to(container.current, params),
      },
      reset: params => gsap.set(container.current, params),
    },
    fullscreen ? fullscreenTransform || noTransform : noTransform
  );

  return (
    <Container small={small} fullscreen={fullscreen} ref={container}>
      <ReactPlayer
        url={fullscreen ? url : previewUrl}
        playing={true}
        loop={true}
        width="100%"
        height="100%"
        muted={!fullscreen}
      />
      {title && (
        <TextContainer>
          <Text
            weight="regular"
            size="medium"
            style={{ position: "absolute", left: 22, bottom: 18 }}
          >
            {title}
          </Text>
        </TextContainer>
      )}
    </Container>
  );
};

type ControllerProps = Props;

export const CameraFeedController = observer((props: ControllerProps) => {
  return <CameraFeed {...props} appScale={stores.appScale.get()} />;
});
