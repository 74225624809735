import { Fade } from "components/Fade";
import React, { useRef, ReactNode } from "react";
import { Container, Box, CloseButton } from "./Modal.styles";

export type Props = {
  open: boolean;
  children: ReactNode;
  onClose?: () => void;
};

export const Modal = ({ open, children, onClose }: Props) => {
  const container = useRef(null);
  return (
    <Fade
      visible={open}
      render={() => (
        <Container
          onClick={evt => evt.target === container.current && open && onClose()}
          ref={container}
        >
          <Box>
            {children}
            <CloseButton onClick={onClose}></CloseButton>
          </Box>
        </Container>
      )}
    />
  );
};
