import { Rail } from "types";

export default {
  type: "rail",
  uuid: "351c8797-y97c-4293-a036-100b565d6093",
  template: "tile-category",
  tiles: [
    {
      type: "categoryTile",
      title: "A-Z",
      hero: {
        mode: "titleArt",
        heroImageUrl: "assets/images/settings/settings.jpg",
      },
    },
    {
      type: "categoryTile",
      title: "Scheduled",
      hero: {
        mode: "titleArt",
        heroImageUrl: "assets/images/settings/settings.jpg",
      },
    },
    {
      type: "categoryTile",
      title: "Disk Space",
      hero: {
        mode: "titleArt",
        heroImageUrl: "assets/images/settings/settings.jpg",
      },
    },
    {
      type: "categoryTile",
      title: "Downloading",
      hero: {
        mode: "titleArt",
        heroImageUrl: "assets/images/settings/settings.jpg",
      },
    },
  ],
} as Rail;
