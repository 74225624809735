import styled from "@emotion/styled";
import { screen, toast } from "constants/dimensions";

export const Container = styled.div`
  align-items: normal;
  display: flex;
  left: 0;
  position: absolute;
  top: 0;
  width: ${screen.width}px;
  height: ${screen.height}px;
`;

export const Logo = styled.img`
  position: absolute;
  top: 52px;
  left: 94px;
`;

export const Background = styled.div(
  ({ overlayMode }: { overlayMode: boolean }) => ({
    backgroundImage: overlayMode
      ? "url(assets/images/toast/overlay_shadow.png)"
      : "linear-gradient(0deg, rgba(0,0,0,0.00) 0%, rgba(8,9,15,0.50) 35%, rgba(8,9,15,0.90) 82%)",
    width: "100%",
    height: overlayMode ? screen.height : 300,
    position: "absolute",
    top: 0,
    left: 0,
  })
);

export const StatusBar = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: left;
`;

export const TextContainer = styled.div({
  position: "absolute",
  left: toast.textX,
  top: 66,
});
