import { TvGuidePage } from "types";
import rail from "./rail";
import { addStartedAtSublines } from "data/pages/helpers";

export default {
  type: "tvGuidePage",
  channelIds: [
    "2002", // BBC ONE
    "2006", // BBC TWO
    "6000", // ITV
    "1621", // Channel 4
    "1800", // Channel 5
    "1402", // Sky One
    "2201", // Sky Witness
    "1412", // Sky Atlantic
    "2617", // W
    "2304", // Gold
    "2306", // Dave
    "2510", // Comedy Central
    "1177", // Sky Comedy
    "1127", // Sky Documentaries
    "2018", // BBC Four
  ],
  rail: addStartedAtSublines(rail, new Date()),
  title: {
    text: "TV Shows",
    minilabel: "TV Guide",
  },
} as TvGuidePage;
