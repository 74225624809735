import { Rail } from "types";

export default {
  type: "rail",
  uuid: "cc73ab94-00e5-4b2d-baf4-f13a94f4b7e6",
  template: "tile-cover",
  title: "Sky Cinema Editor's Picks",
  brandId: "skycinema",
  tiles: [
    {
      type: "titleTile",
      title: "Sky Cinema Editor's Picks",
      action: {
        type: "GO_TO_PAGE",
        pageType: "railsPage",
        pageId: "skyCinema",
        brandId: "skycinema",
      },
      hero: {
        mode: "standard",
        title: "Sky Cinema",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/9d4980c6-ed1c-4d40-9a26-59ef72b80ac6/background/1428",
        synopsis: "A brand new premiere every day.",
      },
    },
    {
      type: "coverTile",
      title: "Spider-Man: Far from Home",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/9d4980c6-ed1c-4d40-9a26-59ef72b80ac6/cover/{height}",
      hero: {
        mode: "standard",
        title: "Spider-Man: Far from Home",
        synopsis:
          "Tom Holland stars as the web-slinging teenager, whose holiday is cut short when Europe comes under attack. Epic superhero sequel. (2019)(129 mins) Also in HD",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/9d4980c6-ed1c-4d40-9a26-59ef72b80ac6/background/1428",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
        metadata: "1h 45m   ",
      },
    },
    {
      type: "coverTile",
      title: "Jaws",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/c23312f7-eb18-4422-b2a7-57c915c236a3/cover/{height}",
      hero: {
        mode: "standard",
        title: "Jaws",
        synopsis:
          "Police chief Roy Scheider hunts the killer shark menacing his peaceful seaside community. Spielberg's classic thriller. Moderate language/violent scenes/flashing images. (1975)(120 mins)",
        heroTitleImageUrl: "assets/images/content/jaws/jaws_hero_title.png",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/c23312f7-eb18-4422-b2a7-57c915c236a3/background/1428",
        metadata: "1h 45m   ",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
      },
    },
    {
      type: "coverTile",
      title: "The Big Lebowski",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/4cb16b0e-4bc7-492f-8c5e-754ee6042e6d/cover/{height}",
      hero: {
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
        mode: "standard",
        title: "The Big Lebowski",
        synopsis:
          "Cult comedy from the Coen brothers, starring Jeff Bridges as an ageing hippy who finds himself embroiled in a rich man's shady dealings. Strong language/sexual references. (1998)(113 mins)",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/4cb16b0e-4bc7-492f-8c5e-754ee6042e6d/background/1428",
        metadata: "1h 45m   ",
      },
    },
    {
      type: "coverTile",
      title: "Edge of Tomorrow",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/1997dcfc-47cc-4525-8410-1847ae96e20b/cover/{height}",
      hero: {
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
        mode: "standard",
        title: "Edge of Tomorrow",
        synopsis:
          "...Live. Die. Repeat: When aliens invade Earth, army officer Tom Cruise gets stuck in a time-loop that sees him doing battle with them again and again. Sci-fi action. (2014)(109 mins)",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/1997dcfc-47cc-4525-8410-1847ae96e20b/background/1428",
        metadata: "1h 45m   ",
      },
    },
    {
      type: "coverTile",
      title: "The Prestige",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/da6e2b9e-5c58-4a81-bab0-20b98dd6ee2e/cover/{height}",
      hero: {
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
        mode: "standard",
        title: "The Prestige",
        synopsis:
          "Christian Bale is a magician with an impossible illusion, Hugh Jackman the rival who can't work out the trick. With Scarlett Johansson. Flashing images. (2006)(126 mins)",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/da6e2b9e-5c58-4a81-bab0-20b98dd6ee2e/background/1428",
        metadata: "1h 45m   ",
      },
    },
    {
      type: "coverTile",
      title: "Die Hard",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/ebdf635d-997d-4eff-b576-ca37a67304a7/cover/{height}",
      hero: {
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
        mode: "standard",
        title: "Die Hard",
        synopsis:
          "A tough New York cop (Bruce Willis) must come to the rescue when terrorists take over an LA skyscraper. With Alan Rickman. Strong language/violence/flashing images. (1988)(126 mins)",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/ebdf635d-997d-4eff-b576-ca37a67304a7/background/1428",
        metadata: "1h 45m   ",
      },
    },
    {
      type: "coverTile",
      title: "Saving Private Ryan",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/89a529b2-76ae-4e72-a31b-709a06d70ba6/cover/{height}",
      hero: {
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
        mode: "standard",
        title: "Saving Private Ryan",
        synopsis:
          "Steven Spielberg's Oscar-winner stars Tom Hanks as the hardened veteran leading a platoon on a WWII rescue mission. Strong language/violence.  (1998)(165 mins)",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/89a529b2-76ae-4e72-a31b-709a06d70ba6/background/1428",
        metadata: "1h 45m   ",
      },
    },
    {
      type: "coverTile",
      title: "Almost Famous",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/194dd4d3-fa97-4b2a-baa5-fc912ee56a1c/cover/{height}",
      hero: {
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
        mode: "standard",
        title: "Almost Famous",
        synopsis:
          "Extended edition of the Oscar-winning comedy-drama following a wannabe journalist who heads on tour with a rising rock band. Kate Hudson and Billy Crudup star. (2000)(155 mins)",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/194dd4d3-fa97-4b2a-baa5-fc912ee56a1c/background/1428",
        metadata: "1h 45m   ",
      },
    },
    {
      type: "coverTile",
      title: "Batman Begins",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/0e9ddfb1-8f0c-4dcc-962f-e002dbeeb232/cover/{height}",
      hero: {
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
        mode: "standard",
        title: "Batman Begins",
        synopsis:
          "A billionaire playboy begins a one-man crusade to clean up Gotham City's streets. Christian Bale stars. (2005)(135 mins)",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/0e9ddfb1-8f0c-4dcc-962f-e002dbeeb232/background/1428",
        metadata: "1h 45m   ",
      },
    },
    {
      type: "coverTile",
      title: "I Can Only Imagine",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/4a27cc6b-7ae4-43b7-905a-c4f2bfd96efa/cover/{height}",
      hero: {
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
        mode: "standard",
        title: "I Can Only Imagine",
        synopsis:
          "The inspiring story of singer Bart Millard, who escaped an abusive childhood and went on to write the biggest selling Christian pop song of all time. With Dennis Quaid. (2018)(105 mins)",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/4a27cc6b-7ae4-43b7-905a-c4f2bfd96efa/background/1428",
        metadata: "1h 45m   ",
      },
    },
    {
      type: "coverTile",
      title: "Django Unchained",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/6bef4cd9-62bf-4ccd-afb6-4d2541be283b/cover/{height}",
      hero: {
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
        mode: "standard",
        title: "Django Unchained",
        synopsis:
          "Quentin Tarantino's Oscar-winning western stars Jamie Foxx as a freed slave on a quest to find his wife. Strong language/violence/nudity/mature themes/flashing images. (2012)(166 mins)",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/6bef4cd9-62bf-4ccd-afb6-4d2541be283b/background/1428",
        metadata: "1h 45m   ",
      },
    },
    {
      type: "titleTile",
      actionText: "View all",
      action: {
        type: "GO_TO_PAGE",
        pageType: "railsPage",
        pageId: "skyCinema",
        brandId: "skycinema",
      },
      hero: {
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
        mode: "titleArt",
        title: "Django Unchained",
        synopsis:
          "Quentin Tarantino's Oscar-winning western stars Jamie Foxx as a freed slave on a quest to find his wife. Strong language/violence/nudity/mature themes/flashing images. (2012)(166 mins)",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/6bef4cd9-62bf-4ccd-afb6-4d2541be283b/background/1428",
        metadata: "1h 45m   ",
      },
    },
  ],
} as Rail;
