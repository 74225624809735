import React, { useRef } from "react";
import { Container } from "./QuickAccessMenu.styles";
import { useDefaultAnimation } from "hooks/useDefaultAnimation";
import { screen, quickAccessMenu } from "constants/dimensions";
import { QAMMenuController } from "components/QAMMenu";
import { useSelector } from "store";
import { qamIsOpen, qamTab } from "selectors/quickAccessMenu";
import { observer } from "mobx-react-lite";
import { QAMTab } from "types";
import { QAMCamerasController } from "components/QAMCameras";
import { QAMDoorbellController } from "components/QAMDoorbell";
import { QAMLandingController } from "components/QAMLanding";
import { QAMDoorbellCannedController } from "components/QAMDoorbellCanned";

type Props = { visible: boolean; tab: QAMTab };

function renderTab(tab: QAMTab, focused: boolean) {
  switch (tab) {
    case "menu":
      return <QAMMenuController focused={focused} />;
    case "cameras":
      return <QAMCamerasController focused={focused} />;
    case "doorbell":
      return <QAMDoorbellController focused={focused} />;
    case "doorbellCanned":
      return <QAMDoorbellCannedController focused={focused} />;
    case "landing":
      return <QAMLandingController focused={focused} />;
  }
}

export const QuickAccessMenu = ({ visible, tab }: Props) => {
  const containerRef = useRef(null);
  useDefaultAnimation(containerRef, {
    y: visible ? screen.height - quickAccessMenu.height : screen.height,
  });

  return (
    <Container ref={containerRef} id="quick-access-menu">
      {renderTab(tab, visible)}
    </Container>
  );
};

export const QuickAccessMenuController = observer(() => {
  const visible = useSelector(qamIsOpen);
  const tab = useSelector(qamTab);
  return <QuickAccessMenu visible={visible} tab={tab} />;
});
