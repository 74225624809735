import { Observable } from "rxjs";
import { makeAutoObservable } from "mobx";
import { RemoteVideoTrack, RemoteAudioTrack } from "twilio-video";

import TwilioRx from "./TwilioHelpers/TwilioRx";
import { generateChars } from "../utils/twiliohelpers";
import { TwilioRxEvent } from "../types/twiliorx";

class DoorbellService {
  private static _instance: DoorbellService | null = null;
  private _twilioRx = new TwilioRx();
  private _roomId: string = "Room-" + generateChars(4, false);
  private _userId: string = "User-" + generateChars(8);
  private _status: string = "disconnected";
  private _observable: Observable<TwilioRxEvent>;
  private _doorbellRingCallback: Function = null;
  private _isConnectEnabled = true;

  constructor() {
    makeAutoObservable(this);
  }

  static get instance() {
    if (!DoorbellService._instance) {
      DoorbellService._instance = new DoorbellService();
    }
    return DoorbellService._instance;
  }

  get twilioRx(): TwilioRx {
    return this._twilioRx;
  }

  connect() {
    this._isConnectEnabled = false;
    this._observable = this._twilioRx.connectToRoom(this._roomId, this._userId);
    this._observable.subscribe(event => {
      console.log("Twilio Events", event);
      switch (event.type) {
        case "connected to room":
          this.status = "Connected";
          break;
        case "participant joined":
          this.status = "Doorbell Connected";
          break;
        case "incoming data":
          if (event.payload.data === "DOOR BELL!!") {
            this._doorbellRingCallback();
          }
          break;
        case "room disconnected":
          this.status = "Disconnected";
          break;
      }
    });
  }

  disconnect() {
    this._isConnectEnabled = true;
    this._twilioRx.disconnect();
  }

  get roomId() {
    return this._roomId;
  }

  set roomId(id) {
    this._roomId = id;
  }

  get status() {
    return this._status;
  }

  set status(value) {
    this._status = value;
  }

  get remoteVideoTrack(): RemoteVideoTrack {
    const { video } = this.twilioRx.liveTracks;
    if (video.length > 0) {
      return video[0];
    } else {
      return null;
    }
  }

  get remoteAudioTrack(): RemoteAudioTrack {
    const { audio } = this._twilioRx.liveTracks;
    if (audio.length > 0) {
      return audio[0];
    } else {
      return null;
    }
  }

  set doorbellRingCallback(value: Function) {
    this._doorbellRingCallback = value;
  }

  get isConnectEnabled(): boolean {
    return this._isConnectEnabled;
  }
}

export default DoorbellService;
