import { Rail } from "types";

export default {
  type: "rail",
  uuid: "a769c4ef-f437-4b33-9bee-752152e6e0ec",
  template: "tile-landscape",
  title: "Most Popular",
  tiles: [
    {
      type: "landscapeTile",
      title: "The Next Step",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/c6621904-e97c-4ed9-88b0-2ea4583d1fe3/16-9/{width}",
      hero: {
        mode: "standard",
        title: "The Next Step",
        metadata: "1h 45m   ",
        synopsis:
          "Get This Party Started. The Next Step Dance Studio holds auditions for the ten dancers in A-Troupe.",
        logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_bbc/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/c6621904-e97c-4ed9-88b0-2ea4583d1fe3/background/{width}",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_bbc/408/84",
    },
    {
      type: "landscapeTile",
      title: "The Other One",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/e9458d53-8342-4017-b429-7383d9925bc4/16-9/{width}",
      hero: {
        mode: "standard",
        title: "The Other One",
        metadata: "1h 45m   ",
        synopsis:
          "Episode 1. Comedy about a girl called Catherine Walcott. And another girl called Catherine Walcott. Contains adult humour.",
        logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_bbc/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/e9458d53-8342-4017-b429-7383d9925bc4/background/{width}",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_bbc/408/84",
    },
    {
      type: "landscapeTile",
      title: "EastEnders 2008",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/76164001-b847-46ab-b217-33d04bd56aed/16-9/{width}",
      hero: {
        mode: "standard",
        title: "EastEnders 2008",
        metadata: "1h 45m   ",
        synopsis:
          "04/04/2008. Bianca is pushed to extremes trying to protect her kids.",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_bbcfour/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/76164001-b847-46ab-b217-33d04bd56aed/background/{width}",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_bbcfour/408/84",
    },
    {
      type: "landscapeTile",
      title: "You Are What You Wear",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/50d69867-43f6-4aaa-a518-edc828912964/16-9/{width}",
      hero: {
        mode: "standard",
        title: "You Are What You Wear",
        metadata: "1h 45m   ",
        synopsis:
          "Episode 3. Rylan offers makeover advice to a stay-at-home mum, a biker chick and a lifelong soldier.",
        logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_bbc/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/50d69867-43f6-4aaa-a518-edc828912964/background/{width}",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_bbc/408/84",
    },
    {
      type: "landscapeTile",
      title: "Strike",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/938de44c-6c4a-4ba0-baf2-1f7f3493d66f/16-9/{width}",
      hero: {
        mode: "standard",
        title: "Strike",
        metadata: "1h 45m   ",
        synopsis:
          "Episode 1. Cormoran Strike investigates the truth surrounding a mysterious death. Contains some strong language.",
        logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_bbc/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/938de44c-6c4a-4ba0-baf2-1f7f3493d66f/background/{width}",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_bbc/408/84",
    },
    {
      type: "landscapeTile",
      title: "EastEnders: Secrets from the Square",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/cf9e5d19-4524-4af4-bd90-ac672b2c6651/16-9/{width}",
      hero: {
        mode: "standard",
        title: "EastEnders: Secrets from the Square",
        metadata: "1h 45m   ",
        synopsis:
          "Mick and Linda. Stacey Dooley is joined by Danny Dyer and Kellie Bright to talk all things Mick and Linda.",
        logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_bbcone/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/cf9e5d19-4524-4af4-bd90-ac672b2c6651/background/{width}",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_bbcone/408/84",
    },
    {
      type: "landscapeTile",
      title: "QI",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/20769390-66f3-4878-8057-54e5e3ce4616/16-9/{width}",
      hero: {
        mode: "standard",
        title: "QI",
        metadata: "1h 45m   ",
        synopsis:
          "Rubbish. Sandi talks rubbish with Alan Davies, Bridget Christie, Johnny Vegas and Holly Walsh.",
        logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_bbctwo/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/20769390-66f3-4878-8057-54e5e3ce4616/background/{width}",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_bbctwo/408/84",
    },
    {
      type: "landscapeTile",
      title: "Jamie Johnson",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/08cd5df2-7869-4d9b-9746-f9042d058fa8/16-9/{width}",
      hero: {
        mode: "standard",
        title: "Jamie Johnson",
        metadata: "1h 45m   ",
        synopsis:
          "Game Changer. Can Jamie lead Phoenix FC to a cup final? Or will Duncans secret come back to haunt him?",
        logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_cbbc/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/08cd5df2-7869-4d9b-9746-f9042d058fa8/background/{width}",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_cbbc/408/84",
    },
    {
      type: "landscapeTile",
      title: "Normal People",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/3514f54d-a9ef-489b-90a7-103c87476a21/16-9/{width}",
      hero: {
        mode: "standard",
        title: "Normal People",
        metadata: "1h 45m   ",
        synopsis:
          "Episode 1. In rural Ireland, popular Connell and aloof Marianne begin an intense, secret affair. Contains some strong language.",
        logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_bbc/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/3514f54d-a9ef-489b-90a7-103c87476a21/background/{width}",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_bbc/408/84",
    },
    {
      type: "landscapeTile",
      title: "Remarkable Places to Eat",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/44d0ce06-ef66-4623-ac42-ee69a807c0d7/16-9/{width}",
      hero: {
        mode: "standard",
        title: "Remarkable Places to Eat",
        metadata: "1h 45m   ",
        synopsis:
          "Marrakesh. TV chef Andi Oliver takes Fred to Marrakesh to discover the finest Moroccan cooking.",
        logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_bbctwo/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/44d0ce06-ef66-4623-ac42-ee69a807c0d7/background/{width}",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_bbctwo/408/84",
    },
  ],
} as Rail;
