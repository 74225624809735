import { SwitcherState } from "./index";
import { RecentTile, OnNowTile, JourneyId } from "types";
import { getTileFields } from "data/journeys";

export const numSwitcherItems = ({
  recentTiles,
  onNow,
  nextTiles,
}: SwitcherState) => recentTiles.length + (onNow ? 1 : 0) + nextTiles.length;

export const createRecent = (journeyId: JourneyId): RecentTile => {
  const tileFields = getTileFields(journeyId);
  return {
    type: "recentTile",
    journeyId,
    progress: 27,
    ...tileFields,
    action: {
      type: "JOURNEY.START",
      journeyId,
    },
  };
};

export const addToRecent = (
  recentTiles: RecentTile[],
  onNowTile?: OnNowTile
) => {
  if (!onNowTile) return recentTiles;
  return [...recentTiles, createRecent(onNowTile.journeyId)];
};

export const removeFromRecent = (
  recentTiles: RecentTile[],
  journeyId: JourneyId
) => recentTiles.filter(tile => tile.journeyId !== journeyId);

export const createOnNow = (
  journeyId: JourneyId,
  pageIndex?: number
): OnNowTile => {
  const { imageUrl, providerImageUrl } = getTileFields(journeyId);
  return {
    type: "onNowTile" as OnNowTile["type"],
    journeyId,
    pageIndex: pageIndex || 0,
    imageUrl,
    providerImageUrl,
  };
};
