import { animations } from "animations";
import { CameraFeedController } from "components/CameraFeed";
import { useKeys } from "hooks/useKeys";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { Selector } from "stores/Selector";
import { Toggler } from "stores/Toggler";
import { CameraFeed } from "types";
import { Container, CameraFeedContainer } from "./QAMLiveFeeds.styles";

type Props = {
  focused: boolean;
  feeds: CameraFeed[];
  index: number;
  fullscreen?: boolean;
};

export const QAMLiveFeeds = ({ focused, feeds, index, fullscreen }: Props) => (
  <Container>
    {feeds.map(({ url, title }, i) => {
      const isFocused = focused && index === i;
      return (
        <CameraFeedContainer key={url} focused={isFocused}>
          <CameraFeedController
            url={url}
            fullscreen={fullscreen && isFocused}
            title={title}
          />
        </CameraFeedContainer>
      );
    })}
  </Container>
);

type ControllerProps = { focused: boolean };

export const QAMLiveFeedsController = observer(
  ({ focused }: ControllerProps) => {
    const [feedChooser] = useState(
      () =>
        new Selector([
          {
            url: "assets/videos/live-feeds/front-door/front-door.mpd",
            title: "Front door",
          },
          {
            url: "assets/videos/live-feeds/garden/garden.mpd",
            title: "Back garden",
          },
          {
            url: "assets/videos/live-feeds/hallway.mp4",
            title: "Hallway",
          },
          {
            url: "assets/videos/live-feeds/sitting-room.mp4",
            title: "Sitting room",
          },
        ])
    );
    const [fullscreenToggler] = useState(() => new Toggler());
    useKeys(focused, ({ button }) => {
      switch (button) {
        case "LEFT":
          feedChooser.prev();
          break;
        case "RIGHT":
          feedChooser.next();
          break;
        case "SELECT":
          fullscreenToggler.toggle();
          animations.animate(
            fullscreenToggler.on ? "cameraFeedZoomIn" : "cameraFeedZoomOut",
            feedChooser.item.url
          );
          break;
        case "BACK":
          if (fullscreenToggler.on) {
            fullscreenToggler.on = false;
            animations.animate("cameraFeedZoomOut", feedChooser.item.url);
          }
          break;
      }
    });
    return (
      <QAMLiveFeeds
        focused={focused}
        feeds={feedChooser.items}
        index={feedChooser.index}
        fullscreen={fullscreenToggler.on}
      />
    );
  }
);
