import { Rail } from "types";

export default {
  type: "rail",
  uuid: "2bfeedfa-ba57-45b8-9476-44c9648ffbaf",
  template: "tile-landscape",
  title: "Drama",
  tiles: [
    {
      type: "landscapeTile",
      title: "McMafia",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/5e110b3c-8393-4a2e-a4aa-8db45cea1921/16-9/{width}",
      hero: {
        mode: "standard",
        title: "McMafia",
        metadata: "1h 45m   ",
        synopsis:
          "When tragedy strikes, Alex Godman finds himself drawn into the criminal world.",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/5e110b3c-8393-4a2e-a4aa-8db45cea1921/background/{width}",
      },
    },
    {
      type: "landscapeTile",
      title: "The Luminaries",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/38d63ecb-4f9a-438e-8924-101bbec8765f/16-9/{width}",
      hero: {
        mode: "standard",
        title: "The Luminaries",
        metadata: "1h 45m   ",
        synopsis:
          "An intricately woven, suspenseful tale of love, murder, magic and revenge.",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/38d63ecb-4f9a-438e-8924-101bbec8765f/background/{width}",
      },
    },
    {
      type: "landscapeTile",
      title: "The Salisbury Poisonings",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/50dea4f8-9d3e-4abd-a662-469172083753/16-9/{width}",
      hero: {
        mode: "standard",
        title: "The Salisbury Poisonings",
        metadata: "1h 45m   ",
        synopsis:
          "In March 2018 Salisbury became the site of an unprecedented national emergency",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/50dea4f8-9d3e-4abd-a662-469172083753/background/{width}",
      },
    },
    {
      type: "landscapeTile",
      title: "Doctor Foster",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/bfc7c473-02aa-4562-85c3-d7d062fc749d/16-9/{width}",
      hero: {
        mode: "standard",
        title: "Doctor Foster",
        metadata: "1h 45m   ",
        synopsis:
          "A trusted GP sees her charmed life explode when she suspects her husband of an affair.",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/bfc7c473-02aa-4562-85c3-d7d062fc749d/background/{width}",
      },
    },
    {
      type: "landscapeTile",
      title: "Cardinal",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/1e1e67ba-96f4-42d0-932a-67252718c131/16-9/{width}",
      hero: {
        mode: "standard",
        title: "Cardinal",
        metadata: "1h 45m   ",
        synopsis:
          "Canadian crime drama series featuring detective duo John Cardinal and Lise Delorme.",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/1e1e67ba-96f4-42d0-932a-67252718c131/background/{width}",
      },
    },
    {
      type: "landscapeTile",
      title: "Unprecedented",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/dc3a8afe-bf3d-488b-9810-71073fa7b569/16-9/{width}",
      hero: {
        mode: "standard",
        title: "Unprecedented",
        metadata: "1h 45m   ",
        synopsis:
          "Stories of love, triumph and uncertainty during the time of coronavirus.",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/dc3a8afe-bf3d-488b-9810-71073fa7b569/background/{width}",
      },
    },
    {
      type: "landscapeTile",
      title: "State of Happiness",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/83e1bcf7-092e-48f9-a109-028d616ae885/16-9/{width}",
      hero: {
        mode: "standard",
        title: "State of Happiness",
        metadata: "1h 45m   ",
        synopsis:
          "Drama set in Stavanger in 1969. What happens next after a final attempt to find oil?",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/83e1bcf7-092e-48f9-a109-028d616ae885/background/{width}",
      },
    },
    {
      type: "landscapeTile",
      title: "Mystery Road",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/91b70e45-f4c5-45c8-9782-d8923622fdc9/16-9/{width}",
      hero: {
        mode: "standard",
        title: "Mystery Road",
        metadata: "1h 45m   ",
        synopsis:
          "Australian drama series featuring indigenous investigator Jay Swan, a mistrustful lawman.",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/91b70e45-f4c5-45c8-9782-d8923622fdc9/background/{width}",
      },
    },
    {
      type: "landscapeTile",
      title: "Logan High",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/9f95ad55-a546-4d7d-ad9b-a20f849f8c10/16-9/{width}",
      hero: {
        mode: "standard",
        title: "Logan High",
        metadata: "1h 45m   ",
        synopsis: "Catch up with the lives of the pupils of Logan High.",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/9f95ad55-a546-4d7d-ad9b-a20f849f8c10/background/{width}",
      },
    },
    {
      type: "landscapeTile",
      title: "Luther",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/57f7453b-d264-4722-a436-be7360247b72/16-9/{width}",
      hero: {
        mode: "standard",
        title: "Luther",
        metadata: "1h 45m   ",
        synopsis: "Crime drama series starring Idris Elba as DCI John Luther.",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/57f7453b-d264-4722-a436-be7360247b72/background/{width}",
      },
    },
  ],
} as Rail;
