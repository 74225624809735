import { Rail } from "types";

const netflixLogo =
  "https://cdn.skyq.sky.com/uk/epg/live/media/logos/netflix/v1/logo_365x75.png";

export default {
  type: "rail",
  uuid: "57b2f360-a20c-4975-9db5-222d7a87f92e",
  template: "tile-cover",
  title: "Netflix",
  tiles: [
    {
      type: "coverTile",
      title: "Rocketman",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/cd4de3d0-03b8-4016-af8e-31056cd5a2b0/cover/{height}",
      hero: {
        mode: "standard",
        title: "Rocketman",
        metadata: "1h 45m   ",
        synopsis:
          "Dazzling musical biopic detailing Sir Elton John's breakthrough years. Taron Egerton, Jamie Bell and Richard Madden star. (2019)(116 mins)",
        logoUrl: netflixLogo,
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/fe83aa93de07e40d0cf0fae5b1a53fc3/hero.jpg",
        heroTitleImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/d8d1fcc955ed7087be4da846e06448b2/herotitle.png",
      },
    },
    {
      type: "coverTile",
      title: "The Secret Life of Pets 2",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/73a7e70e-6d06-484e-a98b-1755a55e85dd/cover/{height}",
      hero: {
        mode: "standard",
        title: "The Secret Life of Pets 2",
        metadata: "1h 45m   ",
        synopsis:
          "Sequel to the hit film following the adventures of our pets that we don't see when we leave home! With the voices of Kevin Hart and Harrison Ford. (2019)(90 mins)",
        logoUrl: netflixLogo,
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/f5d65500ff6f4ef2b8b4287f136f4cba/hero.jpg",
        heroTitleImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/6820fd79a2800d4c837c6db9065dd042/herotitle.png",
      },
    },
    {
      type: "coverTile",
      title: "Brightburn",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/4d5d9c3c-b766-4927-81a1-306de66370e5/cover/{height}",
      hero: {
        mode: "standard",
        title: "Brightburn",
        metadata: "1h 45m   ",
        synopsis:
          "An extra-terrestrial child mysteriously crash-lands on Earth. His powers are unlike anything ever seen - but can they be harnessed for good? Elizabeth Banks stars. (2019)(90 mins)",
        logoUrl: netflixLogo,
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/4d5d9c3c-b766-4927-81a1-306de66370e5/background/{width}",
        heroTitleImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/72cbc85f31f50c9d6fdb41a1dc80825a/herotitle.png",
      },
    },
    {
      type: "coverTile",
      title: "Godzilla: King of the Monsters",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/d74131d5-76af-47b5-86de-a83b2886f1b3/cover/{height}",
      hero: {
        mode: "standard",
        title: "Godzilla: King of the Monsters",
        metadata: "1h 45m   ",
        synopsis:
          "Godzilla battles a host of giant monsters - including his nemesis, the three-headed King Ghidorah. (2019)(126 mins)",
        logoUrl: netflixLogo,
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/d74131d5-76af-47b5-86de-a83b2886f1b3/background/{width}",
        heroTitleImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/885d5f190e143f93498bf8f5eeac6a4b/herotitle.png",
      },
    },
    {
      type: "coverTile",
      title: "The Curse of La Llorona",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/b2740ff5-d906-422e-bce1-8fc7544a4f72/cover/{height}",
      hero: {
        mode: "standard",
        title: "The Curse of La Llorona",
        metadata: "1h 45m   ",
        synopsis:
          "A social worker faces the deadly wrath of a legendary ghost when she ignores the eerie warning of a troubled mother. Linda Cardellini stars. (2019)(93 mins)",
        logoUrl: netflixLogo,
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/b2740ff5-d906-422e-bce1-8fc7544a4f72/background/{width}",
        heroTitleImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/ad89bfcc8c1d38ef6fa933e998d034b8/herotitle.png",
      },
    },
    {
      type: "coverTile",
      title: "Us",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/cbd46853-027b-4eae-bcd8-d7df3b9daa84/cover/{height}",
      hero: {
        mode: "standard",
        title: "Us",
        metadata: "1h 45m   ",
        synopsis:
          "A family gets caught in a nightmare when their holiday is interrupted by unexpected visitors. Horror from Get Out's Jordan Peele. Strong language/violence/mature themes. (2019)(120 mins)",
        logoUrl: netflixLogo,
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/2d1196433f174c1bf369467accd0793a/hero.jpg",
        heroTitleImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/5f9f66903edd8b42c92367ae7af401b8/herotitle.png",
      },
    },
    {
      type: "coverTile",
      title: "Instant Family",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/384c738a-b796-47ac-93d2-5aa5379cf84d/cover/{height}",
      hero: {
        mode: "standard",
        title: "Instant Family",
        metadata: "1h 45m   ",
        synopsis:
          "Couple Pete (Mark Wahlberg) and Ellie (Rose Byrne) adopt three children from foster care with hectic consequences. Comedy-drama. (2018)(118 mins)",
        logoUrl: netflixLogo,
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/8eee3b4334ac77d79d2a0bd9bbbef245/hero.jpg",
        heroTitleImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/220167674c19a1350f5909a35a2a47ec/herotitle.png",
      },
    },
  ],
} as Rail;
