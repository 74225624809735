import React, { useRef } from "react";
import { Journey } from "components/Journey";

import { Text } from "../Text";
import {
  Container,
  ContentContainer,
  MediaContainer,
  SwitcherTileContainer,
} from "./OnNowTile.styles";
import { Journey as JourneyType } from "types";
import { SwitcherTile } from "components/SwitcherTile";
import { useAnimations } from "hooks/useAnimations";
import gsap from "gsap";
import { boolToInt } from "utils/boolToInt";
import { wait } from "utils/wait";
import { transitions } from "constants/transitions";

type Props = {
  focused: boolean;
  journey: JourneyType;
  pageIndex: number;
  fullscreen?: boolean;
  imageUrl?: string;
  providerImageUrl?: string;
};

export const OnNowTile = ({
  focused,
  journey,
  pageIndex,
  fullscreen,
  imageUrl,
  providerImageUrl,
}: Props) => {
  const contentContainer = useRef(null),
    switcherTile = useRef(null);

  // Animate ContentContainer
  useAnimations(
    {
      reset: params => gsap.set(contentContainer.current, params),
    },
    { opacity: boolToInt(!fullscreen && focused) }
  );

  // Animate SwitcherTile
  useAnimations(
    {
      uninterruptible: ["zoomOut"],
      animations: {
        zoomOut: async () => {
          gsap.set(switcherTile.current, { opacity: 0 });
          await wait(transitions.zoomOut.duration);
        },
      },
      reset: params => gsap.set(switcherTile.current, params),
    },
    { opacity: boolToInt(!fullscreen && !focused) }
  );

  return (
    <Container focused={focused} fullscreen={fullscreen}>
      <MediaContainer>
        <Journey
          journey={journey}
          pageIndex={pageIndex}
          key={journey.id}
          focused={focused}
        />
      </MediaContainer>
      <ContentContainer ref={contentContainer}>
        <Text size="medium" weight="medium">
          {journey.title}
        </Text>
      </ContentContainer>
      <SwitcherTileContainer ref={switcherTile}>
        <SwitcherTile
          focused={focused}
          imageUrl={imageUrl}
          providerImageUrl={providerImageUrl}
          tag="On Now"
          title={journey.title}
          progress={23}
        />
      </SwitcherTileContainer>
    </Container>
  );
};
