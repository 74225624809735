import { State } from "reducers";
import { pageTitleForHero } from "selectors/browse";
import { Hero } from "types";

export const heroSelector = (state: State): Hero => {
  const title = pageTitleForHero(state);
  return title
    ? { mode: "hugeTitle", title: title.text, minilabel: title.minilabel }
    : { mode: "none" };
};
