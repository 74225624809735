import React, { useRef } from "react";
import { maxRowX, horizMaskGradient } from "utils/railHelpers";
import { Container, Row } from "./RailSelector.styles";
import { RailLens } from "components/RailLens";
import { useAnimations } from "hooks/useAnimations";
import gsap from "gsap";
import { transitions } from "constants/transitions";
import { useFocusedItemDims } from "hooks/useFocusedItemDims";

type Props = {
  items: (focused: boolean) => JSX.Element[];
  index: number;
  width: number;
  height: number;
  focused?: boolean;
  focusedHeight?: number;
  focusedExtraWidth?: number;
  animationScope?: string;
};

export const RailSelector = ({
  index,
  items,
  width,
  height,
  focused,
  focusedExtraWidth,
  focusedHeight,
  animationScope,
}: Props) => {
  focused = focused ?? false;
  focusedHeight = focusedHeight ?? height;
  focusedExtraWidth = focusedExtraWidth ?? 0;
  const row = useRef(null),
    focusedItemDims = useFocusedItemDims(row, index),
    maximumRowX = maxRowX(row, width),
    rowX = -Math.min(focusedItemDims.x, maximumRowX),
    lensX = Math.max(focusedItemDims.x - maximumRowX, 0);

  // Animate row
  useAnimations(
    {
      scope: animationScope,
      reset: params => gsap.set(row.current, params),
      uninterruptible: ["holdRail"],
      animations: {
        holdRail: (_, timeline) =>
          timeline.fromTo(
            row.current,
            { "-webkit-mask-image": "" },
            { duration: transitions.changeRail.duration }
          ),
        moveLens: (params, timeline) =>
          timeline.to(row.current, {
            ...params,
            ...transitions.moveLens,
          }),
      },
    },
    {
      x: rowX,
      "-webkit-mask-image": horizMaskGradient(
        focusedItemDims.x - focusedExtraWidth / 2,
        focusedItemDims.width + focusedExtraWidth,
        focused
      ),
    }
  );

  return (
    <Container>
      <Row ref={row}> {items(false)} </Row>

      {focused && (
        <RailLens
          {...{
            x: lensX - focusedExtraWidth / 2,
            y: -(focusedHeight - height) / 2,
            index,
            extraWidth: focusedExtraWidth,
            height: focusedHeight,
            animationScope,
          }}
        >
          {items(true)}
        </RailLens>
      )}
    </Container>
  );
};
