import React, { useEffect, useState } from "react";
import { useKeys } from "hooks/useKeys";
import { TVGuideCategories } from "./TVGuideCategories";
import { observer } from "mobx-react-lite";
import { tvCategoriesIsShowing } from "selectors/browse";
import { useDispatch, useSelector } from "store";
import { pageOrder } from "data/pages/tvGuide";
import { focusArea } from "selectors/app";
import { CyclicSelector } from "stores/CyclicSelector";
import { tvGuidePageIndex } from "selectors/pages/tvGuide";

export const TVGuideCategoriesController = observer(() => {
  const dispatch = useDispatch();
  const focused = useSelector(focusArea) === "tvCategories",
    visible = useSelector(tvCategoriesIsShowing),
    currentPageIndex = useSelector(tvGuidePageIndex);

  const [categorySelector] = useState(() => new CyclicSelector(pageOrder));

  useEffect(() => {
    // Every time it becomes visible, sync selector to current page id
    if (visible) {
      categorySelector.setIndex(currentPageIndex);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categorySelector, visible]);

  useKeys(
    focused,
    ({ button }) => {
      switch (button) {
        case "BACK":
          dispatch({ type: "FOCUS_ON_BROWSE" });
          dispatch({ type: "BROWSE_BACK" });
          break;
        case "DOWN":
          categorySelector.next();
          dispatch({
            type: "BROWSE_REPLACE_PAGE",
            pageType: "tvGuidePage",
            pageId: categorySelector.item,
          });
          break;
        case "UP":
          categorySelector.prev();
          dispatch({
            type: "BROWSE_REPLACE_PAGE",
            pageType: "tvGuidePage",
            pageId: categorySelector.item,
          });
          break;
        case "RIGHT":
          dispatch({ type: "BROWSE.FOCUS_OFF_TV_CATEGORIES" });
          break;
      }
    },
    { throttleMs: 300 }
  );

  return (
    <TVGuideCategories
      categories={pageOrder}
      index={categorySelector.index}
      visible={visible}
      focused={focused}
    />
  );
});
