import { Rail } from "types";

export default {
  type: "rail",
  uuid: "5d6b2a57-2b64-4da1-90f3-4eb48ee210fb",
  template: "tile-cover",
  title: "Learning At Home",
  tiles: [
    {
      type: "coverTile",
      title: "Moominvalley",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/cb72686f-2d2c-4c4a-b1cb-1ca425e9fe7a/cover/{height}",
      hero: {
        mode: "standard",
        title: "Moominvalley",
        metadata: "1h 45m   ",
        synopsis:
          "The beloved Moomins are back! Moomintroll’s search for independence continues in season two of the whimsical tale. Adapted from the work of Tove Jansson, featuring a star-studded voice cast.",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/cb72686f-2d2c-4c4a-b1cb-1ca425e9fe7a/background/{width}",
        heroTitleImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/3b0a739c4b2ab6a39e7f83921d0beb08/herotitle.png",
      },
    },
    {
      type: "coverTile",
      title: "Morph",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/93ed277c-1f78-4074-acc3-ace5f6adaa2f/cover/{height}",
      hero: {
        mode: "standard",
        title: "Morph",
        metadata: "1h 45m   ",
        synopsis:
          "Morph, the iconic star of children's television is back in a series of mini-adventures. Morph and his sidekick Chas get up to all kinds of mischief in their home on an artist's desk.\r",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/93ed277c-1f78-4074-acc3-ace5f6adaa2f/background/{width}",
      },
    },
    {
      type: "coverTile",
      title: "The Athena",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/257d29be-1d9b-40b7-9f55-49ed609839ff/cover/{height}",
      hero: {
        mode: "standard",
        title: "The Athena",
        metadata: "1h 45m   ",
        synopsis:
          "The Athena is a new drama series charting the fortunes of a group of students at a prestigious college of visual arts in present-day London.\r",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/257d29be-1d9b-40b7-9f55-49ed609839ff/background/{width}",
      },
    },
    {
      type: "coverTile",
      title: "FYI: For Your Info",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/99387495-8564-4bce-8e5f-9457bb3f931a/cover/{height}",
      hero: {
        mode: "standard",
        title: "FYI: For Your Info",
        metadata: "1h 45m   ",
        synopsis:
          "A one-stop shop for weekly news, presented by kids for kids.\r",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/99387495-8564-4bce-8e5f-9457bb3f931a/background/{width}",
      },
    },
    {
      type: "coverTile",
      title: "Aliens Love Underpants and...",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/b13443f6-0a8a-4b32-b513-54da0a754e08/cover/{height}",
      hero: {
        mode: "standard",
        title: "Aliens Love Underpants and...",
        metadata: "1h 45m   ",
        synopsis:
          "Aliens Love Underpants and many other fun things. Join the aliens as they star in a series of silly, catchy songs about their great loves.",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/b13443f6-0a8a-4b32-b513-54da0a754e08/background/{width}",
      },
    },
    {
      type: "coverTile",
      title: "Ultimate Video Skills",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/5c431f45-9ccc-437f-9176-0b9f53eebc64/cover/{height}",
      hero: {
        mode: "standard",
        title: "Ultimate Video Skills",
        metadata: "1h 45m   ",
        synopsis:
          "Ultimate Video Skills is the show that teaches you the techniques and secrets needed to make some amazing videos tricks. Join Luke Franks, along with his guest friends.",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/5c431f45-9ccc-437f-9176-0b9f53eebc64/background/{width}",
      },
    },
    {
      type: "coverTile",
      title: "Best Makes Ever!",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/5d1195f5-ce09-4798-8215-2785d9d9c4c2/cover/{height}",
      hero: {
        mode: "standard",
        title: "Best Makes Ever!",
        metadata: "1h 45m   ",
        synopsis:
          "Best Makes Ever shows you how to make the best fun crafts for all ages. Grab what you need and watch along!",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/5d1195f5-ce09-4798-8215-2785d9d9c4c2/background/{width}",
      },
    },
    {
      type: "coverTile",
      title: "Streetcat Bob",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/fe33dde6-5305-40f0-b2a9-9e7f53cc6b1f/cover/{height}",
      hero: {
        mode: "standard",
        title: "Streetcat Bob",
        metadata: "1h 45m   ",
        synopsis:
          "Meet Bob - a stray ginger cat living in Bowen Park alongside his animal friends. Animated lyrical adventure for preschoolers based on the bestselling books and film.",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/fe33dde6-5305-40f0-b2a9-9e7f53cc6b1f/background/{width}",
      },
    },
    {
      type: "coverTile",
      title: "SO Beano!",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/f5320852-dd7a-48c0-80fd-15c5f7a6b2e3/cover/{height}",
      hero: {
        mode: "standard",
        title: "SO Beano!",
        metadata: "1h 45m   ",
        synopsis:
          "The very best of riotous Beano mayhem, featuring hilarious sketches from the latest emerging talent, guest challenges, original toons and more.",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/f5320852-dd7a-48c0-80fd-15c5f7a6b2e3/background/{width}",
      },
    },
    {
      type: "coverTile",
      title: "Night Zookeeper",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/d04cdbb9-373f-4cfa-9ae9-555ddc362a7f/cover/{height}",
      hero: {
        mode: "standard",
        title: "Night Zookeeper",
        metadata: "1h 45m   ",
        synopsis:
          "The zoo needs you! Join Will, Riya and Sam the Spying Giraffe as they try and save the magical animals in the Night Zoo from an army of evil robotic Voids!\r",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/d04cdbb9-373f-4cfa-9ae9-555ddc362a7f/background/{width}",
      },
    },
    {
      type: "titleTile",
      actionText: "View all",
      hero: {
        mode: "standard",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/d04cdbb9-373f-4cfa-9ae9-555ddc362a7f/background/{width}",
      },
    },
  ],
} as Rail;
