import { videos } from "data/videos";
import { Journey } from "types";

export const coast: Journey = {
  id: "coast",
  pages: [
    {
      type: "videoPage",
      url: videos.coast,
    },
  ],
  title: "Coast",
};
