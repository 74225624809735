import { State } from "reducers";
import { getJourney } from "data/journeys";

export const currentJourneyId = ({ switcher: { onNow } }: State) =>
  onNow?.journeyId;

export const currentJourney = ({ switcher: { onNow } }: State) =>
  !!onNow && getJourney(onNow.journeyId);

export const onLastPage = (state: State) => {
  const journey = currentJourney(state);
  return (
    !!journey && state.switcher.onNow?.pageIndex === journey.pages.length - 1
  );
};
