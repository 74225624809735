import { NavStep, PageType } from "types";
import { select, put } from "sagas/effects";
import { railsPageNavigate } from "sagas/navigate/pages/rails";
import { gridPageNavigate } from "sagas/navigate/pages/grid";
import { imagePageNavigate } from "sagas/navigate/pages/image";
import { videoPageNavigate } from "sagas/navigate/pages/video";
import { tvGuidePageNavigate } from "sagas/navigate/pages/tvGuide";
import { onHomepage } from "selectors/browse";

function* pageNavigate(pageType: PageType, step: NavStep) {
  switch (pageType) {
    case "railsPage":
      yield* railsPageNavigate(step);
      break;
    case "gridPage":
      yield* gridPageNavigate(step);
      break;
    case "imagePage":
      yield* imagePageNavigate(step);
      break;
    case "videoPage":
      yield* videoPageNavigate(step);
      break;
    case "tvGuidePage":
      yield* tvGuidePageNavigate(step);
      break;
  }
}

export function* browseNavigate(step: NavStep) {
  const pageType = yield select(s => s.browse.pageState.pageType);
  if (step === "BACK" && (yield select(onHomepage))) {
    yield put({ type: "GO_HOME" });
  } else {
    yield* pageNavigate(pageType, step);
  }
}
