import { QAMButton } from "components/QAMButton/QAMButton";
import { useKeys } from "hooks/useKeys";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { Selector } from "stores/Selector";
import { Button } from "types";
import { Container } from "./QAMButtons.styles";

type Props = {
  buttons: Button[];
  index: number;
  focused: boolean;
};

export const QAMButtons = ({ buttons, index, focused }: Props) => (
  <Container>
    {buttons.map(({ title, id }, i) => (
      <QAMButton key={id} focused={focused && index === i} title={title} />
    ))}
  </Container>
);

//------------------------------------------------------------

type ControllerProps = {
  buttons: Button[];
  focused: boolean;
  onSelect?: (id: string) => void;
};

export const QAMButtonsController = observer(
  ({ buttons, focused, onSelect }: ControllerProps) => {
    const [buttonChooser] = useState(() => new Selector<Button>(buttons));

    useKeys(focused, ({ button }) => {
      switch (button) {
        case "LEFT":
          buttonChooser.prev();
          break;
        case "RIGHT":
          buttonChooser.next();
          break;
        case "SELECT":
          onSelect?.(buttonChooser.item.id);
          break;
      }
    });
    return (
      <QAMButtons
        buttons={buttons}
        index={buttonChooser.index}
        focused={focused}
      />
    );
  }
);
