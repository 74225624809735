import { State } from "reducers";

export const switcherTiles = ({
  switcher: { recentTiles, onNow, nextTiles },
}: State) =>
  onNow
    ? [...recentTiles, onNow, ...nextTiles]
    : [...recentTiles, ...nextTiles];

export const selectedSwitcherTile = (state: State) =>
  switcherTiles(state)[state.switcher.focusIndex];
