import { Subject, partition } from "rxjs";

import PusherConnection from "./PusherConnection";
import { RemoteButtonEvent } from "../types/remoteTypes";
import { generateChars } from "../utils/twiliohelpers";

class RemoteControlService {
  private static _instance: RemoteControlService | null = null;
  private _subject: Subject<KeyboardEvent> | null = null;
  private _pusherConnection: PusherConnection | null = null;
  private _remoteId: string = `remote-${generateChars(8)}`;

  public static get instance() {
    if (!RemoteControlService._instance) {
      RemoteControlService._instance = new RemoteControlService();
    }
    return RemoteControlService._instance;
  }

  constructor() {
    this.createStreams();
    this.connectToChannel(this._remoteId);
  }

  private createStreams() {
    this._subject = new Subject();
  }

  public handleRemoteButtonEvent = (e: RemoteButtonEvent) => {
    if (this._subject) {
      const { code, direction } = e;
      const eventName = direction ? "keydown" : "keyup";
      const keyboardEvent = new KeyboardEvent(eventName, { code });
      this._subject.next(keyboardEvent);
    }
  };

  get stream() {
    return this._subject;
  }

  connectToChannel(channelId: string) {
    const eventId = "client-remoteButtonEvent";
    if (this._pusherConnection) {
      this._pusherConnection.destroy();
    }
    this._pusherConnection = new PusherConnection(`private-${channelId}`, {
      [eventId]: this.handleRemoteButtonEvent,
    });
    this._remoteId = channelId;
  }

  get remoteId() {
    return this._remoteId;
  }

  get status() {
    if (this._pusherConnection) {
      return this._pusherConnection.status;
    }
    return "disconnected";
  }

  get upAndDownStreams() {
    return partition(this._subject, e => e.type === "keydown");
  }
}

export default RemoteControlService;
