import { Rail } from "types";

const netflixLogoUrl =
  "https://cdn.skyq.sky.com/uk/epg/live/media/logos/netflix/v1/logo_365x75.png";

export default {
  type: "rail",
  uuid: "a68c2129-aae4-47fe-927c-a3c954de54b1",
  template: "tile-landscape",
  title: "Netflix Originals",
  tiles: [
    {
      type: "landscapeTile",
      title: "Ride Like A Girl",
      imageUrl:
        "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/f684f244cb14b089f6ac399e1926f0b3/{width}/{height}/item.jpg",
      providerImageUrl: netflixLogoUrl,
      hero: {
        mode: "standard",
        title: "Ride Like A Girl",
        metadata: "1h 45m   ",
        synopsis:
          "It's like a family trip to the cinema, but available to watch now, at home. Sports biopic about history-making jockey Michelle Payne. Strong language/flashing images. (2020)(94 mins)",
        logoUrl: netflixLogoUrl,
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/fc8b0854-254e-4464-a198-97dd6de8dec6/background/{width}",
      },
    },
    {
      type: "landscapeTile",
      title: "The King of Staten Island",
      imageUrl:
        "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/9403f40470ebe26eb45445052a59fe0d/{width}/{height}/item.jpg",
      providerImageUrl: netflixLogoUrl,
      hero: {
        mode: "standard",
        title: "The King of Staten Island",
        metadata: "1h 45m   ",
        synopsis:
          "Judd Apatow and Pete Davidson's heartfelt comedy about grief. Strong language/violence/sex/mature themes/flashing images. (2020)(132 mins) Enhanced for UHD. Available in Dolby Atmos",
        logoUrl: netflixLogoUrl,
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/12d0e435-a107-4143-b3f9-e487021c24be/background/{width}",
      },
    },
    {
      type: "landscapeTile",
      title: "The High Note",
      imageUrl:
        "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/8d7d59dc4c9e96de458f0ca38d313088/{width}/{height}/item.jpg",
      providerImageUrl: netflixLogoUrl,
      hero: {
        mode: "standard",
        title: "The High Note",
        metadata: "1h 45m   ",
        synopsis:
          "It's like a family trip to the cinema, but available to watch now, at home. A singer and her PA are given a choice that changes everything. Strong language/flashing images. (2020)(113 mins)",
        logoUrl: netflixLogoUrl,
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/0be0a856-cab1-4c64-8006-23fa3fe68931/background/{width}",
      },
    },
    {
      type: "landscapeTile",
      title: "A Rainy Day In New York",
      imageUrl:
        "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/2da36e9fbe771b564c99e68734e689ee/{width}/{height}/item.jpg",
      providerImageUrl: netflixLogoUrl,
      hero: {
        mode: "standard",
        title: "A Rainy Day In New York",
        metadata: "1h 45m   ",
        synopsis:
          " It's like a family trip to the cinema, but available to watch now, at home. Two youngsters arrive in New York for the weekend for wild adventures. Sex/mature themes. (2019)(89 mins)",
        logoUrl: netflixLogoUrl,
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/6c977a76-10cd-4d3d-9da8-d1203ca9d933/background/{width}",
      },
    },
    {
      type: "landscapeTile",
      title: "Trolls World Tour",
      imageUrl:
        "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/9647950539964d4f41606c656617166c/{width}/{height}/item.jpg",
      providerImageUrl: netflixLogoUrl,
      hero: {
        mode: "standard",
        title: "Trolls World Tour",
        metadata: "1h 45m   ",
        synopsis:
          "It's like a family trip to the cinema, but available to watch now, at home. Poppy and Branch must unite to save the day. Flashing images. (2020)(87 mins) Enhanced for UHD.",
        logoUrl: netflixLogoUrl,
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/0d1bcca4-646d-4e7a-bf71-96c5a4044cef/background/{width}",
      },
    },
  ],
} as Rail;
