import { Rail } from "types";

export default {
  type: "rail",
  uuid: "9707a29d-7943-434d-9590-0ca6ebdf3e2c",
  template: "tile-landscape",
  title: "Sunday",
  tiles: [
    {
      type: "landscapeTile",
      title: "Songs of Praise",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/3496f2c8-56d9-4a9f-93d0-0e0f43f7f8a1/16-9/{width}",
      hero: {
        mode: "standard",
        title: "Songs of Praise",
        metadata: "1h 45m   ",
        synopsis:
          "Father's Day. For Fathers Day, we revisit stories of inspiring dads putting Christian love into action.",
        logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_bbcone/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/3496f2c8-56d9-4a9f-93d0-0e0f43f7f8a1/background/{width}",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_bbcone/408/84",
    },
    {
      type: "landscapeTile",
      title: "Reflections at the Quay",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/1b817675-6669-4142-9ee3-9b7da5dd0678/16-9/{width}",
      hero: {
        mode: "standard",
        title: "Reflections at the Quay",
        metadata: "1h 45m   ",
        synopsis:
          "21/06/2020. This week, reflections from Rev Sally Foster-Fulton, head of Christian Aid Scotland.",
        logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_bbcone/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/1b817675-6669-4142-9ee3-9b7da5dd0678/background/{width}",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_bbcone/408/84",
    },
    {
      type: "landscapeTile",
      title: "Bargain Hunt",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/87557f71-466e-4c5f-93c7-b36885c3eeb6/16-9/{width}",
      hero: {
        mode: "standard",
        title: "Bargain Hunt",
        metadata: "1h 45m   ",
        synopsis:
          "Westpoint 31. Eric Knowles presents from Exeter's Westpoint Arena.",
        logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_bbcone/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/87557f71-466e-4c5f-93c7-b36885c3eeb6/background/{width}",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_bbcone/408/84",
    },
    {
      type: "landscapeTile",
      title: "Sunday Worship",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/b34b6d48-9121-48f3-9947-3cb841c05745/16-9/{width}",
      hero: {
        mode: "standard",
        title: "Sunday Worship",
        metadata: "1h 45m   ",
        synopsis:
          "Second Sunday after Trinity. A service for Fathers Day from Holy Trinity Platt Church in Manchester.",
        logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_bbcone/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/b34b6d48-9121-48f3-9947-3cb841c05745/background/{width}",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_bbcone/408/84",
    },
    {
      type: "landscapeTile",
      title: "Sunday Morning Live",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/e114cc14-0750-45e3-a055-6792031fd887/16-9/{width}",
      hero: {
        mode: "standard",
        title: "Sunday Morning Live",
        metadata: "1h 45m   ",
        synopsis:
          "Episode 1. Sean Fletcher and Sally Phillips explore the ethical and religious issues of the week.",
        logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_bbcone/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/e114cc14-0750-45e3-a055-6792031fd887/background/{width}",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_bbcone/408/84",
    },
    {
      type: "landscapeTile",
      title: "Sunday Politics Northern Ireland",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/08ef7292-5961-4388-8d87-a88597a77901/16-9/{width}",
      hero: {
        mode: "standard",
        title: "Sunday Politics Northern Ireland",
        metadata: "1h 45m   ",
        synopsis:
          "21/06/2020. Mark Carruthers looks at the political developments of the week.",
        logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_bbcone/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/08ef7292-5961-4388-8d87-a88597a77901/background/{width}",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_bbcone/408/84",
    },
    {
      type: "landscapeTile",
      title: "Politics Wales",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/c5fa8c4b-1f90-497c-8393-200eba2ee42f/16-9/{width}",
      hero: {
        mode: "standard",
        title: "Politics Wales",
        metadata: "1h 45m   ",
        synopsis:
          "21/06/2020. All the latest political news from Wales and beyond, presented by James Williams.",
        logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_bbcone/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/c5fa8c4b-1f90-497c-8393-200eba2ee42f/background/{width}",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_bbcone/408/84",
    },
    {
      type: "landscapeTile",
      title: "Politics Scotland",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/5537e619-7e21-423e-8371-23fc15b33565/16-9/{width}",
      hero: {
        mode: "standard",
        title: "Politics Scotland",
        metadata: "1h 45m   ",
        synopsis:
          "21/06/2020. Gordon Brewer and guests look at the weeks events at Westminster and Holyrood.",
        logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_bbcone/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/5537e619-7e21-423e-8371-23fc15b33565/background/{width}",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_bbcone/408/84",
    },
    {
      type: "landscapeTile",
      title: "Politics England",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/8c7e290d-d92c-4da3-af77-b117bba63359/16-9/{width}",
      hero: {
        mode: "standard",
        title: "Politics England",
        metadata: "1h 45m   ",
        synopsis:
          "21/06/2020. Guests include Jackie Doyle-Price, Hilary Benn, Ben Houchen and Ian Wright.",
        logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_bbcone/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/8c7e290d-d92c-4da3-af77-b117bba63359/background/{width}",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_bbcone/408/84",
    },
    {
      type: "landscapeTile",
      title: "The Andrew Marr Show",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/be5a63fa-78bc-44b6-b03a-d4feb482879c/16-9/{width}",
      hero: {
        mode: "standard",
        title: "The Andrew Marr Show",
        metadata: "1h 45m   ",
        synopsis:
          "21/06/2020. Nick Robinson is joined by Jonathan Ashworth, Professor Peter Piot and Sir Ian Diamond.",
        logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_bbcone/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/be5a63fa-78bc-44b6-b03a-d4feb482879c/background/{width}",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_bbcone/408/84",
    },
  ],
} as Rail;
