import React from "react";
import {
  Container,
  Sidebar,
  BgMask,
  FrameContainer,
} from "./TVGuideCategories.styles";
import { InfiniteList } from "components/InfiniteList";
import { TvGuidePageId } from "types";
import { GlassFrame } from "components/GlassFrame";

const IMAGE_URLS: { [id in TvGuidePageId]: string } = {
  tvGuide_kids: "assets/images/tvCategories/kids.svg",
  tvGuide_sports: "assets/images/tvCategories/sports.svg",
  tvGuide_music: "assets/images/tvCategories/music.svg",
  tvGuide_movies: "assets/images/tvCategories/movies.svg",
  tvGuide_tvShows: "assets/images/tvCategories/tvShows.svg",
  tvGuide_news: "assets/images/tvCategories/news.svg",
};

type Props = {
  categories: TvGuidePageId[];
  index: number;
  visible: boolean;
  focused: boolean;
};

export const TVGuideCategories = ({
  categories,
  index,
  visible,
  focused,
}: Props) => {
  return (
    <Container id="tv-guide-categories" visible={visible}>
      <BgMask />
      <Sidebar>
        <InfiniteList
          originalLength={categories.length}
          windowLength={8}
          index={index}
          itemHeight={89}
          renderItem={idx => (
            <img
              alt={categories[idx]}
              src={IMAGE_URLS[categories[idx]]}
              width={32}
              height={32}
            />
          )}
          style={{ marginLeft: -32 }}
        />
        <FrameContainer visible={focused}>
          <GlassFrame width={72} height={72} />
        </FrameContainer>
      </Sidebar>
    </Container>
  );
};
