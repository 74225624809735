import request from "superagent";
import { heroUrlTemplate } from "services/images";
import { Hero } from "types";
import { SimpleCache } from "utils/SimpleCache";

const fetchProgramme = async function(uuid) {
  const response = await request.get(
    `https://entity.search.sky.com/entity/programme/v1/stb/ethan/4101/1/user/${uuid}`
  );
  return response.body;
};

const heroesCache = new SimpleCache<Hero>();

export const programmeHero = async (uuid: string): Promise<Hero> =>
  heroesCache.call(
    uuid,
    async (): Promise<Hero> => {
      try {
        const { t: title, sy: synopsis } = await fetchProgramme(uuid);
        return {
          mode: "standard",
          title,
          synopsis,
          heroImageUrl: heroUrlTemplate(uuid),
        };
      } catch (error) {
        return { mode: "none" };
      }
    }
  );
