import React, { useRef } from "react";
import { useSelector } from "store";
import { Container, Row, StyledItem, LensContainer } from "./Switcher.styles";
import {
  grid,
  screen,
  switcher,
  switcherTile,
  onNowTile,
} from "constants/dimensions";
import { SwitcherTile as SwitcherTileType } from "types";
import { SwitcherTile } from "components/SwitcherTile";
import { OnNowTile } from "components/OnNowTile";
import { getJourney } from "data/journeys";
import { switcherTiles } from "selectors/switcher";
import { RailLens } from "components/RailLens";
import { boolToInt } from "utils/boolToInt";
import { useAnimations } from "hooks/useAnimations";
import gsap from "gsap";
import { transitions } from "constants/transitions";
import {
  switcherIsShowing,
  focusArea as focusAreaSelector,
} from "selectors/app";

const renderItem = (
  tile: SwitcherTileType,
  key: number,
  { focused, fullscreen }: { focused: boolean; fullscreen?: boolean }
) => (
  <StyledItem key={key} focused={focused}>
    {tileComponent(tile, { focused, fullscreen })}
  </StyledItem>
);

const tileComponent = (
  tile: SwitcherTileType,
  { focused, fullscreen }: { focused: boolean; fullscreen: boolean }
) => {
  switch (tile.type) {
    case "onNowTile": {
      const journey = getJourney(tile.journeyId);
      return (
        <OnNowTile
          focused={focused}
          journey={journey}
          pageIndex={tile.pageIndex}
          fullscreen={fullscreen}
          imageUrl={tile.imageUrl}
          providerImageUrl={tile.providerImageUrl}
        />
      );
    }
    default: {
      const { imageUrl, providerImageUrl, title, tag, progress } = tile;
      return (
        <SwitcherTile
          focused={focused}
          imageUrl={imageUrl}
          providerImageUrl={providerImageUrl}
          title={title}
          tag={tag}
          progress={progress}
        />
      );
    }
  }
};

const fullscreenParams = (focusIndex: number) => {
  const scale = screen.width / onNowTile.width;
  return {
    x: -switcherTile.outerWidth * focusIndex * scale,
    y: 0,
    scale,
    opacity: 1,
  };
};

const nonFullscreenParams = (
  showSwitcher: boolean,
  tiles: SwitcherTileType[],
  focusIndex: number
) => {
  const xOffset = switcherTile.outerWidth * focusIndex,
    maxXOffset = Math.max(
      switcherTile.outerWidth * tiles.length -
        grid.column.gap -
        switcher.innerWidth,
      0 // can't be negative
    );
  return {
    x: grid.startX - Math.min(xOffset, maxXOffset),
    y: showSwitcher ? switcher.switcherMode.Y : switcher.browseMode.Y,
    scale: 1,
    opacity: boolToInt(showSwitcher),
  };
};

type RowDimensions = {
  x: number;
  y: number;
  scale: number;
};

const zoomFullscreen = ({ x, y, scale }: RowDimensions, zoom: number) => {
  const { width, height } = screen,
    newWidth = width * zoom,
    newHeight = height * zoom,
    dx = (newWidth - width) / 2,
    dy = (newHeight - height) / 2;
  return {
    x: x * zoom - dx,
    y: y * zoom - dy,
    scale: scale * zoom,
  };
};

export const Switcher = () => {
  const focusIndex = useSelector(s => s.switcher.focusIndex),
    tiles = useSelector(switcherTiles),
    focusArea = useSelector(focusAreaSelector),
    fullscreenMode = useSelector(s => s.app.fullscreenMode),
    showSwitcher = useSelector(switcherIsShowing),
    container = useRef(null),
    row = useRef(null),
    containerParams = fullscreenMode
      ? fullscreenParams(focusIndex)
      : nonFullscreenParams(showSwitcher, tiles, focusIndex);

  useAnimations(
    {
      scope: "switcher",
      reset: params => gsap.set(container.current, params),
      uninterruptible: [
        "fullscreenFadeIn",
        "fullscreenFadeOut",
        "zoomIn",
        "zoomOut",
      ],
      animations: {
        moveLens: (params, timeline) =>
          timeline.to(container.current, {
            ...params,
            ...transitions.moveLens,
          }),
        fullscreenFadeIn: (_, timeline) => {
          const params = fullscreenParams(focusIndex);
          return timeline.fromTo(
            container.current,
            { ...zoomFullscreen(params, 1.1), opacity: 0 },
            { ...params, opacity: 1, duration: 1 }
          );
        },
        fullscreenFadeOut: (_, timeline) => {
          const params = fullscreenParams(focusIndex);
          return timeline.fromTo(
            container.current,
            { ...params },
            { ...zoomFullscreen(params, 0.5), opacity: 0, duration: 0.5 }
          );
        },
        switcherToBrowse: ({ y, opacity }, timeline) =>
          timeline.to(container.current, {
            y: switcher.browseMode.Y,
            opacity: 0,
            ...transitions.switcherToBrowse,
          }),
        browseToSwitcher: (params, timeline) =>
          timeline.to(container.current, {
            ...params,
            ...transitions.browseToSwitcher,
          }),
        zoomIn: (params, timeline) =>
          timeline.to(container.current, {
            //...fullscreenParams(focusIndex),
            ...params,
            ...transitions.zoomIn,
          }),
        zoomOut: (params, timeline) =>
          timeline.to(container.current, {
            //...nonFullscreenParams(showSwitcher, tiles, focusIndex),
            ...params,
            ...transitions.zoomOut,
          }),
      },
    },
    containerParams
  );

  const showLens = focusArea === "switcher" && !fullscreenMode;

  return (
    <Container ref={container} id="switcher">
      <Row ref={row}>
        {tiles.map((tile, index) =>
          renderItem(tile, index, {
            focused: false,
            fullscreen: fullscreenMode,
          })
        )}
      </Row>
      {/*
        Annoyingly we can't do showLens && ... here
        and instead have to use opacity because
        a playing video inside the lens needs to sync correctly
        with the non-focused one (TODO: better way of treating video!)
      */}
      <LensContainer visible={showLens}>
        <RailLens
          x={focusIndex * switcherTile.outerWidth - grid.column.gap}
          y={-switcherTile.extraFocusedHeight / 2}
          index={focusIndex}
          extraWidth={2 * grid.column.gap}
          height={switcherTile.focusedHeight}
          animationScope="switcher"
        >
          {tiles.map((tile, index) =>
            renderItem(tile, index, { focused: true })
          )}
        </RailLens>
      </LensContainer>
    </Container>
  );
};
