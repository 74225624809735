import { Action } from "types/actions";
import clamp from "lodash/clamp";

export type TvState = Readonly<{
  muted: boolean;
  volume: number;
  volumeVisible: boolean;
  standbyMode: boolean;
  paused: boolean;
}>;

export const MAX_VOLUME = 100;

export const initialTvState = {
  muted: false,
  volume: MAX_VOLUME,
  volumeVisible: false,
  standbyMode: false,
  paused: false,
};

export const tvReducer = (state: TvState = initialTvState, action: Action) => {
  switch (action.type) {
    case "CHANGE_VOLUME": {
      const vol = state.volume + action.value;
      return { ...state, volume: clamp(vol, 0, MAX_VOLUME) };
    }
    case "SET_VOLUME": {
      return { ...state, volume: clamp(action.value, 0, MAX_VOLUME) };
    }
    case "MUTE":
      return { ...state, muted: true };

    case "UNMUTE":
      return { ...state, muted: false };

    case "SHOW_VOLUME":
      return { ...state, volumeVisible: true };

    case "HIDE_VOLUME":
      return { ...state, volumeVisible: false };

    case "STANDBY.TOGGLE":
      return { ...state, standbyMode: !state.standbyMode };

    case "STANDBY.OFF":
      return { ...state, standbyMode: false };

    case "PAUSE":
      return { ...state, paused: true };

    case "UNPAUSE":
      return { ...state, paused: false };

    case "TOGGLE_PAUSE":
      return { ...state, paused: !state.paused };

    default:
      return state;
  }
};
