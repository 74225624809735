import React from "react";
import { observer } from "mobx-react-lite";
import { Hero } from "./Hero";
import { useSelector } from "store";
import { heroSelector } from "selectors/hero";
import { sidebar } from "constants/dimensions";
import { tvCategoriesIsShowing } from "selectors/browse";

export const HeroController = observer(() => {
  const hero = useSelector(heroSelector),
    showTvCategories = useSelector(tvCategoriesIsShowing);

  return (
    <Hero hero={hero} offsetX={showTvCategories ? sidebar.extraWidth : 0} />
  );
});
