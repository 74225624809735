import { Rail } from "types";

export default {
  type: "rail",
  uuid: "6e8f604e-7440-4814-a314-bc6160f213fc",
  template: "tile-landscape",
  title: "My List",
  tiles: [
    {
      type: "titleTile",
      title: "My List",
      actionText: "View All",
      hero: {
        mode: "standard",
        title: "My List",
        synopsis: "Your watchlist of shows and movies",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/f0b89556-25db-4ac7-8b5e-51d817f49a06/background/{width}",
      },
    },
    {
      type: "landscapeTile",
      title: "About Time",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/f0b89556-25db-4ac7-8b5e-51d817f49a06/16-9/{width}",
      hero: {
        mode: "standard",
        title: "About Time",
        synopsis:
          "On learning he can time travel, Domhnall Gleeson tries to engineer the perfect relationship with Rachel McAdams. Romance from Richard Curtis. Strong language/sex/nudity. (2013)(119 mins)",
        metadata: "1h 45m   ",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/f0b89556-25db-4ac7-8b5e-51d817f49a06/background/{width}",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
    },
    {
      type: "landscapeTile",
      title: "How to Train Your Dragon",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/90e92a4e-df7b-4cd8-9f14-9bdd34f4c90e/16-9/{width}",
      hero: {
        mode: "standard",
        title: "How to Train Your Dragon",
        synopsis:
          "As his father wages a war against dragons, a viking teen secretly befriends one of the fire-breathing fiends. Sublime DreamWorks animation. Contains flashing images. (2010)(94 mins) ",
        metadata: "1h 45m   ",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/90e92a4e-df7b-4cd8-9f14-9bdd34f4c90e/background/{width}",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
    },
    {
      type: "landscapeTile",
      title: "Sleeping Beauty",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/90fd3595-71a2-459e-a247-3a5b6faeaae4/16-9/{width}",
      hero: {
        mode: "standard",
        title: "Sleeping Beauty",
        synopsis:
          "In this classic Disney animation, wicked fairy Maleficent places a young princess under a curse that only true love can break. Contains flashing images. (1959)(75 mins) Also in HD",
        metadata: "1h 45m   ",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/90fd3595-71a2-459e-a247-3a5b6faeaae4/background/{width}",
        logoUrl: "assets/images/disney_plus.png",
      },
      providerImageUrl: "assets/images/disney_plus.png",
    },
    {
      type: "landscapeTile",
      title: "Jumanji: Welcome to the Jungle",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/5fbeeb3b-d30e-4692-a0a5-5a9a9c35dadb/16-9/{width}",
      hero: {
        mode: "standard",
        title: "Jumanji: Welcome to the Jungle",
        synopsis:
          "Four teens in detention discover an old video game that sucks them into the dangerous world of Jumanji. Dwayne Johnson stars. (2017)(115 mins)",
        metadata: "1h 45m   ",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/5fbeeb3b-d30e-4692-a0a5-5a9a9c35dadb/background/{width}",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
    },
    {
      type: "landscapeTile",
      title: "Jumanji",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/96cdd7a8-0a8a-4361-8774-75b2576c1f6c/16-9/{width}",
      hero: {
        mode: "standard",
        title: "Jumanji",
        synopsis:
          "A man trapped for 26 years inside a magical boardgame is freed, along with a horde of jungle beasts. Starring Robin Williams. Flashing images. (1995)(100 mins)",
        metadata: "1h 45m   ",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/96cdd7a8-0a8a-4361-8774-75b2576c1f6c/background/{width}",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
    },
    {
      type: "landscapeTile",
      title: "The Shining",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/d92468ca-49b9-4335-a8c8-1080f4340ab5/16-9/{width}",
      hero: {
        mode: "standard",
        title: "The Shining",
        synopsis:
          "Caretaking a remote hotel, writer Jack Nicholson slowly succumbs to murderous insanity. Stanley Kubrick directs this unsettling horror classic. Contains violent scenes. (1980)(114 mins) Also in HD",
        metadata: "1h 45m   ",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/d92468ca-49b9-4335-a8c8-1080f4340ab5/background/{width}",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
    },
    {
      type: "landscapeTile",
      title: "The Greatest Showman",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/6c19ce32-fea1-47f1-b057-1ff4cb208439/16-9/{width}",
      hero: {
        mode: "standard",
        title: "The Greatest Showman",
        synopsis:
          "Hugh Jackman dons a top hat and tap shoes to play legendary circus entrepreneur PT Barnum. Showstopping musical biopic with Zac Efron. (2017)(101 mins)",
        metadata: "1h 45m   ",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/6c19ce32-fea1-47f1-b057-1ff4cb208439/background/{width}",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
    },
    {
      type: "landscapeTile",
      title: "The Addams Family",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/b775eb41-edda-4271-bfde-f7f9a7b0f6f2/16-9/{width}",
      hero: {
        mode: "standard",
        title: "The Addams Family",
        synopsis:
          "Animated adventure sees the Addams family's lives disturbed by a TV host with macabre intentions. Charlize Theron and Oscar Isaac star. Strong language/flashing images. (2019)(86 mins)",
        metadata: "1h 45m   ",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/b775eb41-edda-4271-bfde-f7f9a7b0f6f2/background/{width}",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
    },
    {
      type: "landscapeTile",
      title: "Addams Family Values",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/4abf333d-c18d-41b7-acbb-c33af23c95c4/16-9/{width}",
      hero: {
        mode: "standard",
        title: "Addams Family Values",
        synopsis:
          "Sinister sequel fun as Uncle Fester falls for the fiendish family's suspicious new babysitter. Comedy with Anjelica Huston, with comic violence and flashing images. (1993)(90 mins) ",
        metadata: "1h 45m   ",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/4abf333d-c18d-41b7-acbb-c33af23c95c4/background/{width}",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
    },
    {
      type: "landscapeTile",
      title: "Watchmen",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/63850f6b-344c-446e-b18d-81801b1482b0/16-9/{width}",
      hero: {
        mode: "standard",
        title: "Watchmen",
        synopsis:
          "The murder of a costumed avenger brings his superhero colleagues out of retirement in this brilliantly brutal adaptation of Alan Moore's formidable graphic novel. (2009)(156 mins) ",
        metadata: "1h 45m   ",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/63850f6b-344c-446e-b18d-81801b1482b0/background/{width}",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
    },
    {
      type: "landscapeTile",
      title: "Terminator 3: Rise of the Machines",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/750fa03d-7bb1-4682-8bd6-9400bd6b7b13/16-9/{width}",
      hero: {
        mode: "standard",
        title: "Terminator 3: Rise of the Machines",
        synopsis:
          "Arnold Schwarzenegger returns to protect the future leader of the resistance and prevent the end of the human race. Sci-fi. Strong language/violence/flashing images. (2003)(105 mins)",
        metadata: "1h 45m   ",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/750fa03d-7bb1-4682-8bd6-9400bd6b7b13/background/{width}",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
    },
    {
      type: "landscapeTile",
      title: "Terminator Salvation",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/52e3bcc7-f273-46c2-bbef-980a94b34598/16-9/{width}",
      hero: {
        mode: "standard",
        title: "Terminator Salvation",
        synopsis:
          "With the world in the grip of murderous machines, Christian Bale fights to save humanity in this killer retooling of the cyborg saga. Contains violence/flashing images. (2009)(111 mins) Also in HD",
        metadata: "1h 45m   ",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/52e3bcc7-f273-46c2-bbef-980a94b34598/background/{width}",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
    },
    {
      type: "landscapeTile",
      title: "Terminator Genisys",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/bf4e4420-cdf7-44b5-b12d-695702b3f3e2/16-9/{width}",
      hero: {
        mode: "standard",
        title: "Terminator Genisys",
        synopsis:
          "Arnold Schwarzenegger is back in this action-packed reboot of the sci-fi-series, co-starring Emilia Clarke. Strong language/violence/flashing images. (2015)(121 mins)",
        metadata: "1h 45m   ",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/bf4e4420-cdf7-44b5-b12d-695702b3f3e2/background/{width}",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
    },
    {
      type: "landscapeTile",
      title: "Ocean's 8",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/a4b039b0-3867-4a3f-8f7d-4f71d3987387/16-9/{width}",
      hero: {
        mode: "standard",
        title: "Ocean's 8",
        synopsis:
          "After getting out of prison, Debbie Ocean plots to steal a priceless necklace from New York's Met Gala. Heist caper with Sandra Bullock. (2018)(106 mins) Also in HD",
        metadata: "1h 45m   ",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/a4b039b0-3867-4a3f-8f7d-4f71d3987387/background/{width}",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
    },
  ],
} as Rail;
