import { videos } from "data/videos";
import { Journey } from "types";

const imageUrl = (filename: string) =>
  `./assets/images/journeys/netflix/${filename}`;

export const netflix: Journey = {
  id: "netflix",
  intro: {
    durationMs: 4060,
    url: videos.netflixIntro,
  },
  pages: [
    {
      type: "imagePage",
      url: imageUrl("menu-page.jpg"),
    },
    {
      type: "imagePage",
      url: imageUrl("show-page.jpg"),
    },
    {
      type: "videoPage",
      url: videos.alwaysBe,
    },
  ],
  title: "Netflix",
};
