import chunk from "lodash/chunk";
import generateUuid from "uuid/v4";
import { GridPage, RailsPage } from "types";

export const gridPageToRailsPage = (
  page: GridPage,
  tilesPerRail: number
): RailsPage => {
  return {
    type: "railsPage",
    rails: chunk(page.tiles, tilesPerRail).map(tiles => ({
      type: "rail",
      uuid: generateUuid(),
      template: page.template,
      tiles,
    })),
  };
};

export const gridPageIndexToRailsPageIndices = (
  index: number,
  tilesPerRail: number
): [number, { [j: number]: number }] => {
  const railIndex = Math.floor(index / tilesPerRail),
    tileIndex = index % tilesPerRail;
  return [
    railIndex,
    // Guarantees a better animation if rails either side are pre-populated
    {
      [railIndex - 1]: tileIndex,
      [railIndex]: tileIndex,
      [railIndex + 1]: tileIndex,
    },
  ];
};
