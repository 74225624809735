import styled from "@emotion/styled";

export const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  transform-origin: left top;
`;

export const RowContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  overflow: hidden;
  border-radius: 12px;
`;

export const Row = styled.div`
  position: relative;
  display: flex;
  align-items: stretch;
`;
