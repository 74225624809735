import { Rail } from "types";

export default {
  type: "rail",
  uuid: "895a083e-24c4-4fc7-b23e-6e53f29241ee",
  template: "tile-landscape",
  title: "Because You Watched Tales of NY",
  tiles: [
    {
      type: "landscapeTile",
      title: "True Detective",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/e5d52d42-27e6-47af-815e-f736917c53aa/16-9/{width}?providername=skyatlantic",
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_skyatlantic/408/84",
      hero: {
        mode: "standard",
        title: "True Detective",
        metadata: "1h 45m   ",
        synopsis:
          "HBO’s critically-acclaimed crime anthology series, featuring some of Hollywood’s biggest names.",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skyatlantic/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/e5d52d42-27e6-47af-815e-f736917c53aa/background/1428",
      },
    },
    {
      type: "landscapeTile",
      title: "Six Feet Under",
      imageUrl:
        "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/d46838bd25b43e03e66979e399811b01/{width}/{height}/item.jpg",
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_skyatlantic/408/84",
      hero: {
        mode: "standard",
        title: "Six Feet Under",
        metadata: "1h 45m   ",
        synopsis:
          "Alan Ball's award-winning drama takes a darkly comical look at members of a dysfunctional California family that runs an independent funeral home.",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skyatlantic/408/84",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/4f3dff5ebe9af8c1b72061e846945122/hero.jpg",
      },
    },
    {
      type: "landscapeTile",
      title: "Band of Brothers",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/6f8899ed-0903-414b-9844-2336d036b51f/16-9/{width}",
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_skyatlantic/408/84",
      hero: {
        mode: "standard",
        title: "Band of Brothers",
        metadata: "1h 45m   ",
        synopsis:
          "Co-produced by Steven Spielberg and Tom Hanks, this award-winning drama follows Easy Company through the duration of World War II. Starring Damian Lewis, James McAvoy and Simon Pegg.\r",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skyatlantic/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/6f8899ed-0903-414b-9844-2336d036b51f/background/{width}",
      },
    },
    {
      type: "landscapeTile",
      title: "The Pacific",
      imageUrl:
        "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/ef0bbe8c9a5a26cb2dc97bfeb30930ce/{width}/{height}/item.jpg",
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_skyatlantic/408/84",
      hero: {
        mode: "standard",
        title: "The Pacific",
        metadata: "1h 45m   ",
        synopsis:
          "Produced by Tom Hanks and Steven Spielberg, the acclaimed companion series to Band Of Brothers follows three US marines as they brave the South East Pacific war zone in World War II.",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skyatlantic/408/84",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/f5ec3fd570461ee8a475e33f790e870e/hero.jpg",
      },
    },
    {
      type: "landscapeTile",
      title: "Deadwood",
      imageUrl:
        "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/b5cadd4d78c57cdabb50154bf9b7d29a/{width}/{height}/item.jpg",
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_skyatlantic/408/84",
      hero: {
        mode: "standard",
        title: "Deadwood",
        metadata: "1h 45m   ",
        synopsis:
          "Timothy Olyphant and Ian McShane star in the uncompromising western, where a diverse assembly of civilians, entrepreneurs and low-life outlaws look for a new life in the lawless town of Deadwood.",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skyatlantic/408/84",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/bfbf663a8c18e2f9708b122450bef310/hero.jpg",
      },
    },
    {
      type: "landscapeTile",
      title: "Treme",
      imageUrl:
        "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/16904b3236b985cdbf74519cab2efc9f/{width}/{height}/item.jpg",
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_skyatlantic/408/84",
      hero: {
        mode: "standard",
        title: "Treme",
        metadata: "1h 45m   ",
        synopsis:
          "Drama from the makers of The Wire focusing on the culturally rich communities of New Orleans as they rebuild in the wake of Hurricane Katrina. ",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skyatlantic/408/84",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/7799236635b0bc407f89c9dca34d3467/hero.jpg",
      },
    },
    {
      type: "landscapeTile",
      title: "The Knick",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/88566585-95fa-4f80-9c44-69e747fdc337/16-9/{width}?providername=skyatlantic",
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_skyatlantic/408/84",
      hero: {
        mode: "standard",
        title: "The Knick",
        metadata: "1h 45m   ",
        synopsis:
          "Series follows the groundbreaking surgeons, nurses and staff at the Knickerbocker Hospital, who push the bounds of medicine in a time before antibiotics and of high mortality rates.",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skyatlantic/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/88566585-95fa-4f80-9c44-69e747fdc337/background/1428",
      },
    },
    {
      type: "landscapeTile",
      title: "Twin Peaks",
      imageUrl:
        "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/65fb5edf49914995a9f7888fcb45d2ac/{width}/{height}/item.jpg",
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_skyatlantic/408/84",
      hero: {
        mode: "standard",
        title: "Twin Peaks",
        metadata: "1h 45m   ",
        synopsis:
          "Agent Dale Cooper heads the investigation into the murder of teenager Laura Palmer in this detective drama, set in the fictional town of Twin Peaks, which abounds with quirky characters.",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skyatlantic/408/84",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/da8b4120c3f3a4c29e08497161b1b4d5/hero.jpg",
      },
    },
    {
      type: "landscapeTile",
      title: "Oz",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/7401610a-97b9-4960-9bad-3cb63d8f329c/16-9/{width}",
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_skyatlantic/408/84",
      hero: {
        mode: "standard",
        title: "Oz",
        metadata: "1h 45m   ",
        synopsis:
          "Ground-breaking drama following the daily activities of warders and inmates at Oswald State Correctional Facility.",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skyatlantic/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/7401610a-97b9-4960-9bad-3cb63d8f329c/background/{width}",
      },
    },
    {
      type: "titleTile",
      actionText: "View all",
      hero: {
        mode: "standard",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/7401610a-97b9-4960-9bad-3cb63d8f329c/background/{width}",
      },
    },
  ],
} as Rail;
