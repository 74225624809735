import styled from "@emotion/styled";
import { hero, screen } from "constants/dimensions";
import { CSSProperties } from "react";

export const Container = styled.div(
  ({
    backgroundImageUrl,
    maskBackground,
    overlay,
  }: {
    backgroundImageUrl?: string;
    maskBackground: boolean;
    overlay: boolean;
  }) => `
  position: absolute;
  top: 0;
  display: flex;
  height: ${hero.height}px;
  flex-direction: column;
  width: ${hero.width}px;
  background: ${
    backgroundImageUrl ? `url("` + backgroundImageUrl + `") no-repeat` : ""
  };
  mask-image: ${
    maskBackground ? 'url("assets/images/hero/hero-brand-bg-mask.png")' : ""
  };
  z-index: ${overlay ? 1 : "auto"};
`
);

export const ContentsContainer = styled.div(
  ({ offsetX }: { offsetX: number }) => ({
    position: "absolute",
    transform: `translateX(${offsetX}px)`,
  })
);

export type HeroMask = "title" | "synopsis" | "brand";

const heroMaskUrl = (mask: HeroMask) => {
  switch (mask) {
    case "title":
      return "assets/images/hero/hero-title-art-mask-3.png";
    case "brand":
      return "assets/images/hero/hero-brand-mask.png";
    case "synopsis":
    default:
      return "assets/images/hero/hero-synopsis-mask.png";
  }
};

export const heroImageStyle = (
  mask?: HeroMask,
  opacity?: number
): CSSProperties => {
  const maskImage = mask && `url("${heroMaskUrl(mask)}")`;
  return {
    display: "block",
    position: "absolute",
    maskImage,
    WebkitMaskImage: maskImage, // need this too because we're not using styled.img
    height: hero.height,
    width: hero.width,
    opacity: opacity ?? 1,
  };
};

export const TitleImage = styled.img({
  display: "block",
  position: "absolute",
  left: hero.titleArt.x,
  top: hero.titleArt.y,
  height: hero.titleArt.height,
  width: hero.titleArt.width,
});

export const StandardWrapper = styled.div`
  position: relative;
  width: 920px;
  height: 451px;
  margin: 226px 0 0 88px;
`;

export const LogoContainer = styled.div`
  height: ${hero.logo.height}px;
  position: absolute;
  top: -90px;
  width: ${hero.logo.width}px;
`;

export const Logo = styled.img`
  display: block;
  height: 100%;
  margin-left: 4px;
`;

export const LargeTitleWrapper = styled.div`
  margin: 226px 0 0 96px;
  width: 887px;
`;

export const HugeTitleWrapper = styled.div`
  position: relative;
`;

export const HugeTitleMask = styled.div({
  position: "absolute",
  width: screen.width,
  height: screen.height,
  background: `url("assets/images/hero/hero-huge-title-bg.png")`,
  maskImage: `url("${heroMaskUrl("title")}")`,
});

export const MinilabelWrapper = styled.div`
  position: absolute;
  left: ${hero.hugeTitle.x}px;
  top: ${hero.hugeTitle.minilabelY}px;
  white-space: nowrap;
`;

export const HugeTitleTextWrapper = styled.div`
  position: absolute;
  left: ${hero.hugeTitle.x}px;
  top: ${hero.hugeTitle.y}px;
  white-space: nowrap;
`;
