import { Rail } from "types";

export default {
  type: "rail",
  uuid: "ded398ea-0aa6-4784-ab42-945ee73290ff",
  template: "tile-landscape",
  title: "Box Office",
  tiles: [
    {
      type: "landscapeTile",
      title: "L1 P/O 2nd Leg: Oxford V Portsmouth",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/258c2b27-fc9f-4311-a2d8-3721bfa36dc9/16-9/{width}",
      hero: {
        mode: "standard",
        title: "L1 P/O 2nd Leg: Oxford V Portsmouth",
        metadata: "1h 45m   ",
        synopsis:
          "Oxford United welcome Portsmouth to the Kassam Stadium for the second leg of their Sky Bet League One play-off semi-final. The tie is finely poised after a 1-1 draw in the first leg.",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/258c2b27-fc9f-4311-a2d8-3721bfa36dc9/background/{width}",
      },
    },
    {
      type: "landscapeTile",
      title: "L1 P/O SF 2nd Leg: Wycombe V Fleetwood",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/258c2b27-fc9f-4311-a2d8-3721bfa36dc9/16-9/{width}",
      hero: {
        mode: "standard",
        title: "L1 P/O SF 2nd Leg: Wycombe V Fleetwood",
        metadata: "1h 45m   ",
        synopsis:
          "Gareth Ainsworth's Wycombe Wanderers face Joey Barton's Fleetwood Town at Adams Park in the second leg of their Sky Bet League One play-off semi-final. Wycombe lead 4-1 after the first leg.",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/258c2b27-fc9f-4311-a2d8-3721bfa36dc9/background/{width}",
      },
    },
    {
      type: "landscapeTile",
      title: "Anthony Joshua: 1989 - 2014",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/9cfa0a1b-9bcd-4837-9f79-e38640607fd8/16-9/{width}",
      hero: {
        mode: "standard",
        title: "Anthony Joshua: 1989 - 2014",
        metadata: "1h 45m   ",
        synopsis:
          "From childhood to champion, remember the sensational rise of Anthony Joshua with the full fascinating story of the heavyweight hero's journey to stardom.",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/9cfa0a1b-9bcd-4837-9f79-e38640607fd8/background/{width}",
      },
    },
    {
      type: "landscapeTile",
      title: "Rocket Mortgage Classic Hlts",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/26cde2f6-3f75-4871-8fbb-5dc60289ff51/16-9/{width}",
      hero: {
        mode: "standard",
        title: "Rocket Mortgage Classic Hlts",
        metadata: "1h 45m   ",
        synopsis:
          "The best of the action from the Rocket Mortgage Classic on the PGA Tour from the Detroit Golf Club in Michigan. This is just the second time that this event has been held.",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/26cde2f6-3f75-4871-8fbb-5dc60289ff51/background/{width}",
      },
    },
    {
      type: "landscapeTile",
      title: "Fit In 5",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/693ecbdb-9968-479e-8973-4c2aebf62c75/16-9/{width}",
      hero: {
        mode: "standard",
        title: "Fit In 5",
        metadata: "1h 45m   ",
        synopsis: "Marvin Ambrosius takes us through the Fit In 5 workout.",
        logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_skyone/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/693ecbdb-9968-479e-8973-4c2aebf62c75/background/{width}",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_skyone/408/84",
    },
    {
      type: "landscapeTile",
      title: "The Football Show: Monday",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/28d48690-9935-412f-bdfa-86dfa6c8eed8/16-9/{width}",
      hero: {
        mode: "standard",
        title: "The Football Show: Monday",
        metadata: "1h 45m   ",
        synopsis:
          "A look at the all the major stories making the football headlines, with discussion of all the big talking points from within the game.",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/28d48690-9935-412f-bdfa-86dfa6c8eed8/background/{width}",
      },
    },
    {
      type: "landscapeTile",
      title: "NBA Retro: Bulls v Jazz '98",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/45a6ff44-014d-47a9-b7b8-7f4c17cb9a03/16-9/{width}",
      hero: {
        mode: "standard",
        title: "NBA Retro: Bulls v Jazz '98",
        metadata: "1h 45m   ",
        synopsis:
          "Look back at one of the most famous games in NBA history as Michael Jordan hit one of the most iconic shots ever to lift the Chicago Bulls over the Utah Jazz for their sixth title. ",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/45a6ff44-014d-47a9-b7b8-7f4c17cb9a03/background/{width}",
      },
    },
    {
      type: "landscapeTile",
      title: "Conor McGregor: Notorious",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/d7d0e21a-e33f-4105-a20d-de3482d6775c/16-9/{width}",
      hero: {
        mode: "standard",
        title: "Conor McGregor: Notorious",
        metadata: "1h 45m   ",
        synopsis:
          "From making ends meet to a lavish life at the top, an all-access look at the remarkable rise of Conor McGregor, with all the highs and lows of his ascent.",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/d7d0e21a-e33f-4105-a20d-de3482d6775c/background/{width}",
        heroTitleImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/00c6e0b05e8f54461ae288f5514cf5c7/herotitle.png",
      },
    },
    {
      type: "landscapeTile",
      title: "Lewis Hamilton On Diversity In F1",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/8c9de6e3-80c1-49a0-930c-62a6c91d57ea/16-9/{width}",
      hero: {
        mode: "standard",
        title: "Lewis Hamilton On Diversity In F1",
        metadata: "1h 45m   ",
        synopsis:
          "Mercedes' Lewis Hamilton speaks ahead of the season opener in Austria, as he shares his thoughts on diversity in the sport.",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/8c9de6e3-80c1-49a0-930c-62a6c91d57ea/background/{width}",
      },
    },
    {
      type: "landscapeTile",
      title: "NRL: Bulldogs v Rabbitohs",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/cb7d27f7-a755-45d6-874e-cfc016f6eaa6/16-9/{width}",
      hero: {
        mode: "standard",
        title: "NRL: Bulldogs v Rabbitohs",
        metadata: "1h 45m   ",
        synopsis:
          "The Canterbury-Bankstown Bulldogs take on the South Sydney Rabbitohs as round eight of the 2020 NRL season continues.",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/cb7d27f7-a755-45d6-874e-cfc016f6eaa6/background/{width}",
      },
    },
    {
      type: "landscapeTile",
      title: "NRL: Sea Eagles v Knights",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/cb7d27f7-a755-45d6-874e-cfc016f6eaa6/16-9/{width}",
      hero: {
        mode: "standard",
        title: "NRL: Sea Eagles v Knights",
        metadata: "1h 45m   ",
        synopsis:
          "The Manly-Warringah Sea Eagles take on the Newcastle Knights as round eight of the 2020 NRL season continues.",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/cb7d27f7-a755-45d6-874e-cfc016f6eaa6/background/{width}",
      },
    },
    {
      type: "landscapeTile",
      title: "The Women's Football Show",
      imageUrl:
        "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/a2fc08814a69c4008ca9a5815eeef0b7/{width}/{height}/item.jpg",
      hero: {
        mode: "standard",
        title: "The Women's Football Show",
        metadata: "1h 45m   ",
        synopsis:
          "A look at all the latest news and major talking points from around the world of women's football, with pundits and guests joining to offer their insight.",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/c72ded765bc32ec17952ed03f592ad38/hero.jpg",
      },
    },
    {
      type: "landscapeTile",
      title: "Austrian F1 Qualifying",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/242fe218-5241-466e-a34d-8030e9502353/16-9/{width}",
      hero: {
        mode: "standard",
        title: "Austrian F1 Qualifying",
        metadata: "1h 45m   ",
        synopsis:
          "The qualifying session from Formula 1's first race back at the Austrian Grand Prix from the Red Bull Ring in Spielberg. Charles Leclerc secured pole at this track last season.",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/242fe218-5241-466e-a34d-8030e9502353/background/{width}",
      },
    },
    {
      type: "landscapeTile",
      title: "Soccer AM",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/4138276e-0990-4e1e-89df-88ac69a25049/16-9/{width}",
      hero: {
        mode: "standard",
        title: "Soccer AM",
        metadata: "1h 45m   ",
        synopsis:
          "Fenners and Jimmy are joined by goalkeeping legend Petr Cech and comedian Mo Gilligan. Plus, Everton's Tom Davies joins Tubes for My Perfect Midfielder, and much more.",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skysppl/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/4138276e-0990-4e1e-89df-88ac69a25049/background/{width}",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_skysppl/408/84",
    },
    {
      type: "landscapeTile",
      title: "Bellator MMA: Straus v Pitbull",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/9ece5b5a-78e6-4ab3-bc65-f1aecd3cfb6f/16-9/{width}",
      hero: {
        mode: "standard",
        title: "Bellator MMA: Straus v Pitbull",
        metadata: "1h 45m   ",
        synopsis:
          "After nearly 18 months away from the Bellator cage, featherweight champ Daniel Straus is back to defend the belt against the man he dethroned, Patricio Pitbull. ",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/9ece5b5a-78e6-4ab3-bc65-f1aecd3cfb6f/background/{width}",
      },
    },
    {
      type: "titleTile",
      actionText: "View all",
      hero: {
        mode: "standard",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/9ece5b5a-78e6-4ab3-bc65-f1aecd3cfb6f/background/{width}",
      },
    },
  ],
} as Rail;
