import styled from "@emotion/styled";
import { quickAccessMenu, screen } from "constants/dimensions";

export const Container = styled.div`
  position: relative;
`;

export const TextContainer = styled.div`
  position: absolute;
  left: 0;
  bottom: 337px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  width: ${screen.width}px;
`;

export const ButtonsContainer = styled.div`
  margin: 56px 0 ${quickAccessMenu.marginBottom}px
    ${quickAccessMenu.marginLeft}px;
`;
