import { BrandId, Brand } from "types";
import { videos } from "./videos";

const BRANDS = {
  skycinema: {
    brandId: "skycinema" as BrandId,
    titleImageUrl: "assets/images/brands/skycinema/title.png",
    backgroundImageUrl: "assets/images/brands/skycinema/background.jpg",
    introVideoUrl: videos.skyCinemaIntro,
    introVideoDurationMs: 1920,
  },
};

export const getBrand = (brandId: BrandId): Brand => {
  const brand = BRANDS[brandId];
  if (brand) {
    return brand;
  } else {
    throw new Error(`Couldn't find brand with id ${brandId}`);
  }
};
