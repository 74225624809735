import React from "react";
import { Container } from "./QAMButton.styles";
import { Text } from "components/Text";
import { GlassFrame } from "components/GlassFrame";
import { quickAccessMenu } from "constants/dimensions";
import { Fade } from "components/Fade";

type Props = { title: string; focused: boolean };

export const QAMButton = ({ title, focused }: Props) => (
  <Container>
    <Text weight="medium" size="small">
      {title}
    </Text>
    <Fade
      visible={focused}
      duration={0.3}
      render={() => (
        <GlassFrame
          width={quickAccessMenu.button.width}
          height={quickAccessMenu.button.height}
        />
      )}
    />
  </Container>
);
