import React from "react";
import ReactPlayer from "react-player";
import { VideoPage as VideoPageType } from "types";
import { useSelector } from "store";
import { MAX_VOLUME } from "reducers/tv";

type Props = {
  page: VideoPageType;
  focused: boolean;
};

export const VideoPage = ({ page, focused }: Props) => {
  const { volume, muted, paused } = useSelector(s => s.tv);
  return (
    <ReactPlayer
      url={page.url}
      playing={!paused}
      loop={page.loop ?? true}
      width="100%"
      height="100%"
      volume={volume / MAX_VOLUME}
      muted={focused || muted}
    />
  );
};
