import styled from "@emotion/styled";
import { tiles } from "constants/dimensions";

const { squareTile } = tiles;

export const Container = styled.div(
  ({ focused }: { focused: boolean }) => `
  width: ${squareTile.width}px;
  height: ${squareTile.height}px;
  box-sizing: border-box;
  border-radius: ${focused ? 0 : squareTile.borderRadius}px;
  overflow: hidden;
`
);

export const Image = styled.img`
  width: 100%;
`;
