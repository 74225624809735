import styled from "@emotion/styled";
import { tiles } from "constants/dimensions";
import { tilePale } from "constants/colours";
import { tileShadow } from "constants/effects";

const { nowAndNextTile } = tiles;

export const Container = styled.div(
  ({ focused }: { focused: boolean }) => `
  box-shadow: ${tileShadow};
  box-sizing: border-box;
  width: ${nowAndNextTile.width}px;
  height: ${nowAndNextTile.height}px;
  position: relative;
  background-color: ${tilePale};
  box-sizing: border-box;
  border-radius: ${focused ? 0 : nowAndNextTile.borderRadius}px;
  overflow: hidden;
`
);

export const NowContainer = styled.div`
  background-color: rgba(241, 241, 241, 0.08);
  height: ${nowAndNextTile.nowHeight}px;
  padding: 20px 30px 30px;
`;

export const NextContainer = styled.div`
  height: ${nowAndNextTile.nextHeight}px;
  padding: 32px 30px;
`;

export const Logo = styled.img`
  width: 100%;
  height: auto;
  margin-bottom: 24px;
`;
