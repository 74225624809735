import { RailsPage } from "types";
import today from "./today";
import yesterday from "./yesterday";
import sunday from "./sunday";
import saturday from "./saturday";
import friday from "./friday";
import thursday from "./thursday";
import wednesday from "./wednesday";
import tuesday from "./tuesday";
import utils from "../sharedRails/utils";

export default {
  type: "railsPage",
  rails: [
    today,
    yesterday,
    sunday,
    saturday,
    friday,
    thursday,
    wednesday,
    tuesday,
    utils,
  ],
  title: {
    text: "BBC One",
    logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_bbcone/365/75",
  },
} as RailsPage;
