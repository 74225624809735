import React from "react";
import { Container, Image } from "./SquareTile.styles";
import { SquareTile as SquareTileType } from "types";

type Props = {
  focused: boolean;
  tile: SquareTileType;
};

export const SquareTile = ({ tile: { title, imageUrl }, focused }: Props) => {
  return (
    <Container focused={focused}>
      <Image alt={title} src={imageUrl} />
    </Container>
  );
};
