import { Rail } from "types";

export default {
  type: "rail",
  uuid: "801237c8-c065-4cbb-a79b-ae4fd91d11a2",
  template: "tile-cover",
  title: "We Love Books",
  tiles: [
    {
      type: "coverTile",
      title: "Revolting Rhymes",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/304c7221-242a-46bd-b723-8f4ca9d5ec21/cover/{height}",
      hero: {
        mode: "standard",
        title: "Revolting Rhymes",
        metadata: "1h 45m   ",
        synopsis:
          "Two half-hour animated films based on the much-loved rhymes written by Roald Dahl and illustrated by Quentin Blake.",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/304c7221-242a-46bd-b723-8f4ca9d5ec21/background/{width}",
        heroTitleImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/a420be14b36fb7508cf57f9104ee31bf/herotitle.png",
      },
    },
    {
      type: "coverTile",
      title: "Horrid Henry",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/835c4dbd-5ee6-4f67-b906-98af7aea014d/cover/{height}",
      hero: {
        mode: "standard",
        title: "Horrid Henry",
        metadata: "1h 45m   ",
        synopsis:
          "Meet Henry. They don't call him Horrid Henry for nothing. He really is quite horrid. Because when someone gets tricked, when rules are being broken, when trouble's around... it's usually Henry.",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/835c4dbd-5ee6-4f67-b906-98af7aea014d/background/{width}",
      },
    },
    {
      type: "coverTile",
      title: "Where's Wally",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/dc9f8fcd-7943-4c74-8fb4-5a8d37e36799/cover/{height}",
      hero: {
        mode: "standard",
        title: "Where's Wally",
        metadata: "1h 45m   ",
        synopsis:
          "As members of a worldwide travel society, adventurers Wally and Wenda must complete missions so they can one day become wizard-level wanderers. But standing in their way is rival globetrotter Odlulu!",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/dc9f8fcd-7943-4c74-8fb4-5a8d37e36799/background/{width}",
      },
    },
    {
      type: "coverTile",
      title: "David Walliams' Ratburger",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/09c78a2e-8c9a-45ab-8d5a-21c1a10e9066/cover/{height}",
      hero: {
        mode: "standard",
        title: "David Walliams' Ratburger",
        metadata: "1h 45m   ",
        synopsis:
          "International Emmy award-winning adaptation of David Walliams' book. Zoe's dancing rat is in danger when gross burger van man Burt (Walliams) earmarks it for his menu. With Sheridan Smith. ",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/09c78a2e-8c9a-45ab-8d5a-21c1a10e9066/background/{width}",
      },
    },
    {
      type: "coverTile",
      title: "Zog",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/86d4cc20-ca1c-4605-b2c1-8a2148be184b/cover/{height}",
      hero: {
        mode: "standard",
        title: "Zog",
        metadata: "1h 45m   ",
        synopsis:
          "Lenny Henry narrates the animated tale of an accident-prone dragon who forms an unlikely friendship with a young girl. With the voice of Kit Harington. Flashing images. (2018)(28 mins)",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/86d4cc20-ca1c-4605-b2c1-8a2148be184b/background/{width}",
      },
    },
    {
      type: "coverTile",
      title: "The Highway Rat",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/268ed80c-9a91-49dc-b390-5f73c9eea17e/cover/{height}",
      hero: {
        mode: "standard",
        title: "The Highway Rat",
        metadata: "1h 45m   ",
        synopsis:
          "Charming animated short about a ravenous rat bandit who craves buns, biscuits and all sweet things. With the voice of David Tennant. Contains flashing images. (2017)(25 mins)",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/268ed80c-9a91-49dc-b390-5f73c9eea17e/background/{width}",
      },
    },
    {
      type: "coverTile",
      title: "The Gruffalo",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/8765c382-072e-48f9-a48b-e9baa029a5ae/cover/{height}",
      hero: {
        mode: "standard",
        title: "The Gruffalo",
        metadata: "1h 45m   ",
        synopsis:
          "A tiny mouse escapes hungry jaws by weaving a tale about an imaginary monster. Then the monster turns up... Animation narrated by Helena Bonham Carter. Flashing images. (2009)(27 mins)",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/8765c382-072e-48f9-a48b-e9baa029a5ae/background/{width}",
      },
    },
    {
      type: "coverTile",
      title: "Peter Rabbit",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/1f3f5dc1-3903-42fb-86a7-f4c982d94a70/cover/{height}",
      hero: {
        mode: "standard",
        title: "Peter Rabbit",
        metadata: "1h 45m   ",
        synopsis:
          "Peter Rabbit and friends overcome obstacles, outwit familiar predators and delight in new discoveries in this heartwarming series based on Beatrix Potter's classic tales.",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/1f3f5dc1-3903-42fb-86a7-f4c982d94a70/background/{width}",
      },
    },
    {
      type: "titleTile",
      actionText: "View all",
      hero: {
        mode: "standard",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/1f3f5dc1-3903-42fb-86a7-f4c982d94a70/background/{width}",
      },
    },
  ],
} as Rail;
