import { Rail } from "types";

export default {
  type: "rail",
  uuid: "2619d9bb-b676-4bf9-b945-0f69cfd5108c",
  template: "tile-landscape",
  title: "Restart what's on now",
  tiles: [
    {
      type: "landscapeTile",
      title: "Cloudy with a Chance of Meatballs",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/1542038d-5690-49f8-a3ee-6eb9d81c9f6f/16-9/{width}",
      hero: {
        mode: "standard",
        title: "Cloudy with a Chance of Meatballs",
        synopsis:
          "An inventor builds a machine that turns rain showers into food showers, but it soon spins dangerously out of control. Tasty animation voiced by Anna Faris. (2009)(87 mins) (3D TV required)",
        metadata: "1h 45m   ",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/1542038d-5690-49f8-a3ee-6eb9d81c9f6f/background/{width}",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_cartoonnetwork/408/84",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_cartoonnetwork/408/84",
    },
    {
      type: "landscapeTile",
      title: "Teen Titans Go!",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/cf14c54c-5863-4227-8f8b-642cc20c158f/16-9/{width}",
      hero: {
        mode: "standard",
        title: "Teen Titans Go!",
        synopsis:
          'This follow-up to the popular "Teen Titans" series takes a more comedic look at the superheroes, showing what life is like for the teens when their capes come off.',
        metadata: "1h 45m   ",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/cf14c54c-5863-4227-8f8b-642cc20c158f/background/{width}",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_cartoonnetwork/408/84",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_cartoonnetwork/408/84",
    },
    {
      type: "landscapeTile",
      title: "The Tom and Jerry Show",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/75528277-3e88-4f3e-bf32-b0782b1697d8/16-9/{width}",
      hero: {
        mode: "standard",
        title: "The Tom and Jerry Show",
        synopsis:
          "Tom and Jerry are back. The animated cat-and-mouse duo known for their cartoon violence star in half-hour episodes that are split into two segments.",
        metadata: "1h 45m   ",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/75528277-3e88-4f3e-bf32-b0782b1697d8/background/{width}",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_cbeebies/408/84",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_cbeebies/408/84",
    },
    {
      type: "landscapeTile",
      title: "Sam & Cat",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/56d3f796-bd1e-464a-b9ff-fd133f4b9dc8/16-9/{width}",
      hero: {
        mode: "standard",
        title: "Sam & Cat",
        synopsis:
          "Sam and Cat become roommates after Cat's grandmother, Nona, moves into an old-age home. They start a babysitting service which leads to a series of misadventures in their lives.",
        metadata: "1h 45m   ",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/56d3f796-bd1e-464a-b9ff-fd133f4b9dc8/background/{width}",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_nickelodeon/408/84",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_nickelodeon/408/84",
    },
    {
      type: "landscapeTile",
      title: "Victor and Valentino",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/af2c6cae-7027-446d-88be-e9fcafe245ec/16-9/{width}",
      hero: {
        mode: "standard",
        title: "Victor and Valentino",
        synopsis:
          "Mischief, mayhem and Mesoamerican myths rule the day for Victor and Valentino. They are half-brothers from Monte Macabre, a small, mysterious town that they explore while simultaneously trying to appreciate each other's quirks and differences.",
        metadata: "1h 45m   ",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/af2c6cae-7027-446d-88be-e9fcafe245ec/background/{width}",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_nickelodeon/408/84",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_nickelodeon/408/84",
    },
  ],
} as Rail;
