import { Rail } from "types";

export default {
  type: "rail",
  uuid: "129acbb2-6bf1-4981-af6e-20a1ef025f53",
  template: "tile-landscape",
  title: "Tuesday",
  tiles: [
    {
      type: "landscapeTile",
      title: "Would I Lie to You?",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/98c30395-e184-41e4-8bab-14d33fa232dc/16-9/{width}",
      hero: {
        mode: "standard",
        title: "Would I Lie to You?",
        metadata: "1h 45m   ",
        synopsis:
          "Episode 7. With guests Tom Allen, Geoff Norcott, Vicki Pepperdine and Esme Young.",
        logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_bbcone/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/98c30395-e184-41e4-8bab-14d33fa232dc/background/{width}",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_bbcone/408/84",
    },
    {
      type: "landscapeTile",
      title: "EastEnders: Secrets from the Square",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/cf9e5d19-4524-4af4-bd90-ac672b2c6651/16-9/{width}",
      hero: {
        mode: "standard",
        title: "EastEnders: Secrets from the Square",
        metadata: "1h 45m   ",
        synopsis:
          "Mick and Linda. Stacey Dooley is joined by Danny Dyer and Kellie Bright to talk all things Mick and Linda.",
        logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_bbcone/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/cf9e5d19-4524-4af4-bd90-ac672b2c6651/background/{width}",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_bbcone/408/84",
    },
    {
      type: "landscapeTile",
      title: "Panorama",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/f26098f8-f572-48c6-a4fd-0f8e9f42169a/16-9/{width}",
      hero: {
        mode: "standard",
        title: "Panorama",
        metadata: "1h 45m   ",
        synopsis:
          "Hunting the Neo-Nazis. Panorama investigates a global network of neo-Nazis that is now recruiting in the UK. Contains some upsetting scenes.",
        logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_bbcone/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/f26098f8-f572-48c6-a4fd-0f8e9f42169a/background/{width}",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_bbcone/408/84",
    },
    {
      type: "landscapeTile",
      title: "The One Show",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/f6ed54d7-79a6-4c84-886e-7b65a0e033c7/16-9/{width}",
      hero: {
        mode: "standard",
        title: "The One Show",
        metadata: "1h 45m   ",
        synopsis:
          "22/06/2020. Alex and Chris Ramsey help to wish BBC legend Dame Esther Rantzen a happy 80th birthday.",
        logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_bbcone/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/f6ed54d7-79a6-4c84-886e-7b65a0e033c7/background/{width}",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_bbcone/408/84",
    },
    {
      type: "landscapeTile",
      title: "BBC News Special",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/c9594d2e-069d-4058-9e23-90cfc036d6c0/16-9/{width}",
      hero: {
        mode: "standard",
        title: "BBC News Special",
        metadata: "1h 45m   ",
        synopsis:
          "22/06/2020. The latest news including a Downing Street news conference on the coronavirus pandemic.",
        logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_bbcone/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/c9594d2e-069d-4058-9e23-90cfc036d6c0/background/{width}",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_bbcone/408/84",
    },
    {
      type: "landscapeTile",
      title: "The Bidding Room",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/bca2e316-b433-4fea-8db3-f323394dc12f/16-9/{width}",
      hero: {
        mode: "standard",
        title: "The Bidding Room",
        metadata: "1h 45m   ",
        synopsis:
          "Episode 11. Five dealers compete to buy a vintage bucket, a jewellery box and two birthing chairs.",
        logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_bbcone/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/bca2e316-b433-4fea-8db3-f323394dc12f/background/{width}",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_bbcone/408/84",
    },
    {
      type: "landscapeTile",
      title: "Escape to the Country",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/4aea2c5e-d173-49b0-bd2c-595d44844a1b/16-9/{width}",
      hero: {
        mode: "standard",
        title: "Escape to the Country",
        metadata: "1h 45m   ",
        synopsis:
          "East Devon. Nicki Chapman is in east Devon helping a couple who are on a budget of £750,000.",
        logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_bbcone/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/4aea2c5e-d173-49b0-bd2c-595d44844a1b/background/{width}",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_bbcone/408/84",
    },
    {
      type: "landscapeTile",
      title: "Father Brown",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/b770369e-9b0b-4cb6-adb4-34895aafcbfb/16-9/{width}",
      hero: {
        mode: "standard",
        title: "Father Brown",
        metadata: "1h 45m   ",
        synopsis:
          "The Sins of Others. Father Brown struggles to save Sid from himself.",
        logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_bbcone/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/b770369e-9b0b-4cb6-adb4-34895aafcbfb/background/{width}",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_bbcone/408/84",
    },
    {
      type: "landscapeTile",
      title: "The Heights",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/699bf553-ffdd-4264-bcc0-1109daaf027b/16-9/{width}",
      hero: {
        mode: "standard",
        title: "The Heights",
        metadata: "1h 45m   ",
        synopsis:
          "Episode 6. Friday night activities get complicated as get-togethers do not go as planned.",
        logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_bbcone/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/699bf553-ffdd-4264-bcc0-1109daaf027b/background/{width}",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_bbcone/408/84",
    },
    {
      type: "landscapeTile",
      title: "Bargain Hunt",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/916e7e69-3e4d-42d3-bfd8-7667e4a39828/16-9/{width}",
      hero: {
        mode: "standard",
        title: "Bargain Hunt",
        metadata: "1h 45m   ",
        synopsis:
          "Newmarket 11. Charlie Ross is at Newmarket racecourse with experts Roo Irvine and Tim Weeks.",
        logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_bbcone/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/916e7e69-3e4d-42d3-bfd8-7667e4a39828/background/{width}",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_bbcone/408/84",
    },
  ],
} as Rail;
