import { State } from "reducers";
import { switcherIsShowing } from "selectors/app";
import { currentPage } from "selectors/currentPage";
import { PageTitle } from "types";

export const pageTitle = (state: State): PageTitle | undefined => {
  if (switcherIsShowing(state)) return;
  const { page } = currentPage(state);
  return "title" in page ? page.title : undefined;
};

export const pageTitleInHeroPosition = (state: State) =>
  state.settings.pageTitlePosition === "hero";
