import { createStore, applyMiddleware, Middleware } from "redux";
import createSagaMiddleware from "redux-saga";
import {
  useDispatch as originalUseDispatch,
  useSelector as originalUseSelector,
  TypedUseSelectorHook,
} from "react-redux";
import { composeWithDevTools } from "redux-devtools-extension/logOnlyInProduction";
import { reducer, State } from "reducers";
import { Action } from "types/actions";

export const sagaMiddleware = createSagaMiddleware();

const composeEnhancers = composeWithDevTools({});
const middlewares: Middleware[] = [sagaMiddleware];

export const store = createStore(
  reducer,
  composeEnhancers(applyMiddleware(...middlewares))
);

export const dispatch = (action: Action) => store.dispatch(action);
export type Dispatch = typeof dispatch;

export const useSelector: TypedUseSelectorHook<State> = originalUseSelector;
export const useDispatch: () => Dispatch = originalUseDispatch;
