import { Rail } from "types";

export default {
  type: "rail",
  uuid: "6c5380e4-be19-4749-b8ba-42ca0d1ac650",
  template: "tile-cover",
  title: "Based on a book",
  tiles: [
    {
      type: "coverTile",
      title: "The Girl with the Dragon Tattoo",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/9719ed17-0133-4d77-b301-4cdc44af81ba/cover/{height}",
      hero: {
        mode: "standard",
        title: "The Girl with the Dragon Tattoo",
        metadata: "1h 45m   ",
        synopsis:
          "An Oscar-nominated Rooney Mara is the unorthodox hacker who joins journo Daniel Craig on a missing persons case. (2011)(152 mins)",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/9719ed17-0133-4d77-b301-4cdc44af81ba/background/{width}",
      },
    },
    {
      type: "coverTile",
      title: "The Twilight Saga",
      imageUrl:
        "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/7919dd663550b286d9601c1009bfddfb/{width}/{height}/item.jpg",
      hero: {
        mode: "standard",
        title: "The Twilight Saga",
        metadata: "1h 45m   ",
        synopsis:
          "Sink your teeth into a world of romance, vengeance and vampires as Kristen Stewart, Robert Pattinson and Taylor Lautner star in the epic fantasy film saga that took the world by storm.",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/962d23227b59ebdc7887d3d1d89b8fa6/hero.jpg",
      },
    },
    {
      type: "coverTile",
      title: "The Godfather",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/31d3ae92-0a9a-4b6f-8bce-ff85c1a17170/cover/{height}",
      hero: {
        mode: "standard",
        title: "The Godfather",
        metadata: "1h 45m   ",
        synopsis:
          "Marlon Brando won Best Actor as the ageing Mafia don replaced by steely son Al Pacino. Francis Ford Coppola's towering Mob epic. (1972)(169 mins)",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/31d3ae92-0a9a-4b6f-8bce-ff85c1a17170/background/{width}",
      },
    },
    {
      type: "coverTile",
      title: "The Godfather: Part II",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/08680dec-5981-4fa8-8547-3f942571453e/cover/{height}",
      hero: {
        mode: "standard",
        title: "The Godfather: Part II",
        metadata: "1h 45m   ",
        synopsis:
          "Robert De Niro won his first Oscar as a young Don Corleone, with Al Pacino as his ruthless successor, in this mighty Mob epic. (1974)(192 mins)",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/08680dec-5981-4fa8-8547-3f942571453e/background/{width}",
      },
    },
    {
      type: "coverTile",
      title: "The Goldfinch",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/99605174-679b-44ad-8c69-92c21c01495c/cover/{height}",
      hero: {
        mode: "standard",
        title: "The Goldfinch",
        metadata: "1h 45m   ",
        synopsis:
          "A troubled young man enters the world of art forgery, years after his mother was killed at the Metropolitan Museum of Art. With Ansel Elgort and Sarah Paulson. (2019)(149 mins)",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/6bf2ed9f4f2fe4bd4a5405a80bb62f34/hero.jpg",
        heroTitleImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/d3237a645752f60bfdb2a38bed3148c4/herotitle.png",
      },
    },
    {
      type: "coverTile",
      title: "No Country for Old Men",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/52a6b9ce-6cc3-4d27-856f-d6de22e6ba37/cover/{height}",
      hero: {
        mode: "standard",
        title: "No Country for Old Men",
        metadata: "1h 45m   ",
        synopsis:
          "After stealing $2million in drug money, Josh Brolin is hunted by ruthless psychotic Javier Bardem. Oscar-winning crime thriller. (2007)(118 mins)",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/3d75d1b5d5e0162a1d7a1ef748e65528/hero.jpg",
      },
    },
    {
      type: "coverTile",
      title: "The Da Vinci Code",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/f7284cd5-dfc7-4b06-914d-4a646b9f54a2/cover/{height}",
      hero: {
        mode: "standard",
        title: "The Da Vinci Code",
        metadata: "1h 45m   ",
        synopsis:
          "Tom Hanks and Audrey Tautou race across Europe in a bid to uncover a secret protected for centuries. Mystery thriller. (2006)(143 mins)",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/f7284cd5-dfc7-4b06-914d-4a646b9f54a2/background/{width}",
      },
    },
    {
      type: "coverTile",
      title: "Angels & Demons",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/11029ffa-fc3e-4371-b40b-e2aca7b0f88d/cover/{height}",
      hero: {
        mode: "standard",
        title: "Angels & Demons",
        metadata: "1h 45m   ",
        synopsis:
          "Tom Hanks is called in to save Vatican City from being incinerated by an ancient sect. Sequel to The Da Vinci Code. (2009)(133 mins) ",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/11029ffa-fc3e-4371-b40b-e2aca7b0f88d/background/{width}",
      },
    },
    {
      type: "coverTile",
      title: "Harry Potter Collection",
      imageUrl:
        "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/e250d9a4fa9679eb02a2ca969c5aa8d0/{width}/{height}/item.jpg",
      hero: {
        mode: "standard",
        title: "Harry Potter Collection",
        metadata: "1h 45m   ",
        synopsis:
          "Book yourself in for an extended stay at Hogwarts. With the whole series available to devour, there’s hours of wizardry and wonderment to make your stay a magical one.",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/9547b0d762ae810ac6a8c7337c00953a/hero.jpg",
      },
    },
    {
      type: "coverTile",
      title: "Babe",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/ea2914b7-94a6-485f-9b3f-cfbfb5620bf4/cover/{height}",
      hero: {
        mode: "standard",
        title: "Babe",
        metadata: "1h 45m   ",
        synopsis:
          "Plucky piglet Babe discovers an unexpected talent for herding sheep which may just save him from becoming the Christmas dinner. Family favourite. (1995)(88 mins)",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/ea2914b7-94a6-485f-9b3f-cfbfb5620bf4/background/{width}",
      },
    },
    {
      type: "coverTile",
      title: "Stardust",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/886aff44-145b-4ba1-943f-9e453a23705a/cover/{height}",
      hero: {
        mode: "standard",
        title: "Stardust",
        metadata: "1h 45m   ",
        synopsis:
          "A lovelorn shop boy sets out to capture a falling star, but hasn't reckoned on sky captain Robert De Niro or wicked witch Michelle Pfeiffer. (2007)(123 mins)",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/886aff44-145b-4ba1-943f-9e453a23705a/background/{width}",
      },
    },
    {
      type: "coverTile",
      title: "Crazy Rich Asians",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/c3929355-2a14-4c42-ad46-d3145f8fa4ab/cover/{height}",
      hero: {
        mode: "standard",
        title: "Crazy Rich Asians",
        metadata: "1h 45m   ",
        synopsis:
          "When an NYU professor heads to Singapore with her boyfriend, she's surprised to learn he's from an extremely wealthy family... who aren't so approving of her. Hit romcom. (2018)(116 mins) ",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/c3929355-2a14-4c42-ad46-d3145f8fa4ab/background/{width}",
      },
    },
    {
      type: "coverTile",
      title: "Bridget Jones's Diary",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/cf07f1c9-8635-4291-89b5-f78410367fbf/cover/{height}",
      hero: {
        mode: "standard",
        title: "Bridget Jones's Diary",
        metadata: "1h 45m   ",
        synopsis:
          "Top comedy with pinpoint performances from chardonnay-loving singleton Renee Zellweger and rotter Hugh Grant, not forgetting a certain pair of pants. (2001)(93 mins)",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/cf07f1c9-8635-4291-89b5-f78410367fbf/background/{width}",
      },
    },
    {
      type: "coverTile",
      title: "The Shawshank Redemption",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/b5bebc37-9499-4afb-8c9e-3bb4db6517c0/cover/{height}",
      hero: {
        mode: "standard",
        title: "The Shawshank Redemption",
        metadata: "1h 45m   ",
        synopsis:
          "A man is thrown into the brutality of prison life when he gets two life sentences for a crime he claims he didn't commit. Drama starring Tim Robbins and Morgan Freeman. (1995)(142 mins)",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/b5bebc37-9499-4afb-8c9e-3bb4db6517c0/background/{width}",
      },
    },
    {
      type: "coverTile",
      title: "The Bridge on the River Kwai",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/04ca9cc8-48d9-4941-bed6-aaba48635bb0/cover/{height}",
      hero: {
        mode: "standard",
        title: "The Bridge on the River Kwai",
        metadata: "1h 45m   ",
        synopsis:
          "William Holden and Alec Guinness star as British POWs charged with building a bridge that their own side want to obliterate. (1957)(154 mins)",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/04ca9cc8-48d9-4941-bed6-aaba48635bb0/background/{width}",
      },
    },
    {
      type: "coverTile",
      title: "The Social Network",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/1988d56b-51ff-4655-a6d7-38000c013f86/cover/{height}",
      hero: {
        mode: "standard",
        title: "The Social Network",
        metadata: "1h 45m   ",
        synopsis:
          "Jesse Eisenberg plays Facebook founder Mark Zuckerberg in David Fincher's Oscar-winning drama. With Andrew Garfield and Justin Timberlake. (2010)(116 mins)",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/1988d56b-51ff-4655-a6d7-38000c013f86/background/{width}",
      },
    },
    {
      type: "coverTile",
      title: "Psycho",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/5acb74de-a259-4c8b-bf8c-448baf824be2/cover/{height}",
      hero: {
        mode: "standard",
        title: "Psycho",
        metadata: "1h 45m   ",
        synopsis:
          "Thief Janet Leigh makes a terrible mistake when she checks into a secluded motel run by Anthony Perkins. Alfred Hitchcock directs this groundbreaking thriller. (1960)(103 mins)",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/5acb74de-a259-4c8b-bf8c-448baf824be2/background/{width}",
      },
    },
    {
      type: "coverTile",
      title: "Breakfast at Tiffany's",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/99fcc255-28a0-4314-8130-2dcf124d9043/cover/{height}",
      hero: {
        mode: "standard",
        title: "Breakfast at Tiffany's",
        metadata: "1h 45m   ",
        synopsis:
          "This film has outdated attitudes, language and cultural depictions which may cause offence today. Iconic romance based on Truman Capote's novella with Audrey Hepburn. (1961)(110 mins)",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/99fcc255-28a0-4314-8130-2dcf124d9043/background/{width}",
      },
    },
    {
      type: "coverTile",
      title: "True Grit",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/41bc01e4-f271-4dff-9c69-584c77636d65/cover/{height}",
      hero: {
        mode: "standard",
        title: "True Grit",
        metadata: "1h 45m   ",
        synopsis:
          "John Wayne won his only Oscar as Rooster Cogburn, the one-eyed marshall hired by a young girl to avenge her father's death. Violent and enduring western. (1969)(123 mins)",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/41bc01e4-f271-4dff-9c69-584c77636d65/background/{width}",
      },
    },
    {
      type: "titleTile",
      actionText: "View all",
      hero: {
        mode: "standard",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/41bc01e4-f271-4dff-9c69-584c77636d65/background/{width}",
      },
    },
  ],
} as Rail;
