import { Rail } from "types";

export default {
  type: "rail",
  uuid: "00b9aee1-0ca4-48c6-92eb-3f27d00136d8",
  template: "tile-square",
  title: "Sky Channels",
  tiles: [
    {
      type: "squareTile",
      title: "Sky One",
      imageUrl: "assets/images/channels/Sky_One.png",
      hero: {
        mode: "standard",
        title: "Sky One",
        synopsis:
          "Enjoy watching live or on demand TV, from live news coverage, music and big sport events to great comedies, gripping documentaries and nail-biting dramas.",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/4b6bc808-1bf1-4ab5-b5f5-3846cd694c05/background/1428",
      },
    },
    {
      type: "squareTile",
      title: "Sky Atlantic",
      imageUrl: "assets/images/channels/Sky_Atlantic.png",
      hero: {
        mode: "standard",
        title: "Sky Atlantic",
        synopsis:
          "Enjoy watching live or on demand TV, from live news coverage, music and big sport events to great comedies, gripping documentaries and nail-biting dramas.",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/344f2c21-2d55-47a2-a098-6b5e85a10116/background/1428",
      },
    },
    {
      type: "squareTile",
      title: "Sky Witness",
      imageUrl: "assets/images/channels/Sky_Witness.png",
      hero: {
        mode: "standard",
        title: "Sky Witness",
        synopsis:
          "Enjoy watching live or on demand TV, from live news coverage, music and big sport events to great comedies, gripping documentaries and nail-biting dramas.",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/5aad7091b70a18889becdf144807c5c3/hero.jpg",
      },
    },
    {
      type: "squareTile",
      title: "Sky Comedy",
      imageUrl: "assets/images/channels/Sky_Comedy.png",
      hero: {
        mode: "standard",
        title: "Sky Comedy",
        synopsis:
          "Enjoy watching live or on demand TV, from live news coverage, music and big sport events to great comedies, gripping documentaries and nail-biting dramas.",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/58f3e4a2d3e4c0ad32fbfd4417822960/hero.jpg",
      },
    },
    {
      type: "squareTile",
      title: "Sky Documentaries",
      imageUrl: "assets/images/channels/Sky_Documentaries.png",
      hero: {
        mode: "standard",
        title: "Sky Documentaries",
        synopsis:
          "Enjoy watching live or on demand TV, from live news coverage, music and big sport events to great comedies, gripping documentaries and nail-biting dramas.",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/e0df652acfe06a7976cd633724e1c52d/hero.jpg",
      },
    },
    {
      type: "squareTile",
      title: "Sky Crime",
      imageUrl: "assets/images/channels/Sky_Crime.png",
      hero: {
        mode: "standard",
        title: "Sky Crime",
        synopsis:
          "Enjoy watching live or on demand TV, from live news coverage, music and big sport events to great comedies, gripping documentaries and nail-biting dramas.",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/772eb9bba22efd9d1da4578ac4f00da7/hero.jpg",
      },
    },
    {
      type: "squareTile",
      title: "Sky Arts",
      imageUrl: "assets/images/channels/Sky_Arts.png",
      hero: {
        mode: "standard",
        title: "Sky Arts",
        synopsis:
          "Enjoy watching live or on demand TV, from live news coverage, music and big sport events to great comedies, gripping documentaries and nail-biting dramas.",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/ce06d4f4786248727de5096e0c63cfa8/hero.jpg",
      },
    },
    {
      type: "titleTile",
      actionText: "View all",
      hero: {
        mode: "standard",
        synopsis:
          "Enjoy watching live or on demand TV, from live news coverage, music and big sport events to great comedies, gripping documentaries and nail-biting dramas.",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/ce06d4f4786248727de5096e0c63cfa8/hero.jpg",
      },
    },
  ],
} as Rail;
