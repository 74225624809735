import { State } from "reducers";
import { pageTitle } from "selectors/pageTitle";
import { Hero } from "types";
import { browsePageHeroSelector } from ".";

export const heroSelector = (state: State): Hero => {
  const heroForBrowsePage = browsePageHeroSelector(state);
  const pgTitle = pageTitle(state);
  return {
    ...heroForBrowsePage,
    mode: "hugeTitle",
    title: pgTitle.text,
    minilabel: pgTitle.minilabel,
  };
};
