import React, { CSSProperties } from "react";
import { Bar, Fill } from "./ProgressBar.styles";

interface ProgressBarProps {
  height: number;
  percentage: number;
  styles?: CSSProperties;
}
export const ProgressBar = ({
  height,
  percentage,
  styles,
}: ProgressBarProps) => (
  <Bar height={height} style={styles}>
    <Fill height={height} percentage={percentage} />
  </Bar>
);
