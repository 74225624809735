import styled from "@emotion/styled";
import { screen } from "../../constants/dimensions";

export const Container = styled.div``;

export interface ImageProps {
  src: string;
}

export const Image = styled.img<ImageProps>`
  display: block;
  height: ${screen.height}px;
  left: 0;
  position: absolute;
  top: 0;
  width: ${screen.width}px;
`;
