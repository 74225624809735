import { SettingsState } from "reducers/settings";

const getObject = (key: string) => {
  try {
    const item = localStorage.getItem(key);
    return item ? JSON.parse(item) : {};
  } catch (err) {
    console.error(`Error retrieving ${key} from localStorage`, err);
    return {};
  }
};

const setObject = (key: string, obj: { [k: string]: any }) => {
  try {
    localStorage.setItem(key, JSON.stringify(obj));
  } catch (err) {
    console.error(`Error storing ${key} to localStorage`, err);
  }
};

export const localStore = {
  get settings(): SettingsState {
    return getObject("settings");
  },

  saveSettings(settings: SettingsState) {
    setObject("settings", settings);
  },
};
