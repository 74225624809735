import { NavStep } from "types";
import { select, put } from "sagas/effects";
import { selectedTile, selectedIndex } from "selectors/gridPage";
import { BackBehaviour } from "reducers/settings";

export function* gridPageNavigate(step: NavStep) {
  switch (step) {
    case "UP":
      yield put({ type: "GRID.UP" });
      break;
    case "DOWN":
      yield put({ type: "GRID.DOWN" });
      break;
    case "LEFT":
      yield put({ type: "GRID.LEFT" });
      break;
    case "RIGHT":
      yield put({ type: "GRID.RIGHT" });
      break;
    case "SELECT": {
      const tile = yield select(selectedTile);
      tile.action && (yield put(tile.action));
      break;
    }
    case "BACK": {
      const backBehaviour: BackBehaviour = yield select(
          s => s.settings.backBehaviour
        ),
        index = yield select(selectedIndex);
      if (backBehaviour === "twice-for-page-back" && index > 0) {
        yield put({ type: "GRID.RESET" });
      } else {
        yield put({ type: "BROWSE_BACK" });
      }
      break;
    }
  }
}
