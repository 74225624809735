import styled from "@emotion/styled";
import { hero } from "constants/dimensions";

export const Container = styled.div(
  ({ offsetX }: { offsetX?: number }) => `
  position: absolute;
  top: 0;
  left: ${offsetX || 0}px;
`
);

export const MinilabelContainer = styled.div`
  position: absolute;
  left: ${hero.hugeTitle.x}px;
  top: ${hero.hugeTitle.minilabelY}px;
`;

export const DaysContainer = styled.div`
  position: absolute;
  left: ${hero.hugeTitle.x}px;
  top: ${hero.hugeTitle.y}px;
  overflow: hidden;
`;

export const Days = styled.div`
  position: relative;
  display: flex;
`;
