import {
  ChannelId,
  ChannelSchedule,
  LinearEvent,
  LinearEventLookup,
  Schedule,
} from "types";
import { format, isWithinInterval } from "date-fns";
import request from "superagent";
import { getChannel } from "data/channels";

// Types as returned by the API
type ApiChannel = {
  name: string;
  serviceKey: string;
  channelNumber: string;
};

type ApiLinearEvent = {
  st: number;
  d: number;
  programmeuuid: string;
  t: string;
};

type ApiChannelSchedule = {
  sid: string;
  events: ApiLinearEvent[];
};

type ApiSchedule = {
  schedule: ApiChannelSchedule[];
};

const getChannels = async () => {
  const response = await request
    .get(`https://atlantis.epgsky.com/atlantis/linear/services/4097/1`)
    .set({
      "X-SkyOTT-Proposition": "SKYGO",
      "X-SkyOTT-Device": "MOBILE",
      "X-SkyOTT-Platform": "IOS",
      "X-SkyOTT-Territory": "GB",
    });
  return response.body;
};

const dateString = (date: Date) => format(date, "yyyyMMdd");

const getSchedule = async (
  date: Date,
  channelIds: string[]
): Promise<ApiSchedule> => {
  const response = await request.get(
    `https://awk.epgsky.com/hawk/linear/schedule/${dateString(
      date
    )}/${channelIds.join(",")}`
  );
  return response.body;
};

const mapChannel = ({ name, serviceKey, channelNumber }: ApiChannel) => ({
  title: name,
  id: serviceKey,
  channelNumber,
});

const mapChannelSchedule = ({
  sid,
  events,
}: ApiChannelSchedule): ChannelSchedule => ({
  channel: getChannel(sid),
  events: events.map(mapLinearEvent),
});

const mapLinearEvent = ({
  st,
  d,
  programmeuuid,
  t,
}: ApiLinearEvent): LinearEvent => ({
  startMs: st * 1000,
  endMs: (st + d) * 1000,
  programmeId: programmeuuid,
  title: t,
});

export const epg = {
  async channels(): Promise<
    { id: string; title: string; channelNumber: string }[]
  > {
    const data = await getChannels();
    return data.services.map(mapChannel);
  },

  async schedule(date: Date, channelIds: string[]): Promise<Schedule> {
    const data = await getSchedule(date, channelIds);
    return {
      channels: data.schedule.map(mapChannelSchedule),
    };
  },

  async onNow(channelIds: ChannelId[]): Promise<LinearEventLookup> {
    const now = new Date();
    const { schedule } = await getSchedule(now, channelIds);
    const isOnNow = ({ startMs: start, endMs: end }: LinearEvent) =>
      isWithinInterval(now, { start, end });
    return channelIds.reduce((lookup, channelId, i) => {
      const event = schedule[i].events.map(mapLinearEvent).find(isOnNow);
      lookup[channelId] = event;
      return lookup;
    }, {});
  },
};
