import { State } from "reducers";
import { onHomepage, pageTitleForHero } from "selectors/browse";
import {
  selectedTile,
  pageBrand as getPageBrand,
  railBrand as getRailBrand,
} from "selectors/pages/rails";
import { Hero, Brand } from "types";

export const unbrandedHeroSelector = (state: State): Hero => {
  const tile = selectedTile(state);
  const hero = tile?.hero || { mode: "none" };

  // Add page title if asked to
  const title = onHomepage(state) ? undefined : pageTitleForHero(state);
  return title
    ? {
        ...hero,
        mode: "hugeTitle",
        title: title.text,
        minilabel: title.minilabel,
      }
    : hero;
};

const addBrandFields = (
  hero: Hero,
  brand: Brand,
  backgroundImageMode: Hero["backgroundImageMode"]
): Hero => ({
  ...hero,
  backgroundImageUrl: brand.backgroundImageUrl,
  backgroundImageMode,
});

export const heroSelector = (state: State): Hero => {
  let hero = unbrandedHeroSelector(state);

  const pageBrand = getPageBrand(state);
  if (pageBrand) {
    return addBrandFields(hero, pageBrand, "full");
  }

  const railBrand = getRailBrand(state);
  if (railBrand) {
    return addBrandFields(hero, railBrand, "partial");
  }

  return hero;
};
