import { Rail } from "types";

export default {
  type: "rail",
  uuid: "efc300b7-cdb3-48e8-a3f1-53b95490c4b5",
  template: "tile-landscape",
  title: "On Demand Top Picks",
  tiles: [
    {
      type: "landscapeTile",
      title: "Cricket",
      imageUrl:
        "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/87a481afddca5ec30337dec57ceb177c/{width}/{height}/item.jpg",
      hero: {
        mode: "standard",
        title: "Cricket",
        metadata: "1h 45m   ",
        synopsis:
          "Enjoy this collection of cracking cricket, featuring England internationals, ICC tournaments, county cricket and more.",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/a3ed28acbd845c5e48789e6ec5f17e78/hero.jpg",
      },
    },
    {
      type: "landscapeTile",
      title: "NFL",
      imageUrl:
        "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/fca41164639fdc485c10858220e99693/{width}/{height}/item.jpg",
      hero: {
        mode: "standard",
        title: "NFL",
        metadata: "1h 45m   ",
        synopsis:
          "All the latest content from the National Football League, including round-up shows, highlights and features.",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/a0971e1d4cf434a2652b5f1340a87e16/hero.jpg",
      },
    },
    {
      type: "landscapeTile",
      title: "NBA",
      imageUrl:
        "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/4123519a35531ccc3dac157720e51bca/{width}/{height}/item.jpg",
      hero: {
        mode: "standard",
        title: "NBA",
        metadata: "1h 45m   ",
        synopsis:
          "Enjoy all of the action from the 2019-20 NBA season as well as the latest WNBA highlights.",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/96685d194b9e0852d92b617792c5e305/hero.jpg",
      },
    },
    {
      type: "landscapeTile",
      title: "Rugby League",
      imageUrl:
        "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/dde0cdd805b302f136b608b18ba90338/{width}/{height}/item.jpg",
      hero: {
        mode: "standard",
        title: "Rugby League",
        metadata: "1h 45m   ",
        synopsis:
          "Enjoy this rugby league collection, featuring the best moments from the Super League, Challenge Cup, and more.",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/d891244fd1999ca0f3cf98427d973572/hero.jpg",
      },
    },
    {
      type: "landscapeTile",
      title: "Rugby Union",
      imageUrl:
        "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/99d9e1f2f962245a1ff19dbf8be60511/{width}/{height}/item.jpg",
      hero: {
        mode: "standard",
        title: "Rugby Union",
        metadata: "1h 45m   ",
        synopsis:
          "Enjoy the latest rugby union content including the weekly World Rugby Show.",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/84ce761f0546c0aa68b4a76053ae05fd/hero.jpg",
      },
    },
    {
      type: "landscapeTile",
      title: "Netball",
      imageUrl:
        "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/7839d14168d5e8148c0ba359b5c13ae9/{width}/{height}/item.jpg",
      hero: {
        mode: "standard",
        title: "Netball",
        metadata: "1h 45m   ",
        synopsis:
          "Catch up on all the latest Netball action, including the 2020 Netball Superleague.",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/06ed178f3b98eaa7738e867f29ec544b/hero.jpg",
      },
    },
    {
      type: "landscapeTile",
      title: "Darts",
      imageUrl:
        "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/b9455b0178724bd89eabff862ebf884c/{width}/{height}/item.jpg",
      hero: {
        mode: "standard",
        title: "Darts",
        metadata: "1h 45m   ",
        synopsis:
          "Live all of the latest darts drama, with highlights and full repeats of matches.",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/db52eca7a40bd168e75f33d7b65102b8/hero.jpg",
      },
    },
    {
      type: "landscapeTile",
      title: "More Sport",
      imageUrl:
        "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/e8f9315a51b53fdaaaa0a0d147f59d85/{width}/{height}/item.jpg",
      hero: {
        mode: "standard",
        title: "More Sport",
        metadata: "1h 45m   ",
        synopsis:
          "Enjoy some of the most exciting sports from across the world with this special collection, including snowsports, drone racing and more.",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/00bd42295bbd73f3aa420849b145ae76/hero.jpg",
      },
    },
    {
      type: "landscapeTile",
      title: "Premier League",
      imageUrl:
        "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/d4e7cc736df204fa04fe37fb1a93a796/{width}/{height}/item.jpg",
      hero: {
        mode: "standard",
        title: "Premier League",
        metadata: "1h 45m   ",
        synopsis:
          "Enjoy the latest from the top flight with Sky Sports Premier League, including highlights, interviews, and much more.",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skysppl/408/84",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/c0475ced136ca091677e411ac40167ff/hero.jpg",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_skysppl/408/84",
    },
    {
      type: "landscapeTile",
      title: "Formula 1",
      imageUrl:
        "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/c402d5bfd8f29ece65900aced5bace7e/{width}/{height}/item.jpg",
      hero: {
        mode: "standard",
        title: "Formula 1",
        metadata: "1h 45m   ",
        synopsis:
          "Follow all of the twists and turns of the 2020 F1 season on Sky Sports, the exclusive home of all live Formula 1. With race highlights, features, interviews and more.’",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/ffeb61627b7fefb2ae7896b1b07154f2/hero.jpg",
      },
    },
    {
      type: "landscapeTile",
      title: "Football",
      imageUrl:
        "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/8ae1e85a4a449fc7f0bc7d0e0e65343b/{width}/{height}/item.jpg",
      hero: {
        mode: "standard",
        title: "Football",
        metadata: "1h 45m   ",
        synopsis:
          "Catch up with football from around the world, featuring the EFL, SPFL, Eredivisie and more",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/9eaa8be7e16444127bdc0ea1c2f3c5ce/hero.jpg",
      },
    },
    {
      type: "landscapeTile",
      title: "Boxing",
      imageUrl:
        "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/b08e5bd68eb37f50f5bedc32c19c3041/{width}/{height}/item.jpg",
      hero: {
        mode: "standard",
        title: "Boxing",
        metadata: "1h 45m   ",
        synopsis:
          "Brilliant boxing, including a collection of world title fights, the best of Britain, and more.",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/b3bf61583e0b90a41734eb1427324f39/hero.jpg",
      },
    },
    {
      type: "landscapeTile",
      title: "Golf",
      imageUrl:
        "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/be772e3346212ea365a75939b3a28a6c/{width}/{height}/item.jpg",
      hero: {
        mode: "standard",
        title: "Golf",
        metadata: "1h 45m   ",
        synopsis:
          "The definitive home of golf. Open here to enjoy the best of golf’s Majors, the European and PGA Tours, Ryder Cup and much more.",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/1c8872e515a499b38e011b9cbeb9e66b/hero.jpg",
      },
    },
    {
      type: "titleTile",
      actionText: "View all",
      hero: {
        mode: "standard",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/00bd42295bbd73f3aa420849b145ae76/hero.jpg",
      },
    },
  ],
} as Rail;
