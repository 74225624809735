import { Rail } from "types";

export default {
  type: "rail",
  uuid: "ed85bc98-45fa-4017-97d5-e1816a626ff6",
  template: "tile-cover",
  title: "Movies",
  tiles: [
    {
      type: "coverTile",
      title: "About Time",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/f0b89556-25db-4ac7-8b5e-51d817f49a06/cover/{height}",
      hero: {
        mode: "standard",
        title: "About Time",
        metadata: "1h 45m    ",
        synopsis:
          "On learning he can time travel, Domhnall Gleeson tries to engineer the perfect relationship with Rachel McAdams. Romance from Richard Curtis. Strong language/sex/nudity. (2013)(119 mins)",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/f0b89556-25db-4ac7-8b5e-51d817f49a06/background/{width}",
      },
    },
    {
      type: "coverTile",
      title: "How to Train Your Dragon",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/90e92a4e-df7b-4cd8-9f14-9bdd34f4c90e/cover/{height}",
      hero: {
        mode: "standard",
        title: "How to Train Your Dragon",
        metadata: "1h 45m    ",
        synopsis:
          "As his father wages a war against dragons, a viking teen secretly befriends one of the fire-breathing fiends. Sublime DreamWorks animation. Contains flashing images. (2010)(94 mins) ",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/90e92a4e-df7b-4cd8-9f14-9bdd34f4c90e/background/{width}",
      },
    },
    {
      type: "coverTile",
      title: "Sleeping Beauty",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/90fd3595-71a2-459e-a247-3a5b6faeaae4/cover/{height}",
      hero: {
        mode: "standard",
        title: "Sleeping Beauty",
        metadata: "1h 45m    ",
        synopsis:
          "In this classic Disney animation, wicked fairy Maleficent places a young princess under a curse that only true love can break. Contains flashing images. (1959)(75 mins)",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/90fd3595-71a2-459e-a247-3a5b6faeaae4/background/{width}",
      },
    },
    {
      type: "coverTile",
      title: "Jumanji: Welcome to the Jungle",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/5fbeeb3b-d30e-4692-a0a5-5a9a9c35dadb/cover/{height}",
      hero: {
        mode: "standard",
        title: "Jumanji: Welcome to the Jungle",
        metadata: "1h 45m    ",
        synopsis:
          "Four teens in detention discover an old video game that sucks them into the dangerous world of Jumanji. Dwayne Johnson stars. (2017)(115 mins)",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/5fbeeb3b-d30e-4692-a0a5-5a9a9c35dadb/background/{width}",
      },
    },
    {
      type: "coverTile",
      title: "Jumanji",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/96cdd7a8-0a8a-4361-8774-75b2576c1f6c/cover/{height}",
      hero: {
        mode: "standard",
        title: "Jumanji",
        metadata: "1h 45m    ",
        synopsis:
          "A man trapped for 26 years inside a magical boardgame is freed, along with a horde of jungle beasts. Starring Robin Williams. Flashing images. (1995)(100 mins)",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/96cdd7a8-0a8a-4361-8774-75b2576c1f6c/background/{width}",
      },
    },
    {
      type: "coverTile",
      title: "The Shining",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/d92468ca-49b9-4335-a8c8-1080f4340ab5/cover/{height}",
      hero: {
        mode: "standard",
        title: "The Shining",
        metadata: "1h 45m    ",
        synopsis:
          "Caretaking a remote hotel, writer Jack Nicholson slowly succumbs to murderous insanity. Stanley Kubrick directs this unsettling horror classic. Contains violent scenes. (1980)(114 mins)",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/d92468ca-49b9-4335-a8c8-1080f4340ab5/background/{width}",
      },
    },
    {
      type: "coverTile",
      title: "The Greatest Showman",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/6c19ce32-fea1-47f1-b057-1ff4cb208439/cover/{height}",
      hero: {
        mode: "standard",
        title: "The Greatest Showman",
        metadata: "1h 45m    ",
        synopsis:
          "Hugh Jackman dons a top hat and tap shoes to play legendary circus entrepreneur PT Barnum. Showstopping musical biopic with Zac Efron. (2017)(101 mins)",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/6c19ce32-fea1-47f1-b057-1ff4cb208439/background/{width}",
      },
    },
    {
      type: "coverTile",
      title: "The Addams Family",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/8adedf05-1a23-4f3c-bd06-e439b3d10582/cover/{height}",
      hero: {
        mode: "standard",
        title: "The Addams Family",
        metadata: "1h 45m    ",
        synopsis:
          "When a stranger claims to be their long lost uncle, the Addams Family attempt to uncover the truth. Comedy with Christina Ricci. With comic violence and flashing images. (1991)(95 mins) ",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/8adedf05-1a23-4f3c-bd06-e439b3d10582/background/{width}",
      },
    },
    {
      type: "coverTile",
      title: "Addams Family Values",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/4abf333d-c18d-41b7-acbb-c33af23c95c4/cover/{height}",
      hero: {
        mode: "standard",
        title: "Addams Family Values",
        metadata: "1h 45m    ",
        synopsis:
          "Sinister sequel fun as Uncle Fester falls for the fiendish family's suspicious new babysitter. Comedy with Anjelica Huston, with comic violence and flashing images. (1993)(90 mins) ",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/4abf333d-c18d-41b7-acbb-c33af23c95c4/background/{width}",
      },
    },
    {
      type: "coverTile",
      title: "Watchmen",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/63850f6b-344c-446e-b18d-81801b1482b0/cover/{height}",
      hero: {
        mode: "standard",
        title: "Watchmen",
        metadata: "1h 45m    ",
        synopsis:
          "The murder of a costumed avenger brings his superhero colleagues out of retirement in this brilliantly brutal adaptation of Alan Moore's formidable graphic novel. (2009)(156 mins) ",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/63850f6b-344c-446e-b18d-81801b1482b0/background/{width}",
      },
    },
    {
      type: "coverTile",
      title: "Terminator 3: Rise of the Machines",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/750fa03d-7bb1-4682-8bd6-9400bd6b7b13/cover/{height}",
      hero: {
        mode: "standard",
        title: "Terminator 3: Rise of the Machines",
        metadata: "1h 45m    ",
        synopsis:
          "Arnold Schwarzenegger returns to protect the future leader of the resistance and prevent the end of the human race. Sci-fi. Strong language/violence/flashing images. (2003)(105 mins)",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/750fa03d-7bb1-4682-8bd6-9400bd6b7b13/background/{width}",
      },
    },
    {
      type: "coverTile",
      title: "Terminator Salvation",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/52e3bcc7-f273-46c2-bbef-980a94b34598/cover/{height}",
      hero: {
        mode: "standard",
        title: "Terminator Salvation",
        metadata: "1h 45m    ",
        synopsis:
          "With the world in the grip of murderous machines, Christian Bale fights to save humanity in this killer retooling of the cyborg saga. Contains violence/flashing images. (2009)(111 mins)",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/52e3bcc7-f273-46c2-bbef-980a94b34598/background/{width}",
      },
    },
    {
      type: "coverTile",
      title: "Terminator Genisys",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/bf4e4420-cdf7-44b5-b12d-695702b3f3e2/cover/{height}",
      hero: {
        mode: "standard",
        title: "Terminator Genisys",
        metadata: "1h 45m    ",
        synopsis:
          "Arnold Schwarzenegger is back in this action-packed reboot of the sci-fi-series, co-starring Emilia Clarke. Strong language/violence/flashing images. (2015)(121 mins)",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/bf4e4420-cdf7-44b5-b12d-695702b3f3e2/background/{width}",
      },
    },
    {
      type: "coverTile",
      title: "Ocean's 8",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/a4b039b0-3867-4a3f-8f7d-4f71d3987387/cover/{height}",
      hero: {
        mode: "standard",
        title: "Ocean's 8",
        metadata: "1h 45m    ",
        synopsis:
          "After getting out of prison, Debbie Ocean plots to steal a priceless necklace from New York's Met Gala. Heist caper with Sandra Bullock. (2018)(106 mins) ",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/a4b039b0-3867-4a3f-8f7d-4f71d3987387/background/{width}",
      },
    },
  ],
} as Rail;
