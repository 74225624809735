import { useState, useLayoutEffect, MutableRefObject } from "react";

type Dimensions = {
  x: number;
  y: number;
  width: number;
  height: number;
};

const dimensions = (element: HTMLElement | undefined): Dimensions =>
  element
    ? {
        x: element.offsetLeft,
        y: element.offsetTop,
        width: element.offsetWidth,
        height: element.offsetHeight,
      }
    : { x: 0, y: 0, width: 0, height: 0 };

const childElement = (
  parentElement: HTMLElement | undefined,
  index: number
) => {
  const child = parentElement?.children[index];
  return child && (child as HTMLElement);
};

export function useFocusedItemDims(
  parentElementRef: MutableRefObject<HTMLElement>,
  index: number
) {
  const [dims, setDims] = useState<Dimensions>(
    dimensions(childElement(parentElementRef.current, index))
  );

  useLayoutEffect(() => {
    const element = childElement(parentElementRef.current, index);
    if (element) {
      setDims(dimensions(element));
    }
  }, [parentElementRef, index]);

  return dims;
}
