import { Rail } from "types";

export default {
  type: "rail",
  uuid: "5c8cf9aa-fb65-40b1-8eb6-4d8c35925d86",
  template: "tile-cover",
  title: "Favourite Friends",
  tiles: [
    {
      type: "coverTile",
      title: "Teen Titans Go!",
      imageUrl:
        "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/abe2d7c007916da0114d6919c5912bdd/{width}/{height}/item.jpg",
      hero: {
        mode: "standard",
        title: "Teen Titans Go!",
        metadata: "1h 45m   ",
        synopsis:
          "Animated series based on the DC Comics fictional superhero team, the Teen Titans.",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/cf14c54c-5863-4227-8f8b-642cc20c158f/background/{width}",
        heroTitleImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/c779f61878da36f7e8fbd8ddbb10d655/herotitle.png",
      },
    },
    {
      type: "coverTile",
      title: "The Amazing World of Gumball",
      imageUrl:
        "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/fea3e26483ebd96c27f53490c6dedb63/{width}/{height}/item.jpg",
      hero: {
        mode: "standard",
        title: "The Amazing World of Gumball",
        metadata: "1h 45m   ",
        synopsis:
          "Meet the Wattersons: Mum, Dad, Anais, Darwin and of course Gumball, the 12-year-old cat with a flair for misadventure!\r",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/e187cda9-3b90-4e71-a5c4-aacbbd71356e/background/{width}",
      },
    },
    {
      type: "coverTile",
      title: "LEGO Friends: Girls on a Mission",
      imageUrl:
        "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/8d1b557f12d5b5da57bee337dfc86139/{width}/{height}/item.jpg",
      hero: {
        mode: "standard",
        title: "LEGO Friends: Girls on a Mission",
        metadata: "1h 45m   ",
        synopsis:
          "Meet Andrea, Mia, Emma, Olivia and Stephanie. They're five best friends. And they're on a heroic mission: to save Heartlake City from wily swindlers!\r",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/048bbe53-8bea-4806-88ca-5c13322277db/background/{width}",
      },
    },
    {
      type: "coverTile",
      title: "iCarly",
      imageUrl:
        "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/f5590ccb6b6a35c54e4e0733b6ac3cc7/{width}/{height}/item.jpg",
      hero: {
        mode: "standard",
        title: "iCarly",
        metadata: "1h 45m   ",
        synopsis:
          "Miranda Cosgrove stars in this series about a teenage girl who becomes internet-famous when she and her friends create a zany web TV show (Nickelodeon).",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/ef0da86f-ffb1-4670-b7cb-10f1c419b428/background/{width}",
      },
    },
    {
      type: "coverTile",
      title: "Horrid Henry",
      imageUrl:
        "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/5b2e62227770766a335dbfd975d9b9f9/{width}/{height}/item.jpg",
      hero: {
        mode: "standard",
        title: "Horrid Henry",
        metadata: "1h 45m   ",
        synopsis:
          "Meet Henry. They don't call him Horrid Henry for nothing. He really is quite horrid. Because when someone gets tricked, when rules are being broken, when trouble's around... it's usually Henry.",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/835c4dbd-5ee6-4f67-b906-98af7aea014d/background/{width}",
      },
    },
    {
      type: "coverTile",
      title: "KIDZ BOP",
      imageUrl:
        "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/e56f95c466704fa3ccfc3c02400211da/{width}/{height}/item.jpg",
      hero: {
        mode: "standard",
        title: "KIDZ BOP",
        metadata: "1h 45m   ",
        synopsis:
          'KIDZ BOP launches the first ever British KIDZ BOP Kids. Family-friendly versions of today\'s biggest hits sung "by kids for kids"!',
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/86b5a84c-0d39-4c67-b6be-c646da178607/background/{width}",
      },
    },
    {
      type: "coverTile",
      title: "The Loud House",
      imageUrl:
        "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/9fc48e1993a4c7ea3d8a102fa4b15443/{width}/{height}/item.jpg",
      hero: {
        mode: "standard",
        title: "The Loud House",
        metadata: "1h 45m   ",
        synopsis:
          "Ever wonder what it's like to grow up in a big family? Lincoln Loud tries to survive the chaos of a huge household, especially as he has ten sisters!",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/48fea0eb-1686-4eb9-92e7-88d3d0644c22/background/{width}",
      },
    },
    {
      type: "coverTile",
      title: "Ben 10",
      imageUrl:
        "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/a2aa4f36c72aee8eb8c6a4c45e6d7646/{width}/{height}/item.jpg",
      hero: {
        mode: "standard",
        title: "Ben 10",
        metadata: "1h 45m   ",
        synopsis:
          "Reboot of the animation for children which follows the adventures of 10-year-old Ben Tennyson, who can change into 10 different alien creatures using a device known as the Omnitrix.",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/09a1a774-e255-4f57-aee6-c571165f8924/background/{width}",
      },
    },
    {
      type: "titleTile",
      actionText: "View all",
      hero: {
        mode: "standard",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/09a1a774-e255-4f57-aee6-c571165f8924/background/{width}",
      },
    },
  ],
} as Rail;
