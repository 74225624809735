import { addStartedAtSublines } from "data/pages/helpers";
import { TvGuidePage } from "types";
import rail from "./rail";

export default {
  type: "tvGuidePage",
  channelIds: [
    "2019", // CBeebies
    "2020", // CBBC
    "6273", // CiTV
    "5601", // Cartoon Network
    "5609", // Boomerang
    "1846", // Nickelodeon
    "1849", // Nick Toons
    "3645", // BabyTV
    "1857", // Nick Jr
    "3750", // POP
    "3780", // Tiny POP
    "4262", // POP MAX
    "1371", // Cartoonito
  ],
  rail: addStartedAtSublines(rail, new Date()),
  title: {
    text: "Kids",
  },
} as TvGuidePage;
