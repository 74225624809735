import { SpeechRecognizer } from "services/speechRecognizer";
import { DialogFlow } from "services/dialogFlow";
import uuidv4 from "uuid/v4";
import {
  validateNumber,
  validatePageId,
  validateJourneyId,
} from "types/validators";
import { PageId, JourneyId } from "types";

type AsrPayload = {
  asr: string; // The actual thing they said
};

type GoToPagePayload = {
  pageId: PageId;
} & AsrPayload;

type PlayPayload = {
  contentId: JourneyId;
} & AsrPayload;

type VolumeChangePayload = {
  value: number;
} & AsrPayload;

type VolumeSetPayload = {
  value: number;
} & AsrPayload;

type Callback<Payload = never> = (payload?: Payload) => void;
type Callbacks = {
  goHome: Callback<AsrPayload>;
  goBack: Callback<AsrPayload>;
  goToPage: Callback<GoToPagePayload>;
  play: Callback<PlayPayload>;
  mute: Callback<AsrPayload>;
  notRecognised: Callback<AsrPayload>;
  unmute: Callback<AsrPayload>;
  volumeChange: Callback<VolumeChangePayload>;
  volumeSet: Callback<VolumeSetPayload>;
  wakeWord: Callback;
};

type ListeningMode = "wakeword" | "command";

export class BrowserVoiceListener {
  private wakeword = "hello sky";
  private speechRecognizer = new SpeechRecognizer();
  private dialogFlow = new DialogFlow(uuidv4());
  private listeningMode: ListeningMode = "wakeword";
  private callbacks: Partial<Callbacks>;

  constructor(callbacks: Partial<Callbacks> = {}) {
    this.callbacks = callbacks;
    this.speechRecognizer.onSpeech(async phrase => {
      console.info(`Speech recognised: "${phrase}"`);
      if (this.listeningMode === "wakeword") {
        this.handleWakeword(phrase);
      } else {
        this.handleCommand(phrase);
      }
    });
  }

  private handleWakeword(phrase: string) {
    if (phrase.toLowerCase().trim() === this.wakeword) {
      this.callbacks.wakeWord?.();
    }
  }

  private async handleCommand(phrase: string) {
    const { action, parameters } = await this.dialogFlow.detectIntent(phrase);
    if (action === "input.unknown") {
      this.callbacks.notRecognised?.({ asr: phrase });
    } else {
      this.callCallback(action, parameters, phrase);
    }
  }

  private callCallback(
    action: string,
    parameters: { [key: string]: unknown },
    phrase: string
  ) {
    try {
      switch (action) {
        case "go-to-page":
          this.callbacks.goToPage?.({
            asr: phrase,
            pageId: validatePageId(parameters.pageId, "pageId"),
          });
          break;
        case "go-home":
          this.callbacks.goHome?.({
            asr: phrase,
          });
          break;
        case "go-back":
          this.callbacks.goBack?.({
            asr: phrase,
          });
          break;
        case "play":
          this.callbacks.play?.({
            asr: phrase,
            contentId: validateJourneyId(parameters.contentId, "journeyId"),
          });
          break;
        case "mute":
          this.callbacks.mute?.({
            asr: phrase,
          });
          break;
        case "unmute":
          this.callbacks.unmute?.({
            asr: phrase,
          });
          break;
        case "volume-up":
          this.callbacks.volumeChange?.({
            asr: phrase,
            value: validateNumber(parameters.amount, "amount"),
          });
          break;
        case "volume-down":
          this.callbacks.volumeChange?.({
            asr: phrase,
            value: -validateNumber(parameters.amount, "amount"),
          });
          break;
        case "volume-set":
          this.callbacks.volumeSet?.({
            asr: phrase,
            value: validateNumber(parameters.amount, "amount"),
          });
          break;
        default:
          console.warn(
            "Don't know how to deal with DialogFlow result",
            action,
            parameters
          );
      }
    } catch (error) {
      console.error(error);
    }
  }

  commandMode() {
    this.listeningMode = "command";
  }

  wakewordMode() {
    this.listeningMode = "wakeword";
  }

  on<Name extends keyof Callbacks>(name: Name, callback: Callbacks[Name]) {
    this.callbacks[name] = callback;
  }
}
