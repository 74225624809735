import { SpecificAction } from "types/actions";
import { call, put, takeLatest, animate } from "sagas/effects";
import { sounds } from "services/sounds";

export function* voiceSaga() {
  yield takeLatest("VOICE.GO_HOME", onGoHome);
  yield takeLatest("VOICE.GO_BACK", onGoBack);
  yield takeLatest("VOICE.GO_TO_PAGE", onGoToPage);
  yield takeLatest("VOICE.MUTE", onMute);
  yield takeLatest("VOICE.NOT_RECOGNISED", onNotRecognised);
  yield takeLatest("VOICE.PLAY", onPlay);
  yield takeLatest("VOICE.UNMUTE", onUnmute);
  yield takeLatest("VOICE.VOLUME_CHANGE", onVolumeChange);
  yield takeLatest("VOICE.VOLUME_SET", onVolumeSet);
  yield takeLatest("VOICE.WAKE", onWake);
}

function* onGoHome({ asr: message }: SpecificAction<"VOICE.GO_HOME">) {
  yield put({ type: "GO_HOME" });
  yield put({ type: "SHOW_TOAST", message, status: "success", quote: true });
}

function* onGoBack({ asr: message }: SpecificAction<"VOICE.GO_BACK">) {
  yield put({ type: "NAVIGATE", step: "BACK" });
  yield put({ type: "SHOW_TOAST", message, status: "success", quote: true });
}

function* onGoToPage({
  asr: message,
  pageId,
}: SpecificAction<"VOICE.GO_TO_PAGE">) {
  yield put({ type: "FOCUS_ON_BROWSE" });
  yield put({ type: "GO_TO_PAGE", pageType: "railsPage", pageId });
  yield put({ type: "SHOW_TOAST", message, status: "success", quote: true });
}

function* onMute({ asr: message }: SpecificAction<"VOICE.MUTE">) {
  yield put({ type: "SHOW_TOAST", message, status: "listening", quote: true });
  yield put({ type: "MUTE" });
}

function* onNotRecognised({ asr }: SpecificAction<"VOICE.NOT_RECOGNISED">) {
  yield put({
    type: "SHOW_TOAST",
    message: `Not recognised: "${asr}"`,
    status: "error",
  });
}

function* onPlay({ asr, contentId: journeyId }: SpecificAction<"VOICE.PLAY">) {
  yield put({
    type: "SHOW_TOAST",
    message: asr,
    status: "success",
    quote: true,
  });
  yield put({ type: "JOURNEY.START", journeyId });
  yield animate("journeyForward");
}

function* onUnmute({ asr: message }: SpecificAction<"VOICE.UNMUTE">) {
  yield put({ type: "SHOW_TOAST", message, status: "listening", quote: true });
  yield put({ type: "UNMUTE" });
}

function* onVolumeChange({
  asr: message,
  value,
}: SpecificAction<"VOICE.VOLUME_CHANGE">) {
  yield put({ type: "SHOW_TOAST", message, status: "listening", quote: true });
  yield put({ type: "CHANGE_VOLUME", value });
}

function* onVolumeSet({
  asr: message,
  value,
}: SpecificAction<"VOICE.VOLUME_SET">) {
  yield put({ type: "SHOW_TOAST", message, status: "listening", quote: true });
  yield put({ type: "SET_VOLUME", value });
}

function* onWake() {
  yield put({
    type: "SHOW_TOAST",
    message: "Hello, how can I help?",
    status: "listening",
  });
  yield call(sounds.voiceWake);
}
