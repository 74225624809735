type Substitutions = {
  [name: string]: number | string;
};

export const imageUrlFromTemplate = (
  url: string | undefined,
  substitutions: Substitutions = {}
) =>
  url &&
  url.replace(/{(.*?)}/g, (match, name) =>
    substitutions[name] ? substitutions[name].toString() : match
  );
