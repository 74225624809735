const urlTemplate = (
  uuid: string,
  type: "16-9" | "cover" | "background",
  param = "width"
) => `https://images.metadata.sky.com/pd-image/${uuid}/${type}/{${param}}`;

export const tileUrlTemplate = (uuid: string) => urlTemplate(uuid, "16-9");

export const coverUrlTemplate = (uuid: string) =>
  urlTemplate(uuid, "cover", "height");

export const heroUrlTemplate = (uuid: string) =>
  urlTemplate(uuid, "background");

export const logoUrl = (providerId: string) =>
  `https://images.metadata.sky.com/pd-logo/skychb_${providerId}/365/205`;
