import { select, put, animate } from "sagas/effects";
import { currentJourney } from "selectors/journey";
import { inBrowse } from "selectors/app";

export function* journeyStart() {
  const journey = yield select(currentJourney),
    wasInBrowse = yield select(inBrowse);
  if (journey?.intro) {
    yield put({ type: "INTRO.PLAY", videoUrl: journey.intro.url });
  }
  if (wasInBrowse) {
    yield animate("fullscreenFadeIn");
  } else {
    yield animate("zoomIn");
  }
}
