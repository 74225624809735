import styled from "@emotion/styled";

export const Bar = styled.div(
  ({ height }: { height: number }) => `
  height: ${height}px;
  width: 100%;
  border-radius: ${height / 2}px;
  background-color: rgba(255,254,254,0.3);
  box-shadow: inset 0 1px 3px 0 rgba(0,0,0,0.2), 0 1px 0 0 rgba(255,255,255,0.2);
  margin:
`
);

export const Fill = styled.div(
  ({ height, percentage }: { percentage: number; height: number }) => `
  height: 100%;
  width: max(${percentage}%, ${height}px);
  border-radius: ${height / 2}px;
  background-color: #0087FF;
`
);
