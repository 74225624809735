import styled from "@emotion/styled";
import { quickAccessMenu } from "constants/dimensions";
import { tileShadow } from "constants/effects";

export const Container = styled.div`
  width: ${quickAccessMenu.button.width}px;
  height: ${quickAccessMenu.button.height}px;
  background: rgba(241, 241, 241, 0.06);
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: ${tileShadow};
  border-radius: ${quickAccessMenu.button.borderRadius}px;
  position: relative;
`;

export const GlassFrameContainer = styled.div``;
