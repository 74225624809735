import { Rail } from "types";

export default {
  type: "rail",
  uuid: "3d30d3a0-0d61-446f-b78e-3564915ac853",
  template: "tile-cover",
  title: "Must-see Series",
  tiles: [
    {
      type: "coverTile",
      title: "Africa's Underwater Wonders",
      imageUrl:
        "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/1e399573e5402513eee97340f0f6722f/{width}/{height}/item.jpg",
      hero: {
        mode: "standard",
        title: "Africa's Underwater Wonders",
        synopsis:
          "Almost three quarters of our planet is covered in water. But to date, we have only explored about six percent of this territory. This series explores the enigmas of our oceans.",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/3f46433bcba7ea5a207924b6d797866f/hero.jpg",
        metadata: "1h 45m   ",
      },
    },
    {
      type: "coverTile",
      title: "Peaky Blinders",
      imageUrl:
        "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/f92492b65f51e956448c950e33163eab/item.jpg",
      hero: {
        mode: "standard",
        title: "Peaky Blinders",
        synopsis:
          "A notorious gang in 1919 Birmingham, England, is led by the fierce Tommy Shelby, a crime boss set on moving up in the world no matter the cost.",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/adb16cd6f1f5a3d0bda72d82f5fcac9f/hero.jpg",
        logoUrl:
          "https://cdn.skyq.sky.com/uk/epg/live/media/logos/netflix/v1/logo_365x75.png",
        metadata: "1h 45m   ",
      },
    },
    {
      type: "coverTile",
      title: "Avenue 5",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/3f9a5a68-3084-46b5-ac1b-6921fd3e5aaa/cover/{height}",
      hero: {
        mode: "standard",
        title: "Avenue 5",
        synopsis:
          "Hugh Laurie stars as the captain of luxury space cruiser Avenue 5 in this sci-fi comedy from Armando Iannucci, creator of The Thick Of It. What can possibly go wrong?\r",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/3f9a5a68-3084-46b5-ac1b-6921fd3e5aaa/background/1428",
        metadata: "1h 45m   ",
      },
    },
    {
      type: "coverTile",
      title: "Gangs of London",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/c2936b7c-ce69-4239-8758-4d1a0e91a96a/cover/{height}",
      hero: {
        mode: "standard",
        title: "Gangs of London",
        synopsis:
          "Created by Gareth Evans and Matt Flannery, Gangs of London is a brand-new Sky original that delves into London's modern-day criminal underworld. Contains strong language and violence.",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/c2936b7c-ce69-4239-8758-4d1a0e91a96a/background/1428",
        metadata: "1h 45m   ",
      },
    },
    {
      type: "coverTile",
      title: "Unorthodox",
      imageUrl:
        "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/af6c7806c58a3fdbc3fed0f412032370/item.jpg",
      hero: {
        mode: "standard",
        title: "Unorthodox",
        synopsis:
          "A Hasidic Jewish woman in Brooklyn flees to Berlin from an arranged marriage and is taken in by a group of musicians — until her past comes calling.",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/4b36a66258ff1e52bdb2a6f25828354b/hero.jpg",
        logoUrl:
          "https://cdn.skyq.sky.com/uk/epg/live/media/logos/netflix/v1/logo_365x75.png",
        metadata: "1h 45m   ",
      },
    },
    {
      type: "coverTile",
      title: "Gangs of Lemur Island",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/0dffa7ba-39d4-455f-85ff-5ed9ab695e2d/cover/{height}",
      hero: {
        mode: "standard",
        title: "Gangs of Lemur Island",
        synopsis:
          "A fascinating natural history docu-soap following the lives of four rival gangs of lemurs in one of their last wild sanctuaries on the planet - the Berenty Reserve in southern Madagascar.",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/abfc9459efc478a86770c18bbe200497/hero.jpg",
        metadata: "1h 45m   ",
      },
    },
    {
      type: "coverTile",
      title: "Money Heist",
      imageUrl:
        "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/b38240cac9b8bfc605cce4a0b6b627cd/{width}/{height}/item.jpg",
      hero: {
        mode: "standard",
        title: "Money Heist",
        synopsis:
          "Eight thieves take hostages and lock themselves in the Royal Mint of Spain as a criminal mastermind manipulates the police to carry out his plan.",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/d8f8776c9a8c9842cac4c2d3fbccdeb3/hero.jpg",
        logoUrl:
          "https://cdn.skyq.sky.com/uk/epg/live/media/logos/netflix/v1/logo_365x75.png",
        metadata: "1h 45m   ",
      },
    },
    {
      type: "coverTile",
      title: "Pridelands: Wilderness Reborn",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/64000b74-5f49-414e-a787-dbb5cfc573b7/cover/{height}",
      hero: {
        mode: "standard",
        title: "Pridelands: Wilderness Reborn",
        synopsis:
          "The inspirational story of family who left the Sydney suburbs to transform a derelict farm and former hunting ranch in South Africa into a sanctuary for local wildlife. ",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/b16914ed9e7db55f46e84d4f508e0f40/hero.jpg",
        metadata: "1h 45m   ",
      },
    },
    {
      type: "coverTile",
      title: "S.W.A.T.",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/32570789-dfc9-4dbc-81b7-d49ec2016a01/cover/{height}",
      hero: {
        mode: "standard",
        title: "S.W.A.T.",
        synopsis:
          "Shemar Moore defends LA's mean streets as newly-promoted SWAT sergeant Daniel 'Hondo' Harrelson, a man torn between his loyalty to his community and his brothers in blue. ",
        heroTitleImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/ffacbfa9b96ca07b72cffa283c080457/herotitle.png",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/2ef2a6238da75b208728b4216e8130b1/hero.jpg",
        metadata: "1h 45m   ",
      },
    },
    {
      type: "coverTile",
      title: "The Letter for the King",
      imageUrl:
        "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/2cce15e79397252dcec4fb5e357926fa/{width}/{height}/item.jpg",
      hero: {
        mode: "standard",
        title: "The Letter for the King",
        synopsis:
          "A young knight in training contends with ancient prophecies, magical powers and fickle companions as he sets out on an epic quest to save his kingdom.",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/b615be070c76f92d6e936b4a901ee031/hero.jpg",
        logoUrl:
          "https://cdn.skyq.sky.com/uk/epg/live/media/logos/netflix/v1/logo_365x75.png",
        metadata: "1h 45m   ",
      },
    },
    {
      type: "coverTile",
      title: "Malawi Wildlife Rescue",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/843bf9b7-4b06-40b0-b8d4-51687e39b744/cover/{height}",
      hero: {
        mode: "standard",
        title: "Malawi Wildlife Rescue",
        synopsis:
          "Join vet Amanda at the Lilongwe Wildlife centre, where her and her dedicated team rescue, treat and rehabilitate hundreds of wild animals every year. ",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/2c33f299d2b989d7d657877e28cff8c8/hero.jpg",
        metadata: "1h 45m   ",
      },
    },
    {
      type: "coverTile",
      title: "MacGyver",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/5d71033e-682d-4f8c-9c40-baaaf15f4ee9/cover/{height}",
      hero: {
        mode: "standard",
        title: "MacGyver",
        synopsis:
          'Lucas Till stars as genius problem solver Angus "Mac" MacGyver in series one of the much-anticipated reboot of the hit 1980s action adventure series.\r',
        heroTitleImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/6be9e37dd06fa9af78aa3c69fce0ef81/herotitle.png",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/3bbc3e1d25d80d5f892ff269e2c3dcee/hero.jpg",
        metadata: "1h 45m   ",
      },
    },
    {
      type: "titleTile",
      actionText: "View all",
      hero: {
        mode: "standard",
        heroTitleImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/6be9e37dd06fa9af78aa3c69fce0ef81/herotitle.png",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/3bbc3e1d25d80d5f892ff269e2c3dcee/hero.jpg",
      },
    },
  ],
} as Rail;
