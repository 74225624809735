import React, { useRef, ReactNode } from "react";
import { Container, RowContainer, Row } from "./RailLens.styles";
import { GlassFrame } from "components/GlassFrame";
import { useAnimations } from "hooks/useAnimations";
import gsap from "gsap";
import { transitions } from "constants/transitions";
import { useFocusedItemDims } from "hooks/useFocusedItemDims";

type Props = {
  x: number;
  y: number;
  index: number;
  animationScope: string;
  extraWidth?: number;
  height: number;
  children: ReactNode[];
};

export const RailLens = ({
  x,
  y,
  index,
  extraWidth,
  height,
  animationScope,
  children,
}: Props) => {
  const container = useRef(null),
    row = useRef(null),
    focusedItemDims = useFocusedItemDims(row, index),
    scale = focusedItemDims.width
      ? 1 + (extraWidth || 0) / focusedItemDims.width
      : 1;

  // Animate container
  useAnimations(
    {
      scope: animationScope,
      reset: params => gsap.set(container.current, params),
      animations: {
        moveLens: (params, timeline) =>
          timeline.to(container.current, {
            ...params,
            ...transitions.moveLens,
          }),
      },
    },
    {
      x,
      y,
      width: focusedItemDims.width,
      height: height / scale,
      scale,
    }
  );

  // Animate row
  useAnimations(
    {
      scope: animationScope,
      reset: params => gsap.set(row.current, params),
      animations: {
        moveLens: (params, timeline) =>
          timeline.to(row.current, {
            ...params,
            ...transitions.moveLens,
          }),
      },
    },
    {
      x: -focusedItemDims.x,
      scaleY: focusedItemDims.height
        ? height / focusedItemDims.height / scale
        : 1,
    }
  );

  return (
    <Container className="lens-container" ref={container}>
      <RowContainer>
        <Row ref={row}>{children}</Row>
      </RowContainer>
      <GlassFrame
        width={focusedItemDims.width}
        height={height / scale}
        animationScope={animationScope}
      />
    </Container>
  );
};
