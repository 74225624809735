import { Rail } from "types";

export default {
  type: "rail",
  uuid: "9aa145ba-76e1-4290-b383-522b075efdc1",
  template: "tile-landscape",
  title: "Comedy",
  tiles: [
    {
      type: "landscapeTile",
      title: "People Just Do Nothing",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/e3e3c58a-1bf3-49e8-9297-1bed2d600917/16-9/{width}",
      hero: {
        mode: "standard",
        title: "People Just Do Nothing",
        metadata: "1h 45m   ",
        synopsis:
          "Mockumentary series that follows west London pirate radio station 'Kurupt FM'.",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/e3e3c58a-1bf3-49e8-9297-1bed2d600917/background/{width}",
      },
    },
    {
      type: "landscapeTile",
      title: "Sunny D",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/59bad787-1085-4a3b-971e-2558cc893eef/16-9/{width}",
      hero: {
        mode: "standard",
        title: "Sunny D",
        metadata: "1h 45m   ",
        synopsis:
          "Dane is deeply frustrated with life. He is almost 30 and still lives with his parents.",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/59bad787-1085-4a3b-971e-2558cc893eef/background/{width}",
      },
    },
    {
      type: "landscapeTile",
      title: "The Kumars at 42",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/73b85e1a-690f-4b53-aa72-5c25845d56a6/16-9/{width}",
      hero: {
        mode: "standard",
        title: "The Kumars at 42",
        metadata: "1h 45m   ",
        synopsis:
          "Comedy series about an Asian chat-show host who lives and works at home with his family.",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/73b85e1a-690f-4b53-aa72-5c25845d56a6/background/{width}",
      },
    },
    {
      type: "landscapeTile",
      title: "Staged",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/6bda8858-43c3-4560-9602-b074c0ce6845/16-9/{width}",
      hero: {
        mode: "standard",
        title: "Staged",
        metadata: "1h 45m   ",
        synopsis:
          "David Tenant & Michael Sheen star as 2 actors having to rehearse remotely due to Covid-19",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/6bda8858-43c3-4560-9602-b074c0ce6845/background/{width}",
      },
    },
    {
      type: "landscapeTile",
      title: "Some Girls",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/5fceb543-3e23-4597-9508-4761a83764d8/16-9/{width}",
      hero: {
        mode: "standard",
        title: "Some Girls",
        metadata: "1h 45m   ",
        synopsis:
          "A comedy about group of quirky teenage girls who live on the same inner city estate.",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/5fceb543-3e23-4597-9508-4761a83764d8/background/{width}",
      },
    },
    {
      type: "landscapeTile",
      title: "The Other One",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/f3b10365-c734-4a76-83ad-5403140f9efc/16-9/{width}",
      hero: {
        mode: "standard",
        title: "The Other One",
        metadata: "1h 45m   ",
        synopsis:
          "A comedy about sisters who had no idea the other existed until their father dropped dead.",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/f3b10365-c734-4a76-83ad-5403140f9efc/background/{width}",
      },
    },
    {
      type: "landscapeTile",
      title: "The First Team",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/74ca94d1-785d-4126-9c1c-16f259a8cc74/16-9/{width}",
      hero: {
        mode: "standard",
        title: "The First Team",
        metadata: "1h 45m   ",
        synopsis:
          "Comedy following three young footballers and their adventures.",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/74ca94d1-785d-4126-9c1c-16f259a8cc74/background/{width}",
      },
    },
    {
      type: "landscapeTile",
      title: "Bad Education",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/376491e2-d370-4cd8-976d-a929b8da857e/16-9/{width}",
      hero: {
        mode: "standard",
        title: "Bad Education",
        metadata: "1h 45m   ",
        synopsis:
          "Comedy series about a teacher who is a bigger kid than the kids he teaches.",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/376491e2-d370-4cd8-976d-a929b8da857e/background/{width}",
      },
    },
    {
      type: "landscapeTile",
      title: "Peter Kay's Car Share",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/66aa7291-f9de-4f11-9d41-09b33b4f68b1/16-9/{width}",
      hero: {
        mode: "standard",
        title: "Peter Kay's Car Share",
        metadata: "1h 45m   ",
        synopsis:
          "John and Kayleigh have been thrown together in a company car share scheme.",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/66aa7291-f9de-4f11-9d41-09b33b4f68b1/background/{width}",
      },
    },
    {
      type: "landscapeTile",
      title: "Dave",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/cc5daf99-ff27-4b23-b1ab-a25805f7a973/16-9/{width}",
      hero: {
        mode: "standard",
        title: "Dave",
        metadata: "1h 45m   ",
        synopsis:
          "A neurotic mid-20s suburbanite is convinced he is destined to be one of the greatest rap stars ever",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/cc5daf99-ff27-4b23-b1ab-a25805f7a973/background/{width}",
      },
    },
  ],
} as Rail;
