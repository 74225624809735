import { NavStep } from "types";
import { select, put } from "sagas/effects";
import { currentPage } from "selectors/currentPage";
import { showBrowseTabs } from "selectors/browseTabs";

export function* imagePageNavigate(step: NavStep) {
  switch (step) {
    case "SELECT":
      const { page } = yield select(currentPage);
      page.action && (yield put(page.action));
      break;
    case "BACK":
      yield put({ type: "BROWSE_BACK" });
      break;
    case "UP":
      if (yield select(showBrowseTabs)) {
        yield put({ type: "FOCUS_ON_BROWSE_TABS" });
      }
      break;
  }
}
