import { Rail } from "types";

export default {
  type: "rail",
  uuid: "8a758bd8-8910-4c7d-9819-44a424499b7e",
  template: "tile-cover",
  title: "Family",
  tiles: [
    {
      type: "coverTile",
      title: "Despicable Me Collection",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/f8dff62d-5b14-4487-bc71-7231438c41e6/cover/{height}",
      hero: {
        mode: "standard",
        title: "Despicable Me Collection",
        metadata: "1h 45m   ",
        synopsis:
          "Enjoy this double dose of Gru and the minions;  the superbad superdad and his loyal band of small, yellow henchmen.",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/f8dff62d-5b14-4487-bc71-7231438c41e6/background/{width}",
      },
    },
    {
      type: "coverTile",
      title: "Lego Movie Collection",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/4e228e6b-9162-4977-bf61-a57de288a102/cover/{height}",
      hero: {
        mode: "standard",
        title: "Lego Movie Collection",
        metadata: "1h 45m   ",
        synopsis:
          "Eeverything is awesome, everything is cool... when you hit play on this Lego collection.",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/4e228e6b-9162-4977-bf61-a57de288a102/background/{width}",
      },
    },
    {
      type: "coverTile",
      title: "Hop",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/3331a03b-7fa0-4c3a-958e-9afd4dc45304/cover/{height}",
      hero: {
        mode: "standard",
        title: "Hop",
        metadata: "1h 45m   ",
        synopsis:
          "Russell Brand voices a young rabbit who ducks his Easter Bunny duties in the search for rock stardom. Bouncy family fun with James Marsden. (2011)(92 mins)",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/3331a03b-7fa0-4c3a-958e-9afd4dc45304/background/{width}",
      },
    },
    {
      type: "coverTile",
      title: "The Grinch",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/5e849cb3-c88a-46e9-8236-d1b867e201fb/cover/{height}",
      hero: {
        mode: "standard",
        title: "The Grinch",
        metadata: "1h 45m   ",
        synopsis:
          "The grumpy old Grinch hatches a dastardly scheme to ruin Christmas in Whoville. Animated Dr Seuss adaptation with the voices of Benedict Cumberbatch and Pharrell Williams. (2018)(86 mins) Also in UHD.",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/5e849cb3-c88a-46e9-8236-d1b867e201fb/background/{width}",
      },
    },
    {
      type: "coverTile",
      title: "Smallfoot",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/2de3971c-4fd3-45a9-9b25-92d26e57b816/cover/{height}",
      hero: {
        mode: "standard",
        title: "Smallfoot",
        metadata: "1h 45m   ",
        synopsis:
          "An epic journey ensues when a Yeti named Migo stumbles across a real human being - but is banished from his snowy home when no-one believes him. With Channing Tatum. (2018)(96 mins) Also in UHD.",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/2de3971c-4fd3-45a9-9b25-92d26e57b816/background/{width}",
      },
    },
    {
      type: "coverTile",
      title: "Dora And The Lost City Of Gold",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/91333547-707c-4f7a-9e04-d1a8d565a53a/cover/{height}",
      hero: {
        mode: "standard",
        title: "Dora And The Lost City Of Gold",
        metadata: "1h 45m   ",
        synopsis:
          "Despite years of exploring, nothing could prepare Dora for her biggest journey yet - high school. Live-action adaptation of the animated show. (2019)(102 mins) Also in UHD. Available in Dolby Atmos",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/91333547-707c-4f7a-9e04-d1a8d565a53a/background/{width}",
      },
    },
    {
      type: "coverTile",
      title: "Pokemon Detective Pikachu",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/aa2ccf5b-f102-4ef8-8456-8604f82c9705/cover/{height}",
      hero: {
        mode: "standard",
        title: "Pokemon Detective Pikachu",
        metadata: "1h 45m   ",
        synopsis:
          "Ryan Reynolds voices the famous Pokemon hero as he puts his sleuthing skills to work to help a 21-year-old find his missing detective father. (2019)(104 mins) Also in UHD. Available in Dolby Atmos",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/aa2ccf5b-f102-4ef8-8456-8604f82c9705/background/{width}",
      },
    },
    {
      type: "coverTile",
      title: "Four Kids And It",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/5b592e46-9aed-4a24-a154-eee1dccc23c4/cover/{height}",
      hero: {
        mode: "standard",
        title: "Four Kids And It",
        metadata: "1h 45m   ",
        synopsis:
          "Sky original based on Jacqueline Wilson's novel about four children who find a wish-granting sand creature. With Michael Caine, Russell Brand and Matthew Goode. (2020)(106 mins) Also in UHD.",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/5b592e46-9aed-4a24-a154-eee1dccc23c4/background/{width}",
      },
    },
    {
      type: "coverTile",
      title: "The Secret Life Of Pets 2",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/73a7e70e-6d06-484e-a98b-1755a55e85dd/cover/{height}",
      hero: {
        mode: "standard",
        title: "The Secret Life Of Pets 2",
        metadata: "1h 45m   ",
        synopsis:
          "Sequel to the hit film about the adventures of our pets that we don't see when we leave home. With the voices of Kevin Hart and Harrison Ford. (2019)(90 mins) Also in UHD. Available in Dolby Atmos",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/73a7e70e-6d06-484e-a98b-1755a55e85dd/background/{width}",
      },
    },
    {
      type: "coverTile",
      title: "Wonder Park",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/90dc116e-704a-4f3b-be25-8c2e631239c8/cover/{height}",
      hero: {
        mode: "standard",
        title: "Wonder Park",
        metadata: "1h 45m   ",
        synopsis:
          "The story of a magical theme park where the imagination of a little girl named June comes to life. Animated family film with the voices of Mila Kunis and Jennifer Garner. (2019)(85 mins) Also in UHD.",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/90dc116e-704a-4f3b-be25-8c2e631239c8/background/{width}",
      },
    },
  ],
} as Rail;
