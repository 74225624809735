import { Rail } from "types";

export default {
  type: "rail",
  uuid: "7a93dfdf-2440-4fb9-9708-9c7314146e3e",
  template: "tile-cover",
  title: "For The Little Ones",
  tiles: [
    {
      type: "coverTile",
      title: "Paw Patrol",
      imageUrl:
        "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/c3aa69a9301f1af102e1d257894343be/{width}/{height}/item.jpg",
      hero: {
        mode: "standard",
        title: "Paw Patrol",
        metadata: "1h 45m   ",
        synopsis:
          "Join the PAW Patrol for some amazing adventures and exciting rescues!",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/27560723-1b7b-4116-bb7b-c557aef387fa/background/{width}",
        heroTitleImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/8a668342996e38193c444eddf3eb5551/herotitle.png",
      },
    },
    {
      type: "coverTile",
      title: "Hey Duggee",
      imageUrl:
        "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/e7f4f9293d90457c7a1c197fecc208fe/{width}/{height}/item.jpg",
      hero: {
        mode: "standard",
        title: "Hey Duggee",
        metadata: "1h 45m   ",
        synopsis:
          "Duggee is a big lovable brown dog, who runs The Squirrel Club; a place where children have adventures and earn badges for their accomplishments.\r",
        logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_bbc/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/c9076176-8e50-4ae4-a285-dc93e1e291c1/background/{width}",
      },
    },
    {
      type: "coverTile",
      title: "Peppa Pig",
      imageUrl:
        "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/8811d0de091ede750925a439d0879e38/{width}/{height}/item.jpg",
      hero: {
        mode: "standard",
        title: "Peppa Pig",
        metadata: "1h 45m   ",
        synopsis:
          "Every day is an adventure for Peppa Pig! With her family and friends there's always something to smile about, and a whole world of things to discover!",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/193e5295-cef5-430a-9d5d-0f78880f3781/background/{width}",
      },
    },
    {
      type: "coverTile",
      title: "Super Wings",
      imageUrl:
        "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/834f1a3faf75d804fe02c79becd5dfdb/{width}/{height}/item.jpg",
      hero: {
        mode: "standard",
        title: "Super Wings",
        metadata: "1h 45m   ",
        synopsis:
          "Action-packed preschool series about an adorable jet plane named Jett who travels the world delivering packages to children.",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/e37d0834-c0b5-4aef-a522-1bf4a39372c6/background/{width}",
      },
    },
    {
      type: "coverTile",
      title: "Harry and Bip",
      imageUrl:
        "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/7b021d300e3f136389d4fa6391621468/{width}/{height}/item.jpg",
      hero: {
        mode: "standard",
        title: "Harry and Bip",
        metadata: "1h 45m   ",
        synopsis:
          "Meet Harry and Bip - two energetic young penguins who are all about the three 'F's... Fun, Fish and Friends!",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/ca97c420-c65b-4064-8448-81255336b4b5/background/{width}",
      },
    },
    {
      type: "coverTile",
      title: "Ben and Holly's Little Kingdom",
      imageUrl:
        "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/e61348e2f754bf2b4d306a79d7cced8a/{width}/{height}/item.jpg",
      hero: {
        mode: "standard",
        title: "Ben and Holly's Little Kingdom",
        metadata: "1h 45m   ",
        synopsis:
          "Meet fairy princess Holly and her elf best friend Ben. Holly is still learning how to do magic, Ben can't do magic, but is very brave!",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/73835fc3-dbaa-4537-8806-87555226ce2e/background/{width}",
      },
    },
    {
      type: "coverTile",
      title: "Sunny Bunnies",
      imageUrl:
        "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/a4e6e4dc2eee87f7e4f66ebf1e101d2a/{width}/{height}/item.jpg",
      hero: {
        mode: "standard",
        title: "Sunny Bunnies",
        metadata: "1h 45m   ",
        synopsis:
          "Sunny Bunnies are five beaming balls of light that can appear anywhere there is a light source, bringing fun and happiness.",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/41d6a11e-42b3-495f-bb54-fe32a00341ac/background/{width}",
      },
    },
    {
      type: "coverTile",
      title: "Ryan's Mystery Playdate",
      imageUrl:
        "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/eb13d914082f6f8ff5ebabec060091d5/{width}/{height}/item.jpg",
      hero: {
        mode: "standard",
        title: "Ryan's Mystery Playdate",
        metadata: "1h 45m   ",
        synopsis:
          "Ryan, his parents, and his animated friends solve a series of challenges and unboxing puzzles that reveal his mystery playdate.",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/33fb1d6b-16ec-4728-8660-18ae96c6b609/background/{width}",
      },
    },
    {
      type: "coverTile",
      title: "Sofia the First",
      imageUrl:
        "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/5117211fa988410867e2025333960d60/{width}/{height}/item.jpg",
      hero: {
        mode: "standard",
        title: "Sofia the First",
        metadata: "1h 45m   ",
        synopsis:
          "Sofia is a girl whose life changed forever when her mother married the king. As a princess-in-training she is learning to cope with a life of royalty while making everyone round her feel special.\r",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/20117949-a149-4105-9279-89a82e4fc6f6/background/{width}",
      },
    },
    {
      type: "coverTile",
      title: "Bing",
      imageUrl:
        "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/1b55ed2f28a49e032a1cc6102440c1e7/{width}/{height}/item.jpg",
      hero: {
        mode: "standard",
        title: "Bing",
        metadata: "1h 45m   ",
        synopsis:
          "Animated series based on the Bing Bunny books by Ted Dewan, exploring what life is like for three-year-old bunny Bing, his carer Flop, his friends and all the grown-ups in their lives.\r",
        logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_bbc/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/a2856d7f-2d1f-416c-808e-dc771e016166/background/{width}",
      },
    },
    {
      type: "titleTile",
      actionText: "View all",
      hero: {
        mode: "standard",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/a2856d7f-2d1f-416c-808e-dc771e016166/background/{width}",
      },
    },
  ],
} as Rail;
