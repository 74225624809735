import React, { useState } from "react";
import { useKeys } from "hooks/useKeys";
//import { useDispatch } from "store";
import {
  ButtonsContainer,
  Container,
  TextContainer,
} from "./QAMLanding.styles";
import { QAMEventReelController } from "components/QAMEventReel";
import { QAMButtonsController } from "components/QAMButtons";
import { Selector } from "stores/Selector";
import { observer } from "mobx-react-lite";
import { Text } from "components/Text";
import { useSelector } from "react-redux";
import { now } from "selectors/time";
import { format } from "date-fns";

type FocusArea = "buttons" | "event-reel";
type Props = { focusArea: FocusArea; focused: boolean; date: Date };

export const QAMLanding = ({ focused, focusArea, date }: Props) => (
  <Container>
    <TextContainer>
      <Text weight="medium" size="medium">
        Welcome home, Nathan
      </Text>
      <Text weight="regular" size="small" style={{ lineHeight: "40px" }}>
        Event timeline - {format(date, "do MMMM")}
      </Text>
    </TextContainer>
    <QAMEventReelController focused={focused && focusArea === "event-reel"} />
    <ButtonsContainer>
      <QAMButtonsController
        focused={focused && focusArea === "buttons"}
        buttons={[
          { id: "view-cameras", title: "View Cameras" },
          { id: "dismiss", title: "Dismiss" },
        ]}
      />
    </ButtonsContainer>
  </Container>
);

type ControllerProps = { focused: boolean };

export const QAMLandingController = observer(({ focused }: ControllerProps) => {
  //const dispatch = useDispatch();
  const date = useSelector(now);
  const [focusAreaChooser] = useState(
    () => new Selector<FocusArea>(["buttons", "event-reel"])
  );
  useKeys(focused, ({ button }) => {
    switch (button) {
      case "UP":
        focusAreaChooser.next();
        break;
      case "DOWN":
        focusAreaChooser.prev();
        break;
    }
  });
  return (
    <QAMLanding
      focused={focused}
      focusArea={focusAreaChooser.item}
      date={date}
    />
  );
});
