import { RefObject } from "react";
import { Rail, RailsPage } from "types";

export const maxRowX = (
  rowElement: RefObject<HTMLElement>,
  railInnerWidth: number
) => {
  if (!rowElement.current) return Infinity;
  const numItems = rowElement.current.children.length,
    lastItem = rowElement.current.children[numItems - 1];
  return lastItem instanceof HTMLElement
    ? Math.max(lastItem.offsetLeft + lastItem.offsetWidth - railInnerWidth, 0) // can't be negative
    : Infinity;
};

export const horizMaskGradient = (
  x: number,
  width: number,
  enabled: boolean
) => {
  const maskOpacity = enabled ? 0 : 1;
  return `linear-gradient(90deg,
    rgba(0,0,0,1) ${x}px,
    rgba(0,0,0,${maskOpacity}) ${x}px,
    rgba(0,0,0,${maskOpacity}) ${x + width}px,
    rgba(0,0,0,1) ${x + width}px
  )`;
};

export const shouldShowRailTitle = (rail: Rail, focusedTileIndex: number) => {
  const focusedTile = rail.tiles[focusedTileIndex || 0];
  return (
    !!rail.title &&
    !(focusedTile?.type === "titleTile" && focusedTile?.title) &&
    rail.template !== "tile-promo"
  );
};

export const updateRail = (
  page: RailsPage,
  railUuid: string,
  updater: (rail: Rail) => Rail
) => {
  const index = page.rails.findIndex(rail => rail.uuid === railUuid);
  if (index > -1) {
    page = { ...page, rails: [...page.rails] };
    page.rails[index] = updater(page.rails[index]);
  }
  return page;
};
