import styled from "@emotion/styled";
import { screen } from "constants/dimensions";

const width = 193,
  height = 560;

export const Container = styled.div`
  position: absolute;
  padding-left: 100px;
  top: 0;
  width: ${screen.width}px;
  height: ${screen.height}px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: url(assets/images/volume/volume-shadow.png) no-repeat left center;
`;

export const Frame = styled.div`
  width: ${width}px;
  height: ${height}px;
  background: url(assets/images/volume/volume-frame.png) no-repeat center center;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const TextContainer = styled.div`
  margin: 42px 0 12px;
`;

export const SpeakerIcon = styled.img``;
