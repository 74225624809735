import React from "react";
import { Autosize } from "./Autosize";
import { useSelector } from "react-redux";
import { stores } from "stores";

const AutosizeController: React.FC<{}> = ({ children }) => {
  const isResizeEnabled = useSelector(state => {
    return !!state["settings"].enableAutoResize;
  });

  return (
    <Autosize
      resize={isResizeEnabled}
      onResize={scale => stores.appScale.set(scale)}
    >
      {children}
    </Autosize>
  );
};

export default AutosizeController;
