import React, { useRef } from "react";
import { format } from "date-fns";
import { Container } from "./Clock.styles";
import { useDefaultAnimation } from "hooks/useDefaultAnimation";
import { boolToInt } from "utils/boolToInt";
import { Text } from "components/Text";

export type Props = {
  timeMs: number;
  visible: boolean;
};

export const Clock = ({ timeMs, visible }: Props) => {
  const container = useRef(null);
  useDefaultAnimation(container, { opacity: boolToInt(visible) });
  return (
    <Container ref={container}>
      <Text size="small" weight="regular">
        {format(timeMs, "h:mma").toLowerCase()}
      </Text>
    </Container>
  );
};
