import { Rail } from "types";

export default {
  type: "rail",
  uuid: "be806bce-5d4c-4925-9516-b254a449e50c",
  template: "tile-cover",
  title: "Ultra HD",
  tiles: [
    {
      type: "coverTile",
      title: "The Goldfinch",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/99605174-679b-44ad-8c69-92c21c01495c/cover/{height}",
      hero: {
        mode: "standard",
        title: "The Goldfinch",
        metadata: "1h 45m   ",
        synopsis:
          "A troubled young man enters the world of art forgery, years after his mother was killed at the Metropolitan Museum of Art. With Ansel Elgort and Sarah Paulson. (2019)(149 mins)",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/99605174-679b-44ad-8c69-92c21c01495c/background/{width}",
        heroTitleImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/d3237a645752f60bfdb2a38bed3148c4/herotitle.png",
      },
    },
    {
      type: "coverTile",
      title: "Downton Abbey",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/631af54d-e06f-4eb1-ba62-512492043748/cover/{height}",
      hero: {
        mode: "standard",
        title: "Downton Abbey",
        metadata: "1h 45m   ",
        synopsis:
          "Big-screen adaptation of the hit television series, following the beloved Crawleys as they prepare for a royal visit. With Maggie Smith. (2019)(122 mins) ",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/631af54d-e06f-4eb1-ba62-512492043748/background/{width}",
        heroTitleImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/7be1f1ff2ef13bce5ae414250ec32cda/herotitle.png",
      },
    },
    {
      type: "coverTile",
      title: "Gemini Man",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/3df6fb6a-a92d-40c9-8141-306c585a16b6/cover/{height}",
      hero: {
        mode: "standard",
        title: "Gemini Man",
        metadata: "1h 45m   ",
        synopsis:
          "Will Smith stars as an aging assassin who becomes targeted by a mysterious enemy that seems able to anticipate his every move. Ang Lee directs. (2019)(117 mins)",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/b8868e8a896a4c6ed5df000fab6ff21a/hero.jpg",
        heroTitleImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/8b749efdb7f09817f8e6738ec4dac7d9/herotitle.png",
      },
    },
    {
      type: "coverTile",
      title: "The Kitchen",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/13d760a2-5e75-4b43-8a27-ba26b6296f16/cover/{height}",
      hero: {
        mode: "standard",
        title: "The Kitchen",
        metadata: "1h 45m   ",
        synopsis:
          "In 1970s New York, three mob wives take business into their own hands when their husbands are sent to prison. Melissa McCarthy, Tiffany Haddish and Elisabeth Moss star. (2019)(103 minutes)",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/13d760a2-5e75-4b43-8a27-ba26b6296f16/background/{width}",
        heroTitleImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/989f7a9b2cae3c42b84d4d4d967edb8e/herotitle.png",
      },
    },
    {
      type: "coverTile",
      title: "The Dead Don't Die",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/9f3bfab5-81a0-405e-bdf5-79c64b9753e4/cover/{height}",
      hero: {
        mode: "standard",
        title: "The Dead Don't Die",
        metadata: "1h 45m   ",
        synopsis:
          "The citizens of a sleepy small town must confront the rise of the undead. Zombie comedy with a star-studded cast including Bill Murray, Adam Driver and Steve Buscemi. (2019)(103 mins)",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/9f3bfab5-81a0-405e-bdf5-79c64b9753e4/background/{width}",
      },
    },
    {
      type: "coverTile",
      title: "Crawl",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/7b7ee267-1578-41e7-87f4-30ea1988e169/cover/{height}",
      hero: {
        mode: "standard",
        title: "Crawl",
        metadata: "1h 45m   ",
        synopsis:
          "When her hometown is devastated by a hurricane, a teenager returns to find her missing father. But rising waters may be the least of their worries. (2019)(87 mins)",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/7b7ee267-1578-41e7-87f4-30ea1988e169/background/{width}",
        heroTitleImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/b2e9b8941ff2ce98b64e499d50ea5282/herotitle.png",
      },
    },
    {
      type: "coverTile",
      title: "Fast & Furious Presents: Hobbs & Shaw",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/7cd0fcec-0f5a-4a8a-963d-4bd862f758f5/cover/{height}",
      hero: {
        mode: "standard",
        title: "Fast & Furious Presents: Hobbs & Shaw",
        metadata: "1h 45m   ",
        synopsis:
          "Spin-off from the iconic franchise. Luke Hobbs (Dwayne Johnson) and Deckard Shaw (Jason Statham) must put their differences aside and team up to stop a huge terror threat. (2019)(137 mins)",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/7cd0fcec-0f5a-4a8a-963d-4bd862f758f5/background/{width}",
        heroTitleImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/2fa700f12672d4b7dd853efd54162392/herotitle.png",
      },
    },
    {
      type: "coverTile",
      title: "Alita: Battle Angel",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/ded8d126-5d0d-4d02-bb1f-676bb3415424/cover/{height}",
      hero: {
        mode: "standard",
        title: "Alita: Battle Angel",
        metadata: "1h 45m   ",
        synopsis:
          "A battle cyborg awakens in a post-apocalyptic world with no memory and embarks on a search to find out who she really is. Epic sci-fi adventure co-written by James Cameron. (2019)(122 mins)",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/ded8d126-5d0d-4d02-bb1f-676bb3415424/background/{width}",
        heroTitleImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/f9dc20cc8befc53e078bded7e2290141/herotitle.png",
      },
    },
    {
      type: "coverTile",
      title: "Dora and the Lost City of Gold",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/91333547-707c-4f7a-9e04-d1a8d565a53a/cover/{height}",
      hero: {
        mode: "standard",
        title: "Dora and the Lost City of Gold",
        metadata: "1h 45m   ",
        synopsis:
          "Despite years of exploring, nothing could prepare Dora for her biggest journey yet - high school. Live-action adaptation of the animated show. (2019)(102 mins)",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/91333547-707c-4f7a-9e04-d1a8d565a53a/background/{width}",
        heroTitleImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/428565932550936987e4df89c4f1ad7c/herotitle.png",
      },
    },
    {
      type: "coverTile",
      title: "Stuber",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/8252798d-0b39-4086-ad95-de58c7560bfd/cover/{height}",
      hero: {
        mode: "standard",
        title: "Stuber",
        metadata: "1h 45m   ",
        synopsis:
          "A wild journey ensues for a mild-mannered Uber driver when he picks up an aggressive cop who is on the hunt for a vicious killer. Action comedy with Dave Bautista. (2019)(93 mins)",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/8252798d-0b39-4086-ad95-de58c7560bfd/background/{width}",
      },
    },
    {
      type: "coverTile",
      title: "Once Upon a Time...In Hollywood",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/21daeb91-8778-4d93-a8cd-4bd7318674b5/cover/{height}",
      hero: {
        mode: "standard",
        title: "Once Upon a Time...In Hollywood",
        metadata: "1h 45m   ",
        synopsis:
          "Leonardo DiCaprio and Brad Pitt try to navigate life in Quentin Tarantino's ninth movie, set during the final years of Hollywood's Golden Age. (2019)(161 mins) ",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/c174d07dab447df3c7a089b72733d7a3/hero.jpg",
        heroTitleImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/f4b30cfd99297d10e59757196838fbcf/herotitle.png",
      },
    },
    {
      type: "coverTile",
      title: "Men in Black: International",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/dd02b98e-4284-4694-9f32-09bd5e5f1ede/cover/{height}",
      hero: {
        mode: "standard",
        title: "Men in Black: International",
        metadata: "1h 45m   ",
        synopsis:
          "The Men in Black return to tackle a new threat: a mole within their organisation. Starring Chris Hemsworth and Tessa Thompson. (2019)(110 mins)",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/dd02b98e-4284-4694-9f32-09bd5e5f1ede/background/{width}",
        heroTitleImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/c792ed4bfbd959f341ab20b9ea60d489/herotitle.png",
      },
    },
    {
      type: "coverTile",
      title: "Tolkien",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/f0716055-d546-495a-acda-97c4ca4df5a0/cover/{height}",
      hero: {
        mode: "standard",
        title: "Tolkien",
        metadata: "1h 45m   ",
        synopsis:
          "Drama set in the early years of author JRR Tolkien (Nicholas Hoult), as he finds friendship, love and inspiration among fellow outcasts at school. (2019)(108 mins)",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/f0716055-d546-495a-acda-97c4ca4df5a0/background/{width}",
        heroTitleImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/106124a3af60ed568b44d1bf90f5f654/herotitle.png",
      },
    },
    {
      type: "coverTile",
      title: "Ma",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/99f45019-6007-4f43-b168-350601f5324f/cover/{height}",
      hero: {
        mode: "standard",
        title: "Ma",
        metadata: "1h 45m   ",
        synopsis:
          "A lonely woman lets a group of teenagers party at her house, but they soon begin to question her intentions.  (2019)(90 mins)",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/99f45019-6007-4f43-b168-350601f5324f/background/{width}",
        heroTitleImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/bac15de69cf4c48687b0979fb575d696/herotitle.png",
      },
    },
    {
      type: "coverTile",
      title: "Yesterday",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/d37d9b4a-239e-4dc0-a5ef-98a1c2bb94e2/cover/{height}",
      hero: {
        mode: "standard",
        title: "Yesterday",
        metadata: "1h 45m   ",
        synopsis:
          "A struggling singer-songwriter is the only person who remembers The Beatles following a freak accident. (2019)(112 mins)",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/d37d9b4a-239e-4dc0-a5ef-98a1c2bb94e2/background/{width}",
        heroTitleImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/6721d401be699cd0bf66ff02b50af82b/herotitle.png",
      },
    },
    {
      type: "coverTile",
      title: "Spider-Man: Far from Home",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/9d4980c6-ed1c-4d40-9a26-59ef72b80ac6/cover/{height}",
      hero: {
        mode: "standard",
        title: "Spider-Man: Far from Home",
        metadata: "1h 45m   ",
        synopsis:
          "After Avengers: Endgame, Spider-Man (Tom Holland) confronts new threats in a world that has changed forever. 2019)(124 mins) ",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/9d4980c6-ed1c-4d40-9a26-59ef72b80ac6/background/{width}",
        heroTitleImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/cc704dadd81950ba01197b616e74ed0b/herotitle.png",
      },
    },
    {
      type: "coverTile",
      title: "Pokemon Detective Pikachu",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/aa2ccf5b-f102-4ef8-8456-8604f82c9705/cover/{height}",
      hero: {
        mode: "standard",
        title: "Pokemon Detective Pikachu",
        metadata: "1h 45m   ",
        synopsis:
          "Ryan Reynolds voices the famous Pokémon hero as he puts his sleuthing skills to work to help a 21-year-old find his missing detective father. (2019)(104 mins)",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/aa2ccf5b-f102-4ef8-8456-8604f82c9705/background/{width}",
        heroTitleImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/e66277e466d024c8d70516d167287448/herotitle.png",
      },
    },
    {
      type: "coverTile",
      title: "Four Kids and It",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/5b592e46-9aed-4a24-a154-eee1dccc23c4/cover/{height}",
      hero: {
        mode: "standard",
        title: "Four Kids and It",
        metadata: "1h 45m   ",
        synopsis:
          "Sky original based on Jacqueline Wilson's novel about four children who find a wish-granting sand creature. With Michael Caine, Russell Brand and Matthew Goode. (2020)(106 mins)",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/5b592e46-9aed-4a24-a154-eee1dccc23c4/background/{width}",
      },
    },
    {
      type: "coverTile",
      title: "Breakthrough",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/dc4e96f3-4b30-4af5-a99f-68cd454f766f/cover/{height}",
      hero: {
        mode: "standard",
        title: "Breakthrough",
        metadata: "1h 45m   ",
        synopsis:
          "When Joyce Smith's adopted son John falls through an icy lake, all hope seems lost. But Joyce refuses to give up. Based on a true story. (2019)(111 mins)",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/dc4e96f3-4b30-4af5-a99f-68cd454f766f/background/{width}",
      },
    },
    {
      type: "titleTile",
      actionText: "View all",
      hero: {
        mode: "standard",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/dc4e96f3-4b30-4af5-a99f-68cd454f766f/background/{width}",
      },
    },
  ],
} as Rail;
