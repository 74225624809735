import { Rail } from "types";

export default {
  type: "rail",
  uuid: "cf336026-4378-4d7c-83ee-eaac92b05ab6",
  template: "tile-cover",
  title: "Disney+",
  tiles: [
    {
      type: "coverTile",
      title: "Frozen 2",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/1735dba0-5eab-4aff-91ad-b021a406e3c4/cover/{height}",
      hero: {
        logoUrl: "assets/images/disney_plus.png",
        mode: "standard",
        title: "Frozen 2",
        metadata: "1h 45m   ",
        synopsis:
          "Anna, Elsa and the gang leave Arendelle to travel to an ancient forest of an enchanted land. Smash-hit animated sequel with the voices of Kristen Bell and Idina Menzel. (2019)(103 mins)",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/1735dba0-5eab-4aff-91ad-b021a406e3c4/background/{width}",
        heroTitleImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/ae4a4372dd41a837039ab8c883fc155a/herotitle.png",
      },
    },
    {
      type: "coverTile",
      title: "Maleficent",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/a2bf3e43-6eee-4dbb-aa8c-ed82b15fd7ed/cover/{height}",
      hero: {
        logoUrl: "assets/images/disney_plus.png",
        mode: "standard",
        title: "Maleficent",
        metadata: "1h 45m   ",
        synopsis:
          "Angelina Jolie stars as the dark fairy who places a terrible curse on the king's daughter in this spectacular fairy tale adventure. With Elle Fanning. Flashing images. (2014)(95 mins)",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/a2bf3e43-6eee-4dbb-aa8c-ed82b15fd7ed/background/{width}",
      },
    },
    {
      type: "coverTile",
      title: "Maleficent: Mistress Of Evil",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/cac2a705-350d-46f4-9520-098296354316/cover/{height}",
      hero: {
        logoUrl: "assets/images/disney_plus.png",
        mode: "standard",
        title: "Maleficent: Mistress Of Evil",
        metadata: "1h 45m   ",
        synopsis:
          "Loyalties are tested as Maleficent embarks on an epic battle against Aurora's future mother-in-law. Fantasy sequel starring Angelina Jolie. (2019)(118 mins) ",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/cac2a705-350d-46f4-9520-098296354316/background/{width}",
      },
    },
    {
      type: "coverTile",
      title: "Toy Story 4",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/624be896-32f5-4262-87ad-d4856e777ea3/cover/{height}",
      hero: {
        logoUrl: "assets/images/disney_plus.png",
        mode: "standard",
        title: "Toy Story 4",
        metadata: "1h 45m   ",
        synopsis:
          "Woody and the gang are back for a fourth film in the iconic franchise. When a new toy joins the room, a wild road trip begins with friends old and new. (2019)(96 mins)",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/624be896-32f5-4262-87ad-d4856e777ea3/background/{width}",
      },
    },
    {
      type: "coverTile",
      title: "The Incredibles",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/2b9c4705-0e47-40af-ae90-319c7b6b19bb/cover/{height}",
      hero: {
        logoUrl: "assets/images/disney_plus.png",
        mode: "standard",
        title: "The Incredibles",
        metadata: "1h 45m   ",
        synopsis:
          "Mr Incredible and his superhero family may be semi-retired, but when duty calls they're ready for action... if they can still squeeze on the spandex. Super Pixar animation. (2004)(115 mins)",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/2b9c4705-0e47-40af-ae90-319c7b6b19bb/background/{width}",
      },
    },
    {
      type: "coverTile",
      title: "Incredibles 2",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/9509b3d0-9b3f-4c80-8837-6c297d632bf8/cover/{height}",
      hero: {
        logoUrl: "assets/images/disney_plus.png",
        mode: "standard",
        title: "Incredibles 2",
        metadata: "1h 45m   ",
        synopsis:
          "When a new villain emerges and hatches a dangerous plot, everyone's favourite super-powered family must come together to save the day. Oscar-nominated Pixar sequel. (2018)(113 mins)",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/9509b3d0-9b3f-4c80-8837-6c297d632bf8/background/{width}",
      },
    },
    {
      type: "coverTile",
      title: "Ratatouille",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/1485ed2b-8a30-434b-bd89-f7b105ce7bd0/cover/{height}",
      hero: {
        logoUrl: "assets/images/disney_plus.png",
        mode: "standard",
        title: "Ratatouille",
        metadata: "1h 45m   ",
        synopsis:
          "A rat helps a clumsy kitchen porter create culinary magic. But can Paris's newest sensation keep the secret ingredient under his hat? Pixar animation. Flashing images. (2007)(107 mins)",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/1485ed2b-8a30-434b-bd89-f7b105ce7bd0/background/{width}",
      },
    },
    {
      type: "coverTile",
      title: "Monsters, Inc. Collection",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/0d25a0aa-d54c-4e10-8f4b-a017aaa6322e/cover/{height}",
      hero: {
        logoUrl: "assets/images/disney_plus.png",
        mode: "standard",
        title: "Monsters, Inc. Collection",
        metadata: "1h 45m   ",
        synopsis:
          'Join James "Sulley" Sullivan and his one-eyed partner and best friend Mike Wazowski as they embark on a journey to become top professional scarers.',
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/0d25a0aa-d54c-4e10-8f4b-a017aaa6322e/background/{width}",
      },
    },
    {
      type: "coverTile",
      title: "Finding Nemo",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/1fef5bf1-6aa7-423f-bb31-63699be1dcf5/cover/{height}",
      hero: {
        logoUrl: "assets/images/disney_plus.png",
        mode: "standard",
        title: "Finding Nemo",
        metadata: "1h 45m   ",
        synopsis:
          "A clownfish scours the ocean for his son, who has been caught and put in a dentist's aquarium. Pixar adventure with the voice of Ellen DeGeneres. Flashing images. (2003)(100 mins)",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/1fef5bf1-6aa7-423f-bb31-63699be1dcf5/background/{width}",
      },
    },
    {
      type: "coverTile",
      title: "Brave",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/77958d35-1cef-41a0-8bba-8ea1c9e5bf57/cover/{height}",
      hero: {
        logoUrl: "assets/images/disney_plus.png",
        mode: "standard",
        title: "Brave",
        metadata: "1h 45m   ",
        synopsis:
          "Kelly Macdonald voices a feisty Highland princess determined to break the bounds of tradition in Pixar's Oscar-winning animated adventure. With the voice of Billy Connolly. (2012)(100 mins)",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/77958d35-1cef-41a0-8bba-8ea1c9e5bf57/background/{width}",
      },
    },
    {
      type: "coverTile",
      title: "Coco",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/08cfb5d1-800f-4933-8a88-0e45edaea349/cover/{height}",
      hero: {
        logoUrl: "assets/images/disney_plus.png",
        mode: "standard",
        title: "Coco",
        metadata: "1h 45m   ",
        synopsis:
          "Oscar-winning Pixar animation about a music-loving youngster whisked away to the Land of the Dead after playing an enchanted guitar. With the voice of Gael Garcia Bernal. (2017)(105 mins)",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/08cfb5d1-800f-4933-8a88-0e45edaea349/background/{width}",
      },
    },
    {
      type: "titleTile",
      actionText: "View all",
      hero: {
        logoUrl: "assets/images/disney_plus.png",
        mode: "standard",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/08cfb5d1-800f-4933-8a88-0e45edaea349/background/{width}",
      },
    },
  ],
} as Rail;
