import { Rail } from "types";

export default {
  type: "rail",
  uuid: "143358b4-737f-425e-afc3-5f372ab0d975",
  template: "tile-cover",
  title: "Because You Watched",
  tiles: [
    {
      type: "coverTile",
      title: "Avengers: Endgame",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/4ca82455-e7a1-443b-a8f5-74cf4792dfd3/cover/{height}",
      hero: {
        mode: "standard",
        title: "Avengers: Endgame",
        synopsis:
          "In the dramatic, concluding film in the Infinity saga, the remaining Avengers reassemble to try and undo the deadly actions of Thanos. Robert Downey Jr stars. (2019)(181 mins) Also in HD",
        heroTitleImageUrl:
          "assets/images/content/avengers/avengers_hero_title.png",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/4ca82455-e7a1-443b-a8f5-74cf4792dfd3/background/1428",
        metadata: "1h 45m   ",
      },
    },
    {
      type: "coverTile",
      title: "Speed",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/a3a9fbb1-1eb8-4df4-93ba-bcee9f75e9f1/cover/{height}",
      hero: {
        mode: "standard",
        title: "Speed",
        synopsis:
          "Hotshot bomb specialist Keanu Reeves goes for a ride when a city bus is rigged with a speed-sensitive bomb. Action with Sandra Bullock. Contains strong language/violence. (1994)(111 mins) Also in HD",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/a3a9fbb1-1eb8-4df4-93ba-bcee9f75e9f1/background/1428",
        metadata: "1h 45m   ",
      },
    },
    {
      type: "coverTile",
      title: "Sister Act",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/a0dc3837-6ec1-4ccd-9c21-ccc3e0f989d5/cover/{height}",
      hero: {
        mode: "standard",
        title: "Sister Act",
        synopsis:
          "Whoopi Goldberg is the glamorous club singer hidden in a convent after she witnesses her mob boyfriend commit murder. Quick-witted, soulful comedy with Maggie Smith. (1992)(97 mins) Also in HD",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/a0dc3837-6ec1-4ccd-9c21-ccc3e0f989d5/background/1428",
        metadata: "1h 45m   ",
      },
    },
    {
      type: "coverTile",
      title: "Evan Almighty",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/e04695d2-e1fe-4190-8b90-ad13a41de75b/cover/{height}",
      hero: {
        mode: "standard",
        title: "Evan Almighty",
        synopsis:
          "Hapless Evan (Steve Carrell) is tasked with building an ark when God (Morgan Freeman) and a menagerie of animals appear on his doorstep. Comedy. Flashing images. (2007)(92 mins) Also in HD",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/e04695d2-e1fe-4190-8b90-ad13a41de75b/background/1428",
        metadata: "1h 45m   ",
      },
    },
    {
      type: "coverTile",
      title: "Deadpool 2",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/8924e5c8-71f2-40cf-8cdd-537d4bc03f85/cover/{height}",
      hero: {
        mode: "standard",
        title: "Deadpool 2",
        synopsis:
          "Action comedy sequel with Ryan Reynolds suiting up once more, this time to do battle with Josh Brolin's time-travelling super-soldier. Atomic Blonde's David Leitch directs. (2018)(115 mins) Also in HD",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/8924e5c8-71f2-40cf-8cdd-537d4bc03f85/background/1428",
        metadata: "1h 45m   ",
      },
    },
    {
      type: "coverTile",
      title: "Ratatouille",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/1485ed2b-8a30-434b-bd89-f7b105ce7bd0/cover/{height}",
      hero: {
        mode: "standard",
        title: "Ratatouille",
        synopsis:
          "A rat helps a clumsy kitchen porter create culinary magic. But can Paris's newest sensation keep the secret ingredient under his hat? Pixar animation. Flashing images. (2007)(107 mins) Also in HD",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/1485ed2b-8a30-434b-bd89-f7b105ce7bd0/background/1428",
        metadata: "1h 45m   ",
      },
    },
    {
      type: "coverTile",
      title: "Race To Witch Mountain",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/12c0c1b7-8942-43d8-a9c8-159a62274429/cover/{height}",
      hero: {
        mode: "standard",
        title: "Race To Witch Mountain",
        synopsis:
          "Dwayne Johnson stars as a tough ex-con cab driver, who befriends two extraterrestrial teenagers on an urgent mission to save Earth. Disney family adventure. Flashing images. (2009)(95 mins) Also in HD",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/12c0c1b7-8942-43d8-a9c8-159a62274429/background/1428",
        metadata: "1h 45m   ",
      },
    },
    {
      type: "coverTile",
      title: "Starship Troopers",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/b2753ffd-a9d1-4fa2-a312-8e78936fb8ee/cover/{height}",
      hero: {
        mode: "standard",
        title: "Starship Troopers",
        synopsis:
          "When huge alien bugs threaten Earth, it's up to Casper Van Dien and co to do some serious exterminating. Sci-fi action from Paul Verhoeven. Strong language/violence/sex. (1997)(105 mins) Also in HD",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/b2753ffd-a9d1-4fa2-a312-8e78936fb8ee/background/1428",
        metadata: "1h 45m   ",
      },
    },
    {
      type: "coverTile",
      title: "The Little Mermaid",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/f9fdda57-329d-49e1-bf91-1dfb087641eb/cover/{height}",
      hero: {
        mode: "standard",
        title: "The Little Mermaid",
        synopsis:
          "A teenage mermaid, who longs to track down a human prince, makes a deal with a ruthless sea-witch to trade her tail for legs. Classic animation from Disney. Flashing images. (1989)(83 mins) Also in HD",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/f9fdda57-329d-49e1-bf91-1dfb087641eb/background/1428",
        metadata: "1h 45m   ",
      },
    },
    {
      type: "coverTile",
      title: "300",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/f79cdb15-f599-497b-b870-f0ba89750563/cover/{height}",
      hero: {
        mode: "standard",
        title: "300",
        synopsis:
          "Spartan king Gerard Butler leads his brave elite warriors against the all-conquering Persian invaders. Bloody retelling of Frank Miller's graphic novel. Strong violence. (2006)(112 mins) ",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/f79cdb15-f599-497b-b870-f0ba89750563/background/1428",
        metadata: "1h 45m   ",
      },
    },
  ],
} as Rail;
