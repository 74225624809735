import { Rail } from "types";

export default {
  type: "rail",
  uuid: "94428161-25c6-4f5e-92a2-a564c93c8196",
  template: "tile-landscape",
  title: "Today",
  tiles: [
    {
      type: "landscapeTile",
      title: "Escape to the Country",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/30924283-4fad-4cdd-b6fb-e7d07ea0284f/16-9/{width}",
      hero: {
        mode: "standard",
        title: "Escape to the Country",
        metadata: "1h 45m   ",
        synopsis:
          "North Yorkshire. Margherita Taylor is in North Yorkshire helping a lady on a budget of £500,000.",
        logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_bbcone/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/30924283-4fad-4cdd-b6fb-e7d07ea0284f/background/{width}",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_bbcone/408/84",
    },
    {
      type: "landscapeTile",
      title: "Father Brown",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/1656f81c-62dd-48e5-bf35-1e4c117956c6/16-9/{width}",
      hero: {
        mode: "standard",
        title: "Father Brown",
        metadata: "1h 45m   ",
        synopsis:
          "The Penitent Man. Father Brown suspects an ulterior motive when Flambeau is incarcerated for murder.",
        logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_bbcone/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/1656f81c-62dd-48e5-bf35-1e4c117956c6/background/{width}",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_bbcone/408/84",
    },
    {
      type: "landscapeTile",
      title: "The Heights",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/8dbe9908-d6bc-4ef0-b041-bc6fa120af52/16-9/{width}",
      hero: {
        mode: "standard",
        title: "The Heights",
        metadata: "1h 45m   ",
        synopsis:
          "Episode 10. Hazel feels lost when Patch falls ill, but Ana calmly takes command.",
        logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_bbcone/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/8dbe9908-d6bc-4ef0-b041-bc6fa120af52/background/{width}",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_bbcone/408/84",
    },
    {
      type: "landscapeTile",
      title: "Bargain Hunt",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/08543e51-82c3-4649-860e-c5d3f94dcc83/16-9/{width}",
      hero: {
        mode: "standard",
        title: "Bargain Hunt",
        metadata: "1h 45m   ",
        synopsis:
          "Lewes 19. Lewes High Street plays host to todays Bargain Hunt, presented by Christina Trevanion.",
        logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_bbcone/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/08543e51-82c3-4649-860e-c5d3f94dcc83/background/{width}",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_bbcone/408/84",
    },
    {
      type: "landscapeTile",
      title: "Caught Red Handed",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/2363a4c7-97ce-4d03-ab7b-8e6d59978c55/16-9/{width}",
      hero: {
        mode: "standard",
        title: "Caught Red Handed",
        metadata: "1h 45m   ",
        synopsis:
          "Episode 8. Money mysteriously goes missing from a performing arts school.",
        logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_bbcone/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/2363a4c7-97ce-4d03-ab7b-8e6d59978c55/background/{width}",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_bbcone/408/84",
    },
    {
      type: "landscapeTile",
      title: "Bargain Hunt",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/08543e51-82c3-4649-860e-c5d3f94dcc83/16-9/{width}",
      hero: {
        mode: "standard",
        title: "Bargain Hunt",
        metadata: "1h 45m   ",
        synopsis:
          "Lewes 19. Lewes High Street plays host to todays Bargain Hunt, presented by Christina Trevanion.",
        logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_bbcone/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/08543e51-82c3-4649-860e-c5d3f94dcc83/background/{width}",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_bbcone/408/84",
    },
    {
      type: "landscapeTile",
      title: "Homes Under the Hammer",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/a1831383-b96d-4e83-990c-9592d4027816/16-9/{width}",
      hero: {
        mode: "standard",
        title: "Homes Under the Hammer",
        metadata: "1h 45m   ",
        synopsis:
          "Episode 15. Featuring properties in Sunbury-on-Thames, Sutton Coldfield and Whitstable.",
        logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_bbcone/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/a1831383-b96d-4e83-990c-9592d4027816/background/{width}",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_bbcone/408/84",
    },
    {
      type: "landscapeTile",
      title: "Your Money and Your Life",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/4e92f91c-28f5-4e02-9799-a3fca845c9c1/16-9/{width}",
      hero: {
        mode: "standard",
        title: "Your Money and Your Life",
        metadata: "1h 45m   ",
        synopsis:
          "Episode 10. Matt Allwright and Kym Marsh answer the nations burning consumer questions.",
        logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_bbcone/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/4e92f91c-28f5-4e02-9799-a3fca845c9c1/background/{width}",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_bbcone/408/84",
    },
    {
      type: "landscapeTile",
      title: "BBC News",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/ed8533fd-801b-4b39-8220-cf5a857dbb86/16-9/{width}",
      hero: {
        mode: "standard",
        title: "BBC News",
        metadata: "1h 45m   ",
        synopsis:
          "26/06/2020. The latest national and international stories as they break.",
        logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_bbcone/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/ed8533fd-801b-4b39-8220-cf5a857dbb86/background/{width}",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_bbcone/408/84",
    },
    {
      type: "landscapeTile",
      title: "Breakfast",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/6da317da-a085-4ba1-92ee-df4c42cb89bf/16-9/{width}",
      hero: {
        mode: "standard",
        title: "Breakfast",
        metadata: "1h 45m   ",
        synopsis:
          "26/06/2020. The latest news, sport, business and weather from the BBC's Breakfast team.",
        logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_bbcone/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/6da317da-a085-4ba1-92ee-df4c42cb89bf/background/{width}",
      },
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_bbcone/408/84",
    },
  ],
} as Rail;
