import { put, takeEvery } from "sagas/effects";

export function* doorbellSaga() {
  yield takeEvery("DOORBELL.RING", ringDoorbell);
}

function* ringDoorbell() {
  yield put({ type: "QUICK_ACCESS_MENU.SHOW", tab: "doorbell" });
  yield put({ type: "PAUSE" });
}
