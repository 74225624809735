import { Rail } from "types";

export default {
  type: "rail",
  uuid: "5bc4d7bb-6760-4ee3-a0e6-fbcb08e14a39",
  template: "tile-cover",
  title: "Record for later",
  tiles: [
    {
      type: "coverTile",
      title: "Super Troopers 2",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/fab3af5b-8740-424d-84fe-d515527124b0/cover/{height}",
      hero: {
        mode: "standard",
        title: "Super Troopers 2",
        metadata: "1h 45m   ",
        synopsis:
          "An unexpected border dispute between the US and Canada gives a group of disgraced state troopers a second chance. Comedy sequel with Rob Lowe and Brian Cox. (2018)(96 mins)",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/fab3af5b-8740-424d-84fe-d515527124b0/background/{width}",
      },
    },
    {
      type: "coverTile",
      title: "The Runaways (2019)",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/3dddf39f-b407-438f-8187-6d6cb699f6c2/cover/{height}",
      hero: {
        mode: "standard",
        title: "The Runaways (2019)",
        metadata: "1h 45m   ",
        synopsis:
          "British drama following three children who embark on a journey to find their estranged mother when their father dies. With Mark Addy and Tara Fitzgerald. (2019)(109 mins)",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/3dddf39f-b407-438f-8187-6d6cb699f6c2/background/{width}",
      },
    },
    {
      type: "coverTile",
      title: "Ralph Breaks The Internet",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/216f8832-e027-46f4-9dac-f6b6bb565e62/cover/{height}",
      hero: {
        mode: "standard",
        title: "Ralph Breaks The Internet",
        metadata: "1h 45m   ",
        synopsis:
          "There's trouble ahead as video game villain Ralph bursts into the chaotic world of the internet with his best friend Vanellope in tow. With John C Reilly. (2018)(108 mins)",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/216f8832-e027-46f4-9dac-f6b6bb565e62/background/{width}",
      },
    },
    {
      type: "coverTile",
      title: "Captain Marvel",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/a333dbb0-585c-4886-ac54-a10621d2d68f/cover/{height}",
      hero: {
        mode: "standard",
        title: "Captain Marvel",
        metadata: "1h 45m   ",
        synopsis:
          "Oscar winner Brie Larson is Captain Marvel, one of the great heroes of the universe, caught up in an intergalactic struggle between alien worlds. With Samuel L Jackson. (2019)(124 mins) Also in HD",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/a333dbb0-585c-4886-ac54-a10621d2d68f/background/{width}",
      },
    },
    {
      type: "coverTile",
      title: "Shazam!",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/b4e0c70d-10b8-48fa-8c60-5c9c92c3e1ec/cover/{height}",
      hero: {
        mode: "standard",
        title: "Shazam!",
        metadata: "1h 45m   ",
        synopsis:
          "A streetwise 14-year-old discovers he can transform into a superhero by shouting one word- Shazam! Action based on the DC Comics character. (2019)(132 mins)",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/b4e0c70d-10b8-48fa-8c60-5c9c92c3e1ec/background/{width}",
      },
    },
    {
      type: "coverTile",
      title: "Top Ten: Animation",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/bcf5c22b-2d64-4b52-a7fd-6041f949b338/cover/{height}",
      hero: {
        mode: "standard",
        title: "Top Ten: Animation",
        metadata: "1h 45m   ",
        synopsis: "A countdown of the top 10 animation box office smash hits.",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/bcf5c22b-2d64-4b52-a7fd-6041f949b338/background/{width}",
      },
    },
    {
      type: "coverTile",
      title: "Frozen 2",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/1735dba0-5eab-4aff-91ad-b021a406e3c4/cover/{height}",
      hero: {
        mode: "standard",
        title: "Frozen 2",
        metadata: "1h 45m   ",
        synopsis:
          "Anna, Elsa and the gang leave Arendelle to travel to an ancient forest of an enchanted land. Animated sequel with Kristen Bell and Idina Menzel. (2019)(103 mins)",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/1735dba0-5eab-4aff-91ad-b021a406e3c4/background/{width}",
      },
    },
    {
      type: "coverTile",
      title: "Enchanted",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/b10cf037-1cf4-4fb5-be1b-50619d093b70/cover/{height}",
      hero: {
        mode: "standard",
        title: "Enchanted",
        metadata: "1h 45m   ",
        synopsis:
          "Fairy tale princess Amy Adams is banished to real world New York by an evil queen. Will dashing lawyer Patrick Dempsey be her knight in pinstripe armour? Flashing images. (2007)(107 mins)",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/b10cf037-1cf4-4fb5-be1b-50619d093b70/background/{width}",
      },
    },
    {
      type: "coverTile",
      title: "Armageddon",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/d24979fa-50ae-4225-bdc6-fa2e6e7790a4/cover/{height}",
      hero: {
        mode: "standard",
        title: "Armageddon",
        metadata: "1h 45m   ",
        synopsis:
          "Ben Affleck and Bruce Willis star as expert drillers who are sent into space to plant explosives inside an asteroid headed for Earth. Blockbusting action from Michael Bay. (1998)(144 mins) Also in HD",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/d24979fa-50ae-4225-bdc6-fa2e6e7790a4/background/{width}",
      },
    },
    {
      type: "coverTile",
      title: "Avengers: Endgame",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/4ca82455-e7a1-443b-a8f5-74cf4792dfd3/cover/{height}",
      hero: {
        mode: "standard",
        title: "Avengers: Endgame",
        metadata: "1h 45m   ",
        synopsis:
          "In the dramatic, concluding film in the Infinity saga, the remaining Avengers reassemble to try and undo the deadly actions of Thanos. Robert Downey Jr stars. (2019)(181 mins)",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/4ca82455-e7a1-443b-a8f5-74cf4792dfd3/background/{width}",
      },
    },
    {
      type: "coverTile",
      title: "The Wolf Hour",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/6757e97f-82a1-4d1c-875f-494cb69cf659/cover/{height}",
      hero: {
        mode: "standard",
        title: "The Wolf Hour",
        metadata: "1h 45m   ",
        synopsis:
          "As a wave of violence and murder sweeps New York City in 1977, a troubled woman tries to hide from the chaos in her apartment. Psychological drama starring Naomi Watts. (2019)(99 mins)",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/6757e97f-82a1-4d1c-875f-494cb69cf659/background/{width}",
      },
    },
    {
      type: "coverTile",
      title: "Tarzan",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/fb743682-7b13-4022-a3ae-0808f2e34835/cover/{height}",
      hero: {
        mode: "standard",
        title: "Tarzan",
        metadata: "1h 45m   ",
        synopsis:
          "Disney animation about the jungle-dwelling man torn between the ape family that raised him and the human adventurers who could take him home. Flashing images. (1999)(85 mins)",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/fb743682-7b13-4022-a3ae-0808f2e34835/background/{width}",
      },
    },
    {
      type: "coverTile",
      title: "Cars",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/2371c894-3d37-4416-9ae8-c0ba7c1bf494/cover/{height}",
      hero: {
        mode: "standard",
        title: "Cars",
        metadata: "1h 45m   ",
        synopsis:
          "Owen Wilson and Paul Newman voice this Pixar animation about a hotshot racecar who gets stranded in a backwater town. Flashing images. (2006)(114 mins) Also in HD",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/2371c894-3d37-4416-9ae8-c0ba7c1bf494/background/{width}",
      },
    },
    {
      type: "coverTile",
      title: "The Game Plan",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/872a7b44-658c-40b4-9392-556c2cd8463c/cover/{height}",
      hero: {
        mode: "standard",
        title: "The Game Plan",
        metadata: "1h 45m   ",
        synopsis:
          "Dwayne 'The Rock' Johnson stars as the selfish NFL superstar whose world is turned upside down when he learns he has a daughter. Family comedy. Contains flashing images. (2007)(106 mins)",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/872a7b44-658c-40b4-9392-556c2cd8463c/background/{width}",
      },
    },
    {
      type: "coverTile",
      title: "Mary Poppins Returns",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/db8413a5-8ca4-485f-b8de-d4d7c09ce18a/cover/{height}",
      hero: {
        mode: "standard",
        title: "Mary Poppins Returns",
        metadata: "1h 45m   ",
        synopsis:
          "The practically-perfect nanny returns, using her magical skills to dazzle the Banks family once again. Musical fantasy with Emily Blunt, Dick Van Dyke and Meryl Streep. (2018)(130 mins)",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/db8413a5-8ca4-485f-b8de-d4d7c09ce18a/background/{width}",
      },
    },
    {
      type: "coverTile",
      title: "Rise Of The Planet Of The Apes",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/b6d0c003-7acd-4197-935e-27d31ce1452b/cover/{height}",
      hero: {
        mode: "standard",
        title: "Rise Of The Planet Of The Apes",
        metadata: "1h 45m   ",
        synopsis:
          "Scientist James Franco discovers how to enhance brain DNA, but will his experiments on astute ape Andy Serkis change mankind forever? Gripping action. Contains violence. (2011)(101 mins) Also in HD",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/b6d0c003-7acd-4197-935e-27d31ce1452b/background/{width}",
      },
    },
    {
      type: "coverTile",
      title: "The Little Stranger",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/18632562-16ac-4045-be3a-b601532391e2/cover/{height}",
      hero: {
        mode: "standard",
        title: "The Little Stranger",
        metadata: "1h 45m   ",
        synopsis:
          "A young doctor (Domhnall Gleeson) makes a house-call to a dilapidated mansion with a dark secret. Gothic horror from Room's Lenny Abrahamson. Ruth Wilson stars. (2018)(111 mins)",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/18632562-16ac-4045-be3a-b601532391e2/background/{width}",
      },
    },
    {
      type: "coverTile",
      title: "The Goldfinch",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/99605174-679b-44ad-8c69-92c21c01495c/cover/{height}",
      hero: {
        mode: "standard",
        title: "The Goldfinch",
        metadata: "1h 45m   ",
        synopsis:
          "A troubled young man enters the world of art forgery, years after his mother was killed at the Metropolitan Museum of Art. With Ansel Elgort and Sarah Paulson. (2019)(149 mins)",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/99605174-679b-44ad-8c69-92c21c01495c/background/{width}",
      },
    },
    {
      type: "coverTile",
      title: "Remember The Titans",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/6965f863-6f50-4aea-b1ed-a175327a000c/cover/{height}",
      hero: {
        mode: "standard",
        title: "Remember The Titans",
        metadata: "1h 45m   ",
        synopsis:
          "Denzel Washington stars as the new coach of a high school American football team who are forced to battle racial prejudice. Top sports drama. Mature themes/flashing images. (2000)(109 mins)",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/6965f863-6f50-4aea-b1ed-a175327a000c/background/{width}",
      },
    },
    {
      type: "titleTile",
      actionText: "View all",
      hero: {
        mode: "standard",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/6965f863-6f50-4aea-b1ed-a175327a000c/background/{width}",
      },
    },
  ],
} as Rail;
