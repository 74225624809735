import { State } from "reducers";
import { onHomepage } from "selectors/browse";

export const inBrowse = (state: State) => !state.app.showSwitcher;

export const switcherIsShowing = (state: State) =>
  state.app.showSwitcher && onHomepage(state);

export const focusArea = (state: State) => state.app.focusArea;
export const overlayFocusArea = (state: State) => state.app.overlayFocusArea;

export const inGlance = (state: State) => state.app.mode === "glance";

export const isFullscreen = (state: State) => state.app.fullscreenMode;
