import { RailsPage } from "types";
import { rail as railDims } from "constants/dimensions";
import { shouldShowRailTitle } from "utils/railHelpers";

export const pageOffsetY = (
  page: RailsPage,
  index: number,
  railIndices: { [index: number]: number }
) =>
  page.rails
    .slice(0, index)
    .reduce(
      (y, rail, i) =>
        y +
        railDims.height(rail.template) +
        railDims.marginTop(shouldShowRailTitle(rail, railIndices[i])),
      railDims.marginTop(true)
    );
