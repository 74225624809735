import { ImagePage } from "types";

export default {
  type: "imagePage",
  url: "assets/images/showPages/landlord-showpage.jpg",
  action: {
    type: "JOURNEY.START",
    journeyId: "landlord",
  },
} as ImagePage;
