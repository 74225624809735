import { Rail } from "types";

export default {
  type: "rail",
  uuid: "bec88c05-23a3-48ff-bc9f-9c39dec9b0e1",
  template: "tile-landscape",
  title: "Best of YouTube",
  tiles: [
    {
      type: "landscapeTile",
      title: "Spurs edge past Everton",
      imageUrl:
        "https://e3.365dm.com/20/07/456x257/promo418338585_5032311.jpg?20200706221216",
      hero: {
        mode: "standard",
        title: "Spurs edge past Everton",
        metadata: "1h 45m   ",
        synopsis:
          "FREE TO WATCH: Highlights from Tottenham's win over Everton in the Premier League.",
        heroImageUrl:
          "https://cdn.skyq.sky.com/mashup/images/sports-football-latest-news-hs-hero.jpg",
      },
    },
    {
      type: "landscapeTile",
      title: "Adams stunner helps Saints beat City",
      imageUrl:
        "https://e3.365dm.com/20/07/456x257/skysports-southampton-manchester-city_5031444.jpg?20200705210816",
      hero: {
        mode: "standard",
        title: "Adams stunner helps Saints beat City",
        metadata: "1h 45m   ",
        synopsis:
          "FREE TO WATCH: Highlights from Southampton's win over Manchester City.",
        heroImageUrl:
          "https://cdn.skyq.sky.com/mashup/images/sports-football-latest-news-hs-hero.jpg",
      },
    },
    {
      type: "landscapeTile",
      title: "Mane and Jones see off determined Villa",
      imageUrl:
        "https://e3.365dm.com/20/07/456x257/promo418239386_5031301.jpg?20200705183824",
      hero: {
        mode: "standard",
        title: "Mane and Jones see off determined Villa",
        metadata: "1h 45m   ",
        synopsis:
          "FREE TO WATCH: Highlights from Liverpool's win over Aston Villa in the Premier League.",
        heroImageUrl:
          "https://cdn.skyq.sky.com/mashup/images/sports-football-latest-news-hs-hero.jpg",
      },
    },
    {
      type: "landscapeTile",
      title: "Hammers blow lead twice as Toon earn draw",
      imageUrl:
        "https://e3.365dm.com/20/07/456x257/skysports-newcastle-west-ham_5031163.jpg?20200705162800",
      hero: {
        mode: "standard",
        title: "Hammers blow lead twice as Toon earn draw",
        metadata: "1h 45m   ",
        synopsis:
          "FREE TO WATCH: Highlights from Newcastle's draw with West Ham in the Premier League.",
        heroImageUrl:
          "https://cdn.skyq.sky.com/mashup/images/sports-football-latest-news-hs-hero.jpg",
      },
    },
    {
      type: "landscapeTile",
      title: "Egan volley earns Blades a point",
      imageUrl:
        "https://e3.365dm.com/20/07/456x257/skysports-burnley-sheffield-united_5030991.jpg?20200705141228",
      hero: {
        mode: "standard",
        title: "Egan volley earns Blades a point",
        metadata: "1h 45m   ",
        synopsis:
          "FREE TO WATCH: Highlights from Burnley's draw against Sheffield United in the Premier League.",
        heroImageUrl:
          "https://cdn.skyq.sky.com/mashup/images/sports-football-latest-news-hs-hero.jpg",
      },
    },
    {
      type: "landscapeTile",
      title: "Chelsea ease past Watford",
      imageUrl:
        "https://e3.365dm.com/20/07/456x257/promo418165608_5030591.jpg?20200704220859",
      hero: {
        mode: "standard",
        title: "Chelsea ease past Watford",
        metadata: "1h 45m   ",
        synopsis:
          "FREE TO WATCH: Highlights from Chelsea's win over Watford in the Premier League.",
        heroImageUrl:
          "https://cdn.skyq.sky.com/mashup/images/sports-football-latest-news-hs-hero.jpg",
      },
    },
    {
      type: "landscapeTile",
      title: "Saka and Lacazette secure win at Wolves",
      imageUrl:
        "https://e3.365dm.com/20/07/456x257/promo418156410_5030429.jpg?20200704193515",
      hero: {
        mode: "standard",
        title: "Saka and Lacazette secure win at Wolves",
        metadata: "1h 45m   ",
        synopsis:
          "FREE TO WATCH: Highlights from Arsenal's win at Wolves in the Premier League.",
        heroImageUrl:
          "https://cdn.skyq.sky.com/mashup/images/sports-football-latest-news-hs-hero.jpg",
      },
    },
    {
      type: "landscapeTile",
      title: "Greenwood stars as United hit five",
      imageUrl:
        "https://e3.365dm.com/20/07/456x257/promo418147400_5030249.jpg?20200704170557",
      hero: {
        mode: "standard",
        title: "Greenwood stars as United hit five",
        metadata: "1h 45m   ",
        synopsis:
          "FREE TO WATCH: Highlights from Manchester United's win over Bournemouth in the Premier League.",
        heroImageUrl:
          "https://cdn.skyq.sky.com/mashup/images/sports-football-latest-news-hs-hero.jpg",
      },
    },
    {
      type: "landscapeTile",
      title: "Vardy inspires Leicester to 3-0 win",
      imageUrl:
        "https://e3.365dm.com/20/07/456x257/promo418147746_5030259.jpg?20200704171138",
      hero: {
        mode: "standard",
        title: "Vardy inspires Leicester to 3-0 win",
        metadata: "1h 45m   ",
        synopsis:
          "FREE TO WATCH: Highlights from Leicester's win over Crystal Palace in the Premier League.",
        heroImageUrl:
          "https://cdn.skyq.sky.com/mashup/images/sports-football-latest-news-hs-hero.jpg",
      },
    },
    {
      type: "landscapeTile",
      title: "Trossard nudges Norwich closer to relegation",
      imageUrl:
        "https://e3.365dm.com/20/07/456x257/skysports-brighton-premier-league_5030090.jpg?20200704151148",
      hero: {
        mode: "standard",
        title: "Trossard nudges Norwich closer to relegation",
        metadata: "1h 45m   ",
        synopsis:
          "FREE TO WATCH: Highlights from Brighton's win over Norwich in the Premier League",
        heroImageUrl:
          "https://cdn.skyq.sky.com/mashup/images/sports-football-latest-news-hs-hero.jpg",
      },
    },
  ],
} as Rail;
