import { RailsPage } from "types";
import topPicks from "./topPicks";
import mustSeeMovies from "./mustSeeMovies";
import newPremieres from "./newPremieres";
import becauseYouWatched from "./becauseYouWatched";
import trending from "./trending";
import nowAndNext from "./nowAndNext";
import family from "./family";
import drama from "./drama";
import crime from "./crime";
import genres from "./genres";
import recordForLater from "./recordForLater";
import basedOnABook from "./basedOnABook";
import actors from "./actors";
import evenBetterInUltraHd from "./evenBetterInUltraHd";
import comingSoon from "./comingSoon";
import utils from "../sharedRails/utils";

export default {
  type: "railsPage",
  brandId: "skycinema",
  rails: [
    topPicks,
    mustSeeMovies,
    newPremieres,
    becauseYouWatched,
    trending,
    nowAndNext,
    family,
    drama,
    crime,
    genres,
    recordForLater,
    basedOnABook,
    actors,
    evenBetterInUltraHd,
    comingSoon,
    utils,
  ],
  title: {
    logoUrl: "https://images.metadata.sky.com/pd-logo/skychb_skycinema/365/75",
    text: "Sky Cinema",
  },
} as RailsPage;
