import { NavStep } from "types";
import { put } from "sagas/effects";

export function* videoPageNavigate(step: NavStep) {
  switch (step) {
    case "BACK":
      yield put({ type: "BROWSE_BACK" });
      break;
  }
}
