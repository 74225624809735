import { Rail } from "types";

export default {
  type: "rail",
  uuid: "83c49136-5a38-4722-a02f-d2b0f6c91cd3",
  template: "tile-cover",
  title: "Sky Store",
  tiles: [
    {
      type: "coverTile",
      title: "24",
      imageUrl:
        "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/0135fabf30b6888b1c8dee136d3acb81/{width}/{height}/item.jpg",
      hero: {
        mode: "standard",
        title: "24",
        metadata: "1h 45m   ",
        synopsis:
          "Kiefer Sutherland is counter terrorist agent Jack Bauer, a one-man army who must save the day against the clock in this real-time thriller. \r",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skystore/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/undefined/background/{width}",
      },
    },
    {
      type: "coverTile",
      title: "Broadchurch",
      imageUrl:
        "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/ee629ae30d6164733955ca3313a755ca/{width}/{height}/item.jpg",
      hero: {
        mode: "standard",
        title: "Broadchurch",
        metadata: "1h 45m   ",
        synopsis:
          "Detectives Olivia Colman and David Tennant investigate as the town of Broadchurch is rocked by a terrible crime. BAFTA award-winning drama.\r",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skystore/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/undefined/background/{width}",
      },
    },
    {
      type: "coverTile",
      title: "Outlander",
      imageUrl:
        "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/8c0a4365d46714b527bc04b66be455bc/{width}/{height}/item.jpg",
      hero: {
        mode: "standard",
        title: "Outlander",
        metadata: "1h 45m   ",
        synopsis:
          "In 1945, Claire Randall is mysteriously transported to 1743 Scotland, where she becomes involved in the Jacobite risings. Based on Diana Gabaldon's novel.\r",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skystore/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/undefined/background/{width}",
      },
    },
    {
      type: "coverTile",
      title: "The West Wing Seasons 1-7",
      imageUrl:
        "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/85b12b68654561892f63daa1740fbb51/{width}/{height}/item.jpg",
      hero: {
        mode: "standard",
        title: "The West Wing Seasons 1-7",
        metadata: "1h 45m   ",
        synopsis:
          "Martin Sheen is President Jed Bartlet in Oscar winner Aaron Sorkin's multi-award-winning look into the inner workings of US politics. Strong language/violence/sex/mature themes/flashing images.",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skystore/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/undefined/background/{width}",
      },
    },
    {
      type: "coverTile",
      title: "Prison Break",
      imageUrl:
        "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/a0caed1a4619dd3c830ba70ca59310d0/{width}/{height}/item.jpg",
      hero: {
        mode: "standard",
        title: "Prison Break",
        metadata: "1h 45m   ",
        synopsis:
          "Hit thriller about a man who devises a genius plan to break his brother out of prison. \r",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skystore/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/undefined/background/{width}",
      },
    },
    {
      type: "coverTile",
      title: "Buffy the Vampire Slayer",
      imageUrl:
        "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/f8b53052054c31fa18746aca909577ae/{width}/{height}/item.jpg",
      hero: {
        mode: "standard",
        title: "Buffy the Vampire Slayer",
        metadata: "1h 45m   ",
        synopsis:
          "Buffy Summers, together with her group of misfit friends, fights to save Earth from supernatural evil. Cult fantasy drama from Joss Whedon. \r",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skystore/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/undefined/background/{width}",
      },
    },
    {
      type: "coverTile",
      title: "Suits",
      imageUrl:
        "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/c0796643a1b194c60f2f752654e8fc62/{width}/{height}/item.jpg",
      hero: {
        mode: "standard",
        title: "Suits",
        metadata: "1h 45m   ",
        synopsis:
          "A talented dropout with no degree stumbles into a job with a top New York lawyer. Stylish drama starring Gabriel Macht and Meghan Markle. \r",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skystore/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/undefined/background/{width}",
      },
    },
    {
      type: "coverTile",
      title: "Peaky Blinders",
      imageUrl:
        "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/ada0c64cd9797cba52ddb30349252e2f/{width}/{height}/item.jpg",
      hero: {
        mode: "standard",
        title: "Peaky Blinders",
        metadata: "1h 45m   ",
        synopsis:
          "An ambitious gang in interwar Birmingham attempt to become the most feared and powerful in town. Epic drama starring Cillian Murphy. Available to Buy now  & Keep forever.\r",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skystore/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/undefined/background/{width}",
      },
    },
    {
      type: "coverTile",
      title: "The Night Manager",
      imageUrl:
        "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/341d86fa3a54d6bce45bafe3f9df06d9/{width}/{height}/item.jpg",
      hero: {
        mode: "standard",
        title: "The Night Manager",
        metadata: "1h 45m   ",
        synopsis:
          "Hotel night manager Tom Hiddleston is recruited by the secret service to bring down arms dealer Hugh Laurie. Based on the John le Carre novel. Strong language/sex/mature themes/flashing images.",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skystore/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/undefined/background/{width}",
      },
    },
    {
      type: "coverTile",
      title: "Westworld",
      imageUrl:
        "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/9811768cde49113806245039ff9d0398/{width}/{height}/item.jpg",
      hero: {
        mode: "standard",
        title: "Westworld",
        metadata: "1h 45m   ",
        synopsis:
          "Set at the intersection of the near future and the reimagined past, explore a world in which every human appetite can be indulged without consequence.\r",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skystore/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/undefined/background/{width}",
      },
    },
    {
      type: "titleTile",
      actionText: "View all",
      hero: {
        mode: "standard",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/undefined/background/{width}",
      },
    },
  ],
} as Rail;
