import { useMemo } from "react";

export const useComponentName = () => {
  return useMemo(() => {
    try {
      throw new Error();
    } catch (e) {
      return (
        e.stack
          ?.split("useComponentName")[1]
          ?.match(/^ *at ([A-Z]\w+)/m)?.[1] || "Unknown"
      );
    }
  }, []);
};
