import React from "react";
import { Container, MediaContainer, Image } from "./CoverTile.styles";
import { CoverTile as CoverTileType } from "types";
import { cover } from "constants/assetSizes";
import { imageUrlFromTemplate } from "utils/imageUrlFromTemplate";

type Props = {
  focused: boolean;
  tile: CoverTileType;
};

export const CoverTile = ({ tile: { title, imageUrl }, focused }: Props) => (
  <Container focused={focused}>
    <MediaContainer>
      <Image alt={title} src={imageUrlFromTemplate(imageUrl, cover)} />
    </MediaContainer>
  </Container>
);
