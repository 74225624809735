import { Rail } from "types";

export default {
  type: "rail",
  uuid: "651c8797-497c-4293-a036-100b565d6093",
  template: "tile-category",
  tiles: [
    {
      type: "categoryTile",
      title: "Family",
      hero: {
        mode: "titleArt",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/f8dff62d-5b14-4487-bc71-7231438c41e6/background/1428",
      },
    },
    {
      type: "categoryTile",
      title: "Action",
      hero: {
        mode: "titleArt",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/3df6fb6a-a92d-40c9-8141-306c585a16b6/background/1428",
      },
    },
    {
      type: "categoryTile",
      title: "Comedy",
      hero: {
        mode: "titleArt",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/468ee3a6-aff3-4f67-a982-eab45f086a81/background/1428",
      },
    },
    {
      type: "categoryTile",
      title: "Drama",
      hero: {
        mode: "titleArt",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/631af54d-e06f-4eb1-ba62-512492043748/background/1428",
      },
    },
    {
      type: "categoryTile",
      title: "Horror",
      hero: {
        mode: "titleArt",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/ae570efa-d64b-4b2e-8f46-ac3cb0521ea4/background/1428",
      },
    },
    {
      type: "categoryTile",
      title: "Thriller",
      hero: {
        mode: "titleArt",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/13d760a2-5e75-4b43-8a27-ba26b6296f16/background/1428",
      },
    },
    {
      type: "categoryTile",
      title: "Sci-Fi",
      hero: {
        mode: "titleArt",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/e73c5b13-2680-43e9-bbd1-18b75a56bf4f/background/1428",
      },
    },
    {
      type: "categoryTile",
      title: "Classics",
      hero: {
        mode: "titleArt",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/5fa55704-6995-4387-b1ca-e904fd036a38/background/1428",
      },
    },
    {
      type: "categoryTile",
      title: "Documentaries",
      hero: {
        mode: "titleArt",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/32804349-7dbf-4879-8ecc-513d1bd9a412/background/1428",
      },
    },
    {
      type: "categoryTile",
      title: "World Cinema",
      hero: {
        mode: "titleArt",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/091b27e2-231b-4614-b4b7-8016e140cb9d/background/1428",
      },
    },
  ],
} as Rail;
