import { makeAutoObservable } from "mobx";

export class Selector<Item> {
  index: number;
  items: Item[];

  constructor(items: Item[], index: number = 0) {
    this.items = items;
    this.index = index;
    makeAutoObservable(this);
  }

  get item() {
    return this.items[this.index];
  }

  prev() {
    if (0 < this.index) {
      this.index--;
    }
  }

  next() {
    if (this.index < this.items.length - 1) {
      this.index++;
    }
  }
}
