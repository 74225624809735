import { Rail } from "types";

export default {
  type: "rail",
  uuid: "914dc6f0-e717-4d6f-9048-f90257965ddd",
  template: "tile-cover",
  title: "Drama",
  tiles: [
    {
      type: "coverTile",
      title: "Downton Abbey",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/631af54d-e06f-4eb1-ba62-512492043748/cover/{height}",
      hero: {
        mode: "standard",
        title: "Downton Abbey",
        metadata: "1h 45m   ",
        synopsis:
          "Big-screen adaptation of the hit television series, following the beloved Crawleys as they prepare for a royal visit. With Maggie Smith. (2019)(122 mins) Enhanced for UHD. Available in Dolby Atmos",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/631af54d-e06f-4eb1-ba62-512492043748/background/{width}",
      },
    },
    {
      type: "coverTile",
      title: "Mr. Jones (2019)",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/1468982f-6de3-4583-b07b-264bb07ec667/cover/{height}",
      hero: {
        mode: "standard",
        title: "Mr. Jones (2019)",
        metadata: "1h 45m   ",
        synopsis:
          "An ambitious Welsh journalist takes a life-threatening risk as he sets out to expose the truth about the devastating famine in the Soviet Union in the 1930s. Drama. (2019)(141 mins)",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/1468982f-6de3-4583-b07b-264bb07ec667/background/{width}",
      },
    },
    {
      type: "coverTile",
      title: "The Great Gatsby (2013)",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/71c4c7fc-67bf-4a98-b515-abde213b6391/cover/{height}",
      hero: {
        mode: "standard",
        title: "The Great Gatsby (2013)",
        metadata: "1h 45m   ",
        synopsis:
          "Leonardo DiCaprio is suave millionaire Jay Gatsby in a dazzling, double Oscar-winning reworking of the American classic, directed by Baz Luhrmann. Violence/flashing images. (2013)(137 mins)Also in UHD",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/71c4c7fc-67bf-4a98-b515-abde213b6391/background/{width}",
      },
    },
    {
      type: "coverTile",
      title: "Once Upon A Time...In Hollywood",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/21daeb91-8778-4d93-a8cd-4bd7318674b5/cover/{height}",
      hero: {
        mode: "standard",
        title: "Once Upon A Time...In Hollywood",
        metadata: "1h 45m   ",
        synopsis:
          "Quentin Tarantino's ninth film follows the life of a washed-up actor during the final years of Hollywood's Golden Age. With Leonardo DiCaprio, Margot Robbie and Brad Pitt. (2019)(159 mins) Also in UHD",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/21daeb91-8778-4d93-a8cd-4bd7318674b5/background/{width}",
      },
    },
    {
      type: "coverTile",
      title: "The Wolf Of Wall Street",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/41f3a880-172b-4346-956e-ff308bd7dac9/cover/{height}",
      hero: {
        mode: "standard",
        title: "The Wolf Of Wall Street",
        metadata: "1h 45m   ",
        synopsis:
          "Leonardo DiCaprio depicts the excessive life of a shady stockbroker in Martin Scorsese's crime comedy. Strong language/sex/flashing images/violence/mature themes/drug use. (2013)(172 mins)",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/41f3a880-172b-4346-956e-ff308bd7dac9/background/{width}",
      },
    },
    {
      type: "coverTile",
      title: "The Twilight Saga ",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/bcbb4c7b-0e63-4e0f-aafc-97ae76f77cd0/cover/{height}",
      hero: {
        mode: "standard",
        title: "The Twilight Saga ",
        metadata: "1h 45m   ",
        synopsis:
          "Sink your teeth into a world of romance, vengeance and vampires as Kristen Stewart, Robert Pattinson and Taylor Lautner star in the epic fantasy film saga that took the world by storm.",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/bcbb4c7b-0e63-4e0f-aafc-97ae76f77cd0/background/{width}",
      },
    },
    {
      type: "coverTile",
      title: "Extremely Wicked, Shockingly...",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/ae2107bb-40f0-4fca-8766-f17405f520b5/cover/{height}",
      hero: {
        mode: "standard",
        title: "Extremely Wicked, Shockingly...",
        metadata: "1h 45m   ",
        synopsis:
          "Sky Cinema original exploring the horrific crimes of Ted Bundy (Zac Efron) as told from the perspective of his girlfriend Elizabeth Kloepfer (Lily Collins). (2019)(108 mins) Enhanced for UHD.",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/ae2107bb-40f0-4fca-8766-f17405f520b5/background/{width}",
      },
    },
    {
      type: "coverTile",
      title: "Hotel Mumbai",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/ccc6c731-eac0-4024-953e-e203036f4101/cover/{height}",
      hero: {
        mode: "standard",
        title: "Hotel Mumbai",
        metadata: "1h 45m   ",
        synopsis:
          "The true story of the Taj Hotel terror attacks, and the heroic hotel staff. With Dev Patel. Contains upsetting scenes. (2018)(123 mins) Enhanced for UHD.",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/ccc6c731-eac0-4024-953e-e203036f4101/background/{width}",
      },
    },
    {
      type: "coverTile",
      title: "Vita & Virginia",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/29b6c75b-879e-45d8-9875-ba424e97d9e7/cover/{height}",
      hero: {
        mode: "standard",
        title: "Vita & Virginia",
        metadata: "1h 45m   ",
        synopsis:
          "Epic biopic romance about the life of Virginia Woolf and her passionate love affair with fellow author Vita Sackville-West. Gemma Arterton and Elizabeth Debicki star. (2019)(110 mins)",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/29b6c75b-879e-45d8-9875-ba424e97d9e7/background/{width}",
      },
    },
    {
      type: "coverTile",
      title: "Tolkien",
      imageUrl:
        "https://images.metadata.sky.com/pd-image/f0716055-d546-495a-acda-97c4ca4df5a0/cover/{height}",
      hero: {
        mode: "standard",
        title: "Tolkien",
        metadata: "1h 45m   ",
        synopsis:
          "Drama set in the early years of iconic author JRR Tolkien's life, as he discovers friendship, love and inspiration among fellow outcasts at school. Nicholas Hoult stars. (2019)(112 mins) Also in UHD.",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skycinema/408/84",
        heroImageUrl:
          "https://images.metadata.sky.com/pd-image/f0716055-d546-495a-acda-97c4ca4df5a0/background/{width}",
      },
    },
  ],
} as Rail;
