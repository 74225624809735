import { TvGuidePage } from "types";
import rail from "./rail";
import { addStartedAtSublines } from "data/pages/helpers";

export default {
  type: "tvGuidePage",
  channelIds: [
    "1404", // Sky News
    "2011", // BBC News
    "2072", // BBC Parliament
    "3611", // CNBC
    "5602", // CNN
    "4645", // Sky News Arabia
  ],
  rail: addStartedAtSublines(rail, new Date()),
  title: {
    text: "News",
  },
} as TvGuidePage;
