import styled from "@emotion/styled";
import { cameraFeed, screen } from "constants/dimensions";
import { CameraEvent } from "types";

export const Container = styled.div`
  height: 300px;
  position: relative;
`;

const barHeight = 8,
  barY = cameraFeed.small.height + 43;

export const Bar = styled.div`
  width: ${screen.width}px;
  height: ${barHeight}px;
  border-radius: ${barHeight / 2}px;
  background-color: rgba(255, 254, 254, 0.3);
  box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.2);
  position: absolute;
  left: 0;
  top: ${barY}px;
`;

export const Fill = styled.div(
  ({ width }: { width: number }) => `
  height: 100%;
  width: max(${width}px, ${barHeight}px);
  border-radius: ${barHeight / 2}px;
  background-color: #2EBFEA;
`
);

export const Circle = styled.div(({ x }: { x: number }) => {
  const rad = 20;
  return `position: absolute;
  top: ${-rad + barHeight / 2}px;
  left: ${x - rad}px;
  width: ${2 * rad}px;
  height: ${2 * rad}px;
  background-image: linear-gradient(
    -44deg,
    #40c7f4 0%,
    #5b2e91 25%,
    #ed008c 50%,
    #f27020 75%,
    #ffde00 100%
  );
  background-image: linear-gradient(
    177deg,
    #2ebfea 0%,
    rgba(255, 255, 255, 0.32) 100%
  );
  background: #2ebfea;
  box-shadow: 16px 16px 16px 0 rgba(0, 0, 0, 0.12);
  border-radius: 169.5px;
`;
});

export const LargeCircle = styled.div(({ x }: { x: number }) => {
  const rad = 50;
  return `
  position: absolute;
  top: ${-rad + barHeight / 2}px;
  left: ${x - rad}px;
  width: ${2 * rad}px;
  height: ${2 * rad}px;
  background: url(assets/images/quickAccessMenu/cameraEventLine/large-circle.svg) no-repeat;
  background-size: contain;
`;
});

export const Icon = styled.div(
  ({ x, type }: { x: number; type: CameraEvent["type"] }) => {
    const width = 20,
      height = 20;
    return `
  background: url(assets/images/quickAccessMenu/cameraEventLine/${type}.svg) no-repeat 50% 50%;
  background-size: contain;
  width: ${width}px;
  height: ${height}px;
  position: absolute;
  left: ${x - width / 2}px;
  top: ${barHeight / 2 - height / 2}px;
`;
  }
);

export const Label = styled.div(
  ({ x }: { x: number }) => `
  position: absolute;
  left: ${x - 20}px;
  top: 38px;
`
);

export const CameraFeedContainer = styled.div(
  ({ x }: { x: number }) => `
  position: absolute;
  left: ${x - cameraFeed.small.width / 2}px;
  top: ${-barY}px
`
);
