import { Rail } from "types";

export default {
  type: "rail",
  uuid: "426b8b01-c681-464d-95bf-ad4da7ff999d",
  template: "tile-landscape",
  title: "Live Sport This Week",
  tiles: [
    {
      type: "landscapeTile",
      title: "Live SNF: Liverpool v Man City",
      imageUrl:
        "assets/images/content/liverpool-man-city/liverpool-man-city-tile.png",
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_skysppl/408/84",
      hero: {
        mode: "standard",
        title: "Live SNF: Liverpool v Man City",
        metadata: "2h 45   ",
        synopsis:
          "Pep Guardiola’s Man City welcome Jugen Klopp’s Liverpool to the Etihad Stadium for a Saturday evening clash from the Premier League. With Sky sports Crowds.",
        heroImageUrl:
          "assets/images/content/liverpool-man-city/liverpool-man-city-hero.jpg",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skysppl/408/84",
      },
    },
    {
      type: "landscapeTile",
      title: "Live PGA Tour Golf",
      imageUrl: "assets/images/content/golf-pga/golf-pga-tile-2.jpg",
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_skyspgolf/408/84",
      hero: {
        mode: "standard",
        title: "Live PGA Tour Golf",
        metadata: "3h   Starts at 6.00pm",
        synopsis:
          "Day three of the Shoprite LPGA Classic from Galloway, New Jersey. The reigning champion here is American Lexi Thompson.",
        heroImageUrl: "assets/images/content/golf-pga/golf-pga-hero.jpg",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skyspgolf/408/84",
      },
    },
    {
      type: "landscapeTile",
      title: "Live Renault Super Sunday",
      imageUrl:
        "assets/images/content/leicester-man-utd/leicester-man-utd-tile.png",
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_skysppl/408/84",
      hero: {
        mode: "standard",
        title: "Leicester vs Man Utd",
        metadata: "3h     Tomorrow, 4.00pm",
        synopsis:
          "Brendan Rodgers Leicester City take on Ole Gunnar Solskjaer’s Manchester United at the King Power stadium in a big Super Sunday clash from the Premier League. With Sky Sports Crowds.",
        heroImageUrl:
          "assets/images/content/leicester-man-utd/leicester-man-utd-hero.jpg",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skysppl/408/84",
      },
    },
    {
      type: "landscapeTile",
      title: "Live: The Football Show",
      imageUrl:
        "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/6044fa15f676ec9e34e852c8886625a5/{width}/{height}/item.jpg",
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_skysports/408/84",
      hero: {
        mode: "standard",
        title: "Live: The Football Show",
        metadata: "1h 45m   ",
        synopsis:
          "A look at the all the major stories making the football headlines, with discussion of all the big talking points from within the game.",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/ed191f00d402c18733e1e6a0edb8ab09/hero.jpg",
        heroTitleImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/8e06068bf14d6b90cacf33862deb4ba7/herotitle.png",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skysports/408/84",
      },
    },
    {
      type: "landscapeTile",
      title: "Live England v W Indies 1st Test",
      imageUrl:
        "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/481f723179d2743f8176a13a132847f6/{width}/{height}/item.jpg",
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_skyspcricket/408/84",
      hero: {
        mode: "standard",
        title: "Live England v W Indies 1st Test",
        metadata: "1h 45m   ",
        synopsis:
          "The cricketing summer finally gets underway as England take on West Indies on day one of the opening Test in the series at the Ageas Bowl in Southampton.",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/f872f263b83748be421aab323835ce49/hero.jpg",
        heroTitleImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/00e4e6014c5469968ba57dc195a9cd6c/herotitle.png",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skyspcricket/408/84",
      },
    },
    {
      type: "landscapeTile",
      title: "Live EFL: West Brom v Derby",
      imageUrl:
        "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/d2a2799168376df4c1bf57f2861931b0/{width}/{height}/item.jpg",
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_skysports/408/84",
      hero: {
        mode: "standard",
        title: "Live EFL: West Brom v Derby",
        metadata: "1h 45m   ",
        synopsis:
          "Promotion-chasing West Bromwich Albion take on Derby County at The Hawthorns in the Sky Bet Championship.",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/3eee926e07f9145e9c1babc033fbe5b4/hero.jpg",
        heroTitleImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/b8730e00b67f66773a4f7eb2c957cec4/herotitle.png",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skysports/408/84",
      },
    },
    {
      type: "landscapeTile",
      title: "Live PL: Sheffield Utd v Wolves",
      imageUrl:
        "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/c0aa47673a64134f102a72cd6ab525b9/{width}/{height}/item.jpg",
      providerImageUrl:
        "https://images.metadata.sky.com/pd-logo/skychb_skysppl/408/84",
      hero: {
        mode: "standard",
        title: "Live PL: Sheffield Utd v Wolves",
        metadata: "1h 45m   ",
        synopsis:
          "Two sides chasing European qualification meet at Bramall Lane as Sheffield United and Wolverhampton Wanderers go head-to-head in the Premier League.",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/b4d849130a78b75f293d2bbb57f6a68e/hero.jpg",
        heroTitleImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/bd0be39d3c793dbd1d986f8f70d5f3a1/herotitle.png",
        logoUrl:
          "https://images.metadata.sky.com/pd-logo/skychb_skysppl/408/84",
      },
    },
    {
      type: "titleTile",
      actionText: "View all",
      hero: {
        mode: "standard",
        heroImageUrl:
          "https://static.skyq-b.interactive.sky.com/config-published-content/apps/media/b4d849130a78b75f293d2bbb57f6a68e/hero.jpg",
      },
    },
  ],
} as Rail;
